
<div class="row text-center" *ngIf="isSaving">
  <div class="loading-container">
    <mat-spinner diameter="50" color="accent"> </mat-spinner>
  </div>
  <br>
  <mat-label>Saving ...</mat-label>
</div>


<form [formGroup]="formData" *ngIf="formData != null && !isSaving">
  <div class="container">
    <mat-card>
      <mat-card-header>
        <mat-card-title class="pt-3">
          <span [hidden]="!loadedModel?.id">Update Campaign - {{ loadedModel?.name }}</span>
          <span [hidden]="loadedModel?.id">Add Campaign</span>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <mat-form-field
            class="col-12 col-sm-6 col-lg-6"
            appearance="fill"
          >
            <mat-label>Service Name</mat-label>
            <input
              matInput
              maxlength="256"
              placeholder="Service Name"
              formControlName="name"
            />
            <mat-error>
              <span *ngIf="formData.get('name').hasError('required')"
                >Service Name <strong>required</strong></span
              >
            </mat-error>
          </mat-form-field>
          <mat-form-field
            class="col-12 col-sm-6 col-lg-6"
            appearance="fill"
          >
            <mat-label>Fully Qualify Domain Name</mat-label>
            <input
              matInput
              maxlength="1024"
              placeholder="Fully Qualify Domain Name"
              formControlName="fqdn"
            />
            <mat-error>
              <span *ngIf="formData.get('fqdn').hasError('required')"
                >Full Domain is <strong>required</strong></span
              >
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-12 col-sm-6 col-lg-6 mt-3" appearance="fill">
						<mat-label>Monitoring Status</mat-label>
						<mat-select formControlName="enableCheck">
							<mat-option [value]="0">
								No
							</mat-option>
							<mat-option [value]="1">
								Yes
							</mat-option>
						</mat-select>
					</mat-form-field>          
        </div>
        <mat-card-actions>
          <div class="row">
            <div class="col-12 text-center">
              <button
                mat-raised-button
                color="primary"
                class="m-2"
                (click)="save()"
                [hidden]="loadedModel?.id > 0"
              >
                Draft
              </button>
              <button
                mat-raised-button
                color="primary"
                class="m-2"
                (click)="save()"
                
              >
                <span [hidden]="!loadedModel || loadedModel?.id<=0">
                  Update
                </span>
                <span [hidden]="loadedModel && loadedModel?.id>0">
                  Save
                </span>
              </button>
              <button
                mat-raised-button
                class="m-2"
                [routerLink]="['/native-ads/ads-report']"
              >
                Cancel
              </button>
            </div>
          </div>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>
</form>

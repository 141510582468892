import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ISearchParams } from "../interfaces/ISearchParams";

export abstract class ListingService<T> {
  abstract list(params?: HttpParams)
  abstract get(id: string | number)
  abstract delete(id: string | number)
  abstract update(id: string | number, model: T)
  abstract create(model: T)
  abstract save(model: T, id?: string | number)

  baseURL: string = ""
  get createURL() {
    return this.baseURL + environment.consumerReport.commonCreatePath
  }
  get updateURL() {
    return this.baseURL + environment.consumerReport.commonUpdatePath
  }
  get listURL() {
    return this.baseURL + environment.consumerReport.commonListPath
  }
  get getURL() {
    return this.baseURL + environment.consumerReport.commonGetPath
  }

  get deleteURL() {
    return this.baseURL + environment.consumerReport.commonDeletePath
  }
  saveURL(id?: string | number) {
    
    if(id) {
      return this.baseURL + environment.consumerReport.commonUpdatePath + '/'+ id?.toString()
    } 
    return this.baseURL + environment.consumerReport.commonCreatePath
  }

  // commonListingFn(params?: HttpParams) {
  //   return this.http
  // }

}
<ng-container
  *ngIf="!auth; then notauthenticated; else authenticated">
</ng-container>
<ng-template #authenticated>
  <mat-toolbar color="primary" class="main-top-nav">
    <button mat-icon-button (click)="toggleMenu()">
      <mat-icon class="menu-darvis">menu</mat-icon>
    </button>
    
    <!-- <span>DailyVanity</span> -->
    <span><img title="Darvis" class="logo" src="/assets/logo.png"></span>
    <span class="toolbar-spacer"></span>
    <darvis-share-menu-user-icon ></darvis-share-menu-user-icon>
  </mat-toolbar>
  
  <mat-drawer-container class="main-container" autosize [hasBackdrop]="false">
    <mat-drawer #drawer class="main-sidenav" mode="side" opened="{{openMenu}}">
      <darvis-share-side-menu></darvis-share-side-menu>
    </mat-drawer>
    <mat-drawer-content>
      <div class="container-fluid pt-2 pb-2">
        <router-outlet></router-outlet>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</ng-template>
<ng-template #notauthenticated>
  <router-outlet></router-outlet>
</ng-template>

import { Component } from '@angular/core';
import { IStaticReportConfigGroup } from '../../interfaces/iStaticReportConfigGroups';
import { ActivatedRoute } from '@angular/router';
import { ConsumerReportSurveyConfigGroupService } from '../../services/consumer-report-survey-config-group.service';
import { re } from 'mathjs';

@Component({
  selector: 'app-reports-group-update',
  templateUrl: './reports-group-update.component.html',
  styleUrl: './reports-group-update.component.scss'
})
export class ReportsGroupUpdateComponent {

  isLoading = false;
  model: IStaticReportConfigGroup = {
    name: '',
    about: '',
    reportConfig: [],
    id: 0,
    uuid: ''
  }

  constructor(
    private readonly route: ActivatedRoute,
    private consumerReportSurveyConfigGroup : ConsumerReportSurveyConfigGroupService,
 
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    const id = this.route.snapshot.paramMap.get('id');
    this.consumerReportSurveyConfigGroup.get(id)
    .subscribe(async (res) => {
      this.isLoading = false;
      if(res){
        // console.log(res);
        this.model.uuid = res.uuid;
        this.model.id = res.id;
        this.model.name = res.name; 
        this.model.about = res.about;
        this.model.countryOfOrigin = res.countryOfOrigin;
        this.model.reportConfig = res.reportConfigs.split(",");
      }
    },error => {
      this.isLoading = false;
    })
  }

}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleIndexComponent } from './pages/role-index/role-index.component';
import { RoleUpdateComponent } from './pages/role-update/role-update.component';
import { ServiceIndexComponent } from './pages/service-index/service-index.component';
import { ServiceUpdateComponent } from './pages/service-update/service-update.component';
import { UserDetailComponent } from './pages/user-detail/user-detail.component';
import { UserLogsIndexComponent } from './pages/user-logs-index/user-logs-index.component';
import { UserRequestIndexComponent } from './pages/user-request-index/user-request-index.component';
import { UserCreateComponent } from './pages/user-create/user-create.component';

export const SecurityGuardRoute: Routes  = [
  {path: 'security-guard', redirectTo: 'security-guard/users', pathMatch: 'full'},
  {path: 'security-guard/users', component: UserLogsIndexComponent},
  {path: 'security-guard/users/create', component: UserCreateComponent},
  {path: 'security-guard/profile/:id', component: UserDetailComponent},
  {path: 'security-guard/roles', component: RoleIndexComponent},
  {path: 'security-guard/roles/:id', component: RoleUpdateComponent},
  {path: 'security-guard/user-request', component: UserRequestIndexComponent},
  {path: 'security-guard/service', component: ServiceIndexComponent},
  {path: 'security-guard/service/:id', component: ServiceUpdateComponent},
];

@NgModule({
  imports: [
    RouterModule.forChild(SecurityGuardRoute)
  ],
  exports: [RouterModule]
})
export class SecurityGuardRouteModule { }

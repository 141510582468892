<form [formGroup]="formData">
  <div class="container">
    <mat-card>
      <mat-card-header>
        <mat-card-title class="pt-3">
          <span [hidden]="!loadedModel?.id">Update Campaign - {{ loadedModel?.name }}</span>
          <span [hidden]="loadedModel?.id">Add Campaign</span>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row" *ngIf="isAutoLoadArticle">
          <mat-form-field
          class="col-12 col-sm-12 col-lg-12"
          appearance="fill"
        >
          <mat-label>Search Articles</mat-label>
          <input
            matInput
            maxlength="120"
            placeholder="Article"
            formControlName="article"
            [matAutocomplete]="autoCompleteArticle"
          />
          <mat-autocomplete #autoCompleteArticle="matAutocomplete" [displayWith]="displayFnArticle">
            <mat-option *ngFor="let article of filteredArticle$ | async" [value]="article">
              {{ article.title.rendered }}
            </mat-option>
          </mat-autocomplete>
          <mat-error>
            <span *ngIf="formData.get('article').hasError('required')"
              >Article is <strong>required</strong></span
            >
          </mat-error>
        </mat-form-field>
        </div>

        <div class="row">
          <mat-form-field
            class="col-12 col-sm-6 col-lg-6"
            appearance="fill"
          >
            <mat-label>Client Name</mat-label>
            <input
              matInput
              maxlength="120"
              placeholder="Client Name"
              formControlName="client"
              [matAutocomplete]="autoCompleteClientName"
            />
            <mat-autocomplete #autoCompleteClientName="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let client of filteredClient$ | async" [value]="client">
                {{ client.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error>
              <span *ngIf="formData.get('client').hasError('required')"
                >Client is <strong>required</strong></span
              >
            </mat-error>
          </mat-form-field>
          <mat-form-field
            class="col-12 col-sm-6 col-lg-6"
            appearance="fill"
          >
            <mat-label>Campaign Name</mat-label>
            <input
              matInput
              maxlength="120"
              placeholder="Campaign Name"
              formControlName="name"
            />
            <mat-error>
              <span *ngIf="formData.get('name').hasError('required')"
                >Campaign Name is <strong>required</strong></span
              >
            </mat-error>
          </mat-form-field>
          <div class="col-12 col-sm-6 col-lg-6 datetime-picker-container">
            <div class="row">
              <div  class="col-12 col-md-6 col-lg-6">
                <mat-form-field class="col-12" appearance="fill">
                  <mat-label>Start Date (DD MMM YYYY)</mat-label>
                  <input matInput placeholder="Start Date" [matDatepicker]="startDatePicker" formControlName="startDate" (dateChange)="startDateChanged($event.value)" />
                  <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #startDatePicker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-12 col-sm-6 col-lg-6"> 
                <ngx-timepicker-field #startTimeEle [defaultTime]="formData.get('startTime').value" (timeChanged)="startTimeChanged($event)"></ngx-timepicker-field>
              </div>
            </div>
            <div class="row">

              <mat-error>
                <span *ngIf="formData.get('startDate').hasError('required')">Start Date is <strong>required</strong></span>
              </mat-error>
              <mat-error>
                <span *ngIf="formData.get('startTime').hasError('required')">Start Time is <strong>required</strong></span>
              </mat-error>
              <mat-error>
                <span *ngIf="formData.errors?.periodError">Start Date Time must be before End Date Time</span>
              </mat-error>
            </div>
          </div>
          
          <div class="col-12 col-sm-6 col-lg-6 datetime-picker-container">
            <div class="row">
              <div class="col-12 col-sm-6 col-lg-6">
                <mat-form-field appearance="fill" class="col-12">
                  <mat-label>End Date (DD MMM YYYY)</mat-label>
                  <input placeholder="End Date" matInput [matDatepicker]="endDatePicker" formControlName="endDate" />
                  <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                  <mat-datepicker #endDatePicker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="col-12 col-sm-6 col-lg-6"> 
                <ngx-timepicker-field #endTimeEle [defaultTime]="formData.get('endTime').value" (timeChanged)="endTimeChanged($event)"></ngx-timepicker-field>
              </div>
            </div>
            <div class="row">
              <mat-error>
                <span *ngIf="formData.get('endDate').hasError('required')">End Date is <strong>required</strong></span>
              </mat-error>
              <mat-error>
                <span *ngIf="formData.get('endTime').hasError('required')">End Time is <strong>required</strong></span>
              </mat-error>
              <mat-error>
                <span *ngIf="formData.errors?.periodError">End Date Time must be after Start Date Time</span>
              </mat-error>
            </div>
            
          </div>
          
          <mat-form-field class="col-12 col-sm-6 col-lg-6 mt-3" appearance="fill">
            <mat-label>Platform</mat-label>
            <mat-select multiple formControlName="publishers" [compareWith]="comparePublisher">
              <mat-option *ngFor="let publisher of loadedPublisher$ | async" [value]="publisher">
                {{publisher.name}}
              </mat-option>
            </mat-select>
            <mat-error>
              <span *ngIf="formData.get('publishers').hasError('required')">Publishers are <strong>required</strong></span>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-12 col-sm-6 col-lg-6 mt-3" appearance="fill">
						<mat-label>Status</mat-label>
						<mat-select formControlName="status" [compareWith]="compareCampaignStatus">
							<mat-option *ngFor="let status of campaignStatus$ | async" [value]="status">
								{{status.value}}
							</mat-option>
						</mat-select>
					</mat-form-field>


          
          
        </div>
				<div formArrayName="campaignItems">
					<ng-container
						*ngFor="let campaignItem of campaignItems.controls;let campaignItemIndex = index"
						class="mb-2 col-6"
					>
						<div class="row" [formGroupName]="campaignItemIndex">
							<mat-form-field
								class="col-12 col-sm-6 col-lg-6"
								appearance="fill"
							>
								<mat-label>Ads Type</mat-label>
								<mat-select formControlName="type" [compareWith]="compareCampaignStatus" (selectionChange)="adsTypeChange($event.value, campaignItemIndex)">
									<mat-option
										*ngFor="let adtype of adsType$ | async"
										[value]="adtype"
									>
										{{ adtype.value }}
									</mat-option>
								</mat-select>
							</mat-form-field>
							<mat-form-field
								class="col-12 col-sm-6 col-lg-6"
								appearance="fill"
                [hidden]="!showHighlight"
							>
								<mat-label>Headline</mat-label>
								<input
									matInput
									placeholder="Ads Headline"
									formControlName="title"
                  [readonly]="!showHighlight"
								/>
							</mat-form-field>
							<mat-form-field
								class="col-12 col-sm-6 col-lg-6"
								appearance="fill"
                [hidden]="hideCampaignItemFields()"
							>
								<mat-label>Destination URL</mat-label>
								<input
									matInput
									placeholder="Destination URL"
									formControlName="linkURL"
								/>
							</mat-form-field>
              <mat-form-field
								class="col-12 col-sm-12 col-lg-12"
								appearance="fill"
                [hidden]="!hideCampaignItemFields()"
							>
								<mat-label>Custom Script</mat-label>
								<textarea matInput formControlName="description" placeholder="Custom Script" rows="5"></textarea>
							</mat-form-field>
              <mat-form-field
								class="col-12 col-sm-6 col-lg-6"
								appearance="fill"
                [hidden]="!enableFields(campaignItemIndex) || hideCampaignItemFields()"
							>
								<mat-label>Impressions booked</mat-label>
								<input
									matInput
									placeholder="Impressions booked"
									formControlName="impressionPurchased"
                  ngxNumberFormat="#,###,###,###,###"
                  [readonly]="!enableFields(campaignItemIndex)"
								/>
                <mat-error>
                  <span *ngIf="campaignItem.get('impressionPurchased').hasError('min')">Impression booked must be more or equal to 10,000</span>
                  <span *ngIf="campaignItem.get('impressionPurchased').hasError('required')">Impression booked cannot be empty</span>
                  
                </mat-error>
							</mat-form-field>
							<div class="col-12 imageWrapper position-relative mb-4" [hidden]="!enableFields(campaignItemIndex) || hideCampaignItemFields() || hideImageFields()" [class.pu-adstype]="campaignItem.get('type')?.value?.identifier == 'PU'" [class.ai-adstype]="campaignItem.get('type')?.value?.identifier == 'AI'">
								<ng-container *ngIf="!enableDesktopImage(campaignItemIndex)">
                  Featured image
                </ng-container>
                <ng-container *ngIf="enableDesktopImage(campaignItemIndex)">
                  Mobile image
                </ng-container>
                <mat-hint>
                  <span *ngIf="campaignItem.get('type')?.value?.id === 19">(900x467, jpg, png, gif only. Less than 2MB)</span>
                  <span *ngIf="campaignItem.get('type')?.value?.id === 21">(1400x800, jpg, png, gif only. Less than 2MB)</span>
                  <span *ngIf="campaignItem.get('type')?.value?.id === 20">(800x1040, jpg, png, gif only. Less than 2MB)</span>
                  <br /> <span class="imageDescription">Image displayed here is approximately the size you will see on the mobile phone. Please ensure creative's font is of readable size in a glance without reader needing to focus due to font size being too tiny.</span>
                </mat-hint>
                <mat-error>
                  <span *ngIf="campaignItem.get('imageURL').hasError('imageDimension')">Upload wrong image dimension</span>
                  <span *ngIf="campaignItem.get('imageURL').hasError('imageSize')">Image size cannot be more than 2MB</span>
                  <span *ngIf="campaignItem.get('imageURL').hasError('imageType')">Format type JPG, PNG or GIF only</span>
                </mat-error>
                <div class="mt-2 mb-2 resolution-toggle">
                  View in: <span class="toggleIcon"><a href="#" (click)="toggleView()"><mat-icon>phone_iphone</mat-icon></a><a  href="#" (click)="toggleView()"><mat-icon>computer</mat-icon></a></span>
                </div>
                <div class="imageContainer mobileImage" [class.mobileView]="viewMobile" [class.desktopView]="!viewMobile">
                  <div class="popup-background">
                  </div>
                  <img title="{{ campaignItem.value.title }}" width="100%" ckla src="{{campaignItem.value.imageURL}}" />
                  <div class="position-absolute bottom-50 end-50">
                    <app-shared-upload-button 
                      [uploadBucket]="uploadBucket"
                      [fileNamePrefix]="fileNamePrefix"
                      [uploadPath]="uploadPath"
                      (uploadedCompletes)="uploadedFiles($event)"
                      [multiUpload]="false"
                      [validateFile]="validateUploadImage(campaignItemIndex)"
                      [allowExtension]="allowUploadType"
                    ></app-shared-upload-button>
                  </div>
                </div>
							</div>

              <div class="col-12 imageWrapper position-relative mb-4 pu-adstype" [hidden]="!enableDesktopImage(campaignItemIndex) || hideCampaignItemFields() || hideImageFields()">
								Desktop Image
                <mat-hint>
                  <span>(Recommended: 2000x1200, minimum 800x1040, jpg, png, gif only. Less than 5MB)</span>
                </mat-hint>
                <mat-error>
                  <span *ngIf="campaignItem.get('desktopImageURL').hasError('imageDimension')">Upload wrong image dimension</span>
                  <span *ngIf="campaignItem.get('desktopImageURL').hasError('imageSize')">Image size cannot be more than 5MB</span>
                  <span *ngIf="campaignItem.get('desktopImageURL').hasError('imageType')">Format type JPG, PNG or GIF only</span>
                </mat-error>
                <div class="imageContainer desktopImage">
                  <div class="popup-background">
                  </div>
                  <img title="{{ campaignItem.value.title }}" width="100%" ckla src="{{campaignItem.value.desktopImageURL}}" />
                  <div class="position-absolute bottom-50 end-50">
                    <app-shared-upload-button 
                      [uploadBucket]="uploadBucket"
                      [fileNamePrefix]="fileNamePrefix"
                      [uploadPath]="uploadPath"
                      (uploadedCompletes)="uploadedDesktopFiles($event)"
                      [multiUpload]="false"
                      [validateFile]="validateUploadDesktopImage(campaignItemIndex)"
                      [allowExtension]="allowUploadType"
                    ></app-shared-upload-button>
                  </div>
                </div>
							</div>
              <div class="col-12 col-sm-6 col-lg-6 mt-2 mb-2" [hidden]="!showHighlight">
                <mat-label >Select a Priority</mat-label> <br />
                <mat-radio-group aria-label="Select a Priority" [formControl]="PriorityControl">
                  <mat-radio-button value="1" class="mt-2">High priority (clients)</mat-radio-button> <br />
                  <mat-radio-button value="0" class="mt-2">Low priority (in-house, editorial)</mat-radio-button>
                </mat-radio-group>
                <mat-error>
                  <span *ngIf="PriorityControl.hasError('required')"
                    >Campaign priority is <strong>required</strong></span
                  >
                </mat-error>
              </div>
						</div>
					</ng-container>
				</div>
        
        <mat-card-actions>
          <div class="row">
            <div class="col-12 text-center">
              <button
                type="button"
                mat-raised-button
                color="primary"
                class="m-2"
                (click)="save(true)"
                [hidden]="loadedModel?.id > 0"
              >
                Draft
              </button>
              <button
                type="button"
                mat-raised-button
                color="primary"
                class="m-2"
                (click)="save(false)"
                
              >
                <span [hidden]="!loadedModel || loadedModel?.id<=0">
                  Update
                </span>
                <span [hidden]="loadedModel && loadedModel?.id>0">
                  {{ btnSaveText }}
                </span>
              </button>
              <button
                type="button"
                mat-raised-button
                class="m-2"
                [routerLink]="['/native-ads/ads-report']"
              >
                Cancel
              </button>
            </div>
          </div>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>
</form>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FollowerIndexComponent } from './telegram/pages/follower-index/follower-index.component';
import { NotificationIndexComponent } from './telegram/pages/notification-index/notification-index.component';
import { PostIndexComponent } from './telegram/pages/post-index/post-index.component';
import { MessageIndexComponent } from './telegram/pages/message-index/message-index.component';
import { AuthGuardService } from '../auth/auth-guard.service';
import { environment } from 'src/environments/environment';

export const ReportRoute: Routes  = [
  {path: 'telegram/follower', component: FollowerIndexComponent, canActivate: [AuthGuardService], data : {roles: environment.telegramReport.defaultRoles} },
  {path: 'telegram/post', component: PostIndexComponent,  canActivate: [AuthGuardService], data : {roles: environment.telegramReport.defaultRoles} },
  {path: 'telegram/notification', component: NotificationIndexComponent,  canActivate: [AuthGuardService], data : {roles: environment.telegramReport.defaultRoles} },
  {path: 'telegram/messages', component: MessageIndexComponent,  canActivate: [AuthGuardService], data : {roles: environment.telegramReport.defaultRoles} },
]

@NgModule({
  imports: [
    RouterModule.forRoot(ReportRoute, {})
  ],
  exports: [RouterModule]
})
export class ReportRouteModule { }

import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { ICRUDPath } from 'src/app/shared/interfaces/iCRUDPath.interface';
import { environment } from 'src/environments/environment';
import { IPublisher } from '../interfaces/publisher';
import { IUploadFile } from '../interfaces/upload-file';

@Injectable({
  providedIn: 'root'
})
export class NativeAdsUploadFilesService extends CRUDService<IUploadFile>{
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }
  list(params?: HttpParams): Observable<ICommonListingResponse<IUploadFile[]>> {
    throw new Error('Method not implemented.');
  }
  get(id: string | number): Observable<IUploadFile> {
    throw new Error('Method not implemented.');
  }
  delete(id: string | number): Observable<IUploadFile> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: IUploadFile): Observable<IUploadFile> {
    throw new Error('Method not implemented.');
  }
  create(model: IUploadFile): Observable<IUploadFile> {
    throw new Error('Method not implemented.');
  }
  makeFormGroup(loadedModel?: IUploadFile) {
    return this.fb.group({
      id: [loadedModel?.id],
      bucketURL: [loadedModel?.bucketURL],
      path: [loadedModel?.path],
      fileName: [loadedModel?.fileName],
      fullFilePath: [loadedModel?.fullFilePath], 
      url: [loadedModel?.url],
      name: [loadedModel?.name],
      mimetype: [loadedModel?.mimetype],
      width: [loadedModel?.width],
      height: [loadedModel?.height],
      campaignItemID: [loadedModel?.campaignItemID],
      parentID: [loadedModel?.parentID]
    })
  }

  resizeImages(models: IUploadFile[]): Observable<IUploadFile[]> {
    return this.httpClient.post<IUploadFile[]>(this.getAPIURL('/resize-image'), models)
  }

  apiPath = environment.nativeAds.uploadFileBase
  environmentCRUDPath = environment.nativeAds;

  constructor(
    public readonly httpClient: HttpClient,
    public readonly fb: FormBuilder,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute
  ) {
    super();
  }
}

<div [formGroup]="loadedModel" class="row">
  <div [class.col-9]="!showGroup" [class.col-7]="showGroup">  
    <mat-form-field class="fullWidth" appearance="fill">
      <mat-label>Label</mat-label>
      <input matInput #label maxlength="256" placeholder="Answer text" formControlName="label" >
    </mat-form-field>                
  </div>
  <div class="col-2" [hidden]="!showGroup">
    <mat-form-field class="fullWidth" appearance="fill">
      <mat-label>Group</mat-label>
      <input matInput #group maxlength="256" placeholder="Option grouping" formControlName="group" >
    </mat-form-field>
  </div>
  <div class="col-1">
    <mat-form-field class="fullWidth" appearance="fill">
      <mat-label>Points</mat-label>
      <input matInput #point maxlength="256" placeholder="Points for the answer (leave blank if no point)" formControlName="point">
    </mat-form-field>
  </div>
  <div [class.col-9]="!showGroup" [class.col-7]="showGroup">                
    <mat-form-field class="fullWidth" appearance="fill">
      <mat-label>ToolTips</mat-label>
      <input matInput #tooltip maxlength="256" placeholder="Answer text" formControlName="tooltip" >
    </mat-form-field>                
  </div>
  <div class="col-3" [hidden]="!allowOptions">                
    <mat-form-field appearance="fill">
      <mat-label>Choose an option</mat-label>
      <mat-select formControlName="specialControl">
        <mat-option value=""></mat-option>
        <mat-option value="deselectAll">DeSelect all Options</mat-option>
        <mat-option value="userDefine">Allow User define</mat-option>
      </mat-select>
    </mat-form-field>               
  </div>
</div>

import { HttpParams } from '@angular/common/http';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { from, Observable, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { ConsumerReportUserService } from '../services/consumerReportUserService.service';

export class EmailDuplicateValidator {
  static createValidator(consumerReportUserService: ConsumerReportUserService, originalEmail?: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      if (originalEmail && originalEmail.trim().toLocaleLowerCase() !== control.value.trim().toLocaleLowerCase()) {
        const params: HttpParams = new HttpParams({
          fromObject: {
            email: control.value,
            limit: '1',
            page: '1'
          }
        });

        return timer(500)
          .pipe(
            switchMap(_ => consumerReportUserService.list(params)),
            map((v:any) => v.total <= 0 ? null : {emailExists: control.value + ' already exists in system.'})
          );
      }

      return timer(10)
        .pipe(
          map(v => null)
        );
    };
  }
}

import { HttpParams } from "@angular/common/http";
import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";

@Component({
  template: ''
})
export abstract class FormComponent<T> {

  @Input('formModel')
  loadedModel: T;

  formData: FormGroup = null;
  savingForm: boolean = false

  get formErrors() { 
    return this.formData.controls 
  }

  abstract initForm(): void;
  abstract save(): void;
}
<div class="row mb-3">
	<div class="col-12 text-right">
		<button mat-raised-button color="primary" [routerLink]="['/security-guard/user/create']">Add New User</button>
	</div>
</div>
<form  [formGroup]="searchFormGroup" class="row mb-3">
	<mat-form-field class="col-12 col-sm-6" appearance="fill">
		<input type="text" placeholder="Type anything to search user" aria-label="Type anything to search User"
			matInput appearance="fill" autocomplete="off" formControlName="searchText">
	</mat-form-field>
	<mat-form-field class="col-12 col-sm-3" appearance="fill">
		<mat-label>Role</mat-label>
		<mat-select multiple formControlName="roles" [compareWith]="compareCommonEntity">
			<mat-option *ngFor="let role of role$ | async" [value]="role">
				{{role.name}}
			</mat-option>
		</mat-select>
	</mat-form-field>
	<mat-form-field class="col-12 col-sm-3" appearance="fill">
		<mat-label>Status</mat-label>
		<mat-select multiple formControlName="cognitoStatus">
			<mat-option *ngFor="let status of cognitoStatuses" [value]="status.value">
				{{status.label}}
			</mat-option>
		</mat-select>
	</mat-form-field>
</form>
<div class="row">
	<div class="col-12">
		<table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
			<ng-container matColumnDef="name">
				<th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
				<td mat-cell *matCellDef="let element">
					{{ element.displayName }}
				</td>
			</ng-container>
			<ng-container matColumnDef="email">
				<th mat-header-cell mat-sort-header *matHeaderCellDef>Email</th>
				<td mat-cell *matCellDef="let element">
					{{ element.email }}
				</td>
			</ng-container>
			<ng-container matColumnDef="roles">
				<th mat-header-cell *matHeaderCellDef>Roles</th>
				<td mat-cell *matCellDef="let element">
					<span *ngFor="let role of element.roles" class="roleCol">{{ role.name }}</span>
				</td>
			</ng-container>
			<ng-container matColumnDef="loginActivity">
				<th mat-header-cell *matHeaderCellDef>Login Activity</th>
				<td mat-cell *matCellDef="let element">
					<span *ngFor="let request of element.userRequests" class="userRequestDate">{{ convertDateFormat(request.createdDate) | date:'dd MMM yyyy hh:mm a' }}</span>
				</td>
			</ng-container>

			<ng-container matColumnDef="workActivity">
				<th mat-header-cell *matHeaderCellDef>Work Activity</th>
				<td mat-cell *matCellDef="let element">
					<span *ngFor="let activity of element.workActivities" class="userRequestDate">{{ combineWorkActivity(activity) | date:'MMM YYYY' }} </span>
				</td>
			</ng-container>

			<ng-container matColumnDef="cognitoStatus">
				<th mat-header-cell *matHeaderCellDef>Account Status</th>
				<td mat-cell *matCellDef="let element">
					{{ getCognitoStatusLabel(element.cognitoStatus) }}
				</td>
			</ng-container>


			<ng-container matColumnDef="action">
				<th mat-header-cell *matHeaderCellDef> Action </th>
				<td mat-cell *matCellDef="let element;">
					<a mat-icon-button color="primary" (click)="changeUserPassword(element)" [disabled]="element.googleID">
						<mat-icon matTooltip="Edit" matTooltipPosition="left">key</mat-icon>
					</a>
					<a mat-icon-button color="primary" routerLink="/security-guard/profile/{{element.id}}">
						<mat-icon matTooltip="View" matTooltipPosition="left">visibility</mat-icon>
					</a>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="listingCols" class="p-2"></tr>
			<tr mat-row *matRowDef="let row; columns: listingCols;" class="p-2"></tr>
		</table>
	</div>
</div>
<div class="row mb-4">
	<div class="col-12">
		<mat-paginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
			[showFirstLastButtons]="true" [pageIndex]="page" (page)="changePage($event)">
		</mat-paginator>
	</div>
</div>

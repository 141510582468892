<form [formGroup]="formData" *ngIf="formData !== null">
	<div class="container">
		<mat-card>
			<mat-card-header>
				<mat-card-title class="pt-3">
					<span [hidden]="!loadedModel?.id"
						>Update User - {{ loadedModel?.displayName }}</span
					>
					<span [hidden]="loadedModel?.id">Add User</span>
				</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<div class="row">
					<mat-form-field class="col-12 col-sm-6 col-lg-6" appearance="fill">
						<mat-label>First Name</mat-label>
						<input
							matInput
							maxlength="256"
							placeholder="First Name"
							formControlName="firstName"
						/>
						<mat-error>
							<span *ngIf="formData.get('firstName').hasError('required')">First Name <strong>required</strong></span>
						</mat-error>
					</mat-form-field>

					<mat-form-field class="col-12 col-sm-6 col-lg-6" appearance="fill">
						<mat-label>Last Name</mat-label>
						<input
							matInput
							maxlength="256"
							placeholder="Last Name"
							formControlName="lastName"
						/>
						<mat-error>
							<span *ngIf="formData.get('lastName').hasError('required')"
								>Last Name <strong>required</strong></span>
						</mat-error>
					</mat-form-field>

					<mat-form-field class="col-12 col-sm-6 col-lg-6" appearance="fill">
						<mat-label>Display Name</mat-label>
						<input
							matInput
							maxlength="256"
							placeholder="Display Name"
							formControlName="displayName"
						/>
						<mat-error>
							<span *ngIf="formData.get('displayName').hasError('required')"
								>Display Name <strong>required</strong></span
							>
						</mat-error>
					</mat-form-field>

					<mat-form-field class="col-12 col-sm-6 col-lg-6" appearance="fill">
						<mat-label>Email</mat-label>
						<input
							matInput
							maxlength="256"
							placeholder="Email"
							formControlName="email"
						/>
						<mat-error>
							<span *ngIf="formData.get('email').hasError('required')"
								>Email <strong>required</strong></span
							>
						</mat-error>
					</mat-form-field>
					<div class="col-12">
						<h3>User Roles</h3>
						<cdk-virtual-scroll-viewport class="outlet-viewport scrollable-viewport" itemSize="20">
							<ul>
								<li *cdkVirtualFor="let role of roleList$ | async">
									<mat-checkbox [checked]="roleSelection.isSelected(role.id)" (change)="toggleRole(role.id)">
										{{ role.label }} - {{ role.name }}
									</mat-checkbox>
								</li>
							</ul>
						</cdk-virtual-scroll-viewport>
					</div>
					

				</div>
				<mat-card-actions>
					<div class="row">
						<div class="col-12 text-center">
							<button
								mat-raised-button
								color="primary"
								class="m-2"
								[disabled]="savingForm"
								(click)="save()"
							>
								<span [hidden]="loadedModel && +loadedModel?.id > 0">
									Save
								</span>
							</button>
							<button
								mat-raised-button
								class="m-2"
								[disabled]="savingForm"
								[routerLink]="['/security-guard/users']"
							>
								Cancel
							</button>
						</div>
					</div>
				</mat-card-actions>
			</mat-card-content>
		</mat-card>
	</div>
</form>

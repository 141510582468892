export enum MasterDataTypeEnum {
	publisherCategories='publisherCategories',
	gender='gender',
	country='country',
	adsType='adsType',
	currency='currency',
	campaignStatus='campaignStatus',
	userRole='userRole',
	adsStatus='adsStatus',
	metricsType='metricsType',
	publisherCPMStatus='publisherCPMStatus',
	publisherStatus='publisherStatus',
	paymentStatus='paymentStatus',
	tokenType='tokenType',
	publisherBankStatus='publisherBankStatus'
}
import { Component, OnInit } from '@angular/core';
import { IQuestion } from '../../interfaces/question';

@Component({
  selector: 'app-question-create',
  templateUrl: './question-create.component.html',
  styleUrls: ['./question-create.component.scss']
})
export class QuestionCreateComponent implements OnInit {

  model: IQuestion = {
    label: '',
    answers: [],
    year: 0,
    controlType: 'text',
    type: "",
    instruction: '',    
    order: 0,
    matrices: [],
    id: undefined
  };

  constructor() { }

  ngOnInit(): void {
  }

}

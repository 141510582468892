export const DateFormat = {
	parse: {
		dateInput: ['l', 'LL', 'L','LLL', 'LLLL']
	},
	display: {
		dateInput: 'DD MMM YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY'
	}
}
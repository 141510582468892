import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormComponent } from 'src/app/shared/abstracts/form-component';
import { INewUrlShortener } from '../../interfaces/INewUrlShortener';
import { UrlShortenerCollectionService } from '../../services/url-shortener-collection.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AsyncSubject, BehaviorSubject, catchError, debounceTime, filter, finalize, forkJoin, map, Observable, of, startWith, Subscription, switchMap, tap } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { UrlShortenerTagService } from '../../services/url-shortener-tag.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ITag } from '../../interfaces/ITag';
import { captureException } from '@sentry/angular-ivy';
import { UrlShortenerDomainService } from '../../services/url-shortener-domain.service';
import { MakeSearchParams } from 'src/app/consumer-report/helpers/MakeSearchParams';
import { IClient } from 'src/app/native-ads/interfaces/client';
import { MatDialog } from '@angular/material/dialog';
import { TelephoneUrlGeneratorComponent } from '../telephone-url-generator/telephone-url-generator.component';
import { WhatsappUrlGeneratorComponent } from '../whatsapp-url-generator/whatsapp-url-generator.component';

@Component({
	selector: 'url-shortener-form',
	templateUrl: './url-shortener-form.component.html',
	styleUrls: ['./url-shortener-form.component.scss']
})
export class UrlShortenerFormComponent extends FormComponent<INewUrlShortener, UrlShortenerCollectionService> implements OnInit, OnChanges, OnDestroy {

	_client = new FormControl(null, Validators.required);
	_campaign = new FormControl(null, Validators.required);

	@Input() formTypeTitle: string;
	@Input() actionType: string;

	@Input() refreshForm: BehaviorSubject<INewUrlShortener>;

	generatedURL = false;

	domains: any[] = [
		{ value: 'dv.sg', viewValue: 'dv.sg' },
		{ value: 'a1.plus', viewValue: 'a1.plus' },
		{ value: 'dvmy.link', viewValue: 'dvmy.link' },
		{ value: 'redirect.urlshortener.tipsy.darvis.dev', viewValue: 'redirect.urlshortener.tipsy.darvis.dev' }
	];
	selectedOwner: any;
	selectedClient: any;
	filteredClients: Observable<any[]>;
	lastFilterClient: string = '';
	currentAccount: any;

	selectedCampaign: any;
	filteredCampaigns: Observable<any[]>;
	lastFilterCampaign: string = '';


	formData: FormGroup;
	owners: any[] = [];
	defaultClients: any;
	defaultCampaigns: any;
	clients: any;
	campaigns: any;
	savingForm = false;

	isSave = false;
	successUrl: any;
	defaultHash:string = '';
	hashUsed:boolean;

	isSavingTag = false;

	creatingNewClient$: Subscription = null;
	creatingNewCampaign$: Subscription = null;
	

	constructor(
		public readonly urlShortenerCollectionService: UrlShortenerCollectionService,
		public readonly baseService: UrlShortenerCollectionService,
		private readonly urlShortenerTagService: UrlShortenerTagService,
		private readonly snackBar: MatSnackBar,
		private readonly authService: AuthService,
		private router: Router,
		public fb: FormBuilder,
		private readonly urlShortenerDomainService: UrlShortenerDomainService,
	  	public dialog: MatDialog,
		) {
		super();
	}
	ngOnDestroy(): void {
		if(this.refreshForm)
			this.refreshForm.unsubscribe();
	}
	ngOnInit(): void {
		this.setUpForm();
		if(this.refreshForm) {
			this.refreshForm.subscribe((result: INewUrlShortener) => {				
				this.loadedModel = {
					...result,
					id: 0,
					fullURL: '',
					description: '',
					redirectURL: '',
					hash: this.baseService.generateNewHash(5),
				}
				this.setUpForm();
			})
		}
	}
	

	displayTagFn(client: any | string): string | undefined {
		return client?.value || ""
	}

	async setUpForm() {
		if(this.loadedModel?.id > 0 || this.loadedModel?.tags?.length>0) { 
			this.clients = this.loadedModel?.tags?.filter((tag) => tag.key === 'client');
			this._client.setValue(this.loadedModel?.tags?.find((tag) => tag.key === 'client') || "")
			this.campaigns = this.loadedModel?.tags?.filter((tag) => tag.key === 'campaign');
			this._campaign.setValue(this.loadedModel?.tags?.find((tag) => tag.key === 'campaign') || "")
		}

		if(this.loadedModel?.id > 0) {
			this.selectedOwner = this.loadedModel.account?.name;
		}
		if(this.loadedModel?.id <=0 || this.loadedModel?.id === null) {
			if(this.loadedModel?.hash === null || this.loadedModel?.hash === "") {
				this.loadedModel.hash = this.baseService.generateNewHash(5);
			}
		}
		this.initForm()
		if((this.loadedModel?.id <= 0 || this.loadedModel?.id == null) && (!this.loadedModel.accountID || !this.loadedModel.ownerID)) {
			this.urlShortenerCollectionService.ownersName(this.authService.getProfileFromToken()?.email)
				.subscribe(((result: any) => {
					if (result !== undefined) {
						this.formData.controls['ownerID'].setValue(result.id);
						this.formData.controls['accountID'].setValue(result.id);
					}
				})
			);
		}
		this.urlShortenerDomainService.list(MakeSearchParams({
			limit: 100,
			active: "1",
		})).subscribe((result) => {
			this.domains = result.data
			this.formData.controls['domainName']
				.setValue(result.data?.find(d=>d.id === this.loadedModel.domainID)?.domain || "", { emitEvent: false });
		})
		
		this.formData.controls['domainID'].valueChanges.subscribe((value) => {
			this.formData.controls['hash'].updateValueAndValidity();
		})

		this.formData.controls['domainID'].valueChanges.subscribe((value) => {
			this.formData.controls['domainName']
				.setValue(this.domains?.find(d=>d.id === this.loadedModel.domainID)?.domain || "", { emitEvent: false });
		})

		this.filteredClients = this._client.valueChanges.pipe(
			startWith<string | any[]>(''),
			filter((value) => typeof value === 'string'),
			debounceTime(500),
			map((value) => (typeof value === 'string' ? value : '')),
			switchMap((filter) => {
				return this.urlShortenerTagService.searchClient(filter).pipe(
					tap((result) => {
						if(result.total>0) {
							this.clients = result?.data
						}
					})
				);
			}),
			map((filter) => filter.data)
		);
		

		this.filteredCampaigns = this._campaign.valueChanges.pipe(
			startWith<string | any[]>(''),
			filter((value) => typeof value === 'string'),
			debounceTime(500),
			map((value) => (typeof value === 'string' ? value : '')),	
			switchMap((filter) => {
				return this.urlShortenerTagService.searchCampaign(filter).pipe(
					tap((result) => {
						if(result.total>0) {
							this.campaigns = result?.data;
						}
					})
				);
			}),
			map((filter) => filter.data)
		);
	}

	

	replaceTag(key: string, newTag: ITag) {
		let existingTag = this.formData.controls['tags'].value || [];
		existingTag = existingTag.filter((tag) => tag.key !== key);
		existingTag.push(newTag)
		this.formData.controls['tags'].setValue(existingTag); 
	}

	selectCampaign($event: MatAutocompleteSelectedEvent) {
		const value = $event.option.value;
		this._campaign.setValue(value);
		this.formData.controls['campaignID'].setValue(value.id);
		this.replaceTag('campaign', value);
	}
	
	selectClient($event: MatAutocompleteSelectedEvent) {
		const value = $event.option.value;
		this._client.setValue(value);
		this.formData.controls['clientID'].setValue(value.id);
		this.replaceTag('client', value);
	}

	findOrCreateClient() {
		if(typeof this._client?.value === 'string') {
		  return this.urlShortenerTagService.findOrCreate('client', this._client.value)
				.pipe(
					catchError((err) => {
						this.snackBar.open('Unable to create new client. Please inform IT with screenshot', 'Close', {
							duration: 20000,
							horizontalPosition: 'center',
							verticalPosition: 'top'
						});
						captureException(err)
						return of(err)
					}),
					finalize(() => {
						this.isSavingTag = false;
					})
				)
		} else {
			return of(this._client.value)
		}
	}


	findOrCreateCampaign() {
		if(typeof this._campaign?.value === 'string') {
			return this.urlShortenerTagService.findOrCreate('campaign', this._campaign.value)
				.pipe(
					catchError((err) => {
						this.snackBar.open('Unable to create new campaign. Please inform IT with screenshot', 'Close', {
							duration: 20000,
							horizontalPosition: 'center',
							verticalPosition: 'top'
						});
						captureException(err)
						return of(err)
					}),
					finalize(() => {
						this.isSavingTag = false;
					})
				)
				
		} else {
			return of(this._campaign.value)
		}
	}

	ngOnChanges(changes: SimpleChanges): void {		
		if (changes.loadedModel) this.setUpForm();
	}

	copyUrl() {
		if (navigator.clipboard) {
			navigator.clipboard.writeText(this.successUrl).then(() => {

				this.snackBar.open('Short Url Link is copied', 'Close', {
					duration: 2000,
					horizontalPosition: 'center',
					verticalPosition: 'top'
				});
				this.router.navigate(["/short-url/dashboard"])
			}, (error) => {
				captureException(error)
			});
		} else {
			captureException('Browser do not support Clipboard API')
			this.snackBar.open('Browser do not support Clipboard API. Please contact IT.', 'Close', {
				duration: 2000,
				horizontalPosition: 'center',
				verticalPosition: 'top'
			});
		}
	}

	isValidForm() {
		return this.formData.valid && this._client.valid && this._campaign.valid;
	}

	save(): void {
		if(this.isValidForm()) {
			this.isSavingTag = true;
			forkJoin({
				client: this.findOrCreateClient(),
				campaign: this.findOrCreateCampaign()
			})
			.subscribe((result) => {
				this.formData.controls['clientID'].setValue(result.client.id);
				this.replaceTag('client', result.client);
				this._client.setValue(result.client);

				this.formData.controls['campaignID'].setValue(result.campaign.id);
				this.replaceTag('campaign', result.campaign);
				this._campaign.setValue(result.campaign);
				const formValue = this.formData.value

				if (formValue.id === 0) {
					this.urlShortenerCollectionService.save(formValue).subscribe((result: INewUrlShortener) => {
						this.isSavingTag = false
						this.snackBar.open('Short url successfully created', 'Close', {
							duration: 2000,
							horizontalPosition: 'center',
							verticalPosition: 'top'
						});
						this.formSaved.emit(result);
					})
				} else {
					this.urlShortenerCollectionService.update(formValue.id, formValue).subscribe((result: INewUrlShortener) => {
						this.isSavingTag = false
						this.snackBar.open('Short url successfully updated', 'Close', {
							duration: 2000,
							horizontalPosition: 'center',
							verticalPosition: 'top'
						});
						this.formSaved.emit(result);
					})
				}
			})
		}
	}

	generateURL(type:string){
		let dialogComponent;
		let isTel = false;
		if(type == 'tel'){
			isTel = true;
			dialogComponent  = TelephoneUrlGeneratorComponent;
		}else{
			isTel = false;
			dialogComponent  = WhatsappUrlGeneratorComponent;
		}

		const dialogRef = this.dialog.open(dialogComponent, {
			width: '700px',
			data:{}
		  });
		  dialogRef.afterClosed().subscribe(result => {

			if(result != ""){
				this.formData.get('redirectURL').setValue(result);
				if(isTel) {
					this.formData.get('redirectURL').setErrors(null)
				};
				this.generatedURL = true;
			}
		  });

	}

	clearGenerateURL(){
		this.formData.get('redirectURL').enable();
		this.formData.get('redirectURL').setValue('')
		this.formData.get('redirectURL').updateValueAndValidity();
		this.generatedURL = false;
	}


}
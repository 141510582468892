import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { FormDuplicateComponent } from '../../components/form-duplicate/form-duplicate.component';
import { IForm } from '../../interfaces/form';
import { ConsumerReportFormPageService } from '../../services/consumer-report-form-page.service';
import { ConsumerReportFormService } from '../../services/consumer-report-form.service';
import { Params } from '@angular/router';
import { ConfirmDeleteDialogComponent } from '../../components/confirm-delete-dialog/confirm-delete-dialog.component';
import { SurveyResultReportDialogComponent } from '../../components/survey-result-report-dialog/survey-result-report-dialog.component';
import { saveAs } from 'file-saver';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { mode } from 'mathjs';
import { E } from '@angular/cdk/keycodes';
import moment from 'moment';

@Component({
  selector: 'app-forms-index',
  templateUrl: './forms-index.component.html',
  styleUrls: ['./forms-index.component.scss']
})
export class FormsIndexComponent extends ListingComponent<IForm> implements OnInit {

  populateList<U extends HttpParams | Params>(params?: U, urlHistory?: boolean): void {
    this.commonPopulateList(this.makeSearchParams(params), urlHistory)
  }
  getExtraListingParams(): void {
    throw new Error('Method not implemented.');
  }

  formCols = [
    'status',
    'name',
    'originatedISOCountryCode',
    'period',
    'questionCount',
    'participantCount',
    'exportStatus',
    'exportDate',
    'action'
  ];

  statusColour = [
    'grey',
    'yellow',
    'green',
    'red'
  ]

  exportDataStatus = [
    {key:'new', color: 'blue', label: 'New'},
    {key:'newV2', color: 'blue', label: 'New'},
    {key:'processing', color: 'yellow', label: 'Processing'},
    {key:'failed', color: 'red', label: 'Failed'},
    {key:'success', color: 'green', label: 'Complete'}
  ]

  formList: IForm[]
  reCaching: boolean
  duplicating:boolean
  generatingParticipantSubmission:boolean
  formToDuplicate:any
  
  batchProcessIndex: number;

  duplicatingForm:boolean;
  duplicatingFormPage:boolean;
  duplicatingFormPageQuestions:boolean;
  duplicatingFormPageQuestionsAns:boolean;
  duplicatingFormPageQuestionsDependency:boolean;
  duplicatingFormPageQuestionsMatrics:boolean;
  uploadingTOS3:boolean;

  numberForm:number;
  numberFormPage:number;
  numberFormPageQuestions:number;
  numberFormPageQuestionsAns:number;
  numberFormPageQuestionsDependency:number;
  numberFormPageQuestionsMatrics:number;



  constructor(
    public override readonly baseService: ConsumerReportFormService,  
    private readonly consumerReportFormPageService : ConsumerReportFormPageService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    
  ) {
    super();

    this.duplicatingForm = false;
    this.duplicatingFormPage = false;
    this.duplicatingFormPageQuestions = false;
    this.duplicatingFormPageQuestionsAns = false;
    this.duplicatingFormPageQuestionsDependency = false;
    this.duplicatingFormPageQuestionsMatrics = false;
    this.uploadingTOS3 = false;

    this.numberForm = 1;
    this.numberFormPage = 0;
    this.numberFormPageQuestions = 0;
    this.numberFormPageQuestionsAns = 0;
    this.numberFormPageQuestionsDependency = 0;
    this.numberFormPageQuestionsMatrics = 0;
 
  }

  ngOnInit(): void {
    this.checkUserFormsStatus()
    this.generatingParticipantSubmission = false;
    this.reCaching = false
    this.duplicating = false
    this.batchProcessIndex = 0;
  }

  checkUserFormsStatus() {
    this.baseService.checkUserFormsStatus().subscribe(r => {
      this.populateList()
    })
  }

  recacheForm(id: number) {
    this.reCaching = true
    this.baseService.refreshCache(id.toString())
      .subscribe(m => {
        this.reCaching = false
      });
  }

  delForm(model: IForm) {

    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '500px',
      data: model.id,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(v => {
    if(!v){
      this.reCaching = true
      this.baseService.delete(model.id)
        .subscribe(() => {
          this.populateList()
          this.reCaching = false
        })
    }      
    });
  }

  generateSurveyResult(model:IForm){
  
    const dialogRef = this.dialog.open(SurveyResultReportDialogComponent, {
      width: '500px',
      data: model,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(v => {
    if(!v){
          this.populateList()
          this.reCaching = false
      }   
    });

  }

  generateParticipantSubmissionCSV(model:IForm){
    console.log(model);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {
        title : "Export participants submission",
        message: "After export we will send email that include the  exported files"
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(v => {
      if(v){
          this.baseService.generateParticipantsSubmission(model.uuid)
          .subscribe(response => {
            this.populateList();
          })
        
      }   
    });    
  }

  generateParticipantSubmissionCSVv2(model:IForm){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {
        title : "Export participants submission",
        message: "After export we will send email that include the  exported files"
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(v => {
      if(v){
          this.baseService.generateParticipantsSubmissionV2(model.uuid)
          .subscribe(response => {
            this.populateList();
          })
        
      }   
    });    
  }



  exportSubmission(model: IForm) {
    this.reCaching = true
    this.baseService.exportSubmission(model)
      .subscribe(blob => {
        this.reCaching = false
        // saveAs(blob, model.uuid+'.csv')
      })
  }

  duplicateForm(model:IForm){

    this.duplicatingForm = false;
    this.duplicatingFormPage = false;
    this.duplicatingFormPageQuestions = false;
    this.duplicatingFormPageQuestionsAns = false;
    this.duplicatingFormPageQuestionsDependency = false;
    this.duplicatingFormPageQuestionsMatrics = false;
    this.uploadingTOS3 = false;
    
    const dialogRef = this.dialog.open(FormDuplicateComponent, {
      width: '500px',
      data: model,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(v => {
      this.duplicatingForm = true;
      this.saveDuplicatedFormPage(v);
    });
  }

  saveDuplicatedFormPage(form:IForm){
    this.baseService.find(form.id.toString())
      .subscribe(res => {
        this.numberFormPage = res.formPage.length; 
        this.formToDuplicate = form
        this.duplicating = true
        this.consumerReportFormPageService.saveDuplicatedFormPage(form)
        .subscribe(response=>{
          this.duplicatingFormPage = true;
          this.saveDuplicatedFormQuestion(response.newForm,response.oldForm);
        },(error:any) => {
          console.log("error",error);
          this.duplicating = false;
        })

      });
  }

  async processbatchSavingFormAnsQuestion(newFormAns:any, newFormAnsTotal:number, _index:number){
    
    if(this.batchProcessIndex == newFormAnsTotal) return true;
    
    if(this.batchProcessIndex != newFormAnsTotal){
      let _ans = [];
      let remainingIndex = newFormAnsTotal - _index;
      
      const currentIndex = _index
      
      if(remainingIndex < 200 && remainingIndex >= 0){
          let remaingAns = []
          
            for (let r = this.batchProcessIndex ; r <= newFormAnsTotal ; r++) {
              remaingAns.push(newFormAns[r])
            }
            this.batchProcessIndex = this.batchProcessIndex + remainingIndex;
              let objFormQuestionAns = {
                formQuestionAns: JSON.stringify(remaingAns)
              }
           
               await this.saveFormAnsQuestion(objFormQuestionAns)
               console.log("End")
            
              _ans = [];
              remaingAns = [];
              this.processbatchSavingFormAnsQuestion(newFormAns, newFormAnsTotal, this.batchProcessIndex)
             
            
      }else{
        for (let i = this.batchProcessIndex; i < newFormAnsTotal; i++) {
            if(this.batchProcessIndex > newFormAnsTotal) return true;
            if(this.batchProcessIndex !== newFormAnsTotal){
              _ans.push(newFormAns[i]);

              if(_ans.length === 200){
             
                let objFormQuestionAns = {
                  formQuestionAns: JSON.stringify(_ans)
                }

                await this.saveFormAnsQuestion(objFormQuestionAns)
                
                this.batchProcessIndex = this.batchProcessIndex + 200
                _ans = [];

                if(this.batchProcessIndex <= newFormAnsTotal){
                  this.processbatchSavingFormAnsQuestion(newFormAns, newFormAnsTotal, this.batchProcessIndex)
                }
              }
            }
        }
      }

    }
    
  }

  saveFormAnsQuestion(objFormQuestionAns){
    return new Promise(resolve =>{
      this.consumerReportFormPageService.saveFormQuestionAns(objFormQuestionAns)
      .subscribe(response=>{
        resolve(response);
      })
    });
  }

  saveFormQuestionAnsDependency(objFormQuestionAnsDependency){
    return new Promise(resolve =>{
      this.consumerReportFormPageService.saveFormQuestionDependency(objFormQuestionAnsDependency)
      .subscribe(response=>{
        resolve(response);
      })
    });
  }

  saveFormQuestionMatrics(objFormQuestionMatrics){
    return new Promise(resolve =>{
      this.consumerReportFormPageService.saveFormQuestionMatric(objFormQuestionMatrics)
      .subscribe(response=>{
        resolve(response);
      })
    });
  }

  
  saveDuplicatedFormQuestion(newform:any, oldForm:any){
    let questionList = [];
    Promise.all(oldForm.formPage.map(oFp=>{
      questionList.push(...oFp.formQuestion)
    })).then(_res=>{
      this.numberFormPageQuestions = questionList.length;
    })

    let newFormAns =[];
    let newFormAnsDependencies =[];
    let newFormAnsMatrics =[];

    let total = 0;
    let dataObj = {
      newFormID: newform.id,
      oldFormID: oldForm.id
    }

    this.consumerReportFormPageService.saveDuplicatedFormQuestion(dataObj)
    .subscribe(async response=>{
      this.duplicatingFormPageQuestions = true;
      let oldfFormQuestionMatricsTotal = response.oldfFormQuestionMatrics.length;
      let oldfFormQuestionAnsDependencyTotal = response.oldfFormQuestionAnsDependency.length;

      let oldFormData  = response.oldForm;
      let updatedNewFormData = response.newForm;
      Promise.all(
        oldFormData.formPage.map(rFP => {
          if(rFP.formQuestion.length > 0){
            rFP.formQuestion.map(fq=>{
              
              //get the same FORM PAGE with old and new   
              let nFIndex = updatedNewFormData.formPage.findIndex(nFP => nFP.slug.toLowerCase() === rFP.slug.toLowerCase() )

              if(nFIndex !== -1 && updatedNewFormData.formPage[nFIndex].formQuestion.length > 0){

                //get the same FORM QUESTION with old and new   
                let nFqIndex =  updatedNewFormData.formPage[nFIndex].formQuestion.findIndex(nFQ => nFQ.label.toLowerCase() === fq.label.toLowerCase())
          
                if(nFqIndex !== -1){

                  let obj = {
                    newFormID: newform.id,
                    newFormPageID: updatedNewFormData.formPage[nFIndex].id,
                    newFormQuestionID: updatedNewFormData.formPage[nFIndex].formQuestion[nFqIndex].id,
                    newFormQuestionLabel : updatedNewFormData.formPage[nFIndex].formQuestion[nFqIndex].label,
                    questionID: updatedNewFormData.formPage[nFIndex].formQuestion[nFqIndex].questionID,
                    oldFormID: oldForm.id,
                    oldFormPageID: rFP.id,
                    oldFormQuestionID: fq.id,
                    oldFormQuestionLabel : fq.label,
                    oldFormQuestionAnswerDependsID: null,
                    oldFormQuestionDependsID: null,
                  }

                  if(fq.answers.length > 0) {
                    for(let ans of fq.answers){
                      ans['questionID'] = '';
                      delete ans.uuid;
                      ans.id = null

                      ans.formQuestionID = obj.newFormQuestionID;
                      ans.questionID = obj.questionID;
                         
                      newFormAns.push({
                        formId: obj.newFormID,
                        formPage: obj.newFormPageID, 
                        questionLabel : obj.newFormQuestionLabel,
                        formQuesiontID :obj.newFormQuestionID,
                        questionID : obj.questionID,
                      })
                    }
                    
                  }else{
                    newFormAns.push({
                      formId: obj.newFormID,
                      formPage: obj.newFormPageID, 
                      questionLabel : obj.newFormQuestionLabel,
                      formQuesiontID :obj.newFormQuestionID,
                      questionID : obj.questionID,
                    })
                  }

                  if(fq.dependencies.length > 0){
                    for(let _oldDependency of fq.dependencies){
                      //DEPENDE FROM other FORM QUESTION
                      delete _oldDependency.formQuestionDepends.uuid;
                      _oldDependency.formQuestionDepends.id = null;
                      _oldDependency.formQuestionDepends.formID = obj.newFormID;
                      _oldDependency.formQuestionDepends.formPageID = obj.newFormPageID;

                      //DEPENDE FROM other FORM QUESTION ANS
                      delete _oldDependency.formQuestionAnswerDepends.uuid;
                      _oldDependency.formQuestionAnswerDepends.id = null;
                      _oldDependency.formQuestionAnswerDepends.formQuestionID = obj.newFormQuestionID;

                      _oldDependency.id = null;
                      _oldDependency.formQuestionID = obj.newFormQuestionID;
                      // _oldDependency.formQuestionAnswerDependsID = null;
                      // _oldDependency.formQuestionDependsID = null;
                      
                      
                      let objDependency = {
                        formQuestionDependecy: _oldDependency.formQuestionDepends,
                        formQuestionAnsDependecy: _oldDependency.formQuestionAnswerDepends,
                        baseFormQuestionDependency : _oldDependency
                      } 

                      newFormAnsDependencies.push(objDependency);
                    
                    }
                  }

                  if(fq.matrices.length > 0){
                      for(let _matric of fq.matrices){
                        _matric['questionID'] = '';
                        _matric.formQuestionID = obj.newFormQuestionID;
                        _matric.questionID = obj.questionID;
                        newFormAnsMatrics.push(_matric)
                      }
                  } 
                
                }
              }
            })
          }
        })).then(res =>{
          newFormAns =  [...new Map(newFormAns.map(item => [item['questionID'], item])).values()],

          this.numberFormPageQuestionsAns = newFormAns.length;
          this.numberFormPageQuestionsDependency = newFormAnsDependencies.length;
          this.numberFormPageQuestionsMatrics = newFormAnsMatrics.length;

          let result = this.processbatchSavingFormAnsQuestion(newFormAns, newFormAns.length, 0)
          result.then(_response =>{
            this.duplicatingFormPageQuestionsAns = true;
  
              let objNewFormAnsDependency = {
                newFormAnsDependency : newFormAnsDependencies,
                totalNewFormAnsDependencyToCopy : newFormAnsDependencies.length,
              }

              this.saveFormQuestionAnsDependency(objNewFormAnsDependency)
              .then((saveFormQuestionAnsDependencyResult:any) =>{
                if(!saveFormQuestionAnsDependencyResult.error){
                  this.duplicatingFormPageQuestionsDependency = true;
                
                  
                    newFormAnsMatrics = newFormAnsMatrics.filter((v,i,a)=>a.findIndex(v2=>['formQuestionID','questionMatricID'].every(k=>v2[k] ===v[k]))===i)

                    let objNewFormAnsMatrics = {
                      newFormAnsMatric : newFormAnsMatrics,
                      totalNewFormAnsMatricToCopy : newFormAnsMatrics.length,
                      newFormDataID: updatedNewFormData.id
                    }

                    this.saveFormQuestionMatrics(objNewFormAnsMatrics)
                    .then((saveFormQuestionAnsMatricResult:any)=>{
             
                      if(!saveFormQuestionAnsMatricResult.error){   
                        this.duplicatingFormPageQuestionsMatrics = true;
                        this.filterAndUploadtoS3Buckets(updatedNewFormData.id);
                        
                      }else{
                        this.populateList();
                    
                        this.duplicating = false;
                    
                        this.snackBar.open( 'Error: Failed to duplicate '+ this.formToDuplicate.name, 'Close', {
                          duration: 20000,
                          horizontalPosition: 'center',
                          verticalPosition: 'top'
                        });
                      }
                  

                    })

                  
                }else{
                  this.populateList();
                    
                  this.duplicating = false;
              
                  this.snackBar.open( 'Error: Failed to duplicate '+ this.formToDuplicate.name, 'Close', {
                    duration: 20000,
                    horizontalPosition: 'center',
                    verticalPosition: 'top'
                  });
                }
              })
            

           
            
          })

          

        })
      
      },(error:any) => {
      this.duplicating = false;
      this.snackBar.open('Error: Failed to duplicate '+ this.formToDuplicate.name, 'Close', {
        duration: 20000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      console.log("error",error);
      this.reCaching = false;
    })

  }

  filterAndUploadtoS3Buckets(formID){

    this.consumerReportFormPageService.uploadFileTos3Buckets(formID)
    .subscribe(res=>{
      if(!res.error){
        this.uploadingTOS3 = true;                        
        this.populateList();
                        
        this.snackBar.open( this.formToDuplicate.name+ ' has been duplicated SUCCESSFULLY!', 'Close', {
          duration: 20000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
  
        setTimeout(() => {
          this.duplicating = false;
        }, 5000)
      }else{
        this.populateList();
                    
        this.duplicating = false;
    
        this.snackBar.open( 'Error: Failed to duplicate '+ this.formToDuplicate.name, 'Close', {
          duration: 20000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }
      
    })
            
  
  }

  getExportStatus(status:string){
    return this.exportDataStatus.find(ex => ex.key == status);    
  }

  getCSVFile(model) {
    this.generatingParticipantSubmission = true;
    this.baseService.exportParticipantSubmission(model.fileName)
      .subscribe(blob => {
        this.generatingParticipantSubmission = false;
        saveAs(blob, model.fileName)
      })
   }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Observable } from 'rxjs';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { ICommonObject } from 'src/app/shared/interfaces/ICommonObject';
import { ICRUDPath } from 'src/app/shared/interfaces/iCRUDPath.interface';
import { environment } from 'src/environments/environment';
import { IUser } from '../interfaces/iuser';
import { RoleService } from './role.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends CRUDService<IUser> {

  constructor(
    public readonly httpClient: HttpClient, 
    public readonly activatedRoute: ActivatedRoute, 
    public readonly router: Router, 
    public readonly fb: FormBuilder,
    private readonly roleService: RoleService
  ) {
    super();
  }
  apiPath = environment.securityGuard.userBase;
  environmentCRUDPath = environment.securityGuard;

  list(params?: HttpParams): Observable<ICommonListingResponse<IUser[]>> {
    return this.httpList(params);
  }
  get(id: string | number): Observable<IUser> {
    return this.httpGet(id.toString());
  }
  delete(id: string | number): Observable<IUser> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: IUser): Observable<IUser> {
    throw new Error('Method not implemented.');
  }
  create(model: IUser): Observable<IUser> {
    throw new Error('Method not implemented.');
  }
  makeFormGroup(loadedModel?: IUser) {
    return this.fb.group({
      firstName: [loadedModel?.firstName || "", [Validators.maxLength(125)]],
      lastName: [loadedModel?.lastName || "", [Validators.maxLength(125)]],
      displayName: [loadedModel?.displayName || "", [Validators.maxLength(512)]],
      email: [loadedModel?.email || "", [Validators.maxLength(1024), RxwebValidators.email()]],
      roles: this.fb.array(this.roleService.makeFormArray(loadedModel?.roles || [])),
    });
  }

  makeSearchListGroup(searchParams?: ICommonObject) {
    return this.fb.group({
      searchText: [searchParams?.searchText || ""],
			roles: [searchParams?.roles || []],
      cognitoStatus: [searchParams?.cognitoStatus || []],
    })
  }

  syncCognitoUserByEmail(email: string) {
    return this.httpClient.post<ICommonListingResponse<IUser>>(`${this.fullAPIPath}/sync-cognito-user`, {
      emails: [email]
    });
  }
}

import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDeleteDialogComponent } from 'src/app/consumer-report/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { TelegramCollectionService } from '../../services/telegram-collection.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { catchError, firstValueFrom, map, merge, startWith, switchMap } from 'rxjs';
import { UrlShortenerCollectionService } from 'src/app/url-shorterner/services/url-shortener-collection.service';
import { IMessageResult } from '../../interfaces/IMessageResult';

export interface Element {
    select: boolean;
    createdAt?: Date;
    campaign?: number;
    client?: number;
    description?: string;
    shortUrl?: string;
    highlighted?: boolean;
}

@Component({
    selector: 'app-link-click-data',
    templateUrl: './link-click-data.component.html',
    styleUrls: ['./link-click-data.component.scss']
})


export class LinkClickDataComponent implements OnInit, AfterViewInit {


    displayedColumns: string[] = ['select', 'createdAt', 'campaign', 'client', 'totalRequested', 'totalUniqueRequested', 'description', 'shortUrl'];
    linkDataSource: MatTableDataSource<any>;

    selectedShortUrls: any[] = [];
    resultsLength:any
    pageSize:number = 10
    trimmedLongMessage:string = '';
    searchText: string = '';
    withTGDesc:boolean = true;

    connectedLinks:any[] = [];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    isLoadingResults = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: IMessageResult,
        public dialogRef: MatDialogRef<ConfirmDeleteDialogComponent>,
        private readonly snackBar: MatSnackBar,
        private readonly telegramCollectionService: TelegramCollectionService,
        private readonly urlShortenerService: UrlShortenerCollectionService,
    ) {
    }

    ngOnInit(): void {
        this.trimmedLongMessage = this.data.longMessage.substring(0,200) + "...";        
    }

    selectedRow(element:any) {
        
        
        if(element.checked){
            this.isLoadingResults = true;
            let data = {
                messageID:this.data.id,
                link: element.source.value.fullURL,
                addedDate: new Date(),
                shortUrlID: element.source.value.id
            }      


            this.telegramCollectionService.updateSocialEngagementLinks(data)
            .subscribe((res:any)=>{
                
                this.snackBar.open(res.message, 'Close', {
                    duration: 2000,
                    horizontalPosition: 'center',
                    verticalPosition: 'top'
                });
            
                if(res.status == 200){
                      
                    this.connectedLinks.push(element.source.value);
                    const indexToRemove = this.linkDataSource.data.findIndex(item => item.id === element.source.value.id);

                    if (indexToRemove > -1) {
                        this.linkDataSource.data.splice(indexToRemove, 1);
                        this.linkDataSource._updateChangeSubscription();
                    }
                }

                this.isLoadingResults = false;
            })

        }else{
            this.selectedShortUrls = this.selectedShortUrls.filter(ss=> ss.id != element.value.id)
            
        }
       
    }

    highlight(element: Element) {
        element.highlighted = !element.highlighted;
    }

    applyFilter(event: any) {
        this.withTGDesc = false;
        this.searchText = event.target.value;
        this.displayAllShortUrl();

    }

    resetFilter(event:any){
        if(event.target.value ==  ''){
            this.searchText = ''
            this.withTGDesc = true;
            this.displayAllShortUrl();
        }
    }

    unlinkMessage(data){

        this.isLoadingResults = true;
        let params = {
            id: data.id,
            messageID : this.data.id
        }
        this.telegramCollectionService.unlinkClick(params)
        .subscribe((response : any)=>{

            this.snackBar.open(response.message, 'Close', {
                duration: 2000,
                horizontalPosition: 'center',
                verticalPosition: 'top'
              });


            if(response.status == 200){
                this.connectedLinks = this.connectedLinks.filter(cl => cl.id != data.id)
                this.linkDataSource.data.push(data)

                this.linkDataSource.data.sort((a:any, b:any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
                this.linkDataSource._updateChangeSubscription();
            }
            this.isLoadingResults = false;
        })
    }

    ngAfterViewInit() {
        
        if(this.data.messageLinks.length > 0){
            this.withTGDesc = false;
            let messageLinkIds = this.data.messageLinks.map( a => a.shortUrlID);
            messageLinkIds = messageLinkIds.filter(ml => ml != null);
            this.displayShortUrlByID(messageLinkIds)
            this.withTGDesc = true;
            this.displayAllShortUrl();
        }else{
            this.withTGDesc = true;
            this.displayAllShortUrl();
        }
      
    }

    async displayShortUrlByID(shortUrlIDs){

        this.connectedLinks = [];
        this.isLoadingResults = true;
         for(let id of shortUrlIDs){
            let response = await firstValueFrom(this.urlShortenerService.getUser(id))
            this.connectedLinks.push(response);
         }
        this.isLoadingResults = false;

        
    }

    removeAlreadyConnectedUrl(tableSource, messageLinkIds) {

        if(messageLinkIds.length > 0){
            const idsToRemove = messageLinkIds.map(obj =>  {
                if(obj.shortUrlID !== null)  return  Number(obj.id);
            })
            return tableSource.filter(obj => !idsToRemove.includes(obj.id));
        }else{
            return tableSource;
        }
    }
    
 
    displayAllShortUrl(){
        // If the user changes the sort order, reset back to the first page.
        this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(
            startWith({}),
            switchMap(() => {
                this.isLoadingResults = true;
                return this.telegramCollectionService.getShortUrlClicksData(
                this.paginator.pageIndex,
                this.pageSize,
                this.searchText,
                this.withTGDesc
                )
            }),
            map(response => {
                // Flip flag to show that loading has finished.
                this.isLoadingResults = false;

                if (!response) {
                return [];
                }

                // Only refresh the result length if there is new data. In case of rate
                // limit errors, we do not want to reset the paginator to zero, as that
                // would prevent users from re-triggering requests.
                this.resultsLength = response.total;
                return response.data;
            }),
            )
            .subscribe(data => {
                this.linkDataSource = new MatTableDataSource(this.removeAlreadyConnectedUrl(data, this.connectedLinks)) 
            });
    }

}



import { NgModule } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HttpClientModule } from '@angular/common/http';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { RouterModule } from '@angular/router';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SalonFinderRouteModule } from './salon-finder.routes';
import { AdvertiserIndexComponent } from './pages/advertiser-index/advertiser-index.component';
import { AdvertiserFormComponent } from './components/advertiser-form/advertiser-form.component';
import { AdvertiserCreateComponent } from './pages/advertiser-create/advertiser-create.component';
import { AdvertiserUpdateComponent } from './pages/advertiser-update/advertiser-update.component';
import { CollectionIndexComponent } from './pages/collection-index/collection-index.component';
import { CollectionFormComponent } from './components/collection-form/collection-form.component';
import { CollectionCreateComponent } from './pages/collection-create/collection-create.component';
import { CollectionUpdateComponent } from './pages/collection-update/collection-update.component';
import { CollectionAddServiceComponent } from './pages/collection-add-service/collection-add-service.component';
import { CollectionUpdateServiceComponent } from './pages/collection-update-service/collection-update-service.component';
import { OutletIndexComponent } from './pages/outlet-index/outlet-index.component';
import { OutletFormComponent } from './components/outlet-form/outlet-form.component';
import { OutletUpdateComponent } from './pages/outlet-update/outlet-update.component';
import { OutletCreateComponent } from './pages/outlet-create/outlet-create.component';
import { ServiceIndexComponent } from './pages/service-index/service-index.component';
import { ServiceFormComponent } from './components/service-form/service-form.component';
import { ServiceCreateComponent } from './pages/service-create/service-create.component';
import { ServiceUpdateComponent } from './pages/service-update/service-update.component';
import { PromoIndexComponent } from './pages/promo-index/promo-index.component';
import { PromoFormComponent } from './components/promo-form/promo-form.component';
import { PromoCreateComponent } from './pages/promo-create/promo-create.component';
import { PromoUpdateComponent } from './pages/promo-update/promo-update.component';
import { FinanceIndexComponent } from './pages/finance-index/finance-index.component';
import { FinancePurchasesIndexComponent } from './pages/finance-purchases-index/finance-purchases-index.component';
import { FinanceRedemptionIndexComponent } from './pages/finance-redemption-index/finance-redemption-index.component';
import { FinancePaymentIndexComponent } from './pages/finance-payment-index/finance-payment-index.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { SharedModule } from '../shared/shared.module';
import { OrderVoucherSendEmailDialogComponent } from './components/order-voucher-send-email-dialog/order-voucher-send-email-dialog.component';
import { OrderVoucherExtendExpiryDateDialogComponent } from './components/order-voucher-extend-expiry-date-dialog/order-voucher-extend-expiry-date-dialog.component';
import { OrderVoucherRedeemDialogComponent } from './components/order-voucher-redeem-dialog/order-voucher-redeem-dialog.component';
import { OrderVoucherPaymentDialogComponent } from './components/order-voucher-payment-dialog/order-voucher-payment-dialog.component';
import { EmailNotificationIndexComponent } from './pages/email-notification-index/email-notification-index.component';
import { EmailNotificationUpdateComponent } from './pages/email-notification-update/email-notification-update.component';
import { EmailNotificationCreateComponent } from './pages/email-notification-create/email-notification-create.component';
import { EmailNotificationFormComponent } from './components/email-notification-form/email-notification-form.component';
import { MatLuxonDateModule, MAT_LUXON_DATE_FORMATS } from '@angular/material-luxon-adapter';

@NgModule({
  declarations: [
    AdvertiserIndexComponent,
    AdvertiserFormComponent,
    AdvertiserCreateComponent,
    AdvertiserUpdateComponent,
    CollectionIndexComponent,
    CollectionFormComponent,
    CollectionCreateComponent,
    CollectionUpdateComponent,
    CollectionAddServiceComponent,
    CollectionUpdateServiceComponent,
    OutletIndexComponent,
    OutletFormComponent,
    OutletCreateComponent,
    OutletUpdateComponent,
    ServiceIndexComponent,
    ServiceCreateComponent,
    ServiceUpdateComponent,
    ServiceFormComponent,
    PromoIndexComponent,
    PromoFormComponent,
    PromoCreateComponent,
    PromoUpdateComponent,
    FinanceIndexComponent,
    FinancePurchasesIndexComponent,
    FinanceRedemptionIndexComponent,
    FinancePaymentIndexComponent,
    OrderVoucherSendEmailDialogComponent,
    OrderVoucherExtendExpiryDateDialogComponent,
    OrderVoucherRedeemDialogComponent,
    OrderVoucherPaymentDialogComponent,
    EmailNotificationIndexComponent,
    EmailNotificationUpdateComponent,
    EmailNotificationCreateComponent,
    EmailNotificationFormComponent
  ],
  imports: [
    SharedModule,
    SalonFinderRouteModule,
    BrowserAnimationsModule,
    RxReactiveFormsModule,
    DragDropModule,
    MatExpansionModule,
    CdkStepperModule,
    MatProgressSpinnerModule,
    RouterModule,
    NgxMaterialTimepickerModule,
    HttpClientModule,
    ScrollingModule,
    MatLuxonDateModule
  ],
  providers: [
    {
      provide: MAT_LUXON_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['D', 'DD']
        },
        display: {
          dateInput: 'DD',
          monthYearLabel: 'MMM yyyy',
          dateA11yLabel: 'DDD',
          monthYearA11yLabel: 'MMMM yyyy'
        }
      }
    },
  ]
})
export class SalonFinderModule { }

<div class="row mb-3">
  <div class="col-12 text-left">
    <h1>Form's "Others" field approval & merging</h1>
  </div>
</div>
<div>
  <mat-card *ngFor="let questionTable of questionTableListing" class="mat-elevation-z8 mb-3">
    <mat-card-header>
      <mat-card-title>{{ questionTable.question.label }} ({{ questionTable.question.formDefinedValues.length }})</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <table mat-table [dataSource]="questionTable.question.formDefinedValues" class="mat-elevation-z8" style="width: 100%">

        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef> Value </th>
          <td mat-cell *matCellDef="let element"> {{element.value}} </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Duplicate </th>
          <td mat-cell *matCellDef="let element"> 
            <a mat-icon-button color="primary" (click)="duplicateValue(element.related, questionTable.question.id)">
              <mat-icon>content_copy</mat-icon>
            </a> 
          </td>
        </ng-container>
        <ng-container matColumnDef="count">
          <th mat-header-cell *matHeaderCellDef> Count </th>
          <td mat-cell *matCellDef="let element"> {{element.count}} </td>
        </ng-container>
        <ng-container matColumnDef="selection">
          <th mat-header-cell *matHeaderCellDef> Select </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox  (click)="$event.stopPropagation()"
            (change)="$event ? questionTable.selection.toggle(element) : null"
            [checked]="questionTable.selection.isSelected(element)"></mat-checkbox>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="questionTable.selection.toggle(row)"></tr>
      </table>
    </mat-card-content>
    <mat-card-actions>
      <button mat-raised-button color="primary" class="m-2" (click)="addToList(questionTable.question)">Add to List</button>
    </mat-card-actions>
  </mat-card>
</div>



<div class="row mb-3">
  <div class="col-12 text-right">
    <button mat-raised-button color="primary" (click)="back()">Back</button>
  </div>
</div>


<div>
  <div class="row" >
    <div class="col-4 text-left">
      <mat-form-field appearance="fill" class="col-12">
        <mat-label>Period</mat-label>
        <mat-select (selectionChange)="selectedPeriod($event)" [(value)]="selected">
          <mat-option *ngFor="let period of periods" [value]="period.value">
            {{period.viewValue}}
          </mat-option>
        </mat-select>
      </mat-form-field> 
    </div>
    <div class="col-4 text-center">
      <mat-button-toggle-group  *ngIf="checkToDisplayRange()" [value]="selectedRange" (change)="onChangeRange($event)" class="btn-toggle-byweek-bymonth" name="fontStyle" aria-label="Font Style">
        <mat-button-toggle [value]="toggleEnumRange.Daily">Daily</mat-button-toggle>
        <mat-button-toggle [value]="toggleEnumRange.Weekly">Weekly</mat-button-toggle>
        <mat-button-toggle [value]="toggleEnumRange.Monthly">Monthly</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="col-4 text-right">
      <button mat-raised-button class="export-btn" color="primary" (click)="export()">Export</button>
    </div>
  </div>
  <!-- CUSTOM DATE RANGE -->
  <div class="row" *ngIf="showCustomDateRange">
      <div class="col-4">
        <mat-form-field  appearance="fill" class="col-12">
          <mat-date-range-input [formGroup]="range" [rangePicker]="pickerRange" [dateFilter]="filterFutureDates">
            <input matStartDate formControlName="startRange"  placeholder="Start date">
            <input matEndDate formControlName="endRange"  placeholder="End date" (dateChange)="onFetchData()">
          </mat-date-range-input>
          <mat-hint>DD MMM YYYY – DD MMM YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="pickerRange"></mat-datepicker-toggle>
          <mat-date-range-picker #pickerRange></mat-date-range-picker>

          <mat-error *ngIf="range.controls.startRange.hasError('matStartDateInvalid')">Invalid start
              date</mat-error>
          <mat-error *ngIf="range.controls.endRange.hasError('matEndDateInvalid')">Invalid end
              date</mat-error>
          </mat-form-field>
      </div>
      <div class="col-4"></div>
      <div class="col-4"></div>
  </div>


  <div class="row">
    <div class="col-10">
      <mat-grid-list cols="2" rowHeight="50px">
        <mat-grid-tile>
          <div class="grid-tile-content"> <b>Client Name: </b> 
            <span *ngIf="urlInfo?.tags?.length > 0">
              <span *ngFor="let e of urlInfo.tags">
                <span *ngIf="e.key === 'client'"> 
                    {{ e.value }}
                </span>  
              </span>  
            </span>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
        <div class="grid-tile-content"> <b>Long URL: </b>  
          <a href="{{ urlInfo?.redirectURL }}" target="_blank"> <span>{{ urlInfo?.redirectURL }}</span> </a>  </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="grid-tile-content"> <b>Campaign Name: </b> 
            <span *ngIf="urlInfo?.tags?.length > 0">
              <span *ngFor="let e of urlInfo.tags">
                <span *ngIf="e.key === 'campaign'"> 
                    {{ e.value }}
                </span>  
              </span>
            </span>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
        <div class="grid-tile-content"> <b>Short URL: </b> <a href="https://{{ urlInfo?.fullURL }}" target="_blank"> <span>https://{{ urlInfo?.fullURL }}</span> </a> </div>
        </mat-grid-tile>
        <mat-grid-tile>
        <div class="grid-tile-content"> <b>Description: </b> {{urlInfo?.description}} </div>
        </mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
      
      </mat-grid-list>
    </div>
    <div class="col-2"></div>
  </div>

  <div class="row" style="display: block;" class="mb-3" *ngIf="!generatingChart">
    <canvas baseChart class="canvas" height="600"
      [type]="'line'"
      [data]="lineChartData"
      [options]="lineChartOptions"
      [legend]="lineChartLegend">
    </canvas>
  </div>


<div class="row text-center mb-4" *ngIf="generatingChart">
  <div class="loading-container">
      <mat-spinner diameter="50" color="accent"> </mat-spinner> 
  </div>
  <br>
  <mat-label>Generating Chart ...</mat-label>
</div> 

  <div class="row mb-3">
    <div class="col-6">
      <mat-card>
        <mat-card-header>  
          <mat-card-title>Referral Source</mat-card-title>
        </mat-card-header>
        <table  style="width:100%">
          <thead>
          <tr>
            <th style="width:70%">URL</th>
            <th>All Clicks</th>
            <th>Unique Clicks</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let data of urlDetails?.sortedReferrerObj">
            <td>{{data.referrer}}</td>
            <td>{{data.numberOfOccurences}}</td>
            <td>{{data.numberOfUniqueOccurences}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td><b>Total</b></td>
            <td>{{urlDetails?.numberOfReferrers}}</td>
            <td>{{urlDetails?.numberOfUniqueReferrers}}</td>
          </tr>
        </tfoot>
        </table>
      </mat-card>
    </div>
    <div class="col-6">

      <mat-card>
        <mat-card-header>
          <mat-card-title>Devices</mat-card-title>
        </mat-card-header>
        <table  style="width:100%">
          <thead>
            <tr>
              <th style="width:70%">URL</th>
              <th>All Clicks</th>
              <th>Unique Clicks</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of urlDetails?.sortedDeviceObj">
              <td>{{data.device}}</td>
              <td>{{data.numberOfOccurences}}</td>
              <td>{{data.numberOfUniqueOccurences}}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td><b>Total</b></td>
              <td>{{urlDetails?.numberOfDevices}}</td>
              <td>{{urlDetails?.numberOfUniqueDevices}}</td>
            </tr>
          </tfoot>
        </table>
      </mat-card>

    </div>
  </div>

  <div class="row mb-3">
    <div class="col-6">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Browsers</mat-card-title>
        </mat-card-header>
        <table  style="width:100%">
          <thead>
          <tr>
            <th style="width:70%">URL</th>
            <th>All Clicks</th>
            <th>Unique Clicks</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let data of urlDetails?.sortedBrowserObj">
            <td>{{data.browser}}</td>
            <td>{{data.numberOfOccurences}}</td>
            <td>{{data.numberOfUniqueOccurences}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td><b>Total</b></td>
            <td>{{urlDetails?.numberOfBrowsers}}</td>
            <td>{{urlDetails?.numberOfUniqueBrowsers}}</td>
          </tr>
        </tfoot>
        </table>
      </mat-card>
    </div>
    <div class="col-6">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Countries</mat-card-title>
        </mat-card-header>
        <table  style="width:100%">
          <thead>
            <tr>
              <th style="width:70%">URL</th>
              <th>All Clicks</th>
              <th>Unique Clicks</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of urlDetails?.sortedCountryObj">
              <td>{{data.country}}</td>
              <td>{{data.numberOfOccurences}}</td>
              <td>{{data.numberOfUniqueOccurences}}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td><b>Total</b></td>
              <td>{{urlDetails?.numberOfCountries}}</td>
              <td>{{urlDetails?.numberOfUniqueCountries}}</td>
            </tr>
          </tfoot>
        </table>
      </mat-card>

    </div>
  </div>
</div>
import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { ICRUDPath } from 'src/app/shared/interfaces/iCRUDPath.interface';
import { environment } from 'src/environments/environment';
import { IOrder } from '../interfaces/IOrder';
import { IOrderVoucherListStats } from '../interfaces/iorder-voucher-list-stats';
import { IOrderVoucherRedeem } from '../interfaces/iorder-voucher-redeem';
import { IOrderPayment } from '../interfaces/IOrderPayment';
import { IOrderVoucher } from '../interfaces/IOrderVoucher';
import { IRedeemedVoucher } from '../interfaces/iredeemed-voucher';

@Injectable({
  providedIn: 'root'
})
export class SalonFinderOrderVoucherService extends CRUDService<IOrderVoucher> {
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }
  router: Router;
  activatedRoute: ActivatedRoute;

  constructor(public readonly httpClient: HttpClient) {
    super();
  }
  
  apiPath = environment.salonFinder.orderVoucherPath  
  environmentCRUDPath = environment.salonFinder
  list(params?: HttpParams): Observable<ICommonListingResponse<IOrderVoucher[]>> {
    return this.httpList(params)
  }
  get(id: string | number): Observable<IOrderVoucher> {
    throw new Error('Method not implemented.');
  }
  delete(id: string | number): Observable<IOrderVoucher> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: IOrderVoucher): Observable<IOrderVoucher> {
    throw new Error('Method not implemented.');
  }
  create(model: IOrderVoucher): Observable<IOrderVoucher> {
    throw new Error('Method not implemented.');
  }
  makeFormGroup(loadedModel?: IOrderVoucher) {
    throw new Error('Method not implemented.');
  }

  getListStats(params?: HttpParams) {
    return this.httpClient.get<IOrderVoucherListStats>(this.getAPIURL(environment.salonFinder.orderVoucherListStats), {params})
  }

  redeemVoucher(body: IOrderVoucherRedeem) {
    return this.httpClient.post<IOrderVoucher>(this.getAPIURL(environment.salonFinder.orderVoucherRedeem), body)
  }

  emailVoucher(model: IOrderVoucher, email?: string, name?: string) {
    return this.httpClient.post<IOrderVoucher>(this.getAPIURL(environment.salonFinder.orderVoucherSendEmail), {
      voucherId: model.id,
      voucherCode: model.voucherCode,
      customerEmail: email?email: model.order.customerEmail,
      customerName: name?name: model.order.customerName
    })
  }

  extendVoucher(model: IOrderVoucher) {
    return this.httpClient.post<IOrderVoucher>(this.getAPIURL(environment.salonFinder.orderVoucherExtendExpiryDate)+'/'+model.id, model)
  }

  cancelVoucher(model: IOrderVoucher) {
    return this.httpClient.post<IOrderVoucher>(this.getAPIURL(environment.salonFinder.orderVoucherCancel)+'/'+model.id, model)
  }
  uncancelVoucher(model: IOrderVoucher) {
    return this.httpClient.post<IOrderVoucher>(this.getAPIURL(environment.salonFinder.orderVoucherUnCancel)+'/'+model.id, model)
  }

  listRedeemed(params?: HttpParams) {
    return this.httpClient.get<ICommonListingResponse<IRedeemedVoucher[]>>(this.getAPIURL(environment.salonFinder.orderListRedeemed), {
      params
    })
  }

  listRedeemedDetailsExport(advertiserID: number, startDate: Date, endDate: Date) {
    return this.httpClient.get(this.getAPIURL(environment.salonFinder.orderListRedeemedDetailExport) +'/'+advertiserID, {
      responseType: 'blob',
      params: {
        redeemedStartDate: startDate.toISOString(),
        redeemedEndDate: endDate.toISOString()
      }
    })
  }

  listRedeemedSettlement(params?: HttpParams) {
    return this.httpClient.get<ICommonListingResponse<IRedeemedVoucher[]>>(this.getAPIURL(environment.salonFinder.orderListSettlement), {
      params
    })
  }

  payOrderVoucher(IDs: number[], paymentDate: DateTime[], advertiserID: number, bankingEntityID: number, payingMonth: Date) {
    return this.httpClient.post<IOrderPayment>(this.getAPIURL(environment.salonFinder.orderVoucherPayment), {
      orderVoucherIDs: IDs,
      advertiserID,
      bankingEntityID,
      paymentDate,
      payingMonth
    });
  }
}

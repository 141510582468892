import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { UserLogsIndexComponent } from './pages/user-logs-index/user-logs-index.component';
import { SecurityGuardRouteModule } from './security-guard.routes';
import { UserDetailComponent } from './pages/user-detail/user-detail.component';
import { UserRequestIndexComponent } from './pages/user-request-index/user-request-index.component';
import { ServiceIndexComponent } from './pages/service-index/service-index.component';
import { ServiceUpdateComponent } from './pages/service-update/service-update.component';
import { ServiceCreateComponent } from './pages/service-create/service-create.component';
import { RoleIndexComponent } from './pages/role-index/role-index.component';
import { RoleCreateComponent } from './pages/role-create/role-create.component';
import { RoleUpdateComponent } from './pages/role-update/role-update.component';
import { ServiceFormComponent } from './components/service-form/service-form.component';
import { RoleFormComponent } from './components/role-form/role-form.component';
import { RoleAccessFormComponent } from './components/role-access-form/role-access-form.component';
import { UserDetailInfoComponent } from './components/user-detail-info/user-detail-info.component';
import { UserFormComponent } from './components/user-form/user-form.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { UserCreateComponent } from './pages/user-create/user-create.component';

@NgModule({
  declarations: [
    UserLogsIndexComponent,
    UserDetailComponent,
    UserRequestIndexComponent,
    ServiceIndexComponent,
    ServiceUpdateComponent,
    ServiceCreateComponent,
    RoleIndexComponent,
    RoleCreateComponent,
    RoleUpdateComponent,
    ServiceFormComponent,
    RoleFormComponent,
    RoleAccessFormComponent,
    UserDetailInfoComponent,
    UserFormComponent,
    UserCreateComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ScrollingModule,
    MatDatepickerModule,
    MatMomentDateModule,
    SecurityGuardRouteModule 
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL', 'L','LLL', 'LLLL']
        },
        display: {
          dateInput: 'DD MMM YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY'
        }
      }
    }
  ]
})
export class SecurityGuardModule { }

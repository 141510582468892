import { Component, Inject, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { IFormQuestion } from '../../interfaces/form-question';
import { IFormQuestionAnswer } from '../../interfaces/form-question-answer';
import { IFormQuestionDependency } from '../../interfaces/form-question-dependency';
import { IFormQuestionDependencyDialogData } from '../../interfaces/IFormQuestionDependencyDialogData';
import { IQuestionAnswer } from '../../interfaces/question-answer';
import { ConsumerReportAnswerService } from '../../services/consumer-report-answer.service';

interface selectedQuestionType {
  qnsID: number;
  answers: IFormQuestionAnswer[];
}

@Component({
  selector: 'app-forms-question-dependency-dialog',
  templateUrl: './forms-question-dependency-dialog.component.html',
  styleUrls: ['./forms-question-dependency-dialog.component.scss']
})
export class FormsQuestionDependencyDialogComponent implements OnInit {

  @Output() readonly dependencyChanges: IFormQuestionDependency[];
  formQuestionData: FormGroup;
  selectedQuestion: selectedQuestionType[];

  formQuestion: IFormQuestion;
  previousFormQuestions: IFormQuestion[];
  formQuestionDependency: IFormQuestionDependency[];

  constructor(
    private readonly fb: FormBuilder,
    public dialogRef: MatDialogRef<FormsQuestionDependencyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IFormQuestionDependencyDialogData,
    private readonly consumerReportAnswerService: ConsumerReportAnswerService
  ) {
    
   
    this.formQuestion = data.formQuestion;
    this.previousFormQuestions = data.previousFormQuestions;
    this.selectedQuestion = Array<selectedQuestionType>();
    this.formQuestionDependency = data.formQuestionDependency ? data.formQuestionDependency as IFormQuestionDependency[] : []; 
 }

  get formControls() { return this.formQuestionData.controls; }

  ngOnInit(): void {
    this.initForm();
    this.initDependedQuestionAnswer()
  }

  get dependedQuestions(): FormArray {
    return this.formQuestionData.get('dependedQuestions') as FormArray;
  }

  questionAnswer(qnsID): IQuestionAnswer[]|undefined {
    if (this.selectedQuestion.findIndex(f => f.qnsID === qnsID) >= 0) {
      return this.selectedQuestion.find(f => f.qnsID === qnsID).answers;
    }

    return undefined;
  }

  compareQuestions(option: IFormQuestion, selected: IFormQuestion): boolean {
    return option.questionID === selected.questionID;
  }

  compareQuestionAnswer(option: IFormQuestionAnswer, selected: IFormQuestionAnswer): boolean {
    return option.questionAnswerID === selected.questionAnswerID;
  }

  initDependedQuestionAnswer() {
    if(this.formQuestionDependency.length > 0){
      for(const dependency of this.formQuestionDependency) {
        this.consumerReportAnswerService.listAnswer({
          limit: 1000,
          page: 0,
          questionID: [dependency.formQuestionDepends.questionID.toString()]
        })
        .subscribe(v => {
          this.selectedQuestion.push({
            qnsID: dependency.formQuestionDepends.questionID,
            answers: v.data.map(d=>this.convertQuestionAnswerToFormQuestionAnswer(d))
          });
        });
      }
    }
  }

  convertQuestionAnswerToFormQuestionAnswer(questionAnswer: IQuestionAnswer): IFormQuestionAnswer {
    return {
      ...questionAnswer,
      id: undefined,
      questionAnswerID: questionAnswer.id
    }
  }

  initDependedQuestion(dependency?: IFormQuestionDependency): FormGroup {
    const dependedQns = this.fb.group({
      question: this.fb.control(dependency?dependency.formQuestionDepends: undefined),
      answer: this.fb.control(dependency ? {
        ...dependency.formQuestionAnswerDepends,
        id: dependency.formQuestionAnswerDepends.questionAnswerID
      } as IQuestionAnswer: undefined)
    });
    dependedQns.get('question').valueChanges
      .pipe(
        map(v => v as IFormQuestion),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(v => {
          return this.consumerReportAnswerService.listAnswer({
            limit: 1000,
            page: 0,
            questionID: [v.questionID.toString()]
          })
          .pipe(
            map(r => {
              return {
                question: v,
                answer: r
              };
            })
          );
        })
      )
      .subscribe(v => {
        this.selectedQuestion.push({
          qnsID: v.question.questionID,
          answers: v.answer.data.map(d=> this.convertQuestionAnswerToFormQuestionAnswer(d))
        });
      });

    return dependedQns;
  }

  initForm(): void {

    this.formQuestionData = this.fb.group({
      label: this.fb.control(this.formQuestion.label, [
        Validators.required,
        Validators.maxLength(125),
        Validators.nullValidator
      ]),
      instruction: this.fb.control(this.formQuestion.instruction, []),
      display: this.fb.control(this.formQuestion.display, []),
      dependedQuestions: this.fb.array(this.formQuestionDependency.length>0?this.formQuestionDependency.map(d => this.initDependedQuestion(d)): [this.initDependedQuestion()])
    });
  }

  questionChange(event: MatSelectChange): void {
    of(event)
      .pipe(
        map(v => v.value as IFormQuestion),
        debounceTime(300),
        distinctUntilChanged(),
        switchMap(v  => {
          return this.consumerReportAnswerService.listAnswer({
            limit: 1000,
            page: 0,
            questionID: [v.questionID.toString()]
          });
        })
      )
      .subscribe(v => {
        this.selectedQuestion = {
          ...event.value,
          formQuestionAnswer: v.data
        };

      });
  }

  addDependency(): void {
    this.dependedQuestions.push(this.initDependedQuestion());
  }

  removeDependency(i: number): void {
    this.dependedQuestions.removeAt(i);
  }

  save(): void {
    const formData = this.formQuestionData.value;
    this.formQuestion.label = formData.label;
    this.formQuestion.instruction = formData.instruction;
    this.formQuestion.display = formData.display;
    this.formQuestion.dependencies = Array<IFormQuestionDependency>();
    for (const dependency of (formData.dependedQuestions as {answer: IFormQuestionAnswer, question: IFormQuestion}[])) {
      if(dependency.answer !== null || dependency.question !== null){      
        this.formQuestion.dependencies.push({
          formQuestionDepends: dependency.question,
          formQuestionAnswerDepends: {
            ...dependency.answer,
            id: dependency.answer?.id?dependency.answer.id: undefined,
            questionAnswerID: dependency?.answer?.questionAnswerID
          }
        });
      }
    }
    this.dialogRef.close(this.formQuestion);
  }

}

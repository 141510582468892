import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { LoadingService } from '../../services/loading.service';
import { IRedeemedVoucher } from '../../interfaces/iredeemed-voucher';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { DateTime } from 'luxon';
import { IOrderVoucher } from '../../interfaces/IOrderVoucher';
import { IOrderTransaction } from '../../interfaces/IOrderTransaction';
import { OrderTransactionTypeEnum } from '../../enums/OrderTransactionTypeEnum';
import { startWith, debounceTime, filter } from 'rxjs/operators';
import { IOrderPayment } from '../../interfaces/IOrderPayment';
import { SalonFinderOrderPaymentService } from '../../services/salon-finder-order-payment.service';
import { MatDatepicker } from '@angular/material/datepicker';
import saveAs from 'file-saver';
import moment, { Moment } from 'moment';
import { MAT_DATE_FORMATS } from '@angular/material/core';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MMM-YYYY',
  },
  display: {
    dateInput: 'MMM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}

@Component({
  selector: 'salon-finder-finance-payment-index',
  templateUrl: './finance-payment-index.component.html',
  styleUrls: ['./finance-payment-index.component.scss'],
  providers: [   

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class FinancePaymentIndexComponent extends ListingComponent<IOrderPayment> implements OnInit {

  payingMonth = new FormControl(moment())
  paymentForm: FormGroup = undefined;
  finances: [];
  allFinances: [];
  searchWord: '';
  searchOption: { value: 'voucher', label: 'Voucher Code' };
  totalGMV: 0;
  totalSfRev: 0;
  startdate: Date;
  enddate: Date;
  isLoading: false;
  isRefresh: false;
  invalidItems: [];
  dataSource!: MatTableDataSource<any>;
  ended: string;
  title: string;
  message: string;
  loading$ = this.loader.loading$;

  mapData: any = [];
  mapD: any = [];
  dvCommissionStr: string;
  selection = new SelectionModel<IRedeemedVoucher>(true, [])

  constructor(
    private readonly fb: FormBuilder,
    private readonly salonFinderOrderPaymentService: SalonFinderOrderPaymentService,
    private cdr: ChangeDetectorRef,
    public loader: LoadingService,
  ) {
    super();
    this.listingCols = [
      'Date Payment',
      'GMV',
      'voucherNo',
      'GST',
      'amountPaid',
      'amountPaidGST',
      'Actions'
    ];
  }
  
  get OrderTransationTypeEnum() {
    return OrderTransactionTypeEnum
  }

  calculateTransactions(orderVouchers: IOrderVoucher[], orderTransactionType: OrderTransactionTypeEnum) {
    let returnValue = 0
    for(const orderVoucher of orderVouchers) {
      const transactions = orderVoucher.orderTransaction?.filter(t=>t.type === orderTransactionType)
      transactions?.map(t=>returnValue+=+t.amount)
    }
    return returnValue
  }

  deleteItem(item: IOrderPayment): void {
    throw new Error('Method not implemented.');
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.payingMonth.value;
    console.log(normalizedMonthAndYear)
    normalizedMonthAndYear.startOf('month');
    // ctrlValue.month(normalizedMonthAndYear.month);
    // ctrlValue.year(normalizedMonthAndYear.year);
    // console.log(this.payingMonth)
    this.payingMonth.setValue(normalizedMonthAndYear);
    datepicker.close();
    this.populateList()
  }

  calculateCost(orderTransactions: IOrderTransaction[]) {
    let returnValue = 0;
    for (const orderTransaction of orderTransactions) {
      if (orderTransaction.type === OrderTransactionTypeEnum.GATEWAY_FEE || orderTransaction.type === OrderTransactionTypeEnum.ADVERTISER_PAYMENT) {
        returnValue += orderTransaction.amount;
      }
    }
    return returnValue;
  }

  calculateRev(orderTransactions: IOrderTransaction[]) {
    let returnValue = 0;
    for (const orderTransaction of orderTransactions) {
      if (orderTransaction.type === OrderTransactionTypeEnum.GATEWAY_FEE || orderTransaction.type === OrderTransactionTypeEnum.DV_COMMISSION || orderTransaction.type === OrderTransactionTypeEnum.DV_PROFIT ) {
        returnValue += orderTransaction.amount;
      }
    }
    return returnValue;
  }

  populateList<U>(params?: U): void {
    this.salonFinderOrderPaymentService.list(this.makeSearchParams({
      payingMonth: moment(this.payingMonth.value).format()
    }))
      .subscribe(d => this.initCommonListingResponse(d));
  }

  getExtraListingParams() {
    throw new Error('Method not implemented.');
  }

  fetchFinancesOnDate() {
    const values = this.paymentForm.value;
    if (values.paymentStartDate && values.paymentEndDate) {
      this.populateList();
    }
  }

  ngOnInit() {
    this.initForm();
    this.populateList();
    this.paymentForm.get('searchText').valueChanges
      .pipe(
        startWith(''),
        debounceTime(500),
        filter<string>(v => v.length >= 3 || v.length === 0)
      )
      .subscribe(value => {
        this.page = 0;
        this.populateList();
      });
  }

  filterData($event: any) {
    this.dataSource.filter = $event.target.value;
  }

  initForm() {
    this.paymentForm = this.fb.group({
      paymentStartDate: this.fb.control(moment().clone().add(-2, 'month').startOf('day').startOf('month')),
      paymentEndDate: this.fb.control(moment().clone().endOf('day').endOf('month')),
      searchText: [''],
      searchType: ['']
    });
  }


  downloadReport(orderPayment: IOrderPayment) {
    this.salonFinderOrderPaymentService.downloadReport(orderPayment.id)
    .subscribe(pdf => {
      saveAs(pdf, DateTime.fromISO(orderPayment.payingMonth.toString()).toFormat('yyyy-MMM') + '-' + orderPayment.bankingEntity.entityName + '.pdf');
    });
  }
}

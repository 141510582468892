import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators';
import moment from 'moment';
import { Observable, firstValueFrom } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../../auth/auth.service';
import { ICreateAuthProfile } from '../../../auth/interfaces/authProfile.interface';
import { IConsumerReportCreateUserProfile, IConsumerReportUser } from '../../interfaces/consumerReportUser.interface';
import { ConsumerReportUserService } from '../../services/consumerReportUserService.service';
import { EmailDuplicateValidator } from '../../validators/emailDuplicate.validator';
import { ConsumerReportFormService } from '../../services/consumer-report-form.service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { UserService } from 'src/app/security-guard/services/user.service';
import { UserPasswordValidator } from 'src/app/shared/helpers/UserPasswordValidator';

@Component({
  selector: 'customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss']
})
export class CustomerFormComponent implements OnInit {

  companies: Observable<string[]>;
  obj = Object;
  accountManagers: Observable<IConsumerReportUser[]>;
  savingForm = false;
  years: Observable<number[]>;
  surveys$ = this.consumerReportFormService.list()
    .pipe(
      map((v) => { return v?.data; })
    )
  selectedRoles: string[];
  allRoles: string[];

  @Input('customer')
  loadedModel: IConsumerReportUser;


  userForm: FormGroup = null;

  get formErrors() { return this.userForm.controls; }
  get formValues() { return this.userForm.controls; }
  get points(): FormArray {
    return this.userForm.get('points') as FormArray;
  }

  constructor(
    private readonly consumerReportUserService: ConsumerReportUserService,
    private readonly consumerReportFormService: ConsumerReportFormService,
    private readonly securityGuardUserService: UserService,
    private readonly authService: AuthService,
    private readonly snapBar: MatSnackBar,
    private readonly fb: FormBuilder,
    private readonly router: Router
  ) {

  }

  newPoint(): FormGroup {
    return this.fb.group({
      surveyID: [null, [RxwebValidators.required({ conditionalExpression: x =>  x.expiryDate !== null })]],
      expiryDate: ['', [
        RxwebValidators.required({ conditionalExpression: x => x.surveyID === null })
      ]],
      pointsLeft: [1],
      id: [0]
    });
  }

  addPoints() {
    this.points.push(this.newPoint());
  }

  get randomPassword() {
    const uppercaseLetters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseLetters: string = 'abcdefghijklmnopqrstuvwxyz';
    const numbers: string = '0123456789';
  
    let password: string = '';
    let hasUppercase: boolean = false;
  
    while (password.length < 7 || !hasUppercase) {
      password = '';
  
      for (let i: number = 0; i < 7; i++) {
        const characters: string = i === 0 ? uppercaseLetters : lowercaseLetters;
        const randomIndex: number = Math.floor(Math.random() * characters.length);
        password += characters.charAt(randomIndex);
      }
  
      hasUppercase = /[A-Z]/.test(password);
    }

    const randomNumberIndex: number = Math.floor(Math.random() * numbers.length);
    password += numbers.charAt(randomNumberIndex);


    return this.shuffleString(password);
   
  }

  // Helper function to shuffle a string
  shuffleString(string) {
  let shuffled = '';
  string = string.split('');

  while (string.length > 0) {
    const randomIndex = Math.floor(Math.random() * string.length);
    shuffled += string.splice(randomIndex, 1);
  }

  return shuffled;
}

  delPoints(i: number) {
    this.points.removeAt(i);
  }

  ngOnInit(): void {

    this.accountManagers = this.consumerReportUserService.getAccountManager()
      .pipe(
        startWith({ data: [], total: 0 } as ICommonListingResponse<IConsumerReportUser[]>),
        map<ICommonListingResponse<IConsumerReportUser[]>, IConsumerReportUser[]>(value => value.data)
      );
    this.years = this.consumerReportUserService.getPointYears()
      .pipe(
        map(v => {
          return v.filter(v => v > 0)
            .sort((a, b) => a - b);
        }),
        map(v => {
          if (!v.findIndex(_v => _v == +moment().format('Y'))) {
            v.push(+moment().format('Y'));
          }

          return v;
        })
      );
    this.companies = this.consumerReportUserService.getCompanies();
    this.allRoles = Object.keys(environment.consumerReport.roles);
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.loadedModel) {
      this.initForm();
    }
  }

  initForm() {

    this.userForm = this.fb.group({
      firstName: this.fb.control(this.loadedModel.firstName, [
        Validators.required,
        Validators.maxLength(125),
        Validators.nullValidator
      ]),
      lastName: this.fb.control(this.loadedModel.lastName, [
        Validators.required,
        Validators.maxLength(125),
        Validators.nullValidator
      ]),
      email: this.fb.control(this.loadedModel.email, [
        Validators.required,
        Validators.maxLength(125),
        Validators.nullValidator,
        Validators.email
      ],
      EmailDuplicateValidator.createValidator(this.consumerReportUserService, this.loadedModel.email)),
      password: this.fb.control(this.loadedModel.id ? '' : this.randomPassword, this.loadedModel.id ? [] : UserPasswordValidator),

    group: this.fb.control([environment.consumerReport.groupName], [
      Validators.required
    ]),
      company: this.fb.control(this.loadedModel.company, [
        Validators.required,
        Validators.maxLength(125),
        Validators.nullValidator
      ]),
      accountManagerID: this.fb.control(this.loadedModel.accountManagerID, [Validators.required,Validators.nullValidator]),
      points: this.fb.array(this.loadedModel.id && this.loadedModel.points.length > 0 ? this.loadedModel.points.map(p => {
        return this.fb.group({
          surveyID: [p.surveyID, [RxwebValidators.required({ conditionalExpression: x => x.expiryDate !== null })]],
          expiryDate: [p.expiryDate, [RxwebValidators.required({ conditionalExpression: x => x.surveyID === null })]],
          pointsLeft: [p.pointsLeft],
          id: [p.id]
        });
      }) : []),
      id: this.fb.control(this.loadedModel.id)
    });
  }

  getRoleDisplayValue(role: string) {
    return environment.consumerReport.roles[role] ? environment.consumerReport.roles[role] : role;
  }

  async saveUser() {
    if (this.userForm.status == 'VALID') {
      this.savingForm = true;
      try {
        let awsID = this.loadedModel.awsID;
        if (!awsID) {
          const authCreateProfile: ICreateAuthProfile = {
            family_name: this.userForm.value.lastName,
            given_name: this.userForm.value.firstName,
            email: this.userForm.value.email,
            name: this.populateDisplayName(),
            username: this.userForm.value.email,
            password: this.userForm.value.password,
            group: this.userForm.value.group
          };
          const cognitoUser = await this.authService.createProfile(authCreateProfile);
          awsID = cognitoUser.Attributes.find(a => a.Name == 'sub').Value;
        } else {
          if (this.userForm.value.password) {
            await this.authService.changePassword(this.userForm.value.password, awsID);
          }
        }
        this.userForm.value.points = this.userForm.value.points.filter(p => p.surveyID != undefined && p.surveyID != '');
        const createProfile: IConsumerReportCreateUserProfile = {
          ...this.userForm.value,
          displayName: this.populateDisplayName(),
          awsID
        };

        await Promise.all(this.userForm.value.points.map(p=>{
          p.expiryDate = moment(p.expiryDate).format('YYYY-MM-DD');
        }))

        createProfile.initialPoints = this.userForm.value.points;
        createProfile.registerOn = this.loadedModel.registerOn == undefined ? null : this.loadedModel.registerOn;
        createProfile.isAccountManager = this.loadedModel.isAccountManager;
        createProfile.accountManagerID = this.userForm.value.accountManagerID

        if(this.loadedModel.id !== 0){
          createProfile.initialPoints.map(p => {
            p.userID = this.loadedModel.id
          })
        }
        
        console.log(createProfile);
        await firstValueFrom(this.securityGuardUserService.syncCognitoUserByEmail(createProfile.email))
        this.consumerReportUserService.createProfile(createProfile)
          .subscribe(u => {
            this.snapBar.open('User has been successfully saved', 'Close', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 5000
            });
            this.loadedModel = u;
            this.initForm();
            this.savingForm = false;
            this.router.navigate(['/consumer-report/customer']);
          }, err => {
            this.snapBar.open('Error from server', 'Close', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 5000
            });
            if (err.error.errors && err.error.errors.length > 0) {
              err.error.errors.map(e => {
                this.userForm.get(e.property).setErrors(e.constraints);
              });
            }
            this.savingForm = false;
          },
            () => this.savingForm = false);
      } catch (e) {
        console.log(e)
        this.savingForm = true;
      }
    }
  }

  populateDisplayName() {
    return this.userForm.value.firstName.trim() + ' ' + this.userForm.value.lastName.trim();
  }

  refreshPassword() {
    this.userForm.controls.password.setValue(this.randomPassword);
  }

  updateExpiryDate(formGroupNameIndex){
    return this.points.controls[formGroupNameIndex]['controls'].expiryDate.markAsTouched();
  } 

  changeDate(formGroupNameIndex){
    return this.points.controls[formGroupNameIndex]['controls'].surveyID.markAsTouched();
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormComponent } from 'src/app/consumer-report/abstracts/form-component';
import { UpdateComponent } from 'src/app/shared/abstracts/update-component';
import { IRole } from '../../interfaces/iroles';
import { RoleService } from '../../services/role.service';

@Component({
  selector: 'security-guard-role-update',
  templateUrl: './role-update.component.html',
  styleUrls: ['./role-update.component.scss']
})
export class RoleUpdateComponent extends UpdateComponent<IRole, RoleService> implements OnInit {

  constructor(
    public readonly baseService: RoleService,
    private readonly activateRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.init(this.activateRoute);
  }

}

import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { ICRUDPath } from 'src/app/shared/interfaces/iCRUDPath.interface';
import { environment } from 'src/environments/environment';
import { IEmailNotification } from '../interfaces/iemail-notification';

@Injectable({
  providedIn: 'root'
})
export class SalonFinderEmailNotificationService extends CRUDService<IEmailNotification> {
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }
  list(params?: HttpParams): Observable<ICommonListingResponse<IEmailNotification[]>> {
    return this.httpList(params)
  }
  get(id: string | number): Observable<IEmailNotification> {
    return this.httpGet(id.toString())
  }
  delete(id: string | number): Observable<IEmailNotification> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: IEmailNotification): Observable<IEmailNotification> {
    return this.save(model, id)
  }
  create(model: IEmailNotification): Observable<IEmailNotification> {
    return this.save(model)
  }
  makeFormGroup(loadedModel?: IEmailNotification) {
    return this.fb.group({
      id: [loadedModel?.id],
      name: [loadedModel?.name, [
        Validators.maxLength(256),
        Validators.required
      ]],
      subject: [loadedModel?.subject, [
        Validators.maxLength(256),
        Validators.required
      ]],
      senderName: [loadedModel?.senderName || environment.salonFinder.defaultSender.name, [
        Validators.maxLength(50),
        Validators.required
      ]],
      senderAddress: [loadedModel?.senderAddress || environment.salonFinder.defaultSender.email, [
        Validators.maxLength(50),
        Validators.required,
        Validators.email
      ]],
      replyTo: [loadedModel?.replyTo || environment.salonFinder.defaultReplyToEmail, [
        Validators.maxLength(50),
        Validators.required,
        Validators.email
      ]],
      description: [loadedModel?.description, [
        Validators.maxLength(256)
      ]],
      sendVia: [loadedModel?.sendVia, [

      ]],
      bcc: [loadedModel?.bcc || environment.salonFinder.defaultBCC, [
        Validators.maxLength(256),
        Validators.email
      ]],
    })
  }
  apiPath = '/email-notification';  
  environmentCRUDPath = environment.salonFinder;

  constructor(public readonly httpClient: HttpClient, private readonly fb: FormBuilder,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute) {
    super();
  }
}

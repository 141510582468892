import { Component, Inject, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ITel } from '../../interfaces/IUrlGenerator';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { number } from 'mathjs';

@Component({
  selector: 'app-telephone-url-generator',
  templateUrl: './telephone-url-generator.component.html',
  styleUrls: ['./telephone-url-generator.component.scss']
})
export class TelephoneUrlGeneratorComponent implements OnInit {
 

	formData: FormGroup;
  code:number = 65;
  number: string = null;


  get formErrors()
   { return this.formData.controls; }
  
  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public loadedModel: ITel,
    public dialogRef: MatDialogRef<TelephoneUrlGeneratorComponent>,
  ){

  }
  ngOnInit(): void {
    this.formData = this.formBuilder.group({
      number: [this.loadedModel.number || null, [
        Validators.nullValidator        
      ]],
      country: [this.loadedModel?.country || 'sg', [
        Validators.nullValidator        
      ]],
    })

    this.formData.get('country').valueChanges.subscribe(country =>{
      if(country == 'sg'){
        this.code = 65 
      }else{
        this.code = 60
      }
    })

    this.formData.get('number').valueChanges.subscribe(number =>{
      this.number = number;
    })
  }

  insertUrl() {
    this.dialogRef.close("tel:+"+this.code+this.number);
  }

}

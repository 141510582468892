import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IRequestFormList } from '../interfaces/request-form-list'
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { IForm } from '../interfaces/form';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';

@Injectable({
  providedIn: 'root'
})
export class ConsumerReportFormService extends CRUDService<IForm> {
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }
  delete(id: string | number): Observable<IForm> {
    return this.httpClient.delete<IForm>(this.deleteURL, {
      params: {
        id: id
      }
    })
  }
  list(params?: HttpParams): Observable<ICommonListingResponse<IForm[]>> {
    return this.httpList(params)
  }
  get(id: string | number): Observable<IForm> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: IForm): Observable<IForm> {
    throw new Error('Method not implemented.');
  }
  create(model: IForm): Observable<IForm> {
    throw new Error('Method not implemented.');
  }
  makeFormGroup(loadedModel?: IForm) {
    throw new Error('Method not implemented.');
  }
  apiPath = environment.consumerReport.formBase;
  environmentCRUDPath = environment.consumerReport

  constructor(
    public override readonly httpClient: HttpClient,
    public override readonly router: Router,
    public override readonly activatedRoute: ActivatedRoute
  ) {
    super();
  }

  save(formData: IForm) {
    return this.httpClient.post<IForm>(environment.consumerReport.baseURL + environment.consumerReport.createForm, formData);
  }

  listForm(params: IRequestFormList) {
    
    return this.httpClient.get<ICommonListingResponse<IForm[]>>(environment.consumerReport.baseURL + environment.consumerReport.listForm, {
      params: new HttpParams({
        fromObject: {
          searchText: params.searchText,
          page: params.page.toString(),
          limit: params.limit.toString()
        }
      })
    })
  }

  find(id: string) {
    return this.httpClient.get<IForm>(environment.consumerReport.baseURL + environment.consumerReport.findForm + '/' + id)
  }

  refreshCache(id: string) {
    return this.httpClient.post<IForm>(environment.consumerReport.baseURL + environment.consumerReport.refreshCacheForm + '/' + id, {})
  }

  exportSubmission(model: IForm) {
    return this.httpClient.get(environment.consumerReport.baseURL + environment.consumerReport.surveyExport + '?id=' + model.id, { responseType: 'blob' })
  }

  exportParticipantSubmission(fileName:string){
    return this.httpClient.get(environment.consumerReport.baseURL + environment.consumerReport.participantSubmissionBase + environment.consumerReport.participantExportSubmission + '/' + fileName, { responseType: 'blob' })
    
  }

  generateParticipantsSubmission(formUUID:string) {
    return this.httpClient.post(environment.consumerReport.baseURL + environment.consumerReport.participantSubmissionBase + environment.consumerReport.generateParticipantsSubmission + '/' + formUUID, {});
  }

  generateParticipantsSubmissionV2(formUUID:string) {
    return this.httpClient.post(environment.consumerReport.baseURL + environment.consumerReport.participantSubmissionBase + environment.consumerReport.generateParticipantsSubmissionV2 + '/' + formUUID, {});
  }

  checkUserFormsStatus() {
    return this.httpClient.get<[]>(environment.consumerReport.baseURL + environment.consumerReport.checkUserFormsStatus);
  }



}

<collection-form [formModel]="collectionForm"></collection-form>
<!-- <div class="container">
  <mat-card class="mt-3">
    <mat-card-content>
      <mat-tab-group>
        <mat-tab label="Services - {{ serviceTotal }}">
          <salon-finder-collection-update-service (totalEvent)="setServiceTotal($event)" [collectionID]="loadingCollectionID"></salon-finder-collection-update-service>
        </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div> -->
import { AfterViewInit, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { from, Observable } from 'rxjs';
import { AuthService } from '../../../auth/auth.service';
import { ICreateAuthProfile } from '../../../auth/interfaces/authProfile.interface';
import { IConsumerReportCreateUserProfile, IConsumerReportUser } from '../../interfaces/consumerReportUser.interface';
import { ConsumerReportUserService } from '../../services/consumerReportUserService.service';
import { EmailDuplicateValidator } from '../../validators/emailDuplicate.validator';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteDialogComponent } from '../confirm-delete-dialog/confirm-delete-dialog.component';
import { PageEvent } from '@angular/material/paginator';
import { map, startWith } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { SelectObjectCompareHelper } from 'src/app/shared/helpers/SelectObjectCompareHelper';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';

@Component({
  selector: 'account-manager-form',
  templateUrl: './account-manager-form.component.html',
  styleUrls: ['./account-manager-form.component.scss']
})
export class AccountManagerFormComponent implements OnInit {

  displayedColumns: string[] = [
    'account',
    'paidReport',
    'status',
    'createdDate',
    'lastLoggedOn',
    'accountManager',
    'latestSearch',
    'action'
  ];
  defaultValue: any;
  accountManagers$ = this.consumerReportUserService.getAccountManagerDetails().pipe(
    map(d=> d)
  )
  userList: IConsumerReportUser[];
  companies: Observable<string[]>;
  obj = Object;
  savingForm = false;
  @Input('account-manager') loadedModel: IConsumerReportUser;
  accountManagerColumn : FormGroup;
  accountManagerForm: FormGroup = null;
  updatedCustomerAccountManager = [];
  length = 0;
  pageSize = 5;
  page = 0;
  pageSizeOptions: number[] = [15, 25, 50, 100];

  pageEvent: PageEvent;

  get formErrors() { return this.accountManagerForm.controls; }
  get formValues() { return this.accountManagerForm.controls; }
  constructor(
    public readonly consumerReportUserService: ConsumerReportUserService,
    private readonly authService: AuthService,
    private readonly snapBar: MatSnackBar,
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly dialog: MatDialog
  ) {
  }

  compareSelect = SelectObjectCompareHelper
  
  ngOnInit(): void {
    this.initForm();
    this.checkSubUser();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.loadedModel) {
      this.initForm();
      this.checkSubUser();
    }
  }

  initForm() {
    this.accountManagerForm = this.fb.group({
      firstName: this.fb.control(this.loadedModel.firstName, [
        Validators.required,
        Validators.maxLength(125),
        Validators.nullValidator
      ]),
      lastName: this.fb.control(this.loadedModel.lastName, [
        Validators.required,
        Validators.maxLength(125),
        Validators.nullValidator
      ]),
      email: this.fb.control(this.loadedModel.email, [
        Validators.required,
        Validators.maxLength(125),
        Validators.email,
      ],
        EmailDuplicateValidator.createValidator(this.consumerReportUserService, this.loadedModel.email)),

      id: this.fb.control(this.loadedModel.id)
    });
    this.accountManagerColumn = this.fb.group({
      accountManagerColumn: [null, Validators.required]
    });
  }

  checkSubUser() { 
    if (this.loadedModel !== undefined) {
      this.userList = [];
      this.consumerReportUserService.listAccountManagerUserById(this.loadedModel.id.toString(), { page: this.page, pageSize: this.pageSize })
        .pipe(
          map<ICommonListingResponse<IConsumerReportUser[]>, IConsumerReportUser[]>(v => {
            this.length = v.total;
            return v.data.map(v => {
              return {
                ...v,
                name: v.displayName + ' - ' + v.email,
                latestSearch: v.latestSearch?.map(vv => {
                  return {
                    ...vv,
                    link: environment.consumerReport.reportBaseURL + environment.consumerReport.reportUpdatePath + '/' + vv.commonID
                  };
                })
              };
            });
          }),
          map(v => {
            const returnVal = [];
            for (const d of v) {
              returnVal.push({
                ...d,
                awsStatus: from(this.authService.findUserByID(d.awsID)).pipe(map(user => {
                  let returnStatus = 'Inactive';
                  if (user.Users && d.active && user.Users.length > 0) {
                    const awsUser = user.Users[0];
                    returnStatus = 'Active';
                    if (awsUser.UserStatus == 'FORCE_CHANGE_PASSWORD') {
                      returnStatus = 'Pending login';
                    }
                  }
                  return returnStatus;
                }))
              });
            }
            return returnVal;
          })
        ).subscribe(v =>{ 
          v.map(data =>{
            if(data.points.length > 0){
              data.points[0].expiryFormatedDate = "";
              data.points[0].expiryFormatedDate = "2021/2022 - Expires on "+ moment(data.points[0].expiryDate).format("DD MMM YYYY")
            }
  
          })
          this.userList = v
        },error =>{
          console.log("Error :",error)
        });
        
    }
  }

  // listAccountManagers() {
  //   this.consumerReportUserService.getAccountManagerDetails()
  //     .subscribe(acccounts => {
  //       if(acccounts.length > 0){
  //       this.accountManagers = acccounts.filter(a => a != null);
  //       this.accountManagerColumn = this.fb.group({
  //         accountManagerColumn: [null, Validators.required]
  //       });
  //       const toSelect = this.accountManagers.find((c:any) => c.id == this.loadedModel.id);
  //       this.accountManagerColumn.get('accountManagerColumn').setValue(toSelect);
  //     }
  //     })
  // }

  disableUser(id: number): void {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '350px',
      data: id
    });
    dialogRef.afterClosed().subscribe(v => {
      if (!v) {
        this.checkSubUser();
      }
    });
  }

  changePage(pageEvent: PageEvent) {
    let changePage = false;
    if (this.pageSize != pageEvent.pageSize) {
      this.pageSize = pageEvent.pageSize;
      changePage = true;
    }
    if (this.page != pageEvent.pageIndex) {
      this.page = pageEvent.pageIndex;
      changePage = true;
    }
    this.checkSubUser();
  }

  updateRowAccountManager(customerAccountID:any, event:any){
    let index = this.updatedCustomerAccountManager.findIndex((c:any) => c.customerAccountID === customerAccountID);
    if(index !== -1){
      this.updatedCustomerAccountManager[index].accountManagerID = event.value.id;
    }else{
      this.updatedCustomerAccountManager.push({
        customerAccountID : customerAccountID,
        accountManagerID : event.value.id
      });
    }
  }

  saveUpdatedCustomerAccountManager() {
    this.updatedCustomerAccountManager.filter((data:any) => data.accountManagerID !== this.loadedModel.id);
    this.consumerReportUserService.updateCustomerAccountManager(this.updatedCustomerAccountManager).subscribe(result => {
     if(result.length === this.updatedCustomerAccountManager.length){
        this.snapBar.open('Account Managers Customer has been successfully updated', 'Close', {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 5000
        });
        this.checkSubUser();
      }
    });
  }

  
  populateDisplayName() {
    return this.accountManagerForm.value.firstName.trim() + ' ' + this.accountManagerForm.value.lastName.trim();
  }

  async saveUser() {
    if (this.accountManagerForm.status == 'VALID') {

      this.savingForm = true;
      try {
        let awsID = this.loadedModel.awsID;
        if (!awsID) {
          const authCreateProfile: ICreateAuthProfile = {
            family_name: this.accountManagerForm.value.lastName,
            given_name: this.accountManagerForm.value.firstName,
            email: this.accountManagerForm.value.email,
            name: this.populateDisplayName(),
            username: this.accountManagerForm.value.email,
            password: this.accountManagerForm.value.password,
            group: this.accountManagerForm.value.group
          };
          const cognitoUser = await this.authService.createProfile(authCreateProfile);
          awsID = cognitoUser.Attributes.find(a => a.Name == 'sub').Value;
        } else {
          if (this.accountManagerForm.value.password) {
            await this.authService.changePassword(this.accountManagerForm.value.password, awsID);
          }
        }
        this.accountManagerForm.value.points = [];
        const createProfile: IConsumerReportCreateUserProfile = {
          ...this.accountManagerForm.value,
          displayName: this.populateDisplayName(),
          awsID,
          isAccountManager: true
        };
        createProfile.initialPoints = this.accountManagerForm.value.points;
        this.consumerReportUserService.createProfile(createProfile)
          .subscribe(u => {
            this.snapBar.open('Account Manager has been successfully saved', 'Close', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 5000
            });
            this.loadedModel = u;
            this.initForm();
            this.savingForm = false;
          }, err => {
            this.snapBar.open('Error from server', 'Close', {
              horizontalPosition: 'center',
              verticalPosition: 'top',
              duration: 5000
            });
            if (err.error.errors && err.error.errors.length > 0) {
              err.error.errors.map(e => {
                this.accountManagerForm.get(e.property).setErrors(e.constraints);
              });
            }
            this.savingForm = false;
          },
            () => this.savingForm = false);
      } catch (e) {
        this.savingForm = true;
      }
    }
  }

}

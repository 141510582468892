import { Component, OnInit } from '@angular/core';
import { Params, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from '../../services/loading.service';
import { IAdvertiser } from '../../interfaces/IAdvertiser';
import { SalonFinderAdvertiserService } from '../../services/salon-finder-advertiser.service';
import { SalonFinderServiceService } from '../../services/salon-finder-service.service';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { HttpParams } from '@angular/common/http';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-salon-finder-advertiser-index',
  templateUrl: './advertiser-index.component.html',
  styleUrls: ['./advertiser-index.component.scss']
})

export class AdvertiserIndexComponent extends ListingComponent<IAdvertiser> implements OnInit {

  loading$ = this.loader.loading$;
  dataLoaded = false;
  wpURL = environment.salonFinder.wpURL + environment.salonFinder.wp_preview

  constructor(
    private readonly salonFinderAdvertiserService: SalonFinderAdvertiserService,
    private readonly salonFinderServiceService: SalonFinderServiceService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    public loader: LoadingService
  ) {
    super();
    this.listingCols = [
      'Advertiser',
      'Outlets',
      'Services',
      'Promos',
      'Items In Preview',
      'Action'
    ];
  }

  resyncAllServices() {
    this.salonFinderServiceService.reSyncAll()
      .subscribe(d=>{
        this.snackBar.open('Services sync request submitted', 'Close', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      })
  }

  populateList(params?: HttpParams | Params): void {
    this.salonFinderAdvertiserService
      .list(this.makeSearchParams())
      .subscribe((result) => {
        this.initCommonListingResponse(result);
      });
  }

  getExtraListingParams() {
    throw new Error('Method not implemented.');
  }

  deleteItem(item: IAdvertiser): void {
    throw new Error('Method not implemented.');
  }

  deactivate(data) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Deactivate Advertiser',
        message: 'Are you sure you want to deactivate this advertiser : ' + data.name
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.handleActivate(data);
      }
    });
  }

  allgolive(data) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'All Go Live',
        message: 'Are you sure you want to live all services for this advertiser : ' + data.name
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.setAllToLive(data);
      }
    });
  }

  ngOnInit() {
    this.populateList();
  }

  async handleActivate(advertiserData) {
    const selectedAdvertiser = advertiserData;
    const status = selectedAdvertiser.active ? 0 : 1;
    this.salonFinderAdvertiserService.activateAdvertiser({id: selectedAdvertiser.id, status: status, woo_id: selectedAdvertiser.wpAdvertiserID})
    .subscribe((data: any) => {
      if (data.active === 1) {
        this.snackBar.open('Advertiser has been activated successfully!', 'Close', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      } if (data.active === 0) {
        this.snackBar.open('Advertiser has been deactivated successfully!', 'Close', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }

      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([`/salon-finder/advertisers/`]);
      });
    });
  }

  async setAllToLive(advertiserData) {
    const selectedAdvertiser = advertiserData;
    this.salonFinderServiceService.setAllToLive(selectedAdvertiser)
    .subscribe((data: any) => {
      this.snackBar.open('All services under ' + advertiserData.name + ' are live.', 'Close', {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });

      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([`/salon-finder/advertisers/`]);
      });
    });
  }

  async handleSendEmail(advertiserData) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Send Email Onboarding',
        message: 'Are you sure you want to send email this advertiser : ' + advertiserData.name
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.salonFinderAdvertiserService.sendOnBoardEmail(advertiserData.id)
        .subscribe((data: any) => {
          this.snackBar.open('Onboard instructions emailed!', 'Close', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
        });
      }
    });
  }
}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IQuestion } from '../../interfaces/question';
import { ConsumerReportQuestionService } from '../../services/consumer-report-question.service';

@Component({
  selector: 'app-question-update',
  templateUrl: './question-update.component.html',
  styleUrls: ['./question-update.component.scss']
})
export class QuestionUpdateComponent implements OnInit {

  model: IQuestion = {
    label: '',
    answers: [],
    year: 0,
    controlType: 'text',
    type: "",    
    instruction: '',    
    matrices: [],
    order: 0,
    id: undefined,
  };

  constructor(private readonly route: ActivatedRoute, private readonly consumerReportFormService: ConsumerReportQuestionService) { }

  ngOnInit(): void {
    const formID = this.route.snapshot.paramMap.get('id');
    this.consumerReportFormService.get(formID)
      .subscribe(res => {
        res.answers = res.answers.sort((a,b) => a.order - b.order)
        this.model = res;
      });
  }

}

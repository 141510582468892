import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { AbstractControl, FormArray, FormGroup } from "@angular/forms";
import { BehaviorSubject, endWith, filter, finalize, Observable, of } from "rxjs";
import { ICommonEntityObject } from "../interfaces/ICommonEntityObject.interface";
import { AbstractCommonMethod } from "./abstractCommonMethod";
import { CRUDService } from "./crud-service";

@Component({
  template: ''
})
export abstract class FormComponent<T extends ICommonEntityObject<any>, U extends CRUDService<T>> extends AbstractCommonMethod<T> implements OnChanges {
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.loadedModel) {
      this.initForm();
    }
  }

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('formModel')
  loadedModel: T = null;

  public _loadedModel$ = new BehaviorSubject<T | null>(null)

  saveForm$: BehaviorSubject<FormGroup> = new BehaviorSubject(null)

  formData: FormGroup = null;
  savingForm: boolean = false

  @Output()
  formSaved: EventEmitter<T> = new EventEmitter<T>()

  abstract baseService: U

  formErrors(searchKey?: string, formControls?: {[key: string]: AbstractControl}) {
    let errors:{[key: string]: any} = {}
    const controls = formControls?formControls:this.formData.controls
    let keys = Object.keys(controls)
    
    if(searchKey) {
      keys = [searchKey]
    }    
    for(const k of keys) {
      if(controls[k].status === "INVALID") {
        if(controls[k] instanceof FormGroup) {
          const keyError = this.formErrors(null, (controls[k] as FormGroup).controls)
          if(Object.keys(keyError).length>0) {
            errors[k] = keyError
          }
        }
        if(controls[k] instanceof FormArray) {
          errors[k] = new Array<{[key: string]: any}>()          
          const arrayControl = controls[k] as FormArray        
          for(const formGroup of arrayControl.controls) {            
            const keyError = this.formErrors(null, (formGroup as FormGroup).controls)
            if(Object.keys(keyError).length>0) {
              errors[k].push(keyError)
            }
          }          
        }
        if(controls[k]?.errors) {
          errors[k] = controls[k]
        }
      }
    }
    if(searchKey && errors[searchKey]) {
      return errors[searchKey]
    }
    return errors
  }

  get formControls() { 
    return this.formData.controls
  }

  title(type: string) {
    
  }

  initForm() {
    this.formData = this.baseService.makeFormGroup(this.loadedModel)
    this._loadedModel$.next(this.loadedModel)
    this.saveForm$.pipe(
      filter(d=>d && !d.hasError),
    )
  }

  
  abstract save(): void
}
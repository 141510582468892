import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import FileSaver from 'file-saver';
import moment from 'moment';
import { forkJoin, from, map, Observable } from 'rxjs';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { IBrand } from '../../interfaces/ibrand';
import { IBrandCategory } from '../../interfaces/ibrand-category';
import { IOptionCountryList } from '../../interfaces/ioption-country-list';
import { IOptionGender } from '../../interfaces/ioption-gender';
import { IOptionSurveyCountry } from '../../interfaces/ioption-survey-country';
import { ConsumerReportBrandCategoryService } from '../../services/consumer-report-brand-category.service';
import { ConsumerReportBrandsService } from '../../services/consumer-report-brands.service';
import { ConsumerReportOptionService } from '../../services/consumer-report-option.service';

@Component({
  selector: 'app-brands-index',
  templateUrl: './brands-index.component.html',
  styleUrls: ['./brands-index.component.scss']
})
export class BrandsIndexComponent extends ListingComponent<IBrand> implements OnInit {

  populateList<U extends HttpParams | Params>(params?: U, urlHistory?: boolean): void {
    if(this.loadingListSubscription) {
      this.loadingListSubscription.unsubscribe()
      this.loadingListSubscription = null
    }
    const searchValue = this.searchForm?.value
    const HttpParams = this.makeSearchParams({
      brandCategory: searchValue['brandCategory']?.map(s=>s.name),
      servingCountry: searchValue['servingCountry']?.map(s=>s.name),
      country: searchValue['country']?.map(s=>s.country),
      gender: searchValue['gender']?.map(s=>s.identifier),
    })
    console.log(HttpParams)
    this.loadingListSubscription = this.commonPopulateListObservable$(HttpParams, urlHistory).subscribe(d=> {
      this.brandList = d.data;
      this.initCommonListingResponse(d)
    })
  }

  


  hasCategory(brandCategory: IBrandCategory[], categoryName: string) {
    return brandCategory.findIndex(c=>c.name === categoryName)>=0
  }

  getExtraListingParams(): void {
    throw new Error('Method not implemented.');
  }
  deleteItem(item: IBrand): void {
    this.baseService.delete(item.id)
    .subscribe(r=> {
      this.populateList()
    })
  }
  
  brandCols = [
    'name',
    'country',
    'surveyCountry',
    'gender',
    'makeup',
    'haircare',
    'skincare',
    'derma',
    'directSales',
    'drugStore',
    'salonBased',
    'celebrity',
    'action'
  ];

  brandList: IBrand[]
  categoryList:any[] = [{name: 'None', id: 'none'}]
  reCaching: boolean  
  searchForm: FormGroup
  
  constructor(
    public baseService: ConsumerReportBrandsService,
    private readonly consumerReportBrandCategoryService: ConsumerReportBrandCategoryService,
    private readonly route: ActivatedRoute, 
    private readonly consumerReportOptionService: ConsumerReportOptionService,
    private readonly fb: FormBuilder,
    public readonly mainRouter: Router,
    public readonly activatedRoute: ActivatedRoute
  ) {
    super();
    this.pageSize = 50
    this.pageSizeOptions = [50, 100, 150, 200, 250, 500, 1000, 99999]

    this.route.queryParams.subscribe(params => {
      if (params.page) {
        this.page = params.page;
      }
      if (params.limit) {
        this.pageSize = params.limit;
      }
      if (params.searchText) {
        this.searchText = params.searchText;
      }
    });
  }


  surveyServingCountry = [
    {name: "SG", id: 1},
    {name: "MY", id: 2}
  ]

  initBrandSearchForm() {
    const queryMap = this.activatedRoute.snapshot.queryParamMap
    this.searchForm = this.fb.group({
      brandCategory: [[]],
      servingCountry: [[]],
      country: [[]],
      gender: [[]]
    })
    this.searchForm.valueChanges.subscribe(d=> {
      this.refreshList(null, 0)
    })
    forkJoin([this.surveyCountry$, this.countryList$, this.genderList$, this.categoryList$])
      .subscribe(d=> {
        this.searchForm.patchValue({
          brandCategory: d[3].filter(dd=> queryMap.getAll('brandCategory[]').findIndex(b=>dd.id === +b) >=0),
          servingCountry: d[0].filter(dd=>queryMap.getAll('servingCountry[]').findIndex(id=>dd.name === id) >=0),
          country:d[1].filter(dd=>queryMap.getAll('country[]').findIndex(id=>id===dd.country)>=0),
          gender: d[2].filter(dd=>queryMap.getAll('gender[]').findIndex(id=>id===dd.identifier) >=0)
        })
      })
    
  }

  compareID(a,b) {
    return a.id === b.id
  }

  compareGender(a,b) {
    return a.identifier === b.identifier
  }

  compareServingCountry(a,b) {
    return a.name === b.name
  }

  compareCountry(a,b) {
    return a.country === b.country
  }

  surveyCountry$: Observable<IOptionSurveyCountry[]> = this.consumerReportOptionService.findByType('surveyCountry')

  countryList$: Observable<IOptionCountryList[]> = this.consumerReportOptionService.findByType('countryList')

  genderList$: Observable<IOptionGender[]> = this.consumerReportOptionService.findByType('genderControlType')

  categoryList$ = this.consumerReportBrandCategoryService.list()
    .pipe(
      map(d=> {
        console.log(d);
        return d.data
      })
    )

  ngOnInit(): void {
    this.initBrandSearchForm()
    this.populateList()
  }

  
  async exportExcel(){
    if(this.brandList.length > 0){
      import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(this.brandList);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "Brands");
      });
    } 
  }

  saveAsExcelFile(buffer: any,name:string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data,  name+"-"+moment().format('YYYY-MM-DD') + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

}

import { Component, Input, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import moment from 'moment';
import {utc} from 'moment';
import { DatePipe } from '@angular/common';
import { LoadingService } from '../../services/loading.service';
import { IRedeemedVoucher, IRedeemedVoucherByRange } from '../../interfaces/iredeemed-voucher';
import { SalonFinderFinanceService } from '../../services/salon-finder-finance.service';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { SalonFinderOrderVoucherService } from '../../services/salon-finder-order-voucher.service';
import { DateTime } from 'luxon';
import { MakeSearchParams } from 'src/app/shared/helpers/MakeSearchParams';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { OrderVoucherPaymentDialogComponent } from '../../components/order-voucher-payment-dialog/order-voucher-payment-dialog.component';
import { startWith, debounceTime, filter } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { IOrderVoucher } from '../../interfaces/IOrderVoucher';
import { environment } from 'src/environments/environment';
import saveAs from 'file-saver';
import { IOrder } from '../../interfaces/IOrder';
import { IOrderPayment } from '../../interfaces/IOrderPayment';
import { SalonFinderOrderPaymentService } from '../../services/salon-finder-order-payment.service';

@Component({
  selector: 'salon-finder-finance-redemption-index',
  templateUrl: './finance-redemption-index.component.html',
  styleUrls: ['./finance-redemption-index.component.scss']
})
export class FinanceRedemptionIndexComponent extends ListingComponent<IRedeemedVoucher> implements OnInit {

  redemptionForm: FormGroup;
  payingVouchers: IRedeemedVoucher[] = new Array<IRedeemedVoucher>();
  finances: [];
  allFinances: [];
  searchWord: '';
  searchOption: { value: 'voucher', label: 'Voucher Code' };
  totalGMV: 0;
  totalSfRev: 0;
  startdate: '';
  enddate: '';
  isRefresh: false;
  invalidItems: [];
  dataSource!: MatTableDataSource<IRedeemedVoucher>;
  ended: string;
  title: string;
  message: string;
  loading$ = this.loader.loading$;
  utc = utc;
  pipe = new DatePipe('en-SG');
  grandTotalGmv = 0.0;
  grandTotalComm = 0.0;
  grandTotalPay = 0.0;
  grandTotalDiscount = 0.0;
  grandTotalRev = 0.0;
  grandTotalGateway = 0.0;
  grandTotalRefund = 0;
  grandTotalExpired = 0;
  grandTotalCashin = 0;

  mapData: any = [];
  mapD: any = [];
  // gmv: any = [];
  // gmv:'';
  dvCommissionStr: string;
  selection = new SelectionModel<IRedeemedVoucher>(true, []);
  // dataLoaded = false;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  // @Input('redemptionForm') loadedModel: IFinanceFormState;

  constructor(
    private readonly fb: FormBuilder,
    private readonly salonFinderOrderVoucher: SalonFinderOrderVoucherService,
    private readonly salonFinderOrderPayment: SalonFinderOrderPaymentService,
    private router: Router,
    private readonly snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    public loader: LoadingService
  ) {
    super();
    this.listingCols = [
      'Checkbox',
      'Merchant',
      'Total GMV',
      'Total DV COMM',
      'totalExpired',
      'totalRefund',
      'Total DV Paid Merchant',
      'Total Discount',
      'Total SF Rev',
      'Gateway Fee',
      'Cash In',
      'Payment Status'
    ];
  }

  populateList<U>(params?: any): void {
    this.grandTotalGmv = 0;
    this.grandTotalComm = 0;
    this.grandTotalPay = 0;
    this.grandTotalDiscount = 0;
    this.grandTotalRev = 0;
    this.grandTotalGateway = 0;
    this.grandTotalRefund = 0;
    this.grandTotalExpired = 0;
    this.grandTotalCashin = 0;
    const httpParams = this.makeSearchParams({
      ...this.redemptionForm.value,
      redeemedStartDate: moment(this.redemptionForm.value.redeemedStartDate).format(),
      redeemedEndDate: moment(this.redemptionForm.value.redeemedEndDate).format(),
      limit: -1
    });
    forkJoin({
      list: this.salonFinderOrderVoucher.listRedeemed(httpParams),
      settlement: this.salonFinderOrderVoucher.listRedeemedSettlement(httpParams)
    }).subscribe(returnVal => {
        const d = returnVal.list;
        d.data = d.data.map(d => {
          this.grandTotalGmv += d.totalGmv;
          this.grandTotalComm += d.totalComm;
          this.grandTotalPay += d.totalGmv + d.totalRefund + d.totalPaid - d.totalComm;
          this.grandTotalDiscount += d.totalDiscount;
          this.grandTotalRev += d.totalComm + d.totalDiscount + d.totalExpired;
          this.grandTotalGateway += d.totalGateway;

          this.grandTotalRefund += d.totalRefund;
          this.grandTotalExpired += d.totalExpired;
          this.grandTotalCashin += d.totalGmv + d.totalRefund + d.totalDiscount + d.totalGateway;
          const settlementResult = returnVal.settlement.data.find(s => s.advertiserID === d.advertiserID);
          d.totalPaidVoucherIDs =  settlementResult?.totalPaidVoucherIDs?.split(',').filter((value, index, object) => object.indexOf(value) === index).join(',') || null;
          d.totalPaidVoucher = settlementResult?.totalPaidVoucherIDs?.split(',').length || 0;

          d.totalUnPaidVoucherIDs = settlementResult?.totalUnPaidVoucherIDs?.split(',').filter((value, index, object) => object.indexOf(value) === index).join(',') || null;
          d.totalUnPaidVoucher = settlementResult?.totalUnPaidVoucherIDs?.split(',').length || 0;
          d.totalVoucher = +settlementResult?.totalUnPaidVoucher + +settlementResult?.totalPaidVoucher;
          return d;
        });
        this.initCommonListingResponse(d);
      });
  }

  getExtraListingParams() {
    throw new Error('Method not implemented.');
  }

  deleteItem(item: IRedeemedVoucher): void {
    throw new Error('Method not implemented.');
  }

  downloadCSV(orderVoucher: IRedeemedVoucher) {
    this.salonFinderOrderVoucher.listRedeemedDetailsExport(orderVoucher.advertiserID, this.redemptionForm.value.redeemedStartDate, this.redemptionForm.value.redeemedEndDate)
      .subscribe(blob => saveAs(blob, orderVoucher.advertiserName + '.csv'));
    return false;
  }

  ngOnInit() {
    this.initForm();
    this.populateList();
    this.redemptionForm.get('searchText').valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      filter<string>(v => (v.length > 2 || v.length === 0))
    )
    .subscribe(value => {
      this.page = 0;
      this.populateList();
    });
  }

  filterData($event: any) {
    this.dataSource.filter = $event.target.value;
  }

  initForm() {
    this.redemptionForm = this.fb.group({
      redeemedStartDate: [moment().clone().startOf('month'), [Validators.required]],
      redeemedEndDate: [moment().clone().endOf('month'), [Validators.required]],
      searchText: ['']
    });
  }

  combineUnPaidVoucher(checked: boolean, model: IRedeemedVoucher) {
    if (checked) {
      this.payingVouchers.push(model);
    } else {
      this.payingVouchers = this.payingVouchers.filter(p => p.advertiserID != model.advertiserID);
    }
  }

  setCheckbox(checked: boolean, model: IRedeemedVoucher) {
    const index = this.payingVouchers.findIndex(p => p.advertiserID === model.advertiserID);
    if (checked && index < 0) {
      this.payingVouchers.push(model);
    }
    if (!checked && index >= 0) {
      this.payingVouchers = this.payingVouchers.filter(p => p.advertiserID != model.advertiserID);
    }
  }

  fetchFinancesOnDate() {
    const values = this.redemptionForm.value;
    if (values.redeemedStartDate && values.redeemedEndDate) {
      this.populateList();
    }
  }

  makePaymentDialog() {

    let redeemedVoucherByRange: IRedeemedVoucherByRange = {
      start : this.redemptionForm.value.redeemedStartDate,
      end: this.redemptionForm.value.redeemedEndDate,
      redeemVouchers: this.payingVouchers
    }

    const dialog = this.dialog
      .open<OrderVoucherPaymentDialogComponent, IRedeemedVoucherByRange, IOrderPayment[]>(OrderVoucherPaymentDialogComponent, {
      data: redeemedVoucherByRange,
      width: '700px'
    });
    dialog.afterClosed()
      .subscribe(d => {
        for (const orderPayment of d) {
          this.salonFinderOrderPayment.downloadReport(orderPayment.id)
            .subscribe(pdf => {
              saveAs(pdf, DateTime.fromISO(orderPayment.payingMonth.toString()).toFormat('yyyy-MMM') + '-' + orderPayment.bankingEntity.entityName + '.pdf');
            });
        }
        this.populateList();
      });
  }

  fetchCalculation(type: string) {
    // get total gmv and revenue
    const value = this.redemptionForm.value;
    const startDate = value.startDate.startOf('day').startOf('month').format('D MMM YYYY hh:mm A');
    const endDate = moment(value.endDate).endOf('day').endOf('month').format('D MMM YYYY hh:mm A');
    if (type === 'date') {
      // this.salonFinderFinanceService.getCalculation({startDate: startDate, endDate: endDate, voucherType: 'purchased'})
      //   .subscribe((data: any) => {
      //     console.log(data)
      //     this.totalGMV = data.calculation.totalGmv;
      //     this.totalSfRev = data.calculation.totalRev;
      //   })
    }
  }

  toggleCheckbox(checked): void {
    this.dataSource?.data?.map(d => {
      if (d.totalUnPaidVoucher > 0) {
        this.setCheckbox(checked, d);
      }
    });
  }

  handleExtendOrPayment(voucher: any, id: number, advId: number, date: any, option: string) {

  }

  handleDeletion(id: number) {

  }

  isCheckBoxChecked(model: IRedeemedVoucher) {
		return this.payingVouchers?.findIndex(p => p.advertiserID === model.advertiserID) >= 0;
	}

	isAllCheckBoxChecked() {
    return this.dataSource?.data?.filter(d => this.payingVouchers.findIndex(p => p.advertiserID === d.advertiserID) < 0)?.length <= 0 || false;
	}
}

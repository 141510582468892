import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormComponent } from '../../abstracts/form-component';
import { IInfluencer } from '../../interfaces/iinfluencer';
import { IOption } from '../../interfaces/ioption';
import { IOptionSurveyCountry } from '../../interfaces/ioption-survey-country';
import { ConsumerReportInfluencerService } from '../../services/consumer-report-influencer.service';
import { ConsumerReportOptionService } from '../../services/consumer-report-option.service';
import { ConsumerReportStoreService } from '../../services/consumer-report-store.service';

@Component({
  selector: 'consumer-report-influencer-form',
  templateUrl: './influencer-form.component.html',
  styleUrls: ['./influencer-form.component.scss']
})
export class InfluencerFormComponent extends FormComponent<IInfluencer> implements OnInit {

  constructor(
    private readonly fb: FormBuilder,
    private readonly consumerReportOptionService: ConsumerReportOptionService,
    private readonly snackBar: MatSnackBar,
    private readonly consumerReportInfluencerService: ConsumerReportInfluencerService,
    private router: Router
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loadedModel) {      
      this.initForm();
    }
  }
  
  surveyCountry: Observable<IOptionSurveyCountry[]> = this.consumerReportOptionService.findByType('surveyCountry')

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {    
    this.formData = this.fb.group({
      id: [this.loadedModel.id],
      name: [this.loadedModel.name, [Validators.required]],
      originatedISOCountryCode: [this.loadedModel.originatedISOCountryCode]
    });
  }
  save(): void {
    const value = this.formData.value;
    this.savingForm = true;
    this.consumerReportInfluencerService.save(value, value.id)
      .subscribe(
        d => {
          this.savingForm = false;
          this.loadedModel = d;
          this.snackBar.open('Influencer has been saved SUCCESSFULLY!', 'Close', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
          if(value.id)
            this.initForm();
          else {            
            this.router.navigate([`/consumer-report/influencer/update`, d.id]);
            
          }
        },
        err => {
          this.savingForm = false;
          this.snackBar.open('Influencer has been saved FAILED!', 'Close', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
          this.initForm();
        }
      );
  }

}

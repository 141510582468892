<form [formGroup]="userForm">
	<div class="container">
		<mat-card>
			<mat-card-header>
				<mat-card-title class="pt-3">Customer</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<div class="row">
					<div class="col-6">
						<mat-form-field appearance="fill" class="fullWidth">
							<mat-label>First Name</mat-label>
							<input matInput #firstName maxlength="120" placeholder="First Name" formControlName="firstName">
							<mat-error *ngIf="formErrors.firstName.errors?.required">
								First Name is <strong>required</strong>
							</mat-error>
						</mat-form-field>
					</div>
					<div class="col-6">
						<mat-form-field appearance="fill" class="fullWidth">
							<mat-label>Last Name</mat-label>
							<input matInput #lastName maxlength="120" placeholder="Last Name" formControlName="lastName">
							<mat-error *ngIf="formErrors.lastName.errors?.required">
								Last Name is <strong>required</strong>
							</mat-error>
						</mat-form-field>
					</div>
					<div class="col-4">
						<mat-form-field class="fullWidth" appearance="fill">
							<mat-label>Password</mat-label>
							<input matInput #password maxlength="256" placeholder="Leave empty to not change the password"
								formControlName="password">
							<button mat-icon-button matSuffix (click)="refreshPassword()">
								<mat-icon aria-hidden="false" aria-label="Example home icon">autorenew</mat-icon>
							</button>
							<mat-error *ngIf="formErrors?.password?.hasError('required')">
								New password is a required field.
							</mat-error>
	
							<mat-error *ngIf="formErrors?.password?.hasError('minlength')">
								Password must be more than 8 characters.
							</mat-error>
	
							<mat-error *ngIf="formErrors?.password?.hasError('maxlength')">
								Password must be less than 50 characters.
							</mat-error>
							<mat-error *ngIf="formErrors?.password?.hasError('noWhitespace')">
								Password should not contain white space.
							</mat-error>
	
							<mat-error *ngIf="formErrors?.password?.hasError('uppercaseCharacterRule')">
								Password must have at least 1 uppercase characters.
							</mat-error>
	
							<mat-error *ngIf="formErrors?.password?.hasError('lowercaseCharacterRule')">
								Password must have at least 1 lowercase characters.
							</mat-error>
	
							<mat-error *ngIf="formErrors?.password?.hasError('digitCharacterRule')">
								Password must have at least 1 numeric characters.
							</mat-error>
						</mat-form-field>
					</div>
					<div class="col-4">
						<mat-form-field appearance="fill" class="fullWidth">
							<mat-label>Email</mat-label>
							<input matInput #email maxlength="256" placeholder="Enter user email" formControlName="email"
								[disabled]="loadedModel.id>0">
							<mat-error *ngIf="formErrors.email.errors?.required">
								Email is <strong>required</strong>
							</mat-error>
							<mat-error *ngIf="formErrors.email.errors?.email">
								Email format error
							</mat-error>
							<mat-error *ngIf="formErrors.email.errors?.IsUserAlreadyExistConstraint">
								{{ formErrors.email.errors.IsUserAlreadyExistConstraint }}
							</mat-error>
						</mat-form-field>
					</div>
					<div class="col-4">
						<mat-form-field class="fullWidth">
							<mat-label>Roles</mat-label>
							<mat-select formControlName="group" multiple>
								<mat-option *ngFor="let role of allRoles" [value]="role">{{getRoleDisplayValue(role)}}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="col-6">
						<mat-form-field class="fullWidth">
							<input type="text" placeholder="Company" aria-label="Company" matInput appearance="fill"
								formControlName="company" [matAutocomplete]="auto">
							<mat-autocomplete #auto="matAutocomplete">
								<mat-option *ngFor="let option of companies | async" [value]="option">
									{{option}}
								</mat-option>
							</mat-autocomplete>
							<mat-error *ngIf="formErrors.company.errors?.required">
								Company cannot be empty
							</mat-error>
						</mat-form-field>
					</div>
					<div class="col-6">
						<mat-form-field class="fullWidth">
							<mat-label>Account Manager</mat-label>
							<mat-select formControlName="accountManagerID">
								<mat-option *ngFor="let accountManager of accountManagers | async" [value]="accountManager.id">
									{{accountManager.displayName}}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
				</div>
				<section formArrayName="points">
					<div class="row" *ngFor="let point of points.controls; let i=index">
						<ng-container [formGroupName]="i">
							<div class="col-5">
								<mat-form-field class="fullWidth" appearance="fill">
									<mat-label>DVCS Data Year</mat-label>
									<mat-select formControlName="surveyID" (selectionChange)="updateExpiryDate(i)">
										<mat-option *ngFor="let survey of surveys$ | async" [value]="survey.id">
											{{survey.name}}
										</mat-option>
									</mat-select>
									<mat-error *ngIf="point.get('surveyID').hasError('required')">
										DVCS Data Year is <strong>required</strong>
									</mat-error>
								</mat-form-field>
		
							</div>
							<div class="col-5">
								<mat-form-field class="fullWidth" appearance="fill">
									<mat-label>Access expires on</mat-label>
									<input matInput [matDatepicker]="picker" formControlName="expiryDate" (dateChange)="changeDate(i)">
									<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
									<mat-datepicker #picker></mat-datepicker>
									<mat-error *ngIf="point.get('expiryDate').hasError('required')">
										Access expires on is <strong>required</strong>
									</mat-error>
								</mat-form-field>
		
							</div>
							<div class="col-2 text-center">
								<a mat-icon-button color="primary" (click)="addPoints()">
									<mat-icon>add_circle</mat-icon>
								</a>
								<a mat-icon-button color="warn" (click)="delPoints(i)">
									<mat-icon>remove_circle</mat-icon>
								</a>
							</div>
						</ng-container>
					</div>
					<ng-container *ngIf="points.controls.length == 0">
						<div class="col-12 text-center">
							<mat-label>Add Survey Form Access</mat-label>
							<a mat-icon-button color="primary" (click)="addPoints()">
								<mat-icon>add_circle</mat-icon>
							</a>
						</div>
					</ng-container>
				</section>
				<mat-card-actions>
					<div class="row">
						<div class="col-12 text-center m-3">
							If "DVCS Data Year" and "Access expires on" are not filled, a free user account will be created,<br>
							This account will then be able to access the recent two years free version's limited report.
						</div>
					</div>
          <div class="row">
            <div class="col-12 text-center">
							<button mat-raised-button color="primary" class="m-2" (click)="saveUser()"
								[disabled]="userForm.status != 'VALID' || savingForm">Save</button>
							<button mat-raised-button class="m-2" [routerLink]="['/consumer-report/customer']">Cancel</button>
						</div>
          </div>
        </mat-card-actions>
			</mat-card-content>
		</mat-card>
	</div>
</form>

<h1 mat-dialog-title>{{ data.displayName }} - Change Password</h1>
<div mat-dialog-content>
	<form [formGroup]="passwordForm" autocomplete="off">
		<div class="row">
			<ng-template [ngIf]="!data.cognitoUserName">
				<mat-form-field appearance="fill" class="col-12">
					<mat-label>Current Password</mat-label>
					<input matInput maxlength="120" placeholder='Current Password'
						formControlName="oldPassword" [type]="hideOldPassword ? 'password' : 'text'">
						<mat-error *ngIf="formControls?.oldPassword?.hasError('password')">
							Password is incorrect.
						</mat-error>
						<mat-error *ngIf="formControls?.oldPassword?.hasError('required')">
							Password is required fields.
						</mat-error>
						<mat-error *ngIf="formControls?.oldPassword?.hasError('noWhitespace')">
							Password should not contain white space.
						</mat-error>
						<button mat-icon-button matSuffix (click)="hideOldPassword = !hideOldPassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideOldPassword">
							<mat-icon>{{hideOldPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
						</button>
				</mat-form-field>
				<mat-form-field appearance="fill" class="col-12">
					<mat-label>Repeat Current Password</mat-label>
					<input matInput maxlength="120" placeholder='Repeat current password. Must be same'
						formControlName="repeatOldPassword" [type]="hideOldPassword ? 'password' : 'text'">
						<mat-error *ngIf="formControls?.repeatOldPassword?.hasError('required')">
							Repeat Password is required fields.
						</mat-error>
						<mat-error *ngIf="formControls?.repeatOldPassword?.hasError('fieldsCompareValidator')">
							Password don't match.
						</mat-error>
						<button mat-icon-button matSuffix (click)="hideOldPassword = !hideOldPassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideOldPassword">
							<mat-icon>{{hideOldPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
						</button>
				</mat-form-field>
				<mat-divider></mat-divider>
			</ng-template>
			
			<mat-form-field appearance="fill" class="col-12">
				<mat-label>New Password</mat-label>
				<input matInput maxlength="120" placeholder='New password'
					formControlName="newPassword"  [type]="hideNewPassword ? 'password' : 'text'" autocomplete="off">
					<mat-error *ngIf="formControls?.newPassword?.hasError('required')">
						New password is a required field.
					</mat-error>

					<mat-error *ngIf="formControls?.newPassword?.hasError('minlength')">
						Password must be more than 10 characters.
					</mat-error>

					<mat-error *ngIf="formControls?.newPassword?.hasError('maxlength')">
						Password must be less than 50 characters.
					</mat-error>
					<mat-error *ngIf="formControls?.newPassword?.hasError('noWhitespace')">
						Password should not contain white space.
					</mat-error>

					<mat-error *ngIf="formControls?.newPassword?.hasError('uppercaseCharacterRule')">
						Password must have at least 1 uppercase characters.
					</mat-error>

					<mat-error *ngIf="formControls?.newPassword?.hasError('lowercaseCharacterRule')">
						Password must have at least 1 lowercase characters.
					</mat-error>

					<mat-error *ngIf="formControls?.newPassword?.hasError('digitCharacterRule')">
						Password must have at least 1 numeric characters.
					</mat-error>
					<mat-error *ngIf="formControls?.newPassword?.hasError('password')">
						{{formControls?.newPassword?.errors['password']}}
					</mat-error>
				<button mat-icon-button matSuffix (click)="hideNewPassword = !hideNewPassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideNewPassword">
					<mat-icon>{{hideNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
				</button>
			</mat-form-field>

			<mat-form-field appearance="fill" class="col-12">
				<mat-label>Repeat New Password</mat-label>
				<input matInput maxlength="120" placeholder='Repeat new password. Must be same.'
					formControlName="repeatNewPassword" [type]="hideNewPassword ? 'password' : 'text'" autocomplete="off">
					<mat-error *ngIf="formControls?.repeatNewPassword?.hasError('required')">
						Repeat new password is a required field.
					</mat-error>
					<mat-error *ngIf="formControls?.repeatNewPassword?.hasError('fieldsCompareValidator')">
						New password don't match.
					</mat-error>
					
					<button mat-icon-button matSuffix (click)="hideNewPassword = !hideNewPassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hideNewPassword">
						<mat-icon>{{hideNewPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
					</button>
			</mat-form-field>
			
		</div>

	</form>
</div>
<div mat-dialog-actions>
	<button mat-button (click)="changePassword()" [disabled]="passwordForm.status !== 'VALID'">Confirm</button>
	<button mat-button [mat-dialog-close]="true" cdkFocusInitial>Cancel</button>
</div>

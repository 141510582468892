import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroupDirective, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
// import { Editor } from 'ngx-editor';
import { validateEmails } from "../../helpers/validateEmails";
import { SalonFinderAdvertiserService } from '../../services/salon-finder-advertiser.service';
import { Observable } from 'rxjs';
import { IAdvertiser } from '../../interfaces/IAdvertiser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-advertiser-update',
  templateUrl: './advertiser-update.component.html',
  styleUrls: ['./advertiser-update.component.scss']
})
export class AdvertiserUpdateComponent implements OnInit {

  _outletTotal = 0
  _serviceTotal = 0
  _promotionTotal = 0

  get outletTotal(): number {
    return this._outletTotal;
  }

  setOutletTotal(t: number): void {
    this._outletTotal = t;
  }

  get serviceTotal(): number {
    return this._serviceTotal;
  }

  setServiceTotal(t: number): void {
    this._serviceTotal = t;
  }

  get promotionTotal(): number {
    return this._promotionTotal;
  }

  setPromotionTotal(t: number): void {
    this._promotionTotal = t;
  }

  advertiserForm: IAdvertiser = {
    woo_id: 0,
    name: '',
    email: '',
    notificationEmail: '',
    marketingEmail: '',
    commission: 0,
    description: '',
    terms: '',
    preview: '',
    slug: '',
    __sImage: environment.salonFinder.advertiserDefaultLogo,
    active: 0,
    password: '',
    bankingEntities: [
      {
        entityName: '',
        entityEmail: '',
        bankName: '',
        bankAccount: '',
        sendToAdvertiser: true
      }
    ]
    
  }

  loadingAdvertiserID = 0
  // editor: Editor;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,   
    private router: Router, 
    private readonly snackBar: MatSnackBar,
    private readonly salonFinderAdvertiserService: SalonFinderAdvertiserService,
  ) { 
  }

  ngOnInit(): void {
    this.loadingAdvertiserID = this.activatedRoute.snapshot.params.id
    console.log('xxxx:'+this.loadingAdvertiserID);
    this.salonFinderAdvertiserService.get(this.activatedRoute.snapshot.params.id)
      .subscribe(d=>this.advertiserForm = d)
  }

//  // Image Preview
//   showPreview(event) {
//     const file = (event.target as HTMLInputElement).files[0];
//     this.advertiserForm.patchValue({
//       avatar: file
//     });
//     this.advertiserForm.get('s_images').updateValueAndValidity()
//     // File Preview
//     const reader = new FileReader();
//     reader.onload = () => {
//       this.imageURL = reader.result as string;
//     }
//     reader.readAsDataURL(file)
//   }

//   save() {
//     const value = this.advertiserForm.value;
//     if(value.description === '' || value.description.length <= 8) {
//       this.snackBar.open('Please fill up description for this advertiser!', 'Close', {
//         duration: 2000,
//         horizontalPosition: 'center',
//         verticalPosition: 'top'
//       });
//     } else if(!validateEmails(value.notificationEmail)) {
//       this.snackBar.open('Invalid notification email address!', 'Close', {
//         duration: 2000,
//         horizontalPosition: 'center',
//         verticalPosition: 'top'
//       });
//     } else if(!validateEmails(value.marketingEmail)) {
//       this.snackBar.open('Invalid marketing email address!', 'Close', {
//         duration: 2000,
//         horizontalPosition: 'center',
//         verticalPosition: 'top'
//       });
//     } else if(!validateEmails(value.entityEmail)) {
//       this.snackBar.open('Invalid financial email address!', 'Close', {
//         duration: 2000,
//         horizontalPosition: 'center',
//         verticalPosition: 'top'
//       });
//     } else {
//       this.savingForm = true;
      
//       // console.log(value);
//       const data = {
//         name: value.name,
//         email: value.email,
//         notificationEmail: value.notificationEmail,
//         marketingEmail: value.marketingEmail,
//         __commission: value.__commission,
//         description: value.description,
//         terms: value.terms,
//         active: value.active
//       };

//       let entityData = {
//         entityName: value.entityName,
//         entityEmail: value.entityEmail,
//         bankName: value.bankName,
//         bankAccount: value.bankAccount,
//         advertiserID: null,
//         sendToAdvertiser: true,
//       };
//       this.salonFinderAdvertiserService.updateData(this.id, data)
//       .subscribe(
//         response => {
//           entityData.advertiserID = response.id;
//           this.salonFinderAdvertiserService.saveEntity(entityData)
//           .subscribe(
//             response => {
//               this.savingForm = false;
//               this.snackBar.open('Advertiser has been updated successfully!', 'Close', {
//                 duration: 2000,
//                 horizontalPosition: 'center',
//                 verticalPosition: 'top'
//               });
//           });
//           this.router.navigate(['salon-finder/advertisers']);
//         },
//         error => {
//           this.savingForm = false;
//           this.snackBar.open('Unable to update advertiser!', 'Close', {
//             duration: 2000,
//             horizontalPosition: 'center',
//             verticalPosition: 'top'
//           });
//         }
//       );
//     }
//   }
}
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { IUserRequest } from '../interfaces/iuser-request';
import { AuthService } from 'src/app/auth/auth.service';
import { UserRequestService } from '../services/user-request.service';
import { environment } from '../../../environments/environment';
import { ICreateUserRequestBody } from '../interfaces/icreate-user-request-body';

@Injectable()
export class SecurityGuardInterceptorInterceptor implements HttpInterceptor {

  constructor(private readonly authService: AuthService, private readonly userRequestService: UserRequestService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // const urlObject = new URL(request.url)
    // const tokenProfile = this.authService.getProfileFromToken()
    // if(tokenProfile) {
    //   let userRequest: ICreateUserRequestBody = {
    //     userID: tokenProfile.sub,
    //     applicationName: "",
    //     type: "API",
    //     pathAccessing: request.url,
    //     action: request.method,
    //     domain: urlObject.origin,
    //     queryParams: request.params.toString(),
    //     body: request.body?JSON.stringify(request.body): ""
    //   }
    //   this.userRequestService.pushRequest(userRequest)
    // }
    return next.handle(request);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConsumerReportSurveyConfigService } from '../../services/consumer-report-survey-config.service';
import { ISurveyConfigRequest } from '../../interfaces/iStaticReportConfig';
import { ConsumerReportFormQuestionService } from '../../services/consumer-report-form-question.service';

@Component({
  selector: 'app-reports-update',
  templateUrl: './reports-update.component.html',
  styleUrls: ['./reports-update.component.scss']
})
export class ReportsUpdateComponent implements OnInit {

  isLoading = false;
  model:ISurveyConfigRequest = {
    id: 0,
    formID: "",
    action: "update",
    configName: "",
    comparedForms: "",
    filterType: "",
    surveyReportConfigObj:"",
    reportName: "",
    compareName:""
  };

  constructor(
    private readonly route: ActivatedRoute,
    private consumerReportSurveyConfig : ConsumerReportSurveyConfigService,
    private consumerReportFormQuestionService : ConsumerReportFormQuestionService,
    

  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    const id = this.route.snapshot.paramMap.get('id');
    this.consumerReportSurveyConfig.get(id)
    .subscribe(async (res :ISurveyConfigRequest) => {
      this.isLoading = false;
      if(res){
        this.model.id = res.id;
        this.model.formID = res.formID; 
        this.model.comparedForms = res.comparedForms;
        this.model.configName = res.configName;
        this.model.configSlug = res.configSlug;
        this.model.filterType = res.filterType;
        this.model.uuid = res.uuid;
        this.model.reportName = res.reportName;
        this.model.compareName = res.compareName; 
        if(res.surveyReportSection.length > 0 ){
         this.model.rawConfig =  res.surveyReportSection 
        }
      }
    },error => {
      this.isLoading = false;
    })
  }
}

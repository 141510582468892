import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { map, Observable, retry, shareReplay, switchMap } from 'rxjs';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { ICommonListingSearch } from 'src/app/shared/interfaces/ICommonListSearch';
import { ICommonObject } from 'src/app/shared/interfaces/ICommonObject';
import { environment } from 'src/environments/environment';
import { IService } from '../interfaces/iservice';

@Injectable({
  providedIn: 'root'
})
export class ServiceService extends CRUDService<IService> {

  private _serviceDropDown$: Observable<IService[]>

  getDropDown() {
    if(!this._serviceDropDown$) {
      this._serviceDropDown$ = this.list()
        .pipe(
          map(d=>d.data),
          shareReplay()
        )
    }
    return this._serviceDropDown$
  }


  list(params?: HttpParams): Observable<ICommonListingResponse<IService[]>> {
    return this.httpList(params)
  }
  get(id: string | number): Observable<IService> {
    throw new Error('Method not implemented.');
  }
  delete(id: string | number): Observable<IService> {
    return this.httpDelete(id.toString())
  }
  update(id: string | number, model: IService): Observable<IService> {
    throw new Error('Method not implemented.');
  }
  create(model: IService): Observable<IService> {
    throw new Error('Method not implemented.');
  }
  makeFormGroup(loadedModel?: IService) {
    return this.fb.group({
      name: [loadedModel?.name ?? "", [Validators.required, Validators.maxLength(256)]],
      fqdn: [loadedModel?.fqdn ?? "", [Validators.required, Validators.maxLength(1024), RxwebValidators.url]],
      enableCheck: [+loadedModel?.enableCheck, [RxwebValidators.oneOf({matchValues: [1,0, "1", "0"]})]]
    });
  }
  makeSearchListGroup(defaultParams?: ICommonListingSearch) {
    return this.fb.group(this.generateCommonSearchFormGroup({
      role: [defaultParams?.role || ""]
    }, defaultParams));
  }
  apiPath = environment.securityGuard.serviceBase
  environmentCRUDPath = environment.securityGuard;

  constructor(
    public readonly httpClient: HttpClient,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute,
    public readonly fb: FormBuilder
  ) {
    super();
  }
}

<ng-container *ngIf="!isLoading">
  <form [formGroup]="redemptionForm">
    <div class="row">
      <div class="col-3 mt-3">      
          <mat-form-field class="fullWidth" appearance="fill">
            <mat-label>Select Date Range</mat-label>
            <mat-date-range-input [rangePicker]="picker">                 
              <input matStartDate placeholder="Start Date" formControlName="redeemedStartDate" #dateRangeStart>                 
              <input matEndDate placeholder="End Date" formControlName="redeemedEndDate" #dateRangeEnd (dateChange)="fetchFinancesOnDate()">                
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
  
            <!-- Invalid Date Messages -->
            <mat-error *ngIf="redemptionForm.controls.redeemedStartDate.hasError('matStartDateInvalid')">Invalid Start Date</mat-error>
            <mat-error *ngIf="redemptionForm.controls.redeemedEndDate.hasError('matEndDateInvalid')">Invalid End Date</mat-error>
  
            <!-- Required Date Messages -->
            <mat-error *ngIf="redemptionForm.controls.redeemedStartDate.errors?.required">Start Date Required</mat-error>
            <mat-error *ngIf="redemptionForm.controls.redeemedEndDate.errors?.required">End Date Required</mat-error>
          </mat-form-field>
          
      </div>
      <div class="col-3 mt-4">
        <button mat-raised-button color="primary" [disabled]="payingVouchers.length == 0" (click)="makePaymentDialog()">Make Payment</button>
      </div>
    </div>
    <div class="row">
      <mat-form-field class="col-12" appearance="fill">
        <input type="text"
          placeholder="Type anything to search"
          aria-label="Type anything to search"
          matInput
          appearance="fill"
          autocomplete="off" 
          formControlName="searchText">
      </mat-form-field>
    </div>
  </form>
  <div class="row">
    <div class="col-12">
      <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
        <ng-container matColumnDef="Checkbox">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>
            <mat-checkbox [checked]="isAllCheckBoxChecked()" (change)="toggleCheckbox($event.checked)"></mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let element"> 
            <mat-checkbox [checked]="element.totalUnPaidVoucher>0 && isCheckBoxChecked(element)" [disabled]="element.totalUnPaidVoucher==0" (change)="setCheckbox($event.checked, element)"></mat-checkbox>
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="Merchant">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Merchant</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}"> 
  
            <b><a href="#" (click)="downloadCSV(element)">{{element.entityName}}</a></b> <br />
            {{ element.advertiserName }}
          </td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>
        <ng-container matColumnDef="Total GMV">
          <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">Total GMV</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}" style="text-align: center;"> 
            total <br />
            {{element.totalGmv | currency}}
          </td>
          <td mat-footer-cell *matFooterCellDef style="text-align: center;"> 
            {{grandTotalGmv | currency}}
          </td>
        </ng-container>
        <ng-container matColumnDef="Total DV COMM">
          <th mat-header-cell *matHeaderCellDef style="text-align: center;">DV Comm (From redemption)</th>
          <td mat-cell *matCellDef="let element" style="text-align: center;"> 
            commission <br />
            {{element.totalComm | currency}}
          </td>
          <td mat-footer-cell *matFooterCellDef style="text-align: center;"> 
            {{grandTotalComm | currency}}
          </td>
        </ng-container>
        <ng-container matColumnDef="Total DV Paid Merchant">
          <th mat-header-cell *matHeaderCellDef style="text-align: center;">DV Pays Merchant</th>
          <td mat-cell *matCellDef="let element" style="text-align: center;"> 
            total + (refund) - commission - expired <br />
            {{element.totalGmv - element.totalComm + element.totalRefund - element.totalExpired | currency}}
          </td>
          <td mat-footer-cell *matFooterCellDef style="text-align: center;"> 
            {{grandTotalPay | currency}}
          </td>
        </ng-container>
  
        <ng-container matColumnDef="Total Discount">
          <th mat-header-cell *matHeaderCellDef style="text-align: center;">Total Discount</th>
          <td mat-cell *matCellDef="let element" style="text-align: center;"> 
            {{ element.totalDiscount | currency}}
          </td>
          <td mat-footer-cell *matFooterCellDef style="text-align: center;"> 
            {{grandTotalDiscount | currency}}
          </td>
        </ng-container>
        <ng-container matColumnDef="Total SF Rev">
          <th mat-header-cell *matHeaderCellDef style="text-align: center;">Total SF Rev</th>
          <td mat-cell *matCellDef="let element" style="text-align: center;"> 
            commission + (discount) + expired  <br />
            {{element.totalComm + element.totalDiscount + element.totalExpired | currency}}
          </td>
          <td mat-footer-cell *matFooterCellDef style="text-align: center;"> 
            {{grandTotalRev | currency}}
          </td>
        </ng-container>
        <ng-container matColumnDef="Gateway Fee">
          <th mat-header-cell *matHeaderCellDef style="text-align: center;">Gateway Fee</th>
          <td mat-cell *matCellDef="let element" style="text-align: center;"> 
            (gateway)
            {{element.totalGateway | currency}}
          </td>
          <td mat-footer-cell *matFooterCellDef style="text-align: center;"> 
            {{grandTotalGateway | currency}}
          </td>
        </ng-container>
        <ng-container matColumnDef="Cash In">
          <th mat-header-cell *matHeaderCellDef style="text-align: center;">Cash In</th>
          <td mat-cell *matCellDef="let element" style="text-align: center;"> 
            {{element.totalGmv + element.totalRefund + element.totalDiscount + element.totalGateway | currency}}
          </td>
          <td mat-footer-cell *matFooterCellDef style="text-align: center;"> 
            {{grandTotalCashin | currency}}
          </td>
        </ng-container>
        <ng-container matColumnDef="totalExpired">
          <th mat-header-cell *matHeaderCellDef style="text-align: center;">DV Comm (From expired)</th>
          <td mat-cell *matCellDef="let element" style="text-align: center;"> 
            expired
            {{ element.totalExpired | currency }}
          </td>
          <td mat-footer-cell *matFooterCellDef style="text-align: center;"> 
            {{grandTotalExpired | currency}}
          </td>
        </ng-container>
        <ng-container matColumnDef="totalRefund">
          <th mat-header-cell *matHeaderCellDef style="text-align: center;">Cancellations/Refunds</th>
          <td mat-cell *matCellDef="let element" style="text-align: center;"> 
            Refund
            {{ element.totalRefund | currency }}
          </td>
          <td mat-footer-cell *matFooterCellDef style="text-align: center;"> 
            {{grandTotalRefund | currency}}
          </td>
        </ng-container>
        <ng-container matColumnDef="Payment Status">
          <th mat-header-cell *matHeaderCellDef style="text-align: center;">Settled</th>
          <td mat-cell *matCellDef="let element" style="text-align: center;"> 
            {{ element.totalPaidVoucher }}/{{element.totalVoucher}}
          </td>
          <td mat-footer-cell *matFooterCellDef>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="listingCols" class="p-2"></tr>
        <tr mat-row *matRowDef="let row; columns: listingCols;" class="p-2"></tr>
        <tr mat-footer-row *matFooterRowDef="listingCols"></tr>
      </table>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isLoading">
  <div class="overlay"></div>
  <mat-progress-spinner [mode]="'indeterminate'" [diameter]="50"></mat-progress-spinner>
</ng-container>
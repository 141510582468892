import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { BrowserTracing, Replay, init, routingInstrumentation } from '@sentry/angular-ivy';
import { Sentry } from './environments/sentry';

init({
  dsn: Sentry.dsn,
  integrations: [
    new BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [].concat(
        environment.salonFinder.baseURL, 
        environment.securityGuard.baseURL, 
        environment.consumerReport.baseURL,
        environment.nativeAds.baseURL,
        environment.telegramReport.baseURL,
        environment.urlShortener.baseURL,
      ).map(url => url.replace('http://', '').replace('https://', '')),
      routingInstrumentation: routingInstrumentation,
    }),
    new Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: Sentry.tracesSampleRate, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: Sentry.replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: Sentry.replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  release: Sentry.projectName+"@"+Sentry.projectVersion,
})

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

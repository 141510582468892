import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UrlShortenerCollectionService } from '../../services/url-shortener-collection.service';

@Component({
  selector: 'url-shortener-update',
  templateUrl: './url-shortener-update.component.html',
  styleUrls: ['./url-shortener-update.component.scss']
})
export class UrlShortenerUpdateComponent implements OnInit {

    urlShortenerForm: any = {    
      id: 0,
      domainID: 3,
      domainName: '',
      redirectURL: '',
      accountID: '',
      ownerName: '',
      description: '',
      fullURL: '',
      hash: '',
      active: '',
      clientName: '',
      campaignName: '',
      createdAt: new Date(),
      updatedAt: new Date(),
      deletedAt: new Date(),
      account: [],
      domain: {},
      tags: []
    }

  constructor(
    private readonly route: ActivatedRoute,
    private readonly urlShortenerService: UrlShortenerCollectionService,
  ) { }

  ngOnInit(): void {
    const customerID = this.route.snapshot.paramMap.get('id');
    this.urlShortenerService.get(customerID)
      .subscribe((res:any) => {
        if(res){
          this.urlShortenerForm = res;
        }
      });
  }

}
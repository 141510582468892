<h1 class="finance">FINANCE</h1>
<hr>
<mat-tab-group preserveContent>
  <mat-tab label="Purchases">
    <salon-finder-finance-purchases-index ></salon-finder-finance-purchases-index>
  </mat-tab>
  <mat-tab label="Redemption/Expired">
    <salon-finder-finance-redemption-index></salon-finder-finance-redemption-index>
  </mat-tab>
  <mat-tab label="Past Payment">
    <salon-finder-finance-payment-index></salon-finder-finance-payment-index>
  </mat-tab>
</mat-tab-group>
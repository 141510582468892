import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportRouteModule } from './report.routes';
import { MAT_LUXON_DATE_FORMATS } from '@angular/material-luxon-adapter';
import { FollowersComponent } from './telegram/components/followers/followers.component';
import { NotificationsComponent } from './telegram/components/notifications/notifications.component';
import { PostsComponent } from './telegram/components/posts/posts.component';
import { FollowerIndexComponent } from './telegram/pages/follower-index/follower-index.component';
import { PostIndexComponent } from './telegram/pages/post-index/post-index.component';
import { NotificationIndexComponent } from './telegram/pages/notification-index/notification-index.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgChartsModule } from 'ng2-charts';
import { SharedModule } from '../shared/shared.module';
import { MatSortModule } from '@angular/material/sort';
import { LinkClickDataComponent } from './telegram/components/link-click-data/link-click-data.component';
import { MessageIndexComponent } from './telegram/pages/message-index/message-index.component';

@NgModule({
  declarations: [
    FollowersComponent,
    NotificationsComponent,
    PostsComponent,
    FollowerIndexComponent,
    PostIndexComponent,
    NotificationIndexComponent,
    LinkClickDataComponent,
    MessageIndexComponent
  ],
  imports: [
    SharedModule,
    ReportRouteModule,      
    MatTableModule,
    MatSortModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatButtonModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatSnackBarModule,
    MatChipsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatCardModule,
    MatDividerModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatGridListModule,
    ReactiveFormsModule,
    MatRadioModule,
    FormsModule,
    MatButtonToggleModule,
    RouterModule,
    NgChartsModule
  ],
  providers: [
    {
      provide: MAT_LUXON_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['D', 'DD']
        },
        display: {
          dateInput: 'DD',
          monthYearLabel: 'MMM yyyy',
          dateA11yLabel: 'DDD',
          monthYearA11yLabel: 'MMMM yyyy'
        }
      }
    },
  ]
})
export class ReportModule { }

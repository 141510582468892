import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '../auth/auth-guard.service';
import { AdvertiserCreateComponent } from './pages/advertiser-create/advertiser-create.component';
import { AdvertiserIndexComponent } from './pages/advertiser-index/advertiser-index.component';
import { AdvertiserUpdateComponent } from './pages/advertiser-update/advertiser-update.component';
import { CollectionAddServiceComponent } from './pages/collection-add-service/collection-add-service.component';
import { CollectionCreateComponent } from './pages/collection-create/collection-create.component';
import { CollectionIndexComponent } from './pages/collection-index/collection-index.component';
import { CollectionUpdateServiceComponent } from './pages/collection-update-service/collection-update-service.component';
import { CollectionUpdateComponent } from './pages/collection-update/collection-update.component';
import { EmailNotificationCreateComponent } from './pages/email-notification-create/email-notification-create.component';
import { EmailNotificationIndexComponent } from './pages/email-notification-index/email-notification-index.component';
import { EmailNotificationUpdateComponent } from './pages/email-notification-update/email-notification-update.component';
import { FinanceIndexComponent } from './pages/finance-index/finance-index.component';
import { FinancePurchasesIndexComponent } from './pages/finance-purchases-index/finance-purchases-index.component';
import { OutletCreateComponent } from './pages/outlet-create/outlet-create.component';
import { OutletIndexComponent } from './pages/outlet-index/outlet-index.component';
import { OutletUpdateComponent } from './pages/outlet-update/outlet-update.component';
import { PromoCreateComponent } from './pages/promo-create/promo-create.component';
import { PromoIndexComponent } from './pages/promo-index/promo-index.component';
import { PromoUpdateComponent } from './pages/promo-update/promo-update.component';
import { ServiceCreateComponent } from './pages/service-create/service-create.component';
import { ServiceIndexComponent } from './pages/service-index/service-index.component';
import { ServiceUpdateComponent } from './pages/service-update/service-update.component';

export const SalonFinderRoute: Routes  = [
  {path: 'salon-finder/advertisers', component: AdvertiserIndexComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/advertisers/add', component: AdvertiserCreateComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/advertisers/update/:id', component: AdvertiserUpdateComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/advertisers/outlets/list/:id', component: OutletIndexComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/advertisers/outlets/add/:advertiserID', component: OutletCreateComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/advertisers/services/list/:id', component: ServiceIndexComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/advertisers/services/add/:advertiserID', component: ServiceCreateComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/advertisers/promotions/list/:id', component: PromoIndexComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/advertisers/promotions/add/:advertiserID', component: PromoCreateComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/collections', component: CollectionIndexComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/collections/add', component: CollectionCreateComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/collections/update/:id', component: CollectionUpdateComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/collections/deactivate/:id', component: CollectionIndexComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/collections/add-service/:id', component: CollectionAddServiceComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/collections/update-service/:id', component: CollectionUpdateServiceComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/outlets', component: OutletIndexComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/outlets/add', component: OutletCreateComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/outlets/update/:id', component: OutletUpdateComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/services', component: ServiceIndexComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/services/add', component: ServiceCreateComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/services/update/:id', component: ServiceUpdateComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/promotions', component: PromoIndexComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/promotions/add/:advertiserID', component: PromoCreateComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/promotions/update/:id', component: PromoUpdateComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/finance', component: FinanceIndexComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/finance/purchases', component: FinancePurchasesIndexComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/email-notification', component: EmailNotificationIndexComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/email-notification/add', component: EmailNotificationCreateComponent, canActivate: [AuthGuardService]},
  {path: 'salon-finder/email-notification/update/:id', component: EmailNotificationUpdateComponent, canActivate: [AuthGuardService]}
];

@NgModule({
  imports: [
    RouterModule.forRoot(SalonFinderRoute, {})
  ],
  exports: [RouterModule]
})
export class SalonFinderRouteModule { }

// Font Awesome 5 Icons
export const FontAwesomeIcons = [
'search',
'home',
'account_circle',
'settings',
'done',
'info',
'check_circle',
'delete',
'shopping_cart',
'visibility',
'favorite',
'logout',
'description',
'favorite_border',
'lock',
'schedule',
'language',
'face',
'help_outline',
'manage_accounts',
'filter_alt',
'fingerprint',
'event',
'verified',
'thumb_up',
'dashboard',
'login',
'calendar_today',
'visibility_off',
'list',
'check_circle_outline',
'highlight_off',
'date_range',
'help',
'question_answer',
'task_alt',
'paid',
'article',
'shopping_bag',
'lightbulb',
'open_in_new',
'perm_identity',
'trending_up',
'credit_card',
'history',
'account_balance',
'delete_outline',
'report_problem',
'fact_check',
'assignment',
'verified_user',
'arrow_right_alt',
'star_rate',
'account_balance_wallet',
'analytics',
'autorenew',
'work',
'build',
'view_list',
'print',
'store',
'today',
'delete_forever',
'admin_panel_settings',
'lock_open',
'savings',
'room',
'code',
'grade',
'watch_later',
'receipt',
'update',
'add_shopping_cart',
'contact_support',
'power_settings_new',
'pets',
'done_all',
'explore',
'bookmark',
'bookmark_border',
'note_add',
'reorder',
'account_box',
'shopping_basket',
'pending_actions',
'drag_indicator',
'payment',
'launch',
'supervisor_account',
'pending',
'zoom_in',
'touch_app',
'assessment',
'calendar_month',
'thumb_up_off_alt',
'open_in_full',
'leaderboard',
'done_outline',
'exit_to_app',
'preview',
'assignment_ind',
'card_giftcard',
'view_in_ar',
'work_outline',
'feedback',
'published_with_changes',
'timeline',
'dns',
'swap_horiz',
'assignment_turned_in',
'sync_alt',
'flight_takeoff',
'book',
'stars',
'label',
'pan_tool',
'bug_report',
'contact_page',
'gavel',
'cached',
'translate',
'alarm',
'space_dashboard',
'android',
'supervised_user_circle',
'accessibility',
'edit_calendar',
'minimize',
'extension',
'get_app',
'tips_and_updates',
'record_voice_over',
'add_task',
'trending_flat',
'hourglass_empty',
'help_center',
'thumb_down',
'accessibility_new',
'sticky_note_2',
'flutter_dash',
'rule',
'dashboard_customize',
'support',
'source',
'find_in_page',
'close_fullscreen',
'settings_applications',
'view_headline',
'redeem',
'announcement',
'loyalty',
'group_work',
'ads_click',
'swap_vert',
'restore',
'euro_symbol',
'sensors',
'dangerous',
'compare_arrows',
'nightlight_round',
'privacy_tip',
'subject',
'rocket_launch',
'arrow_circle_right',
'question_mark',
'disabled_by_default',
'track_changes',
'grading',
'https',
'table_view',
'copyright',
'toc',
'arrow_circle_up',
'bookmarks',
'api',
'query_builder',
'perm_media',
'build_circle',
'input',
'book_online',
'zoom_out',
'backup',
'perm_contact_calendar',
'open_with',
'view_module',
'settings_phone',
'circle_notifications',
'3d_rotation',
'speaker_notes',
'arrow_circle_down',
'label_important',
'card_membership',
'perm_phone_msg',
'file_present',
'wysiwyg',
'swipe',
'pageview',
'integration_instructions',
'upgrade',
'trending_down',
'change_history',
'class',
'accessible',
'g_translate',
'settings_accessibility',
'production_quantity_limits',
'offline_bolt',
'expand',
'model_training',
'aspect_ratio',
'donut_large',
'calendar_view_month',
'settings_backup_restore',
'segment',
'maximize',
'schedule_send',
'view_column',
'bookmark_add',
'arrow_circle_left',
'alarm_on',
'thumbs_up_down',
'thumb_down_off_alt',
'percent',
'view_agenda',
'settings_ethernet',
'theaters',
'important_devices',
'invert_colors',
'unpublished',
'open_in_browser',
'currency_exchange',
'commute',
'opacity',
'addchart',
'no_accounts',
'youtube_searched_for',
'history_toggle_off',
'view_week',
'tour',
'system_update_alt',
'settings_input_antenna',
'shop',
'turned_in',
'mark_as_unread',
'bookmark_added',
'hide_source',
'plagiarism',
'flight_land',
'search_off',
'not_started',
'saved_search',
'contactless',
'assignment_late',
'donut_small',
'highlight_alt',
'view_carousel',
'anchor',
'settings_input_component',
'turned_in_not',
'mediation',
'fit_screen',
'toll',
'edit_off',
'flaky',
'settings_remote',
'assignment_return',
'view_quilt',
'remove_shopping_cart',
'all_inbox',
'hourglass_full',
'lock_clock',
'settings_voice',
'online_prediction',
'pregnant_woman',
'swap_horizontal_circle',
'hotel_class',
'event_seat',
'next_plan',
'view_sidebar',
'camera_enhance',
'markunread_mailbox',
'restore_from_trash',
'vertical_split',
'dynamic_form',
'calendar_view_week',
'accessible_forward',
'tab',
'balance',
'filter_alt_off',
'add_to_drive',
'data_exploration',
'shopping_cart_checkout',
'settings_power',
'view_stream',
'remove_done',
'smart_button',
'request_page',
'card_travel',
'try',
'outbox',
'offline_pin',
'rowing',
'calendar_view_day',
'outlet',
'find_replace',
'compress',
'rocket',
'chrome_reader_mode',
'token',
'settings_brightness',
'http',
'alarm_add',
'assignment_returned',
'spellcheck',
'play_for_work',
'gif',
'backup_table',
'restore_page',
'credit_card_off',
'wifi_protected_setup',
'settings_overscan',
'new_label',
'lock_reset',
'fax',
'settings_input_composite',
'generating_tokens',
'quickreply',
'comment_bank',
'view_day',
'terminal',
'swap_vertical_circle',
'polymer',
'outbound',
'line_weight',
'cancel_schedule_send',
'horizontal_split',
'batch_prediction',
'send_and_archive',
'picture_in_picture',
'free_cancellation',
'density_medium',
'code_off',
'settings_bluetooth',
'bookmark_remove',
'disabled_visible',
'switch_access_shortcut',
'flip_to_front',
'shop_two',
'gif_box',
'add_card',
'eject',
'speaker_notes_off',
'satellite_alt',
'perm_data_setting',
'view_timeline',
'settings_input_hdmi',
'perm_scan_wifi',
'alarm_off',
'settings_cell',
'hourglass_disabled',
'app_blocking',
'sensors_off',
'work_off',
'line_style',
'webhook',
'perm_device_information',
'picture_in_picture_alt',
'subtitles_off',
'assured_workload',
'html',
'123',
'switch_access_shortcut_add',
'display_settings',
'update_disabled',
'event_repeat',
'settings_input_svideo',
'voice_over_off',
'output',
'view_array',
'flip_to_back',
'open_in_new_off',
'swipe_left',
'noise_control_off',
'all_out',
'javascript',
'private_connectivity',
'work_history',
'app_shortcut',
'label_off',
'rounded_corner',
'join_full',
'tab_unselected',
'shop_2',
'text_rotate_vertical',
'swipe_right',
'explore_off',
'pan_tool_alt',
'install_desktop',
'abc',
'text_rotation_none',
'swipe_up',
'not_accessible',
'pin_invoke',
'join_inner',
'perm_camera_mic',
'manage_history',
'extension_off',
'density_small',
'data_thresholding',
'lightbulb_circle',
'pin_end',
'install_mobile',
'commit',
'swipe_down',
'text_rotate_up',
'css',
'text_rotation_down',
'join_left',
'text_rotation_angledown',
'view_kanban',
'text_rotation_angleup',
'pinch',
'swipe_vertical',
'view_cozy',
'join_right',
'swipe_right_alt',
'view_comfy_alt',
'spatial_audio_off',
'noise_aware',
'php',
'view_compact_alt',
'swipe_down_alt',
'browse_gallery',
'swipe_left_alt',
'network_ping',
'swipe_up_alt',
'density_large',
'lock_person',
'on_device_training',
'spatial_tracking',
'spatial_audio',
'hls',
'troubleshoot',
'hls_off',
'width_normal',
'width_full',
'width_wide',
'face_unlock',
'arrow_outward',
'add_home',
'unfold_more_double',
'repartition',
'unfold_less_double',
'transcribe',
'warning',
'warning',
'error',
'error_outline',
'warning_amber',
'notification_important',
'add_alert',
'auto_delete',
'play_arrow',
'play_arrow',
'play_circle_filled',
'videocam',
'play_circle',
'mic',
'volume_up',
'pause',
'play_circle_outline',
'volume_off',
'replay',
'skip_next',
'library_books',
'speed',
'stop',
'fiber_manual_record',
'movie',
'skip_previous',
'playlist_add',
'new_releases',
'equalizer',
'loop',
'fast_forward',
'web',
'video_library',
'playlist_add_check',
'mic_off',
'library_add',
'video_call',
'pause_circle',
'subscriptions',
'repeat',
'stop_circle',
'volume_mute',
'shuffle',
'not_interested',
'sort_by_alpha',
'mic_none',
'library_music',
'fast_rewind',
'videocam_off',
'volume_down',
'queue_music',
'recent_actors',
'web_asset',
'pause_circle_filled',
'hearing',
'library_add_check',
'subtitles',
'album',
'pause_circle_outline',
'fiber_new',
'note',
'av_timer',
'radio',
'games',
'playlist_play',
'branding_watermark',
'replay_circle_filled',
'queue',
'forward_10',
'replay_10',
'closed_caption',
'video_settings',
'featured_play_list',
'control_camera',
'playlist_add_check_circle',
'add_to_queue',
'slow_motion_video',
'airplay',
'repeat_one',
'call_to_action',
'snooze',
'hd',
'high_quality',
'repeat_on',
'closed_caption_off',
'replay_30',
'featured_video',
'playlist_remove',
'5g',
'shuffle_on',
'forward_30',
'music_video',
'replay_5',
'queue_play_next',
'art_track',
'playlist_add_circle',
'hearing_disabled',
'forward_5',
'explicit',
'4k',
'fiber_smart_record',
'video_label',
'audio_file',
'interpreter_mode',
'video_file',
'repeat_one_on',
'closed_caption_disabled',
'surround_sound',
'remove_from_queue',
'play_disabled',
'web_asset_off',
'sd',
'missed_video_call',
'10k',
'fiber_pin',
'fiber_dvr',
'4k_plus',
'1k',
'2k',
'1k_plus',
'8k',
'lyrics',
'9k_plus',
'8k_plus',
'5k',
'3k',
'5k_plus',
'7k',
'2k_plus',
'3k_plus',
'9k',
'6k',
'6k_plus',
'7k_plus',
'email',
'location_on',
'call',
'phone',
'business',
'chat',
'mail_outline',
'list_alt',
'vpn_key',
'qr_code_scanner',
'chat_bubble_outline',
'alternate_email',
'forum',
'chat_bubble',
'textsms',
'contact_mail',
'person_search',
'qr_code_2',
'qr_code',
'sentiment_satisfied_alt',
'message',
'comment',
'contacts',
'app_registration',
'import_contacts',
'contact_phone',
'live_help',
'import_export',
'forward_to_inbox',
'key',
'rss_feed',
'hourglass_bottom',
'hourglass_top',
'mark_email_read',
'read_more',
'mark_email_unread',
'call_end',
'more_time',
'document_scanner',
'clear_all',
'hub',
'dialpad',
'phone_enabled',
'mark_chat_unread',
'3p',
'cancel_presentation',
'screen_share',
'call_made',
'call_split',
'unsubscribe',
'present_to_all',
'add_ic_call',
'domain_verification',
'mark_chat_read',
'phonelink_ring',
'stay_current_portrait',
'call_received',
'phonelink_lock',
'phone_disabled',
'duo',
'ring_volume',
'phonelink_setup',
'location_off',
'stay_primary_portrait',
'swap_calls',
'co_present',
'voicemail',
'call_merge',
'mobile_screen_share',
'phonelink_erase',
'person_add_disabled',
'cell_tower',
'domain_disabled',
'call_missed_outgoing',
'cell_wifi',
'speaker_phone',
'desktop_access_disabled',
'stop_screen_share',
'call_missed',
'spoke',
'print_disabled',
'pause_presentation',
'dialer_sip',
'invert_colors_off',
'rtt',
'wifi_calling',
'portable_wifi_off',
'comments_disabled',
'stay_current_landscape',
'sip',
'nat',
'stay_primary_landscape',
'no_sim',
'mark_unread_chat_alt',
'send_time_extension',
'key_off',
'vpn_key_off',
'mail_lock',
'contact_emergency',
'add',
'add_circle_outline',
'content_copy',
'add_circle',
'send',
'clear',
'mail',
'save',
'link',
'remove',
'filter_list',
'inventory_2',
'insights',
'inventory',
'sort',
'remove_circle_outline',
'bolt',
'flag',
'reply',
'add_box',
'remove_circle',
'create',
'push_pin',
'block',
'calculate',
'undo',
'how_to_reg',
'content_paste',
'report',
'file_copy',
'shield',
'backspace',
'archive',
'save_alt',
'policy',
'tag',
'change_circle',
'redo',
'content_cut',
'forward',
'inbox',
'outlined_flag',
'drafts',
'link_off',
'biotech',
'ballot',
'report_gmailerrorred',
'markunread',
'stacked_bar_chart',
'delete_sweep',
'square_foot',
'stream',
'add_link',
'where_to_vote',
'copy_all',
'move_to_inbox',
'dynamic_feed',
'waves',
'unarchive',
'reply_all',
'select_all',
'low_priority',
'text_format',
'content_paste_search',
'weekend',
'font_download',
'how_to_vote',
'upcoming',
'gesture',
'attribution',
'save_as',
'next_week',
'content_paste_go',
'flag_circle',
'content_paste_off',
'filter_list_off',
'report_off',
'font_download_off',
'deselect',
'web_stories',
'light_mode',
'light_mode',
'restart_alt',
'dark_mode',
'task',
'summarize',
'password',
'sell',
'signal_cellular_alt',
'devices',
'settings_suggest',
'quiz',
'widgets',
'storage',
'thermostat',
'battery_full',
'credit_score',
'gps_fixed',
'medication',
'price_check',
'pin',
'gpp_good',
'price_change',
'tungsten',
'battery_charging_full',
'fmd_good',
'reviews',
'note_alt',
'air',
'graphic_eq',
'bluetooth',
'dvr',
'nightlight',
'sports_score',
'access_time',
'water',
'share_location',
'monitor_heart',
'gpp_maybe',
'cable',
'location_searching',
'cameraswitch',
'shortcut',
'device_thermostat',
'airplane_ticket',
'wallpaper',
'monitor_weight',
'radar',
'data_usage',
'signal_wifi_4_bar',
'gpp_bad',
'developer_mode',
'battery_std',
'mode_night',
'bloodtype',
'wifi_tethering',
'signal_cellular_4_bar',
'flashlight_on',
'network_wifi',
'fmd_bad',
'splitscreen',
'access_time_filled',
'airplanemode_active',
'mobile_friendly',
'sim_card_download',
'battery_alert',
'send_to_mobile',
'screen_search_desktop',
'usb',
'lens_blur',
'signal_wifi_statusbar_4_bar',
'discount',
'screen_rotation',
'signal_wifi_statusbar_connected_no_internet_4',
'system_security_update_good',
'gps_not_fixed',
'bluetooth_connected',
'remember_me',
'pattern',
'nfc',
'brightness_high',
'mode_standby',
'battery_saver',
'play_lesson',
'lan',
'storm',
'network_cell',
'data_saver_off',
'screenshot',
'brightness_medium',
'signal_wifi_0_bar',
'brightness_low',
'data_saver_on',
'grid_4x4',
'bluetooth_searching',
'mobiledata_off',
'ad_units',
'bluetooth_disabled',
'security_update_good',
'hdr_auto',
'battery_unknown',
'signal_wifi_off',
'signal_wifi_bad',
'settings_system_daydream',
'wifi_calling_3',
'access_alarms',
'battery_5_bar',
'signal_wifi_connected_no_internet_4',
'aod',
'add_to_home_screen',
'security_update',
'ssid_chart',
'signal_cellular_0_bar',
'flashlight_off',
'flourescent',
'access_alarm',
'gps_off',
'4g_mobiledata',
'grid_3x3',
'signal_wifi_statusbar_null',
'battery_4_bar',
'sd_storage',
'reset_tv',
'wifi_lock',
'battery_0_bar',
'battery_6_bar',
'brightness_auto',
'do_not_disturb_on_total_silence',
'signal_cellular_connected_no_internet_4_bar',
'airplanemode_inactive',
'1x_mobiledata',
'nearby_error',
'security_update_warning',
'signal_cellular_connected_no_internet_0_bar',
'screen_lock_portrait',
'system_security_update',
'battery_3_bar',
'system_security_update_warning',
'wifi_tethering_off',
'edgesensor_high',
'punch_clock',
'4g_plus_mobiledata',
'battery_1_bar',
'signal_wifi_4_bar_lock',
'lte_mobiledata',
'add_alarm',
'signal_cellular_off',
'media_bluetooth_on',
'bluetooth_drive',
'signal_cellular_nodata',
'wifi_password',
'location_disabled',
'signal_cellular_null',
'mobile_off',
'medication_liquid',
'30fps',
'battery_2_bar',
'grid_goldenratio',
'screen_lock_rotation',
'rsvp',
'edgesensor_low',
'3g_mobiledata',
'60fps',
'screen_lock_landscape',
'lte_plus_mobiledata',
'usb_off',
'phishing',
'timer_10_select',
'signal_cellular_no_sim',
'screenshot_monitor',
'g_mobiledata',
'timer_3_select',
'wifi_find',
'h_mobiledata',
'network_wifi_3_bar',
'network_wifi_1_bar',
'media_bluetooth_off',
'e_mobiledata',
'r_mobiledata',
'h_plus_mobiledata',
'wifi_tethering_error',
'hdr_on_select',
'hdr_auto_select',
'wifi_channel',
'network_wifi_2_bar',
'nearby_off',
'wifi_2_bar',
'hdr_off_select',
'signal_cellular_alt_2_bar',
'signal_cellular_alt_1_bar',
'wifi_1_bar',
'devices_fold',
'dataset',
'dataset_linked',
'macro_off',
'attach_money',
'attach_money',
'format_list_bulleted',
'mode_edit',
'edit_note',
'monetization_on',
'attach_file',
'post_add',
'bar_chart',
'checklist',
'drag_handle',
'insert_drive_file',
'show_chart',
'format_quote',
'format_list_numbered',
'border_color',
'table_chart',
'pie_chart',
'notes',
'query_stats',
'format_bold',
'insert_photo',
'text_fields',
'insert_emoticon',
'title',
'table_rows',
'auto_graph',
'publish',
'mode',
'insert_chart_outlined',
'checklist_rtl',
'mode_comment',
'insert_invitation',
'draw',
'insert_link',
'functions',
'mode_edit_outline',
'format_italic',
'format_align_left',
'horizontal_rule',
'format_color_fill',
'add_comment',
'insert_chart',
'linear_scale',
'format_size',
'stacked_line_chart',
'format_underlined',
'bubble_chart',
'insert_comment',
'money_off',
'vertical_align_bottom',
'height',
'vertical_align_top',
'format_align_center',
'format_paint',
'schema',
'format_color_text',
'area_chart',
'format_align_right',
'scatter_plot',
'format_list_numbered_rtl',
'highlight',
'merge_type',
'format_align_justify',
'square',
'add_chart',
'format_shapes',
'money_off_csred',
'pie_chart_outline',
'numbers',
'short_text',
'format_indent_increase',
'align_horizontal_left',
'strikethrough_s',
'vertical_align_center',
'format_color_reset',
'data_object',
'multiline_chart',
'score',
'border_all',
'hexagon',
'space_bar',
'format_indent_decrease',
'format_clear',
'rectangle',
'format_line_spacing',
'move_up',
'align_vertical_bottom',
'horizontal_distribute',
'align_horizontal_center',
'format_strikethrough',
'move_down',
'align_horizontal_right',
'vertical_distribute',
'superscript',
'candlestick_chart',
'margin',
'text_increase',
'wrap_text',
'border_clear',
'align_vertical_top',
'subscript',
'align_vertical_center',
'polyline',
'border_style',
'data_array',
'padding',
'border_outer',
'pentagon',
'text_decrease',
'line_axis',
'insert_page_break',
'border_inner',
'border_left',
'border_bottom',
'format_textdirection_l_to_r',
'border_vertical',
'border_horizontal',
'format_textdirection_r_to_l',
'border_top',
'border_right',
'shape_line',
'type_specimen',
'file_download',
'file_download',
'file_upload',
'download',
'folder',
'grid_view',
'upload_file',
'cloud_upload',
'folder_open',
'cloud',
'text_snippet',
'request_quote',
'cloud_download',
'drive_file_rename_outline',
'upload',
'attachment',
'download_for_offline',
'create_new_folder',
'downloading',
'newspaper',
'folder_shared',
'cloud_done',
'topic',
'download_done',
'cloud_queue',
'workspaces',
'approval',
'cloud_off',
'drive_file_move',
'drive_folder_upload',
'file_download_done',
'file_open',
'attach_email',
'rule_folder',
'cloud_sync',
'cloud_circle',
'snippet_folder',
'folder_zip',
'file_download_off',
'folder_copy',
'difference',
'drive_file_move_rtl',
'folder_delete',
'folder_off',
'format_overline',
]
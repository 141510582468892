import { Component, OnInit, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { FormComponent } from 'src/app/shared/abstracts/form-component';
import { IClient } from '../../interfaces/client';
import { NativeAdsUserService } from '../../services/native-ads-user.service';

@Component({
  selector: 'native-ads-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss']
})
export class ClientFormComponent extends FormComponent<IClient, NativeAdsUserService> implements OnInit {
  save(): void {
    if(this.formData.valid) {
      const value = this.formData.value
      this.savingForm = true
      this.baseService.save(value, value.id)
        .subscribe({
          error: err => {
            this.savingForm = false;
            this.snackBar.open('Client has been saved FAILED!', 'Close', {
              duration: 2000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
            //this.initForm();
          },
          next: d=> {
            this.loadedModel = d
            this.initForm()
            this.savingForm = false
            this.snackBar.open('Client has been saved SUCCESSFULLY!', 'Close', {
              duration: 2000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
            this.router.navigate(['native-ads/client/update/', d.id])
          }
        })
    }
  }

  constructor(
    public readonly baseService: NativeAdsUserService,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { ILookup } from '../interfaces/ilookup';

@Injectable({
  providedIn: 'root'
})
export class ConsumerReportLookupService {

  constructor(private readonly http: HttpClient) { }

  list(type: string, groupName: string) {
    return this.http.get<ICommonListingResponse<ILookup[]>>(environment.consumerReport.baseURL + environment.consumerReport.listLookup, {
      params: {
        type,
        groupName
      }
    })
  }
}

<div class="row mb-3">
  <div class="col-12 text-right">
    <button mat-raised-button color="primary" [routerLink]="['/salon-finder/advertisers/add']">Add New
      Advertiser</button>
    <button mat-raised-button color="primary" (click)="resyncAllServices()">Resync All Services</button>
  </div>
</div>
<div class="row mb-3">
  <mat-form-field class="fullWidth col-12" appearance="fill">
    <input type="text" placeholder="Type anything to search advertiser" aria-label="Type anything to search advertiser"
      matInput appearance="fill" autocomplete="off" #searchTextInput>
  </mat-form-field>
</div>
<div class="row">
  <div class="col-12">
    <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
      <ng-container matColumnDef="Advertiser">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Advertiser</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}">
          {{ element.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Outlets">
        <th mat-header-cell *matHeaderCellDef>Outlets</th>
        <td mat-cell *matCellDef="let element">
          <p class="mt-3" style="text-decoration: underline;" *ngIf="element.outletCount>0; else elseBlock">
            <a routerLink="/salon-finder/advertisers/outlets/list/{{element.id}}">
              {{ element.outletCount }}
            </a><br>
            <a mat-icon-button color="primary" routerLink="/salon-finder/advertisers/outlets/add/{{element.id}}">
              <mat-icon matTooltip="Add Outlet">add</mat-icon>
            </a>
          </p>
          <ng-template #elseBlock>
            <p [ngClass]="{'deactivated':element.active===0}">
              <a mat-icon-button color="primary" routerLink="/salon-finder/advertisers/outlets/add/{{element.id}}">
                <mat-icon matTooltip="Add Outlet">add</mat-icon>
              </a>
            </p>
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="Services">
        <th mat-header-cell *matHeaderCellDef>Services</th>
        <td mat-cell *matCellDef="let element">
          <p class="mt-3" style="text-decoration: underline;" *ngIf="element.serviceCount>0; else elseBlock">
            <a routerLink="/salon-finder/advertisers/services/list/{{element.id}}">
              {{ element.serviceCount }}
            </a><br>
            <a mat-icon-button color="primary" routerLink="/salon-finder/advertisers/services/add/{{element.id}}">
              <mat-icon matTooltip="Add Service">add</mat-icon>
            </a>
          </p>
          <ng-template #elseBlock>
            <p [ngClass]="{'deactivated':element.active===0}">
              <a mat-icon-button color="primary" routerLink="/salon-finder/advertisers/services/add/{{element.id}}">
                <mat-icon matTooltip="Add Service">add</mat-icon>
              </a>
            </p>
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="Promos">
        <th mat-header-cell *matHeaderCellDef>Promos</th>
        <td mat-cell *matCellDef="let element">
          <p class="mt-3" style="text-decoration: underline;" *ngIf="element.promotionCount>0; else elseBlock">
            <a routerLink="/salon-finder/advertisers/promotions/list/{{element.id}}">
              {{ element.promotionCount }}
            </a><br>
            <a mat-icon-button color="primary" routerLink="/salon-finder/advertisers/promotions/add/{{element.id}}">
              <mat-icon matTooltip="Add Promo">add</mat-icon>
            </a>
          </p>
          <ng-template #elseBlock>
            <p [ngClass]="{'deactivated':element.active===0}">
              <a mat-icon-button color="primary" routerLink="/salon-finder/advertisers/promotions/add/{{element.id}}">
                <mat-icon matTooltip="Add Promo">add</mat-icon>
              </a>
            </p>
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="Items In Preview">
        <th mat-header-cell *matHeaderCellDef>Items In Preview</th>
        <td mat-cell *matCellDef="let element">
          <p class="mt-3" style="text-decoration: underline;"
            *ngIf="element.active<2 && element.servicePreviewCount>=1; else elseBlock">
            <a href="{{wpURL}}/{{element.slug}}" target="_blank">
              {{ element.servicePreviewCount }}
            </a><br>
            <a mat-icon-button color="primary" (click)="allgolive(element)">
              All Go Live
            </a>
          </p>
          <ng-template #elseBlock>
            <p>0</p>
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element;">
          <span *ngIf="element.active<5 && element.active===2;">
            Pending
          </span>
          <span *ngIf="element.active<5 && element.active!==2;">
            <a mat-icon-button color="primary" routerLink="/salon-finder/advertisers/update/{{element.id}}">
              <mat-icon matTooltip="Edit" matTooltipPosition="left">edit</mat-icon>
            </a>
            <a mat-icon-button color="warn" (click)="deactivate(element)" [hidden]="element.active===0">
              <mat-icon matTooltip="Deactivate" matTooltipPosition="right">remove_circle</mat-icon>
            </a>
            <a mat-icon-button color="warn" (click)="handleActivate(element)" [hidden]="element.active===1">
              <mat-icon matTooltip="Activate" matTooltipPosition="below" class="color-green">check_circle</mat-icon>
            </a>
            <a mat-icon-button color="warn" (click)="handleSendEmail(element)">
              <mat-icon matTooltip="Email Onboarding" matTooltipPosition="right" class="color-pink">email</mat-icon>
            </a>
          </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="listingCols" class="p-2"></tr>
      <tr mat-row *matRowDef="let row; columns: listingCols;" class="p-2"></tr>
    </table>
  </div>
</div>
<div class="row mb-4">
  <div class="col-12">
    <mat-paginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" [pageIndex]="page"
      (page)="changePage($event)">
    </mat-paginator>
  </div>
</div>

<ng-container *ngIf="loading$ | async">
  <div class="overlay"></div>
  <mat-progress-spinner [mode]="'indeterminate'" [diameter]="50"></mat-progress-spinner>
</ng-container>
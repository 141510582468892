import { SelectionModel } from '@angular/cdk/collections';
import { HttpParams } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { Router, ActivatedRoute, Params } from '@angular/router';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { IService } from '../../interfaces/IService';
import { IServicePromotion } from '../../interfaces/IServicePromotion';
import { LoadingService } from '../../services/loading.service';
import { SalonFinderServiceService } from '../../services/salon-finder-service.service';

@Component({
  selector: 'salon-finder-service-index',
  templateUrl: './service-index.component.html',
  styleUrls: ['./service-index.component.scss']
})
export class ServiceIndexComponent extends ListingComponent<IService> implements OnInit, OnChanges, AfterViewInit {
  
  @Input('advertiserID')
  advertiserID: number = null

  loading$: Subscription
  dataLoaded = false;

  syncingServices = []

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    private readonly salonFinderServiceService: SalonFinderServiceService,
    private readonly router: Router,
    public readonly activatedRoute: ActivatedRoute,
    private readonly snackBar: MatSnackBar,
    private readonly cdr: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    public loader: LoadingService
  ) {
    super();
    this.makeListingCols()
  }
  ngAfterViewInit(): void {
    this.setUpSearchTextInput()
  }

  makeListingCols() {
    this.listingCols = !this.advertiserID?[
      'Advertiser',
      'Service Name',
      'Price',
      'Outlets',
      'Types',
      'Promotions',
      'Purchased',
      'Status',
      'Action'
    ]: [
      'Service Name',
      'Price',
      'Outlets',
      'Types',
      'Promotions',
      'Purchased',
      'Status',
      'Action'
    ];
  }

  hasPromotions(servicePromotions: IServicePromotion[]) {
    return servicePromotions?.filter(s=>moment().isBetween(moment(s.startDate), moment(s.endDate))).length>0?true:false
  }

  currentPromotions(servicePromotions: IServicePromotion[]) {
    return servicePromotions?.filter(s=>moment().isBetween(moment(s.startDate), moment(s.endDate)))?.sort(s=>moment(s.startDate).isSameOrBefore(moment(s.endDate))?-1:1)?.shift()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.advertiserID && changes.advertiserID.currentValue!==undefined) {
      if (changes.advertiserID.currentValue.toString() !== this.advertiserID.toString()) {
        this.defaultParams = {
          advertiserID: this.advertiserID?.toString()
        }
        if(this.loading$) {
          this.loading$.unsubscribe()
          this.loading$ = null
        }
        this.populateList()
        this.makeListingCols()
      }
    }
  }

  isSync(id: number) {
    return this.syncingServices.findIndex(s=>s.id === id) >=0
  }

  resyncAllServices() {
    this.salonFinderServiceService.resyncByAdvertiser(this.advertiserID)
      .subscribe(d=> {
        this.populateList()
      })
  }

  resyncProduct(services: IService[]) {
    for(const service of services) {
      this.syncingServices.push(service)
    }
    
    this.salonFinderServiceService.resync(services)
      .subscribe(d=>{
        this.syncingServices = this.syncingServices.filter(s=>d.data.findIndex(f=>f.id === s.id)<0)
      })
  }

  populateList(params?: HttpParams | Params): void {    
    let listParams = this.makeSearchParams()
    if(this.advertiserID) {
      listParams = this.makeSearchParams({
        advertiserID: this.advertiserID,
        limit: -1
      })
    }
    this.loading$ = this.salonFinderServiceService
      .list(listParams)
      .subscribe(r => {
        this.initCommonListingResponse(r);
      });
  }

  getExtraListingParams() {
    throw new Error('Method not implemented.');
  }

  deleteItem(item: IService): void {
    throw new Error('Method not implemented.');
  }

  deactivate(data) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Deactivate Service',
        message: 'Are you sure you want to deactivate this service:' + data.name
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.handleActivate(data);
      }
    });
  }

  ngOnInit() {
    if(this.activatedRoute.snapshot.params.id) {
      this.advertiserID = this.activatedRoute.snapshot.params.id;
    }
    this.populateList();
    this.makeListingCols()
  }

  filterData($event: any) {
    this.dataSource.filter = $event.target.value;
  }

  async handleActivate(serviceData) {
    // console.log(serviceData);
    const selectedService = serviceData;
    const status = selectedService.active ? 0 : 1;
    this.salonFinderServiceService.activateService({id: selectedService.id, status, woo_id: selectedService.wpServiceID})
    .subscribe((data: any) => {
      if (data.active === 1) {
        this.snackBar.open('Service has been activated successfully!', 'Close', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      } if (data.active === 0) {
        this.snackBar.open('Service has been deactivated successfully!', 'Close', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }
      if(this.advertiserID) {
        this.populateList()
        this.makeListingCols()
      } else {
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([`/salon-finder/services/`]);
        });
      }
    });
  }

  async handlePreview(serviceData) {
    const selectedService = serviceData;
    console.log(selectedService);
    // const preview = selectedService.preview ? 0 : 1;

    // const data = {
    //   id: selectedService.id,
    //   wpID: selectedService.wpID,
    //   name: selectedService.name,
    //   price: parseFloat(selectedService.price),
    //   duration: selectedService.duration,
    //   advertiserID: parseInt(selectedService.advertiserID),
    //   description: selectedService.description,
    //   terms: selectedService.terms,
    //   __s_images: selectedService.__s_images,
    //   imageURL: selectedService.__s_images,
    //   __type: selectedService.__type,
    //   __subType: selectedService.__subType,
    //   __outlets: selectedService.__outlets,
    //   preview: selectedService.preview ? 0 : 1,
    //   active: selectedService.status
    // };

    this.salonFinderServiceService.save(selectedService, selectedService.id)
    .subscribe((result) => {
      console.log(result.preview);
      if (result.preview === true) {
        this.snackBar.open('On Preview!', 'Close', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      } if (result.preview === false) {
        this.snackBar.open('Off Preview!', 'Close', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl]);
          console.log(currentUrl);
      });
      // this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      //   this.router.navigate([`/salon-finder/services/`]);
      // });
    });
  }
}

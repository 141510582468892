import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { merge, startWith, switchMap, map } from 'rxjs';
import { ConfirmDeleteDialogComponent } from 'src/app/consumer-report/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { TelegramCollectionService } from '../../services/telegram-collection.service';

export interface Element {
  select: boolean;
  createdAt?: Date;
  campaign?: number;
  client?: number;
  description?: string;
  shortUrl?: string;
  highlighted?: boolean;
}


@Component({
  selector: 'app-message-index',
  templateUrl: './message-index.component.html',
  styleUrls: ['./message-index.component.scss']
})
export class MessageIndexComponent implements OnInit, AfterViewInit {


  displayedColumns: string[] = ['createdAt', 'username' , 'message' ];
  linkDataSource: MatTableDataSource<any>;

  tempData: any
  selectedShortUrl: any
  resultsLength:any
  pageSize:number = 10
  chats:any = []

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, ) sort: MatSort;

  isLoadingResults = true;

  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly telegramCollectionService: TelegramCollectionService,
  ) { 
  }


  selecteRow(element) {
      this.selectedShortUrl = element;
  }

  highlight(element: Element) {
      element.highlighted = !element.highlighted;
  }

  applyFilter(event: Event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.linkDataSource.filter = filterValue.trim().toLowerCase();
      if (this.linkDataSource.paginator) {
          this.linkDataSource.paginator.firstPage();
      }
  }

  ngOnInit(): void {
    this.telegramCollectionService.getAllChat(0,this.pageSize,'').subscribe((res:any)=>{
      // console.log(res);
      this.resultsLength = res.length;
      this.linkDataSource = new MatTableDataSource(res);
      this.chats = res;
    })
  }

  ngAfterViewInit() {
    console.log(this.sort);
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
           return this.telegramCollectionService.getAllChat(
            this.paginator.pageIndex,
            this.pageSize,
            ''
           )
        }),
        map(response => {
          
          console.log(response);
          
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;

          if (!response) {
            return [];
          }

          // Only refresh the result length if there is new data. In case of rate
          // limit errors, we do not want to reset the paginator to zero, as that
          // would prevent users from re-triggering requests.
          // this.resultsLength = response.totalData;
          // return response.data;
          return []
        }),
      )
      .subscribe(data => {
        this.linkDataSource = new MatTableDataSource(data);
        this.chats = data;
    });
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UpdateComponent } from 'src/app/shared/abstracts/update-component';
import { IPublisher } from '../../interfaces/publisher';
import { NativeAdsPublisherService } from '../../services/native-ads-publisher.service';

@Component({
  selector: 'app-publisher-update',
  templateUrl: './publisher-update.component.html',
  styleUrls: ['./publisher-update.component.scss']
})
export class PublisherUpdateComponent extends UpdateComponent<IPublisher, NativeAdsPublisherService> implements OnInit {

  constructor(public readonly route: ActivatedRoute, public readonly baseService: NativeAdsPublisherService) {
    super();
  }

  ngOnInit(): void {
    this.init(this.route)
  }

}

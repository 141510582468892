import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ICollection } from '../interfaces/ICollection';
import { of, Observable } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { IService } from '../interfaces/IService';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { SalonFinderUploadImagesService } from './salon-finder-upload-images.service';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SalonFinderCollectionService extends CRUDService<ICollection> {
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }

  baseURL = environment.salonFinder.baseURL;
  environmentCRUDPath = environment.salonFinder;
  apiPath = '/collections';

  constructor(
    public httpClient: HttpClient,
    private fb: FormBuilder,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute) {
      super()
  }
  
  list(params?: HttpParams): Observable<ICommonListingResponse<ICollection[]>> {
    return this.httpClient.get<ICommonListingResponse<ICollection[]>>(this.listURL, {
      params: params
    })
  }
  delete(id: string | number): Observable<ICollection> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: ICollection): Observable<ICollection> {
    return this.save(model, id)
  }
  create(model: ICollection): Observable<ICollection> {
    return this.save(model, model.id)
  }
  // save(model: ICollection, id?: string | number): Observable<ICollection> {
  //   return this.httpClient.post<ICollection>(this.saveURL(id), model);
  // }

  makeFormGroup(loadedModel?: ICollection): FormGroup {
    return this.fb.group({
      id: this.fb.control(loadedModel?.id), 
      name: this.fb.control(loadedModel?.name, [
        Validators.required,
        Validators.maxLength(125),
        Validators.nullValidator
      ]),
      startDate: this.fb.control(loadedModel?.startDate, [
        Validators.required,
        Validators.nullValidator
      ]),
      endDate: this.fb.control(loadedModel?.endDate, [
        Validators.required,
        Validators.nullValidator
      ]),
      slug: this.fb.control(loadedModel?.slug, [
        Validators.required,
        Validators.maxLength(50),
        Validators.nullValidator
      ]),
      pageTitle: this.fb.control(loadedModel?.pageTitle, [
        Validators.required,
        Validators.maxLength(150),
        Validators.nullValidator
      ]),
      metaDescription: this.fb.control(loadedModel?.metaDescription, [
        Validators.required,
        Validators.maxLength(150),
        Validators.nullValidator
      ]),
      featured: this.fb.control(loadedModel?.featured),
      status: this.fb.control(loadedModel?.status, [
        Validators.required,
        Validators.nullValidator
      ]),
      active: this.fb.control(loadedModel?.active),
      background_image: this.fb.control(loadedModel?.background_image), 
      uploadImage: this.fb.control(loadedModel?.uploadImage), 
      uploadImageID: this.fb.control(loadedModel?.uploadImageID)
    });
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  getAllData(params?: HttpParams) {
    return this.httpClient
      .get<ICommonListingResponse<ICollection[]>>(environment.salonFinder.baseURL + environment.salonFinder.collections + '/', {
        params: params
      })
  }

  // create data
  createData(data:any):Observable<any>
  {
    // console.log(data);
    return this.httpClient.post(environment.salonFinder.baseURL + environment.salonFinder.collections + '/', data);
  }

  // deactivate data
  deactivate(id:any, data:any):Observable<any>
  {
    return this.httpClient.post(environment.salonFinder.baseURL + environment.salonFinder.collections + '/' + id, data);
  }

  // update data
  updateData(id:any, data:any):Observable<any>
  {
    return this.httpClient.post(environment.salonFinder.baseURL + environment.salonFinder.collections + '/' + id, data);
  }

  // get single data
  get(id: string): Observable<ICollection> {
    return this.httpClient.get<ICollection>(`${this.getURL}/${id}`);
  }

  // upload image
  // uploadImage(image:File):Observable<any>
  // {
  //   const formData = new FormData();

  //   formData.append('image', image);
  //   // console.log(formData);
  //   return this.httpClient.post(`/upload`,formData);
  // }

  // add service
  addCollectionService(data:any)
  {
    console.log(data);
    return this.httpClient.post(environment.salonFinder.baseURL + environment.salonFinder.collections + environment.salonFinder.addCollectionsServices, data);
  }
}
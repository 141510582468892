import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, first, map, startWith, switchMap, tap } from 'rxjs/operators';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { ICRUDPath } from 'src/app/shared/interfaces/iCRUDPath.interface';
import { environment } from 'src/environments/environment';
import { IMasterData } from '../interfaces/master-data';

@Injectable({
  providedIn: 'root'
})
export class NativeAdsMasterDataService extends CRUDService<IMasterData> {
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }

  loadedMasterData: IMasterData[]

  masterData$: BehaviorSubject<IMasterData[]> = new BehaviorSubject<IMasterData[]>(null);

  list(params?: HttpParams): Observable<ICommonListingResponse<IMasterData[]>> {
    return this.httpList(params)
      .pipe(
        tap(d=> {
          this.loadedMasterData = d.data
        })
      )
  }

  listCache(params?: HttpParams): Observable<IMasterData[]> {
    if(!this.masterData$.value) {
      this.list(params)
        .subscribe(d=>this.masterData$.next(d.data))
    }
    return this.masterData$.pipe(filter(f=>f != null));
  }

  get(id: string | number): Observable<IMasterData> {
    throw new Error('Method not implemented.');
  }
  delete(id: string | number): Observable<IMasterData> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: IMasterData): Observable<IMasterData> {
    throw new Error('Method not implemented.');
  }
  create(model: IMasterData): Observable<IMasterData> {
    throw new Error('Method not implemented.');
  }
  makeFormGroup(loadedModel?: IMasterData) {
    return this.fb.group({
      id:[loadedModel?.id],
      reference: [loadedModel?.reference],
      identifier: [loadedModel?.identifier],
      value: [loadedModel?.value],
      active: [loadedModel?.active],
      default: [loadedModel?.default],
      order: [loadedModel?.order],
    })
  }
  apiPath=environment.nativeAds.masterdataBase 
  environmentCRUDPath = environment.nativeAds;

  filterReference(ref: string) {
    return this.loadedMasterData.filter(d=>d.reference === ref).sort((a,b)=>a.order - b.order)
  }

  filterReference$(ref: string) {
    return this.listCache().pipe(
      map(p=>p.filter(d=>d.reference === ref).sort((a,b)=>a.order - b.order))
    )
  }

  getMasterDataModel$(id: number) {
    return this.listCache().pipe(
      map(d=> {
        return d.find(_d=>_d.id === id)
      })
    )
  }

  constructor(
    public readonly httpClient: HttpClient,
    private readonly fb: FormBuilder,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute
  ) {
    super();
  }
}

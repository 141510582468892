import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { UserRequestTypeEnum } from '../../enums/userRequestTypeEnum';
import { IRole } from '../../interfaces/iroles';
import { IUser } from '../../interfaces/iuser';
import { IUserWorkActivity } from '../../interfaces/iuser-work-activity';
import { RoleService } from '../../services/role.service';
import { UserService } from '../../services/user.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordDialogComponent } from 'src/app/shared/components/change-password-dialog/change-password-dialog.component';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'security-guards-user-logs-index',
  templateUrl: './user-logs-index.component.html',
  styleUrls: ['./user-logs-index.component.scss']
})
export class UserLogsIndexComponent extends ListingComponent<IUser> implements OnInit {

  public role$: BehaviorSubject<IRole[]> = new BehaviorSubject([]);

  public cognitoStatuses = [
    { value: 'CONFIRMED', label: 'Confirmed' },
    { value: 'FORCE_CHANGE_PASSWORD', label: 'Pending first login' },
    { value: 'EXTERNAL_PROVIDER', label: 'Google Login' },
    { value: 'RESET_REQUIRED', label: 'Requested Reset Password' },
    { value: 'UNCONFIRMED', label: 'Pending Admin confirmation' },
    { value: 'UNKNOWN', label: 'Unknown'}
  ]

  populateList<U extends HttpParams | Params>(params?: U, urlHistory?: boolean): void {
    const searchParams = this.makeSearchParams({
      ...params,
      userRequestType: UserRequestTypeEnum.AUTH
    }, true)
    this.commonPopulateList(searchParams, true)
  }

  getExtraListingParams(): void {
    throw new Error('Method not implemented.');
  }

  combineWorkActivity(model: IUserWorkActivity) {    
    return moment().year(model.year).month(model.month).toDate()
  }

  convertDateFormat(datetime: string) {    
    return moment(datetime).add(8, 'h').toDate()
  }

  getCognitoStatusLabel(status) {
    return this.cognitoStatuses.find(s=>s.value === status)?.label || "Unknown"
  }
  changeUserPassword(user: IUser) {
    console.log(user)
    this.dialog.open(ChangePasswordDialogComponent, {
      data: {
        displayName: user.displayName,
        cognitoUserName: user.id
      }
    })
  }

  constructor(
    public baseService: UserService,
    public readonly roleService: RoleService,
    public mainRouter: Router,
    public activatedRoute: ActivatedRoute,
    private readonly dialog: MatDialog,
    private readonly authService: AuthService
  ) {
    super();
    this.listingCols = [
      'name',
      'email',
      'roles',
      'loginActivity',
      'workActivity',
      'cognitoStatus',
      'action'
    ];
  }

  ngOnInit(): void {
    const queryParams = {...this.activatedRoute.snapshot.queryParams}
    if(queryParams.cognitoStatus == null) {
      queryParams.cognitoStatus = ['CONFIRMED', 'EXTERNAL_PROVIDER']
    }
    this.initSearchForm(queryParams);
    this.roleService.list(this.makeSearchParams({
      limit: -1
    })).subscribe(r=>{
      this.role$.next(r.data)
      if(queryParams.roleIDs) {
        const roles = r.data.filter(role=>queryParams.roleIDs.includes(role.id.toString()))
        this.searchFormGroup.get('roles').setValue(roles)  
      }      
    })
    
    this.populateList(queryParams, true);
    this.searchFormGroupValueChange$.subscribe((valueChanges) => {      
      if(valueChanges) {
        const searchParams = Object.assign({}, valueChanges);
        searchParams.roleIDs = valueChanges?.roles?.map(r=>r.id) || []
        delete searchParams.roles
        this.populateList(searchParams, true);
      }
    });
  }

}

import { IFollower } from "../../interfaces/IFollower";

const moment = require('moment');

export const GenerateFollowerDateList = async (startDate: Date, endDate: Date, followerData: IFollower[]) => {

    let mergeDatas = mergeObjectsByDate(followerData)

    const dateArray: IFollower[] = [];
    for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {

        let index = mergeDatas.findIndex(i => moment(i.addedDate).format("DD MMM YYYY") == moment(date).format("DD MMM YYYY"))
        if(index == -1){
            const dateObj: IFollower = {
                addedDate: new Date(date).toDateString(),
                channelID: '',
                createdDate:undefined,
                id: 0,
                joined: 0,
                left: 0,
                totalFollowers: 0,
                label: moment(date).format("DD MMM YYYY")
            };
            dateArray.push(dateObj);
        }else{
            let follower = {label: moment(mergeDatas[index].addedDate).format("DD MMM YYYY") ,...mergeDatas[index]} as IFollower;
            dateArray.push(follower);
        }
    }
    return dateArray;
}

export const GetWeeksInRange = async (startDate: Date, endDate: Date, followerData: IFollower[]) => {

    let mergeDatas = mergeObjectsByDate(followerData)
      const weekArray = [];
      let currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        const weekStart = new Date(currentDate);
        const weekEnd = new Date(new Date(currentDate).setDate(currentDate.getDate() + 6));
        const weekLabel = moment(weekStart).format("DD MMM YYYY") + ' - ' + moment(weekEnd).format("DD MMM YYYY");
        weekArray.push({ start: weekStart, end: weekEnd, label: weekLabel, joined: 0, left: 0 });
        currentDate.setDate(currentDate.getDate() + 7);
      }
  
      for (let data of mergeDatas) {
        let index = weekArray.findIndex((i: any) => {
          let range = moment(data.addedDate).isBetween(i.start, i.end, null, []);
          if (range) return i;
        })
  
        if (index != -1) {
          weekArray[index].joined += data.joined == undefined ? 0 : data.joined;
          weekArray[index].left += data.left == undefined ? 0 : data.left;
        }
  
      }
      return weekArray;
  }
  
  export const GetMonthsInRange = async (startDate: Date, endDate: Date, followerData: IFollower[]) => {
      
    let mergeDatas = mergeObjectsByDate(followerData)
      const monthArray = [];
      let currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        const monthStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const monthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59, 999);
        const monthLabel = `${monthStart.toLocaleDateString('default', { month: 'long' })} ${monthStart.getFullYear()}`;
        monthArray.push({ start: monthStart, end: monthEnd, label: monthLabel, joined: 0, left: 0  });
        currentDate.setMonth(currentDate.getMonth() + 1);
      }
  
      for (let data of mergeDatas) {
        let index = monthArray.findIndex((i: any) => {
          let range = moment(data.addedDate).isBetween(i.start, i.end, null, []);
          if (range) return i;
        })
  
        if (index != -1) {
          monthArray[index].joined += data.joined != undefined ? data.joined : 0;
          monthArray[index].left += data.left != undefined? data.left :0;
        }
  
      }
  
      return monthArray;
  }



function mergeObjectsByDate(arr: IFollower[]): IFollower[] {
    const result: IFollower[] = [];
    const map: Map<string, IFollower> = new Map();
  
    for (const obj of arr) {
        const dateString = obj.addedDate !== undefined ? obj.addedDate.toString() : '';
  
        if (map.has(dateString)) {
            const existingObj: any = map.get(dateString);
            existingObj!.joined += obj.joined;
            existingObj!.left += obj.left;
        } else {
            map.set(dateString, obj);
        }
    }
  
    map.forEach((obj) => result.push(obj));
    return result;
  }
import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { fromEvent } from 'rxjs';
import { map, filter, debounceTime, switchMap } from 'rxjs/operators';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { IForm } from '../../interfaces/form';
import { IQuestion } from '../../interfaces/question';
import { ConsumerReportQuestionService } from '../../services/consumer-report-question.service';
import { Params } from '@angular/router';

@Component({
  selector: 'app-question-index',
  templateUrl: './question-index.component.html',
  styleUrls: ['./question-index.component.scss']
})
export class QuestionIndexComponent extends ListingComponent<IQuestion> implements OnInit, AfterViewInit {
  populateList<U extends HttpParams | Params>(params?: U, urlHistory?: boolean): void {
    this.commonPopulateList(params, urlHistory)
  }
  getExtraListingParams(): void {
    throw new Error('Method not implemented.');
  }
  deleteItem(item: IQuestion): void {
    this.baseService.delete(item.id)
  }

  formCols = [
    'label',
    'type',
    'formAmt',
    'ansAmt',
    'action'
  ];
  
  constructor(
    public override readonly baseService: ConsumerReportQuestionService
  ) { 
    super()
  }
  ngOnInit(): void {
    this.populateList()
  }
}

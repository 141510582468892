import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { UrlShortenerCollectionService } from "../../services/url-shortener-collection.service";

export interface DialogData {
    id: number;
    status: any;
  }
  

@Component({
    selector: 'archived-confirmation',
    templateUrl: 'archived-confirmation.html',
    styleUrls: ['./archived-confirmation.scss']
  })
  export class ArchivedDialog {

    isDisabled = true;
    archived:any;
    constructor(
      public dialogRef: MatDialogRef<ArchivedDialog>,
      private readonly urlShortenerService: UrlShortenerCollectionService,
      private readonly snackBar: MatSnackBar,
      @Inject(MAT_DIALOG_DATA) public data: DialogData,
      private router: Router
    ) {}
  
    onNoClick(): void {
      this.dialogRef.close();
    }

    changeUpperCase(textToUpper: string){
        var newWord = textToUpper.toUpperCase();
        if(this.data?.status === 1){
          if(newWord === 'ARCHIVED'){
              this.isDisabled = false;
          }else{
              this.isDisabled = true;
          }
        }else{
          if(newWord === 'UNARCHIVED'){
            this.isDisabled = false;
          }else{
              this.isDisabled = true;
          }
        }
    }
    seePages(){
      this.router.navigate(['/short-url/detail/'+this.data.id]);
      this.dialogRef.close();
    }
    
    archivedClicks(){
      let status = this.data?.status == 0 ? 'activate' : 'archive'; 
        
      this.urlShortenerService.archived(this.data.id, status).subscribe((result:any) =>{
            if(result.status === "Success"){
                this.dialogRef.close();
                let responseMessage =  status == 'active' ? 'Short url data is activated.' : 'Short url data stored successfully.';
                this.snackBar.open(responseMessage, 'Close', {
                    duration: 2000,
                    horizontalPosition: 'center',
                    verticalPosition: 'top'
                  });
                  this.dialogRef.close({ data: 'success' });
            }else{
                this.snackBar.open('Failed to store data.', 'Close', {
                    duration: 2000,
                    horizontalPosition: 'center',
                    verticalPosition: 'top'
                  });
            }
        })
    }
  }
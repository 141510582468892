import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IEmailNotification } from '../../interfaces/iemail-notification';
import { SalonFinderEmailNotificationService } from '../../services/salon-finder-email-notification.service';

@Component({
  selector: 'app-email-notification-create',
  templateUrl: './email-notification-create.component.html',
  styleUrls: ['./email-notification-create.component.scss']
})
export class EmailNotificationCreateComponent implements OnInit {

  emailNotificationForm: IEmailNotification

  constructor(
    private readonly emailNotificationService: SalonFinderEmailNotificationService) { 
      
    }

  ngOnInit(): void {

  }

}

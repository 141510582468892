import { Component, Input, OnInit } from '@angular/core';
import { IPublisher } from '../../interfaces/publisher';

@Component({
  selector: 'native-ads-publisher-icon',
  templateUrl: './publisher-icon.component.html',
  styleUrls: ['./publisher-icon.component.scss']
})
export class PublisherIconComponent implements OnInit {

  @Input()
  publisherModels: IPublisher[]

  constructor() { }

  ngOnInit(): void {
    
  }

}

<div class="row mb-3">
  <div class="col-12 text-right">
    <button mat-raised-button color="primary" [routerLink]="['/consumer-report/form/create']">Add New Form</button>
  </div>
</div>
<div class="row mb-3">
<mat-form-field class="fullWidth col-12" appearance="fill">
  <mat-chip-grid #searchList aria-label="Search">
    <mat-chip-row *ngFor="let term of searchTerms" (removed)="remove(term)">
      {{term}}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip-row>
    <input class="fullWidth" placeholder="Search by email or name. Hit enter to filter" #searchInput
      [formControl]="searchCtrl" [matChipInputFor]="searchList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event)">
  </mat-chip-grid>
</mat-form-field>
</div>
<div class="row">
  <div class="col-12">
    <div class="col-12 export-container">
      <button mat-raised-button color="primary" (click)="exportExcel()" >Export Participants</button>
    </div>
    <table mat-table [dataSource]="lists" class="mat-elevation-z8" style="width: 100%">
      <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef> Participant Name </th>
        <td mat-cell *matCellDef="let element"> 
          <span> {{ element.participant.firstName }} {{ element.participant.lastName }}</span><br/>
        </td>
      </ng-container>
      <!-- <ng-container matColumnDef="displayName">
        <th mat-header-cell *matHeaderCellDef> Participant Name </th>
        <td mat-cell *matCellDef="let element"> 
          {{ element.participant.firstName }} {{ element.participant.lastName }}
        </td>
      </ng-container> -->
      <ng-container matColumnDef="submissionDate">
        <th mat-header-cell *matHeaderCellDef> Date of Submission </th>
        <td mat-cell *matCellDef="let element;"> {{ element.addedDate | date:'d MMMM y - HH:mm:ss a' }} </td>
      </ng-container>
      
      <ng-container matColumnDef="formName">
        <th mat-header-cell *matHeaderCellDef> Survey Name </th>
        <td mat-cell *matCellDef="let element;"> {{ element.survey.name }} </td>
      </ng-container>

      <ng-container matColumnDef="score">
        <th mat-header-cell *matHeaderCellDef> Points </th>
        <td mat-cell *matCellDef="let element;"> {{ element.score }} </td>
      </ng-container>
      
      <ng-container matColumnDef="chances">
        <th mat-header-cell *matHeaderCellDef> Chances </th>
        <td mat-cell *matCellDef="let element;"> {{ calculateChances(element) }} </td>
      </ng-container>

      <ng-container matColumnDef="completed">
        <th mat-header-cell *matHeaderCellDef> Pages Completes </th>
        <td mat-cell *matCellDef="let element;"> {{ element.lastPage }} </td>
      </ng-container>

      <ng-container matColumnDef="action" >
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element;">
          <mat-spinner [hidden]="!reloading"></mat-spinner>
          <span [hidden]="reloading">
            <a mat-icon-button color="primary" (click)="reProcessSubmission(element)">
              <mat-icon>sync</mat-icon>
            </a> 
            <a mat-icon-button color="primary" (click)="generateLink(element)">
              <mat-icon>edit</mat-icon>
            </a> 
            <a mat-icon-button color="warn" (click)="delSubmission(element)">
              <mat-icon>remove_circle</mat-icon>
            </a>
          </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="listingCols" class="p-2"></tr>
      <tr mat-row *matRowDef="let row; columns: listingCols;" class="p-2"></tr>
    </table>
  </div>
</div>
<div class="row mb-4">
  <div class="col-12">
    <mat-paginator 
      [length]="total"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      (page)="changePage($event)">
    </mat-paginator>
  </div>
</div>
export const Sentry = {
  dsn: "https://0b68df78f03e4f2b94496783b1eaec0b@o4505169725751296.ingest.sentry.io/4505601548025856",
  traceURL: [],
  env: 'steady',
  tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0,
  projectName: 'darvis-client',
  projectVersion: 'v2.4.68-sp1',
}
import { Component, OnChanges, OnInit, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { FormComponent } from '../../abstracts/form-component';
import { IAdvertiser } from '../../interfaces/IAdvertiser';
import { SalonFinderAdvertiserService } from '../../services/salon-finder-advertiser.service';
import { SalonFinderRegisteredEntityService } from '../../services/salon-finder-registered-entity.service';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { ObjectCannedACL, PutObjectCommand } from '@aws-sdk/client-s3';
import { environment } from 'src/environments/environment';
import { SalonFinderUploadImagesService } from '../../services/salon-finder-upload-images.service';
import { IRegisteredEntity } from '../../interfaces/iregistered-entity';
import { UploadImageLinkTypeEnum } from '../../enums/UploadImageLinkType.enum';
import { AWSServiceService } from '../../../shared/services/awsservice.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'advertiser-form',
  templateUrl: './advertiser-form.component.html',
  styleUrls: ['./advertiser-form.component.scss']
})

export class AdvertiserFormComponent extends FormComponent<IAdvertiser> implements OnInit, OnChanges {

  advertiserForm: FormGroup = undefined;
  savingForm = false;
  selectedValue: string;
  advertiserID: number;
  wpURL = environment.salonFinder.wpURL + environment.salonFinder.wp_preview

  @ViewChild('UploadFileInput') uploadFileInput: ElementRef;
  myfilename = 'Select File';

  logoImg: string = "";


  constructor(
    private readonly fb: FormBuilder,
    private readonly snackBar: MatSnackBar,
    private readonly salonFinderAdvertiserService: SalonFinderAdvertiserService,
    private readonly AWSService: AWSServiceService,
    private readonly salonFinderUploadImageService: SalonFinderUploadImagesService,
    private router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private salonFinderRegisteredEntityService: SalonFinderRegisteredEntityService,
  ) {
    super();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.loadedModel) {
      this.initForm()
    }
  }

  get bankingEntities() {
    return this.formData.get('bankingEntities') as FormArray
  }

  get wordpressPreviewURL() {
    return environment.salonFinder.wpURL + environment.salonFinder.wp_preview + '/' + this.loadedModel.id
  }

  initForm(): void {
    // console.log(this.loadedModel);
    if(this.advertiserID && this.loadedModel.uploadImage){
      this.logoImg = this.loadedModel.uploadImage.url
    }
    else{
      this.logoImg = this.loadedModel.__sImage
    }

    this.formData = this.fb.group({
      id: [this.loadedModel?.id],
      name: this.fb.control(this.loadedModel.name, [
        Validators.required,
        Validators.maxLength(50),
        Validators.nullValidator
      ]),
      email: this.fb.control(this.loadedModel.email, [
        Validators.required,
        Validators.email,
        Validators.maxLength(1024),
        Validators.nullValidator
      ]),
      notificationEmail: this.fb.control(this.loadedModel.notificationEmail, [
        Validators.required,
        Validators.maxLength(1024),
        Validators.nullValidator
      ]),
      marketingEmail: this.fb.control(this.loadedModel.marketingEmail, [
        Validators.required,
        Validators.maxLength(1024),
        Validators.nullValidator
      ]),
      commission: this.fb.control(this.loadedModel.commission, [
        Validators.required,
        Validators.min(0),
        Validators.max(100),
        Validators.pattern('[0-9]*'),
        Validators.nullValidator
      ]),
      description: this.fb.control(this.loadedModel.description),
      terms: this.fb.control(this.loadedModel.terms),
      active: this.fb.control(this.loadedModel.active),
      uploadImageID: this.fb.control(this.loadedModel.uploadImageID),
      preview: this.fb.control(this.wpURL + "/" + this.loadedModel.slug),
      password: this.fb.control(this.loadedModel.password),
      bankingEntities: this.fb.array(this.loadedModel.bankingEntities?.length<=0?[this.makeBankingEntity()]: this.loadedModel.bankingEntities.map(b=>this.makeBankingEntity(b)))
    });
  }

  makeBankingEntity(loadedModel?: IRegisteredEntity): FormGroup {
    return this.fb.group({
      entityName: this.fb.control(loadedModel?.entityName || "", [
        Validators.required,
        Validators.maxLength(50),
        Validators.nullValidator
      ]),
      entityEmail: this.fb.control(loadedModel?.entityEmail || "", [
        Validators.required,
        Validators.maxLength(1024),
        Validators.nullValidator
      ]),
      bankName: this.fb.control(loadedModel?.bankName || "", [
        Validators.required,
        Validators.maxLength(50),
        Validators.nullValidator
      ]),
      bankAccount: this.fb.control(loadedModel?.bankAccount || "", [
        Validators.required,
        Validators.maxLength(50),
        Validators.nullValidator
      ]),
      sendToAdvertiser: this.fb.control(loadedModel?.sendToAdvertiser || true),
      outletID: this.fb.control(loadedModel?.outletID || 0, [RxwebValidators.numeric({allowDecimal: false})]),
      advertiserID: this.fb.control(loadedModel?.advertiserID || this.loadedModel?.id, [RxwebValidators.numeric({allowDecimal: false})])
    });
  }

  ngOnInit(): void {
    this.advertiserID = this.activatedRoute.snapshot.params.id;
  }

  async upload(event) {
    const uploadFileList: FileList | null = event.target.files
    for(var i=0; i<uploadFileList.length; i++) {
      const uploadFile = uploadFileList.item(i);
      const savedFileName = this.salonFinderUploadImageService.getFileName(uploadFile.name)
      let uploadFileLocationKey = this.salonFinderUploadImageService.getPath(savedFileName)
      var params = {
        Body: uploadFile,
        Bucket: environment.salonFinder.uploadBucket,
        Key: uploadFileLocationKey,
        ACL: ObjectCannedACL.public_read,
        ContentType: uploadFile.type,
        ContentLength: uploadFile.size
      };
      const data = await this.AWSService.S3Client.send(new PutObjectCommand(params))
      this.logoImg = environment.salonFinder.bucketURL+"/"+uploadFileLocationKey
      this.salonFinderUploadImageService.save({
        url: environment.salonFinder.bucketURL+"/"+uploadFileLocationKey,
        fileName: savedFileName,
        mimetype: uploadFile.type,
        bucketURL: environment.salonFinder.uploadBucket,
        path: uploadFileLocationKey,
        linkType: UploadImageLinkTypeEnum.ADVERTISER,
        linkID: 0,
        name: uploadFile.name
      }).subscribe(d=>{
        this.formData.patchValue({
          uploadImageID: d.id,
          __sImage: this.logoImg
        })
      })
    }
  }

  onSelectedImage(event) {
    if(event.target.files.length>0) {
      const file = event.target.files[0];
      this.advertiserForm.get('backgroundImage').setValue(file);
    }
  }

  save() {
    if(this.formData.status === 'VALID') {
      this.salonFinderAdvertiserService
        .save(this.formData.value, this.formData.value['id'])
        .subscribe(
          d => {
            this.savingForm = false;
            this.loadedModel = d;
            this.snackBar.open('Advertiser has been saved SUCCESSFULLY!', 'Close', {
              duration: 2000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
            // if(this.formData.value.id)
            //   this.initForm();
            // else {
              this.router.navigate([`/salon-finder/advertisers/update`, d.id]);
            // }
          },
          err => {
            this.savingForm = false;
            this.snackBar.open('Advertiser has been saved FAILED!', 'Close', {
              duration: 2000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
            this.initForm();
          }
        );
    }
  }
}

import { Component, OnInit, SimpleChanges, OnChanges, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from '../../services/loading.service';
import { IOutlet } from '../../interfaces/IOutlet';
import { SalonFinderOutletService } from '../../services/salon-finder-outlet.service';
import { HttpParams } from '@angular/common/http';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { fromEvent, Subscription } from 'rxjs';
import { map, filter, debounceTime, switchMap } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'salon-finder-outlet-index',
  templateUrl: './outlet-index.component.html',
  styleUrls: ['./outlet-index.component.scss']
})
export class OutletIndexComponent extends ListingComponent<IOutlet> implements OnInit, OnChanges, AfterViewInit {

  loading$: Subscription;
  dataLoaded = false;

  @Input('advertiserID')
  advertiserID: number = null

  syncingServices: IOutlet[] = []
  syncingAll: boolean = false;

  constructor(
    private readonly salonFinderOutletService: SalonFinderOutletService,
    private router: Router,
    public readonly activatedRoute: ActivatedRoute,
    private readonly snackBar: MatSnackBar,
    private dialog: MatDialog,
    public loader: LoadingService
  ) {
    super();
    this.makeListingCols()
  }

  makeListingCols() {
    
    this.listingCols = !this.advertiserID?[
      'Advertiser',
      'Outlet Name',
      'Address',
      'Postal Code',
      'Outlet Code',
      'Status',
      'Action'
    ]: [
      'Outlet Name',
      'Address',
      'Postal Code',
      'Outlet Code',
      'Status',
      'Action'
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.advertiserID && changes.advertiserID.currentValue!==undefined) {
      if (changes.advertiserID.currentValue.toString() !== this.advertiserID.toString()) {
        this.defaultParams = {
          advertiserID: this.advertiserID?.toString()
        }
        if(this.loading$) {
          this.loading$.unsubscribe()
          this.loading$ = null
        }
        this.populateList()
        this.makeListingCols()
      }
    }
  }

  deleteItem(item: IOutlet): void {
    throw new Error('Method not implemented.');
  }

  resyncAllServices() {
    this.syncingAll = true
    this.salonFinderOutletService.resyncByAdvertiser(this.advertiserID)
      .subscribe(d=> {
        this.populateList()
        this.syncingAll = false
      })
  }

  isSyncing(outlet: IOutlet) {
    return this.syncingServices.findIndex(s=>s.id === outlet.id) >=0
  }

  resync(services: IOutlet) {  
    this.syncingServices.push(services)  
    this.salonFinderOutletService.resync(services)
      .subscribe(d=>{
        this.syncingServices = this.syncingServices.filter(s=>s.id != d.id)
      })
  }

  async populateList(params?: HttpParams | Params): Promise<void> {
    let listParams = this.makeSearchParams()
    if(this.advertiserID) {
      listParams = this.makeSearchParams({
        advertiserID: this.advertiserID
      })
    }
    this.loading$ = this.salonFinderOutletService
      .list(listParams)
      .subscribe((result) => {
        this.dataSource = new MatTableDataSource(result.data);
        this.total = result.total
      });
  }

  getExtraListingParams() {
    throw new Error('Method not implemented.');
  }

  deactivate(data) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Deactivate Outlet',
        message: 'Are you sure you want to deactivate this outlet:' + data.name
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.handleActivate(data);
      }
    });
  }

  ngOnInit():void {
    if(this.activatedRoute.snapshot.params.id) {
      this.advertiserID = this.activatedRoute.snapshot.params.id;
    }

    this.populateList();
    this.makeListingCols();
  }

  filterData($event: any) {
    console.log($event)
    //this.dataSource.filter = $event.target.value;
  }

  async handleActivate(outletData) {
    const selectedOutlet = outletData;
    const status = selectedOutlet.active ? 0 : 1;
    this.salonFinderOutletService.activateOutlet({id: selectedOutlet.id, status: status, woo_id: selectedOutlet.wpOutletID})
    .subscribe((data: any) => {
      console.log(data);
      if (data.active === 1) {
        this.snackBar.open('Outlet has been activated successfully!', 'Close', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      } if (data.active === 0) {
        this.snackBar.open('Outlet has been deactivated successfully!', 'Close', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }

      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([`/salon-finder/outlets/`]);
      });
    });
  }

  async handleSendEmail(outletData) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Send Email Onboarding',
        message: 'Are you sure you want to send email this outlet : ' + outletData.name
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      console.log(outletData);
      // alert(advertiserData.id);
      if (result === true) {
        this.salonFinderOutletService.sendOnBoardEmail(outletData.advertiserID,outletData.id)
        .subscribe((data: any) => {
          this.snackBar.open('Onboard instructions emailed!', 'Close', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
        });
      }
    });
  }
}
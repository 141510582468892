import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { SalonFinderServiceService } from '../../services/salon-finder-service.service';
import { IService } from '../../interfaces/IService';

@Component({
  selector: 'app-service-update',
  templateUrl: './service-update.component.html',
  styleUrls: ['./service-update.component.scss']
})
export class ServiceUpdateComponent implements OnInit {

  serviceForm: IService;

  dataLoaded = false;
  savingForm = false;
  isLoading = true;
  
  constructor(
    private activatedRoute: ActivatedRoute,   
    private readonly salonFinderServiceService: SalonFinderServiceService,
  ) { 
  }

  ngOnInit(): void {
    this.salonFinderServiceService.get(this.activatedRoute.snapshot.params.id)
      .subscribe(d=>this.serviceForm = d)    
  }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UrlShortenerRouteModule } from './url-shorterner.routes';
import { UrlShortenerIndexComponent } from './pages/url-shortener-index/url-shortener-index.component';
import { RouterModule } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatListModule } from '@angular/material/list';
import { UrlShortenerFormComponent } from './components/url-shortener-form/url-shortener-form.component';
import { UrlShortenerCreateComponent } from './pages/url-shortener-create/url-shortener-create.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DeleteDialog } from './components/delete-dialog/delete-confirmation';
import { ResetDialog } from './components/reset-dialog/reset-confirmation';
import { ArchivedDialog } from './components/archived-dialog/archived-confirmation'
import { UrlShortenerUpdateComponent } from './pages/url-shortener-update/url-shortener-update.component';
import { UrlShortenerDetailComponent } from './pages/url-shortener-detail/url-shortener-detail.component';
import { NgChartsModule } from 'ng2-charts';
import {MatGridListModule} from '@angular/material/grid-list';
import { MatRadioModule } from '@angular/material/radio';
import { ArchivedVerifyDialog } from './components/archived-verify/archived-verify';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { SharedModule } from '../shared/shared.module';
import { WhatsappUrlGeneratorComponent } from './components/whatsapp-url-generator/whatsapp-url-generator.component';
import { TelephoneUrlGeneratorComponent } from './components/telephone-url-generator/telephone-url-generator.component';

@NgModule({
  declarations: [
    UrlShortenerIndexComponent,
    UrlShortenerCreateComponent,
    UrlShortenerFormComponent,
    UrlShortenerUpdateComponent,
    UrlShortenerDetailComponent,
    DeleteDialog,
    ResetDialog,
    ArchivedDialog,
    ArchivedVerifyDialog,
    WhatsappUrlGeneratorComponent,
    TelephoneUrlGeneratorComponent

  ],
  imports: [
    CommonModule,
    SharedModule,
    ClipboardModule,
    MatTableModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatButtonModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatSnackBarModule,
    MatChipsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatCardModule,
    MatDividerModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatGridListModule,
    ReactiveFormsModule,
    MatRadioModule,
    FormsModule,
    MatButtonToggleModule,
    UrlShortenerRouteModule,
    RouterModule,
    NgChartsModule
  ],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL']
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY'
        }
      }
    }
  ]
})
export class UrlShorternerModule { }
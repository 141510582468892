export enum MatricsCompareEnum {
    USING = "Using",
    TRYING = "Thinking of trying",
    YES = "Yes",
    NO = "No",
    TRUST = "I Trust",
    USED_BEFORE = "I’ve used it before"
  }

  export enum DisplayType {
   PIECHART = "pie-chart",
   TABLE = "table",
   SAMPLE_SIZE  = "sample-size",
   STATISTICS = "stats",
   RATING_BAR = "rating-bar",
   VERTICAL_BAR = "vertical-bar",
   HORIZONTAL_BAR = "horizontal-bar",
   COMPARE = "compare",
   STATISTICS_CLOUD = "stats-cloud",
   STATISTICS_GROUP = "stats-group",
	 VERTICAL_BAR_BRAND_COMPARE = "vertical-bar-brand-compare",
   HORIZONTAL_BAR_BRAND_COMPARE = "horizontal-bar-brand-compare",
	 TABLE_GROUP = "table-group",
	 VERTICAL_BAR_GROUP = "vertical-bar-group",
	 HORIZONTAL_BAR_GROUP = "horizontal-bar-group",
  }

  export enum QuestionTypeEnum {
    PROFILE = 'profile'
  }

<div class="row mb-3">
	<div class="col-12 text-right">
		<button mat-raised-button color="primary" [routerLink]="['create']">Add New Client</button>
	</div>
</div>
<div class="row mb-3">
	<mat-form-field class="col-12" appearance="fill">
		<input type="text" placeholder="Type anything to search client" aria-label="Type anything to search client"
			matInput appearance="fill" autocomplete="off" [formControl]='searchTextFormControl'>
	</mat-form-field>
</div>
<div class="row">
	<div class="col-12">
		<table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
			<ng-container matColumnDef="name">
				<th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
				<td mat-cell *matCellDef="let element">
					{{ element.name }}
				</td>
			</ng-container>
			<ng-container matColumnDef="email">
				<th mat-header-cell *matHeaderCellDef>Email</th>
				<td mat-cell *matCellDef="let element">
					{{ element.email}}
				</td>
			</ng-container>
			<ng-container matColumnDef="createdDate">
				<th mat-header-cell *matHeaderCellDef>Created On</th>
				<td mat-cell *matCellDef="let element">
					{{ element.createdDate | date:'dd MMM yyyy' }}
				</td>
			</ng-container>
			<ng-container matColumnDef="contactNo">
				<th mat-header-cell *matHeaderCellDef>Contact No</th>
				<td mat-cell *matCellDef="let element">
					{{ element.contactNumber }}
				</td>
			</ng-container>
			<ng-container matColumnDef="campaignCount">
				<th mat-header-cell *matHeaderCellDef>Campaign Count</th>
				<td mat-cell *matCellDef="let element">
					{{ element.campaignCount }}
				</td>
			</ng-container>

			<ng-container matColumnDef="action">
				<th mat-header-cell *matHeaderCellDef> Action </th>
				<td mat-cell *matCellDef="let element;">
					<span>
						<a mat-icon-button color="primary" routerLink="update/{{element.id}}">
							<mat-icon matTooltip="Edit" matTooltipPosition="left">edit</mat-icon>
						</a>
						<a mat-icon-button color="warn" (click)="removeUser(element)" [hidden]="element.active===0">
							<mat-icon matTooltip="Deactivate" matTooltipPosition="right">delete</mat-icon>
						</a>
					</span>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="listingCols" class="p-2"></tr>
			<tr mat-row *matRowDef="let row; columns: listingCols;" class="p-2"></tr>
		</table>
	</div>
</div>
<div class="row mb-4">
	<div class="col-12">
		<mat-paginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
			[showFirstLastButtons]="true" [pageIndex]="page" (page)="changePage($event)">
		</mat-paginator>
	</div>
</div>

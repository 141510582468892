import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormGroupDirective } from '@angular/forms';
import { IService } from 'src/app/salon-finder/interfaces/IService';
import { FormComponent } from 'src/app/shared/abstracts/form-component';
import { RoleAccessMethodEnum } from '../../enums/roleAccessMethodEnum';

import { IRoleAccess } from '../../interfaces/irole-access';
import { RoleAccessService } from '../../services/role-access.service';
import { ServiceService } from '../../services/service.service';

@Component({
  selector: 'security-guards-role-access-form',
  templateUrl: './role-access-form.component.html',
  styleUrls: ['./role-access-form.component.scss']
})
export class RoleAccessFormComponent extends FormComponent<IRoleAccess, RoleAccessService> implements OnInit {

  @Input("currentTabSelected")
  currentTabSelected: number;

  @Input("index")
  index: number

  @Input("serviceID")
  serviceID: number
  
  @Output()
  addButtonClick = new EventEmitter<number>()

  @Output()
  removeButtonClick = new EventEmitter<number>()

  @Output()
  updatingForm = new EventEmitter<FormGroup>();

  @Output() updateToRoleForm = new EventEmitter<any>();

  save(): void {
    throw new Error('Method not implemented.');
  }

  addForm() {
    this.addButtonClick.emit(this.serviceID);
  }

  removeForm(index:number) {
    if(this.formData.value.id == null){
      this.formData.value.service.roleAccess.splice(index, 1);
      this.updatingForm.emit(this.formData)  
    }else{
      this.updateToRoleForm.emit({
        "index": index,
        "action": "delete",
        "id": this.formData.value.id  
      });
    }
  }

  methodDrop = [
    {
      id: 'ALL',
      name: 'All Method'
    },
    {
      id: 'GET',
      name: 'GET'
    },
    {
      id: 'POST',
      name: 'POST'
    },
    {
      id: 'PUT',
      name: 'PUT'
    },
    {
      id: 'DELETE',
      name: 'DELETE'
    }
  ];

  get services$() {
    return this.serviceService.getDropDown()
  }

  constructor(
    public readonly baseService: RoleAccessService,
    private readonly serviceService: ServiceService,
    private readonly fb: FormBuilder,
    private rootFormGroup: FormGroupDirective
  ) {
    super();
  }

  get rootFormArray() {
    return this.rootFormGroup.control.get("roleAccess") as FormArray
  }

  ngOnInit(): void {
    this.initForm();
    this.formData.valueChanges.subscribe(d=>{
      d.tabIndex = this.currentTabSelected;
      d.rowIndex = this.index;
      d.id = d.id == null ? 0 : d.id;

      this.updateToRoleForm.emit({
          "index": this.index,
          "currentTabSelected": this.currentTabSelected,
          "value": d,
          "action": "create"
      });
    })
  }

}

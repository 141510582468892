<div class="row mb-3">
	<div class="col-12 text-right">
		<button mat-raised-button color="primary" [routerLink]="['/security-guard/roles/create']">Add New Roles</button>
	</div>
</div>					

<form [formGroup]="searchFormGroup" class="row mb-3">
	<div class="col-12">
		<mat-card>
			<mat-card-content>
				<div class="row">
					<mat-form-field class="col-12 col-sm-8" appearance="fill">
						<input type="text" placeholder="Type anything to search role" aria-label="Type anything to search role"
							matInput appearance="fill" autocomplete="off" formControlName="searchText">
					</mat-form-field>
					<mat-form-field class="col-12 col-sm-4" appearance="fill">
						<mat-label>Role</mat-label>
						<mat-select multiple formControlName="groupType">
							<mat-option *ngFor="let type of groupType" [value]="type">
								{{ type }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="row">
					<div class="col-12">
						<button mat-raised-button color="primary" class="m-2" (click)="search()" [disabled]="isLoading">Search</button>
						<button mat-raised-button color="primary" class="m-2 loadingBtn" (click)="syncAllRole()" [disabled]="isLoading">
							<mat-spinner [diameter]="14" [hidden]="!isLoading"></mat-spinner> Sync All Google Groups 
						</button>
					</div>
				</div>
				
			</mat-card-content>
		</mat-card>
	</div>
</form>
<div class="row">
	<div class="col-12">
		<table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
			<ng-container matColumnDef="label">
				<th mat-header-cell mat-sort-header *matHeaderCellDef>Description</th>
				<td mat-cell *matCellDef="let element">
					{{ element.label }}
				</td>
			</ng-container>
			<ng-container matColumnDef="name">
				<th mat-header-cell mat-sort-header *matHeaderCellDef>Role Name</th>
				<td mat-cell *matCellDef="let element">
					{{ element.name }}
				</td>
			</ng-container>
			

			<ng-container matColumnDef="createdDate">
				<th mat-header-cell *matHeaderCellDef>Date</th>
				<td mat-cell *matCellDef="let element">
					{{ element.lastSyncDate | utcdate:'d MMM YYYY hh:mm:ss a' }}
				</td>
			</ng-container>
			<ng-container matColumnDef="groupType">
				<th mat-header-cell *matHeaderCellDef>Type</th>
				<td mat-cell *matCellDef="let element">
					{{ element.type }}
				</td>
			</ng-container>

			<ng-container matColumnDef="userCount">
				<th mat-header-cell *matHeaderCellDef>User Count</th>
				<td mat-cell *matCellDef="let element">
					{{ element.userCount }}
				</td>
			</ng-container>

			<ng-container matColumnDef="accessCount">
				<th mat-header-cell *matHeaderCellDef>Access attached</th>
				<td mat-cell *matCellDef="let element">
					{{ element.accessCount }}
				</td>
			</ng-container>

			<ng-container matColumnDef="action">
				<th mat-header-cell *matHeaderCellDef> Action </th>
				<td mat-cell *matCellDef="let element;">
					<a mat-icon-button color="primary" routerLink="/security-guard/roles/{{element.id}}">
						<mat-icon matTooltip="View" matTooltipPosition="left">visibility</mat-icon>
					</a>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="listingCols" class="p-2"></tr>
			<tr mat-row *matRowDef="let row; columns: listingCols;" class="p-2"></tr>
		</table>
	</div>
</div>
<div class="row mb-4">
	<div class="col-12">
		<mat-paginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
			[showFirstLastButtons]="true" [pageIndex]="page" (page)="changePage($event)">
		</mat-paginator>
	</div>
</div>
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IOutlet } from '../interfaces/IOutlet';
import { of, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { SalonFinderRegisteredEntityService } from './salon-finder-registered-entity.service';
import { IOutletOpeningHours } from '../interfaces/IOutletOpeningHours';
import { OutletOpeningHoursDateOfWeek } from '../enums/OutletOpeningHoursDateOfWeek.enum';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { DateTime } from 'luxon';
import { IAdvertiser } from '../interfaces/IAdvertiser';
import { SalonFinderUploadImagesService } from './salon-finder-upload-images.service';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SalonFinderOutletService extends CRUDService<IOutlet> {
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }

  baseURL = environment.salonFinder.baseURL
  environmentCRUDPath = environment.salonFinder
  apiPath = '/outlets'

  constructor(
    public readonly httpClient: HttpClient, 
    private fb: FormBuilder,
    private salonFinderRegisteredEntityService: SalonFinderRegisteredEntityService,
    private salonFinderUploadImageService: SalonFinderUploadImagesService,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute) {
    super()
  }

  list(params?: HttpParams): Observable<ICommonListingResponse<IOutlet[]>> {
    return this.httpClient.get<ICommonListingResponse<IOutlet[]>>(this.listURL, {
      params: params
    })
  }
  delete(id: string | number): Observable<IOutlet> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: IOutlet): Observable<IOutlet> {
    return this.save(model, id)
  }
  create(model: IOutlet) {
    return this.save(model, model.id)
  }
  save(model: IOutlet, id?: string | number): Observable<IOutlet> {
    return this.httpClient.post<IOutlet>(this.saveURL(id), model);
  }

  resync(model:IOutlet) {
    return this.httpClient.post<IOutlet>(this.getAPIURL('/re-sync/'+model.id.toString()), {})
  }

  resyncByAdvertiser(advertiserID: number) {
    return this.httpClient.post<ICommonListingResponse<IOutlet[]>>(this.getAPIURL('/re-sync-all/'+advertiserID.toString()), {})
  }

  makeOutletCodePrefix(advertiserModel: IAdvertiser, outletModel: IOutlet) {
    return advertiserModel?.name?.substr(0,2).toLowerCase() + outletModel?.name?.substr(0, 2).toLowerCase();    
  }

  splitOutletCode(outletCode) {
    return outletCode?.match(/[\d\.]+|\D+/g)
  }

  makeFormGroup(loadedModel?: IOutlet) {
    const outletCode = loadedModel.outletCode?.match(/[\d\.]+|\D+/g)
    return this.fb.group({
      id: this.fb.control(loadedModel?.id), 
      advertiser: this.fb.control(loadedModel?.advertiser, [

      ]),
      outletCodePrefix: this.fb.control(outletCode?outletCode[0]:"", [
        Validators.maxLength(5),
        Validators.required
      ]),
      outletCodeNumber: this.fb.control(outletCode?outletCode[1]:"", [
        Validators.maxLength(10),
        Validators.required,
        RxwebValidators.numeric()
      ]),
      name: this.fb.control(loadedModel?.name, [
        Validators.required,
        Validators.maxLength(50),
        Validators.nullValidator
      ]),
      email: this.fb.control(loadedModel?.email, [
        Validators.required,
        Validators.email,
        Validators.maxLength(1024),
        Validators.nullValidator
      ]),
      notificationEmail: this.fb.control(loadedModel?.notificationEmail, [
        Validators.required,
        Validators.maxLength(1024),
        Validators.nullValidator
      ]),
      outletCode: this.fb.control(loadedModel?.outletCode, [

      ]),
      address: this.fb.control(loadedModel?.address, [
        Validators.required,
        Validators.maxLength(50),
        Validators.nullValidator
      ]),
      postalCode: this.fb.control(loadedModel?.postalCode, [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6),
        Validators.pattern('[0-9]*'),
        Validators.nullValidator
      ]),
      phone: this.fb.control(loadedModel?.phone, [
        Validators.required,
        Validators.min(0),
        Validators.nullValidator
      ]),
      description: this.fb.control(loadedModel?.description, [
        Validators.required,
        Validators.maxLength(50),
        Validators.nullValidator
      ]),
      __sImagesPath: this.fb.control(loadedModel?.__sImagesPath),
      creditCard: this.fb.control(false),
      active: this.fb.control(loadedModel?.active),
      advertiserID: [loadedModel.advertiserID, [
        Validators.required
      ]],
      opHours: this.fb.array(
        loadedModel.opHours?.length<=0 || !loadedModel.opHours ?Object.keys(OutletOpeningHoursDateOfWeek).map(k=>this.makeOpHourFormGroup({
          key: OutletOpeningHoursDateOfWeek[k],
          starthr: DateTime.utc(DateTime.utc().year, 1, 1, 3, 15, 0, 0),
          endhr: DateTime.utc(DateTime.utc().year, 1, 1, 12, 0, 0, 0),
          closed: false
        })):loadedModel.opHours?.map(o=>this.makeOpHourFormGroup(o))),
      uploadImages: this.fb.array(this.salonFinderUploadImageService.makeFormArray(loadedModel.uploadImages)),
      bankingEntity: this.salonFinderRegisteredEntityService.makeFormGroup(loadedModel.bankingEntity)
    });
  }

  public validateOperationHours(control: AbstractControl) {    
    const startHr = control.get('starthr').value as DateTime
    const endHr = control.get('endhr').value as DateTime
    if(startHr > endHr) {
      return { errorHours: true }
    }
    return null
  }

  public makeOpHourFormGroup(d?: IOutletOpeningHours) {
    return this.fb.group({
      key: [d.key?d.key:OutletOpeningHoursDateOfWeek.MONDAY, [
        RxwebValidators.oneOf({matchValues: Object.keys(OutletOpeningHoursDateOfWeek).map<string>(k=>OutletOpeningHoursDateOfWeek[k])})
      ]],
      starthr: [DateTime.isDateTime(d.starthr)?d.starthr:DateTime.fromISO(d.starthr), [
        //RxwebValidators.lessThan({fieldName: 'endhr'})
      ]],
      endhr: [DateTime.isDateTime(d.endhr)?d.endhr:DateTime.fromISO(d.endhr), [
        //RxwebValidators.greaterThan({fieldName: 'starthr'})
      ]],
      closed: [d.closed, [
      ]]
    }, {validators: [this.validateOperationHours]})
  }
  
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  // create data
  createData(data:any):Observable<any>
  {
    // console.log(data);
    return this.httpClient.post(environment.salonFinder.baseURL + environment.salonFinder.outlets + '/', data);
  }

  // create data entity
  saveEntity(entity:any):Observable<any>
  {
    console.log(entity);
    return this.httpClient.post(environment.salonFinder.baseURL + environment.salonFinder.entities + '/', entity);
  }

  // update data
  updateData(id:any, data:any):Observable<any>
  {
    console.log(data);
    return this.httpClient.post(environment.salonFinder.baseURL + environment.salonFinder.outlets + '/' + id, data);
  }

  // get outlets by advertiser
  getOutlets(aid: string): Observable<IOutlet> {
    const url = environment.salonFinder.baseURL + environment.salonFinder.outlets + environment.salonFinder.getOutletsByAdvertiser + '/' + aid;
    return this.httpClient.get<IOutlet>(url)
      .pipe(
        tap(_ => console.log(`fetched advertiser id=${aid}`)),
        catchError(this.handleError<IOutlet>(`get id=${aid}`))
      );aid
  }

  // get single data
  get(id: string): Observable<IOutlet> {    
    return this.httpClient.get<IOutlet>(this.getURL+'/'+id)
      .pipe(
        tap(_ => console.log(`fetched advertiser id=${id}`)),
        catchError(this.handleError<IOutlet>(`get id=${id}`))
      );
  }

  getAllDataByAdvertiser(aid) {
    const url = environment.salonFinder.baseURL + environment.salonFinder.outlets + '/search?page=&limit=&search=&type=&aid=' + aid;
    return this.httpClient.get<IOutlet>(url)
      .pipe(
        tap(_ => console.log(`fetched advertiser id=${aid}`)),
        catchError(this.handleError<IOutlet>(`get id=${aid}`))
      );
  }

  activateOutlet(data:any):Observable<any> {
    // console.log(data);
    return this.httpClient.post(environment.salonFinder.baseURL + environment.salonFinder.outlets + environment.salonFinder.deactivateOutlets, data);
  }

  sendOnBoardEmail(aid:number, oid:number) {
    return this.httpClient.get(environment.salonFinder.baseURL + environment.salonFinder.sendOnBoardEmail + '/' + aid + '/' + oid);
  }
}
import { HttpParams, HttpClient } from '@angular/common/http';
import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {ServiceService} from '../../services/service.service';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { IService } from '../../interfaces/iservice';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from 'src/app/shared/components/delete-dialog/delete-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'security-guards-service-index',
  templateUrl: './service-index.component.html',
  styleUrls: ['./service-index.component.scss']
})
export class ServiceIndexComponent extends ListingComponent<IService> implements OnInit, OnChanges {

  populateList<U extends HttpParams | Params>(params?: U, urlHistory?: boolean): void {
    const searchParams = this.makeSearchParams({
      ...params,
      withStats: true,
    }, true)
    this.commonPopulateList(searchParams, urlHistory);
  }
  getExtraListingParams(): void {
    throw new Error('Method not implemented.');
  }
  
  deleteItem(element){
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '350px',
      data: element.id
    });
    dialogRef.afterClosed().subscribe(v => {
      if (v) {
        this.baseService.delete(element.id)
        .subscribe(result=>{
          this.snackBar.open("Successfully deleted service.", "Close", {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          })
        },error => {
          this.snackBar.open("Failed to deleted service.", "Close", {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          })
        })
      }
    });
  }
  
  constructor(
    public readonly baseService: ServiceService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    public activatedRoute: ActivatedRoute,
  
  ) {
    super();
   
  }

  setUpCol() {
    this.listingCols = [
      'name',
      'fqdn',
      'lastActivityDate',
      'action'
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes && changes['userID'].previousValue != changes['userID'].currentValue) {
			this.populateList(null, false);
      this.setUpCol()
		}		
	}

  ngOnInit(): void {
    const queryParams = this.activatedRoute.snapshot.queryParams;

    this.searchFormGroupValueChange$.subscribe((valueChanges) => {
      if(valueChanges) {
        const searchParams = Object.assign({}, valueChanges);        
        this.populateList(searchParams, true);
      }
    })
    this.initSearchForm();
    this.populateList(queryParams, true);
    this.setUpCol();
  }

}

import { Injectable } from '@angular/core';
import { IStaticReportConfigGroup } from '../interfaces/iStaticReportConfigGroups';
import { ListingService } from '../abstracts/listing-service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ICommonResponse } from '../interfaces/icommonResponse.interface';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class ConsumerReportSurveyConfigGroupService  extends ListingService<IStaticReportConfigGroup> {


  baseURL = environment.surveyConfig.baseURL

  constructor(
    private readonly httpClient: HttpClient,
    private readonly fb: FormBuilder,
    ) {
    super();
  }
  
  list(params?: HttpParams) {
      return this.httpClient.get<ICommonResponse<IStaticReportConfigGroup>>(this.baseURL+environment.surveyConfig.surveyReportConfigGroupBase+environment.surveyConfig.commonListPath,
      {params});
  }
  get(id: string | number) {
    return this.httpClient.get<any>(this.baseURL+environment.surveyConfig.surveyReportConfigGroupBase+environment.surveyConfig.commonGetPath + '/' + id )
  }
  delete(id: string | number) {
    return this.httpClient.delete<any>(this.baseURL+environment.surveyConfig.surveyReportConfigGroupBase+environment.surveyConfig.commonDeletePath, {params:{id : id}})
  }
  update(id: string | number, model: IStaticReportConfigGroup) {
    return this.httpClient.post<any>(this.baseURL + environment.surveyConfig.surveyReportConfigGroupBase+environment.surveyConfig.commonUpdatePath+'/'+id, {...model});
 
  }
  create(model: IStaticReportConfigGroup) {
    return this.httpClient.post<any>(this.baseURL + environment.surveyConfig.surveyReportConfigGroupBase+environment.surveyConfig.commonCreatePath, {...model});

  }
  save(model: IStaticReportConfigGroup, id?: string | number) {
    throw new Error('Method not implemented.');
  }

  makeReportConfigGroup(loadedModel?: IStaticReportConfigGroup) {
    return this.fb.group({
      name: [loadedModel?.name || '',Validators.required],
      about: [loadedModel?.about || ''],
      reportConfigs: [loadedModel?.reportConfig || [], Validators.required],
      uuid:[loadedModel?.uuid || uuidv4()],
      countryOfOrigin: [loadedModel?.countryOfOrigin || '',Validators.required],
    });
  }

}

<form [formGroup]="searchForm">
  <div class="row">
    <div class="col-3 mt-3">
      <mat-form-field class="fullWidth" appearance="fill">
        <mat-label>Select Date Range</mat-label>
        <mat-date-range-input [rangePicker]="picker">                 
          <input matStartDate placeholder="Start Date" formControlName="startDate" #dateRangeStart>                 
          <input matEndDate placeholder="End Date" formControlName="endDate" #dateRangeEnd (dateChange)="fetchFinancesOnDate()">                
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div class="col-3 mt-4">
      <p>Total GMV : <span>{{totalGMV|currency}}</span></p>
      <p>Total SF Rev :  <span>{{totalSfRev|currency}}</span></p>
    </div>
    <mat-form-field class="fullWidth col-12" appearance="fill">
      <input type="text"
      placeholder="Type anything to search"
      aria-label="Type anything to search"
      matInput
      appearance="fill"
      formControlName="searchText"
      autocomplete="off">
    </mat-form-field>
  </div>
</form>
<div class="row">
  <div class="col-12">
    <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
      <ng-container matColumnDef="Date Purchased">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Date Purchased</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}">           
          {{ element.order.paymentDate | dateTimeFromIso  | dateTimeToLocal | dateTimeToFormat:'dd MMM yyyy h:mm a'}}
        </td>
      </ng-container>
      <ng-container matColumnDef="Merchant">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Merchant</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}"> 
          {{element.advertiser.bankingEntities[0].entityName}}
        </td>
      </ng-container>
      <ng-container matColumnDef="Service">
        <th mat-header-cell *matHeaderCellDef>Service</th>
        <td mat-cell *matCellDef="let element"> 
          <p>{{element.serviceName}}</p>
          <p>{{element.voucher}} #{{element.orderId}}</p>
          <p><i>{{element.customerFirstName}} {{ element.customerLastName}}: {{element.order.customerEmail}}</i></p>
        </td>
      </ng-container>
      <ng-container matColumnDef="GMV">
        <th mat-header-cell *matHeaderCellDef>GMV</th>
        <td mat-cell *matCellDef="let element"> 
          {{element.order.pricePerUnit | currency}}
        </td>
      </ng-container>
      <ng-container matColumnDef="DV COMM">
        <th mat-header-cell *matHeaderCellDef>DV COMM</th>
        <td mat-cell *matCellDef="let element" style="text-align: center;"> 
          {{getDVCommission(element) | currency}}
        </td>
      </ng-container>
      <ng-container matColumnDef="DV Pays Merchant">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">DV Pays Merchant</th>
        <td mat-cell *matCellDef="let element" class="ml-2" style="text-align: center;"> 
          {{getMerchantAmount(element) | currency}}
        </td>
      </ng-container>
      <ng-container matColumnDef="Discount">
        <th mat-header-cell *matHeaderCellDef>Discount</th>
        <td mat-cell *matCellDef="let element"> 
          <div *ngIf="element.discount && element.couponID;else none">
              <p>{{element.orderCoupon.couponCode}}:</p>
              <p>{{element.discount | currency}}</p>
          </div>
          <ng-template #none>
            <p class="ml-2">$0.00</p>
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="SF Rev">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">SF Rev</th>
        <td mat-cell *matCellDef="let element" style="text-align: center;"> 
          {{getDVCommission(element) - element.discount | currency}}
        </td>
      </ng-container>
      <ng-container matColumnDef="Expiry Date">
        <th mat-header-cell *matHeaderCellDef>Expiry Date</th>
        <td mat-cell *matCellDef="let element"> 
        </td>
      </ng-container>
      <ng-container matColumnDef="Redemption Status">
        <th mat-header-cell *matHeaderCellDef>Redemption Status</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.redeemed == 1">
            Redeemed on {{ element.redeemedDate | dateTimeFromIso  | dateTimeToLocal | dateTimeToFormat:'dd MMM yyyy h:mm a' }} at 
            <br /> {{ element.outlet.name }}
          </span>
          <span *ngIf="element.redeemed == 0">
            Not redeemed, expiring on {{ element.expiryDate | dateTimeFromIso  | dateTimeToLocal | dateTimeToFormat:'dd MMM yyyy'}}
          </span>
          <span *ngIf="element.redeemed == 2">
            Expired on {{ element.expiryDate | dateTimeFromIso  | dateTimeToLocal | dateTimeToFormat:'dd MMM yyyy'}}
          </span>
          <span *ngIf="element.redeemed == 3">
            Canceled on {{ element.cancelDate | dateTimeFromIso  | dateTimeToLocal | dateTimeToFormat:'dd MMM yyyy'}}
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element;">
          <div *ngIf="element.redeemed === 0">
            <button (click)="extendVoucher(element)"><strong>Extend</strong></button>
            <button (click)="cancelVoucher(element)"><strong>Cancel/Refund</strong></button><br>
            <button (click)="emailVoucher(element)"><strong>Email Voucher</strong></button>
            <button (click)="redeemVoucher(element)"><strong>Help Redeem</strong></button>
          </div>
          <div *ngIf="element.redeemed === 2">
            <button (click)="extendVoucher(element)"><strong>Extend</strong></button>
            <button (click)="redeemVoucher(element)"><strong>Help Redeem</strong></button>
          </div>
          <div *ngIf="element.redeemed === 3 || element.redeemed === 1">
            <button (click)="unCancelVoucher(element)"><strong>Un-Cancel/Un-redeemed</strong></button>
          </div>
        </td>
      </ng-container> 
      <tr mat-header-row *matHeaderRowDef="listingCols" class="p-2"></tr>
      <tr mat-row *matRowDef="let row; columns: listingCols;" class="p-2"></tr>
    </table>
  </div>
</div>
<div class="row mb-4">
  <div class="col-12">
    <mat-paginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" [pageIndex]="page"
      (page)="changePage($event)">
    </mat-paginator>
  </div>
</div>

<ng-container *ngIf="loading$ | async">
  <div class="overlay"></div>
  <mat-progress-spinner [mode]="'indeterminate'" [diameter]="50"></mat-progress-spinner>
</ng-container>
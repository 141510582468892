import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import moment, { Moment } from 'moment';
import { Observable } from 'rxjs';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { environment } from 'src/environments/environment';
import { CampaignItemTypeEnum } from '../enums/campaign-item-type';
import { CampaignStatusEnum } from '../enums/campaign-status';
import { ICampaign } from '../interfaces/campaign';
import { NativeAdsCampaignExcludedSiteService } from './native-ads-campaign-excluded-site.service';
import { NativeAdsCampaignItemService } from './native-ads-campaign-item.service';
import { NativeAdsMasterDataService } from './native-ads-master-data.service';
import { NativeAdsPublisherService } from './native-ads-publisher.service';

@Injectable({
  providedIn: 'root'
})
export class NativeAdsCampaignService extends CRUDService<ICampaign> {
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }
  apiPath = environment.nativeAds.campaignBase
  environmentCRUDPath = environment.nativeAds;

  constructor(
    public readonly httpClient: HttpClient,
    private readonly fb: FormBuilder,
    private readonly campaignItemService: NativeAdsCampaignItemService,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute
  ) {
    super();
  }

  list(params?: HttpParams): Observable<ICommonListingResponse<ICampaign[]>> {
    return this.httpList(params)
  }
  get(id: string | number): Observable<ICampaign> {
    return this.httpGet(id.toString())
  }
  delete(id: string | number): Observable<ICampaign> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: ICampaign): Observable<ICampaign> {
    return this.save(model, id)
  }
  create(model: ICampaign): Observable<ICampaign> {
    return this.save(model)
  }

  updateStatus(model: ICampaign, statusID: CampaignStatusEnum) {
    return this.httpClient.post(this.getAPIURL(environment.nativeAds.campaignUpdateStatus)+'/'+model.id+'/'+statusID, model)
  }

  listCampaignName(params?: HttpParams) {
    return this.httpClient.get<ICommonListingResponse<ICampaign[]>>(this.getAPIURL(environment.nativeAds.listCampaignName), {
      params: params
    })
  }

  setMomentTime(date: Moment, time: string) {
    const [unit, period] = time.split(' ');
    let [hour, min] = unit.split(':')
    if(period.toLowerCase() == 'pm') {
      hour += 12;
    }
    return date.add(hour, 'h').add(min, 'minute');
  }

  compareDateTime(control: AbstractControl) {
    const setMomentTime = (date: Moment, time: string) => {
      const [unit, period] = time.split(' ');
      let [hour, min] = unit.split(':')
      if(period.toLowerCase() == 'pm') {
        hour += 12;
      }
      return date.add(hour, 'h').add(min, 'minute');
    }
    const values = control.value
    const checkStartDate = setMomentTime(values.startDate.clone(), values.startTime);
    const checkEndDate = setMomentTime(values.endDate.clone(), values.endTime);
    
    if(checkStartDate.isSameOrAfter(checkEndDate)) {
      return { periodError: true }
    }
    return null
  }

  makeFormGroup(loadedModel?: ICampaign) {
    let startDateTime = moment().clone().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0)
    let endDateTime = moment().clone().add(1, 'M').set('hour', 23).set('minutes', 59).set('seconds', 59).set('millisecond', 0)
    
    if(loadedModel) {
      startDateTime = moment(loadedModel.startDate)
      endDateTime = moment(loadedModel.endDate)
    }
    return this.fb.group({
      id: [loadedModel?.id],
      name: [loadedModel?.name, [Validators.required]],
      client: [loadedModel?.client, [Validators.required]],
      article: [loadedModel?.article],
      clientID: [loadedModel?.clientID],
      clientName: [loadedModel?.clientName],
      status: [loadedModel?.status || {
        active: true,
        default: false,
        id: 24,
        identifier: "draft",
        order: 0,
        reference: "campaignStatus",
        value: "Draft"
      }],
      statusID: [loadedModel?.statusID || CampaignStatusEnum.DRAFT],
      doABTest: [loadedModel?.doABTest],
      startDate: [startDateTime, [Validators.required]],
      startTime: [startDateTime.format('h:mm a'), [Validators.required]],
      endDate: [endDateTime, [Validators.required]],
      endTime: [endDateTime.format('h:mm a'), [Validators.required]],
      publishers: [loadedModel?.publishers || [], [Validators.required]],
      priority: [loadedModel?.priority.toString(), [Validators.required]],
      campaignItems: this.fb.array(this.campaignItemService.makeFormArray(loadedModel?.campaignItems || [])),
    }, { validators: this.compareDateTime })
  }
}

import { FormControl, FormGroup } from "@angular/forms";
import { ICombineAnswerID, IContent, IGraphQuestionAnswer } from "../interfaces/iStaticReportConfig";
import { DisplayType } from "../enums/staticReportConfig.enum";

export const ReplaceMultipleWords = (inputString:string , replacements: {}) => {
	for (const oldWord in replacements) {
	  if (replacements.hasOwnProperty(oldWord)) {
		const newWord = replacements[oldWord];
		const regex = new RegExp(oldWord, 'g');
		inputString = inputString.replace(regex, newWord);
	  }
	}
	return inputString;
}


export const SurveyReportCombineAnswer = async (formQuestionAnswer: IGraphQuestionAnswer, displayType:string, question:string) =>  {
	
	switch (displayType) {
		case DisplayType.SAMPLE_SIZE:
		case DisplayType.STATISTICS:
	
			let statsSamepleSizeCombinedAns : ICombineAnswerID = {};
			let answerIDs:any = [];		
			if(formQuestionAnswer.selectedAnswers.length > 0){
				let selectedAnswers = formQuestionAnswer.selectedAnswers
				for(let selectedAns of selectedAnswers){
					if(selectedAns != undefined && selectedAns != 'Select All'){
						let indexSelectedAns = formQuestionAnswer.answers.findIndex(fa => fa.label == selectedAns);
						if(indexSelectedAns > -1){
			
							answerIDs.push({
								compare: "is",
								formQuestionID:  formQuestionAnswer.uuid,
								formQuestionAnswerID: formQuestionAnswer.answers[indexSelectedAns].uuid,
								formQuestionAnswerLabel: formQuestionAnswer.answers[indexSelectedAns].label
							})

							if(selectedAnswers.length == 1){
							
								statsSamepleSizeCombinedAns = {
									uuid :  formQuestionAnswer.answers[indexSelectedAns].uuid,
									label : formQuestionAnswer.answers[indexSelectedAns].label,
									answerIDs : answerIDs,
									combined: false,
								}
							}	
						}
					}
				}
				if(formQuestionAnswer.selectedAnswers.length > 1){
					statsSamepleSizeCombinedAns = {
						uuid :  formQuestionAnswer.uuid,
						label : formQuestionAnswer.customName == "" ? question : formQuestionAnswer.customName,
						answerIDs : answerIDs,
						combined: true
					}
				}
			}
			return statsSamepleSizeCombinedAns;
		break;
		case DisplayType.TABLE:
		case DisplayType.PIECHART:
			let index = formQuestionAnswer.answers.findIndex(fqa => fqa.label == formQuestionAnswer.selectedAnswers[0]);
			if(index > -1){
				return {answerID: formQuestionAnswer.answers[index]["questionAnswerID"]} as ICombineAnswerID;
			}

		break;
		case DisplayType.RATING_BAR:
		break;
		case DisplayType.HORIZONTAL_BAR:
		case DisplayType.VERTICAL_BAR:	
		break;
	}
}

export const SurveyReportDisplayTypeConfig = async (graphQuestion) => {
	switch (graphQuestion.displayType) {
		case DisplayType.SAMPLE_SIZE:

			return {

				"hint": graphQuestion.hint,
				"display": {
				"column": `${graphQuestion.displayWidth}`
				},
		
				"graph": {
					"textAlign": "center",
					"orderBy": "value",
					"order": "DESC"
				}
			};
			
			break;
		case DisplayType.PIECHART:
			return {
				"hint": graphQuestion.hint,
				"display": {
					"column": `${graphQuestion.displayWidth}`
					},
			}
			break;
		case DisplayType.STATISTICS:
		
				let columnConfig = {}
				for(let ans of graphQuestion.subAnswers){
						let uuid = '';
						//SELECT ALL ANSWER
						if(ans.answers.length === ans.selectedAnswers){
							uuid = ans.uuid;
						}

						//SELECT MULTIPLE  ANSWER but NOT ALL
						if(ans.selectedAnswers.length > 1){
							uuid = graphQuestion.subID;
						}
						
						//SELECT 1 ANSWER only.
						if(ans.selectedAnswers.length == 1){
							let index = ans.answers.findIndex(aa => aa.label == ans.selectedAnswers[0]);
							if(index > -1){
								uuid = ans.answers[index].uuid;
							}
						}

						
						columnConfig[uuid] = {
						"primary": false,
						"showPercent": ans.displayTypeConfig?.displayPercentage  || false,
						"iconClass": `${ans.displayTypeConfig?.icon?.trim() }` || "",
						"bgColor": `${ans.displayTypeConfig?.bgColor?.trim()}` || "",
						"gridConfig":{lg:`${graphQuestion.displayWidth}`,sm:12,xs:12},
					}


				}

				return {
					"hint": graphQuestion.hint,
					"display": {
					  "column": `${graphQuestion.displayWidth}`
					},
					"statsConfig":columnConfig 
				};


		break;
		case DisplayType.TABLE:
			return {
				"hint": graphQuestion.hint,
				"display": {
				  "column": `${graphQuestion.displayWidth}`
				},
			};
			break;
		case DisplayType.HORIZONTAL_BAR:
		case DisplayType.VERTICAL_BAR:	

		let orders = [];
		return {
			"hint": graphQuestion.hint,
			"display": {
			  "column": `${graphQuestion.displayWidth}`
			},
			"graph": {
				"orderBy": "user-defined",
				"order": [
					orders
				]
			}
		};
		
		break;
		case DisplayType.RATING_BAR:
			return {
				"hint": graphQuestion.hint,
			}
		break;
	}


	return {}
	
}

export const TransformConfigToFormGroup = (configs: IContent[], consumerReportFormQuestionService) => {	
	let configLayout = [];
	return new Promise(async (resolve, reject)=> {
		try {
			for(let config of configs){
				let item =  {
					"uuid": config.uuid,
					"id": config.id,
					"title": config.title,
					"content": config.content,
					"formPage": "",
					"order": config.order,
					"graphs": [],
					"surveyReportConfigID":config.surveyReportConfigID,
					"openedPanelSection": false
				}	
		
				// for(let configGraph of config.surveyReportSectionGraph){
				// 	let formQuestions = await consumerReportFormQuestionService.getFormQuestionByUUID(configGraph.formQuestionID).toPromise();
				
				// 		for(let formQuestion of formQuestions){
							
				// 			let itemGraph = {
								
				// 				"order": configGraph.order ,
				// 				"subID": configGraph.formQuestionID,
				// 				"subTitle": configGraph.customName,
				// 				"displayType": configGraph.displayType,
				// 				"displayWidth": parseInt(configGraph.displayTypeConfig?.column)  || 6,
				// 				"hint": configGraph.displayTypeConfig?.hint || "",
				// 				"question": formQuestion.label,
				// 				"showInPercentage": configGraph.showInPercentage,
				// 				"showCompareQuestion": configGraph.showCompareQuestion,
				// 				"nonFree": configGraph.nonFree,
				// 				"dependencies": formQuestion.dependencies,
				// 				"subAnswers": [],
				// 				"id": configGraph.id, 
				// 				"surveyReportConfigID":configGraph.surveyReportConfigID,
				// 				"surveyReportSectionID": configGraph.surveyReportSectionId,
				// 				"matrices": formQuestion.matrices,	
				// 				"formQuestionID": formQuestion.id,
				// 				"quesionID": formQuestion.quesionID
								
				// 			}

				// 			item.graphs.push(itemGraph);
				// 		}
		
				// }

				item.graphs = item.graphs.sort((a, b) => a.order - b.order); 

				configLayout.push(item);
			}
			configLayout = configLayout.sort((a, b) => a.order - b.order);
	
			resolve(configLayout);
		} catch (error) {
			reject("Error Tranform Config To FormGroup :" + error)
		}

	})
	
	
}
  

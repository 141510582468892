import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UrlShortenerCollectionService } from "../../services/url-shortener-collection.service";

export interface DialogData {
    id: number;
  }
  

@Component({
    selector: 'reset-confirmation',
    templateUrl: 'reset-confirmation.html',
    styleUrls: ['./reset-confirmation.scss']
  })
  export class ResetDialog {

    isDisabled = true;
    delete:any;
    constructor(
      public dialogRef: MatDialogRef<ResetDialog>,
      private readonly urlShortenerService: UrlShortenerCollectionService,
      private readonly snackBar: MatSnackBar,
      @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}
  
    onNoClick(): void {
      this.dialogRef.close();
    }

    changeUpperCase(textToUpper: string){
        var newWord = textToUpper.toUpperCase();

        if(newWord === 'RESET'){
            this.isDisabled = false;
        }else{
            this.isDisabled = true;
        }
    }

    resetClicks(){
        this.urlShortenerService.resetClicks(this.data.id).subscribe((result:any) =>{
            if(result.status === "Success"){
                this.dialogRef.close();
                this.snackBar.open('Successfully reset clicks.', 'Close', {
                    duration: 2000,
                    horizontalPosition: 'center',
                    verticalPosition: 'top'
                  });
                  this.dialogRef.close({ data: 'success' });
            }else{
                this.snackBar.open('Failed to reset clicks.', 'Close', {
                    duration: 2000,
                    horizontalPosition: 'center',
                    verticalPosition: 'top'
                  });
            }
        })
    }
  }
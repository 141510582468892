<div mat-dialog-content>
    <mat-form-field appearance="fill" class="fullWidth">
        <mat-label *ngIf="data?.status === 1">Please type <b>ARCHIVED</b> to confirm</mat-label>
        <mat-label *ngIf="data?.status === 0">Please type <b>UNARCHIVED</b> to confirm</mat-label>
        <input matInput [(ngModel)]="archived"  (ngModelChange)="changeUpperCase(archived)" >
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
    <button mat-button class="primary" (click)="archivedClicks()" cdkFocusInitial  [disabled]="isDisabled">Ok</button>
    <button mat-button (click)="onNoClick()">Cancel</button> 
</div>
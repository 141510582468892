import { ViewportScroller } from '@angular/common';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Observable } from 'rxjs';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { ICRUDPath } from 'src/app/shared/interfaces/iCRUDPath.interface';
import { environment } from 'src/environments/environment';
import { CampaignItemStatusEnum } from '../enums/campaign-item-status';
import { CampaignItemTypeEnum } from '../enums/campaign-item-type';
import { ICampaign } from '../interfaces/campaign';
import { ICampaignItem } from '../interfaces/campaign-item';
import { NativeAdsUploadFilesService } from './native-ads-upload-files.service';

@Injectable({
  providedIn: 'root'
})
export class NativeAdsCampaignItemService extends CRUDService<ICampaignItem> {
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }
  list(params?: HttpParams): Observable<ICommonListingResponse<ICampaignItem[]>> {
    return this.httpList(params)
  }
  get(id: string | number): Observable<ICampaignItem> {
    throw new Error('Method not implemented.');
  }
  delete(id: string | number): Observable<ICampaignItem> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: ICampaignItem): Observable<ICampaignItem> {
    throw new Error('Method not implemented.');
  }
  create(model: ICampaignItem): Observable<ICampaignItem> {
    throw new Error('Method not implemented.');
  }

  getAdsTypeShortCut(statusID: number) {
    return {
      [CampaignItemTypeEnum.CONTENT_RECOMMANDED]: "CR",
      [CampaignItemTypeEnum.ARTICLE_INSERTION]: "AI",
      [CampaignItemTypeEnum.SPLASH_POPUP]: "POPUP",
    }[statusID]
    
  }

  getCTR(model: ICampaignItem) {
    return ((model.achievedClick / model.achievedImpression) || 0) * 100
  }

  getViewableCTR(model: ICampaignItem) {
    return ((model.achievedClick / model.achievedViewableImpression) || 0) * 100
  }

  getImpressionUsed(model: ICampaignItem) {
    return (model.costPerCPM * (model.achievedImpression/1000)) || 0
  }

  getCPC(model: ICampaignItem) {
    const impressionCost = this.getImpressionUsed(model)
    return (impressionCost >0 && model.achievedClick >0)?impressionCost/model.achievedClick:0
  }

  makeFormGroup(loadedModel?: ICampaignItem) {
    return this.fb.group({
      id: [loadedModel?.id],
      campaign: [loadedModel?.campaign],
      type: [loadedModel?.type],
      typeID: [loadedModel?.typeID || CampaignItemTypeEnum.CONTENT_RECOMMANDED, [Validators.required]],
      title: [loadedModel?.title, [Validators.maxLength(512), RxwebValidators.required({conditionalExpression: (x)=>x?.type?.id === CampaignItemTypeEnum.CONTENT_RECOMMANDED})]],
      imageURL: [loadedModel?.imageURL, [Validators.maxLength(1024)]],
      desktopImageURL: [loadedModel?.desktopImageURL],
      linkURL: [loadedModel?.linkURL, [Validators.maxLength(1024)]],
      description: [loadedModel?.description],
      seoTitle: [loadedModel?.seoTitle, [Validators.maxLength(512)]],
      clickToActionText: [loadedModel?.clickToActionText],
      iframe: [loadedModel?.iframe],
      costPerCPM: [loadedModel?.costPerCPM],
      impressionPurchased: [loadedModel?.impressionPurchased, Validators.min(10000)],
      totalCost: [loadedModel?.totalCost],
      achievedImpression: [loadedModel?.achievedImpression],
      achievedClick: [loadedModel?.achievedClick],
      achievedViewableImpression: [loadedModel?.achievedViewableImpression],
      testImpression: [loadedModel?.testImpression],
      status: [loadedModel?.status],
      statusID: [loadedModel?.statusID],
      active: [loadedModel?.active],
      freeCampaign: [loadedModel?.freeCampaign],
      cpc: [loadedModel?.cpc],	
      totalMetricsCount: [loadedModel?.totalMetricsCount],
      images: [loadedModel?.images || []]
    })
  }
  apiPath = environment.nativeAds.campaignItemBase
  environmentCRUDPath = environment.nativeAds;

  constructor(
    public readonly httpClient: HttpClient,
    private readonly fb: FormBuilder,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute,
    public readonly viewportScroller: ViewportScroller,
    private readonly uploadFileService: NativeAdsUploadFilesService
  ) {
    super();
  }
}

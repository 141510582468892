<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <mat-form-field class="fullWidth" appearance="fill">
        <input type="text"
          placeholder="Search Questions"
          aria-label="Search Questions"
          matInput
          appearance="fill"
          #searchTextInput>
      </mat-form-field>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">

        <!-- Name Column -->
        <ng-container matColumnDef="label">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.label}} </td>
        </ng-container>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="addQuestion(element)">
              <mat-icon>add</mat-icon>
            </button>
            
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <div class="col-12">

    </div>
  </div>
</div>
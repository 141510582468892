import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators';
import { forkJoin, fromEvent, Observable, pipe, zip } from 'rxjs';
import { debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { IForm } from '../../interfaces/form';
import { IFormPage } from '../../interfaces/form-page';
import { IFormQuestion } from '../../interfaces/form-question';
import { IFormQuestionAnswer } from '../../interfaces/form-question-answer';
import { ILookup } from '../../interfaces/ilookup';
import { IQuestion } from '../../interfaces/question';
import { ConsumerReportFormPageService } from '../../services/consumer-report-form-page.service';
import { ConsumerReportFormQuestionService } from '../../services/consumer-report-form-question.service';
import { ConsumerReportFormService } from '../../services/consumer-report-form.service';
import { ConsumerReportLookupService } from '../../services/consumer-report-lookup.service';
import { ConsumerReportQuestionService } from '../../services/consumer-report-question.service';
import { FormAddQuestionsDialogComponent } from '../form-add-questions-dialog/form-add-questions-dialog.component';
import { FormsQuestionDependencyDialogComponent } from '../forms-question-dependency-dialog/forms-question-dependency-dialog.component';
interface UploadResult {
  isImg: boolean;
  name: string;
  url: string;
}

@Component({
  selector: 'forms-form',
  templateUrl: './forms-form.component.html',
  styleUrls: ['./forms-form.component.scss']
})
export class FormsFormComponent implements OnInit, OnChanges {

  formData: FormGroup = undefined;
  savingForm = false;

  lastPageNo: number;

  offSyncFormQuestionIDs: number[] = undefined;

  displayEditQnsForm: boolean;
  selectedQuestion: IFormQuestion;
  
  deletedPage: IFormPage[] = new Array<IFormPage>()

  @Input('formModel') loadedModel: IForm;

  @ViewChild('searchQuestionsInput') searchQuestionsInputEle: ElementRef;

  get formErrors(): { [key: string]: AbstractControl; } { return this.formData.controls; }
  get questions(): FormArray { return this.formData.get('questions') as FormArray; }
  get formPages(): FormArray { return this.formData.get('formPage') as FormArray; }
  get formPageValue(): IFormPage[] { return this.formPages.value as IFormPage[]; }

  get formPagesCount(): number { return this.formPages.length; }

  options = {
    showPreviewPanel: false,
    hideIcons: [
      'FullScreen',
      'TogglePreview',
      'Code'
    ],
    resizable: false,
    enablePreviewContentClick: false
  };

  isMaxQuestions(formPage: AbstractControl){
    let formQuestions =  formPage.get('formQuestion') as FormArray;
    return formQuestions.value.length >= 10;
  }

  formPageQuestion(formPage: AbstractControl) {
    return formPage.get('formQuestion') as FormArray;
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly consumerReportQuestionService: ConsumerReportQuestionService,
    private readonly consumerReportFormService: ConsumerReportFormService,
    private readonly consumerReportFormQuestionService: ConsumerReportFormQuestionService,
    private readonly consumerReportFormPageService: ConsumerReportFormPageService,
    private readonly consumerReportLookupService: ConsumerReportLookupService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar
  ) {
    this.doUpload = this.doUpload.bind(this);
  }

  doUpload(files: Array<File>): Promise<Array<UploadResult>> {
    // do upload file by yourself
    console.log(files);

    return Promise.resolve([{ name: 'xxx', url: 'xxx.png', isImg: true }]);
  }

  dragDropQuestionsInPage(event: CdkDragDrop<IFormQuestion[] | any>, formPage: AbstractControl): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
    formPage.get('formQuestion').updateValueAndValidity()
  }

  addQuestionsDialog(formPage: AbstractControl): void {
    const ignoreIDs: string[] = Array<string>();
    for (const page of this.formPageValue) {
      page.formQuestion.map(q => ignoreIDs.push(q.questionID.toString()));
    }
    const questionDialog = this.dialog.open(FormAddQuestionsDialogComponent, {
      data: ignoreIDs,
      width: '600px',
      minHeight: '400px',
      maxHeight: '600px'
    });
    questionDialog.componentInstance.addQnsEvent
      .subscribe(question => {
        console.log(question);
        this.addQns(question, formPage.value.page);
      });
  }

  addQns(question: IQuestion, page?: number): void {
    const addingPage = page ? page : this.lastPageNo;
    for (const p of this.formPages.controls) {
      if (p.value.page === addingPage) {
        const formQuestion = {
          ...question,
          questionID: question.id,
          questionDataHash: question.dataHash,
          id: undefined,
          display:true,
          type: question.type
        };

        (p.get('formQuestion') as FormArray).push(this.newQuestions(formQuestion));
      }
    }
  }

  editQns(question: IFormQuestion, formPage: number): void {
    let foundIndex = false;
    let pageQuestions: IFormQuestion[] = Array<IFormQuestion>();
    for (const p of this.formPageValue) {
      for (const q of p.formQuestion) {
        pageQuestions = pageQuestions.concat(q);
      }
    }
    const previousFormQuestions = pageQuestions.filter(a => {
      if (a.questionID === question.questionID) {
        foundIndex = true;
      }

      return !foundIndex;
    });
    const dependencyDialog = this.dialog.open(FormsQuestionDependencyDialogComponent, {
      data: {
        formQuestion: question,
        previousFormQuestions,
        formQuestionDependency: question.dependencies
      }
    });
    dependencyDialog
      .afterClosed()
      .subscribe(d => {
      this.replaceFormQns(formPage, d);
    });
  }

  ngOnInit(): void {
    this.checkOffSync();
    this.initForm();
    this.displayEditQnsForm = true;
  }

  needSync(id: number) {
    if (this.offSyncFormQuestionIDs) {
      return (this.offSyncFormQuestionIDs.findIndex(d => d === id) >= 0) ? true : false;
    }

    return false;
  }

  checkOffSync() {
    if (this.loadedModel) {
      let questions = Array<string>();
      for (const page of this.loadedModel.formPage) {
        questions = questions.concat(page.formQuestion?.map(q => q.id.toString()));
      }
      this.consumerReportFormQuestionService.checkOffSync(questions)
        .subscribe(d => {
          this.offSyncFormQuestionIDs = d.map(dd => dd.id);
        });
    }
  }

  syncFormQuestion(id: number, page: number, questionIndex: number) {
    this.savingForm = true;
    this.consumerReportFormQuestionService.resync(id.toString())
      .subscribe(d => {
        this.checkOffSync();
        const currentQuestions = (this.formData.get('formPage') as FormArray).at(page).get('formQuestion') as FormArray
        currentQuestions.setControl(questionIndex, this.newQuestions(d))
        currentQuestions.updateValueAndValidity()
        this.consumerReportFormService.refreshCache(this.loadedModel.id.toString())
            .subscribe(m => {
              this.savingForm = false;
            });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loadedModel) {
      this.checkOffSync();
      this.initForm();
    }
  }

  getAnswer(i: number): FormArray {
    return this.questions
      .at(i)
      .get('answers') as FormArray;
  }

  newAnswer(formQuestionAnswer?: IFormQuestionAnswer): FormGroup {
    return this.fb.group({
      label: [formQuestionAnswer?.label ? formQuestionAnswer.label : undefined, [
        Validators.maxLength(256)
      ]],
      order: [formQuestionAnswer?.order ? formQuestionAnswer.order : undefined, [
      ]],
      point: [formQuestionAnswer?.point ? formQuestionAnswer.point : undefined, [
        RxwebValidators.numeric({acceptValue: NumericValueType.PositiveNumber, allowDecimal: false})
      ]],
      uuid: [formQuestionAnswer?.uuid ? formQuestionAnswer.uuid : undefined, [
      ]],
      tooltip: [formQuestionAnswer?.tooltip ? formQuestionAnswer.tooltip : undefined, [
      ]],
      group: [formQuestionAnswer?.group ? formQuestionAnswer.group : undefined, [
      ]],
      formQuestionID: [formQuestionAnswer?.formQuestionID ? formQuestionAnswer.formQuestionID : undefined, [
      ]],
      questionAnswerID: [formQuestionAnswer?.questionAnswerID ? formQuestionAnswer.questionAnswerID : undefined, [
      ]],
      id: [formQuestionAnswer?.id ? formQuestionAnswer.id : undefined, [
        RxwebValidators.numeric({acceptValue: NumericValueType.PositiveNumber, allowDecimal: false})
      ]]
    });
  }
  newQuestions(formQuestion?: IFormQuestion): FormGroup {
    return this.fb.group({
      label: [formQuestion ? formQuestion.label : undefined, [
        RxwebValidators.required(),
        Validators.maxLength(256),
        Validators.nullValidator
      ]],
      instruction: [formQuestion ? formQuestion.instruction : undefined],
      uuid: [formQuestion ? formQuestion.uuid : undefined],
      display: [formQuestion ? formQuestion.display : false],
      order: [formQuestion ? formQuestion.order : 1, [
        RxwebValidators.numeric({acceptValue: NumericValueType.PositiveNumber, allowDecimal: false})
      ]],
      maxPoint: [formQuestion ? formQuestion.maxPoint : 0, [
        RxwebValidators.numeric({acceptValue: NumericValueType.PositiveNumber, allowDecimal: false}),
        RxwebValidators.range({maximumNumber: 10000, minimumNumber: 0})
      ]],      
      allowDeselectLabel: [formQuestion ? formQuestion.allowDeselectLabel : undefined ],
      answers: this.fb.array([]),
      // this.fb.array(formQuestion.answers ? formQuestion.answers.map(a => this.newAnswer(a)) : [this.newAnswer()]),
      id: [formQuestion ? formQuestion.id : undefined, [
        RxwebValidators.numeric({acceptValue: NumericValueType.PositiveNumber, allowDecimal: false})
      ]],
      formPageID: [formQuestion ? formQuestion.formPageID : undefined, [
        RxwebValidators.numeric({acceptValue: NumericValueType.PositiveNumber, allowDecimal: false})
      ]],
      questionID: [formQuestion ? formQuestion.questionID : undefined, [
        RxwebValidators.numeric({acceptValue: NumericValueType.PositiveNumber, allowDecimal: false})
      ]],
      questionDataHash: [formQuestion ? formQuestion.questionDataHash : undefined],
      controlType: [formQuestion ? formQuestion.controlType : 'text'],
      dependencies: [formQuestion ? formQuestion.dependencies : undefined],
      type:[formQuestion? formQuestion.type: 'text']
    });
  }

  newPage(formPage?: IFormPage): FormGroup {
    return this.fb.group({
      id: [formPage ? formPage.id : undefined, [
        RxwebValidators.numeric({acceptValue: NumericValueType.PositiveNumber, allowDecimal: false})
      ]],
      formID: [formPage ? formPage.formID : undefined, [
        RxwebValidators.numeric({acceptValue: NumericValueType.PositiveNumber, allowDecimal: false})
      ]],
      title: [formPage ? formPage.title : undefined, [
        Validators.maxLength(256)
      ]],
      content: [formPage ? formPage.content : undefined, [
      ]],
      formQuestion: this.fb.array(formPage.formQuestion ? formPage.formQuestion.map(q => this.newQuestions(q)).sort((a, b) => a.value.order - b.value.order) : [], [

      ]),
      page: [formPage ? formPage.page : undefined],
      slug: [formPage ? formPage.slug : undefined ],
      uuid: [formPage ? formPage.uuid : undefined]
    });
  }

  initForm(): void {
    this.formData = this.fb.group({
      name: this.fb.control(this.loadedModel.name, [
        Validators.required,
        Validators.maxLength(125),
        Validators.nullValidator
      ]),
      startDate: this.fb.control(this.loadedModel.startDate, [
        Validators.maxLength(125),
        Validators.nullValidator
      ]),
      endDate: this.fb.control(this.loadedModel.endDate, [
        Validators.maxLength(125),
        Validators.nullValidator
      ]),
      formPage: this.fb.array(this.loadedModel.formPage ? this.loadedModel.formPage.map(p => this.newPage(p)).sort((a, b) => a.value.page - b.value.page) : [], [

      ]),
      id: this.fb.control(this.loadedModel.id),
      slug: [this.loadedModel ? this.loadedModel.slug : undefined ],
      seoTitle: [this.loadedModel ? this.loadedModel.seoTitle : undefined ],
      seoDescription: [this.loadedModel ? this.loadedModel.seoDescription : undefined ]
      
    });
    this.lastPageNo = this.formPages.length
  }

  addPage(): void {
    this.lastPageNo += 1;
    this.formPages.push(this.newPage({page: this.lastPageNo, formQuestion: []}));
  }

  upPage(currentIndex: number): void {
    if (currentIndex < this.formPagesCount - 1) {
      const nextIndex = currentIndex + 1;
      const currentFormPage = this.formPages.at(currentIndex);
      currentFormPage.value.page += 1;
      const nextFormPage = this.formPages.at(nextIndex);
      nextFormPage.value.page -= 1;
      this.formPages.setControl(nextIndex, currentFormPage);
      this.formPages.setControl(currentIndex, nextFormPage);
    }
  }

  downPage(currentIndex: number): void {
    if (currentIndex > 0) {
      const prevIndex = currentIndex - 1;
      const currentFormPage = this.formPages.at(currentIndex);
      const prevFormPage = this.formPages.at(prevIndex);
      currentFormPage.value.page -= 1;
      prevFormPage.value.page += 1;
      this.formPages.setControl(prevIndex, currentFormPage);
      this.formPages.setControl(currentIndex, prevFormPage);
    }
  }

  delPage(removePage: number): void {
    const confirmedDel = confirm('Are you sure to delete?');
    if (confirmedDel) {
      let page = 0;
      const index = removePage - 1;
      const formPage = this.formPages.at(index).value
      this.formPages.removeAt(index);
      this.formPages.controls.map(f => {
        page += 1;
        f.patchValue({
          page: page
        })        
      })
      this.formPages.updateValueAndValidity();
      this.lastPageNo = page;
      this.deletedPage.push(formPage)
    }
  }

  removeFormPageQuestion(formPageIndex: number, formPageQuestionIndex: number): void {
    (this.formPages.at(formPageIndex).get('formQuestion') as FormArray).removeAt(formPageQuestionIndex);
  }

  replaceFormQns(formPage: number, question: IFormQuestion): void {
    if (this.formPages[formPage]) {
      this.formPages[formPage].questions = this.formPages[formPage].questions.map(q => {
        if (q.questionID === question.questionID) {
          return question;
        }

        return q;
      });
    }
  }

  saveForm(): void {
    if (this.formData.status === 'VALID') {
      const formModel = this.formData.value as IForm;
      const formPages = formModel.formPage;
      delete formModel.formPage
      this.consumerReportFormService
        .save(formModel)
        .pipe(
          tap(m => {
            this.savingForm = true;
          }),
          switchMap(m => {
            let pageNo = 0;
            const formPagesOb = formPages.map(f => {
              pageNo += 1;
              let questionOrder = 0;

              return this.consumerReportFormPageService.save({
                ...f,
                formID: m.id,
                page: pageNo,
                formQuestion: f.formQuestion.map(fq => {
                  questionOrder += 1;

                  return {
                    ...fq,
                    formID: m.id,
                    order: questionOrder
                  };
                })
              });
            });

            const formPagesDelOb = this.deletedPage.filter(f=>f.uuid).map(f => {
              return this.consumerReportFormPageService.delete(f.uuid);
            });

            return forkJoin(formPagesOb.concat(formPagesDelOb))
              .pipe(map(fp => {
                return {
                  ...m,
                  formPages: fp
                };
              }));
          })
        )
        .subscribe(m => {
          this.consumerReportFormService.refreshCache(this.loadedModel.id.toString())
            .subscribe(
              m => {
                this.deletedPage = new Array<IFormPage>()
                this.loadedModel = m;
                this.savingForm = false;
                this.snackBar.open('Form has been saved SUCCESSFULLY!"', 'Close', {
                  duration: 2000,
                  horizontalPosition: 'center',
                  verticalPosition: 'top'
                });
                this.initForm();
              },
              err => {
                this.snackBar.open('Form has been saved FAILED!', 'Close', {
                  duration: 2000,
                  horizontalPosition: 'center',
                  verticalPosition: 'top'
                });
                this.savingForm = false;
              }
            );
        },
        err => {
          this.snackBar.open('Form has been saved FAILED!', 'Close', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
          this.savingForm = false;
        })
    }
  }
}

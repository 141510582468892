import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IBrand } from '../../interfaces/ibrand';
import { ConsumerReportBrandsService } from '../../services/consumer-report-brands.service';

@Component({
  selector: 'app-brands-update',
  templateUrl: './brands-update.component.html',
  styleUrls: ['./brands-update.component.scss']
})
export class BrandsUpdateComponent implements OnInit {

  brandsForm:IBrand = {
    id: undefined,
    uuid: undefined,
    name: undefined,
    country: 'Singapore',
    gender: '0',
    originatedISOCountryCode: 'SG',
    countryZone: 'Asia', 
    description: undefined,
    skincare: true,
    makeup: true,
    haircare: true,
    brandCategory: [],
    celebrity:true
  }

  constructor(private readonly route: ActivatedRoute, private readonly consumerReportBrandService: ConsumerReportBrandsService) { }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.consumerReportBrandService.get(id)
      .subscribe(res => {
        this.brandsForm = res;
      });
  }

}

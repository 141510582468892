import { HttpParams } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { IRole } from '../../interfaces/iroles';
import { RoleService } from '../../services/role.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'security-guards-role-index',
  templateUrl: './role-index.component.html',
  styleUrls: ['./role-index.component.scss']
})
export class RoleIndexComponent extends ListingComponent<IRole> implements OnInit, OnChanges {

  public groupType = [
    'Google',
    'Cognito'
  ];

  populateList<U extends HttpParams | Params>(params?: U, urlHistory?: boolean): void {    
    const searchParams = this.makeSearchParams({
      ...params,
      withStats: true,
    }, true)
    this.commonPopulateList(searchParams, urlHistory);
  }
  getExtraListingParams(): void {
    throw new Error('Method not implemented.');
  }

  @Input('userID')
  userID: string;

  constructor(
    public readonly baseService: RoleService,
    public activatedRoute: ActivatedRoute,
    public mainRouter: Router
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
		if(changes && changes['userID'].previousValue != changes['userID'].currentValue) {
			this.populateList(null, false);
			this.setUpCol()
		}		
	}

  setUpCol() {
		this.listingCols = [
			'createdDate',
			'name',
      'userCount',
      'label',
      'accessCount',
      'groupType',
			'action'
		];
	}

  syncAllRole() {
    this.isLoading = true;
    this.baseService.syncGoogleRole()
      .subscribe(d=> {
        this.isLoading = false
      });
  }

  search() {
    this.populateList(null, true);
  }

  ngOnInit(): void {
    const queryParams = this.activatedRoute.snapshot.queryParams;

    this.searchFormGroupValueChange$.subscribe((valueChanges) => {
      if(valueChanges) {
        const searchParams = Object.assign({}, valueChanges);        
        this.populateList(searchParams, true);
      }
    })

    this.initSearchForm(queryParams);
    this.populateList(queryParams, false);
    this.setUpCol()
    
  }

}

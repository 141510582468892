import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ListingService } from '../abstracts/listing-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { IInfluencer } from '../interfaces/iinfluencer';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { Router, ActivatedRoute } from '@angular/router';
import { ICRUDPath } from 'src/app/shared/interfaces/iCRUDPath.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConsumerReportInfluencerService extends CRUDService<IInfluencer> {
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }
  list(params?: HttpParams): Observable<ICommonListingResponse<IInfluencer[]>> {
    return this.httpList(params)
  }
  get(id: string | number): Observable<IInfluencer> {
    return this.httpGet(id.toString())
  }
  delete(id: string | number): Observable<IInfluencer> {
    console.log("delete",id)
    console.log("this.deleteURL", this.deleteURL);
    return this.httpClient.delete<IInfluencer>(this.deleteURL + "/" + id)
  }
  update(id: string | number, model: IInfluencer): Observable<IInfluencer> {
    return this.save(model, id)
  }
  create(model: IInfluencer): Observable<IInfluencer> {
    return this.save(model)
  }
  makeFormGroup(loadedModel?: IInfluencer) {
    throw new Error('Method not implemented.');
  }
  apiPath= environment.consumerReport.influencerBase
  environmentCRUDPath = environment.consumerReport

  constructor(
    public override readonly httpClient: HttpClient,
    public override readonly router: Router,
    public override readonly activatedRoute: ActivatedRoute
  ) {
    super();
  }

  save(model: IInfluencer, id?: string | number) {
    const url = this.saveURL(id)
    return this.httpClient.post<IInfluencer>(url, model)
  }

  getAll() {
    return this.httpClient.get<any>(environment.consumerReport.baseURL + this.apiPath + '/get-all');
  }


}

import { Component, OnChanges, OnInit, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { sortList } from "../../helpers/sortByKey";
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { IOutlet } from '../../interfaces/IOutlet';
import { SalonFinderAdvertiserService } from '../../services/salon-finder-advertiser.service';
import { SalonFinderOutletService } from '../../services/salon-finder-outlet.service';
import { FormComponent } from 'src/app/shared/abstracts/form-component';
import { SalonFinderRegisteredEntityService } from '../../services/salon-finder-registered-entity.service';
import { IUploadImages } from '../../interfaces/iupload-images';
import { SalonFinderUploadImagesService } from '../../services/salon-finder-upload-images.service';
import { environment } from 'src/environments/environment';
import { UploadImageLinkTypeEnum } from '../../enums/UploadImageLinkType.enum';
import { ObjectCannedACL, PutObjectCommand } from '@aws-sdk/client-s3';
import { DateTime } from 'luxon';
import { AWSServiceService } from 'src/app/shared/services/awsservice.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'salon-finder-outlet-form',
  templateUrl: './outlet-form.component.html',
  styleUrls: ['./outlet-form.component.scss']
})
export class OutletFormComponent extends FormComponent<IOutlet, SalonFinderOutletService> implements OnInit, OnChanges {

  dataLoaded = false;
  savingForm = false;
  selectedValue: string;
  isLoading = true;
  allAdvertisers: any;
  vcode: any;
  isEdit: boolean;
  advertiserID: number;

  @ViewChild('UploadFileInput') uploadFileInput: ElementRef;
  myfilename = 'Select File';

  outletImages: IUploadImages[]

  constructor(
    private readonly fb: FormBuilder,
    private readonly snackBar: MatSnackBar,
    public readonly baseService: SalonFinderOutletService,
    private readonly salonFinderAdvertiserService: SalonFinderAdvertiserService,
    private readonly salonFinderRegisteredEntityService: SalonFinderRegisteredEntityService,
    private readonly salonFinderUploadImageService: SalonFinderUploadImagesService,
    private readonly AWSService: AWSServiceService,
    private router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {
    super();

  }

  updateOutletCodePrefix(e) {
    if((this.formData.value.id <=0 || this.formData.value.id == null) && e.target.value.length>2 && !this.formData.get('outletCodePrefix').dirty) {
      this.formData.patchValue({
        outletCodePrefix: this.baseService.makeOutletCodePrefix(this.formData.value.advertiser, this.formData.value)
      })
    }
  }

  get bankingEntity() {
    return this.formData.get('bankingEntity') as FormGroup
  }

  get opHours() {
    return this.formData.get('opHours') as FormArray
  }

  get loadedAdvertiser() {
    return this.formData?.value?.advertiser
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loadedModel) {
      this.initForm();
      this.outletImages = (this.formData.get('uploadImages') as FormArray).value.filter(v=>v.url)
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.outletImages = []

    if(this.formData.value.id){
      this.outletImages = (this.formData.get('uploadImages') as FormArray).value.filter(v=>v.url)
    }
  }

  fetchAdvertiser(advertiserId: any) {
    this.dataLoaded = true;
    this.salonFinderAdvertiserService.get(advertiserId).subscribe((data) => {
      this.formData.patchValue({
        advertiserID: data.id,
        advertiser: data
      })
    });
  }

  operationHoursErrors() {
    return this.opHours.controls.filter(c=>c.errors?.errorHours).length > 0?true:false
  }

  getOpHoursControl(index: number) {

  }

  handleTime(opHour: AbstractControl, time: string, type: 'startTime' | 'endTime') {
    console.log(time)
    const selectedTime = DateTime.fromFormat(time, 'h:mm a')
    const currentDateTime = DateTime.utc()
    if(type === 'startTime') {
      opHour.patchValue({
        starthr: selectedTime.toUTC(),
        endhr: opHour.value.endhr.set({year: currentDateTime.year, month: currentDateTime.month, day: currentDateTime.day})
      })
    }
    if(type === 'endTime') {
      opHour.patchValue({
        endhr: selectedTime.toUTC(),
        starthr: opHour.value.starthr.set({year: currentDateTime.year, month: currentDateTime.month, day: currentDateTime.day})
      })
    }
    const newOutletOpeningHours = this.opHours.controls.map(c=>c.value['key'] !== opHour.value['key']?c:opHour)
    console.log(newOutletOpeningHours)
    this.formData.patchValue({
      opHours: newOutletOpeningHours
    })
  }

  handleCheckbox(day:string, e:MatCheckboxChange) {
    // handle operation hours checkbox
    if(day != '') {
      let opHours = this.opHours;

      for(let i = 0; i < opHours.length; i++) {
        let opHour = opHours[i];
        if(opHour.key === day) {
          // console.log(opHour.key + '-' + day);
          opHour.closed = e.checked ? 1 : 0
        }
      }
    }
  }

  fillAdvertisers() {
    let advertisersDropdown = [];
    this.salonFinderAdvertiserService
    .list()
    .subscribe((data: any) => {
      this.allAdvertisers=data;
      this.isLoading = false;
      for(let i = 0; i < data.length; i++) {
        advertisersDropdown.push({
            id: data[i].id,
            name: data[i].name,
        });
      }
      this.allAdvertisers=sortList(advertisersDropdown, 'name', 'asc');
    })
  }

  handleChangeOption(value) {
    this.fetchAdvertiser(value);
  }

  onSelectedImage(event) {
    if(event.target.files.length>0) {
      const file = event.target.files[0];
      this.formData.get('backgroundImage').setValue(file);
    }
  }

  async upload(event) {
    const uploadFileList: FileList | null = event.target.files
    this.savingForm = true
    for(var i=0; i<uploadFileList.length; i++) {
      const uploadFile = uploadFileList.item(i);
      const savedFileName = this.salonFinderUploadImageService.getFileName(uploadFile.name)
      let uploadFileLocationKey = this.salonFinderUploadImageService.getPath(savedFileName)
      var params = {
        Body: uploadFile,
        Bucket: environment.salonFinder.uploadBucket,
        Key: uploadFileLocationKey,
        ACL: ObjectCannedACL.public_read,
        ContentType: uploadFile.type,
        ContentLength: uploadFile.size
      };
      await this.AWSService.S3Client.send(new PutObjectCommand(params))
      const uploadImageModel = await this.salonFinderUploadImageService.save({
        url: environment.salonFinder.bucketURL+"/"+uploadFileLocationKey,
        fileName: savedFileName,
        mimetype: uploadFile.type,
        bucketURL: environment.salonFinder.uploadBucket,
        path: uploadFileLocationKey,
        linkType: UploadImageLinkTypeEnum.OUTLET,
        linkID: 0,
        pos: i+1,
        name: uploadFile.name
      }).toPromise()
      this.outletImages.push(uploadImageModel)
    }
    this.savingForm = false
  }

  drop(event: CdkDragDrop<IUploadImages[]>) {
    moveItemInArray(this.outletImages, event.previousIndex, event.currentIndex);
  }

  deleteImage(model: IUploadImages) {
    this.outletImages = this.outletImages.filter(u=>u.id != model.id)
  }

  save() {
    this.savingForm = true
    if(this.formErrors().length>0) {
      this.snackBar.open('Please fix all errors in the form', 'Close', {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      this.savingForm = false
    } else {
      let formValue = this.formData.value
      formValue.uploadImages = this.outletImages.map((o,i)=> {
        return {
          ...o,
          pos: i+1
        }
      })
      formValue.outletCode = formValue.outletCodePrefix + formValue.outletCodeNumber
      this.baseService.save(formValue, this.loadedModel.id)
        .subscribe(d=> {
          this.savingForm = false;

          this.snackBar.open('Outlet has been saved successfully!', 'Close', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
          if(!this.loadedModel.id) {
            this.router.navigate([`/salon-finder/advertisers/outlets/add/${d.advertiserID}`])
          }
        })
    }
  }
}

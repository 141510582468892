<form [formGroup]="formData">
  <div class="container">
    <mat-card>
      <mat-card-header>
        <mat-card-title class="pt-3">Add Collection</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <!-- <div class="col-12 imageWrapper position-relative mb-4" *ngIf="formData.get('featured').value===true || allowUpload===true"> -->
          <div class="col-12 imageWrapper position-relative mb-4">
            Background Image : 
            <img width="100%" ckla src="{{formData.value.background_image}}" />
            <div class="position-absolute bottom-0 end-0">
              <app-shared-upload-button 
                [uploadBucket]="uploadBucket"
                [fileNamePrefix]="fileNamePrefix"
                [uploadPath]="uploadPath"
                (uploadedCompletes)="uploadedFiles($event)"
                [multiUpload]="false"
              ></app-shared-upload-button>
            </div>
          </div>
          <!-- <div class="col-6 col-sm-12 col-md-8 col-lg-6 col-xl-8">
            <div class="row"> -->
              <mat-form-field class="col-12" appearance="fill">
                <mat-label>Collection Name</mat-label>
                <input matInput maxlength="120" placeholder="Collection Name" formControlName="name" (blur)="setUpSlug()">            
                <mat-error>
                  <span *ngIf="formData.get('name').hasError('required')">Collection Name is <strong>required</strong></span>
                </mat-error>
              </mat-form-field>
              <!-- <mat-form-field class="col-12 col-sm-6 col-lg-4" appearance="fill">
                <mat-label>Start Date</mat-label>
                <input matInput [matDatepicker]="startDatePicker" formControlName="startDate">
                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #startDatePicker></mat-datepicker>
                <mat-error>
                  <span *ngIf="formData.get('startDate').hasError('required')">Start Date is <strong>required</strong></span>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-12 col-sm-6 col-lg-4" appearance="fill">
                <mat-label>End Date</mat-label>
                <input matInput [matDatepicker]="endDatePicker" formControlName="endDate">
                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #endDatePicker></mat-datepicker>
                <mat-error>
                  <span *ngIf="formData.get('endDate').hasError('required')">End Date is <strong>required</strong></span>
                </mat-error>
              </mat-form-field> -->
              <mat-form-field class="col-12 col-sm-6 col-lg-4" appearance="fill">
                <mat-label>Select Date Range</mat-label>
                <mat-date-range-input [rangePicker]="picker">                 
                  <input matStartDate placeholder="Start Date" formControlName="startDate" #dateRangeStart>                 
                  <input matEndDate placeholder="End Date" formControlName="endDate" #dateRangeEnd>                
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </mat-form-field>
              <mat-form-field class="col-12 col-sm-6 col-lg-4" appearance="fill">
                <!-- <mat-toolbar> -->
                  <!-- <mat-label>Status</mat-label> -->
                  <mat-select matTooltip="Select Status" autocomplete="off" formControlName="status" placeholder="Status" class="backgroundImage">
                    <mat-option *ngFor="let status of allStatus"  [value]="status">
                      {{status}}
                    </mat-option>
                  </mat-select>
                  <mat-error>
                    <span *ngIf="formData.get('status').hasError('required')">Status is <strong>required</strong></span>
                  </mat-error>
                <!-- </mat-toolbar>  -->
              </mat-form-field>  
              <mat-form-field class="col-12" appearance="fill">
                <mat-label>Slug</mat-label>
                <input matInput maxlength="50" placeholder="Slug" formControlName="slug">            
                <mat-error>
                  <span *ngIf="formData.get('slug').hasError('required')">Slug is <strong>required</strong></span>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-12" appearance="fill">
                <mat-label>Page Title</mat-label>
                <input matInput maxlength="150" placeholder="Page Title" formControlName="pageTitle">            
                <mat-error>
                  <span *ngIf="formData.get('pageTitle').hasError('required')">Page Title is <strong>required</strong></span>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-12" appearance="fill">
                <mat-label>Meta Description</mat-label>
                <input matInput maxlength="150" placeholder="Meta Description" formControlName="metaDescription">            
                <mat-error>
                  <span *ngIf="formData.get('metaDescription').hasError('required')">Meta Description is <strong>required</strong></span>
                </mat-error>
              </mat-form-field>
              <div class="col-12 col-sm-6 col-lg-12 mt-4">
                <mat-slide-toggle class="ml-5" formControlName="featured">
                  Featured
                </mat-slide-toggle>
              </div>
              <div class="col-6 mt-5">
                <mat-slide-toggle class="ml-4" formControlName="active">
                  Active
                </mat-slide-toggle>
              </div> 
            <!-- </div>
          </div>        -->
        </div>
        <div class="container">
          <mat-card class="mt-3">
            <mat-card-content>
              <mat-tab-group>
                <mat-tab label="Services - {{ serviceTotal }}">
                  <salon-finder-collection-add-service [loadedCollection]="loadedModel" (totalEvent)="setServiceTotal($event)" (parentFunctionnewselectedServices)="parentFunctionnewselectedServices($event)"></salon-finder-collection-add-service>
                </mat-tab>
              </mat-tab-group>
            </mat-card-content>
          </mat-card>
        </div>
        <mat-card-actions>
          <div class="row">
            <div class="col-12 text-center">
              <button mat-raised-button color="primary" class="m-2" (click)="save()" [disabled]="formData.status != 'VALID' || savingForm">Save</button>
              <button mat-raised-button class="m-2"  [routerLink]="['/salon-finder/collections']">Cancel</button>
            </div>
          </div>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>   
  </div>
</form>
import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { NotAuthorizedException } from '@aws-sdk/client-cognito-identity-provider';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Component({
  selector: 'darvis-app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  formData: FormGroup = null;
  savingForm: boolean = false

  logingInViaGoogle: boolean = false

  constructor(
    private readonly route: ActivatedRoute, 
    private readonly auth: AuthService, 
    private readonly authService: AuthService, 
    public router: Router, 
    private readonly cookieHelper: CookieService
  ) { 
    this.formData = this.authService.makeFormData()
  }

  

  async login() {
    if(this.formData.valid) {
      const username = this.formData.get('username').value;
      const password = this.formData.get('password').value;
      try {
        const result = await this.authService.loginUserWithUserNameNPassword(username, password)
        this.authService.setTokenResponse({
          access_token: result.AuthenticationResult.AccessToken,
          refresh_token: result.AuthenticationResult.RefreshToken,
          expires_in: result.AuthenticationResult.ExpiresIn,
          id_token: result.AuthenticationResult.IdToken
        })
        this.router.navigate(['index'])
      } catch(e) {
        if(e instanceof NotAuthorizedException) {
          this.formData.setErrors({login: e.message})
          console.log(this.formData)
          console.log(e)
        }
        
      }
      
    }
  }

  toggleGoogleLogin() {
    this.logingInViaGoogle = !this.logingInViaGoogle
  }

  loginViaGoogle() {
    this.toggleGoogleLogin()
    const location = window.location
    location.href = this.authService.authURL
  }

  async ngOnInit(): Promise<void> {
    const code = this.route.snapshot.queryParamMap.get('code');
    const state = this.route.snapshot.queryParamMap.get('state');
    const signinRedirect = this.cookieHelper.get(environment.auth.signInCookie);
    if (state === 'GET_CODE' && code) {
      try {
        await this.authService.exchangeCodeWithToken(code);
        if(signinRedirect) {
          this.cookieHelper.delete(environment.auth.signInCookie)
          this.router.navigateByUrl(signinRedirect);
        }
      } catch (e) {

      }
    } else {
      this.router.navigateByUrl('/sign-in')
    }
    this.formData = this.authService.makeFormData()
  }
}

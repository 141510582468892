<div class="container">
	<div class="row">
		<div class="text-center col-12 mb-4 mt-4">
			<img src="/assets/loginLogo.svg" />
		</div>
	</div>
	<div class="row justify-content-center">
		<div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
			<form [formGroup]="formData">
				<mat-card>
					<mat-card-title><h2 class="text-center">Change Password</h2></mat-card-title>
					<mat-card-subtitle>We have sent a password reset code by email to {{ enteredEmail }}. Enter it below to reset your password.</mat-card-subtitle>
					<mat-card-content>
							<mat-form-field class="col-12" appearance="fill">
								<mat-label>Confirmation Code</mat-label>
								<input matInput placeholder="Confirmation Code" formControlName="confirmationCode" />
								<mat-error>
									<span *ngIf="formData.get('confirmationCode').hasError('required')">Username is <strong>required</strong></span>
								</mat-error>
							</mat-form-field>
							<mat-form-field class="col-12" appearance="fill">
								<mat-label>Password</mat-label>
								<input matInput placeholder="Password" formControlName="password" type="password" />
								<mat-error>
									<span *ngIf="formData.get('password').hasError('required')">Password is <strong>required</strong></span>
								</mat-error>
							</mat-form-field>
							<mat-form-field class="col-12" appearance="fill">
								<mat-label>Confirmation Password</mat-label>
								<input matInput placeholder="Confirmation Password" formControlName="confirmationPassword" type="password" />
								<mat-error>
									<span *ngIf="formData.get('confirmationPassword').hasError('required')">Confirmation password is <strong>required</strong></span>
								</mat-error>
								<mat-error>
									<span *ngIf="formData.get('confirmationPassword').hasError('compare')">Confirmation password is not the same</span>
								</mat-error>
							</mat-form-field>
					</mat-card-content>
					<mat-card-actions class="text-center">
						<button mat-flat-button color="primary" class="me-4" (click)="updatePassword()">Update Password</button> 
						<a mat-flat-button color="basic" [routerLink]="['/forget-password']">Cancel</a>
					</mat-card-actions>
				</mat-card>
			</form>
		</div>
	</div>
</div>
	

import { Component, OnInit } from '@angular/core';
import { ICollection } from '../../interfaces/ICollection';
import { SalonFinderCollectionService } from '../../services/salon-finder-collection.service';

@Component({
  selector: 'app-collection-create',
  templateUrl: './collection-create.component.html',
  styleUrls: ['./collection-create.component.scss']
})

export class CollectionCreateComponent implements OnInit {

  collectionForm: ICollection = {
    id: 0,
    name: '',
    startDate: '', 
    endDate: '',
    featured: true,
    background_image: '',
    background_image_path: '',
    status: '',
    slug: '',
    pageTitle: '',
    metaDescription: '',
    active: true
  }

  constructor(private salonFinderCollectionService: SalonFinderCollectionService) { }

  ngOnInit(): void {
    
  }
}
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { IEmailNotification } from '../../interfaces/iemail-notification';
import { SalonFinderCollectionService } from '../../services/salon-finder-collection.service';
import { SalonFinderEmailNotificationService } from '../../services/salon-finder-email-notification.service';

@Component({
  selector: 'app-email-notification-update',
  templateUrl: './email-notification-update.component.html',
  styleUrls: ['./email-notification-update.component.scss']
})
export class EmailNotificationUpdateComponent implements OnInit {
  emailNotificationForm: IEmailNotification

  constructor(
    private activatedRoute: ActivatedRoute,
    private readonly emailNotificationService: SalonFinderEmailNotificationService) { }

  ngOnInit(): void {
    this.emailNotificationService.get(this.activatedRoute.snapshot.params.id)
      .subscribe(d=>this.emailNotificationForm = d)
  }

}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PutObjectCommand } from '@aws-sdk/client-s3';
import slugify from 'slugify';

import { IS3UploadFile } from '../../interfaces/IS3UploadFile';
import { AWSServiceService } from '../../services/awsservice.service';

@Component({
  selector: 'app-shared-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss']
})
export class UploadButtonComponent implements OnInit {

  @Input('allowExtension')
  _allowExtension: string[] = ["image/*"]

  get allowExtension() {
    return this._allowExtension?.join(',') || "*"
  }

  @Input('uploadBucket')
  uploadBucket: string

  @Input('fileNamePrefix')
  fileNamePrefix: string

  @Input('uploadPath')
  uploadPath: string

  @Input('multiUpload')
  multiUpload: boolean = false

  @Input('validateFile')
  validate: (file: File) => Promise<boolean>

  @Output('uploadingFiles')
  uploadingFiles = new EventEmitter<boolean>()

  @Output('uploadedCompletes')
  uploadedCompletes = new EventEmitter<IS3UploadFile[]>()

  constructor(private readonly AWSService: AWSServiceService) { }

  ngOnInit(): void {
  }

  getFileName(filename) {
    return `${this.fileNamePrefix}-${slugify(filename.toLowerCase())}`
  }

  getPath(filename) {
    return `${this.uploadPath}${filename}`
  }

  public async upload(event): Promise<void> {
    const uploadFileList: FileList | null = event.target.files
    let uploadedImages: IS3UploadFile[] = new Array<IS3UploadFile>();
    this.uploadingFiles.emit(true)    
    for(var i=0; i<uploadFileList.length; i++) {
      const uploadFile = uploadFileList.item(i);
      let validation = true 
      if(this.validate)
        validation = await this.validate(uploadFile)      
      if(validation) {
        const savedFileName = this.getFileName(uploadFile.name)
        let uploadFileLocationKey = this.getPath(savedFileName)
        var params = {
          Body: uploadFile, 
          Bucket: this.uploadBucket, 
          Key: uploadFileLocationKey,
          ContentType: uploadFile.type,
          ContentLength: uploadFile.size
        };
        await this.AWSService.S3Client.send(new PutObjectCommand(params))
        uploadedImages.push({
          ...params,
          FileName: savedFileName
        })
      }
    }
    this.uploadingFiles.emit(false)
    if(uploadedImages.length>0)
      this.uploadedCompletes.emit(uploadedImages)
  }
}

import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, ElementRef, ViewChild, OnInit, EventEmitter, Output, Input, SimpleChanges, OnChanges} from '@angular/core';
import {FormControl, NgControl} from '@angular/forms';
import { MatAutocompleteSelectedEvent,MatAutocomplete} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute } from '@angular/router';
import {Observable} from 'rxjs';
import {filter, map, startWith} from 'rxjs/operators';
import { UrlShortenerCollectionService } from '../../../url-shorterner/services/url-shortener-collection.service';
import { removeHttpsPrefix } from 'src/app/shared/helpers/RemoveHttsPrefix';
import { IMultiSelectFilterObject } from 'src/app/shared/interfaces/IMultiSelectFilterObject';
import { ITag } from '../../interfaces/ITag';

export class ItemList {
  constructor(public item: string, public selected?: boolean) {
    if (selected === undefined) selected = false;
  }
}

@Component({
  selector: 'darvis-share-multiselect-auto-complete',
  templateUrl: 'multiselect-auto-complete.component.html',
  styleUrls: ['multiselect-auto-complete.component.scss']
})
export class MultiselectAutocompleteComponent<T> implements OnInit, OnChanges {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  deepFilter = false;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredTags: Observable<any[]>;
  tags: ITag[] = [];
  allFilters: any[] = [];
  bookTitle: string;  

  @Output() filterCreated = new EventEmitter<ITag[]>();
 
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  
  @Input() startFilters: ITag[];

  
  constructor(
    private userService: UrlShortenerCollectionService,
    private activatedRoute: ActivatedRoute,
    ) {
      this.filteredTags = this.tagCtrl.valueChanges.pipe(
        startWith(null),
        map((tag: string | null) => (tag && typeof tag == 'string'? this._filter(tag) : this.allFilters.slice())),
      );
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes.startFilters && changes.startFilters.currentValue) {
      this.tags = this.tags.concat(changes.startFilters.currentValue).filter((tag, index, self) => self.findIndex(t => t.id === tag.id) === index);
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    let updatedFilters = this.allFilters.filter(ff => ff.value.toLowerCase().includes(filterValue)); 
    
    if(updatedFilters.length == 0){
      this.deepFilter = true;
      this.searchData(filterValue);
    }

    return updatedFilters;
  }

  ngOnInit() {
   this.searchData('')
  }

  remove(tag: ITag): void {
    const index = this.tags.findIndex(t=>t.id === tag.id || (t.key === 'custom' && t.value === tag.value))
    if (index >= 0) {
      this.tags.splice(index, 1);
      this.filterCreated.emit(this.tags);
      if(this.deepFilter){
        this.searchData('')
      }
    }
  }

  add(event: MatChipInputEvent): void {
    
    const value = (event.value || '').trim();
    
    // Add tag only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      if (value) {
        let filteredValue = removeHttpsPrefix(value)
        this.tags.push({
          id: 0,   
          key: 'custom',
          value: filteredValue
        });
        this.tagInput.nativeElement.value = '';
        this.filterCreated.emit(this.tags);
      }
      this.tagCtrl.setValue(null)
      event.chipInput!.clear();  
    }
  }

  searchData(filter:string){
    this.userService.getFilters(filter).subscribe(
      (result: any) =>{
          this.allFilters = result.data;
          this.tagCtrl.setValue(null);
      } 
    )
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const selectedValue = event.option.value as ITag
    this.tags.push(selectedValue);
    this.filterCreated.emit(this.tags)
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }
}
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { environment } from 'src/environments/environment';
import { IService } from '../interfaces/IService';
import { IServicePromotion } from '../interfaces/IServicePromotion';

@Injectable({
  providedIn: 'root'
})
export class SalonFinderPromoService extends CRUDService<IServicePromotion> {
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }

  constructor(readonly httpClient: HttpClient, private readonly fb: FormBuilder,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute) {
    super();
  }
  baseURL = environment.salonFinder.baseURL;
  apiPath = environment.salonFinder.promoBase;
  environmentCRUDPath = environment.salonFinder;
  list(params?: HttpParams): Observable<ICommonListingResponse<IServicePromotion[]>> {
    return this.httpList(params);
  }
  delete(id: string | number): Observable<IServicePromotion> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: IServicePromotion): Observable<IServicePromotion> {
    return this.save(model, id);
  }
  create(model: IServicePromotion): Observable<IServicePromotion> {
    return this.save(model, null);
  }
  makeFormGroup(loadedModel?: IServicePromotion): FormGroup {
    return this.fb.group({
      advertiserID: this.fb.control(loadedModel?.advertiserID, [
        Validators.required,
        Validators.nullValidator
      ]),
      service: this.fb.control(loadedModel?.service),
      serviceID: this.fb.control(loadedModel?.serviceID),
      name: this.fb.control(loadedModel?.name, [
        Validators.required,
        Validators.maxLength(256),
        Validators.nullValidator
      ]),
      servicePrice: [loadedModel?.servicePrice],
      price: this.fb.control(loadedModel?.price, [
        Validators.required,
        RxwebValidators.lessThan({fieldName: 'servicePrice'}),
        RxwebValidators.minNumber({value: 0}),
        RxwebValidators.numeric({allowDecimal: true, acceptValue: NumericValueType.PositiveNumber})
      ]),
      startDate: this.fb.control(loadedModel?.startDate),
      endDate: this.fb.control(loadedModel?.endDate),
      terms: this.fb.control(loadedModel?.terms, [
        Validators.required,
        Validators.nullValidator
      ]),
      outlets: this.fb.array([]),
      active: this.fb.control(loadedModel?.active)
    });
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }

  // create data
  createData(data: any): Observable<any> {
    // console.log(data);
    return this.httpClient.post(this.fullAPIPath + '/', data);
  }

  // update data
  updateData(id: any, data: any): Observable<any> {
    return this.httpClient.post(this.fullAPIPath + '/' + id, data);
  }

  // get single data
  get(id: string): Observable<IServicePromotion> {
    return this.httpGet(id);
  }

  getAllDataByAdvertiser(aid) {
    const url = this.fullAPIPath +  environment.salonFinder.getPromosByAdvertiser + '/' + aid;

    return this.httpClient.get(url)
      .pipe(
        catchError(error => {
        return throwError('Something went wrong!');
      })
      );
  }

  activatePromo(data: any): Observable<any> {
    return this.httpClient.post(this.fullAPIPath + environment.salonFinder.deactivateOutlets, data);
  }
}

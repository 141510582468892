<mat-form-field class="fullWidth">
	<mat-chip-grid #chipList>
	<mat-chip-row
		*ngFor="let tag of tags"
		[removable]="removable"
		(removed)="remove(tag)">
		{{tag.key}}: {{tag.value}}
		<mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
	</mat-chip-row>
	<input
		placeholder="Search by short url, description client or campaign names"
		#tagInput
		[formControl]="tagCtrl"
		[matAutocomplete]="auto"
		[matChipInputFor]="chipList"
		[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
		[matChipInputAddOnBlur]="addOnBlur"
		(matChipInputTokenEnd)="add($event)" />
	</mat-chip-grid>
	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
		<mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
			{{tag.key}} : {{tag.value}}
		</mat-option>
	</mat-autocomplete>
</mat-form-field>
import { DisplayType } from "../enums/staticReportConfig.enum";
import { IDisplayType } from "../interfaces/iStaticReportConfig";

export const ConfigDisplayTypes : IDisplayType[] = [
    {disable: false, name: 'Pie Chart', value: DisplayType.PIECHART},
    {disable: false, name: 'Table', value: DisplayType.TABLE},
    {disable: false, name: 'Sample Size', value: DisplayType.SAMPLE_SIZE},
    {disable: false, name: 'Statistics', value: DisplayType.STATISTICS},
    {disable: true, name: 'Rating Bar', value: DisplayType.RATING_BAR},
    {disable: false, name: 'Vertical Bar', value: DisplayType.VERTICAL_BAR},
    {disable: false, name: 'Horizontal Bar', value: DisplayType.HORIZONTAL_BAR},
    {disable: false, name: 'Compare', value: DisplayType.COMPARE},
    {disable: false, name: 'Statistics Cloud', value: DisplayType.STATISTICS_CLOUD},
    {disable: false, name: 'Statistics Group', value: DisplayType.STATISTICS_GROUP},
		{disable: false, name: 'Horizontal Bar - Brand Compare', value: DisplayType.HORIZONTAL_BAR_BRAND_COMPARE},
		{disable: false, name: 'Vertical Bar - Brand Compare', value: DisplayType.VERTICAL_BAR_BRAND_COMPARE},
		{disable: false, name: 'Table - Group Compare', value: DisplayType.TABLE_GROUP},
		{disable: false, name: 'Vertical Bar - Group Compare', value: DisplayType.VERTICAL_BAR_GROUP},
		{disable: false, name: 'Vertical Bar - Group Compare', value: DisplayType.HORIZONTAL_BAR_GROUP},
  ];

export const ConfigDisplayWidths = [
  {name: '100%', value: 12},
  {name: '50%', value: 6},
  {name: '25%', value: 4},
]

export const ConfigReportFilter = [
  {name: 'Customise', value: 'full-search'},
  {name: 'Campaign Success', value: 'brands-search'},
  {name: 'Untapped Oopportunities', value: 'beauty-concerns-search'},
]

export const ConfigCompare = [
  {name: 'Is', value: 'is'},
  {name: 'Not', value: 'not'},
]

export const CountryOfOrigin = [
  {name: 'Singapore', value: 'sg'},
  {name: 'Malaysia', value: 'my'},
]


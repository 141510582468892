<div class="" style="padding-bottom: 70px;">
  <form [formGroup]="formData">
    <div class="container">
      <mat-card>
        <mat-card-content>
          <div class="row">
            <div class="col-12">
              <mat-form-field appearance="fill" class="fullWidth">
                <mat-label>Name</mat-label>
                <input matInput #firstName maxlength="120" placeholder="Title of the form" formControlName="name">
                <mat-error *ngIf="formErrors.name.errors?.required">
                  First Name is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field appearance="fill" class="fullWidth">
                <mat-label>Start Date</mat-label>
                <input placeholder="Start Date" matInput [matDatepicker]="startDatePicker" formControlName="startDate" />
                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #startDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field class="fullWidth" appearance="fill">
                <mat-label>End Date</mat-label>
                <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" placeholder="End Date" />
                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #endDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field class="fullWidth" appearance="fill">
                <mat-label>Slug</mat-label>
                <input matInput #slug maxlength="256" placeholder="URL Slug (leave blank for autogenerate)"
                  formControlName="slug">
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field class="fullWidth" appearance="fill">
                <mat-label>Seo Title</mat-label>
                <input matInput #seoTitle maxlength="256" placeholder="Title of the Seo"
                  formControlName="seoTitle">
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field class="fullWidth" appearance="fill">
                <mat-label>Seo Description</mat-label>
                <input matInput #seoDescription maxlength="256" placeholder="Description of the Seo"
                  formControlName="seoDescription">
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <div class="row mt-3 mb-3" cdkDropListGroup formArrayName="formPage">
        <section *ngFor="let formPage of formPages.controls; let formPageIndex=index" class="mb-2 col-6">
          <mat-card [formGroupName]="formPageIndex">
            <mat-card-header>
              <mat-card-title class="d-flex justify-content-between">
                <span class="align-self-center">Page {{ formPage.value.page }} Questions ({{
                  formPageQuestion(formPage).length }})</span>
                <span>
                  <a mat-icon-button color="warning" [hidden]="formPageIndex==0" (click)="downPage(formPageIndex)">
                    <mat-icon>arrow_drop_up</mat-icon>
                  </a>
                  <a mat-icon-button color="warning" [hidden]="formPageIndex==formPagesCount-1"
                    (click)="upPage(formPageIndex)">
                    <mat-icon>arrow_drop_down</mat-icon>
                  </a>
                  <a mat-icon-button color="primary" (click)="delPage(formPage.value.page)">
                    <mat-icon>close</mat-icon>
                  </a>
                </span>
              </mat-card-title>
            </mat-card-header>
            <div class="m-2">
              <mat-form-field class="fullWidth" appearance="fill">
                <mat-label>Title</mat-label>
                <input matInput #title maxlength="256" placeholder="Points for the answer (leave blank if no point)"
                  formControlName="title">
              </mat-form-field>
              <mat-form-field class="fullWidth" appearance="fill">
                <mat-label>Slug</mat-label>
                <input matInput #slug maxlength="256" placeholder="URL Slug (leave blank for autogenerate)"
                  formControlName="slug">
              </mat-form-field>
              <div class="fullWidth" appearance="fill">
                <mat-label>Content</mat-label>
                <md-editor formControlName="content" [options]="options" [height]="'200px'" [upload]="doUpload">
                </md-editor>
              </div>
            </div>
            <mat-divider></mat-divider>
            <div class="row">
              <div class="col-6">
                <a mat-icon-button color="primary" (click)="addQuestionsDialog(formPage)" *ngIf="!isMaxQuestions(formPage)"  >
                  <mat-icon>playlist_add</mat-icon>
                </a>
           
              </div>
              <div class="col-6 text-right">
                <a mat-icon-button color="primary" title="Max question is 10" >
                  <mat-icon>info</mat-icon>
                </a>
              </div>
              
            </div>
            <section cdkDropList (cdkDropListDropped)="dragDropQuestionsInPage($event, formPage)"
              [cdkDropListData]="formPageQuestion(formPage).controls" class="formQuestionsContainer"
              formArrayName="formQuestion">
              <div class="mat-elevation-z2 p-3 mb-2"
                *ngFor="let question of formPageQuestion(formPage).controls; let questionIndex=index" cdkDrag>
                <section class="d-flex justify-content-between" [formGroupName]="questionIndex">
                  <span><span [hidden]="question.value.dependencies?.length<=0 || !question.value.dependencies">
                      <mat-icon class="dependencyIcon">link</mat-icon>
                    </span> {{ question.value.label }} - {{ question.value.controlType }}</span>
                  <span>
                    <a mat-icon-button color="primary"
                      (click)="syncFormQuestion(question.value.id, formPageIndex, questionIndex)"
                      [hidden]="!needSync(question.value.id)">
                      <mat-icon>autorenew</mat-icon>
                    </a>
                    <a mat-icon-button color="primary" (click)="editQns(question.value, formPageIndex)">
                      <mat-icon>mode_edit</mat-icon>
                    </a>
                    <a mat-icon-button color="primary" (click)="removeFormPageQuestion(formPageIndex, questionIndex)">
                      <mat-icon>delete</mat-icon>
                    </a>
                  </span>
                </section>

              </div>
            </section>
          </mat-card>
        </section>

      </div>
    </div>

  </form>
  <div class="fixed-bottom p-3">
    <div class="d-flex justify-content-center">
      <button mat-raised-button color="primary" class="m-2" (click)="saveForm()" [disabled]="savingForm">Save</button>
      <button mat-raised-button color="accent" class="m-2" (click)="addPage()" [disabled]="savingForm">Add new
        page</button>
      <button mat-raised-button class="m-2" [routerLink]="['/consumer-report/form']"
        [disabled]="savingForm">Cancel</button>
    </div>
  </div>
</div>
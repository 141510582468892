<div class="container">
  <h1 mat-dialog-title>Voucher Code: #{{data.voucherCode}}</h1>
  <ng-container *ngIf="loading">
    <div class="overlay"></div>
    <mat-progress-spinner [mode]="'indeterminate'" [diameter]="50"></mat-progress-spinner>
  </ng-container>
  <div mat-dialog-content class="row" *ngIf="!loading">
    <mat-form-field class="col-12" appearance="fill">
      <mat-label>Redeemed Date</mat-label>
      <input matInput [matDatepicker]="expiryDatePicker" [formControl]="redeemedDate" [max]="maxDate">
      <mat-datepicker-toggle matSuffix [for]="expiryDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #expiryDatePicker></mat-datepicker>
      <mat-error *ngIf="redeemedDate.hasError('required')">
        <span>Redeem date cannot be empty</span>
      </mat-error>
      <mat-error *ngIf="redeemedDate.hasError('matDatepickerMax')">
        <span>Expiry date must be later than today.</span>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="col-12" appearance="fill">
      <mat-label>Outlet</mat-label>
      <input type="text"
        placeholder="Select Redeemed Outlet"
        matInput
        [formControl]="outlet"
        [matAutocomplete]="auto">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayOutletName" autoSelectActiveOption>
        <mat-option *ngFor="let option of filteredOutlets | async" [value]="option">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
      <mat-error *ngIf="outlet.hasError('required')">
        <span>Please select an outlet</span>
      </mat-error>
      <mat-error *ngIf="outlet.hasError('oneOf')">
        <span>Please select an outlet</span>
      </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions *ngIf="!loading">
    <button mat-button (click)="save()">Save</button>
    <button mat-button [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
  </div>
</div>
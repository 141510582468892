import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss']
})
export class SignOutComponent implements OnInit {

  constructor(private readonly route: ActivatedRoute, private readonly auth: AuthService, private readonly authService: AuthService, public router: Router, private readonly cookieHelper: CookieService) { 

  }

  ngOnInit(): void {
    this.auth.clearToken();
    this.router.navigate(['index']) 
  }
}

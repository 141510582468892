import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import { IPost } from '../../interfaces/IPost';
import { TelegramCollectionService } from '../../services/telegram-collection.service';
import { MatDialog } from '@angular/material/dialog';
import { LinkClickDataComponent } from '../../components/link-click-data/link-click-data.component';
import { CalculateClickComparison, ProcessReturns } from '../../helpers/post/postUtils';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { DateFormat } from 'src/app/shared/helpers/DateFormat';
import { catchError, filter, firstValueFrom, map, merge, startWith, switchMap, zip } from 'rxjs';
import { C } from '@angular/cdk/keycodes';
import { UrlShortenerCollectionService } from 'src/app/url-shorterner/services/url-shortener-collection.service';
import { DateFilterFn } from '@angular/material/datepicker';
const math = require('mathjs');

interface Period {
  value: string;
  viewValue: string;
}

export enum ToggleEnumRange {
  Daily,
  Weekly,
  Monthly
}

export interface PostData {
  addedDate: string;
  post: string;
  views: string;
  viewRate: string;
  clicks: string;
  clickRate: string;
  shares: string;
  shareRate: string;
}

@Component({
  selector: 'app-post-index',
  templateUrl: './post-index.component.html',
  styleUrls: ['./post-index.component.scss'],
  providers: [   
    {provide: MAT_DATE_FORMATS, useValue: DateFormat},
  ],
})
export class PostIndexComponent implements OnInit {


  @Input()dateFilter: DateFilterFn<any>

  displayedColumns: string[] = ['addedDate', 'post', 'views', 'viewRate', 'clicks', 'clickRate', 'shares', 'shareRate', 'actions'];
  dataSource: MatTableDataSource<PostData>;

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  
  // @ViewChild(MatPaginator,  {static: true}) paginator: MatPaginator;
  @ViewChild(MatPaginator, {static: false})
  set paginator(value: MatPaginator) {
    if(this.dataSource !== undefined){
      this.dataSource.paginator = value;
    }
  }
  @ViewChild(MatSort) sort: MatSort

 
  generatingTable = false;
  generatingChart = false;
  periodLabel = [];
  periodTotalViews = [];
  periodTotalShares = [];
  periodTotalClicks = [];

  urlInfo: any;
  returnFilter: any;
  maxValue = 10;
  pageSize: number = 10;
  page: number = 0;
  totalMessage: number = 0;
  sortTable: boolean = false;

  filterByDate: boolean = false;

  periods: Period[] = [
    { value: 'TODAY', viewValue: 'Today' },
    { value: 'WEEK', viewValue: 'Past 7 Days' },
    { value: 'MONTH', viewValue: 'Past 30 Days' },
    { value: 'QUARTER', viewValue: 'Past 3 Months' },
    { value: 'LIFETIME', viewValue: 'Lifetime' },
  ];

  toggleEnumRange = ToggleEnumRange;
  selectedRange = ToggleEnumRange.Daily;
  selected = 'QUARTER';

  rangeFrom = new FormGroup({
    startFrom: new FormControl<Date | null>(null),
    endFrom: new FormControl<Date | null>(null),
  })

  rangeTo = new FormGroup({
    startTo: new FormControl<Date | null>(null),
    endTo: new FormControl<Date | null>(null),
  })

  range = new FormGroup({
    startRange: new FormControl<Date | null>(null),
    endRange: new FormControl<Date | null>(null),
  })

  public lineChartData: ChartConfiguration<'line'>['data'] = {
    labels: this.periodLabel,
    datasets: [
      {
        data: [],
        label: 'Views',
      },
      {
        data: [],
        label: 'Shares',
      },
      {
        data: [],
        label: 'Clicks',
      },
    ]
  };
  public lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    scales: {
      y: {
        min: 0,
        max: this.maxValue
      },
    },
    elements: {
      line: {
        tension: 0 // disables bezier curves
      }
    }

  };

  public lineChartLegend = true;

  tableSourceOfPosts: any[] = [];

  rangeStart: string = '';
  rangeEnd: string = '';

  rangeStartFrom: string = '';
  rangeEndFrom: string = '';

  rangeStartTo: string = '';
  rangeEndTo: string = '';

  comparisonFrom: any;
  comparisonTo: any;

  totalEngagements: any;

  totalPostFrom = 0;
  totalPostTo = 0;
  totalPostPercentage: any = 0;

  totalAverageViewsFrom: any = 0;
  totalAverageViewsTo: any = 0;
  totalAverageViewsPercentage: any = 0;

  totalAverageClicksFrom: any = 0;
  totalAverageClicksTo: any = 0;
  totalAverageClicksPercentage: any = 0;

  totalAverageSharesFrom: any = 0;
  totalAverageSharesTo: any = 0;
  totalAverageSharesPercentage: any = 0;

  totalFollowersAverage:any = 0;

  toolTipInfo :string = '';

  constructor(
    private readonly route: ActivatedRoute,
    private readonly telegramCollectionService: TelegramCollectionService,
    private activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly urlShortenerService: UrlShortenerCollectionService,
    private cdr: ChangeDetectorRef,
  ) {
  
    if(this.activatedRoute.snapshot.queryParams && this.activatedRoute.snapshot.queryParams.start !== undefined){
      
      this.rangeStart = this.activatedRoute.snapshot.queryParams.start.trim();
    } 
    if(this.activatedRoute.snapshot.queryParams && this.activatedRoute.snapshot.queryParams.end !== undefined){
      this.rangeEnd = this.activatedRoute.snapshot.queryParams.end.trim();
    
    }
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)  
    ).subscribe((event) => {

      if((event as NavigationEnd).url === '/telegram/post'){
        let endDate = moment().subtract(1,'days').format('MMM DD YYYY');
        
        endDate = moment(endDate).format('MMM DD YYYY')
    
        let daysAgo = moment().subtract(3, 'days').toDate();
        let startDate: any = moment(daysAgo).format('MMM DD YYYY');

        this.rangeStart = startDate;
        this.rangeEnd = endDate;

        this.router.navigate(['/telegram/post'],{queryParams:{start:moment(this.rangeStart).format('DD-MMM-YYYY'), end:moment(this.rangeEnd).format('DD-MMM-YYYY')}});
      }
    });

 

  }
  ngOnInit(): void {

    this.filterByDate  = true;
    let today = moment().format('DD-MMM-YYYY');
    let endDate = moment(this.rangeEnd).format("DD-MMM-YYYY");

    if(today == endDate){
      let rangeEnd = moment(this.rangeEnd).subtract(1,'day').toDate(); 
      this.rangeEnd = moment(rangeEnd).format('DD-MMM-YYYY')
    
      let rangeStart = moment(this.rangeStart).subtract(1,'day').toDate(); 
      this.rangeStart = moment(rangeStart).format('DD-MMM-YYYY')
    
    }

    this.range = new FormGroup({
      startRange: new FormControl<Date | null>(new Date(this.rangeStart)),
      endRange: new FormControl<Date | null>(new Date(this.rangeEnd)),
    })


    this.getPost();


    const element = document.getElementById("chart");
    element.scrollIntoView({ behavior: 'smooth' })

  }

  viewClicks(data) {

    data.viewClicks = true;
    const dialogRef = this.dialog.open(LinkClickDataComponent, {
      width: '1200px',
      data: data
    });
  }


  filterFutureDates = (d: any): boolean => {
    let currentDate = moment(d).isSame(new Date,'day');
    if(currentDate){
      return false;
    }else{
        let futureDate  = moment(d).isAfter(new Date, 'day');
        if(futureDate) return false;

        let pastDate  = moment(d).isBefore(new Date, 'day');
        if(pastDate) return true;

    }
  };


  openLinkClickDate(data) {

    data.viewClicks = false;

    const dialogRef = this.dialog.open(LinkClickDataComponent, {
      width: '1200px',
      data: data
    });
    dialogRef.afterClosed().subscribe(v => {
      if (v) {
        this.dataSource = new MatTableDataSource<PostData>();
        this.filterByDate = true;     
        this.getPost();
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  onFetchData() {
    if(this.range.value.startRange && this.range.value.endRange){
        this.filterByDate = true;
        this.rangeStart = moment(this.range.value.startRange).format();
        this.rangeEnd = moment(this.range.value.endRange).format() 

        this.getPost()
    }
  }

  removeDuplicates = (array) => {
    const uniqueLink = new Set();
    return array.filter((obj) => {
      if (!uniqueLink.has(obj.link)) {
        uniqueLink.add(obj.link);
        return true;
      }
      return false;
    });
  };

  sortChange(event:any){

     
    this.generatingTable = true;

    this.filterByDate = true;
   
    if(event.direction == ''){
      this.sort.start = 'asc'
    }

    let sortedData = this.dataSource.filteredData.sort((a:any, b:any) => {

      if(event.active == 'addedDate'){
        a.label = new Date(a.label);
        b.label = new Date(b.label);
       
        if(event.direction == 'asc'){          
          return a.label - b.label;
        }else{
         return b.label - a.label;
        }   

      }else{
        if(event.direction == 'asc'){
          return  a[event.active] - b[event.active]
        }else{
        return b[event.active] - a[event.active]
        }
      }      
    })

    
    this.dataSource = new MatTableDataSource(sortedData);

    this.dataSource.paginator = this.paginator ;
    this.dataSource.sort = event;

    this.generatingTable = false;

  }

  async getPost() {

    this.router.navigate(['/telegram/post'],{queryParams:{start:moment(this.rangeStart).format('DD-MMM-YYYY'), end:moment(this.rangeEnd).format('DD-MMM-YYYY')}});
 
    this.generatingChart = true;
    this.generatingTable = true;

    this.periodLabel = [];
    this.periodTotalViews = [];
    this.periodTotalShares = [];
    this.periodTotalClicks = [];

   
    this.maxValue = 0;
    
        
    this.dataSource = new MatTableDataSource([]);
    let periodValueHolder = [];

    let start = moment(this.rangeStart).utc(true).format();

    let addDayOnEnd = moment(this.rangeEnd).add(1,'days');

    let end = moment(addDayOnEnd).utc(true).format();

    zip(
      this.telegramCollectionService.getAllPosts(
        start,
        end
        ),
      this.telegramCollectionService.getAllFollowers(start,
        end)
    )
    .pipe(
      switchMap(async ([postResponse, followerResponse]) => {
      
        for(let data of postResponse.data){
          data.tags = []
          data.totalClicks = 0;
          if(data.messageLinks.length > 0){
              for(let ml of data.messageLinks){
                if(ml.shortUrlID !== null){
                  let response = await firstValueFrom(this.urlShortenerService.getUser(ml.shortUrlID))
                  data.totalClicks += response.totalRequested;
                  data.tags.push(response);            
                }
              }
          }
        }
        return [postResponse, followerResponse]
      })
    )
    .subscribe(async ([postResponse, followerResponse]) => {

        let rangeStart:string
        let rangeEnd:string

        if(this.filterByDate){
          rangeStart = start;
          rangeEnd = end;
        }else{
          rangeStart = this.rangeStart;
          rangeEnd = this.rangeEnd;
        }

    
        let res = await ProcessReturns(followerResponse.data, postResponse.data, new Date(rangeStart), new Date(rangeEnd), this.selectedRange, this.telegramCollectionService, this.filterByDate);

        if (res) {
          for (let data of res.socialEngagementResult) {
            data.addedDate = moment(data.addedDate).format('DD MMM YYYY');
            this.periodLabel.push(data.label);

            periodValueHolder.push(data.views);
            periodValueHolder.push(data.shares);
            periodValueHolder.push(data.clicks)
            
            this.periodTotalViews.push(data.views);
            this.periodTotalShares.push(data.shares);
            this.periodTotalClicks.push(data.clicks);

          }
          
          this.maxValue = Math.max(...periodValueHolder) > this.maxValue ? Math.max(...periodValueHolder) : this.maxValue;
          this.lineChartOptions.scales.y.max = this.maxValue
          this.lineChartData.datasets[0].data = this.periodTotalViews;
          this.lineChartData.datasets[1].data = this.periodTotalShares;
          this.lineChartData.datasets[2].data = this.periodTotalClicks;
          this.lineChartData.labels = this.periodLabel;
    
          setTimeout(()=> {
            this.chart?.chart.update();
            this.generatingChart = false;
          },500)


          this.totalEngagements = res;
          this.totalFollowersAverage = res.totalFollowersAverage;
          this.toolTipInfo = 'Total average follower ' +
           'from: '+moment(this.rangeStart).format('MMM DD YYYY') + ' to: '+moment(this.rangeEnd).format('MMM DD YYYY')+ ' is ' + math.format(this.totalFollowersAverage) +'\n'+
           'Formulas: \n'+
           ' - Average view per post = Total views / Total post  \n'+
           ' - Average view rate based on followers = ( Average views per post / Total average follower ) X 100 \n'+
           ' - Average clicks per post = Total clicks / Total post\n'+
           ' - Average CTR = ( Total clicks / Total views ) X 100 \n'+
           ' - Average shares per post = Total shares / Total post \n'+
           ' - Average shares per view = ( Total shares / Total views ) X 100 \n' ;

          this.loadSocialEngagementTable(res.tableSource)

          this.filterByDate = false;
   
          this.generatingTable = false;
        
        }
      
    })



   
  }

  async loadSocialEngagementTable(tableData: any) {

    this.dataSource = new MatTableDataSource(tableData);

    this.dataSource.paginator = this.paginator;

    
    this.dataSource.sort = this.sort;

    this.generatingTable = false;


  }

 
  onChangeRange($event) {
    this.filterByDate = true;
    this.selectedRange = $event.value;

    this.getPost()
  }

  checkToDisplayRange() {
    return this.selected === 'QUARTER' || this.selected === 'LIFETIME' || this.selected === 'MONTH';
  }


  onStartRangeFromChange(event) {
    this.rangeStartFrom = moment(event.value).format('MMM DD YYYY')
    if (this.rangeStartFrom !== 'Invalid date') {
      this.loadComparisonPosts(this.rangeStartFrom, this.rangeEndFrom, this.rangeStartTo, this.rangeEndTo);
    }
  }

  onEndRangeFromChange(event) {
    this.rangeEndFrom = moment(event.value).format('MMM DD YYYY')
    if (this.rangeEndFrom !== 'Invalid date') {
      this.loadComparisonPosts(this.rangeStartFrom, this.rangeEndFrom, this.rangeStartTo, this.rangeEndTo);
    }
  }

  onStartRangeToChange(event) {
    this.rangeStartTo = moment(event.value).format('MMM DD YYYY')
    if (this.rangeStartTo !== 'Invalid date') {
      this.loadComparisonPosts(this.rangeStartFrom, this.rangeEndFrom, this.rangeStartTo, this.rangeEndTo);
    }
  }

  onEndRangeToChange(event) {
    this.rangeEndTo = moment(event.value).format('MMM DD YYYY')
    if (this.rangeEndTo !== 'Invalid date') {
      this.loadComparisonPosts(this.rangeStartFrom, this.rangeEndFrom, this.rangeStartTo, this.rangeEndTo);
    }
  }

  async loadComparisonPosts(startDateFrom: string, endDateFrom: string, startDateTo: string, endDateTo: string) {

    if (startDateFrom == '' || endDateFrom == '' && startDateTo == '' || endDateTo == '') {
      return false;
    }

    this.comparisonFrom = {
      start: moment(startDateFrom).utc(true).format(),
      end: moment(endDateFrom).utc(true).format()
    }

    this.comparisonTo = {
      start: moment(startDateTo).utc(true).format(),
      end: moment(endDateTo).utc(true).format()
    }

    this.telegramCollectionService.getPostsComparison(this.comparisonFrom, this.comparisonTo)
      .subscribe(async (res: any) => {

        if (res) {

          let clickComparisonTotal = await CalculateClickComparison(res, this.telegramCollectionService);

          if (clickComparisonTotal) {

            this.totalPostFrom = res.from.length
            this.totalPostTo = res.to.length
            this.totalPostPercentage = (((res.from.length - res.to.length) / res.to.length) * 100).toFixed(2)

            let averageViewsFrom = 0
            let averageViewsTo = 0

            this.totalAverageClicksFrom = (clickComparisonTotal.fromTotal / this.totalPostFrom).toFixed(0)
            this.totalAverageClicksTo = (clickComparisonTotal.toTotal / this.totalPostFrom).toFixed(0)
            this.totalAverageClicksPercentage = (((this.totalAverageClicksFrom - this.totalAverageClicksTo) / this.totalAverageClicksTo) * 100).toFixed(2)

            let averageSharesFrom = 0
            let averageSharesTo = 0

            // FROM 
            for (let result of res.from) {
              for (let fromResult of result.socialEngagement) {
                averageViewsFrom += fromResult.views;
                averageSharesFrom += fromResult.shares;
              }
            }
            // TO 
            for (let result of res.to) {
              for (let toResult of result.socialEngagement) {
                averageViewsTo += toResult.views;
                averageSharesTo += toResult.shares;
              }
            }

            this.totalAverageViewsFrom = (averageViewsFrom / this.totalPostFrom).toFixed(0);
            this.totalAverageViewsTo = (averageViewsTo / this.totalPostFrom).toFixed(0);
            this.totalAverageViewsPercentage = (((averageViewsFrom - averageViewsTo) / averageViewsTo) * 100).toFixed(2)

            this.totalAverageSharesFrom = (averageSharesFrom / this.totalPostFrom).toFixed(0);
            this.totalAverageSharesTo = (averageSharesTo / this.totalPostFrom).toFixed(0);
            this.totalAverageSharesPercentage = (((averageSharesFrom - averageSharesTo) / averageSharesTo) * 100).toFixed(2)



          }
        }
      })

  }

  navigateByDate(date) {
    this.filterByDate = true;
    this.rangeEnd = moment(date).format('DD-MMM-YYYY')
    this.rangeStart  = moment(this.rangeEnd).subtract(2, 'days').format('DD-MMM-YYYY');

    this.range = new FormGroup({
      startRange: new FormControl<Date | null>(new Date(this.rangeStart)),
      endRange: new FormControl<Date | null>(new Date(this.rangeEnd)),
    })
   this.getPost();
  }

  makeLinksClickable(element) {

   if(element.id != 0){
      return '<a target="_blank"  href="https://t.me/dailyvanity/' + element.messageID + '"> **[View actual post]** </a>';
    }

  }


}
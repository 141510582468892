<div class="row mb-3">
  <div class="col-12 text-right">
    <button mat-raised-button color="primary" [routerLink]="['/consumer-report/customer/create']">Add New
      Customer</button>
  </div>
</div>
<div class="row mb-3">
  <mat-form-field class="col-12">
    <mat-chip-grid #searchList aria-label="Search">
      <mat-chip-row *ngFor="let term of searchTerms" (removed)="remove(term)">
        {{term}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
      <input class="fullWidth" placeholder="Search by email or company name. Hit enter to filter" #searchInput
        [formControl]="searchCtrl" [matChipInputFor]="searchList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)">
    </mat-chip-grid>
  </mat-form-field>
</div>
<div class="row">
  <div class="col-12">
    <table mat-table [dataSource]="userList" class="mat-elevation-z8" style="width: 100%">
      <ng-container matColumnDef="account">
        <th mat-header-cell *matHeaderCellDef> Account </th>
        <td mat-cell *matCellDef="let element">
          <span>{{element.company}}</span><br />
          <span>{{element.displayName}}</span><br />
          <span>{{element.email}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="paidReport">
        <th mat-header-cell *matHeaderCellDef> Paid Report(s) </th>
        <td mat-cell *matCellDef="let element;">
          <div *ngIf="element.points.length > 0" >
            <div *ngFor="let e of element.points">
              
              {{e.surveyEndName}} Expires on {{ e.expiryDate | dateTimeFromIso | dateTimeToLocal |
                dateTimeToFormat:'dd LLL yyyy' }}
              </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element;"> {{element.awsStatus | async}} </td>
      </ng-container>

      <ng-container matColumnDef="latestSearch">
        <th mat-header-cell *matHeaderCellDef> Search history </th>
        <td mat-cell *matCellDef="let element;">
          <div *ngFor="let e of element.latestSearch">
            <span *ngIf="e.commonID">
              <a href="{{ e.link }}" target="_blank"> {{ e.searchOn | dateTimeFromIso | dateTimeToLocal |
                dateTimeToFormat:'dd LLL yyyy' }}</a>
            </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef> Created Date </th>
        <td mat-cell *matCellDef="let element;"> {{element.registerOn | dateTimeFromIso | dateTimeToLocal |
          dateTimeToFormat:'dd LLL yyyy' }} </td>
      </ng-container>

      <ng-container matColumnDef="lastLoggedOn">
        <th mat-header-cell *matHeaderCellDef> Last login </th>
        <td mat-cell *matCellDef="let element;"> {{element.lastLoggedOn | dateTimeFromIso | dateTimeToLocal |
          dateTimeToFormat:'dd LLL yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="accountManager">
        <th mat-header-cell *matHeaderCellDef> Account Manager </th>
        <td mat-cell *matCellDef="let element;"><span *ngIf="element.accountManagerID">{{
            element.accountManager.displayName }}</span></td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element;">
          <a mat-icon-button color="primary" routerLink="/consumer-report/customer/update/{{element.id}}">
            <mat-icon>edit</mat-icon>
          </a>
          <ng-template [ngIf]="element.active==1">
            <a mat-icon-button color="primary" (click)="resyncUser(element.email)">
              <mat-icon>cached</mat-icon>
            </a> 
            <a mat-icon-button color="warn" (click)="disableUser(element.id)">
              <mat-icon>block</mat-icon>
            </a>
          </ng-template>
          <ng-template [ngIf]="element.active!=1">
            <a mat-icon-button color="default" (click)="enableUser(element.id)">
              <mat-icon>power_settings_new</mat-icon>
            </a>
          </ng-template>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="p-2"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="p-2"></tr>
    </table>
  </div>
</div>
<div class="row mb-4">
  <div class="col-12">
    <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [pageIndex]="page"
      (page)="changePage($event)">
    </mat-paginator>
  </div>
</div>
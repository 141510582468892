<ng-container *ngIf="multiUpload">
  <input type="file" class="file-upload" class="d-none" multiple (change)="upload($event)" #uploadInput accept="{{ allowExtension }}">
  <button mat-mini-fab color="primary" class="upload-btn" (click)="uploadInput.click()">
    <mat-icon>attach_file</mat-icon>
  </button>
</ng-container>

<ng-container *ngIf="!multiUpload">
  <input type="file" class="file-upload" class="d-none" (change)="upload($event)" #uploadInput accept="{{ allowExtension }}">
  <button mat-mini-fab color="primary" class="upload-btn" (click)="uploadInput.click()">
    <mat-icon>attach_file</mat-icon>
  </button>
</ng-container>
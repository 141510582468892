import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IStore } from '../../interfaces/istore';
import { ConsumerReportStoreService } from '../../services/consumer-report-store.service';

@Component({
  selector: 'app-store-update',
  templateUrl: './store-update.component.html',
  styleUrls: ['./store-update.component.scss']
})
export class StoreUpdateComponent implements OnInit {

  model: IStore = {
    name: "",
    description: "",
    originatedISOCountryCode: "SG",
    country: "Singapore",
    countryZone: 'Asia', 
    skincare: true,
    makeup: true,
    haircare: true
  }

  constructor(private readonly route: ActivatedRoute, private readonly consumerReportStoreService: ConsumerReportStoreService) { }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    this.consumerReportStoreService.get(id)
      .subscribe(res => {
        this.model = res;
      });
  }
}

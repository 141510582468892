import { HttpRequest as __HttpRequest } from "@smithy/protocol-http";
import { _json, collectBody, decorateServiceException as __decorateServiceException, expectBoolean as __expectBoolean, expectInt32 as __expectInt32, expectLong as __expectLong, expectNonNull as __expectNonNull, expectNumber as __expectNumber, expectString as __expectString, parseEpochTimestamp as __parseEpochTimestamp, take, withBaseException } from "@smithy/smithy-client";
import { CognitoIdentityProviderServiceException as __BaseException } from "../models/CognitoIdentityProviderServiceException";
import { AliasExistsException, CodeDeliveryFailureException, CodeMismatchException, ConcurrentModificationException, DuplicateProviderException, ExpiredCodeException, ForbiddenException, GroupExistsException, InternalErrorException, InvalidEmailRoleAccessPolicyException, InvalidLambdaResponseException, InvalidOAuthFlowException, InvalidParameterException, InvalidPasswordException, InvalidSmsRoleAccessPolicyException, InvalidSmsRoleTrustRelationshipException, InvalidUserPoolConfigurationException, LimitExceededException, MFAMethodNotFoundException, NotAuthorizedException, PasswordResetRequiredException, PreconditionNotMetException, ResourceNotFoundException, ScopeDoesNotExistException, SoftwareTokenMFANotFoundException, TooManyFailedAttemptsException, TooManyRequestsException, UnauthorizedException, UnexpectedLambdaException, UnsupportedIdentityProviderException, UnsupportedOperationException, UnsupportedTokenTypeException, UnsupportedUserStateException, UserImportInProgressException, UserLambdaValidationException, UsernameExistsException, UserNotConfirmedException, UserNotFoundException, UserPoolAddOnNotEnabledException, UserPoolTaggingException } from "../models/models_0";
import { EnableSoftwareTokenMFAException } from "../models/models_1";
export const se_AddCustomAttributesCommand = async (input, context) => {
  const headers = sharedHeaders("AddCustomAttributes");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminAddUserToGroupCommand = async (input, context) => {
  const headers = sharedHeaders("AdminAddUserToGroup");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminConfirmSignUpCommand = async (input, context) => {
  const headers = sharedHeaders("AdminConfirmSignUp");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminCreateUserCommand = async (input, context) => {
  const headers = sharedHeaders("AdminCreateUser");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminDeleteUserCommand = async (input, context) => {
  const headers = sharedHeaders("AdminDeleteUser");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminDeleteUserAttributesCommand = async (input, context) => {
  const headers = sharedHeaders("AdminDeleteUserAttributes");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminDisableProviderForUserCommand = async (input, context) => {
  const headers = sharedHeaders("AdminDisableProviderForUser");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminDisableUserCommand = async (input, context) => {
  const headers = sharedHeaders("AdminDisableUser");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminEnableUserCommand = async (input, context) => {
  const headers = sharedHeaders("AdminEnableUser");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminForgetDeviceCommand = async (input, context) => {
  const headers = sharedHeaders("AdminForgetDevice");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminGetDeviceCommand = async (input, context) => {
  const headers = sharedHeaders("AdminGetDevice");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminGetUserCommand = async (input, context) => {
  const headers = sharedHeaders("AdminGetUser");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminInitiateAuthCommand = async (input, context) => {
  const headers = sharedHeaders("AdminInitiateAuth");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminLinkProviderForUserCommand = async (input, context) => {
  const headers = sharedHeaders("AdminLinkProviderForUser");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminListDevicesCommand = async (input, context) => {
  const headers = sharedHeaders("AdminListDevices");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminListGroupsForUserCommand = async (input, context) => {
  const headers = sharedHeaders("AdminListGroupsForUser");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminListUserAuthEventsCommand = async (input, context) => {
  const headers = sharedHeaders("AdminListUserAuthEvents");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminRemoveUserFromGroupCommand = async (input, context) => {
  const headers = sharedHeaders("AdminRemoveUserFromGroup");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminResetUserPasswordCommand = async (input, context) => {
  const headers = sharedHeaders("AdminResetUserPassword");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminRespondToAuthChallengeCommand = async (input, context) => {
  const headers = sharedHeaders("AdminRespondToAuthChallenge");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminSetUserMFAPreferenceCommand = async (input, context) => {
  const headers = sharedHeaders("AdminSetUserMFAPreference");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminSetUserPasswordCommand = async (input, context) => {
  const headers = sharedHeaders("AdminSetUserPassword");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminSetUserSettingsCommand = async (input, context) => {
  const headers = sharedHeaders("AdminSetUserSettings");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminUpdateAuthEventFeedbackCommand = async (input, context) => {
  const headers = sharedHeaders("AdminUpdateAuthEventFeedback");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminUpdateDeviceStatusCommand = async (input, context) => {
  const headers = sharedHeaders("AdminUpdateDeviceStatus");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminUpdateUserAttributesCommand = async (input, context) => {
  const headers = sharedHeaders("AdminUpdateUserAttributes");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AdminUserGlobalSignOutCommand = async (input, context) => {
  const headers = sharedHeaders("AdminUserGlobalSignOut");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_AssociateSoftwareTokenCommand = async (input, context) => {
  const headers = sharedHeaders("AssociateSoftwareToken");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ChangePasswordCommand = async (input, context) => {
  const headers = sharedHeaders("ChangePassword");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ConfirmDeviceCommand = async (input, context) => {
  const headers = sharedHeaders("ConfirmDevice");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ConfirmForgotPasswordCommand = async (input, context) => {
  const headers = sharedHeaders("ConfirmForgotPassword");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ConfirmSignUpCommand = async (input, context) => {
  const headers = sharedHeaders("ConfirmSignUp");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_CreateGroupCommand = async (input, context) => {
  const headers = sharedHeaders("CreateGroup");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_CreateIdentityProviderCommand = async (input, context) => {
  const headers = sharedHeaders("CreateIdentityProvider");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_CreateResourceServerCommand = async (input, context) => {
  const headers = sharedHeaders("CreateResourceServer");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_CreateUserImportJobCommand = async (input, context) => {
  const headers = sharedHeaders("CreateUserImportJob");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_CreateUserPoolCommand = async (input, context) => {
  const headers = sharedHeaders("CreateUserPool");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_CreateUserPoolClientCommand = async (input, context) => {
  const headers = sharedHeaders("CreateUserPoolClient");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_CreateUserPoolDomainCommand = async (input, context) => {
  const headers = sharedHeaders("CreateUserPoolDomain");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DeleteGroupCommand = async (input, context) => {
  const headers = sharedHeaders("DeleteGroup");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DeleteIdentityProviderCommand = async (input, context) => {
  const headers = sharedHeaders("DeleteIdentityProvider");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DeleteResourceServerCommand = async (input, context) => {
  const headers = sharedHeaders("DeleteResourceServer");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DeleteUserCommand = async (input, context) => {
  const headers = sharedHeaders("DeleteUser");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DeleteUserAttributesCommand = async (input, context) => {
  const headers = sharedHeaders("DeleteUserAttributes");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DeleteUserPoolCommand = async (input, context) => {
  const headers = sharedHeaders("DeleteUserPool");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DeleteUserPoolClientCommand = async (input, context) => {
  const headers = sharedHeaders("DeleteUserPoolClient");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DeleteUserPoolDomainCommand = async (input, context) => {
  const headers = sharedHeaders("DeleteUserPoolDomain");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DescribeIdentityProviderCommand = async (input, context) => {
  const headers = sharedHeaders("DescribeIdentityProvider");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DescribeResourceServerCommand = async (input, context) => {
  const headers = sharedHeaders("DescribeResourceServer");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DescribeRiskConfigurationCommand = async (input, context) => {
  const headers = sharedHeaders("DescribeRiskConfiguration");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DescribeUserImportJobCommand = async (input, context) => {
  const headers = sharedHeaders("DescribeUserImportJob");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DescribeUserPoolCommand = async (input, context) => {
  const headers = sharedHeaders("DescribeUserPool");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DescribeUserPoolClientCommand = async (input, context) => {
  const headers = sharedHeaders("DescribeUserPoolClient");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_DescribeUserPoolDomainCommand = async (input, context) => {
  const headers = sharedHeaders("DescribeUserPoolDomain");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ForgetDeviceCommand = async (input, context) => {
  const headers = sharedHeaders("ForgetDevice");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ForgotPasswordCommand = async (input, context) => {
  const headers = sharedHeaders("ForgotPassword");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_GetCSVHeaderCommand = async (input, context) => {
  const headers = sharedHeaders("GetCSVHeader");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_GetDeviceCommand = async (input, context) => {
  const headers = sharedHeaders("GetDevice");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_GetGroupCommand = async (input, context) => {
  const headers = sharedHeaders("GetGroup");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_GetIdentityProviderByIdentifierCommand = async (input, context) => {
  const headers = sharedHeaders("GetIdentityProviderByIdentifier");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_GetLogDeliveryConfigurationCommand = async (input, context) => {
  const headers = sharedHeaders("GetLogDeliveryConfiguration");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_GetSigningCertificateCommand = async (input, context) => {
  const headers = sharedHeaders("GetSigningCertificate");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_GetUICustomizationCommand = async (input, context) => {
  const headers = sharedHeaders("GetUICustomization");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_GetUserCommand = async (input, context) => {
  const headers = sharedHeaders("GetUser");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_GetUserAttributeVerificationCodeCommand = async (input, context) => {
  const headers = sharedHeaders("GetUserAttributeVerificationCode");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_GetUserPoolMfaConfigCommand = async (input, context) => {
  const headers = sharedHeaders("GetUserPoolMfaConfig");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_GlobalSignOutCommand = async (input, context) => {
  const headers = sharedHeaders("GlobalSignOut");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_InitiateAuthCommand = async (input, context) => {
  const headers = sharedHeaders("InitiateAuth");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ListDevicesCommand = async (input, context) => {
  const headers = sharedHeaders("ListDevices");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ListGroupsCommand = async (input, context) => {
  const headers = sharedHeaders("ListGroups");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ListIdentityProvidersCommand = async (input, context) => {
  const headers = sharedHeaders("ListIdentityProviders");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ListResourceServersCommand = async (input, context) => {
  const headers = sharedHeaders("ListResourceServers");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ListTagsForResourceCommand = async (input, context) => {
  const headers = sharedHeaders("ListTagsForResource");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ListUserImportJobsCommand = async (input, context) => {
  const headers = sharedHeaders("ListUserImportJobs");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ListUserPoolClientsCommand = async (input, context) => {
  const headers = sharedHeaders("ListUserPoolClients");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ListUserPoolsCommand = async (input, context) => {
  const headers = sharedHeaders("ListUserPools");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ListUsersCommand = async (input, context) => {
  const headers = sharedHeaders("ListUsers");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ListUsersInGroupCommand = async (input, context) => {
  const headers = sharedHeaders("ListUsersInGroup");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_ResendConfirmationCodeCommand = async (input, context) => {
  const headers = sharedHeaders("ResendConfirmationCode");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_RespondToAuthChallengeCommand = async (input, context) => {
  const headers = sharedHeaders("RespondToAuthChallenge");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_RevokeTokenCommand = async (input, context) => {
  const headers = sharedHeaders("RevokeToken");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_SetLogDeliveryConfigurationCommand = async (input, context) => {
  const headers = sharedHeaders("SetLogDeliveryConfiguration");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_SetRiskConfigurationCommand = async (input, context) => {
  const headers = sharedHeaders("SetRiskConfiguration");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_SetUICustomizationCommand = async (input, context) => {
  const headers = sharedHeaders("SetUICustomization");
  let body;
  body = JSON.stringify(se_SetUICustomizationRequest(input, context));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_SetUserMFAPreferenceCommand = async (input, context) => {
  const headers = sharedHeaders("SetUserMFAPreference");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_SetUserPoolMfaConfigCommand = async (input, context) => {
  const headers = sharedHeaders("SetUserPoolMfaConfig");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_SetUserSettingsCommand = async (input, context) => {
  const headers = sharedHeaders("SetUserSettings");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_SignUpCommand = async (input, context) => {
  const headers = sharedHeaders("SignUp");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_StartUserImportJobCommand = async (input, context) => {
  const headers = sharedHeaders("StartUserImportJob");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_StopUserImportJobCommand = async (input, context) => {
  const headers = sharedHeaders("StopUserImportJob");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_TagResourceCommand = async (input, context) => {
  const headers = sharedHeaders("TagResource");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_UntagResourceCommand = async (input, context) => {
  const headers = sharedHeaders("UntagResource");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_UpdateAuthEventFeedbackCommand = async (input, context) => {
  const headers = sharedHeaders("UpdateAuthEventFeedback");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_UpdateDeviceStatusCommand = async (input, context) => {
  const headers = sharedHeaders("UpdateDeviceStatus");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_UpdateGroupCommand = async (input, context) => {
  const headers = sharedHeaders("UpdateGroup");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_UpdateIdentityProviderCommand = async (input, context) => {
  const headers = sharedHeaders("UpdateIdentityProvider");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_UpdateResourceServerCommand = async (input, context) => {
  const headers = sharedHeaders("UpdateResourceServer");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_UpdateUserAttributesCommand = async (input, context) => {
  const headers = sharedHeaders("UpdateUserAttributes");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_UpdateUserPoolCommand = async (input, context) => {
  const headers = sharedHeaders("UpdateUserPool");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_UpdateUserPoolClientCommand = async (input, context) => {
  const headers = sharedHeaders("UpdateUserPoolClient");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_UpdateUserPoolDomainCommand = async (input, context) => {
  const headers = sharedHeaders("UpdateUserPoolDomain");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_VerifySoftwareTokenCommand = async (input, context) => {
  const headers = sharedHeaders("VerifySoftwareToken");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const se_VerifyUserAttributeCommand = async (input, context) => {
  const headers = sharedHeaders("VerifyUserAttribute");
  let body;
  body = JSON.stringify(_json(input));
  return buildHttpRpcRequest(context, headers, "/", undefined, body);
};
export const de_AddCustomAttributesCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_AdminAddUserToGroupCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  await collectBody(output.body, context);
  const response = {
    $metadata: deserializeMetadata(output)
  };
  return response;
};
export const de_AdminConfirmSignUpCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_AdminCreateUserCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_AdminCreateUserResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_AdminDeleteUserCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  await collectBody(output.body, context);
  const response = {
    $metadata: deserializeMetadata(output)
  };
  return response;
};
export const de_AdminDeleteUserAttributesCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_AdminDisableProviderForUserCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_AdminDisableUserCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_AdminEnableUserCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_AdminForgetDeviceCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  await collectBody(output.body, context);
  const response = {
    $metadata: deserializeMetadata(output)
  };
  return response;
};
export const de_AdminGetDeviceCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_AdminGetDeviceResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_AdminGetUserCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_AdminGetUserResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_AdminInitiateAuthCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_AdminLinkProviderForUserCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_AdminListDevicesCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_AdminListDevicesResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_AdminListGroupsForUserCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_AdminListGroupsForUserResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_AdminListUserAuthEventsCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_AdminListUserAuthEventsResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_AdminRemoveUserFromGroupCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  await collectBody(output.body, context);
  const response = {
    $metadata: deserializeMetadata(output)
  };
  return response;
};
export const de_AdminResetUserPasswordCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_AdminRespondToAuthChallengeCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_AdminSetUserMFAPreferenceCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_AdminSetUserPasswordCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_AdminSetUserSettingsCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_AdminUpdateAuthEventFeedbackCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_AdminUpdateDeviceStatusCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_AdminUpdateUserAttributesCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_AdminUserGlobalSignOutCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_AssociateSoftwareTokenCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_ChangePasswordCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_ConfirmDeviceCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_ConfirmForgotPasswordCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_ConfirmSignUpCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_CreateGroupCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_CreateGroupResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_CreateIdentityProviderCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_CreateIdentityProviderResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_CreateResourceServerCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_CreateUserImportJobCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_CreateUserImportJobResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_CreateUserPoolCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_CreateUserPoolResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_CreateUserPoolClientCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_CreateUserPoolClientResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_CreateUserPoolDomainCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_DeleteGroupCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  await collectBody(output.body, context);
  const response = {
    $metadata: deserializeMetadata(output)
  };
  return response;
};
export const de_DeleteIdentityProviderCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  await collectBody(output.body, context);
  const response = {
    $metadata: deserializeMetadata(output)
  };
  return response;
};
export const de_DeleteResourceServerCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  await collectBody(output.body, context);
  const response = {
    $metadata: deserializeMetadata(output)
  };
  return response;
};
export const de_DeleteUserCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  await collectBody(output.body, context);
  const response = {
    $metadata: deserializeMetadata(output)
  };
  return response;
};
export const de_DeleteUserAttributesCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_DeleteUserPoolCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  await collectBody(output.body, context);
  const response = {
    $metadata: deserializeMetadata(output)
  };
  return response;
};
export const de_DeleteUserPoolClientCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  await collectBody(output.body, context);
  const response = {
    $metadata: deserializeMetadata(output)
  };
  return response;
};
export const de_DeleteUserPoolDomainCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_DescribeIdentityProviderCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_DescribeIdentityProviderResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_DescribeResourceServerCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_DescribeRiskConfigurationCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_DescribeRiskConfigurationResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_DescribeUserImportJobCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_DescribeUserImportJobResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_DescribeUserPoolCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_DescribeUserPoolResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_DescribeUserPoolClientCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_DescribeUserPoolClientResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_DescribeUserPoolDomainCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_ForgetDeviceCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  await collectBody(output.body, context);
  const response = {
    $metadata: deserializeMetadata(output)
  };
  return response;
};
export const de_ForgotPasswordCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_GetCSVHeaderCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_GetDeviceCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_GetDeviceResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_GetGroupCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_GetGroupResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_GetIdentityProviderByIdentifierCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_GetIdentityProviderByIdentifierResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_GetLogDeliveryConfigurationCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_GetSigningCertificateCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_GetUICustomizationCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_GetUICustomizationResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_GetUserCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_GetUserAttributeVerificationCodeCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_GetUserPoolMfaConfigCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_GlobalSignOutCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_InitiateAuthCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_ListDevicesCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_ListDevicesResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_ListGroupsCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_ListGroupsResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_ListIdentityProvidersCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_ListIdentityProvidersResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_ListResourceServersCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_ListTagsForResourceCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_ListUserImportJobsCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_ListUserImportJobsResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_ListUserPoolClientsCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_ListUserPoolsCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_ListUserPoolsResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_ListUsersCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_ListUsersResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_ListUsersInGroupCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_ListUsersInGroupResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_ResendConfirmationCodeCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_RespondToAuthChallengeCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_RevokeTokenCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_SetLogDeliveryConfigurationCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_SetRiskConfigurationCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_SetRiskConfigurationResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_SetUICustomizationCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_SetUICustomizationResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_SetUserMFAPreferenceCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_SetUserPoolMfaConfigCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_SetUserSettingsCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_SignUpCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_StartUserImportJobCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_StartUserImportJobResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_StopUserImportJobCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_StopUserImportJobResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_TagResourceCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_UntagResourceCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_UpdateAuthEventFeedbackCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_UpdateDeviceStatusCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_UpdateGroupCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_UpdateGroupResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_UpdateIdentityProviderCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_UpdateIdentityProviderResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_UpdateResourceServerCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_UpdateUserAttributesCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_UpdateUserPoolCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_UpdateUserPoolClientCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = de_UpdateUserPoolClientResponse(data, context);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_UpdateUserPoolDomainCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_VerifySoftwareTokenCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
export const de_VerifyUserAttributeCommand = async (output, context) => {
  if (output.statusCode >= 300) {
    return de_CommandError(output, context);
  }
  const data = await parseBody(output.body, context);
  let contents = {};
  contents = _json(data);
  const response = {
    $metadata: deserializeMetadata(output),
    ...contents
  };
  return response;
};
const de_CommandError = async (output, context) => {
  const parsedOutput = {
    ...output,
    body: await parseErrorBody(output.body, context)
  };
  const errorCode = loadRestJsonErrorCode(output, parsedOutput.body);
  switch (errorCode) {
    case "InternalErrorException":
    case "com.amazonaws.cognitoidentityprovider#InternalErrorException":
      throw await de_InternalErrorExceptionRes(parsedOutput, context);
    case "InvalidParameterException":
    case "com.amazonaws.cognitoidentityprovider#InvalidParameterException":
      throw await de_InvalidParameterExceptionRes(parsedOutput, context);
    case "NotAuthorizedException":
    case "com.amazonaws.cognitoidentityprovider#NotAuthorizedException":
      throw await de_NotAuthorizedExceptionRes(parsedOutput, context);
    case "ResourceNotFoundException":
    case "com.amazonaws.cognitoidentityprovider#ResourceNotFoundException":
      throw await de_ResourceNotFoundExceptionRes(parsedOutput, context);
    case "TooManyRequestsException":
    case "com.amazonaws.cognitoidentityprovider#TooManyRequestsException":
      throw await de_TooManyRequestsExceptionRes(parsedOutput, context);
    case "UserImportInProgressException":
    case "com.amazonaws.cognitoidentityprovider#UserImportInProgressException":
      throw await de_UserImportInProgressExceptionRes(parsedOutput, context);
    case "UserNotFoundException":
    case "com.amazonaws.cognitoidentityprovider#UserNotFoundException":
      throw await de_UserNotFoundExceptionRes(parsedOutput, context);
    case "InvalidLambdaResponseException":
    case "com.amazonaws.cognitoidentityprovider#InvalidLambdaResponseException":
      throw await de_InvalidLambdaResponseExceptionRes(parsedOutput, context);
    case "LimitExceededException":
    case "com.amazonaws.cognitoidentityprovider#LimitExceededException":
      throw await de_LimitExceededExceptionRes(parsedOutput, context);
    case "TooManyFailedAttemptsException":
    case "com.amazonaws.cognitoidentityprovider#TooManyFailedAttemptsException":
      throw await de_TooManyFailedAttemptsExceptionRes(parsedOutput, context);
    case "UnexpectedLambdaException":
    case "com.amazonaws.cognitoidentityprovider#UnexpectedLambdaException":
      throw await de_UnexpectedLambdaExceptionRes(parsedOutput, context);
    case "UserLambdaValidationException":
    case "com.amazonaws.cognitoidentityprovider#UserLambdaValidationException":
      throw await de_UserLambdaValidationExceptionRes(parsedOutput, context);
    case "CodeDeliveryFailureException":
    case "com.amazonaws.cognitoidentityprovider#CodeDeliveryFailureException":
      throw await de_CodeDeliveryFailureExceptionRes(parsedOutput, context);
    case "InvalidPasswordException":
    case "com.amazonaws.cognitoidentityprovider#InvalidPasswordException":
      throw await de_InvalidPasswordExceptionRes(parsedOutput, context);
    case "InvalidSmsRoleAccessPolicyException":
    case "com.amazonaws.cognitoidentityprovider#InvalidSmsRoleAccessPolicyException":
      throw await de_InvalidSmsRoleAccessPolicyExceptionRes(parsedOutput, context);
    case "InvalidSmsRoleTrustRelationshipException":
    case "com.amazonaws.cognitoidentityprovider#InvalidSmsRoleTrustRelationshipException":
      throw await de_InvalidSmsRoleTrustRelationshipExceptionRes(parsedOutput, context);
    case "PreconditionNotMetException":
    case "com.amazonaws.cognitoidentityprovider#PreconditionNotMetException":
      throw await de_PreconditionNotMetExceptionRes(parsedOutput, context);
    case "UnsupportedUserStateException":
    case "com.amazonaws.cognitoidentityprovider#UnsupportedUserStateException":
      throw await de_UnsupportedUserStateExceptionRes(parsedOutput, context);
    case "UsernameExistsException":
    case "com.amazonaws.cognitoidentityprovider#UsernameExistsException":
      throw await de_UsernameExistsExceptionRes(parsedOutput, context);
    case "AliasExistsException":
    case "com.amazonaws.cognitoidentityprovider#AliasExistsException":
      throw await de_AliasExistsExceptionRes(parsedOutput, context);
    case "InvalidUserPoolConfigurationException":
    case "com.amazonaws.cognitoidentityprovider#InvalidUserPoolConfigurationException":
      throw await de_InvalidUserPoolConfigurationExceptionRes(parsedOutput, context);
    case "MFAMethodNotFoundException":
    case "com.amazonaws.cognitoidentityprovider#MFAMethodNotFoundException":
      throw await de_MFAMethodNotFoundExceptionRes(parsedOutput, context);
    case "PasswordResetRequiredException":
    case "com.amazonaws.cognitoidentityprovider#PasswordResetRequiredException":
      throw await de_PasswordResetRequiredExceptionRes(parsedOutput, context);
    case "UserNotConfirmedException":
    case "com.amazonaws.cognitoidentityprovider#UserNotConfirmedException":
      throw await de_UserNotConfirmedExceptionRes(parsedOutput, context);
    case "UserPoolAddOnNotEnabledException":
    case "com.amazonaws.cognitoidentityprovider#UserPoolAddOnNotEnabledException":
      throw await de_UserPoolAddOnNotEnabledExceptionRes(parsedOutput, context);
    case "InvalidEmailRoleAccessPolicyException":
    case "com.amazonaws.cognitoidentityprovider#InvalidEmailRoleAccessPolicyException":
      throw await de_InvalidEmailRoleAccessPolicyExceptionRes(parsedOutput, context);
    case "CodeMismatchException":
    case "com.amazonaws.cognitoidentityprovider#CodeMismatchException":
      throw await de_CodeMismatchExceptionRes(parsedOutput, context);
    case "ExpiredCodeException":
    case "com.amazonaws.cognitoidentityprovider#ExpiredCodeException":
      throw await de_ExpiredCodeExceptionRes(parsedOutput, context);
    case "SoftwareTokenMFANotFoundException":
    case "com.amazonaws.cognitoidentityprovider#SoftwareTokenMFANotFoundException":
      throw await de_SoftwareTokenMFANotFoundExceptionRes(parsedOutput, context);
    case "ConcurrentModificationException":
    case "com.amazonaws.cognitoidentityprovider#ConcurrentModificationException":
      throw await de_ConcurrentModificationExceptionRes(parsedOutput, context);
    case "ForbiddenException":
    case "com.amazonaws.cognitoidentityprovider#ForbiddenException":
      throw await de_ForbiddenExceptionRes(parsedOutput, context);
    case "GroupExistsException":
    case "com.amazonaws.cognitoidentityprovider#GroupExistsException":
      throw await de_GroupExistsExceptionRes(parsedOutput, context);
    case "DuplicateProviderException":
    case "com.amazonaws.cognitoidentityprovider#DuplicateProviderException":
      throw await de_DuplicateProviderExceptionRes(parsedOutput, context);
    case "UserPoolTaggingException":
    case "com.amazonaws.cognitoidentityprovider#UserPoolTaggingException":
      throw await de_UserPoolTaggingExceptionRes(parsedOutput, context);
    case "InvalidOAuthFlowException":
    case "com.amazonaws.cognitoidentityprovider#InvalidOAuthFlowException":
      throw await de_InvalidOAuthFlowExceptionRes(parsedOutput, context);
    case "ScopeDoesNotExistException":
    case "com.amazonaws.cognitoidentityprovider#ScopeDoesNotExistException":
      throw await de_ScopeDoesNotExistExceptionRes(parsedOutput, context);
    case "UnsupportedIdentityProviderException":
    case "com.amazonaws.cognitoidentityprovider#UnsupportedIdentityProviderException":
      throw await de_UnsupportedIdentityProviderExceptionRes(parsedOutput, context);
    case "UnauthorizedException":
    case "com.amazonaws.cognitoidentityprovider#UnauthorizedException":
      throw await de_UnauthorizedExceptionRes(parsedOutput, context);
    case "UnsupportedOperationException":
    case "com.amazonaws.cognitoidentityprovider#UnsupportedOperationException":
      throw await de_UnsupportedOperationExceptionRes(parsedOutput, context);
    case "UnsupportedTokenTypeException":
    case "com.amazonaws.cognitoidentityprovider#UnsupportedTokenTypeException":
      throw await de_UnsupportedTokenTypeExceptionRes(parsedOutput, context);
    case "EnableSoftwareTokenMFAException":
    case "com.amazonaws.cognitoidentityprovider#EnableSoftwareTokenMFAException":
      throw await de_EnableSoftwareTokenMFAExceptionRes(parsedOutput, context);
    default:
      const parsedBody = parsedOutput.body;
      return throwDefaultError({
        output,
        parsedBody,
        errorCode
      });
  }
};
const de_AliasExistsExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new AliasExistsException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_CodeDeliveryFailureExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new CodeDeliveryFailureException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_CodeMismatchExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new CodeMismatchException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_ConcurrentModificationExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new ConcurrentModificationException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_DuplicateProviderExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new DuplicateProviderException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_EnableSoftwareTokenMFAExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new EnableSoftwareTokenMFAException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_ExpiredCodeExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new ExpiredCodeException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_ForbiddenExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new ForbiddenException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_GroupExistsExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new GroupExistsException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_InternalErrorExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new InternalErrorException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_InvalidEmailRoleAccessPolicyExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new InvalidEmailRoleAccessPolicyException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_InvalidLambdaResponseExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new InvalidLambdaResponseException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_InvalidOAuthFlowExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new InvalidOAuthFlowException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_InvalidParameterExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new InvalidParameterException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_InvalidPasswordExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new InvalidPasswordException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_InvalidSmsRoleAccessPolicyExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new InvalidSmsRoleAccessPolicyException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_InvalidSmsRoleTrustRelationshipExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new InvalidSmsRoleTrustRelationshipException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_InvalidUserPoolConfigurationExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new InvalidUserPoolConfigurationException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_LimitExceededExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new LimitExceededException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_MFAMethodNotFoundExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new MFAMethodNotFoundException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_NotAuthorizedExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new NotAuthorizedException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_PasswordResetRequiredExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new PasswordResetRequiredException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_PreconditionNotMetExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new PreconditionNotMetException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_ResourceNotFoundExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new ResourceNotFoundException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_ScopeDoesNotExistExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new ScopeDoesNotExistException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_SoftwareTokenMFANotFoundExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new SoftwareTokenMFANotFoundException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_TooManyFailedAttemptsExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new TooManyFailedAttemptsException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_TooManyRequestsExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new TooManyRequestsException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_UnauthorizedExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new UnauthorizedException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_UnexpectedLambdaExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new UnexpectedLambdaException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_UnsupportedIdentityProviderExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new UnsupportedIdentityProviderException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_UnsupportedOperationExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new UnsupportedOperationException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_UnsupportedTokenTypeExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new UnsupportedTokenTypeException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_UnsupportedUserStateExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new UnsupportedUserStateException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_UserImportInProgressExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new UserImportInProgressException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_UserLambdaValidationExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new UserLambdaValidationException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_UsernameExistsExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new UsernameExistsException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_UserNotConfirmedExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new UserNotConfirmedException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_UserNotFoundExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new UserNotFoundException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_UserPoolAddOnNotEnabledExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new UserPoolAddOnNotEnabledException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const de_UserPoolTaggingExceptionRes = async (parsedOutput, context) => {
  const body = parsedOutput.body;
  const deserialized = _json(body);
  const exception = new UserPoolTaggingException({
    $metadata: deserializeMetadata(parsedOutput),
    ...deserialized
  });
  return __decorateServiceException(exception, body);
};
const se_SetUICustomizationRequest = (input, context) => {
  return take(input, {
    CSS: [],
    ClientId: [],
    ImageFile: context.base64Encoder,
    UserPoolId: []
  });
};
const de_AdminCreateUserResponse = (output, context) => {
  return take(output, {
    User: _ => de_UserType(_, context)
  });
};
const de_AdminGetDeviceResponse = (output, context) => {
  return take(output, {
    Device: _ => de_DeviceType(_, context)
  });
};
const de_AdminGetUserResponse = (output, context) => {
  return take(output, {
    Enabled: __expectBoolean,
    MFAOptions: _json,
    PreferredMfaSetting: __expectString,
    UserAttributes: _json,
    UserCreateDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    UserLastModifiedDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    UserMFASettingList: _json,
    UserStatus: __expectString,
    Username: __expectString
  });
};
const de_AdminListDevicesResponse = (output, context) => {
  return take(output, {
    Devices: _ => de_DeviceListType(_, context),
    PaginationToken: __expectString
  });
};
const de_AdminListGroupsForUserResponse = (output, context) => {
  return take(output, {
    Groups: _ => de_GroupListType(_, context),
    NextToken: __expectString
  });
};
const de_AdminListUserAuthEventsResponse = (output, context) => {
  return take(output, {
    AuthEvents: _ => de_AuthEventsType(_, context),
    NextToken: __expectString
  });
};
const de_AuthEventsType = (output, context) => {
  const retVal = (output || []).filter(e => e != null).map(entry => {
    return de_AuthEventType(entry, context);
  });
  return retVal;
};
const de_AuthEventType = (output, context) => {
  return take(output, {
    ChallengeResponses: _json,
    CreationDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    EventContextData: _json,
    EventFeedback: _ => de_EventFeedbackType(_, context),
    EventId: __expectString,
    EventResponse: __expectString,
    EventRisk: _json,
    EventType: __expectString
  });
};
const de_CreateGroupResponse = (output, context) => {
  return take(output, {
    Group: _ => de_GroupType(_, context)
  });
};
const de_CreateIdentityProviderResponse = (output, context) => {
  return take(output, {
    IdentityProvider: _ => de_IdentityProviderType(_, context)
  });
};
const de_CreateUserImportJobResponse = (output, context) => {
  return take(output, {
    UserImportJob: _ => de_UserImportJobType(_, context)
  });
};
const de_CreateUserPoolClientResponse = (output, context) => {
  return take(output, {
    UserPoolClient: _ => de_UserPoolClientType(_, context)
  });
};
const de_CreateUserPoolResponse = (output, context) => {
  return take(output, {
    UserPool: _ => de_UserPoolType(_, context)
  });
};
const de_DescribeIdentityProviderResponse = (output, context) => {
  return take(output, {
    IdentityProvider: _ => de_IdentityProviderType(_, context)
  });
};
const de_DescribeRiskConfigurationResponse = (output, context) => {
  return take(output, {
    RiskConfiguration: _ => de_RiskConfigurationType(_, context)
  });
};
const de_DescribeUserImportJobResponse = (output, context) => {
  return take(output, {
    UserImportJob: _ => de_UserImportJobType(_, context)
  });
};
const de_DescribeUserPoolClientResponse = (output, context) => {
  return take(output, {
    UserPoolClient: _ => de_UserPoolClientType(_, context)
  });
};
const de_DescribeUserPoolResponse = (output, context) => {
  return take(output, {
    UserPool: _ => de_UserPoolType(_, context)
  });
};
const de_DeviceListType = (output, context) => {
  const retVal = (output || []).filter(e => e != null).map(entry => {
    return de_DeviceType(entry, context);
  });
  return retVal;
};
const de_DeviceType = (output, context) => {
  return take(output, {
    DeviceAttributes: _json,
    DeviceCreateDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    DeviceKey: __expectString,
    DeviceLastAuthenticatedDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    DeviceLastModifiedDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_)))
  });
};
const de_EventFeedbackType = (output, context) => {
  return take(output, {
    FeedbackDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    FeedbackValue: __expectString,
    Provider: __expectString
  });
};
const de_GetDeviceResponse = (output, context) => {
  return take(output, {
    Device: _ => de_DeviceType(_, context)
  });
};
const de_GetGroupResponse = (output, context) => {
  return take(output, {
    Group: _ => de_GroupType(_, context)
  });
};
const de_GetIdentityProviderByIdentifierResponse = (output, context) => {
  return take(output, {
    IdentityProvider: _ => de_IdentityProviderType(_, context)
  });
};
const de_GetUICustomizationResponse = (output, context) => {
  return take(output, {
    UICustomization: _ => de_UICustomizationType(_, context)
  });
};
const de_GroupListType = (output, context) => {
  const retVal = (output || []).filter(e => e != null).map(entry => {
    return de_GroupType(entry, context);
  });
  return retVal;
};
const de_GroupType = (output, context) => {
  return take(output, {
    CreationDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    Description: __expectString,
    GroupName: __expectString,
    LastModifiedDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    Precedence: __expectInt32,
    RoleArn: __expectString,
    UserPoolId: __expectString
  });
};
const de_IdentityProviderType = (output, context) => {
  return take(output, {
    AttributeMapping: _json,
    CreationDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    IdpIdentifiers: _json,
    LastModifiedDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    ProviderDetails: _json,
    ProviderName: __expectString,
    ProviderType: __expectString,
    UserPoolId: __expectString
  });
};
const de_ListDevicesResponse = (output, context) => {
  return take(output, {
    Devices: _ => de_DeviceListType(_, context),
    PaginationToken: __expectString
  });
};
const de_ListGroupsResponse = (output, context) => {
  return take(output, {
    Groups: _ => de_GroupListType(_, context),
    NextToken: __expectString
  });
};
const de_ListIdentityProvidersResponse = (output, context) => {
  return take(output, {
    NextToken: __expectString,
    Providers: _ => de_ProvidersListType(_, context)
  });
};
const de_ListUserImportJobsResponse = (output, context) => {
  return take(output, {
    PaginationToken: __expectString,
    UserImportJobs: _ => de_UserImportJobsListType(_, context)
  });
};
const de_ListUserPoolsResponse = (output, context) => {
  return take(output, {
    NextToken: __expectString,
    UserPools: _ => de_UserPoolListType(_, context)
  });
};
const de_ListUsersInGroupResponse = (output, context) => {
  return take(output, {
    NextToken: __expectString,
    Users: _ => de_UsersListType(_, context)
  });
};
const de_ListUsersResponse = (output, context) => {
  return take(output, {
    PaginationToken: __expectString,
    Users: _ => de_UsersListType(_, context)
  });
};
const de_ProviderDescription = (output, context) => {
  return take(output, {
    CreationDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    LastModifiedDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    ProviderName: __expectString,
    ProviderType: __expectString
  });
};
const de_ProvidersListType = (output, context) => {
  const retVal = (output || []).filter(e => e != null).map(entry => {
    return de_ProviderDescription(entry, context);
  });
  return retVal;
};
const de_RiskConfigurationType = (output, context) => {
  return take(output, {
    AccountTakeoverRiskConfiguration: _json,
    ClientId: __expectString,
    CompromisedCredentialsRiskConfiguration: _json,
    LastModifiedDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    RiskExceptionConfiguration: _json,
    UserPoolId: __expectString
  });
};
const de_SetRiskConfigurationResponse = (output, context) => {
  return take(output, {
    RiskConfiguration: _ => de_RiskConfigurationType(_, context)
  });
};
const de_SetUICustomizationResponse = (output, context) => {
  return take(output, {
    UICustomization: _ => de_UICustomizationType(_, context)
  });
};
const de_StartUserImportJobResponse = (output, context) => {
  return take(output, {
    UserImportJob: _ => de_UserImportJobType(_, context)
  });
};
const de_StopUserImportJobResponse = (output, context) => {
  return take(output, {
    UserImportJob: _ => de_UserImportJobType(_, context)
  });
};
const de_UICustomizationType = (output, context) => {
  return take(output, {
    CSS: __expectString,
    CSSVersion: __expectString,
    ClientId: __expectString,
    CreationDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    ImageUrl: __expectString,
    LastModifiedDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    UserPoolId: __expectString
  });
};
const de_UpdateGroupResponse = (output, context) => {
  return take(output, {
    Group: _ => de_GroupType(_, context)
  });
};
const de_UpdateIdentityProviderResponse = (output, context) => {
  return take(output, {
    IdentityProvider: _ => de_IdentityProviderType(_, context)
  });
};
const de_UpdateUserPoolClientResponse = (output, context) => {
  return take(output, {
    UserPoolClient: _ => de_UserPoolClientType(_, context)
  });
};
const de_UserImportJobsListType = (output, context) => {
  const retVal = (output || []).filter(e => e != null).map(entry => {
    return de_UserImportJobType(entry, context);
  });
  return retVal;
};
const de_UserImportJobType = (output, context) => {
  return take(output, {
    CloudWatchLogsRoleArn: __expectString,
    CompletionDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    CompletionMessage: __expectString,
    CreationDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    FailedUsers: __expectLong,
    ImportedUsers: __expectLong,
    JobId: __expectString,
    JobName: __expectString,
    PreSignedUrl: __expectString,
    SkippedUsers: __expectLong,
    StartDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    Status: __expectString,
    UserPoolId: __expectString
  });
};
const de_UserPoolClientType = (output, context) => {
  return take(output, {
    AccessTokenValidity: __expectInt32,
    AllowedOAuthFlows: _json,
    AllowedOAuthFlowsUserPoolClient: __expectBoolean,
    AllowedOAuthScopes: _json,
    AnalyticsConfiguration: _json,
    AuthSessionValidity: __expectInt32,
    CallbackURLs: _json,
    ClientId: __expectString,
    ClientName: __expectString,
    ClientSecret: __expectString,
    CreationDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    DefaultRedirectURI: __expectString,
    EnablePropagateAdditionalUserContextData: __expectBoolean,
    EnableTokenRevocation: __expectBoolean,
    ExplicitAuthFlows: _json,
    IdTokenValidity: __expectInt32,
    LastModifiedDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    LogoutURLs: _json,
    PreventUserExistenceErrors: __expectString,
    ReadAttributes: _json,
    RefreshTokenValidity: __expectInt32,
    SupportedIdentityProviders: _json,
    TokenValidityUnits: _json,
    UserPoolId: __expectString,
    WriteAttributes: _json
  });
};
const de_UserPoolDescriptionType = (output, context) => {
  return take(output, {
    CreationDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    Id: __expectString,
    LambdaConfig: _json,
    LastModifiedDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    Name: __expectString,
    Status: __expectString
  });
};
const de_UserPoolListType = (output, context) => {
  const retVal = (output || []).filter(e => e != null).map(entry => {
    return de_UserPoolDescriptionType(entry, context);
  });
  return retVal;
};
const de_UserPoolType = (output, context) => {
  return take(output, {
    AccountRecoverySetting: _json,
    AdminCreateUserConfig: _json,
    AliasAttributes: _json,
    Arn: __expectString,
    AutoVerifiedAttributes: _json,
    CreationDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    CustomDomain: __expectString,
    DeletionProtection: __expectString,
    DeviceConfiguration: _json,
    Domain: __expectString,
    EmailConfiguration: _json,
    EmailConfigurationFailure: __expectString,
    EmailVerificationMessage: __expectString,
    EmailVerificationSubject: __expectString,
    EstimatedNumberOfUsers: __expectInt32,
    Id: __expectString,
    LambdaConfig: _json,
    LastModifiedDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    MfaConfiguration: __expectString,
    Name: __expectString,
    Policies: _json,
    SchemaAttributes: _json,
    SmsAuthenticationMessage: __expectString,
    SmsConfiguration: _json,
    SmsConfigurationFailure: __expectString,
    SmsVerificationMessage: __expectString,
    Status: __expectString,
    UserAttributeUpdateSettings: _json,
    UserPoolAddOns: _json,
    UserPoolTags: _json,
    UsernameAttributes: _json,
    UsernameConfiguration: _json,
    VerificationMessageTemplate: _json
  });
};
const de_UsersListType = (output, context) => {
  const retVal = (output || []).filter(e => e != null).map(entry => {
    return de_UserType(entry, context);
  });
  return retVal;
};
const de_UserType = (output, context) => {
  return take(output, {
    Attributes: _json,
    Enabled: __expectBoolean,
    MFAOptions: _json,
    UserCreateDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    UserLastModifiedDate: _ => __expectNonNull(__parseEpochTimestamp(__expectNumber(_))),
    UserStatus: __expectString,
    Username: __expectString
  });
};
const deserializeMetadata = output => ({
  httpStatusCode: output.statusCode,
  requestId: output.headers["x-amzn-requestid"] ?? output.headers["x-amzn-request-id"] ?? output.headers["x-amz-request-id"],
  extendedRequestId: output.headers["x-amz-id-2"],
  cfId: output.headers["x-amz-cf-id"]
});
const collectBodyString = (streamBody, context) => collectBody(streamBody, context).then(body => context.utf8Encoder(body));
const throwDefaultError = withBaseException(__BaseException);
const buildHttpRpcRequest = async (context, headers, path, resolvedHostname, body) => {
  const {
    hostname,
    protocol = "https",
    port,
    path: basePath
  } = await context.endpoint();
  const contents = {
    protocol,
    hostname,
    port,
    method: "POST",
    path: basePath.endsWith("/") ? basePath.slice(0, -1) + path : basePath + path,
    headers
  };
  if (resolvedHostname !== undefined) {
    contents.hostname = resolvedHostname;
  }
  if (body !== undefined) {
    contents.body = body;
  }
  return new __HttpRequest(contents);
};
function sharedHeaders(operation) {
  return {
    "content-type": "application/x-amz-json-1.1",
    "x-amz-target": `AWSCognitoIdentityProviderService.${operation}`
  };
}
const parseBody = (streamBody, context) => collectBodyString(streamBody, context).then(encoded => {
  if (encoded.length) {
    return JSON.parse(encoded);
  }
  return {};
});
const parseErrorBody = async (errorBody, context) => {
  const value = await parseBody(errorBody, context);
  value.message = value.message ?? value.Message;
  return value;
};
const loadRestJsonErrorCode = (output, data) => {
  const findKey = (object, key) => Object.keys(object).find(k => k.toLowerCase() === key.toLowerCase());
  const sanitizeErrorCode = rawValue => {
    let cleanValue = rawValue;
    if (typeof cleanValue === "number") {
      cleanValue = cleanValue.toString();
    }
    if (cleanValue.indexOf(",") >= 0) {
      cleanValue = cleanValue.split(",")[0];
    }
    if (cleanValue.indexOf(":") >= 0) {
      cleanValue = cleanValue.split(":")[0];
    }
    if (cleanValue.indexOf("#") >= 0) {
      cleanValue = cleanValue.split("#")[1];
    }
    return cleanValue;
  };
  const headerKey = findKey(output.headers, "x-amzn-errortype");
  if (headerKey !== undefined) {
    return sanitizeErrorCode(output.headers[headerKey]);
  }
  if (data.code !== undefined) {
    return sanitizeErrorCode(data.code);
  }
  if (data["__type"] !== undefined) {
    return sanitizeErrorCode(data["__type"]);
  }
};
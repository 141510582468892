import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IOption } from '../interfaces/ioption';

@Injectable({
  providedIn: 'root'
})
export class ConsumerReportOptionService {

  constructor(private readonly http: HttpClient) { }

  findByType<U>(type: string):Observable<U> {
    
    return this.http.get<IOption>(environment.consumerReport.baseURL + environment.consumerReport.getOption + '/' + type)
      .pipe(
        map<IOption, U>(d=> {
          return JSON.parse(d.value) as U
        })
      )
  }
}

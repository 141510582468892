<form [formGroup]="formData">
    <div class="container">
      <mat-card>
        <mat-card-header>
          <mat-card-title class="pt-3">
            <span [hidden]="!loadedModel?.id">Update Client - {{ loadedModel?.name }}</span>
            <span [hidden]="loadedModel?.id">Add Client</span>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row">
            <mat-form-field
              class="col-12"
              appearance="fill"
            >
              <mat-label>Client Name</mat-label>
              <input
                matInput
                maxlength="125"
                placeholder="Client Name"
                formControlName="name"
              />
              <mat-error>
                <span *ngIf="formData.get('name').hasError('required')"
                  >Client Name is <strong>required</strong></span
                >
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="col-12 col-sm-6 col-lg-6"
              appearance="fill"
            >
              <mat-label>Email</mat-label>
              <input
                matInput
                placeholder="Email"
                formControlName="email"
              />
              <mat-error>
                <span *ngIf="formData.get('email').hasError('required')"
                  >Email is <strong>required</strong></span
                >
              </mat-error>
              <mat-error>
                <span *ngIf="formData.get('email').hasError('email')"
                  >Email format wrong</span
                >
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="col-12 col-sm-6 col-lg-6"
              appearance="fill"
            >
              <mat-label>Contact Number</mat-label>
              <input
                matInput
                placeholder="Email"
                formControlName="contactNumber"
              />

            </mat-form-field>
          </div>
					</mat-card-content>
          <mat-card-actions>
            <div class="row">
              <div class="col-12 text-center">
                <button
                  mat-raised-button
                  color="primary"
                  class="m-2"
                  (click)="save()"
                  [disabled]="formData.status != 'VALID' || savingForm"
                >
                  Save
                </button>
                <button
                  mat-raised-button
                  class="m-2"
                  [routerLink]="['/native-ads/client']"
                >
                  Cancel
                </button>
              </div>
            </div>
          </mat-card-actions>
      </mat-card>
    </div>
  </form>
  
import { Component, OnInit } from '@angular/core';
import { ConsumerReportSurveyConfigGroupService } from '../../services/consumer-report-survey-config-group.service';
import { IStaticReportConfigGroup } from '../../interfaces/iStaticReportConfigGroups';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { DeleteDialogComponent } from 'src/app/shared/components/delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-reports-group-index',
  templateUrl: './reports-group-index.component.html',
  styleUrl: './reports-group-index.component.scss'
})
export class ReportsGroupIndexComponent  extends ListingComponent<IStaticReportConfigGroup> implements OnInit{
  
  isLoading = false;
  displayedColumns: string[] = [
    'name',
    'countryOfOrigin',
    'reportConfigs',
    'createdDate',
    'action'
  ];

  length = 0;
  
  constructor(
    private consumerReportSurveyConfigGroup : ConsumerReportSurveyConfigGroupService,
    private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog
  
  ) {
    super();
    this.pageSize = 50  
    this.pageSizeOptions = [50, 100, 150, 200, 250, 500, 1000, 99999]

  }
  ngOnInit(): void {
    this.surveyReportConfigGroupList();
  }


  populateList<U extends HttpParams | Params>(params?: U, urlHistory?: boolean): void {
    this.surveyReportConfigGroupList();
  }

  surveyReportConfigGroupList(){
    this.isLoading = true;
    let params = {
      page: this.page,
      limit: this.pageSize
    } as unknown as HttpParams;
    this.consumerReportSurveyConfigGroup.list(params)
    .subscribe((result:any) => {
      this.isLoading = false;
      this.length = result.total;
      this.initCommonListingResponse(result);
    }, error => {
      this.isLoading = false;
    })
  }

  getExtraListingParams() {
    throw new Error('Method not implemented.');
  }
  
  deleteItem(item: IStaticReportConfigGroup): void {
    throw new Error('Method not implemented.');
  } 
  

  changePage(pageEvent: PageEvent) {
    let changePage = false;
    if (this.pageSize != pageEvent.pageSize) {
      this.pageSize = pageEvent.pageSize;
      changePage = true;
    }
    if (this.page != pageEvent.pageIndex) {
      this.page = pageEvent.pageIndex;
      changePage = true;
    }

    this.surveyReportConfigGroupList();

  }
  


  disableReportConfigGroup(id:number){

    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '450px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.consumerReportSurveyConfigGroup.delete(id)
          .subscribe(d=> {
            this.snackBar.open('Report config group has been deleted SUCCESSFULLY!', 'Close', {
              duration: 2000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
            this.surveyReportConfigGroupList();
          },error=>{
            this.snackBar.open('Failed to delete report config group.', 'Close', {
              duration: 2000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
          })
      }
    });

  }


}

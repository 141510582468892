<div class="row mb-3" [hidden]="clientID">
  <div class="col-12 text-right">
    <button mat-raised-button color="primary" [routerLink]="['/native-ads/campaign/create']">Add New
      Campaign</button>
  </div>
  <span class="hint mt-2"><b>* This page shows the compilation of all the different ads units' performance that are grouped under the <u>same campaign name</u></b></span>
</div>
<div class="row mb-3">
  <mat-form-field class="col-8" appearance="fill">
    <input type="text" placeholder="Type anything to search campaign" aria-label="Type anything to search campaign"
      matInput appearance="fill" autocomplete="off" [formControl]='searchTextFormControl'>
  </mat-form-field>
  <mat-form-field class="col-4" appearance="fill">
    <mat-label>Status</mat-label>
    <mat-select multiple  [formControl]='searchStatusFormControl'>
      <mat-option *ngFor="let status of campaignStatus$ | async" [value]="status">
        {{status.value}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div class="row">
  <div class="col-12">
    <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
      <ng-container matColumnDef="client">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Client</th>
        <td mat-cell *matCellDef="let element">
          {{ element.client.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="campaignName">
        <th mat-header-cell *matHeaderCellDef>Campaign Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="period">
        <th mat-header-cell *matHeaderCellDef>Period</th>
        <td mat-cell *matCellDef="let element">
          {{ element.startDate | date:'dd MMM yyyy hh:mm a' }} <br />To<br /> {{ element.endDate | date:'dd MMM yyyy hh:mm a' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="purchasedImpression">
        <th mat-header-cell *matHeaderCellDef>Total Booked Impressions</th>
        <td mat-cell *matCellDef="let element">
          {{ getPurchasedImpression(element.campaignItems) | number }}
        </td>
      </ng-container>

      <ng-container matColumnDef="impression">
        <th mat-header-cell *matHeaderCellDef>Total Impressions Served</th>
        <td mat-cell *matCellDef="let element">
          {{ getArchievedImpression(element.campaignItems) | number }}
        </td>
      </ng-container>

      <ng-container matColumnDef="viewableImpression">
        <th mat-header-cell *matHeaderCellDef>Total Viewable Impressions Served</th>
        <td mat-cell *matCellDef="let element">
          {{ getArchievedViewableImpression(element.campaignItems) | number }}
        </td>
      </ng-container>

      <ng-container matColumnDef="clicks">
        <th mat-header-cell *matHeaderCellDef>Total Clicks Served</th>
        <td mat-cell *matCellDef="let element">
          {{ getArchievedClick(element.campaignItems) | number }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          <native-ads-campaign-status-text [campaignStatus]="element.status"></native-ads-campaign-status-text>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element;">
          <a mat-icon-button color="primary" routerLink="/native-ads/ads-report" [queryParams]="{clientID: element.clientID, campaignName: element.name}">
            <mat-icon matTooltip="View" matTooltipPosition="left">visibility</mat-icon>
          </a>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="listingCols" class="p-2"></tr>
      <tr mat-row *matRowDef="let row; columns: listingCols;" class="p-2"></tr>
    </table>
  </div>
</div>
<div class="row mb-4">
  <div class="col-12">
    <mat-paginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" [pageIndex]="page"
      (page)="changePage($event)">
    </mat-paginator>
  </div>
</div>

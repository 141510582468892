import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { IUserRequest } from '../../interfaces/iuser-request';
import { IUserRequestQuery } from '../../interfaces/iuser-request-query';
import { UserRequestService } from '../../services/user-request.service';
import { UTCDate } from 'src/app/shared/pipes/utc.datepipe';
import { ActivatedRoute, Params, Router } from '@angular/router';
import moment, { ISO_8601 } from 'moment';
import { ServiceService } from '../../services/service.service';
import { IService } from '../../interfaces/iservice';
import { filter } from 'rxjs';

@Component({
	selector: 'security-guards-user-request-index',
	templateUrl: './user-request-index.component.html',
	styleUrls: ['./user-request-index.component.scss']
})
export class UserRequestIndexComponent extends ListingComponent<IUserRequest> implements OnInit,OnChanges,AfterViewInit {

	@Input()
	userID: string;

	searchTextFilterInputControl: FormControl = new FormControl('');
	isSearching = false

	serviceList: IService[] = []
	
	populateList(params?: HttpParams | Params, urlHistory?: boolean): void {
		if(params == null) {
			const searchValue = this.searchFormGroup.value
			const serviceIDs = searchValue?.services?.slice().map(d=>d.id) || []
			delete searchValue.services
			params = this.makeSearchParams({
				...searchValue,
				startDate: moment(searchValue.startDate).toISOString(),
				endDate: moment(searchValue.endDate).toISOString(),
				serviceIDs
			}, true);
		} else {
			params = this.makeSearchParams(params);
		}		
		this.commonPopulateList(params, urlHistory);
	}
	getExtraListingParams(): void {
		throw new Error('Method not implemented.');
	}

	getURLAccess(element?: IUserRequestQuery) {
		if(!element) {
			return ".";
		}
		return element.pathAccessing.startsWith(element.domain)?element.pathAccessing: element.domain + element.pathAccessing;
	}

	constructor(
		public readonly baseService: UserRequestService,
		public readonly mainRouter: Router,
		public readonly activatedRoute: ActivatedRoute,
		private readonly serviceService: ServiceService
	) {
		super();
	}

	setUpCol() {
		this.listingCols = this.userID?[
			'createdDate',
			'domain',
			'url',
			'serviceType',
			'action'
		]:[
			'createdDate',
			'user',
			'domain',
			'url',
			'serviceType',
			'action'
		];
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes && changes['userID'].previousValue != changes['userID'].currentValue) {
			this.searchFormGroup.controls.userID.setValue(changes['userID'].currentValue);
			this.populateList(null, false);
			this.setUpCol()
		}		
	}

	ngOnInit(): void {
		const params = this.activatedRoute.snapshot.queryParams
		this.initSearchForm(params);
		this.populateList(null, false);
		this.setUpCol();
		this.serviceService.list().subscribe(d=> {
			this.serviceList = d.data
			if(params.serviceIDs) {
				this.searchFormGroup.controls.services.setValue(d.data.filter(f=>params.serviceIDs.includes(f.id)));
			}
		});

		this.searchFormGroupValueChange$
		.pipe(
			filter(d=>d)
		)
		.subscribe(searchValue => {
			this.populateList(null, true);
		})
	}
}

import { Auth } from "./auth";
import { environment } from "./environment";
import { urlShortenerServer } from "./url-shortener-server";

export const urlShortener = {
    baseURL: 'http://localhost:1490',

    commonDeletePath: '/delete',
    commonListPath: '/list',
    commonUpdatePath: '/update',
    commonCreatePath: '/create',
    commonGetPath: '/get',
    
    //UrlController 
    urlShortenerCreate: '/url/create',
    urlShortenerDelete: '/url/delete',
    urlShortenerArchived: '/url/archive',
    urlShortenerResetClicks: '/url/resetClicks',
    urlShortenerTag: '/url/list',
    urlShortenerGetUserDetails: '/url',
    urlShortenerUpdate: '/url/update',

    // TagController
    urlShortenerFilters: '/tag/filters',
    urlShortenerClientList: '/tag/clients',
    urlShortenerCampaignList: '/tag/campaigns',
    urlShortenerSearchTag: '/tag/search-tag',
    urlShortenerTagFindOrCreate: '/find-or-create',

    //Account Controller
    urlShortenerAccountList: '/account/list',
    urlShortenerAcountGetByEmail: '/account/get-by-email',

    //Site Controller
    urlShortenerGetNewHash: '/hash/getNewHash',
    
    //RequestController
    urlShortenerGetUrlDetail : '/req/getAll',
    urlShortenerGetClicks : '/req/clicks',
    urlShortenerGetExport : '/req/export',


    //Telegram Request //
    telegramGetUrls: '/url/getUrls',
    telegramGetUrlByID : '/url/id',
    telegramTagShortUrlLinksForComparison: '/url/telegramShortUrlLinksForComparison',

    tagBase: '/tag',


    roles : [
      'campaign@dailyvanity.com',
      'editorial.team@dailyvanity.com',
      'editorial.intern.sg@dailyvanity.com',
      'editorial.sg@dailyvanity.com',
      'editorial.my@dailyvanity.com',
      'creative.campaign@dailyvanity.com',
      'creative@dailyvanity.com',
      'creative.internal@dailyvanity.com', 
      'marketing@dailyvanity.com',
      'marketing.sg@dailyvanity.com',
      'marketing.my@dailyvanity.com',
      'creative.internal@dailyvanity.com',
    ].map(r=>r+"_google_workspace").concat(Auth.defaultAdminRoles),

    ...urlShortenerServer
}

import { SENSITIVE_STRING } from "@smithy/smithy-client";
import { CognitoIdentityProviderServiceException as __BaseException } from "./CognitoIdentityProviderServiceException";
import { AttributeTypeFilterSensitiveLog, RiskConfigurationTypeFilterSensitiveLog, UICustomizationTypeFilterSensitiveLog, UserPoolClientTypeFilterSensitiveLog } from "./models_0";
export class EnableSoftwareTokenMFAException extends __BaseException {
  constructor(opts) {
    super({
      name: "EnableSoftwareTokenMFAException",
      $fault: "client",
      ...opts
    });
    this.name = "EnableSoftwareTokenMFAException";
    this.$fault = "client";
    Object.setPrototypeOf(this, EnableSoftwareTokenMFAException.prototype);
  }
}
export const VerifySoftwareTokenResponseType = {
  ERROR: "ERROR",
  SUCCESS: "SUCCESS"
};
export const SetRiskConfigurationRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.ClientId && {
    ClientId: SENSITIVE_STRING
  })
});
export const SetRiskConfigurationResponseFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.RiskConfiguration && {
    RiskConfiguration: RiskConfigurationTypeFilterSensitiveLog(obj.RiskConfiguration)
  })
});
export const SetUICustomizationRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.ClientId && {
    ClientId: SENSITIVE_STRING
  })
});
export const SetUICustomizationResponseFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.UICustomization && {
    UICustomization: UICustomizationTypeFilterSensitiveLog(obj.UICustomization)
  })
});
export const SetUserMFAPreferenceRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.AccessToken && {
    AccessToken: SENSITIVE_STRING
  })
});
export const SetUserSettingsRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.AccessToken && {
    AccessToken: SENSITIVE_STRING
  })
});
export const SignUpRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.ClientId && {
    ClientId: SENSITIVE_STRING
  }),
  ...(obj.SecretHash && {
    SecretHash: SENSITIVE_STRING
  }),
  ...(obj.Username && {
    Username: SENSITIVE_STRING
  }),
  ...(obj.Password && {
    Password: SENSITIVE_STRING
  }),
  ...(obj.UserAttributes && {
    UserAttributes: obj.UserAttributes.map(item => AttributeTypeFilterSensitiveLog(item))
  }),
  ...(obj.ValidationData && {
    ValidationData: obj.ValidationData.map(item => AttributeTypeFilterSensitiveLog(item))
  }),
  ...(obj.UserContextData && {
    UserContextData: SENSITIVE_STRING
  })
});
export const UpdateAuthEventFeedbackRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.Username && {
    Username: SENSITIVE_STRING
  }),
  ...(obj.FeedbackToken && {
    FeedbackToken: SENSITIVE_STRING
  })
});
export const UpdateDeviceStatusRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.AccessToken && {
    AccessToken: SENSITIVE_STRING
  })
});
export const UpdateUserAttributesRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.UserAttributes && {
    UserAttributes: obj.UserAttributes.map(item => AttributeTypeFilterSensitiveLog(item))
  }),
  ...(obj.AccessToken && {
    AccessToken: SENSITIVE_STRING
  })
});
export const UpdateUserPoolClientRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.ClientId && {
    ClientId: SENSITIVE_STRING
  })
});
export const UpdateUserPoolClientResponseFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.UserPoolClient && {
    UserPoolClient: UserPoolClientTypeFilterSensitiveLog(obj.UserPoolClient)
  })
});
export const VerifySoftwareTokenRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.AccessToken && {
    AccessToken: SENSITIVE_STRING
  }),
  ...(obj.Session && {
    Session: SENSITIVE_STRING
  }),
  ...(obj.UserCode && {
    UserCode: SENSITIVE_STRING
  })
});
export const VerifySoftwareTokenResponseFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.Session && {
    Session: SENSITIVE_STRING
  })
});
export const VerifyUserAttributeRequestFilterSensitiveLog = obj => ({
  ...obj,
  ...(obj.AccessToken && {
    AccessToken: SENSITIVE_STRING
  })
});
import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, combineLatest, filter, forkJoin, map, mergeMap, Observable, tap } from 'rxjs';
import { FormComponent } from 'src/app/shared/abstracts/form-component';
import { IRole } from '../../interfaces/iroles';
import { IUser } from '../../interfaces/iuser';
import { IUserRoleSelection } from '../../interfaces/iuser-role-selection';
import { RoleService } from '../../services/role.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'security-guards-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent extends FormComponent<IUser, UserService> implements OnInit {
  
  save(): void {
    const value = this.formData.value
    value.roles = this.userRoles;
    if(this.formData.valid) {
      this.baseService.save(value, this.loadedModel.id)
        .pipe(tap(d=>this.savingForm = true))
        .subscribe(d=> {
          this.loadedModel = d
          this.initForm()
          this.savingForm = false;
          this.snackBar.open('User information saved', 'Close', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
        })
    }

  }
  roleSelection: SelectionModel<number> = new SelectionModel(true, [], true);
  roleSelectionChange$: BehaviorSubject<number[]> = new BehaviorSubject([]);
  userRoles: IRole[] = new Array<IRole>();
  roleList$ = combineLatest([
    this.roleService.getRoleSelection(),
    this.roleSelectionChange$.asObservable()
  ]).pipe(
    tap(([roleData, roleselectedID]) => {
      if(roleData.length>0 && roleselectedID != null) {
        const selectedRoles = roleData.filter(r=>roleselectedID.findIndex(id=>id===r.id)>=0)
        this.userRoles = selectedRoles
      }
    }),
    
    map(([roleData, roleSelected])=> roleData.slice().sort((a, b) => {
    const aSelected = roleSelected.findIndex(id=>id === a.id) >=0
    const bSelected = roleSelected.findIndex(id=>id === b.id) >=0

    if(aSelected && !bSelected) 
      return -1; 
    else if(!aSelected && bSelected) 
      return 1;
    return a.label.localeCompare(b.label)
  })))
  constructor(
    public readonly baseService: UserService,
    private readonly roleService: RoleService,
    private readonly snackBar: MatSnackBar
  ) {
    super();
  }

  getRoles() {
    return this.formData.get("roles") as FormArray
  }

  hasRole(roleID) {
    const userRoles = this.getRoles().value
    return userRoles.findIndex(ur=>ur.id === roleID)>=0
  }

  toggleRole(role) {
    this.roleSelection.toggle(role)
    
    // const selectedRole = this.roleSelection.selected.filter(r=>this.roleSelection.isSelected(r))
    // console.log(selectedRole)
    // this.roleSelectionChange$.next(selectedRole)
  }

  ngOnInit(): void {
    this.initForm();
    this._loadedModel$
      .pipe(filter(l=>l != null))
      .subscribe(loadedModel=> {
        this.roleSelection.select(...loadedModel.roles.map(r=>r.id))
        this.userRoles = loadedModel.roles
      })
    this.roleSelection.changed.subscribe(r=>{
      this.roleSelectionChange$.next(r.source.selected)
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListingComponent } from '../../abstracts/listing-component';
import { IOption } from '../../interfaces/ioption';
import { IOptionCountryList } from '../../interfaces/ioption-country-list';
import { IOptionSurveyCountry } from '../../interfaces/ioption-survey-country';
import { IStore } from '../../interfaces/istore';
import { ConsumerReportOptionService } from '../../services/consumer-report-option.service';
import { ConsumerReportStoreService } from '../../services/consumer-report-store.service';

@Component({
  selector: 'app-store-index',
  templateUrl: './store-index.component.html',
  styleUrls: ['./store-index.component.scss']
})
export class StoreIndexComponent extends ListingComponent<IStore> implements OnInit {

  searchForm: FormGroup
  slists: IStore[]
  

  constructor(private readonly consumerReportStoreService: ConsumerReportStoreService, 
    private readonly consumerReportOptionService: ConsumerReportOptionService,
    private readonly fb: FormBuilder
  ) {
    super();
    this.listingCols = [
      'name',      
      'country',
      'surveyCountry',
      'makeup',
      'haircare',
      'skincare',
      'action'
    ];
    
  }

  catagoryList = [
    {name: "None", id: 1},
    {name: "SkinCare", id: 1},
    {name: "HairCare", id: 2},
    {name: "MakeUp", id: 3}
  ]

  surveyServingCountry = [
    {name: "SG", id: 1},
    {name: "MY", id: 2}
  ]

  initSearchForm() {
    this.searchForm = this.fb.group({
      searchText: [""],
      catagory: [[]],
      servingCountry: [[]],
      country: [[]]
    })
  }

  surveyCountry: Observable<IOptionSurveyCountry[]> = this.consumerReportOptionService.findByType('surveyCountry')

  countryList: Observable<IOptionCountryList[]> = this.consumerReportOptionService.findByType('countryList')

  ngOnInit(): void {
    this.populateList()
    this.initSearchForm()
  }

  populateList<U>(params?: U): void {
    const HttpParams = this.makeSearchParams(params)
    this.consumerReportStoreService.list(HttpParams)
      .subscribe(r => {
        this.lists = r.data
        this.total = r.total
      })
  }
  getExtraListingParams() {
    throw new Error('Method not implemented.');
  }

  deleteItem(item: IStore): void {
    this.consumerReportStoreService.delete(item.id)
      .subscribe(r=> {
        this.populateList()
      })
  }

  search() {
    const searchValue = this.searchForm.value
    this.populateList({
      searchText: searchValue['searchText'],
      catagory: searchValue['catagory']?.map(s=>s.name),
      servingCountry: searchValue['servingCountry']?.map(s=>s.name),
      country: searchValue['country']?.map(s=>s.country)
    })
  }

}

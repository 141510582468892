import { Component, Input, OnInit } from '@angular/core';
import { IMasterData } from '../../interfaces/master-data';

@Component({
  selector: 'native-ads-campaign-status-text',
  templateUrl: './campaign-status-text.component.html',
  styleUrls: ['./campaign-status-text.component.scss']
})
export class CampaignStatusTextComponent implements OnInit {

  @Input('campaignStatus')
  campaignStatus: IMasterData

  statusColour(identifier: string) {
    return {
      'active': 'green',
      'stop' : 'red',
      'runningTest': 'green',
      'completed': 'black',
      'scheduled': 'grey'
    }[identifier] || 'grey'
  }

  constructor() { }

  ngOnInit(): void {
  }

}

<div>
  <h1 mat-dialog-title>{{data.title}}</h1>
  <div mat-dialog-content>
    {{data.message}}
  </div>
  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true">Confirm</button>
    <button mat-button [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
  </div>
</div>

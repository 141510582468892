import { Injectable } from '@angular/core';
import { MatDrawerContent } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root'
})
export class DarvisService {

  public DrawerContentContainer: MatDrawerContent

  constructor() { }
}

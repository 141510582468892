import { HttpParams } from "@angular/common/http"

type paramsType = {
  searchText?: string,
  page?: number,
  limit?: number
} | {[param: string]: string | ReadonlyArray<string>;}

export const MakeSearchParams = (params: paramsType): HttpParams => {
  let paramObj: {[param: string]: string | ReadonlyArray<string>;} = {}
  if(params)
    Object.keys(params).map(k=> {
      if(params[k]) {
        if(Array.isArray(params[k]))
          paramObj[k+"[]"] = params[k]
        else {
          paramObj[k] = params[k]
        }
      }
    })
  paramObj = {
    searchText: params.searchText?params.searchText:'',
    ...paramObj,
    page: params.page?params.page.toString(): '0',
    limit: params.limit?params.limit.toString(): '-1'
  }
  if(!paramObj['searchText']) {
    delete paramObj['searchText']
  }
  return new HttpParams({
    fromObject: paramObj
  })
}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { CampaignStatusEnum } from '../../enums/campaign-status';
import { ICampaign } from '../../interfaces/campaign';
import { ICampaignItem } from '../../interfaces/campaign-item';
import { NativeAdsCampaignService } from '../../services/native-ads-campaign.service';

@Component({
  selector: 'native-ads-campaign-action',
  templateUrl: './campaign-action.component.html',
  styleUrls: ['./campaign-action.component.scss']
})
export class CampaignActionComponent {

  @Input()
  refreshList: (searchText: string, page: number) => void

  @Output() 
  needRefresh: EventEmitter<number> = new EventEmitter();

  @Input()
  page: number = 0

  @Input()
  campaignModel: ICampaign

  loading: boolean = false

  showView(statusID) {
    return [CampaignStatusEnum.COMPLETED, CampaignStatusEnum.DELETE].find(s=>s === statusID)
  }
  showEdit(statusID) {
    return [CampaignStatusEnum.ACTIVE, CampaignStatusEnum.STOP, CampaignStatusEnum.SCHEDULE, CampaignStatusEnum.DRAFT, CampaignStatusEnum.RUNNINGTEST].find(s=>s === statusID)
  }

  showStop(statusID) {
    return [CampaignStatusEnum.ACTIVE, CampaignStatusEnum.RUNNINGTEST].find(s=>s === statusID)
  }

  showPlay(statusID) {
    return [CampaignStatusEnum.STOP].find(s=>s === statusID)
  }

  showDelete(statusID) {
    return [CampaignStatusEnum.DRAFT].find(s=>s === statusID)
  }

  stopCampaign(model: ICampaign) {
    this.loading = true
    return this.campaignService.updateStatus(model, CampaignStatusEnum.STOP).subscribe(d=>{
      this.loading = false
      this.needRefresh.emit(this.page)
    })
  }

  playCampaign(model: ICampaign) {
    this.loading = true
    return this.campaignService.updateStatus(model, CampaignStatusEnum.ACTIVE).subscribe(d=>{
      this.loading = false
      this.needRefresh.emit(this.page)
    })
  }

  deleteCampaign(model: ICampaign) {

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Deleting Campaign',
        message: 'Are you sure you want to delete this campaign : ' + model.name
      }
    });
    this.loading = true
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.campaignService.updateStatus(model, CampaignStatusEnum.DELETE)
          .subscribe(d=>{
            this.loading = false
            this.needRefresh.emit(this.page)            
          })
      }
    });
    
  }


  constructor(
    private readonly campaignService: NativeAdsCampaignService,
    private readonly dialog: MatDialog
  ) { }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '../auth/auth-guard.service';
import { AdsReportComponent } from './pages/ads-report/ads-report.component';
import { CampaignCreateComponent } from './pages/campaign-create/campaign-create.component';
import { CampaignIndexComponent } from './pages/campaign-index/campaign-index.component';
import { CampaignUpdateComponent } from './pages/campaign-update/campaign-update.component';
import { ClientCreateComponent } from './pages/client-create/client-create.component';
import { ClientIndexComponent } from './pages/client-index/client-index.component';
import { ClientUpdateComponent } from './pages/client-update/client-update.component';
import { PublisherCreateComponent } from './pages/publisher-create/publisher-create.component';
import { PublisherIndexComponent } from './pages/publisher-index/publisher-index.component';
import { PublisherUpdateComponent } from './pages/publisher-update/publisher-update.component';


export const NativeAdsRoute: Routes  = [
  {path: 'campaign', component: CampaignIndexComponent},
  {path: 'campaign/create', component: CampaignCreateComponent},
  {path: 'campaign/update/:id', component: CampaignUpdateComponent},
  {path: 'publisher', component: PublisherIndexComponent},
  {path: 'publisher/create', component: PublisherCreateComponent},
  {path: 'publisher/update/:id', component: PublisherUpdateComponent},
  {path: 'client', component: ClientIndexComponent},
  {path: 'client/create', component: ClientCreateComponent},
  {path: 'client/update/:id', component: ClientUpdateComponent},
  {path: 'ads-report', component: AdsReportComponent},
];

@NgModule({
  imports: [
    RouterModule.forChild(NativeAdsRoute)
  ],
  exports: [RouterModule]
})
export class NativeAdsRouteModule { }

<h1><b> Telegram - Notifications </b></h1>
<div>
    <div class="chart" id="chart">
        <div class="row">
            <div class="col-5">
                <mat-form-field appearance="fill" class="period-field">
                    <mat-label>Period</mat-label>
                    <mat-date-range-input [formGroup]="range" [rangePicker]="pickerRange">
                        <input matStartDate formControlName="startRange" placeholder="Start date">
                        <input matEndDate formControlName="endRange" placeholder="End date" (dateChange)="onFetchData()">
                    </mat-date-range-input>
                    <mat-hint>DD MMM YYYY – DD MMM YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="pickerRange"></mat-datepicker-toggle>
                    <mat-date-range-picker #pickerRange></mat-date-range-picker>

                    <mat-error *ngIf="range.controls.startRange.hasError('matStartDateInvalid')">Invalid start
                        date</mat-error>
                    <mat-error *ngIf="range.controls.endRange.hasError('matEndDateInvalid')">Invalid end
                        date</mat-error>
                </mat-form-field>


            </div>
            <div class="col-5 text-left">

                <mat-button-toggle-group *ngIf="checkToDisplayRange()" [value]="selectedRange"
                    (change)="onChangeRange($event)" class="btn-toggle-byweek-bymonth" name="fontStyle"
                    aria-label="Font Style">
                    <mat-button-toggle [value]="toggleEnumRange.Daily">Daily</mat-button-toggle>
                    <mat-button-toggle [value]="toggleEnumRange.Weekly">Weekly</mat-button-toggle>
                    <mat-button-toggle [value]="toggleEnumRange.Monthly">Monthly</mat-button-toggle>
                </mat-button-toggle-group>
            </div>

        </div>
        <div class="row" style="display: block;" class="mb-3" *ngIf="!generatingChart">
            <canvas baseChart class="canvas" height="600" [type]="'line'" *ngIf="lineChartData.labels.length > 0" [data]="lineChartData"
                [options]="lineChartOptions" [legend]="lineChartLegend">
            </canvas>
        </div>

        <div class="row text-center mb-4" *ngIf="generatingChart">
            <div class="loading-container">
                <mat-spinner diameter="50" color="accent"> </mat-spinner>
            </div>
            <br>
            <mat-label>Generating Chart ...</mat-label>
        </div>
    </div>
    <br>
    <div class="row mb-3">
        <div class="col-4">
            <mat-card>
                <div style="text-align:center;">
                    <mat-card-header>
                        <mat-card-title>Muted</mat-card-title>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    
                    <mat-card-content>
                        <p class="count-number"><b>{{totalMutedRange | number}}</b></p>
                    </mat-card-content>
                </div>

            </mat-card>
        </div>
        <div class="col-4">

            <mat-card>
                <div style="text-align:center;">
                    <mat-card-header>
                        <mat-card-title>Unmuted</mat-card-title>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    
                    <mat-card-content>
                        <p class="count-number"><b>{{totalUnmutedRange | number}}</b></p>
                    </mat-card-content>
                </div>

            </mat-card>

        </div>
        <div class="col-4">

            <mat-card>
                <div style="text-align:center;">
                    <mat-card-header>
                        <mat-card-title>Net</mat-card-title>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    
                    <mat-card-content>
                        <p class="count-number"><b>{{totalMutedRange - totalUnmutedRange | number}}</b></p>
                    </mat-card-content>
                </div>

            </mat-card>

        </div>
    </div>

    <div class="row mb-3">
        <div class="col-12">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Top 5 best "Muted" days</mat-card-title>
                </mat-card-header>
                <mat-divider></mat-divider>
                    
                <mat-card-content>
                    <mat-card-content>
                        <div class="row mt-4 mb-2">
                            <div class="col-2">
                                <span class="circle">1</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalMutedDateRange[0])" >  {{totalMutedDateRange[0]}} </a></b></span>
                            </div>
                            <div class="col-2">
                                <span class="circle">2</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalMutedDateRange[1])" >  {{totalMutedDateRange[1]}} </a></b></span>
                            </div>
                            <div class="col-2">
                                <span class="circle">3</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalMutedDateRange[2])" >  {{totalMutedDateRange[2]}} </a></b></span>
                            </div>
                            <div class="col-2">
                                <span class="circle">4</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalMutedDateRange[3])" >  {{totalMutedDateRange[3]}} </a></b></span>
                            </div>
                            <div class="col-2">
                                <span class="circle">5</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalMutedDateRange[4])" >  {{totalMutedDateRange[4]}} </a></b></span>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card-content>

            </mat-card>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-12">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Top 5 worst "Ummuted" days</mat-card-title>
                </mat-card-header>
                <mat-divider></mat-divider>
                    
                <mat-card-content>
                    <div class="row mt-4 mb-2">
                        <div class="col-2">
                            <span class="circle">1</span>
                            <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalUnmutedDateRange[0])" >  {{totalUnmutedDateRange[0]}} </a></b></span>

                        </div>
                        <div class="col-2">
                            <span class="circle">2</span>
                            <span class="dateRange"><b>  <a class="date-column" [routerLink]="" (click)="navigateByDate(totalUnmutedDateRange[1])" >  {{totalUnmutedDateRange[1]}} </a></b></span>

                        </div>
                        <div class="col-2">
                            <span class="circle">3</span>
                            <span class="dateRange"><b>  <a class="date-column" [routerLink]="" (click)="navigateByDate(totalUnmutedDateRange[2])" >  {{totalUnmutedDateRange[2]}} </a></b></span>

                        </div>
                        <div class="col-2">
                            <span class="circle">4</span>
                            <span class="dateRange"><b>  <a class="date-column" [routerLink]="" (click)="navigateByDate(totalUnmutedDateRange[3])" >  {{totalUnmutedDateRange[3]}} </a></b></span>

                        </div>
                        <div class="col-2">
                            <span class="circle">5</span>
                            <span class="dateRange"><b>  <a class="date-column" [routerLink]="" (click)="navigateByDate(totalUnmutedDateRange[4])" >  {{totalUnmutedDateRange[4]}} </a></b></span>

                        </div>
                    </div>
                </mat-card-content>

            </mat-card>
        </div>
    </div>

    <div class="row mb-3 mr-2 ml-2">
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

            <!-- Position Column -->
            <ng-container matColumnDef="addedDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by date">
                    <b> Date </b>
                </th>
                <td mat-cell *matCellDef="let element"> <b>  <a class="date-column" [routerLink]="" (click)="navigateByDate(element.addedDate)" >  {{element.addedDate | date:"dd MMM yyyy, E"}} </a> </b> </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="totalFollowers">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by totalFollowers">
                    <b> Posts published</b>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.post | number }} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="muted">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by muted">
                    <b> Muted</b>
                </th>
                <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': highestMuted != 0 && highestMuted == element.muted  ? '#93C47D' : null }"> 
                    {{element.muted | number}}
                 </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="unmuted">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by unmuted">
                    <b> Unmuted</b>
                </th>
                <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': highestUnmuted != 0 && highestUnmuted == element.unmuted  ? '#FF0000' : null }"> 
                    {{element.unmuted | number}}
                </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="net">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by net">
                    <b> Net gain </b>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.net | number}} </td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" aria-label="Select page of users"></mat-paginator>
    </div>

</div>
<br>
<div class="comparison mt-4">
    <div class="row">
        <div class="col-5">
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Ranged From</mat-label>
                <mat-date-range-input [formGroup]="rangeFrom" [rangePicker]="pickerFrom">
                    <input matStartDate formControlName="startFrom" placeholder="Start date"
                        (dateChange)="onStartRangeFromChange($event)">
                    <input matEndDate formControlName="endFrom" placeholder="End date"
                        (dateChange)="onEndRangeFromChange($event)">
                </mat-date-range-input>
                <mat-hint>DD MMM YYYY – DD MMM YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                <mat-date-range-picker #pickerFrom></mat-date-range-picker>

                <mat-error *ngIf="rangeFrom.controls.startFrom.hasError('matStartDateInvalid')">Invalid start
                    date</mat-error>
                <mat-error *ngIf="rangeFrom.controls.endFrom.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>
        </div>
        <div class="col-2 text-center" style="margin-top: 35px;">
            <b>Compared To</b>
        </div>
        <div class="col-5">
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Range To</mat-label>
                <mat-date-range-input [formGroup]="rangeTo" [rangePicker]="pickerTo">
                    <input matStartDate formControlName="startTo" placeholder="Start date"
                        (dateChange)="onStartRangeToChange($event)">
                    <input matEndDate formControlName="endTo" placeholder="End date"
                        (dateChange)="onEndRangeToChange($event)">
                </mat-date-range-input>
                <mat-hint>DD MMM YYYY – DD MMM YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="pickerTo"></mat-datepicker-toggle>
                <mat-date-range-picker #pickerTo></mat-date-range-picker>

                <mat-error *ngIf="rangeTo.controls.startTo.hasError('matStartDateInvalid')">Invalid start
                    date</mat-error>
                <mat-error *ngIf="rangeTo.controls.endTo.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>

        </div>
    </div>
    <br>
    <div class="row mb-3">
        <div class="col-4">
            <mat-card>
                <div style="text-align:center;">
                    <mat-card-header>
                        <mat-card-title>Muted</mat-card-title>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    <mat-card-content>
                        <p class="count-number"><b>{{totalMutedFromRange | number }} vs {{totalMutedToRange | number}}</b></p>
                    
                        <div class="percentage"> 
                            <span class="percentage-positive" *ngIf="mutedComparisonPercentage > 0"> {{mutedComparisonPercentage | number}} % </span>
                            <span class="percentage-negative" *ngIf="mutedComparisonPercentage < 0" >{{mutedComparisonPercentage | number}} % </span>
                        
                        </div>
                    </mat-card-content>
                
                </div>

            </mat-card>
        </div>
        <div class="col-4">

            <mat-card>
                <div style="text-align:center;">
                    <mat-card-header>
                        <mat-card-title>Unmuted</mat-card-title>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    <mat-card-content>
                        <p class="count-number"><b>{{totalUnmutedFromRange | number}} vs {{totalUnmutedToRange | number}}</b></p>
                        <div class="percentage">
                            <span class="percentage-positive" *ngIf="unmutedComparisonPercentage > 0">{{unmutedComparisonPercentage | number}} % </span>
                            <span class="percentage-negative" *ngIf="unmutedComparisonPercentage < 0" >{{unmutedComparisonPercentage | number}} % </span>
                
                        </div>
                    </mat-card-content>
                </div>

            </mat-card>
 
        </div>
        <div class="col-4">

            <mat-card>
                <div style="text-align:center;">
                    <mat-card-header>
                        <mat-card-title>Net</mat-card-title>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    <mat-card-content>
                        <p class="count-number">
                            <b>{{totalMutedFromRange - totalUnmutedFromRange | number}} vs {{totalMutedToRange - totalUnmutedToRange | number}}</b>
                        </p>
                        <div class="percentage">
                            <span class="percentage-positive" *ngIf="netComparisonPercentage > 0" >{{netComparisonPercentage | number}} % </span>
                            <span class="percentage-negative" *ngIf="netComparisonPercentage < 0" >{{netComparisonPercentage | number}} % </span>
                        
                        </div>
                        
                    </mat-card-content>
                </div>

            </mat-card>

        </div>
    </div>

    <div class="row mb-3">
        <div class="col-12">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Top 5 best "Unmuted" days</mat-card-title>
                </mat-card-header>
                <mat-divider></mat-divider>
                    
                <br>
                <h3>Period {{comparisonFrom?.start}} to {{comparisonFrom?.end}}</h3>

                <mat-card-content>
                    <mat-card-content>
                        <div class="row mt-4 mb-2">
                            <div class="col-2">
                                <span class="circle">1</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalMutedFromDateRange[0])" >   {{totalMutedFromDateRange[0]}} </a></b></span>

                            </div>
                            <div class="col-2">
                                <span class="circle">2</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalMutedFromDateRange[1])" > {{totalMutedFromDateRange[1]}} </a> </b></span>

                            </div>
                            <div class="col-2">
                                <span class="circle">3</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalMutedFromDateRange[2])" > {{totalMutedFromDateRange[2]}} </a> </b></span>

                            </div>
                            <div class="col-2">
                                <span class="circle">4</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalMutedFromDateRange[3])" > {{totalMutedFromDateRange[3]}} </a> </b></span>


                            </div>
                            <div class="col-2">
                                <span class="circle">5</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalMutedFromDateRange[4])" > {{totalMutedFromDateRange[4]}} </a> </b></span>

                            </div>
                        </div>
                    </mat-card-content>
                </mat-card-content>

                <br>
                <h3>Period {{comparisonTo?.start}} to {{comparisonTo?.end}}</h3>

                <mat-card-content>
                    <mat-card-content>
                        <div class="row mt-4 mb-2">
                            <div class="col-2">
                                <span class="circle">1</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalMutedToDateRange[0])" > {{totalMutedToDateRange[0]}} </a></b></span>
                            </div>
                            <div class="col-2">
                                <span class="circle">2</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalMutedToDateRange[1])" >  {{totalMutedToDateRange[1]}} </a></b></span>
                            </div>
                            <div class="col-2">
                                <span class="circle">3</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalMutedToDateRange[2])" >  {{totalMutedToDateRange[2]}} </a></b></span>

                            </div>
                            <div class="col-2">
                                <span class="circle">4</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalMutedToDateRange[3])" >  {{totalMutedToDateRange[3]}} </a></b></span>

                            </div>
                            <div class="col-2">
                                <span class="circle">5</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalMutedToDateRange[4])" >  {{totalMutedToDateRange[4]}} </a></b></span>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card-content>

            </mat-card>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-12">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Top 5 worst "Muted" days</mat-card-title>
                </mat-card-header>
                <mat-divider></mat-divider>
                    
                <br>
                <h3>Period {{comparisonFrom?.start}} to {{comparisonFrom?.end}}</h3>
                <mat-card-content>
                    <div class="row mt-4 mb-2">
                        <div class="col-2">
                            <span class="circle">1</span> <span class="dateRange"><b>
                                <a class="date-column" [routerLink]="" (click)="navigateByDate(totalUnmutedFromDateRange[0])" >  {{totalUnmutedFromDateRange[0]}} </a> </b></span>

                        </div>
                        <div class="col-2">
                            <span class="circle">2</span> <span class="dateRange"><b>
                                    <a class="date-column" [routerLink]="" (click)="navigateByDate(totalUnmutedFromDateRange[1])" >  {{totalUnmutedFromDateRange[1]}} </a> </b></span>

                        </div>
                        <div class="col-2">
                            <span class="circle">3</span> <span class="dateRange"><b>
                                    <a class="date-column" [routerLink]="" (click)="navigateByDate(totalUnmutedFromDateRange[2])" >  {{totalUnmutedFromDateRange[2]}} </a> </b></span>



                        </div>
                        <div class="col-2">
                            <span class="circle">4</span> <span class="dateRange"><b>
                                    <a class="date-column" [routerLink]="" (click)="navigateByDate(totalUnmutedFromDateRange[3])" >  {{totalUnmutedFromDateRange[3]}} </a> </b></span>



                        </div>
                        <div class="col-2">
                            <span class="circle">5</span>
                            <span class="dateRange"><b> 
                                <a class="date-column" [routerLink]="" (click)="navigateByDate(totalUnmutedFromDateRange[4])" >  {{totalUnmutedFromDateRange[4]}} </a> </b></span>

                        </div>
                    </div>
                </mat-card-content>

                <br>
                <h3>Period {{comparisonTo?.start}} to {{comparisonTo?.end}}</h3>

                <mat-card-content>
                    <mat-card-content>
                        <div class="row mt-4 mb-2">
                            <div class="col-2">
                                <span class="circle">1</span>
                                <span class="dateRange"><b> 
                                    <a class="date-column" [routerLink]="" (click)="navigateByDate(totalUnmutedToDateRange[0])" >  {{totalUnmutedToDateRange[0]}} </a> </b></span>

                            </div>
                            <div class="col-2">
                                <span class="circle">2</span>
                                <span class="dateRange"><b> 
                                    <a class="date-column" [routerLink]="" (click)="navigateByDate(totalUnmutedToDateRange[1])" >  {{totalUnmutedToDateRange[1]}} </a> </b></span>

                            </div>
                            <div class="col-2">
                                <span class="circle">3</span>
                                <span class="dateRange"><b> 
                                    <a class="date-column" [routerLink]="" (click)="navigateByDate(totalUnmutedToDateRange[2])" >  {{totalUnmutedToDateRange[2]}} </a> </b></span>


                            </div>
                            <div class="col-2">
                                <span class="circle">4</span>
                                <span class="dateRange"><b> 
                                    <a class="date-column" [routerLink]="" (click)="navigateByDate(totalUnmutedToDateRange[3])" >  {{totalUnmutedToDateRange[3]}} </a> </b></span>


                            </div>
                            <div class="col-2">
                                <span class="circle">5</span>
                                <span class="dateRange"><b> 
                                    <a class="date-column" [routerLink]="" (click)="navigateByDate(totalUnmutedToDateRange[4])" >  {{totalUnmutedToDateRange[4]}} </a> </b></span>

                            </div>
                        </div>
                    </mat-card-content>
                </mat-card-content>


            </mat-card>
        </div>
    </div>

</div>
<ng-container>
	<a mat-icon-button color="primary" routerLink="/native-ads/campaign/update/{{campaignModel.id}}"
		[hidden]="!showView(campaignModel.statusID) || loading">
		<mat-icon matTooltip="View" matTooltipPosition="left">visibility</mat-icon>
	</a>
	<a mat-icon-button color="primary" routerLink="/native-ads/campaign/update/{{campaignModel.id}}"
		[hidden]="!showEdit(campaignModel.statusID) || loading">
		<mat-icon matTooltip="Edit" matTooltipPosition="left">edit</mat-icon>
	</a>
	<a mat-icon-button color="warn" (click)="stopCampaign(campaignModel)" [hidden]="!showStop(campaignModel.statusID) || loading">
		<mat-icon matTooltip="Stop" matTooltipPosition="right">stop</mat-icon>
	</a>
	<a mat-icon-button color="warn" (click)="playCampaign(campaignModel)" [hidden]="!showPlay(campaignModel.statusID) || loading">
		<mat-icon matTooltip="Play" matTooltipPosition="right">play_arrow</mat-icon>
	</a>
	<a mat-icon-button color="warn" (click)="deleteCampaign(campaignModel)" [hidden]="!showDelete(campaignModel.statusID) || loading">
		<mat-icon matTooltip="Deactivate" matTooltipPosition="right">delete</mat-icon>
	</a>
</ng-container>
<div class="row mb-3">
	<div class="col-12 text-right">
		<button mat-raised-button color="primary" [routerLink]="['/native-ads/publisher/create']">Add New Publisher</button>
	</div>
</div>
<div class="row mb-3">
	<mat-form-field class="col-8" appearance="fill">
		<input type="text" placeholder="Type anything to search publisher" aria-label="Type anything to search publisher"
			matInput appearance="fill" autocomplete="off" [formControl]='searchTextFormControl'>
	</mat-form-field>
</div>
<div class="row">
	<div class="col-12">
		<table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
			<ng-container matColumnDef="name">
				<th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
				<td mat-cell *matCellDef="let element">
					{{ element.name }}
				</td>
			</ng-container>
			<ng-container matColumnDef="period">
				<th mat-header-cell *matHeaderCellDef>Period</th>
				<td mat-cell *matCellDef="let element">
					{{ element.firstActiveMetrics | date:'dd MMM yyyy hh:mm a'}} <br />To<br /> {{ element.lastActiveMetrics | date:'dd MMM yyyy hh:mm a' }}
				</td>
			</ng-container>
			<ng-container matColumnDef="status">
				<th mat-header-cell *matHeaderCellDef>Status</th>
				<td mat-cell *matCellDef="let element">
					{{ getloadedMasterData(element.statusID)?.value }}
				</td>
			</ng-container>

			<ng-container matColumnDef="url">
				<th mat-header-cell *matHeaderCellDef>Website</th>
				<td mat-cell *matCellDef="let element">
					{{ element.url }}
				</td>
			</ng-container>


			<ng-container matColumnDef="action">
				<th mat-header-cell *matHeaderCellDef> Action </th>
				<td mat-cell *matCellDef="let element;">
					<span *ngIf="element.active<5 && element.active===2;">
						Pending
					</span>
					<span *ngIf="element.active<5 && element.active!==2;">
						<a mat-icon-button color="primary" routerLink="update/{{element.id}}">
							<mat-icon matTooltip="Edit" matTooltipPosition="left">edit</mat-icon>
						</a>
					</span>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="listingCols" class="p-2"></tr>
			<tr mat-row *matRowDef="let row; columns: listingCols;" class="p-2"></tr>
		</table>
	</div>
</div>
<div class="row mb-4">
	<div class="col-12">
		<mat-paginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
			[showFirstLastButtons]="true" [pageIndex]="page" (page)="changePage($event)">
		</mat-paginator>
	</div>
</div>

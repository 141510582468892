import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DeleteDialogComponent } from 'src/app/shared/components/delete-dialog/delete-dialog.component';
import { ExportFileDialogComponent } from 'src/app/shared/components/export-file-dialog/export-file-dialog.component';
import { WINDOW } from 'src/app/shared/providers/window.provider';
import { environment } from 'src/environments/environment';
import { ListingComponent } from '../../abstracts/listing-component';
import { IParticipantSubmission } from '../../interfaces/iparticipant-submission';
import { ConsumerReportParticipantSubmissionService } from '../../services/consumer-report-participant-submission.service';

@Component({
  selector: 'consumer-report-participant-submission-index',
  templateUrl: './participant-submission-index.component.html',
  styleUrls: ['./participant-submission-index.component.scss']
})
export class ParticipantSubmissionIndexComponent extends ListingComponent<IParticipantSubmission> implements OnInit {

  searchCtrl = new FormControl();
  searchTerms: string[] = [];
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  reloading: boolean = false;

  constructor(
    private readonly consumerReportParticipantSubmissionService: ConsumerReportParticipantSubmissionService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    @Inject(WINDOW) private window: Window
    ) {
    super();
    this.listingCols = [
      'submissionDate',
      'displayName',
      'completed',
      'score',
      'chances',
      'formName',      
      'action'
    ];
  }

  ngOnInit(): void {
    this.populateList();
  }

  calculateChances(model: IParticipantSubmission) {
    if(model.completed) {
      return 1 + (model.completedReferrerCount * 5)
    }
    return 0
  }

  populateList<U>(params?: U): void {
    let HttpParams = this.makeSearchParams(params)
    
    HttpParams = HttpParams.set('searchText', this.searchTerms.join(','))
    
    this.consumerReportParticipantSubmissionService.list(HttpParams)
      .pipe(
        switchMap(r => {
          return of(r)
        })
      )
      .subscribe(r => {
        this.lists = r.data        
        this.total = r.total
      })
  }
  getExtraListingParams() {
    throw new Error('Method not implemented.');
  }
  deleteItem(item: IParticipantSubmission): void {
    throw new Error('Method not implemented.');
  }

  reProcessSubmission(item: IParticipantSubmission) {
    this.consumerReportParticipantSubmissionService
      .forceReprocess(item.id)
      .subscribe(d=> {
        this.populateList();
      })
  }

  generateLink(item: IParticipantSubmission) {
    const newWindow = this.window.open(environment.consumerReport.surveyURL, "_blank")
    this.consumerReportParticipantSubmissionService
      .generateEditLink(item.id)
      .subscribe(d=> {
        newWindow.location = environment.consumerReport.surveyURL+'/edit-survey/'+d.data.code;
        //this.window.open(environment.consumerReport.surveyURL+'/edit-survey/'+d.data.code, "_blank");
      })
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.searchTerms.push(value.trim());
      this.searchTerms = [...new Set(this.searchTerms)];
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.searchCtrl.setValue(null);
    this.populateList();
  }

  remove(fruit: string): void {
    const index = this.searchTerms.indexOf(fruit);

    if (index >= 0) {
      this.searchTerms.splice(index, 1);
      this.populateList();
    }
  }

  delSubmission(model: IParticipantSubmission) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '450px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.consumerReportParticipantSubmissionService.delete(model.id)
          .subscribe(d=> {
            this.snackBar.open('Submission has been deleted SUCCESSFULLY!', 'Close', {
              duration: 2000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
            this.populateList();
          })
      }
    });
    
  }

  async exportExcel(){

    this.dialog.open(ExportFileDialogComponent,{
      width: '600px',
    })
  }

}

<h2 mat-dialog-title>WhatsApp Url Generator</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="formData">
    
        <div class="mb-3">
            <mat-label>Country : </mat-label>
            <mat-radio-group formControlName="country">
                <mat-radio-button value="sg">Singapore</mat-radio-button>
                <mat-radio-button value="ml">Malaysia</mat-radio-button>
            </mat-radio-group>
            <mat-error *ngIf="formErrors.country.hasError('required')">Country is required</mat-error>

        </div>
        <div class="row mb-2">
            <mat-form-field class="fullWidth" appearance="fill">
                <span matPrefix>&nbsp; +{{code}}&nbsp;</span>
                <input matInput formControlName="number" placeholder="Phone Number" required>
                <mat-error *ngIf="formErrors.number.hasError('required')">Phone Number is required</mat-error>
                <mat-hint *ngIf="formErrors.number.hasError('required')">Phone Number is required</mat-hint>
            </mat-form-field>
        </div>
       
        <mat-form-field class="fullWidth" appearance="fill">
            <mat-label>Default auto message for readers' ease</mat-label>
            <textarea rows="6" matInput formControlName="message" required></textarea>
            <mat-error *ngIf="formErrors.message.hasError('required')">Message is required</mat-error>
        </mat-form-field>

        <div class="mt-3" contenteditable="true">
                <p class="mb-3"><strong> Here's the URL that will be inserted automatically in the Long URL field </strong></p> 
                <br>
                <p class="mb-3">https://api.whatsapp.com/send?phone=<span class="code">{{code}}</span><span class="number">{{number}}</span>&text=<span>{{message}}</span></p>
                <br>
                <p><span class="code">Blue</span> = Country code </p>
                <p><span class="number">Orange</span> = Phone number </p>
        </div>

    </form>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <button mat-raised-button cdkFocusInitial color="primary" (click)="insertUrl()" [disabled]="formData.status == 'INVALID'"> Insert URL</button>
</mat-dialog-actions>


import { Component, OnInit } from '@angular/core';
import { IClient } from '../../interfaces/client';

@Component({
  selector: 'app-client-create',
  templateUrl: './client-create.component.html',
  styleUrls: ['./client-create.component.scss']
})
export class ClientCreateComponent implements OnInit {

  loadedModel: IClient = {
    id: null,
    name: "",
    email: "",
    contactNumber: ""		
  }

  constructor() { }

  ngOnInit(): void {
  }

}

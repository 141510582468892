import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormComponent } from 'src/app/shared/abstracts/form-component';
import { IService } from '../../interfaces/iservice';
import { ServiceService } from '../../services/service.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'security-guards-service-form',
  templateUrl: './service-form.component.html',
  styleUrls: ['./service-form.component.scss']
})
export class ServiceFormComponent extends FormComponent<IService, ServiceService> implements OnInit, OnChanges {

  isSaving = false;

  save(): void {
    this.isSaving = true;
    const value = this.formData.value;
    if(Object.keys(this.formErrors()).length<=0) {
      this.baseService.save(value, this.loadedModel?.id)
        .subscribe(d=> {
          this.isSaving = false;
          this.snackBar.open("Successfully save new servies.", "Close", {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          })
          this.router.navigate([`/security-guard/service`])
        },error=>{
          this.isSaving = false;
          this.snackBar.open("Failed to save new roles.", "Close", {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          })
        })
    }
  }

  constructor(
    public readonly baseService: ServiceService,
    private router: Router,
    private readonly snackBar: MatSnackBar
  
    ) { 
    super();
  }


  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.loadedModel) {
      this.initForm();
    }
  }

}

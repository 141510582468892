import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { DarvisService } from 'src/app/shared/services/darvis.service';
import { IClient } from '../../interfaces/client';
import { NativeAdsMasterDataService } from '../../services/native-ads-master-data.service';
import { NativeAdsUserService } from '../../services/native-ads-user.service';
import { Params } from '@angular/router';

@Component({
  selector: 'app-client-index',
  templateUrl: './client-index.component.html',
  styleUrls: ['./client-index.component.scss']
})
export class ClientIndexComponent extends ListingComponent<IClient> implements OnInit {
  populateList<U>(params?: HttpParams | Params): void {
    this.commonPopulateList(params)
  }
  getExtraListingParams(): void {
    throw new Error('Method not implemented.');
  }
  deleteItem(item: IClient): void {
    throw new Error('Method not implemented.');
  }

  constructor(
    public baseService: NativeAdsUserService,
    public readonly darvisService: DarvisService,    
    private readonly dialog: MatDialog
  ) {
    super();
    this.listingCols = [
      'name',
      'email',
      'contactNo',
      'createdDate',
      'campaignCount',
      'action'
    ];
  }

  removeUser(model: IClient) {

    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Deleting Client',
        message: 'Are you sure you want to delete this client : ' + model.name
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        model.active = false
        this.baseService.save(model)
          .subscribe(d=> {
            this.refreshList()
          })
      }
    });

    
  }

  ngOnInit(): void {
    this.updateDefaultParams();
    this.populateList()
  }

}

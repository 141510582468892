import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampaignIndexComponent } from './pages/campaign-index/campaign-index.component';
import { CampaignCreateComponent } from './pages/campaign-create/campaign-create.component';
import { CampaignUpdateComponent } from './pages/campaign-update/campaign-update.component';
import { SharedModule } from '../shared/shared.module';
import { NativeAdsRouteModule } from './native-ads.routes';
import { CampaignFormComponent } from './components/campaign-form/campaign-form.component';
import { PublisherCreateComponent } from './pages/publisher-create/publisher-create.component';
import { PublisherUpdateComponent } from './pages/publisher-update/publisher-update.component';
import { PublisherIndexComponent } from './pages/publisher-index/publisher-index.component';
import { PublisherFormComponent } from './components/publisher-form/publisher-form.component';
import { ClientFormComponent } from './components/client-form/client-form.component';
import { ClientIndexComponent } from './pages/client-index/client-index.component';
import { ClientCreateComponent } from './pages/client-create/client-create.component';
import { ClientUpdateComponent } from './pages/client-update/client-update.component';
import { AdsReportComponent } from './pages/ads-report/ads-report.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CampaignActionComponent } from './components/campaign-action/campaign-action.component';
import { CampaignStatusTextComponent } from './components/campaign-status-text/campaign-status-text.component';
import { PublisherIconComponent } from './components/publisher-icon/publisher-icon.component';
import { NativeAdsImageComponent } from './components/native-ads-image/native-ads-image.component';

@NgModule({
  declarations: [
    CampaignIndexComponent,
    CampaignCreateComponent,
    CampaignUpdateComponent,
    CampaignFormComponent,
    PublisherCreateComponent,
    PublisherUpdateComponent,
    PublisherIndexComponent,
    PublisherFormComponent,
    ClientFormComponent,
    ClientIndexComponent,
    ClientCreateComponent,
    ClientUpdateComponent,
    AdsReportComponent,
    CampaignActionComponent,
    CampaignStatusTextComponent,
    PublisherIconComponent,
    NativeAdsImageComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatDatepickerModule,
    MatMomentDateModule,
    NativeAdsRouteModule,  
  ],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {strict: true}},
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL', 'L','LLL', 'LLLL']
        },
        display: {
          dateInput: 'DD MMM YYYY',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY'
        }
      }
    }
  ]
})
export class NativeAdsModule { }

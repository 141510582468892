export const sortByDate = (array: any, key: string): any => {
    return array.sort(function(a, b) {
        let x = new Date(a[key]);
        let y = new Date(b[key]);

        return ((x > y) ? -1 : ((x < y) ? 0 : 1));
    });
};

export const sortList = (array: any, key: string, order: string): any => {
    return array.sort(function(a, b) {
        let x = a[key];
        let y = b[key];

        if(order === 'desc')
            return ((x > y) ? -1 : ((x < y) ? 0 : 1));
        else
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
};
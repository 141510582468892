import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import moment from 'moment';
import { IOrderVoucher } from '../../interfaces/IOrderVoucher';
import { SalonFinderOrderVoucherService } from '../../services/salon-finder-order-voucher.service';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DateFormat } from 'src/app/shared/helpers/DateFormat';

@Component({
  selector: 'salon-finder-order-voucher-extend-expiry-date-dialog',
  templateUrl: './order-voucher-extend-expiry-date-dialog.component.html',
  styleUrls: ['./order-voucher-extend-expiry-date-dialog.component.scss'],
  providers: [   
    {provide: MAT_DATE_FORMATS, useValue: DateFormat},
  ],
})
export class OrderVoucherExtendExpiryDateDialogComponent implements OnInit {

  loading = false
  minDate = new Date()

  expiryDate = new FormControl(null, [Validators.required])
  model:IOrderVoucher

  constructor(
    public dialogRef: MatDialogRef<OrderVoucherExtendExpiryDateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IOrderVoucher,
    public salonFinderOrderVoucherService: SalonFinderOrderVoucherService,
    private readonly snackBar: MatSnackBar,
  ) {
    this.expiryDate.setValue(data.expiryDate)
    this.model = data
  }

  ngOnInit(): void {
  }

  save(): void {
    if(!this.expiryDate.invalid) {
      this.loading = true;
      const now = moment()

      this.model.expiryDate = moment(this.expiryDate.value).add(now.hour(), 'hours').add(now.minute(), 'minutes').add(now.second(), 'seconds').toDate()
      this.salonFinderOrderVoucherService.extendVoucher(this.model)
        .subscribe(d=> {
          this.dialogRef.close(d)
          this.loading = false
        })
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { from, Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IAdvertiser } from '../../interfaces/IAdvertiser';

@Component({
  selector: 'app-advertiser-create',
  templateUrl: './advertiser-create.component.html',
  styleUrls: ['./advertiser-create.component.scss']
})
export class AdvertiserCreateComponent implements OnInit {

  advertiserForm: IAdvertiser = {    
    woo_id: 0,
    name: '',
    email: '',
    notificationEmail: '',
    marketingEmail: '',
    commission: 0,
    description: '',
    terms: '',
    preview: '',
    slug: '',
    __sImage: environment.salonFinder.advertiserDefaultLogo,
    active: 1,
    password: '',
    bankingEntities: [
      {
        entityName: '',
        entityEmail: '',
        bankName: '',
        bankAccount: '',
        sendToAdvertiser: true,
        outletID: 0
      }
    ]
  }

  constructor() { }

  ngOnInit(): void {
  }

}

import { AbstractControl, AsyncValidator, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { Observable, catchError, map, of } from "rxjs";
import { UrlShortenerCollectionService } from "../services/url-shortener-collection.service";


export const URLHashValidator = (urlShortenerService: UrlShortenerCollectionService, hashName, domainName, idName): AsyncValidatorFn => {
  return (control: AbstractControl) => {
    const parent = control.parent
    const value = control.value;
    const domain = parent?.get(domainName)?.value;
    const id = parent?.get(idName)?.value;
    if(value === null || value === undefined || value === '' || parent == null || domain == null) return of(null)
    return urlShortenerService.checkHash(value, domain, id)
      .pipe(
        map(res => {
          return null
        }),
        catchError(() => {
          return of({hashFail: true})
        })
      )
  }
}
  
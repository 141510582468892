import { Injectable } from '@angular/core';
import { ITag } from '../interfaces/ITag';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { HttpParams, HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ICommonObject } from 'src/app/shared/interfaces/ICommonObject';
import { ICRUDPath } from 'src/app/shared/interfaces/iCRUDPath.interface';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { environment } from 'src/environments/environment';
import { MakeSearchParams } from 'src/app/consumer-report/helpers/MakeSearchParams';

@Injectable({
  providedIn: 'root'
})
export class UrlShortenerTagService extends CRUDService<ITag> {

  searchCampaign(value: string) {
    return this.list(MakeSearchParams({searchText: value, key: 'campaign', limit: 5}))
  }

  searchClient(value: string) {
    return this.list(MakeSearchParams({searchText: value, key: 'client', limit: 5}))
  }

  list(params?: HttpParams): Observable<ICommonListingResponse<ITag[]>> {
    return this.httpClient.get<ICommonListingResponse<ITag[]>>(this.listURL, {
      params: params
    }); 
  }
  get(id: string | number): Observable<ITag> {
    throw new Error('Method not implemented.');
  }
  delete(id: string | number): Observable<ITag> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: ITag): Observable<ITag> {
    throw new Error('Method not implemented.');
  }
  create(model: ITag): Observable<ITag> {
    return this.httpClient.post<ITag>(this.createURL, model);
  }
  makeFormGroup(loadedModel?: ITag) {
    throw new Error('Method not implemented.');
  }
  makeSearchListGroup(defaultParams?: ICommonObject) {
    throw new Error('Method not implemented.');
  }

  findOrCreate(key: string, value: string) {
    return this.httpClient.post<ITag>(this.getAPIURL(environment.urlShortener.urlShortenerTagFindOrCreate), {
      key,
      value
    })
  }

  apiPath = environment.urlShortener.tagBase;
  environmentCRUDPath = environment.urlShortener;

  constructor( 
    public readonly httpClient: HttpClient,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute) 
    {
    super();
  }
}

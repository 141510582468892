<div [formGroup]="graphsInformation" [id]="panelUUID">
    <div formArrayName="graphs">
        <div class="mt-4" *ngFor="let graph of graphsInformation.get('graphs')['controls']; let iSubPanel = index"
            [formGroupName]="iSubPanel" [id]="graph.get('subID')?.value">
            <mat-card>
                <mat-card-header class="question-card-header mb-3">
                    <div>
                        <a mat-icon-button color="warning" [hidden]="iSubPanel==0" (click)="downQuestion(iSubPanel)">
                            <mat-icon>arrow_drop_up</mat-icon>
                        </a>
                        <a mat-icon-button color="warning" [hidden]="iSubPanel==panelGraphs.length-1"
                            (click)="upQuestion(iSubPanel)">
                            <mat-icon>arrow_drop_down</mat-icon>
                        </a>
                    </div>
                </mat-card-header>
                <mat-card-content>
                    <div class="row">
                        <div class="col-2">
                            <mat-form-field class="col-12" appearance="fill">
                                <mat-label>Sub title</mat-label>
                                <input type="text" matInput [formControl]="graph.get('subTitle')">
                            </mat-form-field>
                        </div>
                        <div class="col-3">
                            <mat-form-field class="col-12" appearance="fill">
                                <mat-label>Question</mat-label>
                                <input type="text" matInput [matAutocomplete]="auto"
                                    [formControl]="graph.get('question')">
                                <mat-autocomplete #auto="matAutocomplete">
                                    <mat-option *ngFor="let question of filteredOptions[iSubPanel] | async "
                                        [value]="question.label" [id]="question.uuid"
                                        (onSelectionChange)="changeSelectedQuestion($event, graph)">
                                        {{ question.label }}
                                    </mat-option>
                                </mat-autocomplete>
                                <mat-hint *ngIf="graph.get('question').status != 'VALID'">Please choose a
                                    question</mat-hint>
                                <mat-error *ngIf="graph.get('question').hasError('required')">Please choose a
                                    question</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-3">
                            <mat-form-field class="col-12" appearance="fill">
                                <mat-label>Display Type</mat-label>
                                <mat-select [formControl]="graph.get('displayType')" required
                                    aria-placeholder="Display Type">
                                    <mat-option *ngFor="let display of displayTypes" [value]="display.value"
                                        (click)="changeDisplayType(display.value)" [disabled]="display.disable">
                                        {{display.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint *ngIf="graph.get('displayType').status != 'VALID'">Please choose a display
                                    type</mat-hint>
                                <mat-error *ngIf="graph.get('displayType').hasError('required')">Please choose a
                                    display type</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-2">
                            <mat-form-field class="col-12" appearance="fill">
                                <mat-label>Display Width</mat-label>
                                <mat-select [formControl]="graph.get('displayWidth')" required
                                    aria-placeholder="Display Width">
                                    <mat-option *ngFor="let width of displayWidths" [value]="width.value"
                                        (click)="changeDisplayWidth(width.value)">
                                        {{width.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint *ngIf="graph.get('displayWidth').status != 'VALID'">Please choose a display
                                    width</mat-hint>
                                <mat-error *ngIf="graph.get('displayWidth').hasError('required')">Please choose a
                                    display width</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-2">
                            <mat-form-field class="col-12" appearance="fill">
                                <mat-label>Hint</mat-label>
                                <input type="text" matInput [formControl]="graph.get('hint')">
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row mt-4">
                        <div class="col-2">
                            <mat-checkbox [formControl]="graph.get('showInPercentage')">Show in percentage
                            </mat-checkbox>
                        </div>
                        <div class="col-2">
                            <mat-checkbox [formControl]="graph.get('showCompareQuestion')">Show compare question
                            </mat-checkbox>
                        </div>
                        <div class="col-2">
                            <mat-checkbox [formControl]="graph.get('nonFree')">Non-Free
                            </mat-checkbox>
                        </div>
                        <div class="col-2">
                            <mat-form-field class="col-12" appearance="fill"
                                *ngIf="graph.get('matrices').value.length > 0">
                                <mat-label>Compare</mat-label>
                                <mat-select [formControl]="graph.get('compare')" aria-placeholder="compare">
                                    <mat-option *ngFor="let compare of compares" [value]="compare.value">
                                        {{compare.name}}
                                    </mat-option>
                                </mat-select>

                            </mat-form-field>
                        </div>
                        <div class="col-2">
                            <mat-form-field class="col-12" appearance="fill"
                                *ngIf=" graph.get('matrices').value.length > 0">
                                <mat-label>Matrics</mat-label>
                                <mat-select [formControl]="graph.get('selectedMatrics')" aria-placeholder="compare">
                                    <mat-option *ngFor="let matric of graph.get('matrices').value"
                                        [value]="matric.uuid">
                                        {{matric.name}}
                                    </mat-option>
                                </mat-select>

                            </mat-form-field>
                        </div>
                        <div class="col-2">
                            <button class="mt-2" mat-button mat-raised-button color="primary"
                                [disabled]="graph.get('question').status == 'INVALID' || generateAnswer"
                                (click)="generateAllQuestioAnswers(graph)">Generate Answers</button>

                        </div>
                    </div>


                    <div class="row mt-4">
                        <div class="col-2">
                            <mat-form-field class="col-12" appearance="fill" *ngIf="isBrandQuestions(graph)">
                                <mat-label>Brand category</mat-label>
                                <mat-select [formControl]="selectedBrandCategory" aria-placeholder="compare">
                                    <mat-option>None</mat-option>
                                    <mat-option *ngFor="let category of brandsCategories" [value]="category">
                                        {{category.value}}
                                    </mat-option>
                                </mat-select>

                            </mat-form-field>

                        </div>
                        <div class="col-2">
                            <mat-form-field class="col-12" appearance="fill" *ngIf="isBrandQuestions(graph)">
                                <mat-label>Brand Country</mat-label>
                                <mat-select [formControl]="selectedbrandsCountry" aria-placeholder="compare">
                                    <mat-option>None</mat-option>
                                    <mat-option *ngFor="let country of brandsCountries$ | async" [value]="country">
                                        {{country.country}}
                                    </mat-option>
                                </mat-select>

                            </mat-form-field>

                        </div>
                       
                    </div>

                    <div class="row text-center mb-4" *ngIf="generateAnswer">
                        <div class="loading-container">
                            <mat-spinner diameter="50" color="accent"> </mat-spinner>
                        </div>
                        <br>
                        <mat-label>Loading ...</mat-label>
                      </div>

                    <div class=" mt-4 mb-4 table-container" *ngIf="!generateAnswer">
                        <app-reports-form-question-answers 
                            [graphID]="graph.get('id')"
                            [generalInformationForm]="generalInformationForm"
                            [displayType]="selectedDisplayType" 
                            [graphQuestionUUID]="graph.get('subID')"
                            [graphQuestionAnswers]="graph.get('subAnswers')"
                            [deletedAnswers]="graph.get('deletedAnswers')"
                            [selectedMatrics]="graph.get('selectedMatrics')" 
                            [matrices]="graph.get('matrices')"
                            [generateAnswer]="generateAnswer"
                            (renderAllAnswer)="resetGenerateAllAnswers($event)"
                            [openPanelStateAnswer]="graph.get('openedPanelAnswer')"
                            (graphQuestionAnswersChange)="onGraphQuestionAnswersChange($event)"
                            >
                            
                        </app-reports-form-question-answers>
                    </div>
                </mat-card-content>
                <mat-card-actions style="justify-content: center;">
                    <div class="button-row">
                        <button mat-button mat-raised-button color="primary"
                            (click)="addSubPanel(graph)"><mat-icon>add</mat-icon> Add</button>
                        <button mat-button mat-raised-button color="warn"
                            [disabled]="graphsInformation.get('graphs')['controls'].length == 1"
                            (click)="deleteSubPanel(iSubPanel, panelIndex,graph)"> <mat-icon>delete_outline</mat-icon>
                            Delete</button>
                    </div>
                </mat-card-actions>
            </mat-card>

        </div>
    </div>
</div>
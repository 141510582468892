import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { UpdateComponent } from 'src/app/shared/abstracts/update-component';
import { IClient } from '../../interfaces/client';
import { NativeAdsUserService } from '../../services/native-ads-user.service';

@Component({
  selector: 'salon-finder-client-update',
  templateUrl: './client-update.component.html',
  styleUrls: ['./client-update.component.scss']
})
export class ClientUpdateComponent extends UpdateComponent<IClient, NativeAdsUserService> implements OnInit {
  

  loadedModel: IClient = {
    id: null,
    name: "",
    email: "",
    contactNumber: ""		
  }

  loading = false;

  constructor(private readonly route: ActivatedRoute, public baseService: NativeAdsUserService) {
    super();
  }

  ngOnInit(): void {
    this.init(this.route);
  }

}

<div class="container-fluid">

  <div class="row text-center mb-4 mt-4" *ngIf="savingForm">
    <div class="loading-container">
      <mat-spinner diameter="50" color="accent"> </mat-spinner>
    </div>
    <br>
    <br>
    <mat-label>Processing participants submissions..</mat-label>
    <mat-label>This will take a minute.</mat-label>
  </div>
  <form [formGroup]="confirmForm" *ngIf="!savingForm">
    <div class="row">
      <div class="col-12">
        <h2 color="warm">Reminders: </h2>
        <p>This will replace your current survey result report.</p>
      </div>
      <div class="col-12">
        <mat-form-field appearance="fill" class="fullWidth">
          <mat-label>Confirm</mat-label>
          <input matInput #confirmField maxlength="120"
            placeholder='Type "Confirm" to generate new survey result report' formControlName="confirmField">
          <mat-error *ngIf="formControls.confirmField.errors">
            Value don't match <strong>Confirm</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <button mat-raised-button color="primary" class="m-2" (click)="closeForm()">Cancel</button>
        <button mat-raised-button color="warn" class="m-2" (click)="generateNewSurveyResult()"
          [disabled]="confirmForm.status != 'VALID' || savingForm">
          <span *ngIf="!savingForm">Confirm</span>
          <span *ngIf="savingForm">Loading...</span>

        </button>
      </div>
    </div>
  </form>
</div>
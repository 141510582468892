import { Component, OnInit } from '@angular/core';
import { IConsumerReportUser } from '../../interfaces/consumerReportUser.interface';

@Component({
  selector: 'app-create-account-manager',
  templateUrl: './account-manager-create.component.html',
  styleUrls: ['./account-manager-create.component.scss']
})
export class AccountManagerCreateComponent implements OnInit {

  accountManagerModel: IConsumerReportUser = {
    accountManagerID: 0,
    awsID: '',
    company: '',
    displayName: '',
    email: '',
    firstName: '',
    id: 0,
    lastName: '',
    active: true
  };

  ngOnInit(): void {

  }

}

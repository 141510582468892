import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ListingService } from '../abstracts/listing-service';
import { environment } from 'src/environments/environment';
import { IQuestion } from '../interfaces/question';
import { ICommonResponse } from '../interfaces/icommonResponse.interface';
import { IContent, IGraphQuestion, IGraphQuestionAnswer, IStaticReportConfig, ISurveyConfigRequest } from '../interfaces/iStaticReportConfig';
import { FormBuilder, Validators } from '@angular/forms';
import { ConfigDisplayTypes } from '../constant/StaticReportConfig';
import { DisplayType } from '../enums/staticReportConfig.enum';
import saveAs from 'file-saver';

@Injectable({
  providedIn: 'root'
})

export class ConsumerReportSurveyConfigService  extends ListingService<any>{
  
  
  static makeGrapGroup(graph: never): any {
    throw new Error('Method not implemented.');
  }
  save(model: any, id?: string | number) {
    throw new Error('Method not implemented.');
  }

  baseURL = environment.surveyConfig.baseURL

  constructor(
    private readonly httpClient: HttpClient,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly fb: FormBuilder,
    ) {
    super();
  }
  
  makeGraphGroup(loadedModel?: IGraphQuestion) {
    return this.fb.group({
      id:[loadedModel?.id || 0],
      order: [loadedModel?.order || 1],
      subID: [loadedModel?.subID || ''],
      subTitle: [loadedModel?.subTitle || ''],
      displayType: [loadedModel?.displayType || '',Validators.required],
      displayWidth: [loadedModel?.displayWidth || null,Validators.required],
      question: [loadedModel?.question, Validators.required],
      subAnswers: loadedModel?.subAnswers  || this.fb.array([this.makeGraphAnswerGroup()]), // Initialize an empty subItems FormArray
      showInPercentage: [loadedModel?.showInPercentage || false],
      showCompareQuestion:[loadedModel?.showCompareQuestion || false],
      nonFree:[loadedModel?.nonFree || false],
      formQuestionID:[loadedModel?.formQuestionID || 0],
      deletedAnswers: [[]],
      selectedMatrics: [[]],
      compare:[loadedModel?.compare || "is"],
      matrices:[loadedModel?.matrices || []],
      hint:[loadedModel?.hint || ""],   
      openedPanelAnswer:[false]
    });
  }

  makeGraphAnswerGroup(loadedModel?:IGraphQuestionAnswer) {
    return this.fb.group({
      customName: [loadedModel?.customName || ""],
      order: [loadedModel?.order||  1],
      questionID: [loadedModel?. questionID || ""],
      selectedAnswers: [loadedModel?.selectedAnswers || [],Validators.required],
      displayTypeConfig: [loadedModel?.displayTypeConfig|| {}],
      uuid: [loadedModel?.uuid ||  ""],
      dependencies: [loadedModel?.dependencies|| []],
      id: [loadedModel?.id || 0],
      deletedAnswers:[[]],
      selectedMatrics: [loadedModel?.selectedMatrics || ""],
      matrices:[loadedModel?.matrices || []],
      compare : [loadedModel?.compare || "is"],
      answer: [""]
    });
  }

  listConfigAnswers(params?: HttpParams){
    return this.httpClient.get<ICommonResponse<any>>(this.baseURL+environment.surveyConfig.surveyReportGraphAnswerBase+environment.surveyConfig.commonListPath,
      {params})
  }


  list(params?: HttpParams) {
    return this.httpClient.get<ICommonResponse<IStaticReportConfig>>(this.baseURL+environment.surveyConfig.surveyReportConfigBase+environment.surveyConfig.commonListPath,
      {params});
  }
  get(id: string | number) {
    return this.httpClient.get<any>(this.baseURL+environment.surveyConfig.surveyReportConfigBase+environment.surveyConfig.commonGetPath + '/' + id )
  }
  delete(id: string | number) {
    return this.httpClient.delete<any>(this.baseURL+environment.surveyConfig.surveyReportConfigBase+environment.surveyConfig.commonDeletePath, {params:{id : id}})
  }
  update(id: string | number, model: ISurveyConfigRequest) {
    return this.httpClient.post<any>(this.baseURL + environment.surveyConfig.surveyReportConfigBase+environment.surveyConfig.commonUpdatePath+'/'+id, {...model});
  }
  create(model: ISurveyConfigRequest) {
    return this.httpClient.post<any>(this.baseURL + environment.surveyConfig.surveyReportConfigBase+environment.surveyConfig.commonCreatePath+'/0', {...model});
  }

  createCombinedComparisonDisplayValue(configID:string | number){
    return this.httpClient.post<any>(this.baseURL + environment.surveyConfig.surveyReportConfigBase+environment.surveyConfig.combinedComparisonDisplayValue+'/', {id:configID});
  
  }
  

  createReportPage(model: any, reportConfigID:number) {
    model.surveyReportConfigID = reportConfigID;
    return this.httpClient.post<any>(this.baseURL + environment.surveyConfig.surveyReportPageBase+environment.surveyConfig.commonCreatePath, {...model});
  }
  
  listReportPage(params?: HttpParams) {
    return this.httpClient.get<ICommonResponse<any>>(this.baseURL+environment.surveyConfig.surveyReportPageBase+environment.surveyConfig.commonListPath,
      {params});
  }

  updateReportPage(model:any){
    return this.httpClient.post<any>(this.baseURL + environment.surveyConfig.surveyReportPageBase+environment.surveyConfig.commonUpdatePath +'/'+model.id, {...model});
  }

  createReportGraph(model:any, reportPageID:number, reportConfigID:number){
    model.reportPageID = reportPageID;
    model.surveyReportConfigID = reportConfigID;
    return this.httpClient.post<any>(this.baseURL + environment.surveyConfig.surveyReportGraphBase+environment.surveyConfig.commonCreatePath, {...model}); 
  }
  
  listReportGraphs(params?: HttpParams) {
    return this.httpClient.get<ICommonResponse<any>>(this.baseURL+environment.surveyConfig.surveyReportGraphBase+environment.surveyConfig.commonListPath,
      {params});
  }

  updateReportGraph(model:any){
    return this.httpClient.post<any>(this.baseURL + environment.surveyConfig.surveyReportGraphBase+environment.surveyConfig.commonUpdatePath +'/'+model.id, {...model}); 
  }


  createReportGraphAnswer(model:any){
    return this.httpClient.post<any>(this.baseURL + environment.surveyConfig.surveyReportGraphAnswerBase+environment.surveyConfig.commonCreatePath, {...model}); 
  }

  
  updateReportGraphAnswer(model:any){
    return this.httpClient.post<any>(this.baseURL + environment.surveyConfig.surveyReportGraphAnswerBase+environment.surveyConfig.commonUpdatePath +'/'+model.id, {...model}); 
  }




  duplicate(model: ISurveyConfigRequest) {
    return this.httpClient.post<any>(this.baseURL + environment.surveyConfig.surveyReportConfigBase+environment.surveyConfig.duplicateReportConfig, {...model});
  }
  
  gerenateSurveyReport(formUUId:string, reportConfigId:string){
    return this.httpClient.post<any>(environment.consumerReport.baseURL+ environment.consumerReport.formBase + environment.consumerReport.generateStaticSurveyReport+ '/'+ formUUId + '/'+reportConfigId,{});
  }

  questions(params?: HttpParams)  {
    return this.httpClient.get<ICommonResponse<IQuestion>>(this.baseURL+environment.surveyConfig.questionBase+environment.surveyConfig.commonListPath);
  }

  question(uuid : string){
    return this.httpClient.get<any>(this.baseURL+environment.surveyConfig.formQuestionBase+environment.surveyConfig.commonGetPath + '/' + uuid)
  }

  updateToSurveyReportConfig(model: any, id?: string | number) {
    return this.httpClient.post<any>(this.baseURL + environment.surveyConfig.surveyReportConfigBase + environment.surveyConfig.updateReportConfig +'/'+id,{...model});
  }

  setDefaultDisplayTypes(ansLength:number, controlType:string){
    
    let displayTypes = ConfigDisplayTypes;

    if(controlType !== 'rating'){
      for(let type of displayTypes){
         type.disable = false; 
      }
      let indexRating = displayTypes.findIndex(obj => obj.value == DisplayType.RATING_BAR);
      displayTypes[indexRating].disable = true;

      let index = displayTypes.findIndex(obj => obj.value == DisplayType.PIECHART);
      displayTypes[index].disable = false;
      if(ansLength > 0 && ansLength <= 3){
        return DisplayType.PIECHART;
      }else if(ansLength >= 4 && ansLength <= 6){
        return DisplayType.HORIZONTAL_BAR;
      }else if(ansLength > 6 && ansLength < 9){
        displayTypes[index].disable = true;
        return DisplayType.VERTICAL_BAR;
      }else if(ansLength >= 9){
        return DisplayType.TABLE;
      }

    }else{
      for(let type of displayTypes){
        type.disable = true;
      }

      let indexRating = displayTypes.findIndex(obj => obj.value == DisplayType.RATING_BAR);
      displayTypes[indexRating].disable = false;
      return DisplayType.RATING_BAR;
    }
    
  }

}

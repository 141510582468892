import { EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IQuestionAnswer } from '../../interfaces/question-answer';

@Component({
  selector: 'app-question-answer-form',
  templateUrl: './question-answer-form.component.html',
  styleUrls: ['./question-answer-form.component.scss']
})
export class QuestionAnswerFormComponent implements OnInit {

  answerFormData: FormGroup;
  @Input('model') loadedModel: FormGroup;
  @Input('allowOptions') allowOptions: boolean;
  @Input('showGroup') showGroup: boolean;

  @Output('model') 
  saveFormEvent:EventEmitter<IQuestionAnswer> = new EventEmitter<IQuestionAnswer>();

  constructor(private readonly fb: FormBuilder) { }

  ngOnInit(): void {

  }

}

<advertiser-form [formModel]="advertiserForm"></advertiser-form>
<div class="container">
  <mat-card class="mt-3">
    <mat-card-header>
      <mat-card-title class="pt-3">Advertiser Information</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <mat-tab-group>
        <mat-tab label="Outlets - {{ outletTotal }}"> 
          <salon-finder-outlet-index (totalEvent)="setOutletTotal($event)" [advertiserID]="loadingAdvertiserID"></salon-finder-outlet-index>
        </mat-tab>
        <mat-tab label="Services - {{ serviceTotal }}">
          <salon-finder-service-index (totalEvent)="setServiceTotal($event)" [advertiserID]="loadingAdvertiserID"></salon-finder-service-index>
        </mat-tab>
        <mat-tab label="Promotions - {{ promotionTotal }}"> <salon-finder-promo-index (totalEvent)="setPromotionTotal($event)" [advertiserID]="loadingAdvertiserID"></salon-finder-promo-index> </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
</div>



import { IFollower } from "../../interfaces/IFollower";
import { IMessageResult } from "../../interfaces/IMessageResult";
import { IPost } from "../../interfaces/IPost";
import { TelegramCollectionService } from "../../services/telegram-collection.service";
const math = require('mathjs');
const moment = require('moment');


export const GenerateTableResource = async (startDate: Date, endDate: Date, socialEngagementsData: any[], messageResult: any[], totalFollowersAverage:number) => {

    let mergeDatas = []

    for (let data of socialEngagementsData) {
        if (mergeDatas.length > 0) {
            let index = mergeDatas.findIndex(d => d.messageID == data.messageID)
            if (index != -1) {

                let messageIndex = messageResult.findIndex(mi => mergeDatas[index].messageID == mi.messageID)

                if(messageResult[messageIndex].messageLinks.length > 0){
                    messageResult[messageIndex].messageLinks = messageResult[messageIndex].messageLinks.filter(ml => ml.shortUrlID != null)
                }

                if(mergeDatas[index].timesStamp !== null){
                
                    mergeDatas[index].id = messageResult[messageIndex].id;
                    mergeDatas[index].messageID = messageResult[messageIndex].messageID
                    mergeDatas[index].linkUrl = messageResult[messageIndex].linkUrl;
                    mergeDatas[index].addedDate = messageResult[messageIndex].addedDate;
                    mergeDatas[index].label = moment(messageResult[messageIndex].addedDate).format("DD MMM YYYY");
                    mergeDatas[index].views = messageResult[messageIndex].totalViews;
                    mergeDatas[index].viewRate =  (messageResult[messageIndex].totalViews / totalFollowersAverage) * 100;
                    mergeDatas[index].shares = messageResult[messageIndex].totalShares;
                    mergeDatas[index].shareRate = ( messageResult[messageIndex].totalShares / messageResult[messageIndex].totalViews) * 100;
                    mergeDatas[index].clicks = messageResult[messageIndex].totalClicks;
                    mergeDatas[index].clickRate = (messageResult[messageIndex].totalClicks / messageResult[messageIndex].totalViews ) *100;
                    mergeDatas[index].shortUrl = messageResult[messageIndex].shortUrl;
                    mergeDatas[index].messageLinks = messageResult[messageIndex].messageLinks;
                    mergeDatas[index].messageLinksCount = messageResult[messageIndex].messageLinks.length;
                }
            } else {
                mergeDatas.push(data)
            }
        } else {
            mergeDatas.push(data)
        }
    }

    for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
        let index = mergeDatas.findIndex(i => moment(i.addedDate).format("DD MMM YYYY") == moment(date).format("DD MMM YYYY"))
        if (index == -1) {
            mergeDatas.push({
                addedDate: new Date(date).toDateString(),
                channelID: '',
                createdDate: undefined,
                id: 0,
                label: moment(date).format("DD MMM YYYY"),
                messageID: 0,
                followerID: 0,
                views: 0,
                shares: 0,
                viewRate: 0,
                shareRate: 0,
                minimizeText: 'No Post',
                longMessage: 'No Post',
                totalFollowers: 0,
                clicks: 0,
                clickRate: 0,
                linkUrl: '',
                shortUrl: null,
                messageLinks: null,
                messageLinksCount: 0,
                
            } as IPost)
        }
    }

    if(messageResult.length > 0){
        for(let message of messageResult){
            if(message.socialEngagement.length == 0){
                mergeDatas.push({
                    addedDate: message.addedDate,
                    channelID: message.channelID,
                    createdDate: message.createdDate,
                    id: message.id,
                    label: moment(message.addedDate).format("DD MMM YYYY"),
                    messageID: message.messageID,
                    followerID: 0,
                    views: 0,
                    shares: 0,
                    viewRate: 0,
                    shareRate: 0,
                    longMessage: message.message,
                    minimizeText : message.message.substring(0,200) + "...",
                    totalFollowers: 0,
                    clicks: 0,
                    clickRate: 0,
                    linkUrl: message.linkUrl,
                    shortUrl: message.shortUrlID,
                    messageLinks: null,
                    messageLinksCount: 0,
                    
                } as IPost)
            }   
        }
    }
    
    return mergeDatas.sort((a: any, b: any) => new Date(b?.addedDate).getTime() - new Date(a?.addedDate).getTime());
}

export const GenerateSocialEngagementDateList = async (startDate: Date, endDate: Date, socialEngagementsData: any[], messageResult: any[], filterByDate:boolean) => {

    let mergeDatas = mergeObjectsByDate(socialEngagementsData)
    const dateArray: any[] = [];
    for (let date = new Date(startDate); date < endDate; date.setDate(date.getDate() + 1)) {
        
        let index = mergeDatas.findIndex(i => moment(i.addedDate).format("DD MMM YYYY") == moment(date).format("DD MMM YYYY"))
        if (index == -1) {
            const dateObj: IPost = {
                addedDate: new Date(date).toDateString(),
                channelID: '',
                createdDate: undefined,
                id: 0,
                label: moment(date).format("DD MMM YYYY"),
                messageID: 0,
                followerID: 0,
                views: 0,
                shares: 0,
                viewRate: 0,
                shareRate: 0,
                minimizeText: 'No Post',
                longMessage: 'No Post',
                totalFollowers: 0,
                clicks: 0,
                clickRate: 0,
                shortUrlID: null

            };
            dateArray.push(dateObj);
        } else {

            let views = 0;
            let shares = 0;
            let clicks = 0;
            let data = messageResult.filter(i => moment(i.addedDate).format("DD MMM YYYY") == moment(date).format("DD MMM YYYY"))
            
            if (data.length > 0) {
                for (let d of data) {
                    views += d.totalViews;
                    shares += d.totalShares;
                    clicks += d.totalClicks;
                }

                const dateObj: IPost = {
                    addedDate: mergeDatas[index].addedDate,
                    channelID: '',
                    createdDate: undefined,
                    label: moment(mergeDatas[index].addedDate).format("DD MMM YYYY"),
                    messageID: 0,
                    followerID: 0,
                    views: views,
                    shares: shares,
                    viewRate: 0,
                    shareRate: 0,
                    minimizeText: 'No Post',
                    longMessage: 'No Post',
                    totalFollowers: 0,
                    clicks: clicks,
                    clickRate: 0,
                    shortUrlID: null

                };
                dateArray.push(dateObj);
            }else{
                const dateObj: IPost = {
                    addedDate: new Date(date).toDateString(),
                    channelID: '',
                    createdDate: undefined,
                    id: 0,
                    label: moment(date).format("DD MMM YYYY"),
                    messageID: 0,
                    followerID: 0,
                    views: 0,
                    shares: 0,
                    viewRate: 0,
                    shareRate: 0,
                    minimizeText: 'No Post',
                    longMessage: 'No Post',
                    totalFollowers: 0,
                    clicks: 0,
                    clickRate: 0,
                    shortUrlID: null
    
                };
                dateArray.push(dateObj);
            }
        }
    }

    if(!filterByDate){
        const dateObj: IPost = {
            addedDate: new Date(endDate).toDateString(),
            channelID: '',
            createdDate: undefined,
            id: 0,
            label: moment(endDate).format("DD MMM YYYY"),
            messageID: 0,
            followerID: 0,
            views: 0,
            shares: 0,
            viewRate: 0,
            shareRate: 0,
            minimizeText: 'No Post',
            longMessage: 'No Post',
            totalFollowers: 0,
            clicks: 0,
            clickRate: 0,
            shortUrlID: null

        };
        dateArray.push(dateObj);
    }



    return dateArray;
}

export const GetWeeksInRange = async (startDate: Date, endDate: Date, socialEngagementsData: IPost[], messageResult: any[]) => {

    const weekArray = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
        const weekStart = new Date(currentDate);
        const weekEnd = new Date(new Date(currentDate).setDate(currentDate.getDate() + 6));
        const weekLabel = moment(weekStart).format("DD MMM YYYY") + ' - ' + moment(weekEnd).format("DD MMM YYYY");
        weekArray.push({ start: weekStart, end: weekEnd, label: weekLabel, views: 0, shares: 0, clicks: 0 });
        currentDate.setDate(currentDate.getDate() + 7);
    }

    for (let data of messageResult) {

        let index = weekArray.findIndex((i: any) => {
            let range = moment(data.addedDate).isBetween(i.start, i.end, null, []);
            if (range) return i;
        })

        if (index != -1) {

            weekArray[index].views += data.totalViews;
            weekArray[index].shares += data.totalShares;
            weekArray[index].clicks += data.totalClicks;
        }

    }

    return weekArray;
}

export const GetMonthsInRange = async (startDate: Date, endDate: Date, socialEngagementsData: IPost[], messageResult: any[]) => {

    const monthArray = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
        const monthStart = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const monthEnd = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0, 23, 59, 59, 999);
        const monthLabel = `${monthStart.toLocaleDateString('default', { month: 'long' })} ${monthStart.getFullYear()}`;
        monthArray.push({ start: monthStart, end: monthEnd, label: monthLabel, views: 0, shares: 0 ,  clicks: 0});
        currentDate.setMonth(currentDate.getMonth() + 1);
    }

    for (let data of messageResult) {

        let index = monthArray.findIndex((i: any) => {
            let range = moment(data.addedDate).isBetween(i.start, i.end, null, []);
            if (range) return i;
        })
        if (index != -1) {
            if (index != -1) {
                monthArray[index].views += data.totalViews;
                monthArray[index].shares += data.totalShares;
                monthArray[index].clicks += data.totalClicks;
            }

        }

    }

    return monthArray;
}


export const ProcessReturns = async (followerResult: IFollower[], messageResult: IMessageResult[], from: Date, to: Date, period: any, service: TelegramCollectionService, filterByDate:boolean) => {

    let result: any;
    let totalViews = 0;
    let totalShares = 0;
    let totalClicks = 0;

    let totalFollowersAverage = 0;

    let socialEngagements = [];

    let tableSource = [];


    if (messageResult.length > 0) {

        for (let objct of messageResult) {
            totalViews +=  objct.totalViews;
            totalShares += objct.totalShares;
            totalClicks +=  objct.totalClicks;
        }

        let overAllFollower = 0;
        for (let follower of followerResult) {
            overAllFollower += follower.totalFollowers;
        }

        let _tfa  = math.divide(overAllFollower,followerResult.length);
        totalFollowersAverage = math.format(_tfa, { notation: 'fixed', precision: 2 });

        for (let result of messageResult) {
            let totalFollower = 0;
            let followerData: IFollower[] = [];
            const reformDate = moment(result.addedDate).format('DD MMM YYYY');
            if(followerResult.length > 0){
                followerData = followerResult.filter(fr => moment(fr.addedDate).format('DD MMM YYYY') ==  reformDate )
                if(followerData.length > 0){
                    totalFollower = followerData[0].totalFollowers;
                }
                
            }
            
            if (result)

                for (let data of result.socialEngagement) {

                    let socialEngagementData: IPost = data;

                    if(socialEngagementData.views !== undefined){

                        let resultViews = math.divide(socialEngagementData.views, totalFollower);
                        let viewsPercent = math.multiply(resultViews, 100);
                        socialEngagementData.viewRate = Number(math.format(viewsPercent, { notation: 'fixed', precision: 2 }));
                
                    }else{
                        socialEngagementData.viewRate = 0;
                    }
                    
                    if(socialEngagementData.shares !== undefined){


                        let resultShareRate = math.divide(socialEngagementData.shares, totalFollower);
                        let shareRatePercent = math.multiply(resultShareRate, 100);
                        socialEngagementData.shareRate = Number(math.format(shareRatePercent, { notation: 'fixed', precision: 2 }));
                         
                    }else{
                        socialEngagementData.shareRate = 0;
                    }

                    
                    socialEngagementData.longMessage = result.message;
                    socialEngagementData.minimizeText = result.message.substring(0,200) + "..."

                    socialEngagements.push(socialEngagementData);
                }
        }

        tableSource = await GenerateTableResource(new Date(from), new Date(to), socialEngagements, messageResult, totalFollowersAverage);
      
        if(filterByDate){
            let labelDate = moment(to).format('DD MMM YYYY');
            tableSource = tableSource.filter(t => t.label !== labelDate)
        }
    
        switch (period) {
            case 0:
                result = await GenerateSocialEngagementDateList(new Date(from), new Date(to), socialEngagements, messageResult,filterByDate);
                break;
            case 1:
                result = await GetWeeksInRange(new Date(from), new Date(to), socialEngagements, messageResult)

                break;
            case 2:
                result = await GetMonthsInRange(new Date(from), new Date(to), socialEngagements, messageResult)
                break;
        }   

        let averageViews = math.format(math.divide(totalViews,messageResult.length), { notation: 'fixed', precision: 2 })
        let averageShares = math.format(math.divide(totalShares,messageResult.length), { notation: 'fixed', precision: 2 })
        let averageClicks = math.format(math.divide(totalClicks,messageResult.length), { notation: 'fixed', precision: 2 })

        let averageViewRate = 0;
        let averageClickRate = 0;
        let averageShareRate = 0;

        if(Number(averageViews) != 0){
            const resultAvr = math.divide(Number(averageViews), totalFollowersAverage);
            const percentageAvr = math.multiply(resultAvr, 100);    
            averageViewRate =  math.format(percentageAvr,{ notation: 'fixed', precision: 2 });
        }
        if(totalClicks != 0){
            const resultTotalClicks = math.divide(totalClicks, totalViews);
            const percentageTotalClicks = math.multiply(resultTotalClicks, 100);    
            averageClickRate =  math.format(percentageTotalClicks,{ notation: 'fixed', precision: 2 });
        }
        if(totalShares != 0){
            const resultTotalShares = math.divide(totalShares, totalViews);
            const percentageTotalShares = math.multiply(resultTotalShares, 100);    
            averageShareRate =  math.format(percentageTotalShares,{ notation: 'fixed', precision: 2 });
        }

        return {
            totalPosts: messageResult.length,
            totalClicks: totalClicks,
            totalViews: totalViews,
            totalShares: totalShares,

            averageViews: averageViews,
            averageShares: averageShares,
            averageClicks: averageClicks,

            averageViewRate:  averageViewRate,
            averageClickRate: averageClickRate,
            averageShareRate: averageShareRate,

            socialEngagementResult: result,
            tableSource: tableSource,

            totalFollowersAverage: totalFollowersAverage
        }

        
       
    
    } else {

        switch (period) {
            case 0:
                result = await GenerateSocialEngagementDateList(new Date(from), new Date(to), [], [], false);
                break;
            case 1:
                result = await GetWeeksInRange(new Date(from), new Date(to), [], [])

                break;
            case 2:
                result = await GetMonthsInRange(new Date(from), new Date(to), [], [])
                break;
        }

        return {
            totalPosts: 0,
            totalClicks: 0,
            totalViews: 0,
            totalShares: 0,
            averageViews: 0,
            averageShares: 0,
            averageClicks: 0,
            averageViewRate: 0,
            averageClickRate: 0,
            averageShareRate: 0,

            socialEngagementResult: result,
            tableSource: []
        }
    }

}
    
export const CalculateClickComparison = async (data:any , service:any) => {
    let from = data.from;
    let to = data.to;
    let obj:any;

    let linksFrom = from
    .map(m => m.linkUrl && m.linkUrl.replace('https://', '')) // Extract linkUrl from each object
    .filter(link => link !== null)

    let linksTo = to
    .map(m => m.linkUrl && m.linkUrl.replace('https://', '')) // Extract linkUrl from each object
    .filter(link => link !== null)

    
     //CALL ShortURL endpoint to updated the clicks via its short url.
     await service.getShortUrlClicksForComparison(linksFrom, linksTo).then(res => {
        if(res){
            obj = res
        }
    })

    return obj;

}

function mergeObjectsByDate(arr: IPost[]): IPost[] {
    const result: IPost[] = [];
    const map: Map<string, IPost> = new Map();

    for (const obj of arr) {
        const dateString = obj.addedDate !== undefined ? obj.addedDate : '';

        if (map.has(dateString)) {
            const existingObj: any = map.get(dateString);
            existingObj!.views += obj.views;
            existingObj!.shares += obj.shares;
            existingObj!.clicks += obj.clicks;
        } else {
            map.set(dateString, obj);
        }
    }

    map.forEach((obj) => result.push(obj));
    return result;
}

import { Component, EventEmitter, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDeleteDialogComponent } from 'src/app/consumer-report/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { ConsumerReportUserService } from 'src/app/consumer-report/services/consumerReportUserService.service';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {

  savingForm = false;

  @Input('confirmationText')
  confirmationText: string = "Confirm"
  @Input("dialogTitle")
  dialogTitle: string = "Delete Confirmation"

  response: EventEmitter<boolean> = new EventEmitter()

  confirmForm: FormGroup = new FormGroup({
    confirmField: new FormControl('', [
      Validators.pattern(new RegExp(this.confirmationText, 'i')),
      Validators.required
    ])
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: number,
    private readonly consumerReportUserService: ConsumerReportUserService,
    public dialogRef: MatDialogRef<ConfirmDeleteDialogComponent>,
    private readonly snackBar: MatSnackBar,
  ) { }

  get formControls() { return  this.confirmForm.controls; }

  ngOnInit(): void {
  }

  closeForm(response: boolean) {
    this.dialogRef.close(response);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UpdateComponent } from 'src/app/shared/abstracts/update-component';
import { IService } from '../../interfaces/iservice';
import { ServiceService } from '../../services/service.service';

@Component({
  selector: 'security-guards-service-update',
  templateUrl: './service-update.component.html',
  styleUrls: ['./service-update.component.scss']
})
export class ServiceUpdateComponent extends UpdateComponent<IService, ServiceService> implements OnInit {

  constructor(
    public readonly baseService: ServiceService,
    public readonly route: ActivatedRoute
    ) {
    super();
    this.init(route);
  }

  ngOnInit(): void {
  }

}

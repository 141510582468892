<div class="row mb-3">
	<div class="col-12 text-right">
		<button mat-raised-button color="primary" [routerLink]="['/salon-finder/email-notification/add']">Add New
			Email Template</button>
	</div>
</div>
<div class="row mb-3">
	<mat-form-field class="fullWidth col-12" appearance="fill">
		<input type="text" placeholder="Type anything to search email notification"
			aria-label="Type anything to search email notification" matInput appearance="fill" autocomplete="off"
			#searchTextInput>
	</mat-form-field>
</div>
<div class="row">
	<div class="col-12">
		<table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
			<ng-container matColumnDef="name">
				<th mat-header-cell mat-sort-header *matHeaderCellDef>Collection Name</th>
				<td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}">
					{{ element.name }}
				</td>
			</ng-container>
			<ng-container matColumnDef="subject">
				<th mat-header-cell *matHeaderCellDef>Subject</th>
				<td mat-cell *matCellDef="let element">
					{{ element.subject }}
				</td>
			</ng-container>
			<ng-container matColumnDef="sender">
				<th mat-header-cell *matHeaderCellDef>Sender</th>
				<td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}">
					{{ element.senderName }} <{{ element.senderAddress }}>
				</td>
			</ng-container>
			<ng-container matColumnDef="reply">
				<th mat-header-cell mat-sort-header *matHeaderCellDef>Reply</th>
				<td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}">
					{{ element.replyTo }}
				</td>
			</ng-container>
			<ng-container matColumnDef="description">
				<th mat-header-cell mat-sort-header *matHeaderCellDef> Description </th>
				<td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}">
					{{ element.description }}
				</td>
			</ng-container>

			<ng-container matColumnDef="action">
				<th mat-header-cell *matHeaderCellDef> Action </th>
				<td mat-cell *matCellDef="let element;">
					<span>
						<a mat-icon-button color="primary" routerLink="/salon-finder/email-notification/update/{{element.id}}">
							<mat-icon matTooltip="Edit" matTooltipPosition="left">edit</mat-icon>
						</a>
					</span>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="listingCols" class="p-2"></tr>
			<tr mat-row *matRowDef="let row; columns: listingCols;" class="p-2"></tr>
		</table>
	</div>
</div>
<div class="row mb-4">
	<div class="col-12">
		<mat-paginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
			[showFirstLastButtons]="true" [pageIndex]="page" (page)="changePage($event)">
		</mat-paginator>
	</div>
</div>

<ng-container *ngIf="isLoading">
	<div class="overlay"></div>
	<mat-progress-spinner [mode]="'indeterminate'" [diameter]="50"></mat-progress-spinner>
</ng-container>
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest, filter, forkJoin, map, Observable } from 'rxjs';
import { FormComponent } from 'src/app/shared/abstracts/form-component';
import { IRoleAccess } from '../../interfaces/irole-access';
import { IRole } from '../../interfaces/iroles';
import { IService } from '../../interfaces/iservice';
import { RoleAccessService } from '../../services/role-access.service';
import { RoleService } from '../../services/role.service';
import { ServiceService } from '../../services/service.service';
import { E } from '@angular/cdk/keycodes';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'security-guard-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.scss']
})
export class RoleFormComponent extends FormComponent<IRole, RoleService> implements OnInit {
  
  currentTabSelected:number;
  
  selectedServiceCtrl = new FormControl(0)
  monitoringServiceCtrl = new FormControl();
  isSaving = false;
  private _serviceAccessTabs: IService[] = [];
  serviceAccessTabs$: BehaviorSubject<IService[]> = new BehaviorSubject(null);

  services$ = 
    combineLatest([
      this.serviceService.getDropDown(),
      this.serviceAccessTabs$.asObservable()
    ])
    .pipe(map(([services, selectedService])=> {
      if(!selectedService) {
        return services
      }
      return services.slice().filter(s=>selectedService.findIndex(ss=>ss.id === s.id)<0)
    }))
    
  
  save(): void {
    this.isSaving = true;
    const values = this.formData.value;
    values.roleAccess = this.roleAccessFormArray.value;
    values.roleAccess = values.roleAccess.filter(r=>r.id || r.path.trim() != "" || r.label.trim() != "")
      .map(r=> {
          delete r.service
        return r
      })

    this.baseService.save(values, this.loadedModel?.id)
      .subscribe(role=> {
        this.isSaving = false;
        this.snackBar.open("Successfully save new roles.", "Close", {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        })
        this.router.navigate([`/security-guard/roles`])
      },error=> {
        this.isSaving = false;
        this.snackBar.open("Failed to save new roles.", "Close", {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        })
      })
  }

  get roleAccessFormArray() {
    return this.formData.get('roleAccess') as FormArray
  }

  changeTabAcess($event){
    this.currentTabSelected = $event;
    return this.selectedServiceCtrl.setValue($event)
  }

  updatingAccessForm(data: any) {

    console.log(data);
    if(data.action == "delete"){
      let index = this.formData.controls.roleAccess.value.findIndex(ra => ra.id == data.id);
      if(index > -1){
        this.removeAccessForm(index);

      }
     
    }else{
      if(this.roleAccessFormArray.value == null){
        this.formData.patchValue({
          roleAccess: [data.value]
        }, {emitEvent: true, onlySelf: false})    
      }else{
        let index;
        if(data.value.id == 0){
          index = this.roleAccessFormArray.value.findIndex(rfr => rfr.tabIndex == data.currentTabSelected && rfr.rowIndex == data.index && rfr.serviceID == data.value.serviceID);
        }else{
          index = this.roleAccessFormArray.value.findIndex(rfr => rfr.id == data.value.id);
        }
        if(index == -1){
      
          if(this.roleAccessFormArray.controls != undefined){
            this.roleAccessFormArray.controls.push(this.fb.group(data.value));
          }
  
          this.roleAccessFormArray.value.push(data.value);
        
        }else{
          if(this.roleAccessFormArray.controls != undefined){
            this.roleAccessFormArray.controls.at(index).setValue(data.value, {emitEvent: false });
          }
  
            this.roleAccessFormArray.value[index].allowAccess = data.value.allowAccess;
            this.roleAccessFormArray.value[index].id = data.value.id;
            this.roleAccessFormArray.value[index].label = data.value.label;
            this.roleAccessFormArray.value[index].method = data.value.method;
            this.roleAccessFormArray.value[index].path = data.value.path;
            this.roleAccessFormArray.value[index].roleID = data.value.roleID;
            this.roleAccessFormArray.value[index].service = data.value.service;
            this.roleAccessFormArray.value[index].serviceID = data.value.serviceID;  
            this.roleAccessFormArray.value[index].tabIndex = data.currentTabSelected;  
            this.roleAccessFormArray.value[index].rowIndex = data.index;  
        }
      }
    }

   
  }


  removeAccessForm(index) {
    this.roleAccessFormArray.removeAt(index);
    this._serviceAccessTabs[0].roleAccess.splice(index, 1);
  }

  addAccessForm(serviceID: number) {
    if(serviceID) {
      for(const service of this._serviceAccessTabs) {
        if(service.id === serviceID) {
          service.roleAccess.push(this.newRoleAccessObj(service));
        }
      }
      this.serviceAccessTabs$.next(this._serviceAccessTabs.slice())
    }
  }
  

  constructor(
    public readonly baseService: RoleService,
    private readonly serviceService: ServiceService,
    private readonly roleAccessService: RoleAccessService,
    private readonly fb: FormBuilder, 
    private router: Router,
    private readonly snackBar: MatSnackBar
  ) {
    super();
  }

  removeMonitoringService(index: number) {
    this._serviceAccessTabs = this._serviceAccessTabs.filter((d,i) => i !== index)
    this.serviceAccessTabs$.next(this._serviceAccessTabs)
  }

  newRoleAccessObj(service: IService) {
    return {
      'roleID': this.loadedModel == undefined ? 0 :  this.loadedModel.id,
      'serviceID': service.id,
      service: service,
      method: null,
      path: "",
      label: "",
    }
  }

  addMonitoringService() {
    const service = this.monitoringServiceCtrl.value as IService
    service.roleAccess = [this.newRoleAccessObj(service)]
    this._serviceAccessTabs.push(service)
    this.serviceAccessTabs$.next(this._serviceAccessTabs)
  }

  ngOnInit(): void {
    this.initForm();

    combineLatest([
      this.serviceService.getDropDown(),
      this._loadedModel$
    ])
    .subscribe(([serviceModel, loadedModel]) => { 
      if(loadedModel !== undefined){
        for(const roleAccess of loadedModel.roleAccess) {
          const index = this._serviceAccessTabs.findIndex(s=>s.id === roleAccess.serviceID)
          if(index >= 0) {
            this._serviceAccessTabs[index].roleAccess.push(roleAccess)
          } else {
            const foundService = serviceModel.find(s=>s.id === roleAccess.serviceID)
          
            if(foundService != undefined){
              foundService.roleAccess = [roleAccess];
              this._serviceAccessTabs.push(foundService)
            }
          }
        }
      }
   
    })
    this.serviceAccessTabs$.next(this._serviceAccessTabs)

    this.serviceAccessTabs$.subscribe(services=> {
      const roleAccessArray = [];
      for(const service of services) {
        for(const roleAccess of service.roleAccess) {
          roleAccessArray.push(roleAccess)
        }
      }
      if(this.loadedModel !== undefined){
        this.formData.patchValue({
          roleAccess:  this.roleAccessService.makeFormArray(roleAccessArray.slice())
        }, {emitEvent: true, onlySelf: false})    
      }

      })
  }
}
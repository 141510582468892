import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { IOrderVoucher } from '../../interfaces/IOrderVoucher';
import { SalonFinderOrderVoucherService } from '../../services/salon-finder-order-voucher.service';

@Component({
  selector: 'app-finance-index',
  templateUrl: './finance-index.component.html',
  styleUrls: ['./finance-index.component.scss']
})
export class FinanceIndexComponent extends ListingComponent<IOrderVoucher> implements OnInit {
  populateList<U>(params?: U | HttpParams): void {
    this.salonFinderOrderVoucherService.list(this.makeSearchParams(params))
      .subscribe(d=>this.initCommonListingResponse(d))
  }
  getExtraListingParams(): void {
    throw new Error('Method not implemented.');
  }
  deleteItem(item: IOrderVoucher): void {
    throw new Error('Method not implemented.');
  }

  constructor(private readonly salonFinderOrderVoucherService: SalonFinderOrderVoucherService) {
    super();
  }

  ngOnInit(): void {
    
  }
}
<div class="container">
	<div class="row">
		<div class="col">
			<mat-card>
				<mat-card-header>
					<mat-card-title>Welcome to DARVIS</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<p>DARVIS (Database and reports virtually integrated system) was created to host a variety of mission critical
						applications, created in-house, in one website for easy access.</p>
					<p>Access to specific applications are given on a needs basis.</p>
					<p>If you are experiencing technical difficulties or require certain access, please email to
						it.support&#64;dailyvanity.com, keith&#64;dailyvanity.com, and your manager.</p>
					<p>Our aim is to make your working experience better. Thus, we welcome feedback if you have any! You may send
						your feedback to the above email addresses too.</p>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
</div>
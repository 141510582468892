import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IRequestAnswerList } from '../interfaces/request-answer-list'
import { IQuestionAnswer } from '../interfaces/question-answer';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators, NumericValueType } from '@rxweb/reactive-form-validators';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';

@Injectable({
  providedIn: 'root'
})
export class ConsumerReportAnswerService {
  constructor(
    private readonly http: HttpClient,
    private readonly fb: FormBuilder
    ) { }

  makeFormGroup(loadedModel?: IQuestionAnswer): FormGroup {
    return this.fb.group({
      label: [loadedModel ? loadedModel.label : undefined, [
        Validators.maxLength(256),
        Validators.required
      ]],
      group: [loadedModel ? loadedModel.group : undefined, [
        Validators.maxLength(125)
      ]],
      order: [loadedModel ? loadedModel.order : 99, [
        RxwebValidators.numeric({acceptValue: NumericValueType.PositiveNumber, allowDecimal: false})
      ]],
      point: [loadedModel ? loadedModel.point : 0, [
        RxwebValidators.numeric({acceptValue: NumericValueType.PositiveNumber, allowDecimal: false})
      ]],
      id: [loadedModel ? loadedModel.id : undefined, [
        RxwebValidators.numeric({acceptValue: NumericValueType.PositiveNumber, allowDecimal: false})
      ]],
      tooltip: [loadedModel ? loadedModel.tooltip : undefined, [
        Validators.maxLength(256)
      ]],
      specialControl: [loadedModel ? loadedModel.specialControl : undefined, [
        Validators.maxLength(256)
      ]],
      reorder: [loadedModel ? loadedModel.reorder : false, [
        
      ]],
      questionID: [loadedModel ? loadedModel.questionID : undefined, [
        RxwebValidators.numeric({acceptValue: NumericValueType.PositiveNumber, allowDecimal: false})
      ]],
    });
  }

  save(param: IQuestionAnswer) {
    return this.http.post<IQuestionAnswer>(environment.consumerReport.baseURL+environment.consumerReport.questionAnswerBase + environment.consumerReport.commonCreatePath, param)
  }

  listAnswer(params: IRequestAnswerList) {
    return this.http.get<ICommonListingResponse<IQuestionAnswer[]>>(environment.consumerReport.baseURL + environment.consumerReport.listQuestionAnswer, {
      params: new HttpParams({
        fromObject: {
          questionID: params.questionID,
          page: params.page.toString(),
          limit: params.limit.toString()
        }
      })
    });
  }
}

import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { map, Observable, shareReplay } from 'rxjs';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { ICommonListingSearch } from 'src/app/shared/interfaces/ICommonListSearch';
import { ICommonObject } from 'src/app/shared/interfaces/ICommonObject';
import { ICRUDPath } from 'src/app/shared/interfaces/iCRUDPath.interface';
import { environment } from 'src/environments/environment';
import { IRoleAccess } from '../interfaces/irole-access';
import { IRole } from '../interfaces/iroles';
import { RoleAccessService } from './role-access.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService extends CRUDService<IRole> {

  private _roleSelection$: Observable<IRole[]>

  

  getRoleSelection() {
    if(!this._roleSelection$) {
      this._roleSelection$ = this.list()
        .pipe(
          map(d=>d.data),
          shareReplay()
        )
    }
    return this._roleSelection$
  }

  list(params?: HttpParams): Observable<ICommonListingResponse<IRole[]>> {
    return this.httpList(params);
  }
  get(id: string | number): Observable<IRole> {
    throw new Error('Method not implemented.');
  }
  delete(id: string | number): Observable<IRole> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: IRole): Observable<IRole> {
    return this.save(model, id);
  }
  create(model: IRole): Observable<IRole> {
    return this.save(model)
  }
  makeFormGroup(loadedModel?: IRole) {
    return this.fb.group({
      id: [loadedModel?.id || 0],
      name: [loadedModel?.name || "", [Validators.maxLength(1024)]],
      label: [loadedModel?.label || "", [Validators.maxLength(1024)]],
      cognitoName: [loadedModel?.cognitoName || "", [Validators.maxLength(1024)]],
      googleID: [loadedModel?.googleID || "", [Validators.maxLength(50)]],
      type: [loadedModel?.type || "", [Validators.maxLength(50)]],
      roleAccess: loadedModel?.roleAccess?.length>0?this.fb.array(this.roleAccessService.makeFormArray(loadedModel?.roleAccess)):[]
    })
  }

  syncGoogleRole() {
    return this.httpClient.post(this.getAPIURL(environment.securityGuard.roleSyncGoogleGroup), {
      
    })
  }

  makeSearchListGroup(defaultParams?: ICommonListingSearch) {
    return this.fb.group(this.generateCommonSearchFormGroup({
      groupType: [defaultParams?.groupType || []],
    }, defaultParams));
  }
  apiPath = environment.securityGuard.roleBase;
  environmentCRUDPath = environment.securityGuard;

  constructor(
    public readonly httpClient: HttpClient,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute,
    private readonly fb: FormBuilder,
    private readonly roleAccessService: RoleAccessService
  ) {
    super();
  }
}

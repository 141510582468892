<!-- <div class="row mb-3">
  <div class="col-12 text-center">
    <h1><b>Update Service</b></h1>
  </div>
</div> -->
<div class="row">
  <div class="col-12">
    <mat-form-field class="fullWidth col-12" appearance="fill">
      <input type="text"
      placeholder="Type anything to search for services that have been added"
      aria-label="Type anything to search for services that have been addede"
      matInput
      appearance="fill"
      autocomplete="off" 
      placeholder="Filter"
      #filterTextInput />
    </mat-form-field>
    <form [formGroup]="serviceForm">
      <div class="row">  
        <div class="col-6 pl-3">
          <h3>Collection Name : <b>{{collectionName}}</b></h3>
        </div>
        <div class="col-6 pr-5 text-right">
          <a mat-icon-button color="primary" routerLink="/salon-finder/collections/add-service/{{loadingCollectionID}}">
            <button matTooltip="Add Service" class="btn submit add-service">Add Service</button>
          </a>
        </div>
      </div>
      
      <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
        <ng-container matColumnDef="Advertiser">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-center">Advertiser</th>
          <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{'deactivated':element.active===0}"> 
            {{ element.advertiser.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Service Name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-center">Service Name</th>
            <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{'deactivated':element.active===0}"> 
              <p *ngIf="element.name !== ''">
                {{element.name}}
              </p>
            </td>
        </ng-container>
        <ng-container matColumnDef="Price">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-center">Price</th>
          <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{'deactivated':element.active===0}"> 
            <p *ngIf="element.price" [ngClass]="{'new-price':element.new_price}">
              {{element.price|currency}}
            </p>
            <p *ngIf="element.new_price">
              {{element.new_price|currency}}
            </p>
          </td>
        </ng-container>
        <ng-container matColumnDef="Outlets">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-center" class="text-center">Outlets</th>
          <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{'deactivated':element.active===0}"> 
            <p *ngIf="element.__outlets; else elseBlock">
              {{element.__outlets.split(',').length}}
            </p>
            <ng-template #elseBlock>0</ng-template>
          </td>
        </ng-container>
        <ng-container matColumnDef="Types">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-center">Types</th>
          <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{'deactivated':element.active===0}"> 
            <div *ngIf="element.type">
              <p>{{element.type + ' : ' + element.subType}}</p>
              <p>{{'Duration : ' + element.duration}}</p>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="Promotions">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-center">Promotions</th>
          <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{'deactivated':element.active===0}">
            <div *ngIf="element.promoNum; else elseBlock">
              <p>Until: {{ element.deadline | date:'d MMMM y' }}</p>
            </div>
            <ng-template #elseBlock>No</ng-template>
          </td>
        </ng-container>
        <ng-container matColumnDef="Purchased">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-center">Purchased</th>
          <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{'deactivated':element.active===0}"> 
            <div *ngIf="element.pNum || element.rNum; else elseBlock">
              <p *ngIf="element.pNum">{{element.pNum}}</p>
              <p *ngIf="element.rNum">{{element.rNum + " redeemed"}}</p>
            </div>
            <ng-template #elseBlock>-</ng-template>
          </td>
        </ng-container>
        <ng-container matColumnDef="Status">
            <th mat-header-cell mat-sort-header *matHeaderCellDef  class="text-center">Status</th>
            <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{'deactivated':element.active===0}"> 
              <p *ngIf="element.active === 0">
                Inactive
              </p>
              <p *ngIf="element.active === 1 && element.preview === 1">
                Preview
              </p>
              <p *ngIf="element.active === 1 && element.preview === 0">
                Active
              </p>
              <p *ngIf="element.active === 2">
                Pending
              </p>
              <p *ngIf="element.active === 5">
                Error
              </p>
            </td>
        </ng-container>
        <ng-container matColumnDef="Action" >
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element;">
            <p>
              <a mat-icon-button color="warn" (click)="removeService(+collectionId,element.id)">
                  <mat-icon matTooltip="Remove Service" matTooltipPosition="right">remove_circle</mat-icon>
              </a>
            </p>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="listingCols"></tr>
        <tr mat-row *matRowDef="let row; columns: listingCols;"></tr>
      </table>
    </form>
  </div>
</div>
<div class="row mb-4">
  <div class="col-12">
    <mat-paginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" [pageIndex]="page"
      (page)="changePage($event)">
    </mat-paginator>
  </div>
</div>

<ng-container *ngIf="isLoading">
  <div class="overlay"></div>
  <mat-progress-spinner [mode]="'indeterminate'" [diameter]="50"></mat-progress-spinner>
</ng-container>
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';
import { IAdvertiser } from '../../interfaces/IAdvertiser';
import { IServicePromotion } from '../../interfaces/IServicePromotion';
import { SalonFinderAdvertiserService } from '../../services/salon-finder-advertiser.service';
import { SalonFinderOutletService } from '../../services/salon-finder-outlet.service';
import { SalonFinderPromoService } from '../../services/salon-finder-promo.service';
import { SalonFinderServiceService } from '../../services/salon-finder-service.service';

@Component({
  selector: 'salon-finder-promo-update',
  templateUrl: './promo-update.component.html',
  styleUrls: ['./promo-update.component.scss']
})
export class PromoUpdateComponent implements OnInit {
  promoForm: IServicePromotion = {
    id: 0,
    name: '',
    price: 0,
    originalPrice: 0,
    startDate: '',
    endDate: '',
    terms: '',
    advertiserID: 0,
    servicePrice: 0,
    serviceID: 0,
    outlets: [],
    status: 0,
    active: true
  };

  loadingData: boolean = false

  constructor(private readonly activatedRoute: ActivatedRoute,
              private readonly salonFinderServiceService: SalonFinderServiceService,
              private readonly salonFinderServicePromoService: SalonFinderPromoService
  ) { }

  ngOnInit(): void {
    this.salonFinderServicePromoService.get(this.activatedRoute.snapshot.paramMap.get('id'))
      .subscribe(d => {
        this.promoForm = d;
        this.promoForm.servicePrice = +d.service.price
      });
  }
}

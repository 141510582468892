import { Component, OnInit } from '@angular/core';
import { IPublisher } from '../../interfaces/publisher';

@Component({
  selector: 'native-ads-publisher-create',
  templateUrl: './publisher-create.component.html',
  styleUrls: ['./publisher-create.component.scss']
})
export class PublisherCreateComponent {

  loadedModel: IPublisher = {
    name: "",
    url: "",
    id: undefined,
    status: {
      active: true,
      default: false,
      id: 45,
      identifier: "activated",
      order: 0,
      reference: "publisherStatus",
      value: "Activated",

    },
    active: true,
    GSTRegistered: false,
    statusID: 45,
    defaultSelect: false
  }

  constructor() { }
}

import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { IMasterData } from '../../interfaces/master-data';
import { IPublisher } from '../../interfaces/publisher';
import { NativeAdsMasterDataService } from '../../services/native-ads-master-data.service';
import { NativeAdsPublisherService } from '../../services/native-ads-publisher.service';

@Component({
  selector: 'app-publisher-index',
  templateUrl: './publisher-index.component.html',
  styleUrls: ['./publisher-index.component.scss']
})
export class PublisherIndexComponent extends ListingComponent<IPublisher> implements OnInit {

  lookupMasterData$: Subscription = new Subscription()

  private _loadedMasterData: IMasterData[] = new Array<IMasterData>()

  getloadedMasterData(id) {
    return this._loadedMasterData.find(l=>l.id === id)
  }

  populateList<U>(params?: HttpParams | U): void {
    this.lookupMasterData$ = this.masterDataService.listCache()
      .subscribe((m) => {
        this._loadedMasterData = m
      })
    this.commonPopulateList().add(this.lookupMasterData$)
  }
  getExtraListingParams(): void {
    throw new Error('Method not implemented.');
  }
  deleteItem(item: IPublisher): void {
    throw new Error('Method not implemented.');
  }

  constructor(
    public baseService: NativeAdsPublisherService,
    private readonly masterDataService: NativeAdsMasterDataService
  ) {
    super();
    this.listingCols = [
      'status',
      'name',
      'url',
      'period',
      'action'
    ];
  }

  ngOnInit(): void {
    this.populateList()
  }

}

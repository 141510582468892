<div class="container-fluid">
  <form [formGroup]="formQuestionData">
    <h3>Form Question</h3>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="fill" class="fullWidth" style="width: 100%">
          <mat-label>Label</mat-label>
          <input matInput #label maxlength="120" placeholder='You can change the label text' formControlName="label">
          <mat-error *ngIf="formControls?.label.errors">
            Label cannot be empty and not more than 125 char
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="fill" class="fullWidth">
          <mat-label>Instruction</mat-label>
          <input matInput #instruction maxlength="120" placeholder='Add instruction for just this form'
            formControlName="instruction">
          <mat-error *ngIf="formControls?.instruction.errors">
            Instruction cannot be more than 1024 char
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <h3 mat-subheader>Depended Answer</h3>
      </div>
      <div class="col-6">
        <h3> <mat-checkbox #display formControlName="display" class="display-question">Display</mat-checkbox></h3>
      </div>
    </div>
    <section formArrayName="dependedQuestions">
      <div class="row" *ngFor="let dependedQuestion of dependedQuestions.controls; let i=index">
        <ng-container [formGroupName]="i">
          <div class="col-4">
            <mat-form-field appearance="fill" class="fullWidth">
              <mat-label>Questions</mat-label>
              <mat-select formControlName="question" #selectedQns [compareWith]="compareQuestions">
                <mat-option *ngFor="let previousFormQuestion of previousFormQuestions; let formQuesionIndex = index"
                  [value]="previousFormQuestion">
                  {{ previousFormQuestion.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-7">
            <mat-form-field appearance="fill" class="fullWidth" *ngIf="selectedQns.value">
              <mat-label>Questions Answer</mat-label>
              <mat-select formControlName="answer" [compareWith]="compareQuestionAnswer">
                <mat-option
                  *ngFor="let selectedQuestionAnswer of questionAnswer(selectedQns.value.questionID); let selectedQuestionAnswerIndex = index"
                  [value]="selectedQuestionAnswer">
                  {{ selectedQuestionAnswer.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-1">
            <span>
              <a mat-icon-button color="primary" (click)="addDependency()">
                <mat-icon>add</mat-icon>
              </a>
              <a mat-icon-button color="primary" (click)="removeDependency(i)">
                <mat-icon>delete</mat-icon>
              </a>
            </span>
          </div>
        </ng-container>

      </div>
    </section>

    <div class="row">
      <div class="col-12 text-center">
        <button mat-raised-button color="primary" class="m-2" (click)="save()"
          [disabled]="formQuestionData.status != 'VALID'">Save</button>
      </div>
    </div>
  </form>
</div>
import { Component, OnInit } from '@angular/core';
import { IForm } from '../../interfaces/form';

@Component({
  selector: 'app-forms-create',
  templateUrl: './forms-create.component.html',
  styleUrls: ['./forms-create.component.scss']
})
export class FormsCreateComponent implements OnInit {

  formModel: IForm = {
    name: '',
    questions: [],
    formPage: []
  };

  ngOnInit(): void {
  }

}

export const Auth = {
  authBaseURL: "https://auth.dailyvanity.sg",
  authPath: "/login",
  authExchangeTokenPath: "/oauth2/authorize",
  authTokenPath: "/oauth2/token",
  signInCookie: 'signin-path',
  authLogoutPath: "/logout",
  authClientID: "2b2kci65apo5mut8udkd21vi2g",
  userInfoEndpoint: "/oauth2/userInfo",
  returnURL: "https://steady-v2.darvis.dev/sign-in",
  signOutURL: "https://steady-v2.darvis.dev/sign-out",
  timeout: 10000,
  scope: "openid email profile aws.cognito.signin.user.admin",
  cookiesRefreshTokenName: "darvisRefreshToken",
  cookiesAccessTokenName: "darvisJWTToken",
  cookiesProfileTokenName: "darvisProfileToken",
  cookiesRefreshTokenExpired: 29,
  userPoolID: "ap-southeast-1_ErePuUTri",
  adminGroupName: "darvis-admin",
  defaultAdminRoles: ['tech@dailyvanity.com', 'founder@dailyvanity.com'].map(r=>r + "_google_workspace"),
  allowedDomains: ["sf-api.steady.darvis.dev", "consumer-report-config-api.steady.darvis.dev", "sf-api.dailyvanity.sg", "consumer-report-api.steady.darvis.dev", "report-api.dailyvanity.sg", 'consumer-report-v2-api.dailyvanity.com', 'consumer-report-api-v2.steady.darvis.dev', 'native-recommandations-api.steady.darvis.dev', "security-guards.steady.darvis.dev", "telegram-bot-api.steady.darvis.dev", "urlshortener-api.steady.darvis.dev"]
}

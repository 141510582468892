import { surveyConfigServer } from "./survey-config-server";

export const surveyConfig = {

    commonDeletePath: '/delete',
    commonListPath: '/list',
    commonUpdatePath: '/update',
    commonCreatePath: '/create',
    commonGetPath: '/get',

    questionBase: '/question',

    formQuestionBase: '/formQuestion',
    
    surveyReportConfigBase : '/surveyReportConfig',
    updateReportConfig : '/updateReportConfig',
    duplicateReportConfig : '/duplicate',
    surveyReportPageBase: '/surveyReportPage',
    surveyReportGraphBase: '/surveyReportGraph',
    surveyReportGraphAnswerBase: '/surveyReportGraphAnswer',
    surveyReportConfigGroupBase : '/surveyReportConfigGroup',
    combinedComparisonDisplayValue: '/combinedComparisonDisplayValue',


    

    ...surveyConfigServer
}
<div class="auth-container">
	<div class="container">
		<div class="row">
			<div class="text-center col-12 mb-4 mt-4">
				<img src="/assets/loginLogo.svg" />
			</div>
		</div>
		<div class="row justify-content-center">
			<div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
				<form [formGroup]="formData">
					<mat-card>
						<mat-card-title><h2 class="mat-title text-center">Forgot your password?</h2></mat-card-title>
						<mat-card-subtitle>Enter your Email below and we will send a message to reset your password</mat-card-subtitle>
						<mat-card-content>
								<mat-form-field class="col-12" appearance="outline">
									<mat-label>Email</mat-label>
									<input matInput placeholder="Email" formControlName="email" />
									<mat-error>
										<span *ngIf="formData.get('email').hasError('required')">Email is <strong>required</strong></span>
									</mat-error>
									<mat-error>
										<span *ngIf="formData.get('email').hasError('email')">Wrong email format</span>
									</mat-error>
								</mat-form-field>								                
							
						</mat-card-content>
						<mat-card-actions class="text-center">
							<button mat-flat-button color="primary" class="me-4" (click)="resetPassword()">Reset Password</button> 
							<a mat-flat-button color="basic" [routerLink]="['/sign-in']">Cancel</a>
						</mat-card-actions>
					</mat-card>
				</form>
			</div>
		</div>
	</div>
</div>
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IConsumerReportCreateUserProfile, IConsumerReportSearchRequest, IConsumerReportUser } from '../interfaces/consumerReportUser.interface';
import { IUserSearchHistoryResponse } from '../interfaces/IUserSearchHistoryResponse.interface';
import { Observable } from 'rxjs';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { Router, ActivatedRoute } from '@angular/router';
import { ICRUDPath } from 'src/app/shared/interfaces/iCRUDPath.interface';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';

@Injectable({
  providedIn: 'root'
})
export class ConsumerReportUserService extends CRUDService<IConsumerReportUser> {
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }

  list(params?: HttpParams): Observable<ICommonListingResponse<IConsumerReportUser[]>> {
    return this.httpList(params)
  }
  get(id: string | number): Observable<IConsumerReportUser> {
    return this.httpGet(id.toString())
  }
  delete(id: string | number): Observable<IConsumerReportUser> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: IConsumerReportUser): Observable<IConsumerReportUser> {
    throw new Error('Method not implemented.');
  }
  create(model: IConsumerReportUser): Observable<IConsumerReportUser> {
    throw new Error('Method not implemented.');
  }
  makeFormGroup(loadedModel?: IConsumerReportUser) {
    throw new Error('Method not implemented.');
  }
  apiPath = environment.consumerReport.userBase;
  environmentCRUDPath = environment.consumerReport


  constructor(
    public override readonly httpClient: HttpClient,
    public override readonly activatedRoute: ActivatedRoute,
    public override readonly router: Router
    ) {
    super();
  }

  loadAllByIDs(searchParams: HttpParams) {
    return this.list(searchParams)
  }

  listUser(searchTerms: string[], pageOptions: { page: number, pageSize: number, customer: boolean }) {
    let params: HttpParams = new HttpParams({
      fromObject: {
        limit: pageOptions.pageSize.toString(),
        page: pageOptions.page.toString(),
        customer: pageOptions.customer.toString()
      }
    });
    if (searchTerms.length > 0) {
      params = params.set('searchTerms', searchTerms.join(','));
    }
    return this.list(params);
  }

  listAccountManagerUser(searchTerms: string, pageOptions: { page: number, pageSize: number }) {
    let params: HttpParams = new HttpParams({
      fromObject: {
        limit: pageOptions.pageSize.toString(),
        page: pageOptions.page.toString()
      }
    });
    if (searchTerms !== null) {
      params = params.set('accountManagerID', searchTerms);
    }
    return this.list(params);
  }

  listAccountManagerUserById(searchTerms: string, pageOptions: { page: number, pageSize: number }) {
    let params: HttpParams = new HttpParams({
      fromObject: {
        limit: pageOptions.pageSize.toString(),
        page: pageOptions.page.toString()
      }
    });
    if (searchTerms !== null) {
      params = params.set('accountManagerID', searchTerms);
    }
    return this.accountManagerSubUser(params);
  }

  accountManagerSubUser (params?: HttpParams): Observable<ICommonListingResponse<IConsumerReportUser[]>> {
    return this.httpClient.get<ICommonListingResponse<IConsumerReportUser[]>>(environment.consumerReport.baseURL + environment.consumerReport.listAccountManagerSubUser, {
      params
    });
  }



  getUser(id: string) {
    return this.get(id)
  }
  
  getUserInfo() {
    return this.httpClient.get<IConsumerReportUser>(this.getAPIURL(environment.consumerReport.userInfo))
  }

  listUserHistory(userID: string, pageOptions: { page: number, pageSize: number, order?: string }) {
    const params: HttpParams = new HttpParams({
      fromObject: {
        id: userID,
        page: pageOptions.page.toString(),
        limit: pageOptions.pageSize.toString(),
        order: pageOptions.order
      }
    });

    return this.httpClient.get<ICommonListingResponse<IUserSearchHistoryResponse[]>>(environment.consumerReport.baseURL + environment.consumerReport.listUserSearchHistory, {
      params
    });
  }
  getAccountManagerDetails() {
    return this.httpClient.get<IConsumerReportUser[]>(environment.consumerReport.baseURL + environment.consumerReport.listAccountManagers);
  }

  getAccountManager() {
    const params: HttpParams = new HttpParams({
      fromObject: {
        isAccountManager: '1',
        limit: '100'
      }
    });

    return this.list(params);
  }

  getPointYears(): Observable<number[]> {
    return this.httpGetByPath<number[]>(environment.consumerReport.getPointYears) as Observable<number[]>
  }

  getFormList() {
    return this.httpClient.get<number[]>(environment.consumerReport.baseURL + environment.consumerReport.listForm);
  }

  getCompletedFormList() {
    const params: HttpParams = new HttpParams({
      fromObject: {
        status: '2'
      }
    })
    return this.httpClient.get<number[]>(environment.consumerReport.baseURL + environment.consumerReport.listForm, { params });
  }

  profileStatus(id: number, status: boolean) {
    return this.httpClient.post<IConsumerReportUser>(environment.consumerReport.baseURL + environment.consumerReport.userStatus + '/' + id, {
      status: status
    }); 
  } 

  disableProfile(id: number) {
    return this.profileStatus(id, false)
  }

  enableProfile(id: number) {
    return this.profileStatus(id, true)
  }

  changePassword(password: string) {

  }

  getCompanies() {
    return this.httpGetByPath<string[]>(environment.consumerReport.listCompanies) as Observable<string[]>
  }

  createProfile(profile: IConsumerReportCreateUserProfile) {
    if (profile.id) {
      return this.httpClient.post<IConsumerReportUser>(environment.consumerReport.baseURL + environment.consumerReport.updateUser + '/' + profile.id, profile);
    }

    return this.httpClient.post<IConsumerReportUser>(environment.consumerReport.baseURL + environment.consumerReport.createUser, profile);
  }

  updateCustomerAccountManager(customerAccountManager:any){
    return this.httpClient.post<any>(environment.consumerReport.baseURL + environment.consumerReport.updateCustomerAccountManager,{data:  customerAccountManager});
  }

  generateNewSurveyResult(uuid:string){
    return this.httpClient.get<any>(environment.consumerReport.baseURL + environment.consumerReport.generateNewSurveyReportResult+ '/' + uuid);
  }
}

<ng-template #allClient let-client="client">
	All Clients
</ng-template>
<ng-template #normalClient let-client="client">
	[{{client.id}}] - {{client.name}} ({{client.campaignCount}})
</ng-template>
<div class="row mb-3" [hidden]="clientID">
	<div class="col-12 text-right">
	  <button mat-raised-button color="primary" [routerLink]="['/native-ads/campaign/create']">Add New
		Campaign</button>
	</div>
</div>
<div class="row">
	<mat-form-field class="col-12" appearance="fill">
		<input type="text" placeholder="Type anything to search based on client name or campaign name"
			aria-label="Type anything to search based on client name or campaign name" matInput appearance="fill"
			autocomplete="off" [formControl]='searchTextFormControl'>
	</mat-form-field>
	<mat-form-field class="col-12 col-sm-6 col-lg-2" appearance="fill">
		<mat-label>Ad Type</mat-label>
		<mat-select multiple [formControl]='searchAdsTypeFormControl' [compareWith]="comparingPrimaryKey">
			<mat-option *ngFor="let campaignItemAdsType of campaignItemAdsType$ | async" [value]="campaignItemAdsType">
				{{campaignItemAdsType.value}}
			</mat-option>
		</mat-select>
	</mat-form-field>
	<mat-form-field class="col-12 col-sm-6 col-lg-2" appearance="fill">
		<mat-label>Platforms</mat-label>
		<mat-select multiple [formControl]='searchPublisherFormControl' [compareWith]="comparingPrimaryKey">
			<mat-option *ngFor="let publisher of loadedPublisher$ | async" [value]="publisher">
				{{publisher.name}}
			</mat-option>
		</mat-select>
	</mat-form-field>
	<mat-form-field class="col-12 col-sm-6 col-lg-2" appearance="fill">
		<mat-select [formControl]="searchClientFormControl" placeholder="Client" #singleSelect
			[compareWith]="compareClient">
			<mat-option>
				<ngx-mat-select-search [formControl]="searchClientTextFormControl" placeholderLabel="Search for client"
					noEntriesFoundLabel="No results found"></ngx-mat-select-search>
			</mat-option>
			<mat-option *ngFor="let client of filteredClientList" [value]="client">
				<ng-container [ngTemplateOutlet]="client.id>0?normalClient:allClient"
					[ngTemplateOutletContext]="{client:client}">
				</ng-container>
			</mat-option>
		</mat-select>
	</mat-form-field>
	<mat-form-field class="col-12 col-sm-6 col-lg-2" appearance="fill">
		<mat-select [formControl]="searchCampaignFormControl" placeholder="Campaign" #singleSelect>
			<mat-option>
				<ngx-mat-select-search [formControl]="searchCampaignTextFormControl" placeholderLabel="Search for campaign"
					noEntriesFoundLabel="No results found"></ngx-mat-select-search>
			</mat-option>
			<mat-option *ngFor="let campaign of filteredCampaignList" [value]="campaign">
				{{campaign}}
			</mat-option>
		</mat-select>
	</mat-form-field>
	<mat-form-field class="col-12 col-sm-12 col-lg-4" appearance="fill">
		<mat-label>Status</mat-label>
		<mat-select multiple [formControl]='searchStatusFormControl' [compareWith]="comparingPrimaryKey">
			<mat-option *ngFor="let status of campaignItemsStatus$ | async" [value]="status">
				{{status.value}}
			</mat-option>
		</mat-select>
	</mat-form-field>
</div>
<div class="row">
	<div class="col-12">
		<table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
			<ng-container matColumnDef="title">
				<th mat-header-cell mat-sort-header *matHeaderCellDef>Headline</th>
				<td mat-cell *matCellDef="let element">
					{{ element.title }}
					<native-ads-image [campaignItem]="element"></native-ads-image>
				</td>
			</ng-container>

			<ng-container matColumnDef="clientName">
				<th mat-header-cell mat-sort-header *matHeaderCellDef>Client</th>
				<td mat-cell *matCellDef="let element">
					{{ element.campaign.clientName }}
				</td>
			</ng-container>
			<ng-container matColumnDef="impressionBooked">
				<th mat-header-cell mat-sort-header *matHeaderCellDef class="mat-column-metrics">Impression Booked</th>
				<td mat-cell *matCellDef="let element" class="mat-column-metrics">
					{{ element.impressionPurchased | number }}
				</td>
			</ng-container>
			<ng-container matColumnDef="campaignName">
				<th mat-header-cell *matHeaderCellDef>Campaign Name</th>
				<td mat-cell *matCellDef="let element">
					{{ element.campaign.name }} <br />
					<native-ads-publisher-icon [publisherModels]="element.campaign.publishers"></native-ads-publisher-icon>
				</td>
			</ng-container>
			<ng-container matColumnDef="adInfo">
				<th mat-header-cell mat-sort-header *matHeaderCellDef>Ad Info</th>
				<td mat-cell *matCellDef="let element">
					<native-ads-campaign-status-text [campaignStatus]="element.campaign.status"></native-ads-campaign-status-text> <br />
					<b>
						{{ element.type.identifier }} <br />
						<mat-icon *ngIf="element.campaign.priority==1">arrow_upward</mat-icon>
						<mat-icon *ngIf="element.campaign.priority!=1">arrow_downward</mat-icon>
					</b>
				</td>
			</ng-container>
			<ng-container matColumnDef="period">
				<th mat-header-cell *matHeaderCellDef>Ends on</th>
				<td mat-cell *matCellDef="let element">
					{{ element.campaign.endDate | date:'dd MMM yyyy hh:mm a' }}
				</td>
			</ng-container>

			<ng-container matColumnDef="impression" class="mat-column-metrics">
				<th mat-header-cell *matHeaderCellDef class="mat-column-metrics">Impressions<br />/<br />Viewable Impression</th>
				<td mat-cell *matCellDef="let element" class="mat-column-metrics-cell">
					{{ element.achievedImpression | number }}<br />/<br />
					{{ element.achievedViewableImpression  | number }}
				</td>
			</ng-container>

			<ng-container matColumnDef="click" class="mat-column-metrics">
				<th mat-header-cell *matHeaderCellDef class="mat-column-metrics">Clicks</th>
				<td mat-cell *matCellDef="let element" class="mat-column-metrics-cell">
					{{ element.achievedClick }}
				</td>
			</ng-container>

			<ng-container matColumnDef="ctr" class="mat-column-metrics">
				<th mat-header-cell *matHeaderCellDef class="mat-column-metrics">CTR<br />/<br />Viewable CTR</th>
				<td mat-cell *matCellDef="let element" class="mat-column-metrics">
					{{ baseService.getCTR(element) | number: '1.2-2'}} %<br />/<br />{{ baseService.getViewableCTR(element) | number: '1.2-2'}} %
				</td>
			</ng-container>

			<ng-container matColumnDef="action">
				<th mat-header-cell *matHeaderCellDef> Action </th>
				<td mat-cell *matCellDef="let element;">
					<native-ads-campaign-action [page]="page" (needRefresh)="refreshPage($event)" [campaignModel]="element.campaign"></native-ads-campaign-action>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="makeListingCol()" class="p-2"></tr>
			<tr mat-row *matRowDef="let row; columns: makeListingCol();" class="p-2"></tr>
		</table>
	</div>
</div>
<div class="row mb-4">
	<div class="col-12">
		<mat-paginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
			[showFirstLastButtons]="true" [pageIndex]="page" (page)="changePage($event)">
		</mat-paginator>
	</div>
</div>

<div class="row mb-3">
  <div class="col-12 text-left">
    <h1>Approval & merging for user defined field: {{ userDefinedList[0].value }}</h1>
  </div>
</div>
<div>
  <table mat-table [dataSource]="userDefinedList" class="mat-elevation-z8" style="width: 100%"> 
    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef> Value </th>
      <td mat-cell *matCellDef="let element"> {{element.value}} </td>
    </ng-container>
    <ng-container matColumnDef="displayName">
      <th mat-header-cell *matHeaderCellDef> Participant </th>
      <td mat-cell *matCellDef="let element"> {{element.participantSubmission.participant.firstName}} </td>
    </ng-container>
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.participantSubmission.participant.email}} </td>
    </ng-container>
    <ng-container matColumnDef="submissionDate">
      <th mat-header-cell *matHeaderCellDef> Submission Date </th>
      <td mat-cell *matCellDef="let element"> {{ element.participantSubmission.addedDate | date:'d MMMM y - HH:mm:ss a' }} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="userDefinedCol"></tr>
    <tr mat-row *matRowDef="let row; columns: userDefinedCol;"></tr>
  </table>
  
  <mat-card class="mat-elevation-z8 mt-3">
    <mat-card-content>
      <div class="row">
        <div class="col-9">
          <mat-form-field class="fullWidth" appearance="fill">
            <mat-label>Select an answer to merge or create new</mat-label>
            <mat-select [formControl]="questionAnswerControl" (selectionChange)="changeForm($event)">
              <mat-option selected></mat-option>
              <mat-option *ngFor="let questionAnswer of questionAnswerList" [value]="questionAnswer">
                {{ questionAnswer.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-3">
          <button mat-raised-button color="primary" class="m-2" (click)="createNew()">Create new</button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="mat-elevation-z8 mt-3">
    <mat-card-content>
      <div class="row">
        <div class="col-12">
          <app-question-answer-form [model]="loadedQuestionAnswerModel" [allowOptions]="false" [showGroup]="false"></app-question-answer-form>
        </div>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <div class="text-center">
        <div class="" style="color: red;">
          Once this new option is added, the survey page will immediately have this option available for selection. The option will be automatically ordered alphabetically in the selection list. If you wish for the option to be arranged differently, you will need to manually edit the arrangement and refresh the question again.
        </div>
        <button mat-raised-button color="primary" class="m-2" (click)="save()" [disabled]="savingForm">{{ buttonText }}</button>
        <div>
          {{ savingText }}
        </div>
        
      </div>
      
    </mat-card-actions>
  </mat-card>
</div>
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { asyncScheduler, concat, concatAll, filter, from, interval, Observable, observeOn, reduce, Subject, Subscribable, Subscription, take, zip } from 'rxjs';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { ICommonObject } from 'src/app/shared/interfaces/ICommonObject';
import { ICRUDPath } from 'src/app/shared/interfaces/iCRUDPath.interface';
import { environment } from 'src/environments/environment';
import { ICreateUserRequestBody } from '../interfaces/icreate-user-request-body';
import { IUserRequest } from '../interfaces/iuser-request';

@Injectable({
  providedIn: 'root'
})
export class UserRequestService extends CRUDService<IUserRequest> {

  list(params?: HttpParams): Observable<ICommonListingResponse<IUserRequest[]>> {
    return this.httpList(params);
  }
  get(id: string | number): Observable<IUserRequest> {
    throw new Error('Method not implemented.');
  }
  delete(id: string | number): Observable<IUserRequest> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: IUserRequest): Observable<IUserRequest> {
    throw new Error('Method not implemented.');
  }
  create(model: IUserRequest): Observable<IUserRequest> {
    return this.save(model);
  }
  makeFormGroup(loadedModel?: IUserRequest) {
    throw new Error('Method not implemented.');
  }

  saveAll(model: IUserRequest[]): Observable<IUserRequest[]> {
    return this.httpClient.post<IUserRequest[]>(this.createURL, model)
  }


  apiPath=environment.securityGuard.userRequestBase;
  environmentCRUDPath=environment.securityGuard;

  private _request: ICreateUserRequestBody[] = [];
  private intervalSub:Observable<number>

  constructor(public readonly httpClient: HttpClient, public readonly activatedRoute: ActivatedRoute, public readonly router: Router, public readonly fb: FormBuilder) {
    super();
    if(!this.intervalSub) {      
      this.intervalSub = interval(environment.securityGuard.timeInterval, asyncScheduler)
      this.intervalSub.subscribe(d=> {
        const unprocessed = this._request.filter((d, index, fullArray)=>!d.processed && !d.processing && index == this.findSimiliarRequestIndex(d, fullArray))
        if(unprocessed.length) {
          this.processingRequest(unprocessed)
          this.saveAll(unprocessed).subscribe(d=> {
            this.processedRequest(unprocessed)
          })
        }
      })
    }
  }

  findSimiliarRequestIndex(request:ICreateUserRequestBody, allRequest: ICreateUserRequestBody[],) {
    return allRequest.findIndex(f=>f.pathAccessing == request.pathAccessing && f.queryParams == request.queryParams && f.addedDate.isSame(request.addedDate))
  }

  makeSearchListGroup(searchParams?: ICommonObject) {
    const searchText = searchParams?.searchText as string || ""
    return this.fb.group({
      searchText: [decodeURIComponent(searchText)],
			activityType: [searchParams?.activityType || ["API", "Client", "Auth"]],
      startDate: [searchParams?.startDate?moment(searchParams.startDate as string):""],
      endDate: [searchParams?.endDate?moment(searchParams.endDate as string):""],
      services: [searchParams?.services || []],
      userID: [searchParams?.userID || null],
    })
  }

  private updateRequest(userRequest: ICreateUserRequestBody) {
    this._request = this._request.map(f=> {
      if(f.pathAccessing == userRequest.pathAccessing && f.queryParams == userRequest.queryParams && f.addedDate.isSame(userRequest.addedDate)) {
        return userRequest
      }
      return f
    })
  }

  private processingRequest(userRequest: ICreateUserRequestBody[]) {
    userRequest.map(u=>{
      u.processing = true
      this.updateRequest(u)
    })
  }

  private processedRequest(userRequest: ICreateUserRequestBody[]) {
    userRequest.map(u=>{
      u.processed = true
      this.updateRequest(u)
    })
  }

  public pushRequest(userRequest: ICreateUserRequestBody) {
    if(environment.securityGuard.ignoreDomains.findIndex((d: string)=>d.includes(userRequest?.domain)) < 0) {
      userRequest.addedDate = moment()
      userRequest.processed = false;
      userRequest.processing = false;
      userRequest.id = this._request.length + 1
      return this._request.push(userRequest)
    }
  }
  
}

import { Component, OnInit, ViewChild, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { Params, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingService } from '../../services/loading.service';
import { ICollection } from '../../interfaces/ICollection';
import { HttpParams } from '@angular/common/http';
import { SalonFinderCollectionService } from '../../services/salon-finder-collection.service';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';

@Component({
  selector: 'app-collection-index',
  templateUrl: './collection-index.component.html',
  styleUrls: ['./collection-index.component.scss']
})

export class CollectionIndexComponent extends ListingComponent<ICollection> implements OnInit, OnChanges {
  id = '';

  results: ICollection[];
  dataSource!: MatTableDataSource<ICollection>;
  formList: ICollection[];
  // total: number;
  currentDate: Date = new Date();
  ended: string;
  loading$ = this.loader.loading$;
  
  selection = new SelectionModel<ICollection>(true, []);
  dataLoaded = false;
  
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    private readonly salonFinderCollectionService: SalonFinderCollectionService,
    private router: Router,
    private readonly snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    public loader: LoadingService
  ) {
    super();
    this.listingCols = [
      'collectionName',
      'service',
      'date',
      'featured',
      'status',
      'active',
      'action'
    ];
  }

  populateList(params?: HttpParams | Params): void {
    this.salonFinderCollectionService
    .list(this.makeSearchParams({
      searchText: this.searchText
    }))
    .subscribe((result) => {
      this.initCommonListingResponse(result);
    });
  }

  getExtraListingParams() {
    throw new Error('Method not implemented.');
  }

  deleteItem(item: ICollection): void {
    throw new Error('Method not implemented.');
  }

  getCollectionById(id: any) {
    this.dataLoaded = true;
    this.salonFinderCollectionService.get(id).subscribe((data: any) => {
      this.id = data.id;
    });
  }

  deactivate(id, value) {
    this.salonFinderCollectionService.update(id, value)
      .subscribe(
        response => {
          // console.log('active:' + response.active);
          if (response.active === true) {
            this.snackBar.open('Collection has been activated successfully!', 'Close', {
              duration: 2000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
          } if (response.active === false) {
            this.snackBar.open('Collection has been deactivated successfully!', 'Close', {
              duration: 2000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
          }

          this.ngOnInit();
        },
        error => {
          this.snackBar.open('Unable to activated/deactivated collection!', 'Close', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
        }
      );
  }

  ngOnInit() {
    this.populateList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.populateList();
  }
}
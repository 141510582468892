import { Component, OnInit, Inject} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import FileSaver from 'file-saver';
import moment from 'moment';
import { ConsumerReportFormService } from 'src/app/consumer-report/services/consumer-report-form.service';
import { ConsumerReportParticipantSubmissionService } from 'src/app/consumer-report/services/consumer-report-participant-submission.service';
import { IConfirmDialogData } from '../../interfaces/iConfirmDialogData.interface';

@Component({
  selector: 'export-file-dialog',
  templateUrl: './export-file-dialog.component.html',
  styleUrls: ['./export-file-dialog.component.scss']
})
export class ExportFileDialogComponent implements OnInit {

  selectedForm;
  generateReport = false;
  loadingForms = true;
  forms = [];
  constructor(
    public dialogRef: MatDialogRef<ExportFileDialogComponent>,
    private readonly consumerReportFormService: ConsumerReportFormService,
    private readonly participantSubmissionService : ConsumerReportParticipantSubmissionService,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    { }
    

  ngOnInit() {
    this.listForm();
  }

  generate(){
    if(this.selectedForm !== undefined){
      this.generateReport = true;
      let form = this.forms.find((form:any) => form.id === this.selectedForm);
      this.participantSubmissionService.getAllParticipantByForm(this.selectedForm).subscribe((data:any) => {
        if(data.length > 0){
          import("xlsx").then(xlsx => {
            this.generateReport = false; 
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            this.saveAsExcelFile(excelBuffer, form.name);
          });
        }     
      })
    }
  }

  saveAsExcelFile(buffer: any,name:string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data,  name+"-"+moment().format('YYYY-MM-DD') + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  listForm() {
    this.loadingForms = true;
    this.consumerReportFormService.listForm({
      page: 0,
      limit: 15,
      searchText: ""
    })
      .subscribe(r => {
        this.forms = r.data.sort(function(a:any,b:any){
          return new Date(b.createdDate).valueOf() - new Date(a.createdDate).valueOf();
        });
        this.loadingForms = false;
      })
  }
}


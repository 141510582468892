import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-form-duplicate',
  templateUrl: './form-duplicate.component.html',
  styleUrls: ['./form-duplicate.component.scss']
})
export class FormDuplicateComponent implements OnInit {

  savingForm = false;

  duplicateForm: FormGroup = new FormGroup({
    confirmField: new FormControl('', [
      Validators.pattern(/confirm/i),
      Validators.required
    ])
  });


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<FormDuplicateComponent>,
  
  ) { 
    console.log("Form Duplicate",this.data)
  }

  get formControls() { return  this.duplicateForm.controls; }


  ngOnInit(): void {
  }

  closeForm() {
    this.dialogRef.close(true);
  }

  renderDuplicateForm() {
    this.dialogRef.close(this.data);  
  }


}

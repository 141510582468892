import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ListingService } from '../abstracts/listing-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { IParticipantDefinedValue } from '../interfaces/iparticipant-defined-value';
import { IParticipantSubmission } from '../interfaces/iparticipant-submission';
import { IParticipantSubmissionCode } from '../interfaces/iparticipant-submission-code';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { Router, ActivatedRoute } from '@angular/router';
import { ICRUDPath } from 'src/app/shared/interfaces/iCRUDPath.interface';
import { Observable } from 'rxjs';
import { ICommonObject } from 'src/app/shared/interfaces/ICommonObject';

@Injectable({
  providedIn: 'root'
})
export class ConsumerReportParticipantSubmissionService  extends CRUDService<IParticipantSubmission> {
  makeSearchListGroup(defaultParams?: ICommonObject) {
    throw new Error('Method not implemented.');
  }
  
  apiPath = environment.consumerReport.participantSubmissionBase;
  environmentCRUDPath = environment.consumerReport;

  baseURL = environment.consumerReport.baseURL + environment.consumerReport.participantSubmissionBase

  constructor(
    public readonly httpClient: HttpClient,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute
  ) {
    super();
  }
  
  list(params?: HttpParams) {
    return this.httpList(params)
  }
  get(id: string | number): Observable<IParticipantSubmission> {
    throw new Error('Method not implemented.');
  }
  delete(id: string | number): Observable<IParticipantSubmission> {
    return this.httpDelete(id.toString())
  }
  update(id: string | number, model: IParticipantSubmission): Observable<IParticipantSubmission> {
    throw new Error('Method not implemented.');
  }
  create(model: IParticipantSubmission): Observable<IParticipantSubmission> {
    throw new Error('Method not implemented.');
  }
  makeFormGroup(loadedModel?: IParticipantSubmission) {
    throw new Error('Method not implemented.');
  }

  getAllParticipantByForm(id: string | number) {
    return this.httpClient.get<ICommonListingResponse<any[]>>(this.baseURL+environment.consumerReport.participantSubmissionByForm +'/'+ id);
  }

  generateEditLink(id: string) {
    return this.httpClient.post<ICommonListingResponse<IParticipantSubmissionCode>>(this.baseURL+environment.consumerReport.participantSubmissionGenerateTempLink + '/' + id, {
      
    })
  }

  forceReprocess(id: string) {
    return this.httpClient.post<ICommonListingResponse<IParticipantSubmission>>(this.baseURL+environment.consumerReport.participantSubmissionReProcessing + '/' + id, {
      
    })
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { ICRUDPath } from 'src/app/shared/interfaces/iCRUDPath.interface';
import { environment } from 'src/environments/environment';
import { IQuestion } from '../interfaces/question';
import { IQuestionList } from '../interfaces/question-list';

@Injectable({
  providedIn: 'root'
})
export class ConsumerReportQuestionService extends CRUDService<IQuestion> {
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }
  list(params?: HttpParams): Observable<ICommonListingResponse<IQuestion[]>> {
    return this.httpList(params)
  }
  get(id: string | number): Observable<IQuestion> {
    return this.httpGet(id.toString())
  }
  delete(id: string | number): Observable<IQuestion> {
    return this.httpClient.delete<IQuestion>(this.deleteURL+'/'+id)
  }
  update(id: string | number, model: IQuestion): Observable<IQuestion> {
    model.id = +id
    return this.save(model, id)
  }
  create(model: IQuestion): Observable<IQuestion> {
    return this.save(model)
  }
  makeFormGroup(loadedModel?: IQuestion) {
    throw new Error('Method not implemented.');
  }
  apiPath = environment.consumerReport.questionBase
  environmentCRUDPath = environment.consumerReport

  constructor(
    public override readonly httpClient: HttpClient,
    public override readonly router: Router,
    public override readonly activatedRoute: ActivatedRoute
  ) {
    super();
  }
}

<div class="row mb-3">
	<div class="col-12 text-right">
		<button mat-raised-button color="primary" [routerLink]="['/security-guard/service/create']">Add New Services</button>
	</div>
</div>					

<form [formGroup]="searchFormGroup" class="row mb-3">
	<div class="col-12">
		<mat-card>
			<mat-card-content>
				<div class="row">
					<mat-form-field class="col-12" appearance="fill">
						<input type="text" placeholder="Type anything to search service" aria-label="Type anything to search service"
							matInput appearance="fill" autocomplete="off" formControlName='searchText'>
					</mat-form-field>
				</div>
				<div class="row">
					<div class="col-12">
						<button mat-raised-button color="primary" class="m-2" (click)="populateList()" [disabled]="isLoading">Search</button>
					</div>
				</div>
				
			</mat-card-content>
		</mat-card>
	</div>
</form>
<div class="row">
	<div class="col-12">
		<table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
			<ng-container matColumnDef="name">
				<th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
				<td mat-cell *matCellDef="let element">
					{{ element.name }}
				</td>
			</ng-container>
			<ng-container matColumnDef="fqdn">
				<th mat-header-cell mat-sort-header *matHeaderCellDef>Domain</th>
				<td mat-cell *matCellDef="let element">
					{{ element.fqdn }}
				</td>
			</ng-container>
			

			<ng-container matColumnDef="lastActivityDate">
				<th mat-header-cell *matHeaderCellDef>Last Activity Date</th>
				<td mat-cell *matCellDef="let element">
					{{ element.createdDate | utcdate:'d MMM YYYY hh:mm:ss a' }}
				</td>
			</ng-container>


			<ng-container matColumnDef="action">
				<th mat-header-cell *matHeaderCellDef> Action </th>
				<td mat-cell *matCellDef="let element;">
					<span>
						<a mat-icon-button color="primary" routerLink="/security-guards/service/{{element.id}}">
							<mat-icon matTooltip="Edit" matTooltipPosition="left">edit</mat-icon>
						</a>
						<a mat-icon-button color="warn" (click)="deleteItem(element)" [hidden]="element.active===0">
							<mat-icon matTooltip="Deactivate" matTooltipPosition="right">delete</mat-icon>
						</a>
					</span>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="listingCols" class="p-2"></tr>
			<tr mat-row *matRowDef="let row; columns: listingCols;" class="p-2"></tr>
		</table>
	</div>
</div>
<div class="row mb-4">
	<div class="col-12">
		<mat-paginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
			[showFirstLastButtons]="true" [pageIndex]="page" (page)="changePage($event)">
		</mat-paginator>
	</div>
</div>
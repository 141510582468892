import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { FormComponent } from 'src/app/shared/abstracts/form-component';
import { IEmailNotification } from '../../interfaces/iemail-notification';
import { SalonFinderEmailNotificationService } from '../../services/salon-finder-email-notification.service';

@Component({
  selector: 'salon-finder-email-notification-form',
  templateUrl: './email-notification-form.component.html',
  styleUrls: ['./email-notification-form.component.scss']
})
export class EmailNotificationFormComponent extends FormComponent<IEmailNotification, SalonFinderEmailNotificationService> implements OnInit, OnChanges {
  save(): void {
    this.savingForm = true
    if(this.formErrors().length>0) {
      this.snackBar.open('Please fix all errors in the form', 'Close', {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      this.savingForm = false
    } else {
      let formValue = this.formData.value;
      this.baseService.save(formValue, this.loadedModel?.id)
      .subscribe(response=>
        {
          this.savingForm = false;
          this.loadedModel = response;
          this.snackBar.open('Template has been saved successfully!', 'Close', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
          this.router.navigate([`/salon-finder/email-notification`])
        },
        error => {
          this.savingForm = false;
          this.snackBar.open('Unable to save Template!', 'Close', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
          // this.initForm();
        }
      );
    }
  }

  constructor(
    public readonly baseService: SalonFinderEmailNotificationService,
    private readonly snackBar: MatSnackBar,
    private router: Router
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.loadedModel) {
      this.initForm()
    }
  }

  ngOnInit(): void {
    this.initForm()
  }

}

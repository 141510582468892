import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IProfileToken } from 'src/app/auth/interfaces/profileToken.interface';
import { UniversalValidators} from 'ngx-validators'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FieldsCompareValidator } from '../../validators/FieldsCompareValidator';
import { UserPasswordValidator } from '../../helpers/UserPasswordValidator';
import { UserNotFoundException } from '@aws-sdk/client-cognito-identity-provider';

@Component({
  selector: 'darvis-share-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

  public passwordForm: FormGroup = null
  savingForm = false;
  hideOldPassword = true;
  hideNewPassword = true;



  get formControls() {
    return this.passwordForm.controls
  }

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      displayName: string,
      cognitoUserName: string
    },
    private readonly fb: FormBuilder,
    private readonly authService: AuthService,
    private readonly snapBar: MatSnackBar
  
    ) { }
  
    ngOnInit() {
      if(!this.data.cognitoUserName) {
        this.passwordForm = this.fb.group({
          oldPassword: ["", [
            Validators.required,
            UniversalValidators.noWhitespace
          ]],
          repeatOldPassword: ["", [
            Validators.required
          ]],
          newPassword: ["", UserPasswordValidator],
          repeatNewPassword: ["", [
            Validators.required
          ]],
        }, {
          validators: [
            FieldsCompareValidator('oldPassword', 'repeatOldPassword'), 
            FieldsCompareValidator('newPassword', 'repeatNewPassword')
          ]
        })
      } else {
        this.passwordForm = this.fb.group({
          newPassword: ["", UserPasswordValidator],
          repeatNewPassword: ["", [
            Validators.required
          ]],
        }, {
          validators: [
            FieldsCompareValidator('newPassword', 'repeatNewPassword')
          ]
        })
      }      
    }

    closeForm(response: boolean) {
      this.dialogRef.close(response);
    }

    async changePassword() {
      this.savingForm = true;
      this.passwordForm.markAllAsTouched();
      this.passwordForm.updateValueAndValidity();
      if(this.passwordForm.status != "VALID") return false;
      const formData = this.passwordForm.value;
      try {
        if(this.data.cognitoUserName) 
          await this.authService.changePassword(formData.newPassword, this.data.cognitoUserName);
        else
          await this.authService.userUpdatePassword(formData.oldPassword, formData.newPassword)
        this.snapBar.open("Password changed successfully", "Close", {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 5000})
        this.dialogRef.close(true);
      } catch(err: any) {
        if(!this.data.cognitoUserName)
          this.passwordForm.controls.oldPassword.setErrors({password: "Incorrect password"})
        else 
          this.passwordForm.controls.newPassword.setErrors({password: err.message})
      }
      return false;
    }

}

import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ReportsFormLayoutDialogComponent } from '../reports-form-layout-dialog/reports-form-layout-dialog.component';
import { Observable, Subject, Subscription, catchError, debounceTime, map, skip, startWith } from 'rxjs';
import { ConsumerReportFormQuestionService } from '../../services/consumer-report-form-question.service';
import { ConsumerReportSurveyConfigService } from '../../services/consumer-report-survey-config.service';
import { ConfigCompare } from '../../constant/StaticReportConfig';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ConsumerReportFormQuestionAnswerService } from '../../services/consumer-report-form-question-answer.service';
import { HttpParams } from '@angular/common/http';
import { IFormQuestion } from '../../interfaces/form-question';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-reports-form-question-answers',
  templateUrl: './reports-form-question-answers.component.html',
  styleUrls: ['./reports-form-question-answers.component.scss']
})
export class ReportsFormQuestionAnswersComponent implements OnInit, OnChanges {

  @Input() answerRowNotifier: Subject<boolean>;

  @ViewChild('table') table: MatTable<any>;

  @ViewChild('panel') panel: MatExpansionPanel;


  @Input("displayType")
  displayType: string;

  @Input("generalInformationForm")
  generalInformationForm: FormGroup

  @Input("graphQuestionAnswers")
  graphQuestionAnswers: FormArray;
  @Output() graphQuestionAnswersChange = new EventEmitter<{answer : FormArray, graphID: FormControl, graphQuestionUUID: FormControl   }>();

  @Input("graphQuestionUUID")
  graphQuestionUUID: FormControl;

  @Input("graphID")
  graphID: FormControl;


  @Input("deletedAnswers")
  deletedAnswers: FormArray;

  @Input("selectedMatrics")
  selectedMatrics: FormControl;

  @Input("matrices")
  matrices: FormControl;

  @Input("openPanelStateAnswer")
  openPanelStateAnswer: FormControl;
  
  
  @Output() 
  renderAllAnswer: EventEmitter<string> = new EventEmitter<string>();

  panelAnswerOpenState = false;

  isLoading = false;

  renderingAnswers = false;

  displayedColumns: string[] = ['order', 'answers', 'compare', 'matrics', 'action'];

  allSelectedAnswer = false;

  filterQuestionAnswersOptions: any[] = [];

  graphAnswersInformation: FormGroup

  defaultAnswers: any[] = [];

  defaultMatrices: any[] = [];

  compares = ConfigCompare;

  questionAnswerFormgroups: FormArray;

  filteredAnswersOptions: Observable<any[]>;

 @Input("generateAnswer")
  private generateAnswer: boolean;
  
  constructor(
    private formBuilder: FormBuilder,
    private readonly dialog: MatDialog,
    private consumerReportFormQuestionService: ConsumerReportFormQuestionService,
    private consumerReportSurveyConfig: ConsumerReportSurveyConfigService,
    private consumerReportFormQuestionAnswerService: ConsumerReportFormQuestionAnswerService,
    private cdr: ChangeDetectorRef,
    private readonly snackBar: MatSnackBar,
  ) {

    
  }

  async ngOnInit() {

    if (!this.generalInformationForm.get("saveFailed").value) {

      if (this.graphQuestionAnswers) {
        this.graphQuestionUUID.valueChanges.pipe(
          startWith(''),
          debounceTime(100),
          skip(1)
        ).subscribe(async (value: any) => {
          this.getQuestionAnswers()  .subscribe(result => {
          this.isLoading = false;
          this.graphQuestionAnswers.clear();
          this.graphQuestionAnswers.push(this.consumerReportSurveyConfig.makeGraphAnswerGroup());
        })

        })
      }

      this.isLoading = true;
      if (this.graphQuestionUUID.value != "") {
        this.getQuestionAnswers()
        .subscribe(result => {
          this.isLoading = false;
        })

      }


      this.initialiizeSubAnswers();

    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['generateAnswer']) {
      if(changes['generateAnswer'].currentValue && this.panel != undefined){ 
        this.panel.close();
      };
    }
  }


  getQuestionAnswers(): Observable<IFormQuestion[]> {
    return this.consumerReportFormQuestionService.getFormQuestionByUUID(this.graphQuestionUUID.value)
      .pipe(
        map(response => {
          for (let res of response) {
            this.defaultAnswers = res.answers;
            this.defaultAnswers.unshift({
              id: 'select-all',
              uuid: 'select-all',
              label: 'Select All'
            });
            this.filterQuestionAnswersOptions = res.answers;
            this.defaultMatrices = this.matrices.value;
          }
          return response;
        }),
        catchError(err => {
          return [];
        })
      );
  }

  async closePanelAnswer(){

    this.openPanelStateAnswer.setValue(false);
    return this.openPanelStateAnswer.value;
  }

  async openPanelAnswer() {
   
    if(this.graphQuestionUUID.value == ""){
      this.openPanelStateAnswer.setValue(false);
      this.snackBar.open('Please select question', 'Close', {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    }else{
      this.renderingAnswers = true;
      setTimeout(() => {
        this.renderQuestionAnswer();  
        this.openPanelStateAnswer.setValue(true);
      },500);

    }
   
  }

  async renderQuestionAnswer() {
    let graphsQuestionAnswers =  this.graphAnswersInformation.get('graphsQuestionAnswers') as FormArray ;
    
    //open the answer w/o all ans is generated
    if(this.graphID.value == 0 && !this.generateAnswer && graphsQuestionAnswers.value != null  && graphsQuestionAnswers.value.length == 1){
        this.graphQuestionAnswers = this.formBuilder.array([this.consumerReportSurveyConfig.makeGraphAnswerGroup()]);
        this.graphAnswersInformation.markAllAsTouched();
        this.graphAnswersInformation.updateValueAndValidity();
        this.graphAnswersInformation.get('graphsQuestionAnswers').markAllAsTouched();
        this.graphAnswersInformation.get('graphsQuestionAnswers').updateValueAndValidity();
        this.filterOptions();
        this.openPanelStateAnswer.setValue(true);
        this.renderingAnswers = false;
    }

    //open the answer with initials answers already.
    if((this.graphID.value != 0 && !this.generateAnswer) || (this.graphID.value != 0 && graphsQuestionAnswers.value.length == 0 )  ){
      let params = {
        graphID: this.graphID.value,
        limit: -1
      } as unknown as HttpParams;
  
      this.consumerReportSurveyConfig.listConfigAnswers(params)
        .subscribe(response => {
          
          

          let subAnswers = [];
          for (let fqAnswer of response.data) {
            let selectedAnswerDetails = [];
            let selectedAnswers = [];
            let selectedMatrics = []
            if (fqAnswer.selectedAnswerDetails != "") {
  
              selectedAnswerDetails = JSON.parse(fqAnswer.selectedAnswerDetails);
              if (fqAnswer.selectedAnswerDetails.length > 0 && selectedAnswerDetails.length > 0) {
                for (let answer of selectedAnswerDetails) {
                  selectedAnswers.push({
                    label: answer.label,
                    uuid: answer.questionAnswerUUID
                  });
                  selectedMatrics.push(answer.questionAnswerMatricUUID);
                }
              }
            }
            let displayConfig = {
              bgColor: fqAnswer.bgColor,
              customName: fqAnswer.customName,
              displayPercentage: fqAnswer.displayPercentage,
              displayWidth: fqAnswer.displayWidth,
              icon: fqAnswer.icon
  
            };
  
            subAnswers.push({
              "customName": fqAnswer.customName,
              "selectedAnswers": selectedAnswers,
              "selectedMatrics": selectedMatrics.length > 0 ? selectedMatrics[0] : null,
              "displayTypeConfig": displayConfig,
              "uuid": fqAnswer.uuid,
              "dependencies": [],
              "answers": this.filterQuestionAnswersOptions,
              "id": fqAnswer.id,
              "order": fqAnswer.order,
              "matrices": this.matrices.value,
              "compare": fqAnswer.compare
            })
  
          }

          let questionAnswersFormArray = [];
          if (subAnswers.length > 0) {

            for (let ans of subAnswers) {
              questionAnswersFormArray.push(this.consumerReportSurveyConfig.makeGraphAnswerGroup(ans));
              if(graphsQuestionAnswers.value == null){
                graphsQuestionAnswers = this.formBuilder.array([...questionAnswersFormArray]);
              }else{
                graphsQuestionAnswers.controls.push(this.consumerReportSurveyConfig.makeGraphAnswerGroup(ans));
                graphsQuestionAnswers.value.push(ans);
              }
              
            }

      
          } else {
            this.graphQuestionAnswers = this.formBuilder.array([this.consumerReportSurveyConfig.makeGraphAnswerGroup()])
          }
          
           this.graphQuestionAnswers = this.formBuilder.array([...questionAnswersFormArray])
       
           this.graphAnswersInformation = this.formBuilder.group({
            graphsQuestionAnswers: this.graphQuestionAnswers,
          })

          this.graphAnswersInformation.markAllAsTouched();
          this.graphAnswersInformation.updateValueAndValidity();
          this.graphAnswersInformation.get('graphsQuestionAnswers').markAllAsTouched();
          this.graphAnswersInformation.get('graphsQuestionAnswers').updateValueAndValidity();
          this.filterOptions();
          
          this.openPanelStateAnswer.setValue(true);
          this.panelAnswerOpenState = true;
          
          this.renderingAnswers = false;
        },error =>{
          console.log("Error renderQuestionAnswer: ", error);
          this.renderingAnswers = false;
        })
    }

    //open the answer w all ans is generated
    if(this.generateAnswer && graphsQuestionAnswers.value.length > 1 ){
      this.graphQuestionAnswers = graphsQuestionAnswers;

      this.graphAnswersInformation.markAllAsTouched();
      this.graphAnswersInformation.updateValueAndValidity();
      this.graphAnswersInformation.get('graphsQuestionAnswers').markAllAsTouched();
      this.graphAnswersInformation.get('graphsQuestionAnswers').updateValueAndValidity();
      this.filterOptions();
      this.renderingAnswers = false;
      this.openPanelStateAnswer.setValue(true);
      
    }

    //re-open the new generated answers
    if(this.graphID.value == 0 && !this.generateAnswer && graphsQuestionAnswers.value.length > 1){
      this.openPanelStateAnswer.setValue(true);
      this.renderingAnswers = false;
    }  

    this.graphQuestionAnswersChange.emit({
      answer: this.graphQuestionAnswers,
      graphID: this.graphID,
      graphQuestionUUID: this.graphQuestionUUID
    });
  }

  initialiizeSubAnswers() {
    this.graphAnswersInformation = this.formBuilder.group({
      graphsQuestionAnswers: this.graphQuestionAnswers,
    })
    this.filterOptions();
    this.isLoading = false;
  }

  filterOptions() {
    if (this.graphQuestionAnswers) {

      if(this.graphAnswersInformation.get('graphsQuestionAnswers').value != null){
        this.filteredAnswersOptions = this.graphAnswersInformation.get('graphsQuestionAnswers')['controls'].map((control: any, index: number) =>
        control.valueChanges.pipe(
          startWith(''),
          map((searchValue: string) => {
            return this._filter(searchValue || '');
          })
        ));

      }
    }
  }

  private _filter(searchValue: any): any[] {
    
    if (typeof searchValue === 'object' && searchValue.answer != null) {
      let searchSimilarAnswers = this.filterQuestionAnswersOptions.filter(option => option.label.toLowerCase().includes(searchValue.answer.toLowerCase()))
      if (searchSimilarAnswers.length > 20) {
        return searchSimilarAnswers.slice(0, 20);
      } else {
        return searchSimilarAnswers;
      }
    } else {
      return this.filterQuestionAnswersOptions.slice(0, 20);
    }
  }


  addRowAnswerGI() {
    var graphsQuestionAnswers = this.graphAnswersInformation.get("graphsQuestionAnswers") as FormArray;
    this.graphAnswersInformation.get('graphsQuestionAnswers')['controls'].push(this.consumerReportSurveyConfig.makeGraphAnswerGroup({ order: graphsQuestionAnswers.value.length + 1 }))
   
    this.graphAnswersInformation.markAllAsTouched();
    this.graphAnswersInformation.updateValueAndValidity();
    this.graphAnswersInformation.get('graphsQuestionAnswers').markAllAsTouched();
    this.graphAnswersInformation.get('graphsQuestionAnswers').updateValueAndValidity();

    this.table.dataSource = graphsQuestionAnswers.controls;
    this.table.renderRows()

    this.filterOptions();
    
  }

  deleteRowAnswerGI(element: FormGroup, index: number) {

    if (element.get('id').value > 0) {
      this.deletedAnswers.value.push(element.get('id').value);
    }

    this.graphQuestionAnswers['controls'].splice(index, 1);
    this.graphQuestionAnswers.value.splice(index, 1);
    this.table.renderRows();
  }

  showConfigLayout(answer: FormGroup) {
    let displayTypeConfig = answer.get('displayTypeConfig');
    const dialogRef = this.dialog.open(ReportsFormLayoutDialogComponent, {
      width: '500px',
      data: {
        content: displayTypeConfig.value,
        displayType: this.displayType

      }
    });
    dialogRef.afterClosed().subscribe(v => {
      if (v != '') {
        answer.value.displayTypeConfig = v;
        answer.controls.displayTypeConfig.setValue(v)
        
        const graphsQuestionAnswers = this.graphAnswersInformation.get('graphsQuestionAnswers') as FormArray;
    
        this.graphQuestionAnswersChange.emit({
          answer: graphsQuestionAnswers,
          graphID: this.graphID,
          graphQuestionUUID: this.graphQuestionUUID
        });
      }
    }, error => {
      console.log("Error:", error);
    });
  }

  changeMatric(event, selectedMatric: FormControl) {
    selectedMatric.patchValue(event.value)
    this.graphAnswersInformation.markAllAsTouched();
    this.graphAnswersInformation.updateValueAndValidity();
    this.graphAnswersInformation.get('graphsQuestionAnswers').markAllAsTouched();
    this.graphAnswersInformation.get('graphsQuestionAnswers').updateValueAndValidity();

  }

  remove(index: number, ansUUID: string, element: FormGroup): void {
    element.get('selectedAnswers').value.splice(index, 1);
    element.get('answer').setValue('');
  }

  checkIfAnswerAlreadySelected(uuid: string, element: FormGroup) {
    let totalNumberAnswers = this.filterQuestionAnswersOptions.length - 1;
    if (element.get('selectedAnswers').value.length == totalNumberAnswers) {
      return true;
    }

    return Boolean(element.get('selectedAnswers').value.find(sa => sa.uuid == uuid));
  }

  selected(event: MatAutocompleteSelectedEvent, element: FormGroup, index: number): void {
    const selectedAnswers = element.get('selectedAnswers');
  
    if (event.option.id == 'select-all') {
      const selectedOptions = this.filterQuestionAnswersOptions
        .filter(ans => ans.uuid !== 'select-all')
        .map(ans => ({ label: ans.label, uuid: ans.uuid }));
  
      selectedAnswers.patchValue([...selectedOptions]);
    } else {
      const selectedOption = { label: event.option.value, uuid: event.option.id };
      const currentSelections = selectedAnswers.value || [];
      selectedAnswers.patchValue([...currentSelections, selectedOption]);
    }
  
    element.clearValidators();
    element.setValidators(Validators.required);
    element.markAllAsTouched();
    element.updateValueAndValidity();
    element.get('answer').setValue('');
  
    const graphsQuestionAnswers = this.graphAnswersInformation.get('graphsQuestionAnswers') as FormArray;
    
    graphsQuestionAnswers.at(index).setValue(element.value);

    this.graphQuestionAnswersChange.emit({
      answer: graphsQuestionAnswers,
      graphID: this.graphID,
      graphQuestionUUID: this.graphQuestionUUID
    });

    graphsQuestionAnswers.markAllAsTouched();
    graphsQuestionAnswers.updateValueAndValidity();
  }

} 

import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { IFormQuestion } from '../../interfaces/form-question';
import { IParticipantDefinedValue } from '../../interfaces/iparticipant-defined-value';
import { IParticipantSubmission } from '../../interfaces/iparticipant-submission';
import { IParticipantSubmittedValue } from '../../interfaces/iparticipant-submitted-value';
import { ConsumerReportFormQuestionService } from '../../services/consumer-report-form-question.service';
import { ConsumerReportParticipantSubmissionService } from '../../services/consumer-report-participant-submission.service';
import { ConsumerReportParticipantUserDefinedValueService } from '../../services/consumer-report-participant-user-defined-value.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-form-userdefined-index',
  templateUrl: './form-userdefined-index.component.html',
  styleUrls: ['./form-userdefined-index.component.scss']
})
export class FormUserdefinedIndexComponent implements OnInit {

  questionTableListing: {
    question: IFormQuestion
    selection:SelectionModel<IParticipantDefinedValue>
  }[] = []

  displayedColumns = [
    'selection',
    'value',
    'count',
    'actions'
  ]

  constructor(
    private readonly participantDefinedValueService: ConsumerReportParticipantUserDefinedValueService,
    private readonly formQuestionService: ConsumerReportFormQuestionService,
    private readonly consumerReportParticipantSubmissionService: ConsumerReportParticipantSubmissionService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { 
    combineLatest([this.route.params, this.route.queryParams])
      .subscribe(([pathParams, queryParams]) => {
      this.formQuestionService.listUserDefinedByFormID(pathParams.formID)
        .subscribe(d=> {
          for(const formQuestion of d.data) {
            this.questionTableListing.push({
              question: this.formatFormQuestion(formQuestion),
              selection: new SelectionModel<IParticipantDefinedValue>(true, [])
            })
          }
        })
    })
  }

  formatFormQuestion(formQuestion: IFormQuestion) {
    formQuestion['formDefinedValues'] = this.getUniqueDefinedValueBySubmittedValue(formQuestion.formSubmittedValues).sort((a,b) => a.value.localeCompare(b.value))
    return formQuestion
  }


  addToList(question: IFormQuestion) {    
    const questionTable = this.questionTableListing.find(qt=>qt.question.id === question.id)
    let selectedValues = questionTable.selection.selected.map(s=>s.id)
    for(const selectedValue of questionTable.selection.selected) {
      selectedValues = selectedValues.concat(selectedValue.related?.map(r=>r.id))
    }

    this.router.navigate(['consumer-report/form/user-defined/create'], {
      queryParams: {
        userDefinedIDs: selectedValues.filter((sv, index, a) => a.findIndex(v=>v === sv) === index),
        questionID: question.questionID,
        formID: question.formID
      }
    })
  }

  duplicateValue(item: IParticipantDefinedValue[], formQuestionID: number) {
    if(window.confirm('Are you sure you want to duplicate this answer: '+ item[0].value)){      
      const reprocessingSubmissions = item.map(i=> this.participantDefinedValueService
        .duplicateDefinedValue(i.id.toString()))
      
      forkJoin(reprocessingSubmissions)
        .pipe(switchMap(d => {
          return this.formQuestionService.listUserDefined(formQuestionID)
        }))
        .subscribe(d=> {
          this.questionTableListing = this.questionTableListing.map(q=> {
            if(q.question.id === d.data.id) {
              return {
                question: this.formatFormQuestion(d.data),
                selection: new SelectionModel<IParticipantDefinedValue>(true, [])
              }
            }
            return q
          })
          this.snackBar.open("Value duplicated", "Close", {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          })
        })
    }
    
  }

  getUniqueDefinedValueBySubmittedValue(submittedValues: IParticipantSubmittedValue[]) {
    let definedValues: IParticipantDefinedValue[] = []
    for(const submittedValue of submittedValues) {
      definedValues = definedValues.concat(submittedValue.participantDefinedValue.map(dv=>{
        return {
          ...dv,
          value: dv.value.trim()
        }
      }))
    }
    let returnDefinedValues = definedValues.filter((item, i, ar) => ar.findIndex(v=>v.value.toUpperCase() === item.value.toUpperCase()) === i)
    return returnDefinedValues.map(dv=>{
      const related = definedValues.filter(vv=>vv.value.toUpperCase() === dv.value.toUpperCase())
      return {
        ...dv,
        count: related.length,
        related: related,
        submissions: related.map(r=>r.participantSubmissionUUID)
      }
    })
  }

  ngOnInit(): void {
    
  }

}

import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import moment from 'moment';
import { ICampaign } from '../../interfaces/campaign';
import { NativeAdsCampaignItemService } from '../../services/native-ads-campaign-item.service';

@Component({
  selector: 'app-campaign-create',
  templateUrl: './campaign-create.component.html',
  styleUrls: ['./campaign-create.component.scss']
})
export class CampaignCreateComponent implements OnInit {

  loadedModel: ICampaign = {
    id: null,
    name: "",
    clientID: 0,
    clientName: "",    
    statusID: 0,
    doABTest: false,
    startDate: moment().clone().set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0),
    endDate: moment().clone().add(1, 'M').set('hour', 23).set('minutes', 59).set('seconds', 59).set('millisecond', 0),
    priority: 1,
    campaignItems: [{
			id: null,      
      typeID: 0,
      title: "",
      imageURL: "",
      linkURL: "",
      description: "",
      clickToActionText: "",
      iframe: false,
      costPerCPM: 0,
      impressionPurchased: null,
      totalCost: 0,
      achievedImpression: 0,
      testImpression: 0,      
      statusID: 0,
      active: true,
      freeCampaign: false
		}]
  }

  constructor(
		private campaignItemService: NativeAdsCampaignItemService
	) { }

  ngOnInit(): void {
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IRedeemedVoucher } from '../interfaces/iredeemed-voucher';
import { of, Observable, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SalonFinderFinanceService {

  constructor(private httpClient: HttpClient) { }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  // get all data
  getFinances(data:any):Observable<any>
  {
    return this.httpClient.post(environment.salonFinder.baseURL + environment.salonFinder.finance, data)
    .pipe(
      catchError( error => {
        return throwError( 'Something went wrong!' );
      })
    )
  }

    // get calculation
    getCalculation(data:any):Observable<any>
    {
      return this.httpClient.post(environment.salonFinder.baseURL + environment.salonFinder.finance + environment.salonFinder.calculate, data)
      .pipe(
        catchError( error => {
          return throwError( 'Something went wrong!' );
        })
      )
    }

  // create data
  createData(data:any):Observable<any>
  {
    // console.log(data);
    return this.httpClient.post(environment.salonFinder.baseURL + environment.salonFinder.advertisers + '/', data);
  }

  // create data entity
  saveEntity(entity:any):Observable<any>
  {
    // console.log(data);
    return this.httpClient.post(environment.salonFinder.baseURL + environment.salonFinder.entities + '/', entity);
  }

  // update data
  updateData(id:any, data:any):Observable<any>
  {
    return this.httpClient.post(environment.salonFinder.baseURL + environment.salonFinder.advertisers + '/' + id, data);
  }

  // get single data
  get(id: any): Observable<IRedeemedVoucher> {
    const url = environment.salonFinder.baseURL + environment.salonFinder.advertisers + '/' + id;
    return this.httpClient.get<IRedeemedVoucher>(url)
      .pipe(
        tap(_ => console.log(`fetched finance id=${id}`)),
        catchError(this.handleError<IRedeemedVoucher>(`get id=${id}`))
      );
  }

  activateAdvertiser(data:any):Observable<any> {
    // console.log(data);
    return this.httpClient.post(environment.salonFinder.baseURL + environment.salonFinder.advertisers + environment.salonFinder.deactivateAdvertisers, data);
  }

  sendOnBoardEmail(aid:number) {
    return this.httpClient.get(environment.salonFinder.baseURL + environment.salonFinder.sendOnBoardEmail + '/' + aid);
  }
}
import { AfterViewInit, Component, OnChanges, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import moment, { Moment } from 'moment';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';
import { FormComponent } from 'src/app/shared/abstracts/form-component';
import { DateFormat } from 'src/app/shared/helpers/DateFormat';
import { MakeSearchParams } from 'src/app/shared/helpers/MakeSearchParams';
import { IS3UploadFile } from 'src/app/shared/interfaces/IS3UploadFile';
import { environment } from 'src/environments/environment';
import { CampaignItemTypeEnum } from '../../enums/campaign-item-type';
import { CampaignStatusEnum } from '../../enums/campaign-status';
import { MasterDataTypeEnum } from '../../enums/master-data-type.enum';
import { UploadFileEnum } from '../../enums/upload-file-type.enum';
import { ICampaign } from '../../interfaces/campaign';
import { IClient } from '../../interfaces/client';
import { IMasterData } from '../../interfaces/master-data';
import { IPublisher } from '../../interfaces/publisher';
import { IUploadFile } from '../../interfaces/upload-file';
import { NativeAdsCampaignService } from '../../services/native-ads-campaign.service';
import { NativeAdsMasterDataService } from '../../services/native-ads-master-data.service';
import { NativeAdsPublisherService } from '../../services/native-ads-publisher.service';
import { NativeAdsUploadFilesService } from '../../services/native-ads-upload-files.service';
import { NativeAdsUserService } from '../../services/native-ads-user.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'native-ads-campaign-form',
  templateUrl: './campaign-form.component.html',
  styleUrls: ['./campaign-form.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    {provide: MAT_DATE_FORMATS, useValue: DateFormat}
  ]
})
export class CampaignFormComponent extends FormComponent<ICampaign, NativeAdsCampaignService> implements OnInit, AfterViewInit, OnChanges {

  filteredClient$: Observable<IClient[]>
  filteredArticle$: Observable<any[]>
  
  loadedClient: IClient[] = []
  
  loadedArticle:any[] = [];

  adsType$: Observable<IMasterData[]>

  loadedPublisher$: Observable<IPublisher[]>

  btnSaveText = "Schedule"

  allowUploadType = ['image/gif','image/jpeg','image/png','image/jpg']

  uploadBucket = environment.nativeAds.uploadBucket;
  fileNamePrefix = DateTime.utc().toFormat('yyyy-LL-dd-HH-mm-ss');
  uploadPath = `${environment.nativeAds.uploadKey}/${DateTime.utc().toFormat('yyyy')}/${DateTime.utc().toFormat('LL')}/`;

  viewMobile: boolean = true

  showHighlight: boolean = true

  isAutoLoadArticle: boolean = false;

  campaignStatus:IMasterData[] = new Array<IMasterData>()

  @ViewChild('startTimeEle')
  startTimeEle;

  @ViewChild('endTimeEle')
  endTimeEle;

  campaignStatus$ = this.masterDateService.listCache()
    .pipe(
      filter(f=>f != null),
      map(d=> {
        return d.filter(m=>m.reference === MasterDataTypeEnum.campaignStatus)
      })
    )


	compareCampaignStatus(service: IMasterData, compareService: IMasterData) {
		return service && compareService && service.id === compareService.id
	}

  get PriorityControl(): FormControl {
    return this.formData.get('priority') as FormControl
  }

  getCampaignItemTypeControl(campaignItem: AbstractControl): FormControl {
    return campaignItem.get('type') as FormControl
  }

  save(saveDraft: boolean = false): void {    
    
    if(this.formData.valid) {
      let value = this.formData.value
      value.clientName = value.client.name
      if(typeof value.client === 'string') {
        value.clientName = value.client
        value.clientID = 0
        value.client = undefined
      } else {
        value.clientName = value.client.name
        value.clientID = value.client.id
      }
      value.statusID = value?.status?.id || value.statusID
			this.savingForm = true
      if(saveDraft) {
        value.statusID = CampaignStatusEnum.DRAFT
        value.status = this.campaignStatus.find(c=>c.id === CampaignStatusEnum.DRAFT)
      }
      value.startDate = this.startDateTime
      value.endDate = this.endDateTime

      const typeObj = value.campaignItems[0].type
      if(typeObj.id === CampaignItemTypeEnum.BESPOKEN || typeObj.id === CampaignItemTypeEnum.ARTICLE_INSERTION || typeObj.id === CampaignItemTypeEnum.SPLASH_POPUP ) {
        value.priority = 1
        if(value.campaignItems[0].title == ""){
          value.campaignItems[0].title = value.clientName + "_" + value.name + "_" + moment().format('DD-MMM-yyyy_h:mma')
        }
      }
      if(typeObj.id === CampaignItemTypeEnum.BESPOKEN) {
        value.campaignItems[0].impressionPurchased = 1000000
      }

      this.baseService.save(value, value.id)
        .subscribe({
          error: () => {
            this.savingForm = false;
            this.snackBar.open('Failed to save campaign!', 'Close', {
              duration: 2000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
          },
          next: d=> {
            this.loadedModel = d
            this.initForm()
            this.savingForm = false
            this.snackBar.open('Campaign has been saved SUCCESSFULLY!', 'Close', {
              duration: 2000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
            this.router.navigate(['/native-ads/ads-report'], {queryParams: {clientID: d.clientID, campaignName: d.name, searchText: d.campaignItems[0]?.title}})
          }
        })
    }
  }

  toggleView() {
    this.viewMobile = !this.viewMobile
    return false;
  }

  public hideImageFields(){
    return this.enableFields(0)?.id === CampaignItemTypeEnum.AUTOLOAD_ARTICLE 
  }

  public hideCampaignItemFields() {
    return this.enableFields(0)?.id === CampaignItemTypeEnum.BESPOKEN 
  }

  public enableFields(index: number) {
    return this.formData.value?.campaignItems[index]?.type
  }

  public enableHighlight(index: number) {
    return this.enableFields(index) && this.showHighlight
  }


  public enableDesktopImage(index: number) {
    return this.enableFields(index)?.id === CampaignItemTypeEnum.SPLASH_POPUP
  }

  async getFileReader(file: File) {
    var reader = new FileReader(); 
    reader.readAsDataURL(file);
    return new Promise<HTMLImageElement>((resolve) => {
      reader.onload = function(e) {
        var image = new Image(); 
        image.src = e.target.result as string; 
        image.onload = (eObj) => {
          resolve(image)
        }
      }
    });
  }

  async uploadedFiles(uploadedFiles: IS3UploadFile[]) {
    const firstCampaignItems = this.campaignItems.at(0)
    const imageReader = await this.getFileReader(uploadedFiles[0].Body)
    const uploadModel:IUploadFile = {
      bucketURL: uploadedFiles[0].Bucket,
      mimetype: uploadedFiles[0].ContentType,
      fullFilePath: uploadedFiles[0].Key,
      fileName: uploadedFiles[0].FileName,
      url: environment.nativeAds.bucketURL + '/' + uploadedFiles[0].Key,
      path: this.uploadPath,
      width: imageReader.width,
      height: imageReader.height,
      type: UploadFileEnum.IMAGE_MOBILE
    }
    
    if(environment.nativeAds.imageSizing[firstCampaignItems.value.type.identifier.toLowerCase()]) {
      const resizingSize = environment.nativeAds.imageSizing[firstCampaignItems.value.type.identifier.toLowerCase()] as {width: number}[];
      for(const dimension of resizingSize) {
        if(dimension.width < imageReader.width) {
          if(!uploadModel.resize) {
            uploadModel.resize = [{
              width: dimension.width
            }]
          } else {
            uploadModel.resize.push({
              width: dimension.width
            })
          }
          
        }
      } 
      this.uploadFileService.resizeImages([uploadModel])
        .subscribe(d=> {
          const storedImages = firstCampaignItems.get('images').value as IUploadFile[]
          const newImages = storedImages?[].concat(storedImages?.filter(s=>s.type != UploadFileEnum.IMAGE_MOBILE),d):d
          firstCampaignItems.patchValue({
            images: newImages.filter(d=>d.id)
          }, {emitEvent:false })

        })
    }
    
    this.campaignItems.at(0).patchValue({
      imageURL: environment.nativeAds.bucketURL + '/' + uploadedFiles[0].Key
    })
    this.viewMobile = true
  }

  public validateUploadImage(index: number) { 
    const instance = this
    return (uploadImage: File) => {
      let maxImageSize = 1024*1024
      if(instance.campaignItems.at(index).get('type').value.id === CampaignItemTypeEnum.ARTICLE_INSERTION || instance.campaignItems.at(index).get('type').value.id === CampaignItemTypeEnum.SPLASH_POPUP) {
        maxImageSize *=2
      }

      if(uploadImage.size>maxImageSize) {
        instance.campaignItems.at(index).get('imageURL').setErrors({
          imageSize: true
        })
      }
      if(instance.allowUploadType.findIndex(f=>f ===uploadImage.type)<0) {
        instance.campaignItems.at(index).get('imageURL').setErrors({
          imageType: true
        })
      }
      var reader = new FileReader(); 
      reader.readAsDataURL(uploadImage);      
      return new Promise<boolean>((resolve) => {
        reader.onload = function(e) {
          var image = new Image(); 
          image.src = e.target.result as string; 
          image.onload = () => {
            const value = instance.formData.value
            let resolveValue = false
            switch(value.campaignItems[0].type.id) {
              case CampaignItemTypeEnum.ARTICLE_INSERTION: 
                resolveValue = (image.height === 400 && image.width === 700) || (image.height === 800 && image.width === 1400)
                break;
              case CampaignItemTypeEnum.CONTENT_RECOMMANDED: 
                resolveValue = (image.height === 467 && image.width === 900) || (image.height === 934 && image.width === 1800)
                break;
              case CampaignItemTypeEnum.SPLASH_POPUP: 
                resolveValue = (image.height === 520 && image.width === 400) || (image.height === 1040 && image.width === 800)
                break;
            }
            if(!resolveValue) {
              instance.campaignItems.at(index).get('imageURL').setErrors({
                imageDimension: true
              })
            }
            resolve(resolveValue)
          }; 
        }; 
      })
    }
  }

  constructor(
    public readonly baseService: NativeAdsCampaignService,
    private readonly userService: NativeAdsUserService,
    private readonly masterDateService: NativeAdsMasterDataService,
    private readonly publisherService: NativeAdsPublisherService,
    private readonly uploadFileService: NativeAdsUploadFilesService,
		private readonly snackBar: MatSnackBar,
		private readonly router: Router,
    public readonly httpClient: HttpClient,
  ) {    
    super();
  }

  get startDateTime() {
    const values = this.formData.value
    return moment(values.startDate.format('DD MMM yyyy') +' '+values.startTime)
      
  }

  get endDateTime() {
    const values = this.formData.value
    return moment(values.endDate.format('DD MMM yyyy') +' '+values.endTime)
  }

  comparePublisher(option: IPublisher, selected: IPublisher): boolean {
    return option.id === selected.id;
  }

  async uploadedDesktopFiles(uploadedFiles: IS3UploadFile[]) {
    const firstCampaignItems = this.campaignItems.at(0)
    const imageReader = await this.getFileReader(uploadedFiles[0].Body)
    const uploadModel:IUploadFile = {
      bucketURL: uploadedFiles[0].Bucket,
      mimetype: uploadedFiles[0].ContentType,
      fullFilePath: uploadedFiles[0].Key,
      fileName: uploadedFiles[0].FileName,
      url: environment.nativeAds.bucketURL + '/' + uploadedFiles[0].Key,
      path: this.uploadPath,
      width: imageReader.width,
      height: imageReader.height,
      type: UploadFileEnum.IMAGE_DESKTOP
    }
    
    if(environment.nativeAds.imageSizing[firstCampaignItems.value.type.identifier.toLowerCase()]) {
      const resizingSize = environment.nativeAds.imageSizing[firstCampaignItems.value.type.identifier.toLowerCase()] as {width: number}[];
      for(const dimension of resizingSize) {
        if(dimension.width < imageReader.width) {
          if(!uploadModel.resize) {
            uploadModel.resize = [{
              width: dimension.width
            }]
          } else {
            uploadModel.resize.push({
              width: dimension.width
            })
          }
          
        }
      }        
      
      this.uploadFileService.resizeImages([uploadModel])
        .subscribe(d=> {
          const storedImages = firstCampaignItems.get('images').value as IUploadFile[]
          const newImages = storedImages?[].concat(storedImages?.filter(s=>s.type != UploadFileEnum.IMAGE_DESKTOP), d):d
          firstCampaignItems.patchValue({
            images: newImages.filter(d=>d.id)
          }, {emitEvent:false })
          console.log(this.formData.value)
        })
    }



    this.campaignItems.at(0).patchValue({
      desktopImageURL: environment.nativeAds.bucketURL + '/' + uploadedFiles[0].Key
    })
    this.viewMobile = true
  }

  public validateUploadDesktopImage(index: number) { 
    const instance = this
    return (uploadImage: File) => {
      const maxImageSize = 1024*1024*5

      if(uploadImage.size>maxImageSize) {
        instance.campaignItems.at(index).get('desktopImageURL').setErrors({
          imageSize: true
        })
      }
      if(instance.allowUploadType.findIndex(f=>f ===uploadImage.type)<0) {
        instance.campaignItems.at(index).get('desktopImageURL').setErrors({
          imageType: true
        })
      }
      var reader = new FileReader(); 
      reader.readAsDataURL(uploadImage);      
      return new Promise<boolean>((resolve) => {
        reader.onload = function(e) {
          var image = new Image(); 
          image.src = e.target.result as string; 
          image.onload = () => {
            const value = instance.formData.value
            let resolveValue = false
            if((image.height==1040  && image.width==800) || (image.width == 2000 && image.height==1200)) {
              resolveValue =true
            }
            if(!resolveValue) {
              instance.campaignItems.at(index).get('desktopImageURL').setErrors({
                imageDimension: true
              })
            }
            resolve(resolveValue)
          }; 
        }; 
      })
    }
  }

  get campaignItems() {    
    return this.formData.get('campaignItems') as FormArray
  }

  listUser() {
    this.userService.list(MakeSearchParams({
      limit: -1
    }))
    .subscribe(u=>{
      this.loadedClient = u.data
      this.filteredClient$ = this.formData.get('client').valueChanges.pipe(
        startWith(this.loadedModel?.client?.name || ''),
        map(value => (typeof value === 'string' ? value : value?.name)),
        map(value=>(value? this._filter(value): this.loadedClient.slice()))
        
      )
    })
  }

  listArticle(){
    this.httpClient.get('https://dailyvanity-sg.tipsy.darvis.dev/wp-json/wp/v2/posts?')
    .subscribe((response:any)=> {
      this.loadedArticle = response;
      this.filteredArticle$ = this.formData.get('article').valueChanges.pipe(
        startWith(this.loadedModel?.article || ''),
        map(value => (typeof value === 'string' ? value : value?.title?.rendered)),
        map(value=>(value? this._filterArticle(value): this.loadedArticle.slice()))
      )    
    })
  }

  displayFn(user): string {
    if(typeof user == 'string'){
      return user;
    }else{
      return user && user.name ? user.name : '';
    }
  }

  displayFnArticle(article: any): string {
    return article && article.title.rendered ? article.title.rendered : '';
  }


  endTimeChanged(time: string) {
    this.formData.patchValue({
      endTime: time
    }, {emitEvent: true})
    this.validateStartEndTime();
  }

  startTimeChanged(time: string) {
    this.formData.patchValue({
      startTime: time
    }, {emitEvent: true})
    this.validateStartEndTime();
  }

  validateStartEndTime(){

    let startDate  = moment(this.formData.get('startDate').value).format("YYYY-MM-DD");
    let endDate = moment(this.formData.get('endDate').value).format("YYYY-MM-DD");
   
    let startTime = this.formData.get('startTime').value;
    let endTime = this.formData.get('endTime').value;

    let startDateTime = new Date(`${startDate} ${startTime}`);
    let endDateTime = new Date(`${endDate} ${endTime}`);
    if(startDateTime < endDateTime){
      this.formData.updateValueAndValidity();
    }

  }



  startDateChanged(value: Moment) {
    const values = this.formData.value
    const checkStartDate =  this.baseService.setMomentTime(value, values.startTime)
    const today = moment()
    if(checkStartDate && values.statusID === CampaignStatusEnum.ACTIVE && checkStartDate.isSameOrAfter(today)) {
      this.btnSaveText = 'Schedule'
      this.formData.patchValue({
        status: this.campaignStatus.find(c=>c.id === CampaignStatusEnum.SCHEDULE),
        statusID: CampaignStatusEnum.SCHEDULE
      }, {onlySelf: true, emitEvent: true})
    }
    if(checkStartDate && values.statusID === CampaignStatusEnum.SCHEDULE && checkStartDate.isBefore(today)) {
      this.btnSaveText = 'Publish'
      this.formData.patchValue({
        status: this.campaignStatus.find(c=>c.id === CampaignStatusEnum.ACTIVE),
        statusID: CampaignStatusEnum.ACTIVE
      }, {onlySelf: true, emitEvent: true})
    }
  }

  ngOnInit(): void {
    this._loadedModel$.subscribe(d=> {
      const formData = d;
      if(formData) {
        this.updateTime();
        this.masterDateService.masterData$.subscribe(masterData => {
          if(masterData !== null){
            const patchingForm = {};
            if(!formData?.campaignItems[0]?.type && formData?.campaignItems[0]?.typeID) {
              const item = formData.campaignItems;
              item[0].type = masterData.find(aa=>aa.id === formData.campaignItems[0].typeID)
              patchingForm['name'] = formData.name;
              this.adsTypeChange(item[0].type, 0)

            }
            if(!formData?.status && formData?.statusID) {
              patchingForm['status'] = masterData.find(m=>m.id === formData?.statusID);
            }
            if(patchingForm) {
              this.formData.patchValue(patchingForm, {emitEvent: true});
            }
          }
        })
      }
    })
    this.listUser()
    this.initForm()
    this.adsType$ = this.masterDateService.listCache()
      .pipe(
        shareReplay(1),
        map(d=> {
          return d.filter(m=>m.reference === MasterDataTypeEnum.adsType)          
        })
      )
    this.loadedPublisher$ = this.publisherService.list()
      .pipe(
        shareReplay(1),
        map(d=>d.data)
      )
  }
  adsTypeChange($event:IMasterData, index) {

    this.isAutoLoadArticle = false;
    this.showHighlight = false;
    
    switch($event.id){
      case CampaignItemTypeEnum.CONTENT_RECOMMANDED:
          this.showHighlight = true
        break;
      case CampaignItemTypeEnum.AUTOLOAD_ARTICLE: 
          let dailyVanity = this.loadedClient.find(c=> c.name == 'Daily Vanity');

          this.formData.patchValue({
            priority: '0',
            client: dailyVanity
          })
          this.showHighlight = true;
          this.listArticle();
          this.isAutoLoadArticle = true;
        break;

    }

    this.formData['controls']['campaignItems']['controls'][index]['controls']['type'].setValue($event)
    
    
    
  }

  updateTime() {
    if(this.startTimeEle && this.loadedModel) {      
      const startTime = moment(this.loadedModel.startDate)
      this.startTimeEle.changeMinute(startTime.minute())
      
      if(startTime.hour()>12) {
        this.startTimeEle.changeHour(startTime.hour() - 12)
        this.startTimeEle.changePeriod('PM')
      } else {
        this.startTimeEle.changeHour(startTime.hour()==0?12:startTime.hour())
        this.startTimeEle.changePeriod('AM')
      }
    }
    if(this.endTimeEle && this.loadedModel) {
      const endTime = moment(this.loadedModel.endDate)
      this.endTimeEle.changeMinute(endTime.minute())
      if(endTime.hour()>12) {
        this.endTimeEle.changeHour(endTime.hour() - 12)
        this.endTimeEle.changePeriod('PM')
      } else {
        this.endTimeEle.changeHour(endTime.hour()==0?12:endTime.hour())
        this.endTimeEle.changePeriod('AM')
      }
    }
  }

  ngAfterViewInit() {
    this.updateTime()
  }

  private _filter(value: string) {
    return this.loadedClient.filter(c=>c.name.toLowerCase().includes(value.toLowerCase()))
  }

  private _filterArticle(value: any) {
    let selectedArticle = this.loadedArticle.filter(c=>c.title.rendered.toLowerCase().includes(value.toLowerCase()))
    
    this.formData.patchValue({
      name: selectedArticle[0].slug,
    })
    
    this.campaignItems.at(0).patchValue({
      description: selectedArticle[0].id,
      linkURL:selectedArticle[0].link,
      impressionPurchased: 999999,
      title: selectedArticle[0].title.rendered
    })

    return selectedArticle;
  }


}

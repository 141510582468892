<h2 mat-dialog-title *ngIf="!data.viewClicks"><b> Link Clicks Data </b></h2>
<div mat-dialog-subtitle class="mat-dialog-subtitle" *ngIf="!data.viewClicks"><b> You are linking </b></div>
<mat-dialog-content class="mat-typography" *ngIf="!data.viewClicks">
    <div class="text-center">
        <br>
        <p><b> </b></p>
        <div class="message-container text-center">
            <div [innerHtml]="trimmedLongMessage"></div>
        </div>
        <br>
        <br>
        <div><a href="https://t.me/dailyvanity/{{ data.messageID }}" target="_blank"> <span>**[View actual post]**</span> </a></div>
        <br>
        <br>
        <!-- <div class="img-container">
            <span>PHOTO</span>
        </div>
        <br> -->
        <p><b> with </b></p>
        <br>
        <table *ngIf="!isLoadingResults">
            <thead>
                <tr>
                    <th>Campaign</th>
                    <th>Client</th>
                    <th>Link</th>
                    <th>Clicks</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let link of connectedLinks">
                    <td>
                        <div *ngIf="link.tags.length > 0">
                            <div *ngFor="let e of link.tags">
                                <div *ngIf="e?.key === 'campaign'">
                                    {{ e?.value }}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div *ngIf="link.tags.length > 0">
                            <div *ngFor="let e of link.tags">
                                <div *ngIf="e?.key === 'client'">
                                    {{ e?.value }}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td><a href="https://{{ link.fullURL }}" target="_blank"> <span>https://{{ link.fullURL }}</span> </a></td>
                    <td>{{ link.totalRequested }}</td>
                    <td>
                        <button mat-icon-button color="accent" matTooltip="Unlink urlshortener" (click)="unlinkMessage(link)">
                            <mat-icon>link_off</mat-icon>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>

        <br>

        <div class="row mb-3 mr-2 ml-2">
            <mat-form-field>
                <mat-label>Search urlshortener</mat-label>
                <input matInput  (keydown.enter)="applyFilter($event)" (input)="resetFilter($event)" placeholder="type and enter" #input>
            </mat-form-field>

            <div class="loading-shade" *ngIf="isLoadingResults">
                <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
            </div>
            <div>
                <table mat-table [dataSource]="linkDataSource" matSort>
                    <ng-container matColumnDef="select">
                        <th mat-header-cell *matHeaderCellDef>
                        </th>
                        <td mat-cell *matCellDef="let element" class="mat-cell-checkbox">
                            <mat-checkbox [value]="element" [checked]="element.id == data.shortUrlID"
                                (change)="selectedRow($event)">
                            </mat-checkbox>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="createdAt">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by createdAt">
                            <div class="th-container"> <b> Created on </b></div>
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.createdAt | date: 'dd MMM YYYY'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="campaign">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by campaign">
                            <div class="th-container"> <b> Campaign</b></div>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <div *ngIf="element.tags.length > 0">
                                <div *ngFor="let e of element.tags">
                                    <div *ngIf="e?.key === 'campaign'">
                                        {{ e?.value }}
                                    </div>
                                </div>
                            </div>
                        </td>



                    </ng-container>

                    <ng-container matColumnDef="client">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by client">
                            <div class="th-container"> <b> Client</b></div>
                        </th>
                        <td mat-cell *matCellDef="let element;">
                            <div *ngIf="element.tags.length > 0">
                                <div *ngFor="let e of element.tags">
                                    <div *ngIf="e?.key === 'client'">
                                        {{ e?.value }}
                                    </div>
                                </div>
                            </div>
                        </td>
                    </ng-container>


                    <ng-container matColumnDef="totalRequested">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            sortActionDescription="Sort by totalRequested">
                            <div class="th-container"> <b> Clicks </b></div>
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.totalRequested}} </td>
                    </ng-container>


                    <ng-container matColumnDef="totalUniqueRequested">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            sortActionDescription="Sort by totalUniqueRequested">
                            <div class="th-container"> <b> Unique Clicks </b></div>
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.totalUniqueRequested}} </td>
                    </ng-container>

                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                            sortActionDescription="Sort by description">
                            <div class="th-container"> <b> Description</b></div>
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element.description}}</td>
                    </ng-container>

                    <ng-container matColumnDef="shortUrl">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by shortUrl">
                            <div class="th-container"> <b> Short URL</b></div>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <a href="https://{{ element.fullURL }}" target="_blank"> <span>https://{{ element.fullURL }}</span> </a>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                        [ngClass]="{hovered: row.hovered, highlighted: row.highlighted}"
                        (click)="row.highlighted = !row.highlighted"></tr>

                </table>


                <mat-paginator [length]="resultsLength" [pageSize]="pageSize"
                    aria-label="Select page of users"></mat-paginator>
            </div>
        </div>


    </div>
</mat-dialog-content>


<mat-dialog-actions align="center" *ngIf="!data.viewClicks">
    <button mat-button mat-flat-button  [mat-dialog-close]="true" mat-dialog-close>Cancel</button>
</mat-dialog-actions>


<h2 mat-dialog-title *ngIf="data.viewClicks"><b> View Clicks </b></h2>

<mat-dialog-content class="mat-typography" *ngIf="data.viewClicks">
    <div class="text-center">
        <div class="row mb-3 mr-2 ml-2">
            <div class="loading-shade" *ngIf="isLoadingResults">
                <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
            </div>
            <div *ngIf="!isLoadingResults">
                <table>
                    <thead>
                        <tr>
                            <th>Campaign</th>
                            <th>Client</th>
                            <th>Link</th>
                            <th>Clicks</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let link of connectedLinks">
                            <td>
                                <div *ngIf="link.tags.length > 0">
                                    <div *ngFor="let e of link.tags">
                                        <div *ngIf="e?.key === 'campaign'">
                                            {{ e?.value }}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div *ngIf="link.tags.length > 0">
                                    <div *ngFor="let e of link.tags">
                                        <div *ngIf="e?.key === 'client'">
                                            {{ e?.value }}
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>  <a href="https://{{ link.fullURL }}" target="_blank"> <span>https://{{ link.fullURL }}</span> </a></td>
                            <td>{{ link.totalRequested }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center" *ngIf="data.viewClicks">
    <button mat-button mat-flat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { ICRUDPath } from 'src/app/shared/interfaces/iCRUDPath.interface';
import { environment } from 'src/environments/environment';
import { IItemMetrics } from '../interfaces/item-metrics';
import { IItemMetricsCount } from '../interfaces/item-metrics-count';

@Injectable({
  providedIn: 'root'
})
export class NativeAdsImpressionService extends CRUDService<IItemMetrics> {
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }
  list(params?: HttpParams): Observable<ICommonListingResponse<IItemMetrics[]>> {
    throw new Error('Method not implemented.');
  }
  get(id: string | number): Observable<IItemMetrics> {
    throw new Error('Method not implemented.');
  }
  delete(id: string | number): Observable<IItemMetrics> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: IItemMetrics): Observable<IItemMetrics> {
    throw new Error('Method not implemented.');
  }
  create(model: IItemMetrics): Observable<IItemMetrics> {
    throw new Error('Method not implemented.');
  }
  makeFormGroup(loadedModel?: IItemMetrics) {
    return this.fb.group({
      id: [loadedModel?.id],
      itemMetricsLogID: [loadedModel?.itemMetricsLogID],
      publisher: [loadedModel?.publisher],
      publisherID: [loadedModel?.publisherID],
      campaignItem: [loadedModel?.campaignItem],
      campaignItemID: [loadedModel?.campaignItemID],
      metricsType: [loadedModel?.metricsType],
      metricsTypeID: [loadedModel?.metricsTypeID],
      metricsGroup: [loadedModel?.metricsGroup],
      addedDate: [loadedModel?.addedDate],
    })
  }
  apiPath = environment.nativeAds.impressionBase;
  environmentCRUDPath = environment.nativeAds;

  listImpressionByCampaignItems(params: HttpParams) {
    return this.httpClient.get<IItemMetricsCount[]>(this.getAPIURL(environment.nativeAds.impressionStatsByCampaignItem), {
      params: params
    })
  }

  constructor(
    public readonly httpClient: HttpClient,
    private readonly fb: FormBuilder,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute
  ) {
    super();
  }
}

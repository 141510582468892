import { salonFinderServer } from "./salon-finder-server";

export const salonFinder = {
  commonDeletePath: '/delete',
  commonListPath: '/list',
  commonUpdatePath: '/update',
  commonCreatePath: '/create',
  commonGetPath: '/get',
  wp_preview: '/preview-by-id',

  collections: '/collections',
  createCollections: '/create',
  updateCollections: '/update',
  deactivateCollections: '/deactivate',
  getCollectionsServicesById: '/get/service',
  addCollectionsServices: '/add-service',

  advertisers: '/advertisers',
  createAdvertisers: '/create',
  updateAdvertisers: '/update',
  deactivateAdvertisers: '/deactivate',
  sendOnBoardEmail: '/email/onBoard',

  outlets: '/outlets',
  createOutlets: '/create',
  updateOutlets: '/update',
  deactivateOutlets: '/deactivate',
  getOutletsByAdvertiser: '/adv',

  entities: '/entities',

  services: '/services',
  createServices: '/create',
  updateServices: '/update',
  resyncServices: '/re-sync',
  reSyncAllServices: '/re-sync-all',
  deactivateServices: '/deactivate',
  getServicesByAdvertiser: '/adv',
  getServicesByCollectionId: '/col',
  addedCollectionsServicesById: '/added-service',
  deleteCollectionsServices: '/delete-service',
  live: '/live',
  promoBase: '/service-promotion',
  createPromos: '/create',
  updatePromos: '/update',
  deactivatePromos: '/deactivate',
  getPromosByAdvertiser: '/adv',

  finance: '/finance',
  calculate: '/calculate',

  wpURL: 'http://localhost/dev-salon-finder',
  
  urlRedirectionURL: 'https://urlshortener-api.tipsy.darvis.dev',
  awsDistributionID: 'E1LEZQQVRALDD2',

  uploadBucket: "sf-uat-upload.dailyvanity.sg",
  uploadKey: "client",
  bucketURL: "https://sf-uat-upload.dailyvanity.sg",

  orderVoucherPath: '/order-vouchers',
  orderPaymentPath: '/order-payment',
  orderVoucherListStats: '/list-stats',
  orderVoucherSendEmail: '/admin-send-email',
  orderVoucherRedeem: '/admin-redeem',
  orderVoucherExtendExpiryDate: '/change-expiry-date',
  orderVoucherCancel: '/admin-cancel',
  orderVoucherUnCancel: '/admin-uncancel',
  orderListRedeemed: '/list-redeemed',
  orderListRedeemedDetailExport: '/download-list-redeemed-details',
  orderListSettlement: '/list-settlement',
  orderVoucherPayment: '/pay-voucher',

  orderPaymentReport: '/export-pdf',


  createUploadImage: "/upload/add-record",

  advertiserDefaultLogo: "https://sf-uploads.dailyvanity.sg/sf/2022/06/dvlogo.jpg",
  serviceDefaultImg: "https://sf-uploads.dailyvanity.sg/sf/2022/06/dvlogo.jpg",
  defaultReplyToEmail: "salonfinder@dailyvanity.com",
  defaultSender: {
    email: "beauty@dailyvanity.com",
    name: "Daily Vanity Salon Finder"
  },
  defaultBCC: "tech@dailyvanity.com",
  ...salonFinderServer
}
import { HttpParams } from "@angular/common/http"

type paramsType = {
  searchText?: string,
  page?: number,
  limit?: number
  pageSize?: number
  defaultParams?: {[param: string]: string | string[]}
} | {[param: string]: string | ReadonlyArray<string>;}

export const MakeSearchParams = (params: paramsType, newArray: boolean = false): HttpParams => {
  let paramObj: {[param: string]: string | ReadonlyArray<string> | number } = {}
  if (params) {
    Object
      .keys(params)
      .map(k => {
      if (params[k]) {
        if (!newArray && Array.isArray(params[k])) {
          paramObj[`${k}[]`] = params[k];
        }
        paramObj[`${k}`] = params[k];
      }

      if (params[k] === undefined || params[k] === null){
          paramObj[k] = ""; 
      }

    });
  }

  paramObj = {
    searchText: params.searchText || "",
    ...paramObj,
    page: params?.page?.toString() || 0,
    limit: params?.limit?params.limit:params.pageSize.toString()?params.pageSize.toString(): '-1',
  };
  paramObj['searchText'] = encodeURIComponent(paramObj['searchText'] as string)
  if(paramObj['searchText']!=='') {
    paramObj['page'] = '0';
  }
  return new HttpParams({
    fromObject: paramObj
  });
}
import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatDrawerContent } from '@angular/material/sidenav';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Route, Router } from '@angular/router';
import { startsWith } from '@rxweb/reactive-form-validators';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { map, filter, debounceTime, endWith, startWith, finalize, tap, switchMap } from 'rxjs/operators';
import { MakeSearchParams } from 'src/app/consumer-report/helpers/MakeSearchParams';
import { ICommonEntityObject } from '../interfaces/ICommonEntityObject.interface';
import { ICommonListingResponse } from '../interfaces/icommonListingResponse.interface';
import { ICommonObject } from '../interfaces/ICommonObject';
import { DarvisService } from '../services/darvis.service';
import { CRUDService } from './crud-service';

@Component({
	template: ''
})
export abstract class ListingComponent<T extends ICommonEntityObject<any>> implements AfterViewInit {

	@Output() totalEvent = new EventEmitter<number>();
	@Output() pageEvent = new EventEmitter<number>();
	@Output() searchTextEvent = new EventEmitter<string>();
	@Output() searchTextRightEvent = new EventEmitter<string>();

	@ViewChild('searchTextInput')
	searchTextInput: ElementRef;

	searchTextFormControl: FormControl = new FormControl('');
	loadingListSubscription: Subscription = null;

	searchFormGroup: FormGroup;

	searchFormGroupValueChange$: BehaviorSubject<any> = new BehaviorSubject(null);

	abstract populateList<U extends HttpParams | Params>(params?: U, urlHistory?: boolean): void;
	abstract getExtraListingParams(): void;
	deleteItem(item: T) {
		this.baseService.delete(item.id);
	}

	get searchValue() {
		return this.searchTextFormControl.value || this.searchTextInput?.nativeElement.value;
	}

	lists: T[] = [];
	private _total = 0;
	private _totalright = 0;
	private _pageSizeOptions: number[] = [15, 25, 50, 100, 150, 500, 99999];
	private _pageSizeOptionsRight: number[] = [15, 25, 50, 100, 150, 500, 99999];
	private _pageSize = 15;
	private _pageSizeRight = 15;
	private _page = 0;
	private _pageright = 0;
	private _searchText = '';
	private _searchTextRight = '';
	private _listingCols: string[] = [];
	order = '';

	private _defaultParams: ICommonObject = {};

	dataSource: MatTableDataSource<T>;
	dataSourceRight: MatTableDataSource<T>;

	public readonly mainRouter: Router;
	public readonly activatedRoute: ActivatedRoute;
	public readonly baseService: CRUDService<T>;
	public readonly darvisService: DarvisService;

	isLoading = false;

	get total(): number {
		return this._total;
	}

	set total(total: number) {
		this.totalEvent.emit(total);
		this._total = total;
	}

	get totalright(): number {
		return this._totalright;
	}

	set totalright(totalright: number) {
		this._totalright = totalright;
	}

	get pageSizeOptions(): number[] {
		return this._pageSizeOptions;
	}

	set pageSizeOptions(p: number[]) {
		this._pageSizeOptions = p;
	}

	get pageSizeOptionsRight(): number[] {
		return this._pageSizeOptionsRight;
	}

	set pageSizeOptionsRight(p: number[]) {
		this._pageSizeOptionsRight = p;
	}

	get defaultParams(): ICommonObject {
		return this._defaultParams;
	}

	set defaultParams(p: ICommonObject) {
		this._defaultParams = p;
	}

	get page(): number {
		return this._page;
	}

	set page(p: number) {
		this.pageEvent.emit(p);
		this._page = p;
	}

	get pageright(): number {
		return this._pageright;
	}

	set pageright(p: number) {
		this.pageEvent.emit(p);
		this._pageright = p;
	}

	get searchText(): string {
		return this._searchText;
	}

	set searchText(p: string) {
		this.searchTextEvent.emit(p);
		this._searchText = p;
	}

	get searchTextRight(): string {
		return this._searchTextRight;
	}

	set searchTextRight(p: string) {
		this.searchTextRightEvent.emit(p);
		this._searchTextRight = p;
	}

	get pageSize(): number {
		return this._pageSize;
	}

	set pageSize(p: number) {
		this._pageSize = p;
	}

	get pageSizeRight(): number {
		return this._pageSizeRight;
	}

	set pageSizeRight(p: number) {
		this._pageSizeRight = p;
	}

	get listingCols(): string[] {
		return this._listingCols;
	}

	set listingCols(cols: string[]) {
		this._listingCols = cols;
	}

	public initSearchForm(defaultParams?: ICommonObject) {
		if(!this.searchFormGroup) {
			try {
				this.searchFormGroup = this.baseService.makeSearchListGroup(defaultParams);
				this.searchFormGroup.valueChanges
					.pipe(
						debounceTime(500),
						//filter(d=>d.searchText.length > 3 || d.searchText.length === 0)
					).subscribe(d=>this.searchFormGroupValueChange$.next(d));
			} catch(e) {
				this.searchFormGroup = null;
			}
		}
		return this.searchFormGroup
	}

	public updateDefaultParams() {
		if (this.activatedRoute) {
			const queryMap = this.activatedRoute.snapshot.queryParamMap;
			
      if (queryMap.has('limit')) {
				this._pageSize = +queryMap.get('limit');
			}

			if (queryMap.has('searchText')) {
				this._searchText = decodeURIComponent(queryMap.get('searchText'));
		    this.setupSearchTextFormControl();
			}
      
			if (queryMap.has('page')) {
				this._page = +queryMap.get('page');
			} 
		}
	}

	refreshList(textSearch?: string, page?: number) {
		if (textSearch || textSearch == '') {
			this.searchText = textSearch;
		}
		if (page) {
			this.page = page
		}
		else {
			this.page = 0
		}
		let searchParams = null
		if(this.searchFormGroup) {
			const searchValue = this.searchFormGroup.value
			delete searchValue['searchText']
			searchParams = new HttpParams({
				fromObject: searchValue
			})
		}
		this.populateList(searchParams, true);
	}

	setupSearchTextFormControl() {
		this.searchTextFormControl = new FormControl(decodeURIComponent(this._searchText));
		this.searchTextFormControl.valueChanges.pipe(
			debounceTime(500),
			filter(text => text.length > 3 || text.length === 0)
		).subscribe(d => this.refreshList(d));
	}

	ngAfterViewInit(): void {
		if (this.searchTextInput) {
			this.setUpSearchTextInput();
		}
		this.setupSearchTextFormControl();

	}

	setUpSearchTextInput(): void {
		fromEvent(this.searchTextInput.nativeElement, 'keyup')
			.pipe(
				map<unknown, string>(e => ((e as KeyboardEvent).target as HTMLInputElement).value),
				filter(text => text.length > 3 || text.length === 0),
				debounceTime(200)
			)
			.subscribe(r => this.refreshList(r));
	}

	updateURLQuery(params: HttpParams, history?: boolean) {
	
		if (history == null || history == undefined) {
			history = false;
		}
	
		
		if (this.mainRouter && this.activatedRoute) {
		
			const keys = params.keys();
			const queryParams = {};
			for (const key of keys) {
				const value = params.getAll(key).length > 1 ? params.getAll(key) : params.get(key);
				if (Array.isArray(value)) {
					if (value.filter(v => v.length > 0).length == 0) {
						continue;
					}
				}
				queryParams[key] = value;
			}
			
	
			this.mainRouter.navigate(
				[],
				{
					queryParams,
					queryParamsHandling: 'merge',
					skipLocationChange: false,
					replaceUrl: !history
				});
		}
	}

	initCommonListingResponse(result: ICommonListingResponse<T[]>): void {
		this.dataSource = new MatTableDataSource<T>(result.data);
		this.total = result.total;
	}

	initRightCommonListingResponse(resultright: ICommonListingResponse<T[]>): void {
		this.dataSourceRight = new MatTableDataSource<T>(resultright.data);
		this.totalright = resultright.total;
	}

	makeSearchParams(params?, newArray: boolean = false): HttpParams {
		return MakeSearchParams({
			searchText: this.searchText,
			page: this.page.toString(),
			limit: this.pageSize ? this.pageSize.toString() : '-1',
			...this.defaultParams,
			...params,
		}, newArray);
	}

	makeSearchParamsRight(params?): HttpParams {
		let paramObj: { [param: string]: string | ReadonlyArray<string>; } = {};
		if (params) {
			Object
				.keys(params)
				.map(k => {
					if (params[k]) {
						if (Array.isArray(params[k])) {
							paramObj[`${k}[]`] = params[k];
						} else {
							paramObj[k] = params[k];
						}
					}
				});
		}
		paramObj = {
			searchText: this.searchTextRight,
			...paramObj,
			page: this.pageright.toString(),
			limit: this.pageSizeRight.toString(),
			...this.defaultParams
		};

		return new HttpParams({
			fromObject: paramObj
		});
	}

	changePage(pageEvent: PageEvent): void {
		let changePage = false;
		if (this.pageSize !== pageEvent.pageSize) {
			this.pageSize = pageEvent.pageSize;
			changePage = true;
		}
		if (this.page !== pageEvent.pageIndex) {
			this.page = pageEvent.pageIndex;
			changePage = true;
		}
		this.populateList(this.searchFormGroup?.value || null, true);
	}

	changePageRight(pageEvent: PageEvent): void {
		let changePageRight = false;
		if (this.pageSizeRight !== pageEvent.pageSize) {
			this.pageSizeRight = pageEvent.pageSize;
			changePageRight = true;
		}
		if (this.pageright !== pageEvent.pageIndex) {
			this.pageright = pageEvent.pageIndex;
			changePageRight = true;
		}

		this.populateList(this.makeSearchParamsRight());
	}

	commonPopulateListObservable$(params?: HttpParams | { [param: string]: string | ReadonlyArray<string>; }, urlHistory?: boolean) {
		let listParams: HttpParams = this.makeSearchParams({
			searchText: this.searchText
		});
		if (params) {
			listParams = (params instanceof HttpParams) ? params : this.makeSearchParams(params);
		}
		return this.baseService
			.list(listParams)
			.pipe(
				tap(() => this.isLoading = true),
				tap(d => {
					if (params) {
						this.updateURLQuery(params as HttpParams, urlHistory)
					}
					return d;
				}),
				finalize(() => {
					this.isLoading = false;
					if (this.darvisService) {
						this.darvisService?.DrawerContentContainer?.scrollTo({
							top: 0,
							left: 0,
							behavior: 'smooth'
						});
					}
				})
			);
	}

	commonPopulateList(params?: HttpParams | { [param: string]: string | ReadonlyArray<string>; }, urlHistory?: boolean) {
		this.isLoading = true;
		return this.commonPopulateListObservable$(params, urlHistory)
			.subscribe((result) => {
				this.initCommonListingResponse(result);
				this.isLoading = false;
			});
	}

	

	compareCommonEntity(a: T, b: T) {
		return a?.id === b?.id;
	}
}

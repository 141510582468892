import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IFormPage } from '../interfaces/form-page';
import { Observable } from 'rxjs';
import { ICommonResponse } from '../interfaces/icommonResponse.interface';

@Injectable({
  providedIn: 'root'
})
export class ConsumerReportFormPageService {

  constructor(private readonly http: HttpClient) { }

  save(formData: IFormPage) {
    return this.http.post<IFormPage>(environment.consumerReport.baseURL + environment.consumerReport.createFormPage, formData);
  }

  saveDuplicatedFormPage(formData: any) {
    return this.http.post<any>(environment.consumerReport.baseURL + environment.consumerReport.duplicateFormPage, formData);
  }

  saveDuplicatedFormQuestion(formData: any) {
    return this.http.post<any>(environment.consumerReport.baseURL + environment.consumerReport.duplicateFormQuestion, formData);
  }

  saveFormQuestionAns(formData: any) {
    return this.http.post<any>(environment.consumerReport.baseURL + environment.consumerReport.formQuestionAnsFormPage, formData);
  }
 
  saveFormQuestionDependency(formData: any) {
    return this.http.post<any>(environment.consumerReport.baseURL + environment.consumerReport.formQuestionDependecyFormPage, formData);
  }
 

  saveFormQuestionMatric(formData: any) {
    return this.http.post<any>(environment.consumerReport.baseURL + environment.consumerReport.formQuestionMatricFormPage, formData);
  }
 
  delete(uuid: string) {
    return this.http.delete<IFormPage>(environment.consumerReport.baseURL + environment.consumerReport.deleteFormPage+'?id='+uuid);
  }

  uploadFileTos3Buckets(formID){
    return this.http.get<any>(environment.consumerReport.baseURL + environment.consumerReport.uploadtosbucket+'/'+formID);
    
  }

  
  getFormPage(formID: string | number ) : Observable<any>  {
		return this.http.get<ICommonResponse<IFormPage>>(environment.consumerReport.baseURL+ environment.consumerReport.formPageByFormID + '/' + formID )
	}
}

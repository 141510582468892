import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ConfirmDeleteDialogComponent } from '../../components/confirm-delete-dialog/confirm-delete-dialog.component';
import { IConsumerReportUser } from '../../interfaces/consumerReportUser.interface';
import { ConsumerReportUserService } from '../../services/consumerReportUserService.service';
import { AuthService } from 'src/app/auth/auth.service';
import moment from 'moment';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { UserService } from 'src/app/security-guard/services/user.service';

@Component({
  selector: 'app-index',
  templateUrl: './customer-index.component.html',
  styleUrls: ['./customer-index.component.scss']
})
export class CustomerIndexComponent implements OnInit {

  userList: IConsumerReportUser[];
  displayedColumns: string[] = [
    'account',
    'paidReport',
    'status',
    'createdDate',
    'lastLoggedOn',
    'accountManager',
    'latestSearch',
    'action'
  ];

  searchCtrl = new FormControl();
  searchTerms: string[] = [];
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  length = 0;
  pageSize = 15;
  page = 0;
  pageSizeOptions: number[] = [15, 25, 50, 100];

  pageEvent: PageEvent;

  constructor(
    private readonly userService: ConsumerReportUserService,
    private readonly securityGuardUserService: UserService,
    private readonly dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService
  ) {
    this.route.queryParams.subscribe(params => {
      if (params.page) {
        this.page = params.page;
      }
      if (params.limit) {
        this.pageSize = params.limit;
      }
      if (params.searchTerms) {
        this.searchTerms = params.searchTerms.split(',');
      }

    });
  }

  changePage(pageEvent: PageEvent) {
    let changePage = false;
    if (this.pageSize != pageEvent.pageSize) {
      this.pageSize = pageEvent.pageSize;
      changePage = true;
    }
    if (this.page != pageEvent.pageIndex) {
      this.page = pageEvent.pageIndex;
      changePage = true;
    }

    this.listUser();

  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.searchTerms.push(value.trim());
      this.searchTerms = [...new Set(this.searchTerms)];
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.searchCtrl.setValue(null);
    this.listUser();
  }

  remove(fruit: string): void {
    const index = this.searchTerms.indexOf(fruit);

    if (index >= 0) {
      this.searchTerms.splice(index, 1);
      this.listUser();
    }
  }

  ngOnInit(): void {
    this.listUser();
  }

  changePassword(): void {
  }

  disableUser(id: number): void {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '350px',
      data: id
    });
    dialogRef.afterClosed().subscribe(v => {
      if (!v) {
        this.listUser();
      }
    });
  }

  async resyncUser(email: string): Promise<void> {
    await firstValueFrom(this.securityGuardUserService.syncCognitoUserByEmail(email))
  }

  enableUser(id: number): void {
    this.userService.enableProfile(id)
      .subscribe(v => {
        if (v.active) {
          this.listUser();
        }
      });
  }

  makeReportLink(id) {
    return environment.consumerReport.reportBaseURL + environment.consumerReport.reportUpdatePath + '/' + id;
  }

  listUser() {    
    this.userService.listUser(this.searchTerms, { page: this.page, pageSize: this.pageSize, customer: true })
      .pipe(
        map<ICommonListingResponse<IConsumerReportUser[]>, IConsumerReportUser[]>(v => {
          this.length = v.total;

          return v.data.map(v => {
            return {
              ...v,
              name: v.displayName + ' - ' + v.email,
              latestSearch: v.latestSearch?.map(vv => {
                return {
                  ...vv,
                  link: environment.consumerReport.reportBaseURL + environment.consumerReport.reportUpdatePath + '/' + vv.commonID
                };
              })
            };
          });
        }),
        map(v => {
          const returnVal = [];
          for (const d of v) {
            returnVal.push({
              ...d,
              awsStatus: from(this.authService.findUserByID(d.awsID)).pipe(map(user => {
                let returnStatus = 'Inactive';
                if (user.Users && d.active && user.Users.length > 0) {
                  const awsUser = user.Users[0];
                  returnStatus = 'Active';
                  if (awsUser.UserStatus == 'FORCE_CHANGE_PASSWORD') {
                    returnStatus = 'Pending login';
                  }
                }

                return returnStatus;
              }))
            });
          }

          return returnVal;
        })
      ).subscribe(v => {
        this.userList = v
      });
    window.history.replaceState({}, '', `/consumer-report/customer?limit=${this.pageSize}&page=${this.page}&searchTerms=${this.searchTerms.join(',')}`);
  }

}

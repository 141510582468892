import { trigger, state, style, transition, animate } from '@angular/animations';
import { NestedTreeControl } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeFlattener, MatTreeFlatDataSource, MatTreeModule } from '@angular/material/tree';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter, distinctUntilChanged } from 'rxjs/operators';

interface MenuNode {
  isExpanded?: boolean
  name: string
  iconName?: string
  link?: string
  active?: boolean
  children?: MenuNode[]
  level?: number
  className?:string
  disabled?:boolean
}

interface MenuFlatNode {
  isExpanded?:boolean
  expandable?: boolean;
  name: string;
  iconName?: string;
  link?: string;
  level?: number;
  className?:string;
  disabled?:boolean
}


let MenuItem: MenuNode[] = [
  {
    name: 'Consumer Report',
    iconName: 'insert_chart_outlined',
    isExpanded: false,
    link: '/consumer-report/customer',
    children: [
      {
        name: 'Customer',
        iconName: '',
        link: '/consumer-report/customer',
        active: false
      },
      {
        name: 'Account Manager',
        iconName: '',
        link: '/consumer-report/account-manager'
      },
      {
        name: 'Forms',
        iconName: '',
        link: '/consumer-report/form'
      },
      {
        name: 'Questions',
        iconName: '',
        link: '/consumer-report/question'
      },
      {
        name: 'Brands',
        iconName: '',
        link: '/consumer-report/brand'
      },
      {
        name: 'Stores',
        iconName: '',
        link: '/consumer-report/store'
      },
      {
        name: 'Influencers',
        iconName: '',
        link: '/consumer-report/influencer'
      },
      {
        name: 'Submissions',
        iconName: '',
        link: '/consumer-report/participant-submission'
      },
      {
        name: 'Reports',
        iconName: '',
        link: '/consumer-report/reports'
      },
      {
        name: 'Reports Group',
        iconName: '',
        link: '/consumer-report/reports-group'
      }
    ]
  },
  {
    name: 'Salon Finder',
    iconName: 'content_cut',
    isExpanded: false,
    link: '/salon-finder/advertisers',
    children: [
      {
        name: 'Advertisers',
        iconName: '',
        link: '/salon-finder/advertisers'
      },
      {
        name: 'Collections',
        iconName: '',
        link: '/salon-finder/collections'
      },
      {
        name: 'Transactions/Finance',
        iconName: '',
        link: '/salon-finder/finance'
      },
      {
        name: 'Email Templates',
        iconName: '',
        link: '/salon-finder/email-notification'
      }
    ]
  },
  {
    name: 'Native Ads',
    iconName: 'perm_media',
    isExpanded: false,
    link: '/native-ads/ads-report',
    children: [
      {
        name: 'Client',
        iconName: '',
        link: '/native-ads/client'
      },
      {
        name: 'Campaign',
        iconName: '',
        link: '/native-ads/campaign'
      },
      {
        name: 'Publisher',
        iconName: '',
        link: '/native-ads/publisher'
      },
      {
        name: 'Ads Report',
        iconName: '',
        link: '/native-ads/ads-report'
      }
    ]
  },
  {
    name: 'Security Guard',
    iconName: 'local_police',
    isExpanded: false,
    link: '/security-guard/users',
    children: [
      {
        name: 'Users',
        iconName: '',
        link: '/security-guard/users'
      },
      {
        name: 'Requests',
        iconName: '',
        link: '/security-guard/user-request'
      },
      {
        name: 'Roles',
        iconName: '',
        link: '/security-guard/roles'
      },
      {
        name: 'Services',
        iconName: '',
        link: '/security-guard/service'
      },
    ]
  },
  {
    name: 'Short URL',
    iconName: 'link',
    isExpanded: false,
    link: '/short-url/dashboard',
    children: [
      {
        name: 'Dashboard',
        iconName: '',
        link: '/short-url/dashboard'
      },
    ]
  },
  {
    name: 'Report',
    iconName: 'stacked_bar_chart',
    isExpanded: false,
    link: '/telegram/post',
    children: [
      {
        name: 'Telegram',
        iconName: 'telegram',
        link: '/telegram/post',
        className:'main-telegram',
        disabled: true
      },
      {
        name: 'Followers',
        iconName: '',
        link: '/telegram/follower',
        className: 'sub-telegram'
      },
      {
        name: 'Posts',
        iconName: '',
        link: '/telegram/post',
        className: 'sub-telegram'
      },
      {
        name: 'Notifications',
        iconName: '',
        link: '/telegram/notification',
        className: 'sub-telegram'
      },
      // ,{
      //   name: 'Messages',
      //   iconName: '',
      //   link: '/telegram/messages',
      //   isExpanded: false
      // }

    ]
  }

]


@Component({
  selector: 'darvis-share-side-menu',
  animations: [
    trigger('openClose', [
      // ...
      state('open', style({
        height: "auto"
      })),
      state('closed', style({
        height: '0px'
      })),
      transition('open => closed', [
        animate('1s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ]),
  ],
  standalone: true,
  imports: [BrowserAnimationsModule, MatTreeModule, MatIconModule, CommonModule, RouterModule, MatButtonModule],
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  currentSelectedNode:any;

  clickLink(mainSection, link) {
    MenuItem = MenuItem.map(m=> {
      if(m.name === mainSection.name) {
        m.isExpanded = !m.isExpanded

      } else {
        m.isExpanded = false
        m.children = m.children.map(c=> {
          c.active = false
          return c
        })
      }
      return m
    })
  }

  triggerSection(section) {
    MenuItem = MenuItem.map(m=> {
      if(m.name === section.name) {
        m.isExpanded = true
        let node = this.treeControl.dataNodes.find(dn=> dn.name == m.name);
        this.treeControl.expand(node);
      
        let nodeLink = this.treeControl.dataNodes.find(dn=> dn.link == m.link);
        this.currentSelectedNode = nodeLink;
   
        if(m.link) {
          this.router.navigateByUrl(m.link)
        }
      } else {
        m.isExpanded = false
        let node = this.treeControl.dataNodes.find(dn=> dn.name == m.name);
        this.treeControl.collapse(node);
      }

     
      return m
    })
  }

  private _transformer = (node: MenuNode, level: number): MenuFlatNode => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      iconName: node.iconName,
      link: node?.link,
      level: level,
      isExpanded : node.isExpanded,
      className: node.className,
      disabled: node.disabled
    };
  };

  treeControl = new NestedTreeControl<MenuNode>(
    node => node.children
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(
    public router: Router,
    private activatedRoute:ActivatedRoute
  ) {
    this.dataSource.data = MenuItem
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      distinctUntilChanged()
    ).subscribe(
      (route: any) => {
        if(route.url) {
          const getNumber = route.url.indexOf('?') >=0? route.url.indexOf('?') : route.url.length
          const selectedURL = route.url.substring(0, getNumber);
          const firstSection = selectedURL.split('/')[1];
          if(firstSection === null || firstSection === '' || firstSection === 'index') { 
            this.treeControl.collapseAll()
            return;
          }
          for(let i=0; i<this.treeControl.dataNodes.length; i++) {
            const dataNode = this.treeControl.dataNodes[i];
            if(selectedURL.indexOf(dataNode.link) >= 0) {
              dataNode.isExpanded = true
            } else {
              dataNode.isExpanded = false
            }
            if(dataNode.level === 0) {
              if(dataNode.link.indexOf(firstSection) >= 0) {
                dataNode.isExpanded = true
              }
            }
            
            this.treeControl.dataNodes[i] = dataNode
          }
          this.treeControl.collapseAll()
          for(const dataNode of this.treeControl.dataNodes) {
            if(dataNode.isExpanded) {
              this.treeControl.expand(dataNode);
            }
            
          }

        }
      
    }
    );
  }

  hasChild = (_: number, node: MenuFlatNode) => node.expandable;


}

import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export const FieldsCompareValidator = (field: string, compareField: string) => {
	return (formGroup: FormGroup) => {
		const passwordControl = formGroup.controls[field];
		const confirmPasswordControl = formGroup.controls[compareField];

		if (!passwordControl || !confirmPasswordControl) {
			return null;
		}

		if (confirmPasswordControl.errors && !confirmPasswordControl.errors.fieldsCompareValidator) {
			return null;
		}

		if (passwordControl.value !== confirmPasswordControl.value) {
			confirmPasswordControl.setErrors({ fieldsCompareValidator: true });
		} else {
			confirmPasswordControl.setErrors(null);
		}

	}
}
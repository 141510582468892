import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { UrlDetails } from '../../interfaces/IUrlDetails';
import { UrlShortenerCollectionService } from '../../services/url-shortener-collection.service';
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DateFormat } from 'src/app/shared/helpers/DateFormat';

interface Period {
  value: string;
  viewValue: string;
}

export enum ToggleEnumRange {
  Daily,
  Weekly,
  Monthly
}

@Component({
  selector: 'url-shortener-detail',
  templateUrl: './url-shortener-detail.component.html',
  styleUrls: ['./url-shortener-detail.component.scss'],
  providers: [   
    {provide: MAT_DATE_FORMATS, useValue: DateFormat},
  ],
})
export class UrlShortenerDetailComponent implements OnInit {

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  generatingChart = false;
  periodLabel = [];
  periodTotalClicks = [];
  periodUniqueClicks = [];
  urlDetails: UrlDetails;
  urlInfo:any;
  returnFilter:any;
  maxValue = 10;
  showCustomDateRange = false;
  urlId;

  range = new FormGroup({
    startRange: new FormControl<Date | null>(null),
    endRange: new FormControl<Date | null>(null),
  })


  periods: Period[] = [
    {value: 'TODAY', viewValue: 'Today'},
    {value: 'WEEK', viewValue: 'Past 7 Days'},
    {value: 'MONTH', viewValue: 'Past 30 Days'},
    {value: 'QUARTER', viewValue: 'Past 3 Months'},
    {value: 'LIFETIME', viewValue: 'Lifetime'},
    {value: 'CUSTOM', viewValue: 'Custom'},
  ];
  toggleEnumRange = ToggleEnumRange;
  selectedRange = ToggleEnumRange.Weekly;
  selected = 'QUARTER';

  public lineChartData: ChartConfiguration<'line'>['data'] = {
    labels : this.periodLabel,
    datasets: [
      {
        data: [],
        label: 'Total Clicks',
      },
      {
        data: [],
        label: 'Unique Clicks',
      },
    ]
  };
  public lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    scales: {
      y: {
        min: 0,
        max: this.maxValue
      },
    },
    elements: {
      line: {
          tension: 0 // disables bezier curves
      }
    }
    
  };
  
  public lineChartLegend = true;
  

  constructor(
    private readonly route: ActivatedRoute,
    private readonly urlShortenerService: UrlShortenerCollectionService,
    private readonly router: Router
  ) { 
    route.queryParams.subscribe((filter:any) => {
      if(Object.keys(filter).length !== 0) this.returnFilter = filter;
    })

  }

  back(){
    this.router.navigate(['short-url/dashboard'],{ queryParams: this.returnFilter });
  }

  ngOnInit(): void {
    this.urlId = this.route.snapshot.paramMap.get('id');
    if(this.urlId){
      this.getUrlDetail(this.urlId,this.selected);
      this.getUrlInfo(this.urlId);
    } 
  }

  async getUrlInfo(urlId:any){
    this.urlShortenerService.getUser(urlId).subscribe((data:any) =>{
      this.urlInfo = data;
    })
  }
  
  async getUrlDetail(urlId, period:string, dateRange?:string){
    this.urlShortenerService.getUrlDetail(Number(urlId),period, dateRange)
    .subscribe((res:any) => {
      if(res){
        this.urlDetails = res as UrlDetails;   
        this.loadClicks(Number(urlId),period, dateRange)
      }
    });
  
  }
  
  async export(){
    
    this.urlShortenerService.export(this.urlId)
    .subscribe((res:any) => {
      if(res){
       const options = { 
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true, 
        showTitle: true,
        title: '',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "Short URL Detail -" + this.urlId
      }; 
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(res);
      }
    });
  }
  
  async loadClicks(id:number, period:string, dateRange?:string){
    let periodValueHolder = [];
    this.periodLabel = [];
    this.periodTotalClicks = [];
    this.periodUniqueClicks = [];
    this.generatingChart = true;
    this.urlShortenerService.getClicks(id,period,this.selectedRange, dateRange)
    .subscribe((res:any) => {
      this.generatingChart = false;
      if(res !== 'No data found'){
        res.map((period:any) => {
          this.periodLabel.push(period.date);
          periodValueHolder.push(period.TotalClicks);
          periodValueHolder.push(period.UniqueClicks);
          this.periodTotalClicks.push(period.TotalClicks);
          this.periodUniqueClicks.push(period.UniqueClicks);
        })

        this.maxValue = Math.max(... periodValueHolder) > this.maxValue ? Math.max(... periodValueHolder) : this.maxValue ;

        this.lineChartOptions.scales.y.max = this.maxValue
        
        this.lineChartData.datasets[0].data = this.periodTotalClicks;
        this.lineChartData.datasets[1].data = this.periodUniqueClicks;         
        this.lineChartData.labels = this.periodLabel;
        this.chart?.chart.update();
      }
  
    });
  }

  selectedPeriod(event:MatSelectChange){
    if(event.value == 'CUSTOM'){
      this.showCustomDateRange = true;
    }else{
      this.showCustomDateRange = false;
      this.generatingChart = true;
      if(event.value === 'LIFETIME') this.selectedRange = this.toggleEnumRange.Monthly;
      if(event.value === 'QUARTER') this.selectedRange = this.toggleEnumRange.Weekly;
      if(event.value === 'TODAY') this.selectedRange = this.toggleEnumRange.Daily;
      if(event.value === 'WEEK') this.selectedRange = this.toggleEnumRange.Daily;
      if(event.value === 'MONTH') this.selectedRange = this.toggleEnumRange.Daily;
      
      this.periodLabel = [];
      this.periodTotalClicks = [];
      this.periodUniqueClicks = [];
      this.chart.data.labels = [];
      this.lineChartData.datasets[0].data = [];
      this.lineChartData.datasets[1].data = [];
  
      this.chart?.chart.update();
      
      if(this.urlId) {
        this.getUrlDetail(Number(this.urlId),event.value)
        // this.loadClicks(Number(urlId),event.value);
      }
    }

  }

  onChangeRange($event) {
    this.selectedRange = $event.value;  
    if(this.selected === 'QUARTER' || this.selected === 'LIFETIME' || this.selected === 'MONTH'){
      this.loadClicks(Number(this.urlId),this.selected);
    }else{
      let rangeStartEnd = moment(this.range.value.startRange).format("YYYY-MM-DD") +":"+ moment(this.range.value.endRange).format("YYYY-MM-DD") 
      this.loadClicks(Number(this.urlId),this.selected,rangeStartEnd);
    } 
  }

  checkToDisplayRange(){
    return this.selected === 'QUARTER' || this.selected === 'LIFETIME' || this.selected === 'MONTH' || this.selected === 'CUSTOM' ;
  }

  filterFutureDates = (d: any): boolean => {
      let futureDate  = moment(d).isAfter(new Date, 'day');
      if(futureDate) return false;

      let pastDate  = moment(d).isBefore(new Date, 'day');
      if(pastDate) return true;
  };


  onFetchData() {
    if(this.range.value.startRange && this.range.value.endRange){
        let rangeStartEnd = moment(this.range.value.startRange).format("YYYY-MM-DD") +":"+ moment(this.range.value.endRange).format("YYYY-MM-DD") 
        if(this.urlId){
          this.getUrlDetail(Number(this.urlId),'CUSTOM',rangeStartEnd);
        }
    }
  }


}
import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Observable } from 'rxjs';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { environment } from 'src/environments/environment';
import { IPublisher } from '../interfaces/publisher';

@Injectable({
  providedIn: 'root'
})
export class NativeAdsPublisherService extends CRUDService<IPublisher>{
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }
  list(params?: HttpParams): Observable<ICommonListingResponse<IPublisher[]>> {
    return this.httpList(params)
  }
  get(id: string | number): Observable<IPublisher> {
    return this.httpGet(id.toString())
  }
  delete(id: string | number): Observable<IPublisher> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: IPublisher): Observable<IPublisher> {
    throw new Error('Method not implemented.');
  }
  create(model: IPublisher): Observable<IPublisher> {
    throw new Error('Method not implemented.');
  }
  makeFormGroup(loadedModel?: IPublisher) {
    return this.fb.group({
      id: [loadedModel?.id],
      name: [loadedModel?.name, [Validators.required, Validators.maxLength(125)]],
      url: [loadedModel?.url, [Validators.required, RxwebValidators.url]],
      active: [loadedModel?.active],
      status: [loadedModel?.status], 
      defaultSelect: [loadedModel?.defaultSelect],
      category: [loadedModel?.category],
      clientID: [loadedModel?.clientID],
      preferedGender: [loadedModel?.preferedGender],
      firstActiveMetrics: [loadedModel?.firstActiveMetrics],
      lastActiveMetrics: [loadedModel?.lastActiveMetrics],
      imageURL: [loadedModel?.imageURL]
    })
  }
  apiPath = environment.nativeAds.publisherBase
  environmentCRUDPath = environment.nativeAds;

  constructor(
    public readonly httpClient: HttpClient,
    public readonly fb: FormBuilder,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute
  ) {
    super();
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDeleteDialogComponent } from '../confirm-delete-dialog/confirm-delete-dialog.component';
import { IContent} from '../../interfaces/iStaticReportConfig';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { boolean, number } from 'mathjs';
import { FontAwesomeIcons } from '../../constant/FontAwesomeIcons';
import { Observable, map, startWith } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { DisplayType } from '../../enums/staticReportConfig.enum';

export interface IConfigLayout{
  displayWidth : string,
  displayPercentage : boolean,
  icon: string,
  bgColor: string,
  customName: string,
}

export interface ISelectedContent {
  content : IConfigLayout,
  displayType: string
}

interface Column {
  value: number;
  name: string;
}

@Component({
  selector: 'app-reports-form-layout-dialog',
  templateUrl: './reports-form-layout-dialog.component.html',
  styleUrls: ['./reports-form-layout-dialog.component.scss']
})
export class ReportsFormLayoutDialogComponent implements OnInit {
  
  layoutForm : FormGroup;

  model : IConfigLayout;

  checkedShowTotal:boolean = false;

  icons = FontAwesomeIcons;

  filteredIcons: Observable<string[]>;

  columns: Column[] = [];
   

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ISelectedContent,
    public dialogRef: MatDialogRef<ReportsFormLayoutDialogComponent>,
    private formBuilder: FormBuilder,
  ){
    this.model = this.data.content;

    if(this.data.displayType == DisplayType.STATISTICS){
      this.columns = [
        {value: 3, name: '1/4'},
        {value: 6, name: '2/4'},
        {value: 9, name: '3/4'},
        {value: 12, name: '4/4'},
      ];
    }else{
      this.columns = [
        {value: 6, name: '2/4'},
        {value: 12, name: '4/4'},
      ];
    }

    this.layoutForm = this.formBuilder.group({
      displayWidth : [this.model?.displayWidth || '6'],
      displayPercentage : [this.model?.displayPercentage || false],
      icon: [this.model?.icon || ''],
      bgColor: [this.model?.bgColor || ''],
      customName: [this.model?.customName || '']
    });
  }
  ngOnInit() { 

    this.filteredIcons = this.layoutForm.get('icon').valueChanges.pipe(
      startWith(this.layoutForm.get('icon').value.icon),
      map(value => {
        return this._filter(value || '')
      }),
    );
  }
  
  selectedIcon(event: MatAutocompleteSelectedEvent){
    this.layoutForm.get('icon').setValue(event.option.value)
    this.layoutForm.value.icon = event.option.value
  }

  save(){
    this.dialogRef.close(this.layoutForm.value);
  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.icons.filter(icon => icon.toLowerCase().includes(filterValue));
  }
}

<form [formGroup]="paymentForm">
  <div class="row">
    <div class="col-3 mt-3">
      <mat-form-field class="fullWidth" appearance="fill">
        <mat-label>Payment Month</mat-label>
        <input matInput [matDatepicker]="dp" [formControl]="payingMonth" />
        <mat-hint>MMM YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
        <mat-datepicker #dp
          startView="multi-year"
          (monthSelected)="setMonthAndYear($event, dp)"
          panelClass="example-month-picker">
        </mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <mat-form-field class="col-12" appearance="fill">
      <input type="text"
      placeholder="Type anything to search"
      aria-label="Type anything to search"
      matInput
      appearance="fill"
      autocomplete="off" 
      formControlName="searchText">
    </mat-form-field>
  </div>
</form>

  <div class="row">
    <div class="col-12">
      <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
        <ng-container matColumnDef="Merchant">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Merchant</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}"> 
            {{element.advertiser.name}}
          </td>
        </ng-container>
        <ng-container matColumnDef="Date Payment">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Payment Date</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}"> 
            {{element.paymentDate | dateTimeFromIso  | dateTimeToLocal | dateTimeToFormat:'dd MMM yyyy' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="voucherNo">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Voucher no.</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}"> 
            {{element.orderVoucher.length}}
          </td>
        </ng-container>
        <ng-container matColumnDef="GMV">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>GMV</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}"> 
            {{ calculateTransactions(element.orderVoucher, OrderTransationTypeEnum.INITIAL_AMT)  | currency}}
          </td>
        </ng-container>
        <ng-container matColumnDef="GST">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>GST</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}"> 
            {{ element.taxAmt }} ({{ element.taxRate }})
          </td>
        </ng-container>
        <ng-container matColumnDef="amountPaid">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Payment before GST</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}"> 
            {{ calculateTransactions(element.orderVoucher, OrderTransationTypeEnum.ADVERTISER_PAYMENT) * -1 | currency}}
          </td>
        </ng-container>
        <ng-container matColumnDef="amountPaidGST">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Payment after GST</th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}"> 
            {{ (calculateTransactions(element.orderVoucher, OrderTransationTypeEnum.ADVERTISER_PAYMENT) * -1 )- element.taxAmt | currency }}
          </td>
        </ng-container>
      
        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef> Actions </th>
          <td mat-cell *matCellDef="let element;">
            <button (click)="downloadReport(element)"><strong>Download Report</strong></button>
          </td>
        </ng-container> 
        <tr mat-header-row *matHeaderRowDef="listingCols" class="p-2"></tr>
        <tr mat-row *matRowDef="let row; columns: listingCols;" class="p-2"></tr>
      </table>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-12">
      <mat-paginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" [pageIndex]="page"
      (page)="changePage($event)">
      </mat-paginator>
    </div>
  </div>

  <ng-container *ngIf="loading$ | async">
    <div class="overlay"></div>
    <mat-progress-spinner [mode]="'indeterminate'" [diameter]="50"></mat-progress-spinner>
  </ng-container>
<form [formGroup]="formData">
    <div class="container">
      <mat-card>
        <mat-card-header>
          <mat-card-title class="pt-3 pb-3">
            <span *ngIf="!formData.value.id">Adding Outlet for {{ loadedAdvertiser?.name }}</span>
            <span *ngIf="formData.value.id">Updating {{ formData.value.name }} information</span>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <section>
            <h2>Outlet Information</h2>
            <div class="row">
              <mat-form-field class="col-6 col-sm-12 col-md-6" appearance="fill">
                <input matInput maxlength="50" placeholder="Name" formControlName="name" (keyup)="updateOutletCodePrefix($event)">
                <mat-error>
                  <span *ngIf="formData.get('name').hasError('required')">Outlet name is <strong>required</strong></span>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-6 col-sm-6 col-md-3" appearance="fill">                
                <input matInput maxlength="50" placeholder="Outlet Code Prefix" formControlName="outletCodePrefix"  maxlength="5">
                <mat-error>
                  <span *ngIf="formData.get('outletCodePrefix').hasError('required')">Outlet Code prefix is <strong>required</strong></span>
                  <span *ngIf="formData.get('outletCodePrefix').hasError('maxlength')">Outlet Code prefix must be <strong>5</strong> char or less</span>
                  
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-6 col-sm-6 col-md-3" appearance="fill">         
                <input matInput maxlength="50" placeholder="Outlet Code" formControlName="outletCodeNumber"  maxlength="10">
                <mat-error>
                  <span *ngIf="formData.get('outletCodeNumber').hasError('required')">Outlet Code is <strong>required</strong></span>
                  <span *ngIf="formData.get('outletCodeNumber').hasError('maxlength')">Outlet Code must be <strong>10</strong> char or less</span>
                  <span *ngIf="formData.get('outletCodeNumber').hasError('numeric')">Outlet Code must be <strong>numeric</strong></span>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-6 col-sm-12 col-md-6" appearance="fill">
                <input matInput maxlength="50" placeholder="Contact No" formControlName="phone">
                <mat-error>
                  <span *ngIf="formData.get('phone').hasError('required')">Contact No is <strong>required</strong></span>
                </mat-error>
                <mat-error>
                  <span *ngIf="formData.get('phone').hasError('min')">Phone number should be positive</span>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-6 col-sm-12 col-md-6" appearance="fill">
                <input matInput maxlength="50" placeholder="Address" formControlName="address">
                <mat-error>
                  <span *ngIf="formData.get('address').hasError('required')">Address is <strong>required</strong></span>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-6 col-sm-12 col-md-6" appearance="fill">
                <input matInput maxlength="6" placeholder="Postal Code" formControlName="postalCode">
                <mat-error>
                  <span *ngIf="formData.get('postalCode').hasError('required')">Postal Code is <strong>required</strong></span>
                </mat-error>
                <mat-error>
                  <span *ngIf="formData.get('postalCode').hasError('minlength')">Postal Code should be 6 digits and a positive number</span>
                </mat-error>
                <mat-error>
                  <span *ngIf="formData.get('postalCode').hasError('maxlength')">Postal Code should be 6 digits and a positive number</span>
                </mat-error>
                <mat-error>
                  <span *ngIf="formData.get('postalCode').hasError('pattern')">Postal Code should be numeric value</span>
                </mat-error>
              </mat-form-field>
              <div class="col-6 col-sm-12 col-md-6">
                <mat-form-field  class="fullWidth" appearance="fill">
                  <input matInput maxlength="1024" placeholder="Email to receive Booking & Enquiries" formControlName="email">
                  <mat-error>
                    <span *ngIf="formData.get('email').hasError('required')">Email to receive Booking & Enquiries is <strong>required</strong></span>
                  </mat-error>
                  <mat-error *ngIf="formData.get('email').hasError('email') && !formData.get('email').hasError('required')">
                    <strong>Please enter a valid email address</strong>
                  </mat-error>
                </mat-form-field>
                <span style="font-size:12px;">(Allows one email only)</span><br>
                <span style="font-size:12px;">(Email to receive email bookings and enquiries)</span>
              </div>
              <div class="col-6 col-sm-12 col-md-6">
                <mat-form-field class="fullWidth" appearance="fill">
                  <input matInput maxlength="1024" placeholder="Email to receive Notifications" formControlName="notificationEmail">
                  <mat-error>
                    <span *ngIf="formData.get('notificationEmail').hasError('required')">Email to receive Notifications is <strong>required</strong></span>
                  </mat-error>
                </mat-form-field>
                <span style="font-size:12px;">(Add comma between multiple emails)</span><br>
                <span style="font-size:12px;">(Email to receive notifications)</span>
              </div>
              
            </div>
          </section>
          

          <section formGroupName="bankingEntity">
            <h2>Banking Information</h2>
            <div class="row">
              <mat-form-field class="col-6 col-sm-12 col-md-6" appearance="fill">
                <mat-label>Bank account name (Legal Entity Name)</mat-label>
                <input matInput maxlength="50" placeholder="Bank account name (Legal Entity Name)" formControlName="bankName">
                <mat-error>
                  <span *ngIf="bankingEntity.get('bankName').hasError('required')">Bank account Name is <strong>required</strong></span>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-6 col-sm-12 col-md-6" appearance="fill">
                <mat-label>Bank Account Name</mat-label>
                <input matInput maxlength="50" placeholder="Registered Entity Name" formControlName="entityName">
                <mat-error>
                  <span *ngIf="bankingEntity.get('entityName').hasError('required')">Entity Name is <strong>required</strong></span>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-6 col-sm-12 col-md-6" appearance="fill">
                <mat-label>Bank Account Number</mat-label>
                <input matInput maxlength="50" placeholder="Bank Account" formControlName="bankAccount">
                <mat-error>
                  <span *ngIf="bankingEntity.get('bankAccount').hasError('required')">Bank Account is <strong>required</strong></span>
                </mat-error>
              </mat-form-field>
              <div class="col-6 col-sm-12 col-md-6">
                <mat-form-field class="fullWidth" appearance="fill">
                  <mat-label>Finance email</mat-label>
                  <input matInput maxlength="1024" placeholder="Email to receive Financial Statements" formControlName="entityEmail">
                  <mat-error>
                    <span *ngIf="bankingEntity.get('entityEmail').hasError('required')">Email to receive Financial Statements is <strong>required</strong></span>
                  </mat-error>
                  <mat-error *ngIf="bankingEntity.get('entityEmail').hasError('email') && !bankingEntity.get('entityEmail').hasError('required')">
                    <strong>Please enter a valid email address</strong>
                  </mat-error>
                </mat-form-field>
                <span style="font-size:12px;">(Add comma between multiple emails)</span><br>
                <span style="font-size:12px;">(Email to receive Financial Statements)</span>
              </div>
              
              <div class="col-6 col-sm-12 col-md-6 mt-4">                  
                <mat-slide-toggle formControlName="sendToAdvertiser">
                  Send Advertiser a copy
                </mat-slide-toggle>
              </div>
            </div>
          </section>
          <section class="mt-3">
            <h2>Additional Information</h2>
            <div class="row">
              <div class="col-6 col-sm-12 col-lg-6" formArrayName="opHours">
                Operation Hours : 
                <mat-grid-list cols="4" rowHeight="30px">
                  <mat-grid-tile class="title">Day</mat-grid-tile>
                  <mat-grid-tile class="title">Start Hour</mat-grid-tile>
                  <mat-grid-tile class="title">End Hour</mat-grid-tile>
                  <mat-grid-tile class="title">Closed</mat-grid-tile>
                </mat-grid-list>
                <mat-grid-list cols="4" rowHeight="30px" *ngFor="let ophour of opHours.controls; let m=index">
                  <ng-container [formGroupName]="m">
                    <mat-grid-tile>{{ ophour.value.key }}</mat-grid-tile>
                    <mat-grid-tile>
                      <input aria-label="default time" [class.error]="ophour.errors?.errorHours" [ngxTimepicker]="starthrValue" [value]="ophour.value.starthr | dateTimeToLocal | dateTimeToFormat:'HH:mm'" style="width: 75px;" readonly />
                      <ngx-material-timepicker #starthrValue (timeSet)="handleTime(opHours.controls[m], $event, 'startTime')"></ngx-material-timepicker>
                    </mat-grid-tile>
                    <mat-grid-tile>
                      <input aria-label="default time" [class.error]="ophour.errors?.errorHours" [ngxTimepicker]="endhrValue" [value]="ophour.value.endhr | dateTimeToLocal | dateTimeToFormat:'HH:mm'" style="width: 75px;" readonly />
                      <ngx-material-timepicker #endhrValue (timeSet)="handleTime(opHours.controls[m], $event, 'endTime')"></ngx-material-timepicker>
                    </mat-grid-tile>
                    <mat-grid-tile><mat-checkbox formControlName="closed"></mat-checkbox></mat-grid-tile>
                  </ng-container>
                </mat-grid-list>
                <mat-error>
                  <span *ngIf="operationHoursErrors()">Please check operation hours. Opening time cannot be later than closing time.</span>
                </mat-error>
              </div>
              <div class="col-6 col-sm-12 col-lg-6 imageWrapper position-relative" >
                Outlet Images : 
                <div class="imagesContainer" cdkDropList (cdkDropListDropped)="drop($event)">
                  <div class="col-12 image-box position-relative mb-2 border border-dark" *ngFor="let outletImage of outletImages" cdkDrag>
                    <img width="100%" src="{{outletImage.url}}" />
                    <div class="position-absolute top-0 end-0">
                      <button mat-icon-button color="warn" (click)="deleteImage(outletImage)">
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
                <input type="file" class="file-upload" multiple class="d-none" (change)="upload($event)" #uploadInput accept="image/*">
                <div class="position-absolute bottom-0 end-0">
                  <button mat-mini-fab color="primary" class="upload-btn"
                    (click)="uploadInput.click()">
                      <mat-icon>attach_file</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </section>
          
          <div class="row">
            <div class="col-12">
              <mat-label>Description</mat-label>
              <quill-editor formControlName="description" [styles]="{height: '150px'}">                  
              </quill-editor>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <mat-slide-toggle class="example-margin" formControlName="creditCard">
                Credit Card
              </mat-slide-toggle>
            </div>
            <div class="col-6">
              <mat-slide-toggle class="example-margin" formControlName="active">
                Active
              </mat-slide-toggle>
            </div>
          </div>
          <mat-card-actions>
            <div class="row">
              <div class="col-12 text-center">
                <button mat-raised-button color="primary" class="m-2" (click)="save()">Save</button>
                <button mat-raised-button class="m-2"  [routerLink]="['/salon-finder/advertisers/update', loadedAdvertiser?.id || loadedModel?.advertiserID]">Cancel</button>
              </div>
            </div>
          </mat-card-actions>
        </mat-card-content>
      </mat-card>   
    </div>
  </form>
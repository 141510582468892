import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  formData: FormGroup = null

  constructor(private readonly authService: AuthService, private readonly router: Router) { 
    this.formData = authService.makeForgetPasswordFormData()
  }

  async resetPassword() {
    if(this.formData.valid) {
      
      const email = this.formData.controls['email'].value
      await this.authService.resetPassword(email)
      this.router.navigate(['confirm-new-password'], {state: {email}})
    }
  }

  ngOnInit(): void {
    if(!this.formData)
      this.formData = this.authService.makeForgetPasswordFormData()
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IFormQuestion } from '../../interfaces/form-question';
import { IQuestionAnswer } from '../../interfaces/question-answer';
import { ConsumerReportAnswerService } from '../../services/consumer-report-answer.service';
import { FormsQuestionDependencyDialogComponent } from '../forms-question-dependency-dialog/forms-question-dependency-dialog.component';

@Component({
  selector: 'app-question-answers-dialog',
  templateUrl: './question-answers-dialog.component.html',
  styleUrls: ['./question-answers-dialog.component.scss']
})
export class QuestionAnswersDialogComponent implements OnInit {

  answerData: FormGroup;

  get formControls() { return this.answerData.controls; }

  constructor(private readonly fb: FormBuilder,
              public dialogRef: MatDialogRef<QuestionAnswersDialogComponent>
  ) { }

  initForm(): void {
    this.answerData = this.fb.group({
      answers: this.fb.control(undefined, [
        Validators.required,
        Validators.nullValidator
      ])
    });
  }

  ngOnInit(): void {
    this.initForm();
  }

  save(): void {
    console.log(this.answerData)
    const formData = this.answerData.value;
    const returnRows = (formData.answers.split('\n') as string[])
      .filter(v => v !== '')
      .map(v => {
        return {
          label: v.trim(),
          point: 1,
          order: 0
        };
      });
    this.dialogRef.close(returnRows);
  }

}

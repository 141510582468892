import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import decode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { AuthProfile } from './interfaces/authProfile.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardLoggedService implements CanActivate {

  constructor(public auth: AuthService, public router: Router, private readonly cookieHelper: CookieService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const roles = route.data.roles as string[];
    return new Promise((resolve, reject) => {
      this.auth.isAuthenticated()
        .then(login => {
          if (login) {
            const signinRedirect = this.cookieHelper.get(environment.auth.signInCookie) || "/";
            if(signinRedirect) {
              this.cookieHelper.delete(environment.auth.signInCookie)
              this.router.navigateByUrl(signinRedirect);
            }
            resolve(false);
          } else {            
            resolve(true);
          }
          resolve(true);
        });
    });
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthGuardService } from '../auth/auth-guard.service';
import { AccountManagerCreateComponent } from './pages/account-manager-create/account-manager-create.component';
import { AccountManagerIndexComponent } from './pages/account-manager-index/account-manager-index.component';
import { AccountManagerUpdateComponent } from './pages/account-manager-update/account-manager-update.component';
import { BrandsCreateComponent } from './pages/brands-create/brands-create.component';
import { BrandsIndexComponent } from './pages/brands-index/brands-index.component';
import { BrandsUpdateComponent } from './pages/brands-update/brands-update.component';
import { CustomerCreateComponent } from './pages/customer-create/customer-create.component';
import { CustomerIndexComponent } from './pages/customer-index/customer-index.component';
import { CustomerUpdateComponent } from './pages/customer-update/customer-update.component';
import { FormUserdefinedCreateComponent } from './pages/form-userdefined-create/form-userdefined-create.component';
import { FormUserdefinedIndexComponent } from './pages/form-userdefined-index/form-userdefined-index.component';
import { FormsCreateComponent } from './pages/forms-create/forms-create.component';
import { FormsIndexComponent } from './pages/forms-index/forms-index.component';
import { FormsUpdateComponent } from './pages/forms-update/forms-update.component';
import { InfluencerCreateComponent } from './pages/influencer-create/influencer-create.component';
import { InfluencerIndexComponent } from './pages/influencer-index/influencer-index.component';
import { InfluencerUpdateComponent } from './pages/influencer-update/influencer-update.component';
import { ParticipantSubmissionIndexComponent } from './pages/participant-submission-index/participant-submission-index.component';
import { ParticipantSubmissionUpdateComponent } from './pages/participant-submission-update/participant-submission-update.component';
import { ParticipantSubmissionViewComponent } from './pages/participant-submission-view/participant-submission-view.component';
import { QuestionCreateComponent } from './pages/question-create/question-create.component';
import { QuestionIndexComponent } from './pages/question-index/question-index.component';
import { QuestionUpdateComponent } from './pages/question-update/question-update.component';
import { StoreCreateComponent } from './pages/store-create/store-create.component';
import { StoreIndexComponent } from './pages/store-index/store-index.component';
import { StoreUpdateComponent } from './pages/store-update/store-update.component';
import { ReportsIndexComponent } from './pages/reports-index/reports-index.component';
import { ReportsCreateComponent } from './pages/reports-create/reports-create.component';
import { ReportsUpdateComponent } from './pages/reports-update/reports-update.component';
import { ReportsGroupIndexComponent } from './pages/reports-group-index/reports-group-index.component';
import { ReportsGroupCreateComponent } from './pages/reports-group-create/reports-group-create.component';
import { ReportsGroupUpdateComponent } from './pages/reports-group-update/reports-group-update.component';

export const ConsumerReportRoute: Routes = [
  { path: 'consumer-report/customer', component: CustomerIndexComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles} },
  { path: 'consumer-report/customer/create', component: CustomerCreateComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles} },
  { path: 'consumer-report/customer/update/:id', component: CustomerUpdateComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles} },
  { path: 'consumer-report/form/create', component: FormsCreateComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles} },
  { path: 'consumer-report/form/update/:id', component: FormsUpdateComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles} },
  { path: 'consumer-report/form', component: FormsIndexComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles} },
  { path: 'consumer-report/form/user-defined/create', component: FormUserdefinedCreateComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles}, pathMatch: 'full' },
  { path: 'consumer-report/form/user-defined/:formID', component: FormUserdefinedIndexComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles} },
  { path: 'consumer-report/question/create', component: QuestionCreateComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles} },
  { path: 'consumer-report/question', component: QuestionIndexComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles} },
  { path: 'consumer-report/question/update/:id', component: QuestionUpdateComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles} },
  { path: 'consumer-report/brand/create', component: BrandsCreateComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles} },
  { path: 'consumer-report/brand/update/:id', component: BrandsUpdateComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles} },
  { path: 'consumer-report/brand', component: BrandsIndexComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles} },
  { path: 'consumer-report/influencer/create', component: InfluencerCreateComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles} },
  { path: 'consumer-report/influencer/update/:id', component: InfluencerUpdateComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles} },
  { path: 'consumer-report/influencer', component: InfluencerIndexComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles} },
  { path: 'consumer-report/store/create', component: StoreCreateComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles} },
  { path: 'consumer-report/store/update/:id', component: StoreUpdateComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles} },
  { path: 'consumer-report/store', component: StoreIndexComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles} },
  { path: 'consumer-report/participant-submission/update/:id', component: ParticipantSubmissionUpdateComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles} },
  { path: 'consumer-report/participant-submission', component: ParticipantSubmissionIndexComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles} },
  { path: 'consumer-report/participant-submission/view/:id', component: ParticipantSubmissionViewComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles} },
  { path: 'consumer-report/account-manager', component: AccountManagerIndexComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles} },
  { path: 'consumer-report/account-manager/create', component: AccountManagerCreateComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles} },
  { path: 'consumer-report/account-manager/update/:id', component: AccountManagerUpdateComponent, canActivate: [AuthGuardService], data : {roles: environment.consumerReport.defaultRoles}},

  { path: 'consumer-report/reports', component: ReportsIndexComponent, canActivate: [AuthGuardService],  data : {roles: environment.consumerReport.defaultRoles}},
  { path: 'consumer-report/reports/create', component: ReportsCreateComponent, canActivate: [AuthGuardService],  data : {roles: environment.consumerReport.defaultRoles}},
  { path: 'consumer-report/reports/update/:id', component: ReportsUpdateComponent, canActivate: [AuthGuardService],  data : {roles: environment.consumerReport.defaultRoles}},
  
  { path: 'consumer-report/reports-group', component: ReportsGroupIndexComponent, canActivate: [AuthGuardService],  data : {roles: environment.consumerReport.defaultRoles}},
  { path: 'consumer-report/reports-group/create', component: ReportsGroupCreateComponent, canActivate: [AuthGuardService],  data : {roles: environment.consumerReport.defaultRoles}},
  { path: 'consumer-report/reports-group/update/:id', component: ReportsGroupUpdateComponent, canActivate: [AuthGuardService],  data : {roles: environment.consumerReport.defaultRoles}},


];

@NgModule({
  imports: [
    RouterModule.forRoot(ConsumerReportRoute, {})
  ],
  exports: [RouterModule]
})
export class ConsumerReportRouteModule { }

<div class="row mb-3">
  <div class="col-12 text-right">
    <button mat-raised-button color="primary" [routerLink]="['/consumer-report/question/create']">Add New
      Questions</button>
  </div>
</div>
<div class="row mb-3">
  <mat-form-field class="fullWidth col-12" appearance="fill">
    <input type="text" placeholder="Key in at least FOUR characters in your keyword to begin search"
      aria-label="Key in at least FOUR characters in your keyword to begin search" matInput appearance="fill"
      #searchTextInput>
  </mat-form-field>
</div>
<div class="row">
  <div class="col-12">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
      <ng-container matColumnDef="label">
        <th mat-header-cell *matHeaderCellDef [ngClass]="'w-label'"> Label </th>
        <td mat-cell *matCellDef="let element;" [ngClass]="'w-label'">
          <span>{{element.label}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef [ngClass]="'w-action text-center'"> Type </th>
        <td mat-cell *matCellDef="let element;" [ngClass]="'w-action text-center'"> {{element.controlType}} </td>
      </ng-container>
      <ng-container matColumnDef="formAmt">
        <th mat-header-cell *matHeaderCellDef [ngClass]="'w-used text-center'"> Used in form </th>
        <td mat-cell *matCellDef="let element;" [ngClass]="'w-used text-center'"> {{element.formUsedCount}} </td>
      </ng-container>
      <ng-container matColumnDef="ansAmt">
        <th mat-header-cell *matHeaderCellDef [ngClass]="'w-action text-center'"> No. of Answer linked </th>
        <td mat-cell *matCellDef="let element;" [ngClass]="'w-action text-center'"> {{element.answerCount}} </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef [ngClass]="'w-action text-right'"> Action </th>
        <td mat-cell *matCellDef="let element;" [ngClass]="'w-action text-right'">
          <a mat-icon-button color="primary" routerLink="/consumer-report/question/update/{{element.id}}">
            <mat-icon>edit</mat-icon>
          </a>
          <a mat-icon-button color="warn" (click)="deleteItem(element)">
            <mat-icon>remove_circle</mat-icon>
          </a>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="formCols" class="p-2"></tr>
      <tr mat-row *matRowDef="let row; columns: formCols;" class="p-2"></tr>
    </table>
  </div>
</div>
<div class="row mb-4">
  <div class="col-12">
    <mat-paginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
      (page)="changePage($event)">
    </mat-paginator>
  </div>
</div>
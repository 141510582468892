import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConsumerReportUserService } from '../../services/consumerReportUserService.service';

@Component({
  selector: 'app-confirm-delete-dialog',
  templateUrl: './confirm-delete-dialog.component.html',
  styleUrls: ['./confirm-delete-dialog.component.scss']
})
export class ConfirmDeleteDialogComponent implements OnInit {

  savingForm = false;

  confirmForm: FormGroup = new FormGroup({
    confirmField: new FormControl('', [
      Validators.pattern(/confirm/i),
      Validators.required
    ])
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: number,
    private readonly consumerReportUserService: ConsumerReportUserService,
    public dialogRef: MatDialogRef<ConfirmDeleteDialogComponent>,
    private readonly snackBar: MatSnackBar,
  ) { }

  get formControls() { return  this.confirmForm.controls; }

  ngOnInit(): void {
  }

  closeForm() {
    this.dialogRef.close(true);
  }

  deleteUser() {
    this.savingForm = true;
    this.consumerReportUserService.disableProfile(this.data)
      .subscribe(res => {
        this.savingForm = false;
        this.snackBar.open('Deactivation for '+res.email+ ' is successful.', 'Close', {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        this.dialogRef.close(res.active);

      });
  }

}

export const typesArr = [{
    key: "Facial", subtitle: ["Anti-Ageing/Lifting", "Hydration", "Brightening/Whitening", "Acne/Purifying", "Organic"]
    }, {
        key: "Massage", subtitle: ["Full Body", "Foot", "Pregnancy", "Couples", "Back and Shoulders", "Aromatherapy", "Swedish", "Thai", "Javanese", "Deep Tissue",
            "Lymphatic Drainage", "Detox", "Hot Stone"]
    }, {
        key: "Hair", subtitle: ["Colouring", "Highlights", "Women’s Haircut", "Men’s Haircut", "Styling", "Rebonding", "Curls", "Growth/Loss Treatment",
            "Treatment", "Extension"]
    }, {
        key: "Brows/Lashes", subtitle: ["Brow Embroidery", "Brow Trimming", "Brow Tinting", "Eyeliner Embroidery", "Eyelash Extension"]
    }, {
        key: "Hair Removal", subtitle: ["Threading", "Brazilian", "IPL/SHR", "Arm", "Leg", "Back", "Lips"]
    }, {
        key: "Aesthetics Treatment", subtitle: ["Laser", "Lifting", "Botox", "Fillers", "Semi-permanent foundation", "Thread", "Scar Removal", "Tattoo Removal",
            "Pigmentation", "IPL"]
    }, {
        key: "Aesthetics Surgery", subtitle: ["Double Eyelid", "Breast Enhancement/Reduction", "Liposuction"]
    }, {
        key: "Weight Management", subtitle: ["Whole Body", "Fat Freeze", "Contouring"]
    }, {
        key: "Nail", subtitle: ["Manicure", "Pedicure", "Express Manicure", "Nail Art", "Nail Extension"]
    }, {
        key: "Makeup", subtitle: ["Bridal", "Dinner and Dance", "Professional"]
    }
];
import { Component, OnInit } from '@angular/core';
import { IStaticReportConfigGroup } from '../../interfaces/iStaticReportConfigGroups';

@Component({
  selector: 'app-reports-group-create',
  templateUrl: './reports-group-create.component.html',
  styleUrl: './reports-group-create.component.scss'
})
export class ReportsGroupCreateComponent implements OnInit {
  
  model: IStaticReportConfigGroup = {
    name: '',
    about: '',
    reportConfig: [],
    id: 0,
    uuid:""
  }

  ngOnInit(): void {
  }
  
}

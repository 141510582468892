import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { environment } from "src/environments/environment";
import { urlShortener } from "src/environments/url-shortener";
import { AuthGuardService } from "../auth/auth-guard.service";
import { UrlShortenerCreateComponent } from "./pages/url-shortener-create/url-shortener-create.component";
import { UrlShortenerDetailComponent } from "./pages/url-shortener-detail/url-shortener-detail.component";
import { UrlShortenerIndexComponent } from "./pages/url-shortener-index/url-shortener-index.component";
import { UrlShortenerUpdateComponent } from "./pages/url-shortener-update/url-shortener-update.component";

export const UrlShortenerRoute: Routes = [
    { path: 'short-url/dashboard', component: UrlShortenerIndexComponent, canActivate: [AuthGuardService] , data: { roles: environment.urlShortener.roles }},
    { path: 'short-url/detail/:id', component: UrlShortenerDetailComponent, canActivate: [AuthGuardService], data: { roles: environment.urlShortener.roles }},
    { path: 'short-url/campaign/:id', component: UrlShortenerIndexComponent, canActivate: [AuthGuardService] , data: { roles: environment.urlShortener.roles }},
    { path: 'short-url/client/:id', component: UrlShortenerIndexComponent, canActivate: [AuthGuardService] },    
    { path: 'short-url/dashboard/add', component: UrlShortenerCreateComponent, canActivate: [AuthGuardService] ,data: { roles: environment.urlShortener.roles }},
    { path: 'short-url/dashboard/update/:id', component: UrlShortenerUpdateComponent, canActivate: [AuthGuardService] , data: { roles: environment.urlShortener.roles }},
];
  
  @NgModule({
    imports: [
      RouterModule.forRoot(UrlShortenerRoute, {})
    ],
    exports: [RouterModule]
  })
  export class UrlShortenerRouteModule { }
<div class="container">
  <h1 mat-dialog-title>Make Payment to </h1>
  <h3 [innerHtml]="voucherStringName"></h3>
  <h1>mechants</h1>
  <ng-container *ngIf="loading">
    <div class="overlay"></div>
    <mat-progress-spinner [mode]="'indeterminate'" [diameter]="50"></mat-progress-spinner>
  </ng-container>
  <div mat-dialog-content class="row" *ngIf="!loading">
    <mat-form-field class="col-12" appearance="fill">
      <mat-label>Payment Date</mat-label>
      <input matInput [matDatepicker]="paymentDatePicker" [formControl]="paymentDate">
      <mat-datepicker-toggle matSuffix [for]="paymentDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #paymentDatePicker></mat-datepicker>
      <mat-error *ngIf="paymentDate.hasError('required')">
        <span>Payment date cannot be empty</span>
      </mat-error>
    </mat-form-field>
    <div class="col-12">
      <div class="row">
        <ng-container *ngFor="let loadedOrderVoucher of loadedOrderVouchers">
          <div class="col-9">
            <h4>#{{ loadedOrderVoucher.voucherCode }}</h4>
          </div>
          <div class="col-3">
            {{ loadedOrderVoucher.order.pricePerUnit | currency }}
          </div>
        </ng-container>
        
      </div>
    </div>
  </div>
  <div mat-dialog-actions *ngIf="!loading">
    <button mat-button (click)="save()">Pay</button>
    <button mat-button [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
  </div>
</div>
<form [formGroup]="formData">
  <div class="container">
    <mat-card>
      <mat-card-header>
        <mat-card-title class="pt-3">Question</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col-12 col-md-8 ">
            <mat-form-field class="fullWidth" appearance="fill">
              <mat-label>Label</mat-label>
              <input matInput #label maxlength="256" placeholder="Question text" formControlName="label">
            </mat-form-field>
          </div>
          <div class="col-6 col-md-2">
            <mat-form-field class="fullWidth" appearance="fill">
              <mat-label>Control Type</mat-label>
              <mat-select formControlName="controlType">
                <mat-option *ngFor="let type of controlType$ | async" [value]="type.name">
                  {{type.value}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-6 col-md-2">
            <mat-form-field class="fullWidth" appearance="fill">
              <mat-label>Country</mat-label>
              <mat-select formControlName="originatedISOCountryCode">
                <mat-option *ngFor="let type of originatedCountry" [value]="type.name">
                  {{type.value}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-10">
            <div class="fullWidth" appearance="fill">
              <mat-label>Instruction</mat-label>
              <md-editor formControlName="instruction" [options]="options" [height]="'200px'" [upload]="doUpload">
              </md-editor>
            </div>
          </div>
          <div class="col-12 col-md-2">
            <div class="row">
              <div class="col-6 col-md-12">
                <mat-form-field class="w-100" appearance="fill">
                  <mat-label>Type</mat-label>
                  <mat-select formControlName="type">
                    <mat-option *ngFor="let type of typeOptions" [value]="type.value">
                      {{type.label}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-6 col-md-12">
                <mat-form-field class="w-100" appearance="fill">
                  <mat-label>Max Point</mat-label>
                  <input matInput #maxPoint maxlength="256"
                    placeholder="This will be auto complete by entering first name and last name"
                    formControlName="maxPoint">
                </mat-form-field>
              </div>
            </div>


          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="mt-2 ansContainer" [hidden]="!needMatricOptions">
      <mat-card-header>
        <mat-card-title>Matrix Columns</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <section formArrayName="matrices" (cdkDropListDropped)="dropMatric($event)" cdkDropList
          [cdkDropListData]="matrices.controls">
          <div class="row border-bottom" *ngFor="let matrix of matrices.controls; let m=index" cdkDrag>
            <ng-container [formGroupName]="m">
              <div class="col-8">
                <mat-icon class="position-absolute pt-4">reorder</mat-icon>
                <mat-form-field class="fullWidth dragable-textbox" appearance="fill">
                  <mat-label>Label</mat-label>
                  <input matInput #label maxlength="256" placeholder="Matric Label" formControlName="name">
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field class="w-100" appearance="fill">
                  <mat-label>Point Modifier</mat-label>
                  <input matInput placeholder="Point Modifier" formControlName="pointModifier">
                </mat-form-field>
                <a class="tooltipHint" (click)="tooltip.toggle()">
                  <mat-icon #tooltip="matTooltip" matTooltip="{{ pointsModifierHintText }}" matTooltipPosition="above">
                    help</mat-icon>
                </a>
              </div>
              <div class="col-2 text-left pt-3">

                <a mat-icon-button color="primary" (click)="addMatric()">
                  <mat-icon>add_circle</mat-icon>
                </a>
                <a mat-icon-button color="warn" (click)="delMatric(m)" [hidden]="matrices.length<=1">
                  <mat-icon>remove_circle</mat-icon>
                </a>
              </div>
            </ng-container>
          </div>
        </section>
      </mat-card-content>
    </mat-card>
    <mat-card class="mt-2">
      <mat-form-field appearance="fill">
        <mat-label>Load Preloaded Answer</mat-label>
        <mat-select [(value)]="selectedPreload">
          <mat-option *ngFor="let type of preloadData" [value]="type.name">
            {{type.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-raised-button color="primary" class="ml-2" (click)="loadPreloaded()">
        Clear & Load Preloaded Answer
      </button>
    </mat-card>

    <mat-card class="mt-2 ansContainer" [hidden]="!needAnswerOptions">
      <mat-card-header>
        <mat-card-title class="pt-3">Answers
          <button mat-icon-button color="primary" (click)="multiAnsDialog()">
            <mat-icon>add_circle</mat-icon>
          </button>

        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <section formArrayName="answers" (cdkDropListDropped)="drop($event)" cdkDropList
          [cdkDropListData]="answers.controls">
          <div class="row border-bottom" *ngFor="let answer of answers.controls; let a=index" cdkDrag>

            <ng-container [formGroupName]="a">
              <div [class.col-9]="oldControlType !== 'checkbox-group'" [class.col-7]="oldControlType === 'checkbox-group'">
                <mat-icon class="position-absolute pt-4">reorder</mat-icon>
                <mat-form-field class="fullWidth dragable-textbox" appearance="fill">
                  <mat-label>Label</mat-label>
                  <input matInput #label maxlength="256" placeholder="Answer text" formControlName="label">
                </mat-form-field>
              </div>
              <div class="col-2" [hidden]="oldControlType !== 'checkbox-group'">
                <mat-form-field class="fullWidth" appearance="fill">
                  <mat-label>Group</mat-label>
                  <input matInput #group maxlength="256" placeholder="Option grouping" formControlName="group">
                </mat-form-field>
              </div>
              <div class="col-1">
                <mat-form-field class="fullWidth" appearance="fill">
                  <mat-label>Points</mat-label>
                  <input matInput #point maxlength="256" placeholder="Points for the answer (leave blank if no point)"
                    formControlName="point">
                </mat-form-field>
              </div>
              <div class="col-2 text-left pt-3">
                <a mat-icon-button color="primary" (click)="addAnswer()">
                  <mat-icon>add_circle</mat-icon>
                </a>
                <a mat-icon-button color="warn" (click)="delAnswer(a)" [hidden]="answers.length<=1">
                  <mat-icon>remove_circle</mat-icon>
                </a>
              </div>
              <div [class.col-9]="oldControlType !== 'checkbox-group'" [class.col-7]="oldControlType == 'checkbox-group'">
                <mat-form-field class="fullWidth dragable-textbox" appearance="fill">
                  <mat-label>ToolTips</mat-label>
                  <input matInput #tooltip maxlength="256" placeholder="Answer text" formControlName="tooltip">
                </mat-form-field>
              </div>
              <div class="col-3"
                [hidden]="oldControlType !== 'checkbox' && oldControlType !== 'checkbox-group' && oldControlType !== 'radio' && oldControlType !== 'autocomplete'">
                <mat-form-field appearance="fill">
                  <mat-label>Choose an option</mat-label>
                  <mat-select formControlName="specialControl">
                    <mat-option value=""></mat-option>
                    <mat-option value="deselectAll">DeSelect all Options</mat-option>
                    <mat-option value="userDefine">Allow User define</mat-option>
                    <mat-option value="defaultValue">Default Value</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </ng-container>
          </div>
        </section>
      </mat-card-content>
    </mat-card>
    <mat-card class="mt-2">
      <mat-card-content>
        <mat-card-actions>
          <div class="row">
            <div class="col-12 text-center">
              <button mat-raised-button color="primary" class="m-2" (click)="saveForm()"
                [disabled]="formData.status !== 'VALID' || savingForm">Save</button>
              <button mat-raised-button class="m-2" [routerLink]="['/consumer-report/question']">Cancel</button>
            </div>
          </div>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>
</form>

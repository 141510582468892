import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { environment } from 'src/environments/environment';
import { IOrderPayment } from '../interfaces/IOrderPayment';
import { IOrderVoucher } from '../interfaces/IOrderVoucher';

@Injectable({
  providedIn: 'root'
})
export class SalonFinderOrderPaymentService extends CRUDService<IOrderPayment> {
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }

  constructor(public readonly httpClient: HttpClient,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute) {
    super();
  }

  apiPath = environment.salonFinder.orderPaymentPath;
  environmentCRUDPath = environment.salonFinder;
  list(params?: HttpParams): Observable<ICommonListingResponse<IOrderPayment[]>> {
    return this.httpList(params);
  }
  get(id: string | number): Observable<IOrderPayment> {
    throw new Error('Method not implemented.');
  }
  delete(id: string | number): Observable<IOrderPayment> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: IOrderPayment): Observable<IOrderPayment> {
    throw new Error('Method not implemented.');
  }
  create(model: IOrderPayment): Observable<IOrderPayment> {
    throw new Error('Method not implemented.');
  }
  makeFormGroup(loadedModel?: IOrderPayment) {
    throw new Error('Method not implemented.');
  }

  downloadReport(id: number) {
    return this.httpClient.get(this.getAPIURL(environment.salonFinder.orderPaymentReport) + '/' + id, {
      responseType: 'blob'
    });
  }

  getReport(id:number){
    return this.httpClient.get(this.getAPIURL(environment.salonFinder.orderPaymentReport) + '/' + id);
  }
}

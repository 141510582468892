import { Component, OnInit } from '@angular/core';
import { IStaticReportConfig, ISurveyConfigRequest } from '../../interfaces/iStaticReportConfig';
import { ConsumerReportSurveyConfigService } from '../../services/consumer-report-survey-config.service';
import { HttpParams } from '@angular/common/http';
import { ISearchParams } from '../../interfaces/ISearchParams';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';

import { MatDialog } from '@angular/material/dialog';
import { ConfirmDeleteDialogComponent } from '../../components/confirm-delete-dialog/confirm-delete-dialog.component';
import { DeleteDialogComponent } from 'src/app/shared/components/delete-dialog/delete-dialog.component';
import { error } from 'console';
import { Params } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reports-index',
  templateUrl: './reports-index.component.html',
  styleUrls: ['./reports-index.component.scss']
})
export class ReportsIndexComponent extends ListingComponent<IStaticReportConfig> implements OnInit {
  
  isLoading = false;
  displayedColumns: string[] = [
    'status',
    'name',
    'survey',
    'createdDate',
    'action'
  ];

  length = 0;
  generatingSurverReport = false;
  constructor(
    private consumerReportSurveyConfig : ConsumerReportSurveyConfigService,
    private readonly snackBar: MatSnackBar,
    private readonly dialog: MatDialog
  
  ) {
    super();
    this.pageSize = 50  
    this.pageSizeOptions = [50, 100, 150, 200, 250, 500, 1000, 99999]

  }


  populateList<U extends HttpParams | Params>(params?: U, urlHistory?: boolean): void {
    this.surveyConfigList();
  }
  getExtraListingParams(): void {
    throw new Error('Method not implemented.');
  }


  ngOnInit(): void {
    this.surveyConfigList();
  }

  surveyConfigList(){
    this.isLoading = true;
    let params = {
      page: this.page,
      limit: this.pageSize
    } as unknown as HttpParams;
    this.consumerReportSurveyConfig.list(params)
    .subscribe((result:any) => {
      this.isLoading = false;
      this.length = result.total;
      this.initCommonListingResponse(result);
    }, error => {
      this.isLoading = false;
    })
  }

  viewJsonConfig(element){
    
  }

  viewReport(element){
     let url = environment.surveyConfig.consumerReportBaseURL+'/'+element.form.slug+'/static-report';
     window.open(url, '_blank');
  }

  changePage(pageEvent: PageEvent) {
    let changePage = false;
    if (this.pageSize != pageEvent.pageSize) {
      this.pageSize = pageEvent.pageSize;
      changePage = true;
    }
    if (this.page != pageEvent.pageIndex) {
      this.page = pageEvent.pageIndex;
      changePage = true;
    }

    this.surveyConfigList();

  }

  disableStaticReport(id:number){

    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '450px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.consumerReportSurveyConfig.delete(id)
          .subscribe(d=> {
            this.snackBar.open('Report config has been deleted SUCCESSFULLY!', 'Close', {
              duration: 2000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
            this.surveyConfigList();
          },error=>{
            this.snackBar.open('Failed to delete report config.', 'Close', {
              duration: 2000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
          })
      }
    });

  }

  duplicateReportConfig(id:number, config:ISurveyConfigRequest){
    
    this.isLoading = true;
    config.id = id;
    config.action = "create";
    config.configName = config.configName+"-Duplicated";
    config.configSlug = config.configSlug+"-duplicated";
    config.formID = config.surveyID.toString();

    console.log(config);
 
    this.consumerReportSurveyConfig.duplicate(config)
    .subscribe(result=>{
      this.snackBar.open("Successfully duplicated report config.", "Close", {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      })
      this.surveyConfigList();
      this.isLoading = false;

    },error=> {
      this.snackBar.open("Failed to duplicated report config. Please contact your administrator", "Close", {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      })
      this.isLoading = false;
    })

  }

}

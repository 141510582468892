import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Observable, pipe } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { CommonRequestHelper } from 'src/app/consumer-report/services/commonRequestHelper.service';
import { MakeSearchParams } from 'src/app/shared/helpers/MakeSearchParams';
import { IOrderVoucher } from '../../interfaces/IOrderVoucher';
import { IOutlet } from '../../interfaces/IOutlet';
import { SalonFinderOrderVoucherService } from '../../services/salon-finder-order-voucher.service';
import { SalonFinderOutletService } from '../../services/salon-finder-outlet.service';

@Component({
  selector: 'app-order-voucher-redeem-dialog',
  templateUrl: './order-voucher-redeem-dialog.component.html',
  styleUrls: ['./order-voucher-redeem-dialog.component.scss']
})
export class OrderVoucherRedeemDialogComponent implements OnInit {

  redeemedDate = new FormControl(null, [Validators.required])
  outlet = new FormControl(null, [Validators.required])

  filteredOutlets: Observable<IOutlet[]>
  allOutlets: IOutlet[]

  model: IOrderVoucher

  loading = false

  maxDate = new Date()

  constructor(
    public dialogRef: MatDialogRef<OrderVoucherRedeemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IOrderVoucher,
    public salonFinderOrderVoucherService: SalonFinderOrderVoucherService,
    public salonFinderOutletService: SalonFinderOutletService,
    private readonly snackBar: MatSnackBar,
  ) { 
    this.model = data
    this.loadOutlets();
    
  }

  ngOnInit(): void {
    
  }

  displayOutletName(value) {
    if(value) {
      return value.name
    }
  }


  save() {
    if(this.outlet.status !== "INVALID" && this.redeemedDate.status !== "INVALID") {
      this.loading = true
      this.salonFinderOrderVoucherService.redeemVoucher({
        date: this.redeemedDate.value,
        voucherCode: this.model.voucherCode,
        outletID: this.outlet.value.id,
        id: this.model.id
      })
      .subscribe(d=> {
        this.loading = false
        this.dialogRef.close(d)
      })
    }
   
  }

  loadOutlets() {
    this.salonFinderOutletService.list(MakeSearchParams({
      advertiserID: this.model.advertiserID.toString(),
      limit: "-1"
    }))
    .subscribe(d=>{

      this.allOutlets = d.data
      this.outlet.addValidators([RxwebValidators.oneOf({matchValues: this.allOutlets})])
      this.filteredOutlets = this.outlet.valueChanges.pipe(
        startWith(''),
        map<string,IOutlet[]>(name => {
          return  name ? this.allOutlets?.filter(a=>a.name.toLowerCase().includes(name)) : this.allOutlets?.slice()
        }),
      )
    })
  }

}

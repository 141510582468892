import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UpdateComponent } from 'src/app/shared/abstracts/update-component';
import { IUser } from '../../interfaces/iuser';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'security-guards-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent extends UpdateComponent<IUser, UserService> implements OnInit {

  constructor(
    public readonly baseService: UserService,
    public readonly activatedRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.init(this.activatedRoute);
  }

}

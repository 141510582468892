import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { ICRUDPath } from 'src/app/shared/interfaces/iCRUDPath.interface';
import { environment } from 'src/environments/environment';
import { IService } from '../interfaces/IService';
import { SalonFinderOutletService } from './salon-finder-outlet.service';
import { SalonFinderServiceCategoriesService } from './salon-finder-service-categories.service';

@Injectable({
  providedIn: 'root'
})
export class SalonFinderServiceService extends CRUDService<IService> {
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }
  
  baseURL = environment.salonFinder.baseURL
  apiPath = '/services'
  environmentCRUDPath = environment.salonFinder

  list(params?: HttpParams): Observable<ICommonListingResponse<IService[]>> {
    return this.httpClient.get<ICommonListingResponse<IService[]>>(this.listURL, {
      params: params
    })   
  }
  delete(id: string | number): Observable<IService> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: IService): Observable<IService> {
    return this.save(model, id)
  }
  create(model: IService): Observable<IService> {
    return this.save(model)
  }

  resync(models: IService[]) {
    return this.httpClient.post<ICommonListingResponse<IService[]>>(this.getAPIURL(environment.salonFinder.resyncServices), models.map(d=>d.id))
  }

  resyncByAdvertiser(advertiserID: number) {
    return this.httpClient.post<ICommonListingResponse<IService[]>>(this.getAPIURL(environment.salonFinder.resyncServices), {}, {
      params: {
        advertiserID: advertiserID
      }
    })
  }

  reSyncAll() {
    return this.httpClient.post<ICommonListingResponse<IService[]>>(this.getAPIURL(environment.salonFinder.reSyncAllServices), [])
  }

  makeFormGroup(loadedModel?: IService): FormGroup {
    return this.fb.group({
      id: this.fb.control(loadedModel?.id),
      name: this.fb.control(loadedModel?.name, [
        Validators.required,
        Validators.maxLength(256),
        Validators.nullValidator
      ]),
      price: this.fb.control(loadedModel?.price, [
        Validators.required,
        Validators.min(0),
        RxwebValidators.numeric({allowDecimal: true, acceptValue: NumericValueType.PositiveNumber}),
        Validators.nullValidator
      ]),
      duration: this.fb.control(loadedModel?.duration),
      customDuration: this.fb.control(loadedModel?.customDuration),
      advertiserID: this.fb.control(loadedModel?.advertiserID),
      advertiser: this.fb.control(loadedModel?.advertiser, [
        Validators.required,
        Validators.nullValidator
      ]),
      description: this.fb.control(loadedModel?.description, [
        Validators.required,
        Validators.nullValidator
      ]),
      terms: this.fb.control(loadedModel?.terms, [
        Validators.required,
        Validators.nullValidator
      ]),
      outlets: this.fb.array(this.salonFinderOutletService.makeFormArray(loadedModel?.outlets)),
      categories: this.fb.array(this.salonFinderServiceCategoryService.makeFormArray(loadedModel?.categories)),
      s_images: this.fb.control(loadedModel?.s_images),
      uploadImage: this.fb.control(loadedModel?.uploadImage),
      uploadImageID: this.fb.control(loadedModel?.uploadImageID),
      preview: this.fb.control(loadedModel?.preview),
      active: this.fb.control(true)
    });
  }

  constructor(
    public readonly httpClient: HttpClient,
    private readonly fb: FormBuilder,
    private readonly salonFinderServiceCategoryService: SalonFinderServiceCategoriesService,
    private readonly salonFinderOutletService: SalonFinderOutletService,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute
  ) {
    super();
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result);
    };
  }

  // create data
  createData(data: any): Observable<any> {
    // console.log(data);
    return this.httpClient.post(environment.salonFinder.baseURL + environment.salonFinder.services + '/', data);
  }

  // update data
  updateData(id: any, data: any): Observable<any> {
    console.log(data);

    return this.httpClient.post(environment.salonFinder.baseURL + environment.salonFinder.services + '/' + id, data);
  }

  // get single data
  get(id: string): Observable<IService> {
    return this.httpClient.get<IService>(`${this.getURL}/${id}`);
  }

  getAllDataByAdvertiser(aid) {
    const url = environment.salonFinder.baseURL + environment.salonFinder.services + '/search?page=&limit=&search=&type=&aid=' + aid;

    return this.httpClient.get(url)
    .pipe(
      catchError(error => {
        return throwError('Something went wrong!');
      })
    );
  }

  activateService(data: any): Observable<any> {
    // console.log(data);
    return this.httpClient.post(environment.salonFinder.baseURL + environment.salonFinder.services + environment.salonFinder.deactivateServices, data);
  }

  // get all service data by collection id
  getAllServicesByCollectionId(cid, params) {
    // console.log(params);
    return this.httpClient
      .get(environment.salonFinder.baseURL + environment.salonFinder.services + environment.salonFinder.getServicesByCollectionId + '/' + cid, {
        params: params
      })
    ;
  }

  // get all service data by collection id
  getCollectionAddedServiceData(cid, params) {
    return this.httpClient.
      get(environment.salonFinder.baseURL + environment.salonFinder.services + environment.salonFinder.addedCollectionsServicesById + '/' + cid, {
        params: params
      })
  }

  // remove service
  removeServiceById(cid:number,sid:number):Observable<any>
  {
    // console.log(id);
    return this.httpClient.delete(environment.salonFinder.baseURL + environment.salonFinder.services + environment.salonFinder.deleteCollectionsServices + '/' + cid + '/' + sid);
  }

  setAllToLive(data: any):Observable<any> {
    return this.httpClient.post(environment.salonFinder.baseURL + environment.salonFinder.services + environment.salonFinder.live, data);
  }
}

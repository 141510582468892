import { Injectable } from '@angular/core';
import { S3Client } from '@aws-sdk/client-s3';
import { CognitoIdentityCredentialProvider, fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AWSServiceService {

  constructor(
    private readonly cookieService: CookieService
  ) {}

  private _s3Client = null;
  private _profileToken = null

  get S3Client(): S3Client {
    if(!this._s3Client) {
      this._s3Client = new S3Client(this.clientConfig)
    }
    return this._s3Client
  }

  get clientConfig() {
    return {
      region: environment.aws.region,
      credentials: this.AWSTokenCredential
    }
  }

  get AWSTokenCredential(): CognitoIdentityCredentialProvider {
    return fromCognitoIdentityPool({
      clientConfig: { region: environment.aws.region  },
      identityPoolId: environment.aws.poolID,
      logins: {
        [`cognito-idp.${environment.aws.region}.amazonaws.com/${environment.auth.userPoolID}`]: this.profileToken
      },
    })
  }

  get profileToken() {
    if (!this._profileToken) {
      const allToken = this.cookieService.getAll()
      this._profileToken = allToken[environment.auth.cookiesProfileTokenName];
    }
    return this._profileToken;
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { environment } from 'src/environments/environment';
import { ISalonFinderServiceCategories } from '../interfaces/salon-finder-service-categories';

@Injectable({
  providedIn: 'root'
})
export class SalonFinderServiceCategoriesService extends CRUDService<ISalonFinderServiceCategories> {
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }

  apiPath = '/service-categories';
  environmentCRUDPath = environment.salonFinder;
  baseURL = environment.salonFinder.baseURL;

  list(params?: HttpParams): Observable<ICommonListingResponse<ISalonFinderServiceCategories[]>> {
    return this.httpClient.get<ICommonListingResponse<ISalonFinderServiceCategories[]>>(
      this.listURL,
      {
        params
      }
    );
  }
  get(id: string | number) {
    return this.httpClient.get<ISalonFinderServiceCategories>(`${this.getURL}/${id}`);
  }
  delete(id: string | number): Observable<ISalonFinderServiceCategories> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: ISalonFinderServiceCategories): Observable<ISalonFinderServiceCategories> {
    return this.save(model, id)
  }
  create(model: ISalonFinderServiceCategories): Observable<ISalonFinderServiceCategories> {
    throw new Error('Method not implemented.');
  }
  save(model: ISalonFinderServiceCategories, id?: string | number): Observable<ISalonFinderServiceCategories> {
    throw new Error('Method not implemented.');
  }
  makeFormGroup(loadedModel?: ISalonFinderServiceCategories) {
    return this.fb.group({
      id: [loadedModel?.id],
      name: [loadedModel?.name],
      parentID: [loadedModel?.parentID]
    })
  }

  constructor(
    public readonly httpClient: HttpClient,
    private readonly fb: FormBuilder,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute
  ) {
    super();
  }
}

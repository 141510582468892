import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/auth.service';
import { IProfileToken } from 'src/app/auth/interfaces/profileToken.interface';
import { ChangePasswordDialogComponent } from '../change-password-dialog/change-password-dialog.component';

@Component({
  selector: 'darvis-share-menu-user-icon',
  templateUrl: './menu-user-icon.component.html',
  styleUrls: ['./menu-user-icon.component.scss']
})
export class MenuUserIconComponent implements OnInit {

  public loggedUser?: IProfileToken;

  constructor(public readonly authService: AuthService, 
    private readonly dialog: MatDialog) { }

  ngOnInit(): void {
    this.loggedUser = this.authService.getProfileFromToken();
  }

  logout() {
    this.authService.logout()
  }

  hasProfilePic() {
    return this.loggedUser?.picture || ""
  }

  getUserName() {
    return this.loggedUser?.name || "User"
  }

  changePassword() {
    this.dialog.open(ChangePasswordDialogComponent, {
      data: {
        displayName: this.loggedUser.name,
        cognitoUserName: null
      }
    })
  }

  getCurrentGreeting() {
    const splitAfternoon = 12; // 24hr time to split the afternoon
    const splitEvening = 17; // 24hr time to split the evening
    const currentHour = new Date().getHours()
    if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
      // Between 12 PM and 5PM
      return 'Good Afternoon';
    } else if (currentHour >= splitEvening) {
      // Between 5PM and Midnight
      return 'Good Evening';
    }
    // Between dawn and noon
    return 'Good Morning';
  }

}

import { ViewportScroller } from "@angular/common";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable } from "rxjs";
import { ICommonListingResponse } from "../interfaces/icommonListingResponse.interface";
import { ICommonListingSearch } from "../interfaces/ICommonListSearch";
import { ICommonObject } from "../interfaces/ICommonObject";
import { ICRUDPath } from "../interfaces/iCRUDPath.interface";

@Injectable({
  providedIn: 'root'
})
export abstract class CRUDService<T> {
  
  abstract list(params?: HttpParams): Observable<ICommonListingResponse<T[]>>
  abstract get(id: string | number): Observable<T>
  abstract delete(id: string | number): Observable<T>
  abstract update(id: string | number, model: T): Observable<T>
  abstract create(model: T): Observable<T>
  
  abstract makeFormGroup(loadedModel?: T)

  abstract makeSearchListGroup(defaultParams?: ICommonObject)
  
  abstract apiPath: string;
  abstract readonly httpClient: HttpClient

  abstract router: Router
  abstract activatedRoute: ActivatedRoute
  public viewportScroller: ViewportScroller

  abstract environmentCRUDPath: ICRUDPath
  
  get fullAPIPath() {
    return this.environmentCRUDPath.baseURL + this.apiPath
  }

  get createURL() {
    return this.fullAPIPath + this.environmentCRUDPath.commonCreatePath
  }
  get updateURL() {
    return this.fullAPIPath + this.environmentCRUDPath.commonUpdatePath
  }
  get listURL() {
    return this.fullAPIPath + this.environmentCRUDPath.commonListPath
  }
  get getURL() {
    return this.fullAPIPath + this.environmentCRUDPath.commonGetPath
  }

  get deleteURL() {
    return this.fullAPIPath + this.environmentCRUDPath.commonDeletePath
  }

  makeFormArray(loadedModel?: T[]): FormGroup[] {
    return (loadedModel && loadedModel?.length>0)?loadedModel.map(t=>this.makeFormGroup(t)):[this.makeFormGroup({} as T)]
  }

  generateCommonSearchFormGroup(searchFormControl: {}, defaultValue: ICommonListingSearch) {
    return { 
      searchText: [decodeURIComponent(defaultValue?.searchText || "")],
      ...searchFormControl
    }
  }

  save(model: T, id?: string | number): Observable<T> {
    return this.httpClient.post<T>(this.saveURL(id), model)
  }

  httpList(params: HttpParams): Observable<ICommonListingResponse<T[]>> {        
    return this.httpClient.get<ICommonListingResponse<T[]>>(this.listURL, {params})
  }

  httpGet(id: string): Observable<T> {
    return this.httpClient.get<T>(this.getURL+'/'+id);
  }

  httpDelete(id: string): Observable<T> {
    return this.httpClient.delete<T>(this.deleteURL+'/'+id);
  }

  httpGetByPath<U>(path: string): Observable<T | U> {
    return this.httpClient.get<T | U>(this.getAPIURL(path))
  }

  getAPIURL(path: string) {
    return this.fullAPIPath + path
  }

  updateDeepLink(params: HttpParams) {
    if(this.router && this.activatedRoute) {
      const keys = params.keys()
      let queryParams = {}
      for(const key of keys) {
        let value = params.getAll(key).length>1?params.getAll(key):params.get(key)
        if(typeof(value) === 'string' && value.length == 0) {
          continue;
        }
        if(Array.isArray(value)) {
          if(value.filter(v=>v.length>0).length == 0) {
            continue;
          }
        }
        queryParams[key] = value
      }
      
      this.router.navigate(
        [], 
        {
          relativeTo: this.activatedRoute,
          queryParams: queryParams
        });
    }
  }

  saveURL(id?: string | number) {
    
    if(id) {
      return this.fullAPIPath + this.environmentCRUDPath.commonUpdatePath + '/'+ id?.toString()
    } 
    return this.fullAPIPath + this.environmentCRUDPath.commonCreatePath
  }

  // commonListingFn(params?: HttpParams) {
  //   return this.http
  // }

}
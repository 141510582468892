import { Component, OnInit } from '@angular/core';
import { ISurveyConfigRequest } from '../../interfaces/iStaticReportConfig';

@Component({
  selector: 'app-reports-create',
  templateUrl: './reports-create.component.html',
  styleUrls: ['./reports-create.component.scss']
})


export class ReportsCreateComponent implements OnInit {

  model:ISurveyConfigRequest ={
    id: 0,
    formID:"",
    action:"create",
    configName: "",
    comparedForms:"",
    filterType: "",
    uuid: "",
    compareName:"",
    reportName:""
  };
  
  constructor(){}

  ngOnInit(): void {
  }

}

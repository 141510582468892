<div class="row mb-3">
    <div class="col-12 text-right">
      <button mat-raised-button color="primary" [routerLink]="['/salon-finder/promotions/add',advertiserID]">Add New Promo</button>
    </div>
  </div>
  <div class="row mb-3">
    <mat-form-field class="fullWidth col-12" appearance="fill">
      <input type="text"
      placeholder="Type anything to search promo"
      aria-label="Type anything to search promo"
      matInput
      appearance="fill"
      autocomplete="off"
      #searchTextInput>
    </mat-form-field>
  </div>
  <div class="row">
    <div class="col-12">
      <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
        <ng-container matColumnDef="statusID">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element;"> <mat-icon [style.color]="statusColour(element)">circle</mat-icon> </td>
        </ng-container>
        <ng-container matColumnDef="advertiser">
          <th mat-header-cell *matHeaderCellDef> Advertiser </th>
          <td mat-cell *matCellDef="let element;">{{ element?.advertiser?.name }}</td>
        </ng-container>
        <ng-container matColumnDef="Promo Name">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-center">Promo Name</th>
          <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{'deactivated':element.active===0}">
            {{element.name}}
          </td>
        </ng-container>
        <ng-container matColumnDef="Price">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-center">Price</th>
          <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{'deactivated':element.active===0}">
            <div class="text-decoration-line-through text-muted">
              {{element.service.price|currency}}
            </div>
            <div>
              {{element.price|currency}}
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="Date">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-center" class="text-center">Date</th>
          <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{'deactivated':element.active===0}">
            <div>{{ element.startDate | date:'dd MMM yyyy hh:mm a' }}</div>
            <div>To</div>
            <div>{{ element.endDate | date:'dd MMM yyyy hh:mm a' }}</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="Purchases">
          <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-center">Purchases</th>
          <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{'deactivated':element.active===0}">
            <div *ngIf="element.pNum || element.rNum; else elseBlock">
                <p *ngIf="element.pNum">{{element.pNum}}</p>
                <p *ngIf="element.rNum">{{element.rNum + " redeemed"}}</p>
            </div>
            <ng-template #elseBlock>-</ng-template>
          </td>
        </ng-container>
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element;">
            <span *ngIf="element.active<5 && element.active===2;">
              Pending
            </span>
            <span *ngIf="element.active<5 && element.active!==2;">
              <a mat-icon-button color="primary" routerLink="/salon-finder/promotions/update/{{element.id}}">
                <mat-icon matTooltip="Edit" matTooltipPosition="left">edit</mat-icon>
              </a>
              <a mat-icon-button color="warn" (click)="deactivate(element)" [hidden]="element.active===0">
                <mat-icon matTooltip="Deactivate" matTooltipPosition="right">remove_circle</mat-icon>
              </a>
              <a mat-icon-button color="warn" (click)="handleActivate(element)" [hidden]="element.active===1">
                <mat-icon matTooltip="Activate" matTooltipPosition="right" class="color-green">check_circle</mat-icon>
              </a>
            </span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="listingCols" class="p-2"></tr>
        <tr mat-row *matRowDef="let row; columns: listingCols;" class="p-2"></tr>
      </table>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-12">
      <mat-paginator
        [length]="total"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [showFirstLastButtons]="true"
        [pageIndex]="page"
        (page)="changePage($event)">
      </mat-paginator>
    </div>
  </div>

  <ng-container *ngIf="loading$ | async">
    <div class="overlay"></div>
    <mat-progress-spinner [mode]="'indeterminate'" [diameter]="50"></mat-progress-spinner>
  </ng-container>

<div class="row mb-3">
  <div class="col-12 text-right">
    <button mat-raised-button color="primary" [routerLink]="['/consumer-report/store/create']">Add New Brand</button>
  </div>
</div>
<div class="row mb-3" [formGroup]="searchForm">
  <div class="col-12">
    <mat-card>
      <mat-card-content>
        <div class="row">
          <div class="col-12">
            <mat-form-field class="fullWidth" appearance="fill">
              <input type="text"
                placeholder="Type anything to search store"
                aria-label="Type anything to search store"
                matInput
                appearance="fill"
                formControlName="searchText"
                #searchFormInput>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="fullWidth" appearance="fill">
              <mat-label>Category</mat-label>
              <mat-select multiple formControlName="catagory">
                <mat-option *ngFor="let catagory of catagoryList" [value]="catagory">{{catagory.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="fullWidth" appearance="fill">
              <mat-label>Survey Country</mat-label>
              <mat-select multiple formControlName="servingCountry">
                <mat-option *ngFor="let country of surveyServingCountry" [value]="country">{{country.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="fullWidth" appearance="fill">
              <mat-label>Country</mat-label>
              <mat-select multiple formControlName="country">
                <mat-option *ngFor="let country of countryList | async" [value]="country">{{country.country}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>  
      <mat-card-actions>
        <div class="col-12 text-center">
          <button mat-raised-button color="primary" (click)="search()">Search</button>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <table mat-table [dataSource]="lists" class="mat-elevation-z8" style="width: 100%">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> 
          {{ element.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef> Country </th>
        <td mat-cell *matCellDef="let element;"> {{ element.country }} </td>
      </ng-container>

      <ng-container matColumnDef="surveyCountry">
        <th mat-header-cell *matHeaderCellDef> Survey Country </th>
        <td mat-cell *matCellDef="let element;"> {{ element.originatedISOCountryCode }} </td>
      </ng-container>

      <ng-container matColumnDef="haircare">
        <th mat-header-cell *matHeaderCellDef> HairCare </th>
        <td mat-cell *matCellDef="let element;"> <div *ngIf="element.haircare; else elseBlock"><span style="color:green;"><mat-icon>done</mat-icon></span></div>
          <ng-template #elseBlock><span style="color:red;"><mat-icon>close</mat-icon></span></ng-template>  </td>
      </ng-container>

      <ng-container matColumnDef="skincare">
        <th mat-header-cell *matHeaderCellDef> SkinCare </th>
        <td mat-cell *matCellDef="let element;"> <div *ngIf="element.skincare; else elseBlock"><span style="color:green;"><mat-icon>done</mat-icon></span></div>
          <ng-template #elseBlock><span style="color:red;"><mat-icon>close</mat-icon></span></ng-template>  </td>
      </ng-container>

      <ng-container matColumnDef="makeup">
        <th mat-header-cell *matHeaderCellDef> Makeup </th>
        <td mat-cell *matCellDef="let element;"> <div *ngIf="element.makeup; else elseBlock"><span style="color:green;"><mat-icon>done</mat-icon></span></div>
          <ng-template #elseBlock><span style="color:red;"><mat-icon>close</mat-icon></span></ng-template> </td>
      </ng-container>

      <ng-container matColumnDef="action" >
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element;">
          <a mat-icon-button color="primary" routerLink="/consumer-report/store/update/{{element.id}}">
            <mat-icon>edit</mat-icon>
          </a> 
          <a mat-icon-button color="warn" (click)="deleteItem(element)">
            <mat-icon>remove_circle</mat-icon>
          </a>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="listingCols" class="p-2"></tr>
      <tr mat-row *matRowDef="let row; columns: listingCols;" class="p-2"></tr>
    </table>
  </div>
</div>
<div class="row mb-4">
  <div class="col-12">
    <mat-paginator 
      [length]="total"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      (page)="changePage($event)">
    </mat-paginator>
  </div>
</div>
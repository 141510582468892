import { Component, OnInit } from '@angular/core';
import { IConsumerReportUser } from '../../interfaces/consumerReportUser.interface';

@Component({
  selector: 'app-create-customer',
  templateUrl: './customer-create.component.html',
  styleUrls: ['./customer-create.component.scss']
})
export class CustomerCreateComponent implements OnInit {

  customerModel: IConsumerReportUser = {
    accountManagerID: null,
    awsID: '',
    company: '',
    displayName: '',
    email: '',
    firstName: '',
    id: 0,
    lastName: '',
    active: true,
    password: '',
    points: []
  };

  ngOnInit(): void {

  }

}

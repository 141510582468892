<span class="profile" *ngIf="loggedUser !== null">
	{{ getCurrentGreeting() }}, {{ getUserName() }} 
</span>
<span *ngIf="loggedUser !== null">
	<button mat-icon-button (click)="logout()" class="menu-auth-icon">
		<mat-icon>logout</mat-icon>
	</button>
	<span *ngIf="authService.adminGroup.length<=0">
		<button mat-icon-button (click)="changePassword()" class="menu-auth-icon">
			<mat-icon>key</mat-icon>
		</button>
	</span>
</span>
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { SelectObjectCompareHelper } from 'src/app/shared/helpers/SelectObjectCompareHelper';
import { IInfluencer } from '../../interfaces/iinfluencer';
import { ConsumerReportInfluencerService } from '../../services/consumer-report-influencer.service';

@Component({
  selector: 'app-influencer-index',
  templateUrl: './influencer-index.component.html',
  styleUrls: ['./influencer-index.component.scss']
})
export class InfluencerIndexComponent extends ListingComponent<IInfluencer> implements OnInit {
  
  searchForm: FormGroup

  constructor(
    public override readonly baseService: ConsumerReportInfluencerService, 
    private readonly fb: FormBuilder,
    private readonly snackBar: MatSnackBar,
  ) {
    super();
    this.listingCols = [
      'name',
      'originatedISOCountryCode',
      'action'
    ]
  }

  surveyServingCountry = [
    {name: "SG", id: 1},
    {name: "MY", id: 2}
  ]

  ngOnInit(): void {
    this.populateList({
      servingCountry: ["SG"]
    })
    this.initInfluencerSearchForm()
  }

  initInfluencerSearchForm() {
    this.searchForm = this.fb.group({
      searchText: [""],
      servingCountry: [[{name: "SG", id: 1}]],
    })
  }

  selectedServingCountry = SelectObjectCompareHelper

  populateList<U>(params?: U): void {
    const HttpParams = this.makeSearchParams(params)
    this.commonPopulateList(HttpParams)
  }
  getExtraListingParams() {
    throw new Error('Method not implemented.');
  }

  search() {
    const searchValue = this.searchForm.value
    this.populateList({
      searchText: searchValue['searchText'],
      servingCountry: searchValue['servingCountry']?.map(s=>s.name)
    })
  }

  deleteItem(item: IInfluencer): void {
    console.log(item);
    this.baseService.delete(item.id)
    .subscribe(response=>{
      console.log(response);
      this.snackBar.open('Influencer has been deleted SUCCESSFULLY!', 'Close', {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      this.populateList({
        servingCountry: ["SG"]
      })  
    })
  }
}

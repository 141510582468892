import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MakeSearchParams } from 'src/app/consumer-report/helpers/MakeSearchParams';
import { FormComponent } from 'src/app/shared/abstracts/form-component';
import { IAdvertiser } from '../../interfaces/IAdvertiser';
import { IService } from '../../interfaces/IService';
import { IServicePromotion } from '../../interfaces/IServicePromotion';
import { SalonFinderAdvertiserService } from '../../services/salon-finder-advertiser.service';
import { SalonFinderPromoService } from '../../services/salon-finder-promo.service';
import { SalonFinderServiceService } from '../../services/salon-finder-service.service';

@Component({
  selector: 'salon-finder-promo-form',
  templateUrl: './promo-form.component.html',
  styleUrls: ['./promo-form.component.scss']
})
export class PromoFormComponent extends FormComponent<IServicePromotion, SalonFinderPromoService> implements OnInit, OnChanges {
  allServices$: Observable<IService[]>;

  customDuration: any;
  imagePreviewUrl: any;
  checkedList: any;
  _advertiserID = 0;
  loadedAdvertiser: IAdvertiser = null
  savingForm = false;



  isLoading = true;

  allTypes = [];
  allDurations = [];

  constructor(
    private readonly snackBar: MatSnackBar,
    public readonly baseService: SalonFinderPromoService,
    private readonly salonFinderServiceService: SalonFinderServiceService,
    private readonly salonFinderAdvertiserService: SalonFinderAdvertiserService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loadedModel) {
      this.init();
    }
  }

  ngOnInit(): void {

  }

  init() {
    this.loadService();
    // if(this.activatedRoute.snapshot.paramMap.get('advertiserID')) {
    //   this.handleTerms(this.activatedRoute.snapshot.paramMap.get('advertiserID'));
    // }
    this.initForm();
    this.loadAdvertiser()
    this.formData.get('service').valueChanges
      .subscribe((service: IService)=> {
        this.formData.patchValue({
          name: service.name,
          price: service.price,
          serviceID: service.id,
          term: service.terms,
          servicePrice: service.price
        })
      })
  }

  loadAdvertiser() {
    const advertiserID = this.loadedModel.advertiserID || this.activatedRoute.snapshot.paramMap.get('advertiserID')
    if(advertiserID) {
      this.salonFinderAdvertiserService.get(advertiserID)
        .subscribe(d=> this.loadedAdvertiser = d)
    }
    
  }

  loadService() {
    const advertiserID = this.loadedModel.advertiserID || this.activatedRoute.snapshot.paramMap.get('advertiserID')
    this.allServices$ = this.salonFinderServiceService.httpList(MakeSearchParams({
      limit: -1,
      advertiserID: advertiserID?.toString() || '0',
    }))
    .pipe(
      map(d=>d.data)
    )
  }

  handleTerms(service:any) {
    this.salonFinderServiceService.get(service.id).subscribe((data: any) => {
      this.formData.controls.terms.setValue(data.terms);
    });
  }

  compareService(service: IService, compareService: IService) {
    return service && compareService && service.id === compareService.id
  }

  save() {
    if(this.formData.status === 'VALID') {
      const value = this.formData.value;
      if(DateTime.isDateTime(value.startDate)) {
        value.startDate = (value.startDate as DateTime).startOf('day')
      }
      if(DateTime.isDateTime(value.endDate)) {
        value.endDate = (value.endDate as DateTime).endOf('day')
      }

      this.baseService.save(value, this.loadedModel.id)
        .subscribe(
          response => {
            this.savingForm = false;
            this.loadedModel = response;
            this.snackBar.open('Promo has been saved successfully!', 'Close', {
              duration: 2000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
            this.router.navigate([`/salon-finder/advertisers/update/${response.advertiserID}`])
          },
          () => {
            this.savingForm = false;
            this.snackBar.open('Unable to add promo!', 'Close', {
              duration: 2000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
            this.initForm();
          }
        );
    }
    
  }
}

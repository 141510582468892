import { Component, OnInit } from '@angular/core';
import { IBrand } from '../../interfaces/ibrand';

@Component({
  selector: 'app-brands-create',
  templateUrl: './brands-create.component.html',
  styleUrls: ['./brands-create.component.scss']
})
export class BrandsCreateComponent implements OnInit {

  brandsForm:IBrand = {
    id: undefined,
    uuid: undefined,
    name: undefined,
    country: 'Singapore',
    gender: '0',
    originatedISOCountryCode: 'SG',
    countryZone: 'Asia', 
    description: undefined,
    skincare: true,
    makeup: true,
    haircare: true,
    brandCategory: [],
    celebrity:true
  }

  constructor() { }

  ngOnInit(): void {
  }

}

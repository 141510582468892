<div class="container">
	<div class="row">
		<div class="text-center col-12 mb-4 mt-4">
			<img src="/assets/loginLogo.svg" />
		</div>
	</div>
	<div class="row justify-content-center">
		<div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
			<mat-card>
				<mat-card-content>
					<div class="container">
						<div class="row">
							<div class="col-xs-12 col-md-6">
								<h2 class="text-center">GUEST LOGIN</h2>
								<form [formGroup]="formData">
									<mat-form-field class="col-12" appearance="fill">
										<mat-label>Username / Email</mat-label>
										<input matInput placeholder="Username / Email" formControlName="username" />
										<mat-error>
											<span *ngIf="formData.get('username').hasError('required')">Username is <strong>required</strong></span>
										</mat-error>
										<mat-error>
											<span *ngIf="formData.get('username').hasError('notExist')">Username or password not correct</span>
										</mat-error>
									</mat-form-field>
									<mat-form-field class="col-12" appearance="fill">
										<mat-label>Password</mat-label>
										<input matInput placeholder="Password" formControlName="password" type="password" />
										<mat-error>
											<span *ngIf="formData.get('password').hasError('required')">Password is <strong>required</strong></span>
										</mat-error>
									</mat-form-field>
									<mat-error>
										<span *ngIf="formData.hasError('login')">{{ formData.getError('login')}}</span>
									</mat-error>	
									<a [routerLink]="['/forget-password']" [queryParams]="{email: formData.controls['username']?.value}" >Forgot your password?</a>									
									<div>
										<button
											mat-raised-button
											color="primary"
											class="m-2"
											[disabled]="formData.status != 'VALID' || savingForm"
											(click)="login()"
										>
											LOGIN
										</button>
									</div>	
																                
								</form>
							</div>
							<div class="col-xs-12 col-md-6 staff-login-container">
								<h2>STAFF LOGIN</h2>
								<a
									mat-raised-button
									color="primary"
									class="m-2"
									(click)="loginViaGoogle()"
									[disabled]="logingInViaGoogle == true"
								>
									LOGIN WITH DAILY VANITY EMAIL
								</a>
							</div>
						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
</div>

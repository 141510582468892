<form [formGroup]="formData">
	<div class="container">
		<mat-card>
			<mat-card-header>
				<mat-card-title class="pt-3">
					<span *ngIf="!formData.value.id">Adding new Email Template</span>
					<span *ngIf="formData.value.id">Updating {{ formData.value.name }} template</span>
				</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<div class="row">
						<mat-form-field class="col-6 col-sm-12 col-md-6" appearance="fill">
							<mat-label>Template Name</mat-label>
							<input matInput maxlength="256" placeholder="Template Name" formControlName="name">
							<mat-error>
								<span *ngIf="formData.get('name').hasError('required')">Template Name is
									<strong>required</strong></span>
							</mat-error>
						</mat-form-field>
						<mat-form-field class="col-6 col-sm-12 col-md-6" appearance="fill">
							<mat-label>Subject</mat-label>
							<input matInput maxlength="256" placeholder="Email Subject" formControlName="subject">
							<mat-error>
								<span *ngIf="formData.get('subject').hasError('required')">Commission Rate (%) is
									<strong>required</strong></span>
							</mat-error>							
						</mat-form-field>

						<mat-form-field class="col-6 col-sm-12 col-md-6" appearance="fill">
							<mat-label>Sender Name</mat-label>
							<input matInput maxlength="50" placeholder="Sender Name"
								formControlName="senderName">							
							<mat-error>
								<span *ngIf="formData.get('senderName').hasError('required')">Sender Name is
									<strong>required</strong></span>
							</mat-error>
						</mat-form-field>
						<mat-form-field class="col-6 col-sm-12 col-md-6" appearance="fill">
							<mat-label>Sender Email</mat-label>
							<input matInput maxlength="50" placeholder="Sender Email"
								formControlName="senderAddress">
							<mat-error>
								<span *ngIf="formData.get('senderAddress').hasError('required')">Sender Email <strong>required</strong></span>
								<span *ngIf="formData.get('senderAddress').hasError('email')">Wrong email format</span>
							</mat-error>
						</mat-form-field>
						<mat-form-field class="col-6 col-sm-12 col-md-6" appearance="fill">
							<mat-label>Reply To</mat-label>
							<input matInput maxlength="50" placeholder="Email to reply"
								formControlName="replyTo">
							<mat-error>
								<span *ngIf="formData.get('replyTo').hasError('required')">Reply to email is 
									<strong>required</strong></span>
									<span *ngIf="formData.get('replyTo').hasError('email')">Wrong email format</span>
							</mat-error>
						</mat-form-field>
						<mat-form-field class="col-6 col-sm-12 col-md-6" appearance="fill">
							<mat-label>BCC email</mat-label>
							<input matInput maxlength="256" placeholder="BCC email"
								formControlName="bcc">
							<mat-error>
								<span *ngIf="formData.get('bcc').hasError('required')">Reply to email is 
									<strong>required</strong></span>
									<span *ngIf="formData.get('bcc').hasError('email')">Wrong email format</span>
							</mat-error>
						</mat-form-field>
				</div>
				<div class="row mt-4">
					<mat-form-field class="col-12" appearance="fill">
						<mat-label>Description</mat-label>
						<textarea  matInput maxlength="256" placeholder="Add description for this email template" formControlName="description"></textarea>
					</mat-form-field>
				</div>
				<mat-card-actions>
					<div class="row">
						<div class="col-12 text-center">
							<button mat-raised-button color="primary" class="m-2" (click)="save()">Save</button>
							<button mat-raised-button class="m-2" [routerLink]="['/salon-finder/email-notification']">Cancel</button>
						</div>
					</div>
				</mat-card-actions>
			</mat-card-content>
		</mat-card>
	</div>
</form>
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IStore } from '../../interfaces/istore';
import { ConsumerReportBrandsService } from '../../services/consumer-report-brands.service';
import { ConsumerReportStoreService } from '../../services/consumer-report-store.service';

@Component({
  selector: 'app-store-create',
  templateUrl: './store-create.component.html',
  styleUrls: ['./store-create.component.scss']
})
export class StoreCreateComponent implements OnInit {
  model: IStore = {
    name: "",
    description: "",
    originatedISOCountryCode: "SG",
    countryZone: 'Asia', 
    country: "Singapore",
    skincare: true,
    makeup: true,
    haircare: true
  }

  constructor(private readonly route: ActivatedRoute, private readonly consumerReportStoreService: ConsumerReportStoreService) { }

  ngOnInit(): void {
  }

}

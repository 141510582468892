import { consumerReport } from './consumer-report'
import { Auth } from './auth'
import { Sentry } from './sentry'
import { salonFinder } from './salon-finder';
import { nativeAds } from './native-ads';
import { urlShortener } from './url-shortener';
import { securityGuard } from './security-guard';
import { telegramReport } from './telegram';
import { surveyConfig } from './survey-config';

export const environment = {
  production: true,
  applicationName: "Darvis_Client",
  aws: {
    region: "ap-southeast-1",
    poolID: "ap-southeast-1:b7a105df-100c-40b7-bd88-a8dc8020c09c"
  },
  sentry: Sentry,
  consumerReport,
  salonFinder,
  urlShortener,
  auth: Auth,
  nativeAds,
  securityGuard,
  telegramReport,
  surveyConfig
};

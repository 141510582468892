import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    public auth: AuthService,
    public router: Router,
    private readonly cookieHelper: CookieService,
    private readonly snapBar: MatSnackBar,
  ) {}
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const roles = route.data.roles as string[];
    return new Promise((resolve, reject) => {
      console.log('AuthGuardService')
      this.auth.isAuthenticated()
        .then(login => { 
          if (login) {
            if(!this.auth.findRolesInToken(roles)){
              this.snapBar.open('Error: Not Authorize to access this page.', 'Close', {
                horizontalPosition: 'center',
                verticalPosition: 'top',
                duration: 5000
              });
              resolve(false);
            }
            this.auth.updateAWSSDK();
            resolve(true);
          } else {
            const gotoURL = this.router.getCurrentNavigation()?.finalUrl?.toString()
            if(gotoURL) {
              this.cookieHelper.set(environment.auth.signInCookie, gotoURL, 1, '/')
            }
            this.router.navigate(['sign-in'])
            resolve(false);
          }
          resolve(false);
        });
    });
  }
}

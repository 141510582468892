import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { ICRUDPath } from 'src/app/shared/interfaces/iCRUDPath.interface';
import { environment } from 'src/environments/environment';
import { INewUrlShortener } from '../interfaces/INewUrlShortener';
import { URLHashValidator } from '../validators/URLHashValidator';

@Injectable({
  providedIn: 'root'
})

export class UrlShortenerCollectionService extends CRUDService<INewUrlShortener>{
  _pattern = "^((mailto:(.*))|(https?:\/\/(.*))|(tel:(.*)))";
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }
  apiPath: string = '/url';
  httpClient: HttpClient;
  environmentCRUDPath = environment.urlShortener;
  baseURL = environment.urlShortener.baseURL
  
  constructor(
    private readonly http: HttpClient,
    private fb: FormBuilder,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute
  
    ) {
    super();
    this.httpClient = http
  }

  list(searchParams?: HttpParams) : Observable<ICommonListingResponse<any[]>>{
    return this.http.get<ICommonListingResponse<any[]>>(this.baseURL + environment.urlShortener.urlShortenerTag, {
      params: searchParams
    });   
  }

  getFilters(searchParams?:string) {
   
    let params: HttpParams = new HttpParams(
      {
        fromObject: {
          searchText : searchParams,
          take:100
        }
      });
    return this.http.get<ICommonListingResponse<any[]>>(this.baseURL + environment.urlShortener.urlShortenerFilters,{
      params
    });   
  }
  
  getByTag(_status:number,_client?:any,_campaign?:any, _page?:number, _limit?:number, _customSearch?:any) {

    _client = _client.length === 0 ? "" : _client.join(",");
    _campaign = _campaign.length === 0 ? "" : _campaign.join(",");
    _customSearch = _customSearch.length === 0 ? "" : _customSearch.join(",");
    
    let params: HttpParams = new HttpParams(
      {
        fromObject: {
          status:_status,
          client : _client,
          campaign : _campaign,
          customSearch: _customSearch,
          page: _page,
          take: _limit, 
        }

      });

    return this.http.get<ICommonListingResponse<any[]>>(this.baseURL + environment.urlShortener.urlShortenerTag,{
      params
    });   
  }

  getUrlDetail(id:number, _period:string, dateRange?:string){

    let params: HttpParams = new HttpParams(
      {
        fromObject: {
          urlID : id,
          period : _period,
          dateRange : dateRange
        }

      });

    return this.http.get<ICommonListingResponse<any[]>>(this.baseURL + environment.urlShortener.urlShortenerGetUrlDetail,{
      params
    });  
  }

  searchTag(type:string,filter:string){
    return this.http.get<ICommonListingResponse<any[]>>(this.baseURL + environment.urlShortener.urlShortenerSearchTag + '/' + type +'/'+filter);

  }

  getClicks(id:number, filterType:string, format:any, dateRange?:string){

    let params: HttpParams = new HttpParams(
      {
        fromObject: {
          urlID : id,
          filterType : filterType,
          format : format,
          dateRange: dateRange
        }
      });


    return this.http.get<ICommonListingResponse<any[]>>(this.baseURL + environment.urlShortener.urlShortenerGetClicks, {
      params
    });  
  }

  getAllClients() {
    return this.http.get<ICommonListingResponse<any[]>>(this.baseURL + environment.urlShortener.urlShortenerClientList );   
  }

  getAllCampaigns() {
    return this.http.get<ICommonListingResponse<any[]>>(this.baseURL + environment.urlShortener.urlShortenerCampaignList );
  }

  checkHash(hash:string, domain:string, id:number) {
    return this.http.get<any>(this.baseURL + "/hash/"+hash+"/domain/"+domain+"/id/"+id) ;
  }

  getNewHash(domain:string) {
    return this.http.get<ICommonListingResponse<any[]>>(this.baseURL + environment.urlShortener.urlShortenerGetNewHash+'/'+domain );
  }

  getUser(id:string){
    return this.http.get<any>(this.baseURL + environment.urlShortener.urlShortenerGetUserDetails + '/id=' + id.toString())
  }

  getByOwnerEmail(email:string){
    return this.http.get<any>(this.baseURL + environment.urlShortener.urlShortenerGetUserDetails + '/ownerEmail=' + JSON.stringify(email))
  }

  ownersName(email?:string){
    return this.http.get<ICommonListingResponse<any[]>>(this.baseURL + environment.urlShortener.urlShortenerAcountGetByEmail + '/' + email);
  }

  save(model: INewUrlShortener){
    return this.http.post<INewUrlShortener>(this.baseURL + environment.urlShortener.urlShortenerCreate, model)
  }

  export(id: string | number) {
    return this.http.get<any>(this.baseURL + environment.urlShortener.urlShortenerGetExport  + '/' + id.toString())
  }

  get(id: string | number) {
    return this.http.get<INewUrlShortener>(this.getURL + '/' + id.toString())
  }
  delete(id: string | number) {
    return this.http.delete<any>(this.baseURL + environment.urlShortener.urlShortenerDelete + '/' + id.toString())
  }

  archived(id: string | number,status:string) {
    return this.http.put<any>(this.baseURL + environment.urlShortener.urlShortenerArchived + '/' + status + '/' + id.toString(),{})
  }

  resetClicks(id: string | number) {
    return this.http.put<any>(this.baseURL + environment.urlShortener.urlShortenerResetClicks + '/' + id.toString(),{})
    
  }
 
  update(id: string | number, model: any) {
    return this.http.put<INewUrlShortener>(this.baseURL + environment.urlShortener.urlShortenerUpdate+ '/' + id.toString(), model)
  }
  create(model: INewUrlShortener): Observable<INewUrlShortener> {
    throw new Error('Method not implemented.');
  }

  generateNewHash(maxChar: any) {
    const chars: string = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz-";
    let stringLength: number = maxChar || 3;
    let newHashString: string = "";
  
    for (let i = 0; i < stringLength; i++) {
      let rnum: number = Math.floor(Math.random() * chars.length);
      newHashString += chars.substring(rnum, rnum + 1);
    }
    return newHashString;
  }

  makeFormGroup(loadedModel?: INewUrlShortener) {
    loadedModel.domainName = 'dv.sg'
    if(loadedModel?.domain) {
      loadedModel.domainName = loadedModel.domain.domain;
    }
    return this.fb.group({
			id: [loadedModel?.id],
			accountID: [loadedModel?.accountID],
      ownerID: [loadedModel?.ownerID],
			tags: [loadedModel?.tags],
      campaignID: [loadedModel?.campaignID,],
      domainID: [loadedModel?.domainID,],
      clientID: [loadedModel?.clientID,],      
      description: [loadedModel?.description],
			redirectURL: [loadedModel.redirectURL, [
				Validators.required,
				Validators.nullValidator,
				Validators.pattern(this._pattern)
			]],
			domainName: [loadedModel?.domainName, [
				Validators.required,
				Validators.maxLength(125),
				Validators.nullValidator
			]],
			hash: [loadedModel.hash, [
				Validators.required,
				Validators.maxLength(125),
        Validators.minLength(5),
				Validators.nullValidator
			], [URLHashValidator(this, 'hash', 'domainName', 'id')]],
    })
  }
}
import { HttpParams } from "@angular/common/http";
import { Component, Input } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import { CRUDService } from "src/app/shared/abstracts/crud-service";

@Component({
  template: ''
})
export abstract class FormComponent<T> {

  @Input('formModel')
  loadedModel: T;

  formData: FormGroup = null;
  savingForm: boolean = false

  formErrors(searchKey?: string, formControls?: {[key: string]: AbstractControl}) {
    let errors:{[key: string]: any} = {}
    const controls = formControls?formControls:this.formData.controls
    let keys = Object.keys(controls)
    
    if(searchKey) {
      keys = [searchKey]
    }    
    for(const k of keys) {
      if(controls[k].status === "INVALID") {
        if(controls[k] instanceof FormGroup) {
          const keyError = this.formErrors(null, (controls[k] as FormGroup).controls)
          if(Object.keys(keyError).length>0) {
            errors[k] = keyError
          }
        }
        if(controls[k] instanceof FormArray) {
          errors[k] = new Array<{[key: string]: any}>()          
          const arrayControl = controls[k] as FormArray        
          for(const formGroup of arrayControl.controls) {            
            const keyError = this.formErrors(null, (formGroup as FormGroup).controls)
            if(Object.keys(keyError).length>0) {
              errors[k].push(keyError)
            }
          }          
        }
        if(controls[k]?.errors) {
          errors[k] = controls[k]
        }
      }
      
    }
    return errors
  }

  get formControls() { 
    return this.formData.controls
  }

  title(type: string) {
    
  }



  abstract initForm(): void;
  abstract save(): void;
}
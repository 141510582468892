import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { IConsumerReportUser } from '../../interfaces/consumerReportUser.interface';
import { ConsumerReportUserService } from '../../services/consumerReportUserService.service';

@Component({
  selector: 'app-update-customer',
  templateUrl: './customer-update.component.html',
  styleUrls: ['./customer-update.component.scss']
})
export class CustomerUpdateComponent implements OnInit {

  constructor(private readonly route: ActivatedRoute, private readonly consumerReportUserService: ConsumerReportUserService) { }

  customerModel: IConsumerReportUser = {
    accountManagerID: 0,
    awsID: '',
    company: '',
    displayName: '',
    email: '',
    firstName: '',
    id: 0,
    lastName: '',
    active: true,
    password: ''
  };

  ngOnInit(): void {
    const customerID = this.route.snapshot.paramMap.get('id');
    this.consumerReportUserService.getUser(customerID)
      .subscribe(res => {
        this.customerModel = res;
      });
  }

}

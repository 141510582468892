<div class="row mb-3">
  <div class="col-12 text-right">
    <button mat-raised-button color="primary" [routerLink]="['/salon-finder/advertisers/outlets/add',advertiserID]">Add New Outlet</button>
    <button mat-raised-button [disabled]="syncingServices.length>0 || syncingAll" (click)="resyncAllServices()">Resync all outlets</button>
  </div>
</div>
<div class="row mb-3">
  <mat-form-field class="fullWidth col-12" appearance="fill">
    <input type="text"
    placeholder="Type anything to search outlet"
    aria-label="Type anything to search outlet"
    matInput
    appearance="fill"
    autocomplete="off" 
    #searchTextInput>
  </mat-form-field>
</div>
<div class="row">
  <div class="col-12">
    <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
      <ng-container matColumnDef="Advertiser">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Advertiser</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}"> 
          {{ element.__advertiser__.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Outlet Name">
        <th mat-header-cell *matHeaderCellDef>Outlet Name</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}"> 
          {{ element.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Address">
        <th mat-header-cell *matHeaderCellDef>Address</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}"> 
          {{ element.address }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Postal Code">
        <th mat-header-cell *matHeaderCellDef>Postal Code</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}"> 
          {{ element.postalCode }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Outlet Code">
        <th mat-header-cell *matHeaderCellDef>Outlet Code</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}"> 
          {{ element.outletCode|uppercase }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}"> 
          {{ element.active === 1 ? 'Active' : 'Inactive' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element;">
          <span *ngIf="element.active<5 && element.active===2;">
            Pending
          </span>          
          <span *ngIf="element.active<5 && element.active!==2;">
            <a mat-icon-button color="primary" [disabled]="isSyncing(element)" (click)="resync(element)">
              <mat-icon>autorenew</mat-icon>
            </a>
            <a mat-icon-button color="primary" routerLink="/salon-finder/outlets/update/{{element.id}}">
            <mat-icon matTooltip="Edit" matTooltipPosition="left">edit</mat-icon>
            </a>
            <a mat-icon-button color="warn" (click)="deactivate(element)" [hidden]="element.active===0">
            <mat-icon matTooltip="Deactivate" matTooltipPosition="right">remove_circle</mat-icon>
            </a>
            <a mat-icon-button color="warn" (click)="handleActivate(element)" [hidden]="element.active===1">
            <mat-icon matTooltip="Activate" matTooltipPosition="right" class="color-green">check_circle</mat-icon>
            </a>
            <a mat-icon-button color="warn" (click)="handleSendEmail(element)">
            <mat-icon matTooltip="Email Onboarding" matTooltipPosition="right" class="color-pink">email</mat-icon>
            </a>
          </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="listingCols" class="p-2"></tr>
      <tr mat-row *matRowDef="let row; columns: listingCols;" class="p-2"></tr>
      </table>
  </div>
</div>
<div class="row mb-4">
  <div class="col-12">
    <mat-paginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" [pageIndex]="page"
      (page)="changePage($event)">
    </mat-paginator>
  </div>
</div>
<!-- 
<ng-container *ngIf="loading$ | async">
  <div class="overlay"></div>
  <mat-progress-spinner [mode]="'indeterminate'" [diameter]="50"></mat-progress-spinner>
</ng-container> -->
import { Component, OnInit } from '@angular/core';
import { INewUrlShortener } from '../../interfaces/INewUrlShortener';
import { BehaviorSubject, delay, map, of } from 'rxjs';

@Component({
  selector: 'url-shortener-create',
  templateUrl: './url-shortener-create.component.html',
  styleUrls: ['./url-shortener-create.component.scss']
})
export class UrlShortenerCreateComponent {

  urlShortenerForm: INewUrlShortener = {    
    id: 0,
    domainID: 3,
    domainName: '',
    redirectURL: '',
    accountID: '',
    ownerName: '',
    description: '',
    fullURL: '',
    hash: '',
    active: 1,
    clientID: 0,
    campaignID: 0,
    clientName: '',
    campaignName: '',
    createdAt: new Date(),
    updatedAt: new Date(),
    deletedAt: new Date(),
  }

  refreshForm:BehaviorSubject<INewUrlShortener> = new BehaviorSubject<INewUrlShortener>(this.urlShortenerForm);

  afterSave(savedForm: any) {
    this.refreshForm.next(savedForm);
  }
}
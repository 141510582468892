import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IForm } from '../../interfaces/form';
import { ConsumerReportFormService } from '../../services/consumer-report-form.service';

@Component({
  selector: 'app-forms-update',
  templateUrl: './forms-update.component.html',
  styleUrls: ['./forms-update.component.scss']
})
export class FormsUpdateComponent implements OnInit {

  constructor(private readonly route: ActivatedRoute, private readonly consumerReportFormService: ConsumerReportFormService) { }
  
  formModel: IForm = {
    name: '',
    formPage: []
  };

  ngOnInit(): void {
    const formID = this.route.snapshot.paramMap.get('id');
    this.consumerReportFormService.find(formID)
      .subscribe(res => {
        this.formModel = res;
      });
  }

}

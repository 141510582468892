import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IInfluencer } from '../../interfaces/iinfluencer';
import { ConsumerReportInfluencerService } from '../../services/consumer-report-influencer.service';

@Component({
  selector: 'app-influencer-create',
  templateUrl: './influencer-create.component.html',
  styleUrls: ['./influencer-create.component.scss']
})
export class InfluencerCreateComponent implements OnInit {

  model: IInfluencer = {
    name: "",
    originatedISOCountryCode: ""
  }

  constructor(private readonly route: ActivatedRoute, private readonly consumerReportInfluencerService: ConsumerReportInfluencerService) { }
 
  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if(id){
      this.consumerReportInfluencerService.get(id)
        .subscribe(res => {
          this.model = res;
        });
    }  
  }

}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ListingService } from '../abstracts/listing-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { IFormQuestion } from '../interfaces/form-question';
import { ICommonResponse } from '../interfaces/icommonResponse.interface';
import { Observable } from 'rxjs';
import { IFormPage } from '../interfaces/form-page';

@Injectable({
  providedIn: 'root'
})
export class ConsumerReportFormQuestionService extends ListingService<IFormQuestion>{

  baseURL = environment.consumerReport.baseURL + environment.consumerReport.formQuestionBase
  list(params?: HttpParams) {
    throw new Error('Method not implemented.');
  }
  get(id: string | number) {
		return this.http.get<ICommonResponse<IFormQuestion>>(this.baseURL+environment.consumerReport.formQuestionBase + '/' + id)
	}
  delete(id: string | number) {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: IFormQuestion) {
    throw new Error('Method not implemented.');
  }
  create(model: IFormQuestion) {
    throw new Error('Method not implemented.');
  }
  save(model: IFormQuestion, id?: string | number) {
    throw new Error('Method not implemented.');
  }

  constructor(private readonly http: HttpClient) {
    super();
  }

	getFormQuestion(formID: string | number , id:string|number) : Observable<any>  {
		return this.http.get<ICommonResponse<IFormQuestion>>(this.baseURL+ environment.consumerReport.formQuestionByFormID + '/' + formID +'/'+id)
	}

  getFormQuestionByUUID(uuid: string ) : Observable<any>  {
		return this.http.get<ICommonResponse<IFormQuestion>>(this.baseURL+ environment.consumerReport.formQuestionByUUID + '/' + uuid )
	}

	getFormQuestionByUUIDNBrand(uuid: string, brandCategoryID?: number, brandCountry?: string) : Observable<any>  {
		return this.http.get<ICommonResponse<IFormQuestion>>(this.baseURL+ environment.consumerReport.formQuestionByUUID + '/' + uuid, {
			params: new HttpParams({
				fromObject: {
					brandCategoryID: brandCategoryID || 0,
					brandCountry: brandCountry
				}
			})
		})
	}

	checkOffSync(id: string[]) {
    return this.http.get<IFormQuestion[]>(environment.consumerReport.baseURL + environment.consumerReport.checkOffSyncFormQuestion, {
      params: new HttpParams({
        fromObject: {
          id: id
        }
      })
    });
  }

  resync(id: string) {
    return this.http.post<IFormQuestion>(environment.consumerReport.baseURL + environment.consumerReport.resyncFormQuestion+'/'+id, {});
  }

  listUserDefinedByFormID(formID: number) {
    return this.http.get<ICommonListingResponse<IFormQuestion[]>>(this.baseURL+environment.consumerReport.formQuestionUserDefinedValueBySurvey + '/' + formID, {

    })
  }

  listUserDefined(questionID: number) {
    return this.http.get<ICommonResponse<IFormQuestion>>(this.baseURL+environment.consumerReport.formQuestionUserDefinedValue + '/' + questionID, {

    })
  }
}

<div class="row text-center mb-4" *ngIf="isLoading">
  <div class="loading-container">
    <mat-spinner diameter="50" color="accent"> </mat-spinner>
  </div>
  <br>
  <mat-label>Loading ...</mat-label>
</div>

<ng-container *ngIf="!isLoading">
  <form [formGroup]="reportConfigGroup" (ngSubmit)="onSubmit()">
    <div class="container">
      <mat-card>
        <mat-card-header class="mb-4">
          <mat-card-title class="pt-3">Report Groups</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="row">
            <div class="col-12">
              <mat-form-field class="fullWidth" appearance="fill">
                <mat-label>Name</mat-label>
                <input matInput maxlength="256" placeholder="Name" formControlName="name" required>
                <mat-error *ngIf="reportConfigGroup.get('name').hasError('required')">Please fill the name
                  field.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field class="col-12" appearance="fill">
                <mat-label>Country of origin</mat-label>
                <mat-select formControlName="countryOfOrigin" required
                    aria-placeholder="Country of origin">
                    <mat-option *ngFor="let country of countryOfOrigin" [value]="country.value">
                        {{country.name}}
                    </mat-option>
                </mat-select>
             
                <mat-error *ngIf="reportConfigGroup.get('countryOfOrigin').hasError('required')">Please fill the name
                  field.</mat-error>
            </mat-form-field>
            </div>
            <div class="col-12">
              <quill-editor formControlName="about" [styles]="{height: '150px'}">
              </quill-editor>
            </div>
            <div class="col-12 mt-4">
              <mat-form-field appearance="fill" class="fullWidth">
                <mat-label>Report Configuration</mat-label>
                <mat-select formControlName="reportConfigs" multiple>
                  <mat-option *ngFor="let config of reportConfigs"
                    [value]="config.uuid">{{config.configName}}</mat-option>
                </mat-select>
                <mat-error *ngIf="reportConfigGroup.get('reportConfigs').hasError('required')">
                  Please select at least one option.
                </mat-error>`
              </mat-form-field>

            </div>

          </div>
        </mat-card-content>
        <mat-card-actions style="justify-content: center;">
          <div class="button-row">
            <button type="submit" mat-button mat-raised-button color="primary"
              [disabled]="reportConfigGroup.status == 'INVALID'">Save</button>
            <button mat-button mat-raised-button color="warn" [routerLink]="['/consumer-report/reports-group']"> 
              Cancel</button>
          </div>
        </mat-card-actions>
      </mat-card>
    </div>
  </form>
</ng-container>
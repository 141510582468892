import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { AuthRouteModule } from './auth.routes';
import { IndexComponent } from './index/index.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignOutComponent } from './sign-out/sign-out.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ConfirmNewPasswordComponent } from './confirm-new-password/confirm-new-password.component';

@NgModule({
  declarations: [SignInComponent, SignOutComponent, IndexComponent, ForgetPasswordComponent, ConfirmNewPasswordComponent],
  imports: [
    CommonModule,
    SharedModule,
    AuthRouteModule
  ]
})
export class AuthModule { }

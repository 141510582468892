import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import decode from 'jwt-decode';
import moment from 'moment';
import { CookieOptions, CookieService } from 'ngx-cookie-service';
import { firstValueFrom, from } from 'rxjs';
import { environment } from '../../environments/environment';
import { IAccessTokenResponse } from './interfaces/accessTokenResponse.interface';
import { AuthProfile, ICreateAuthProfile } from './interfaces/authProfile.interface';
import { CognitoIdentityProviderClient, ListGroupsCommand, AdminAddUserToGroupCommand, AdminSetUserPasswordCommand, AdminCreateUserCommand, ListUsersCommand, InitiateAuthCommand, AuthFlowType, GetUserCommand, ForgotPasswordCommand, ConfirmForgotPasswordCommand, AdminCreateUserCommandOutput, UserType, ChangePasswordCommand } from "@aws-sdk/client-cognito-identity-provider";
import { AWSServiceService } from '../shared/services/awsservice.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ILoginCredential } from './interfaces/ilogin-credential';
import { IProfileToken } from './interfaces/profileToken.interface';
import { IConfirmNewPasswordWithCode } from './interfaces/iconfirm-password-with-code';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { lastValueFrom } from 'rxjs';
import { UserService } from '../security-guard/services/user.service';
import { setUser } from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _token: string;
  private _refreshToken: string;
  private _profileToken: string;

  private _isAuth: boolean = null;

  private _allToken: {[key: string]: string} = null;

  private readonly _profile: AuthProfile;

  private _provider: CognitoIdentityProviderClient

  private _cookieOptions: CookieOptions = {
    path: '/',
    sameSite: 'Strict'
  }

  get provider(): CognitoIdentityProviderClient {
    if(!this._provider) {
      this.provider = new CognitoIdentityProviderClient(this.awsService.clientConfig)
    }
    return this._provider
  }

  set provider(value: CognitoIdentityProviderClient) {
    this._provider = value
  }

  get authURL() {
    const queryParamsString = new HttpParams({ fromObject: {
      response_type: 'code',
      state: 'GET_CODE',
      redirect_uri: environment.auth.returnURL,
      client_id: environment.auth.authClientID,
      scope: environment.auth.scope,
      identity_provider: 'Google',
    } }).toString();
    return environment.auth.authBaseURL + environment.auth.authExchangeTokenPath + '?' + queryParamsString;
  }

  constructor(
    private readonly jwtHelper: JwtHelperService, 
    private readonly httpHelper: HttpClient, 
    private readonly cookieService: CookieService, 
    private readonly awsService: AWSServiceService,
    private readonly securityGuardUserService: UserService,
    private fb: FormBuilder
  ) {
    this.populateAllToken();
  }

  private populateAllToken() {
    if(this._allToken === null) {
      this._allToken = this.cookieService.getAll()
    }
    this.getToken();
    this.getRefreshToken()
    this.getProfileToken()
  }


  public async initToken() {
    if(this.cookieService.check(environment.auth.cookiesAccessTokenName)) {
      this._token = this.cookieService.get(environment.auth.cookiesAccessTokenName)
    }
    if(this.cookieService.check(environment.auth.cookiesRefreshTokenName)) {
      this._refreshToken = this.cookieService.get(environment.auth.cookiesRefreshTokenName)
    }
    if(this.cookieService.check(environment.auth.cookiesProfileTokenName)) {
      this._profileToken = this.cookieService.get(environment.auth.cookiesProfileTokenName)
    }
    const refreshingToken = await this.refreshToken();
    if(!refreshingToken) {
      console.log('refreshing token failed')
      this.clearToken();
    }
    this._isAuth = (this._token !== null && this._refreshToken !== null && this._profileToken !== null)

    return this.isAuth();
  }

  getToken() {
    if (this._token === null) {
      this._token = this._allToken[environment.auth.cookiesAccessTokenName] || ""
    }
    return this._token;
  }

  private setToken(token: string, expiredIn: number) {
    this._token = token == ""?null:token;
    this.cookieService.set(
      environment.auth.cookiesAccessTokenName, 
      token, 
      {
        ...this._cookieOptions,
        expires: moment().add((expiredIn - 200), 'seconds').toDate()
      } as CookieOptions
    );
  }

  private getRefreshToken() {
    if (!this._refreshToken) {
      this._refreshToken = this._allToken[environment.auth.cookiesRefreshTokenName]
    }

    return this._refreshToken;
  }

  public makeConfirmNewPasswordFormData(initialData?: IConfirmNewPasswordWithCode) {
    return this.fb.group({
      confirmationCode: [initialData?.ConfirmationCode || "", [Validators.required]],
      confirmationPassword: [initialData?.Password || "", [RxwebValidators.compare({fieldName: 'password'})]],
      username: [initialData?.Username || "", [Validators.required, Validators.email]],
      password: [initialData?.Password || "", [Validators.required]],

    })
  }

  public makeForgetPasswordFormData(email?: string) {
    return this.fb.group({
      email: [email || "", [Validators.required, Validators.email]]
    })
  }

  public makeFormData(initialData?: ILoginCredential) {
    return this.fb.group({
      username: [initialData?.username || "", [Validators.required]],
      password: [initialData?.password || "", [Validators.required]],
    })
  }

  private setRefreshToken(token: string, expiredIn: number) {
    this._refreshToken = token == ""?null:token;
    this.cookieService.set(environment.auth.cookiesRefreshTokenName, token, 
      {
        ...this._cookieOptions,
        expires: moment().add((expiredIn), 'seconds').toDate()
      } as CookieOptions);
  }

  private setProfileToken(token: string, expiredIn: number) {
    this._profileToken = token == ""?null:token;
    this.cookieService.set(environment.auth.cookiesProfileTokenName, token, 
      {
        ...this._cookieOptions,
        expires: moment().add((expiredIn - 200), 'seconds').toDate()
      } as CookieOptions);
    this.updateAWSSDK();
  }

  public userUpdatePassword(oldPassword: string, newPassword: string) {
    const data = new ChangePasswordCommand({
      AccessToken: this.getToken(),
      PreviousPassword: oldPassword,
      ProposedPassword: newPassword
    })
    return this.provider.send(data);
  }

  updateAWSSDK() {
    if (this.getProfileToken()) {
      this.provider = new CognitoIdentityProviderClient(this.awsService.clientConfig);
    }

  }

  getProfileToken() {
    if (!this._profileToken) {
      this._profileToken = this._allToken[environment.auth.cookiesProfileTokenName];
    }
    return this._profileToken;
  }

  listGroups() {
    return this.provider.send(new ListGroupsCommand({
      UserPoolId: environment.auth.userPoolID
    }))
  }

  async addUserToGroup(AWSID: string, groupName: string, poolID: string) {
    return this.provider.send(new AdminAddUserToGroupCommand({
      GroupName: groupName,
      Username: AWSID,
      UserPoolId: poolID
    }))
  }

  async changePassword(password: string, username: string) {
    return this.provider.send(new AdminSetUserPasswordCommand({
      Password: password,
      Permanent: true,
      UserPoolId: environment.auth.userPoolID,
      Username: username
    }))
  }

  async createProfile(profile: ICreateAuthProfile) {    
    let cognitoUserOutput: AdminCreateUserCommandOutput | undefined = undefined;
    let returnUser: UserType | undefined  = undefined
    try {
      const cognitoUserResp = await lastValueFrom(this.findUserByEmail(profile.email))
      if (!cognitoUserResp?.Users?.length) {
        cognitoUserOutput = await this.provider.send(new AdminCreateUserCommand({
          UserPoolId: environment.auth.userPoolID,
          TemporaryPassword: profile.password,
          DesiredDeliveryMediums: undefined,
          ForceAliasCreation: true,
          UserAttributes: [
            {
              Name: 'family_name',
              Value: profile.family_name
            },
            {
              Name: 'email',
              Value: profile.email
            },
            {
              Name: 'given_name',
              Value: profile.given_name
            },
            {
              Name: 'name',
              Value: profile.name
            },
            {
              Name: 'email_verified',
              Value: 'True'
            }
          ],
          Username: profile.username,
          MessageAction: 'SUPPRESS'
        }))
        if (cognitoUserOutput) {
          returnUser = cognitoUserOutput.User;
          const changePasswordResp = await this.changePassword(profile.password, profile.username);
          if(profile !== undefined && profile.group !== undefined && changePasswordResp){
            for (const group of profile.group) {
              await this.addUserToGroup(returnUser?.Username || "", group, environment.auth.userPoolID);
            }
          }
        }
      } else {
        returnUser = cognitoUserResp.Users[0];
      }
    } catch (e) {
      throw e;
    }

    return returnUser;
  }

  public setTokenResponse(response?: IAccessTokenResponse) {
    if(response) {
      this.setToken(response.access_token, response.expires_in);
      this.setRefreshToken(response.refresh_token, response.expires_in);
      this.setProfileToken(response.id_token, response.expires_in);
      this._isAuth = true;
    }
    return this.isAuth();
  }

  public clearToken() {
    const expiredDate = -1000
    
    this.setToken("", expiredDate);
    this.setRefreshToken("", expiredDate);
    this.setProfileToken("", expiredDate);
    this._isAuth = false;
    setUser(null)
  }

  async exchangeCodeWithToken(code: string) {
    const q = new HttpParams({ fromObject: {
      grant_type: 'authorization_code',
      state: 'GET_TOKEN',
      redirect_uri: environment.auth.returnURL,
      client_id: environment.auth.authClientID,
      code
    }});
    try {
      const query = await this.httpHelper.post<IAccessTokenResponse>(environment.auth.authBaseURL + environment.auth.authTokenPath, q.toString(), {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      }).toPromise();
      this.setTokenResponse(query);

      return true;
    } catch (e) {
      throw e;
    }

  }

  async needTokenRefresh() {
    const expiredDate = this.jwtHelper.getTokenExpirationDate(this.getToken());
    if(moment().add(-2, 'hours').isBefore(moment(expiredDate))) {
      await this.refreshToken();
      return true;
    }
    return false;
  }

  async refreshToken() {
    const refreshToken = this.getRefreshToken()
    if(refreshToken === 'undefined' || refreshToken === '' || refreshToken === null || refreshToken === undefined)  {
      this._refreshToken = null
      return false;
    }
    let q = new HttpParams();
    q = q.set('grant_type', 'refresh_token');
    q = q.set('client_id', environment.auth.authClientID);
    q = q.set('refresh_token', refreshToken);
    try {
      const query = await firstValueFrom(this.httpHelper.post<IAccessTokenResponse>(environment.auth.authBaseURL + environment.auth.authTokenPath, q.toString(),  {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      }))
      query.refresh_token = refreshToken;
      this.setTokenResponse(query);
      return true;
    } catch (e) {
      console.log('refresh token failed')
      console.log(e)
    }
    return false;
  }

  findUserByEmail(email: string) {    
    return from(this.provider.send(new ListUsersCommand({
      UserPoolId: environment.auth.userPoolID,
      Filter: `email = \"${email}\"`
    })))
  }

  public findUserByID(id: string) {
    return from(this.provider.send(new ListUsersCommand({
      UserPoolId: environment.auth.userPoolID,
      Filter: `sub = \"${id}\"`
    })))
  }

  public async getUserFromCognito() {
    const accessToken = this.getToken()
    const command = new GetUserCommand({
      AccessToken: accessToken
    })
    return this.provider.send(command)
  }

  public async loginUserWithUserNameNPassword(username: string, password: string) {
    const command = new InitiateAuthCommand({
      ClientId: environment.auth.authClientID,
      AuthFlow: AuthFlowType.USER_PASSWORD_AUTH,
      AuthParameters: {
        USERNAME: username,
        PASSWORD: password
      }
    })
    return this.provider.send(command)
  }

  public async confirmNewPasswordWithCode(input: IConfirmNewPasswordWithCode) {
    const command = new ConfirmForgotPasswordCommand({
      ...input,
      ClientId: environment.auth.authClientID
    })
    return this.provider.send(command)
  }
  
  public async resetPassword(username: string) {
    const command = new ForgotPasswordCommand({
      ClientId: environment.auth.authClientID,
      Username: username
    })
    return this.provider.send(command)
  }

  findRolesInToken(roles: string[]): boolean {
    const token = this.getToken();
    if (!token) {
      return false;
    }
    if (!roles || roles.length <= 0) {
      return true;
    }

    const tokenPayload = decode<AuthProfile>(token); 
    
    let roleFound = false;
    if (roles && roles.length > 0) {
      for (const r of roles) {
        let role = r.toLowerCase().trim();
        if (tokenPayload['cognito:groups']?.findIndex(g => g.toLowerCase().trim() === role)>=0) {
          roleFound = true;
          break;
        }       
      }
    }

    return roleFound;
  }

  isRoleInAdminToken(roles: string[]) {
    if (!roles || roles.length <= 0) {
      return true;
    }
    const adminGroup = this.adminGroup
    return roles.findIndex(r=>adminGroup.findIndex(g=>g.toLowerCase().trim() === r.toLowerCase().trim())>=0)>=0
  }

  get adminGroup() {
    let returnGroup = [];
    if(this.isAuth()) {
      const token = this.getProfileFromToken()
      if(token) {
        const group = token['cognito:groups']
        if(group) {
          returnGroup = group.filter(g=>g.endsWith('_google_workspace')).map(s=>s.replace('_google_workspace', '').toLocaleLowerCase())
        }
      }
    }
    return returnGroup;
  }

  getProfileFromToken(): IProfileToken | null {
    const token = this.getProfileToken()
    if(token)
      return this.jwtHelper.decodeToken<IProfileToken>(token)
    return null
  }

  isAuth() {
    if(this._isAuth) {
      const profileModel = this.getProfileFromToken();
      setUser({
        email: profileModel?.email,
        id: profileModel?.sub,
        username: profileModel?.email,
        ip_address: "{{auto}}",
      })
    }
    return this._isAuth;
  }

  logout() {
    this.clearToken();
    let q = new HttpParams({
      fromObject: {
        client_id: environment.auth.authClientID,
        logout_uri: environment.auth.signOutURL
      }
    });
    const location = window.location;
    location.href = environment.auth.authBaseURL + environment.auth.authLogoutPath + '?'+q.toString();
  }

  async isAuthenticated(): Promise<boolean> {
    if (!this.isAuth()) {
      if (this.getRefreshToken()) {
        try {
          const refreshToken = this.refreshToken();
          if(!refreshToken) {
            this.clearToken();
          }
          return refreshToken
        } catch (e) {
          return false;
        }
      }
    } else {
      return true;
    }
  }
}
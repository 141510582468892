<div>
    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
    </mat-form-field>

    <div *ngIf="chats.length > 0" class="mat-elevation-z8">

        <table mat-table [dataSource]="linkDataSource" matSort>

            <ng-container matColumnDef="createdAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by createdAt">
                    <div class="th-container"> <b> Send Date </b></div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.addedDate | date: 'dd MMM YYYY'}} </td>
            </ng-container>

            <ng-container matColumnDef="username">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by username">
                    <div class="th-container"> <b> Username </b></div>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.username }} </td>
            </ng-container>

            <ng-container matColumnDef="message">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by message">
                    <div class="th-container"> <b> Message </b></div>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.chatMessage.length > 0 "> <a href=""> See all </a> </div>
                    <div *ngIf="element.chatMessage.length == 0 "> No Message </div>
                </td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                [ngClass]="{hovered: row.hovered, highlighted: row.highlighted}"
                (click)="row.highlighted = !row.highlighted"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>

        </table>


        <mat-paginator [length]="resultsLength" [pageSize]="pageSize" aria-label="Select page of users"></mat-paginator>
    </div>
</div>
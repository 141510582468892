import { Component, OnInit, ViewChild, Input, OnDestroy} from '@angular/core';
import { MatTable } from '@angular/material/table';
import { v4 as uuidv4 } from 'uuid';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, catchError, interval } from 'rxjs';
import { SurveyReportCombineAnswer, SurveyReportDisplayTypeConfig, TransformConfigToFormGroup } from '../../helpers/SurveyReportConfigBuilder';
import { ConsumerReportFormService } from '../../services/consumer-report-form.service';
import { ConsumerReportSurveyConfigService } from '../../services/consumer-report-survey-config.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConsumerReportFormQuestionService } from '../../services/consumer-report-form-question.service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { IForm } from '../../interfaces/form';
import { title } from 'process';
import { IAnswer, IComparedForm, IContent, IDisplayType, ISurveyConfigRequest } from '../../interfaces/iStaticReportConfig';
import { MatricsCompareEnum, DisplayType } from '../../enums/staticReportConfig.enum';
import { IGraphQuestion, IGraphQuestionAnswer } from '../../interfaces/iStaticReportConfig';
import {  QuestionTypeEnum } from '../../enums/staticReportConfig.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { ConsumerReportFormPageService } from '../../services/consumer-report-form-page.service';
import { MatOptionSelectionChange } from '@angular/material/core';
import { ReportFormErrorDialogComponent } from '../report-form-error-dialog/report-form-error-dialog.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { isArray } from 'mathjs';
import { LoadingService } from '../../services/loading.service';
import { ConfigDisplayTypes, ConfigReportFilter } from '../../constant/StaticReportConfig';
import { MatExpansionPanel } from '@angular/material/expansion';
import { HttpParams } from '@angular/common/http';


export const ELEMENT_DATA: QuestionElement[] = [
  {order: 1, question: '', type: '' },
];

export interface QuestionElement {
  order: number;
  question: string;
  type: string;
}


@Component({
  selector: 'app-reports-form',
  templateUrl: './reports-form.component.html',
  styleUrls: ['./reports-form.component.scss']
})
export class ReportsFormComponent implements  OnInit {

  loading$ = this.loader.loading$;

  @Input('formModel') loadedModel: ISurveyConfigRequest;

  @ViewChild('table') table: MatTable<any>;

  @ViewChild('panel') panel: MatExpansionPanel;

  dataSource = ELEMENT_DATA;
  dragDisabled = true;
  displayTypes = ConfigDisplayTypes;
  reportConfigFilters = ConfigReportFilter;
  filteredQuestions:any;
  filteredQuestionAnswers:any[] = [];

  gIRows:any;
 
  generalInformationForm: FormGroup;
 
  gIDynamicControlNames: string[] = [];

  allSelectedAnswer = false;
 
  allSelectedMatric = false;
 
  isLoading = false;
 
  isRendering = false;
  
  panelFormOpenState = false;

  forms:IForm[];

  defaultForms:IForm[] = [];
  
  currentSelectedForm: IForm;

  selectedComparedUuids: string[] = [];
   
  selectedFormPageID = "";
  
  selectedFormUUID :string = ""
  
  currentSelectedformQuestionsUUID : string[] = [];

  formGroup: FormGroup;
  
  formSurveyControl = new FormControl();
  
  formSurveyFilterControl = new FormControl();
  
  comparedFormGroup: FormGroup; 

  questionsFilter:Observable<any>;

  questions:any[] = [];
  
  defaultQuestions = [];
  
  formPages = [];

  showPanel = false;

  loadFormQuestionsAndAnswers = false;

  reportConfigName = new FormControl('', [Validators.required]);
  reportName = new FormControl('');
  compareName = new FormControl('');

  reportConfigSlug = new FormControl('');

  renderingQuestions = false;
  
  content = {
    uuid: undefined,
    id:"",
    title:"",
    content:"",
    order: 0,
    graphs: [],
    deletedGraphs:[],
    formPage: "",
    openedPanelSection:false
  } as IContent;

  compared = [{
    uuid: "",
    subName: ""
  } as IComparedForm]

  private subscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private consumerReportFormService: ConsumerReportFormService,
    private consumerReportSurveyConfig : ConsumerReportSurveyConfigService,
    private consumerReportFormQuestionService : ConsumerReportFormQuestionService,
    private consumerReportFormPageService : ConsumerReportFormPageService,
    private readonly snackBar: MatSnackBar,
    private router: Router,
    private readonly route: ActivatedRoute,
    private loader : LoadingService,
    
    ) {
  }

  //Initialize graph row
  initializeContentGraph(content: IContent[], lastIndexCount?:number){
    
    const graphContentArray =  this.generalInformationForm.get('contents') as FormArray;
    for (let index = 0; index < content.length; index++) {
      let cgItem = content[index];
      let order = 0;
      if(lastIndexCount == undefined  && cgItem.order != null){
        order = index + 1;
      }
      
      if(this.loadedModel.action == 'update' && cgItem.order == null && lastIndexCount == undefined) {
        order = index + 1;
      }
      
      if(lastIndexCount != undefined){
        order = cgItem.order + lastIndexCount;
      }
      
      let item = {
        uuid : cgItem.uuid == undefined ? uuidv4() : cgItem.uuid,
        id:cgItem.id,
        title:cgItem.title,
        content:cgItem.content,
        formPage: cgItem.formPage,
        order:  order,
        graphs: cgItem.graphs.length > 0 ? this.createGraphArray(cgItem.graphs) : this.formBuilder.array([this.consumerReportSurveyConfig.makeGraphGroup()]) ,
        deletedGraphs: [[]],
        openedPanelSection:false

      }
    
      const contentGraph = this.formBuilder.group(item);
      contentGraph.updateValueAndValidity();
      graphContentArray.push(contentGraph);
    }    

    this.isRendering = false;
    this.showPanel = true;
    }

  createGraphArray(graphs: any[]) : FormArray {
    let formGroupArrayGraph = [];
    for(let graph of graphs){
        graph.subAnswers = this.formBuilder.array([])
      

      formGroupArrayGraph.push(this.consumerReportSurveyConfig.makeGraphGroup(graph));
    }


    return this.formBuilder.array(formGroupArrayGraph);

  }

  getFormList(){
    return new Promise((resolve,rejects) =>{      
    this.consumerReportFormService.listForm({limit: 15, page:0})
    .subscribe(async (res:ICommonListingResponse<IForm[]>)=>{

      this.forms = res.data;

      this.defaultForms = res.data;
      
      resolve(res);   
      },error => {
        console.log("Error list form : ",error);
        rejects(error);
      })
    })
  }
  async initForm(){
    this.isRendering = true;

    let formList = await this.getFormList();
    if(formList){

      //Initialize content row
      this.generalInformationForm  = this.formBuilder.group({
        saveFailed: false,
        contents : this.formBuilder.array([]),
        deletedContents: this.formBuilder.array([]),
      }) 

      this.comparedFormGroup  = this.formBuilder.group({
        comparedForms : this.formBuilder.array([]) 
      })

   
      if(this.loadedModel.action == "update"){
        if(this.loadedModel.id !== 0 && this.loadedModel.rawConfig != undefined){
 
          this.reportConfigName.setValue(this.loadedModel.configName); 
          this.reportConfigSlug.setValue(this.loadedModel.configSlug);
          this.reportName.setValue(this.loadedModel.reportName); 
          this.compareName.setValue(this.loadedModel.compareName); 

          this.formSurveyFilterControl.setValue(this.loadedModel.filterType);

          let index = this.forms.findIndex(f => f.id == parseInt(this.loadedModel.formID));
          if(index > -1){

            this.updateSelectedForm(this.forms[index].uuid);
            this.formSurveyControl.setValue(this.forms[index].uuid);
            
            if(this.loadedModel.comparedForms != "" && this.loadedModel.comparedForms != null){
              
              // this.initializeComparedForms(this.loadedModel.comparedForms.split(","));
              this.initializeComparedForms(this.loadedModel.comparedForms);
            }else{
              let comparedForms = this.comparedFormGroup.get("comparedForms") as FormArray;
              comparedForms.push(this.formBuilder.group({
                uuid:["", Validators.required],
                subName:[""]
              }))
            }

            TransformConfigToFormGroup(this.loadedModel.rawConfig, this.consumerReportFormQuestionService)
            .then((result:any) => {
              this.initializeContentGraph(result); 
            })
            
          }
        } 
        
        this.isRendering = false;
       
      }

      if(this.loadedModel.action == "create"){
        let contents =  this.generalInformationForm.get('contents') as FormArray;
        this.initializeContentGraph([this.content], 1); 
      }
    }
    
  }

  removeProfileQuestions(questions) {
    return questions.filter(q => q.question.type !== QuestionTypeEnum.PROFILE);
  }

  getFormQuestions(formID:number){
    this.consumerReportFormQuestionService.getFormQuestion(formID, this.loadedModel.id == 0 ? 0 : this.loadedModel.id)
      .subscribe(result=>{
        this.defaultQuestions = result;
        this.questions =  this.removeProfileQuestions(result);
        this.filteredQuestions = this.questions;
        
        this.showPanel = true;
        this.loadFormQuestionsAndAnswers = false;
        this.isRendering = false;

        if(this.loadedModel.action == 'update'){
          this.forms = this.forms.filter(f => f.id != parseInt(this.loadedModel.formID));
        }
  
      },error=>{
       console.log("Get form question error: ", error)
       this.showPanel = true;
       this.loadFormQuestionsAndAnswers = false;
       this.isRendering = false;
     
      })

  }

  getFormPages(formID:number){
    this.consumerReportFormPageService.getFormPage(formID)
      .subscribe(result=>{
        let slugsToHide = ['start','end']
        this.formPages = result.filter(r =>  !slugsToHide.includes(r.slug));
      },error=>{
       console.log("Get form question error: ", error)
      })

  }

  generateFormPageQuestion(panelGraph:FormGroup){
    // this.isRenderingFormQuestionsAnswers = true;
    let formPageQuestions = this.formPages.find(fp => fp.id == this.selectedFormPageID);
    if(formPageQuestions && formPageQuestions.formQuestion.length > 0){
      let graphs = panelGraph.get('graphs') as FormArray;
      graphs.clear();
      for(let formQuestion of formPageQuestions.formQuestion){
        if(formQuestion.question.type == QuestionTypeEnum.PROFILE) continue;

        let formQuestionAnswerArray = [];
        for(let formQuestionAnswer of formQuestion.answers){
          let itemAnswer: IGraphQuestionAnswer = {
            customName: "",
            order: formQuestionAnswer.order,
            questionID: formQuestionAnswer.formQuestionID,
            selectedAnswers: [formQuestionAnswer.label],
            selectedMatrics: [],
            displayTypeConfig: null,
            uuid: formQuestionAnswer.uuid,
            dependencies: [],
          }
          formQuestionAnswerArray.push(this.consumerReportSurveyConfig.makeGraphAnswerGroup(itemAnswer));
        }

        let item: IGraphQuestion = {
          subAnswers: this.formBuilder.array([...formQuestionAnswerArray]),
          subTitle:"",
          subID:formQuestion.uuid,
          question: formQuestion.label,
          displayType: this.consumerReportSurveyConfig.setDefaultDisplayTypes(formQuestion.answers.length, formQuestion.controlType),
          displayWidth:6,
          order:formQuestion.order,
          showInPercentage: false,
          showCompareQuestion: false,
          hint:"",
          nonFree: false,
          formQuestionID: 0,
          openedPanelAnswer: false
        };
        
        graphs.push(this.consumerReportSurveyConfig.makeGraphGroup(item)) 
        graphs.markAllAsTouched();
        graphs.updateValueAndValidity();

      }
      panelGraph.markAllAsTouched();
      panelGraph.updateValueAndValidity();
      // this.isRenderingFormQuestionsAnswers = false;
    }
  }

  changeSelectedFormPage(page: any){
    this.selectedFormPageID = page.id; 
  }

  ngOnInit(){
    this.initForm();
  }

  addExpansionPanel(order: FormControl, event: MouseEvent){
    event.stopPropagation();
    this.filteredQuestions =  this.removeProfileQuestions(this.defaultQuestions);
    let contents =  this.generalInformationForm.get('contents') as FormArray;
    let latestIndex =  contents.controls.length + 1;
    this.initializeContentGraph([this.content], latestIndex);
  }

  deleteExpansionPanel(mainPanelIndex: number,event: MouseEvent, panel:FormGroup) {
    event.stopPropagation();
    let contents = this.generalInformationForm.get('contents') as FormArray
    if (mainPanelIndex >= 0 && mainPanelIndex < contents.length) {
      if(panel.get("id").value != ""){
        let deleteContents = this.generalInformationForm.get("deletedContents");
        deleteContents.value.push(panel.get("id").value);
        
      }

      this.generalInformationForm.get('contents')['controls'].splice(mainPanelIndex, 1);
      this.generalInformationForm.value.contents.splice(mainPanelIndex, 1);
    }
    this.generalInformationForm.get('contents').setErrors(null);   
  }
  
  initializeComparedForms(comparedForms? : IComparedForm[]){ 
    const comparedFormsArray =  this.comparedFormGroup.get('comparedForms') as FormArray;
 
    if(comparedForms == null){
      let item = {
        uuid:["", Validators.required],
        subName: [""]
      }
      comparedFormsArray.push(this.formBuilder.group(item));

    }else{
  
      for(let form of comparedForms){
        let item = {
          uuid:[form.uuid || "", Validators.required],
          subName: [form.subName]
        }
        comparedFormsArray.push(this.formBuilder.group(item));
     
        if(this.loadedModel.action == 'update' && this.loadedModel.comparedForms != ""){
          this.selectedComparedUuids.push(form.uuid)
        }
      }
    }
    

   
    
  }

  removeComparedForm(fg : FormGroup, index:number){
    if(fg.value.uuid !== '') this.selectedComparedUuids = this.selectedComparedUuids.filter(sc => sc !== fg.value.uuid);
    if(this.comparedFormGroup.get('comparedForms') != undefined){
      this.comparedFormGroup.get('comparedForms')['controls'].splice(index, 1);
      this.comparedFormGroup.value.comparedForms.splice(index,1);
    }
    
  }

  addToCompareForm(fg : FormGroup, index:number){
    if(fg.get('uuid').value != ''){
      
      let form = this.defaultForms.find(f => f.uuid == fg.value.uuid);
      
      this.selectedComparedUuids.push(form.uuid);
      this.initializeComparedForms(null);
    }
  }

  checkToHide(uuid:string){
    return this.selectedComparedUuids.findIndex(sc => sc === uuid) > -1;
  }

  async updateSelectedForm(value:string){
    this.loadFormQuestionsAndAnswers = true;
    this.filteredQuestions = [];
    this.selectedFormUUID = value;
    let form = this.forms.find(f => f.uuid == this.selectedFormUUID);
    if(form){
      this.selectedComparedUuids.push(form.uuid);
      if(this.loadedModel.action == 'create') {
        this.initializeComparedForms(null);
      }
      this.currentSelectedForm = form; 
      // this.loadFormQuestionsAndAnswers = false;
      this.getFormPages(form.id);
      this.getFormQuestions(form.id);
    }else{
      this.loadFormQuestionsAndAnswers = false;
    }
  }

  getExistingSurveyConfig(form){
     //check if the form-id exist on survey config.
     const id = this.route.snapshot.paramMap.get('id');
     this.consumerReportSurveyConfig.get(id)
     .subscribe(result => {
       if(this.loadedModel.action == "create"){
         this.loadFormQuestionsAndAnswers = false;
         this.showPanel = false;
         this.snackBar.open("Survey config already exist", 'Close', {
           duration: 2000,
           horizontalPosition: 'center',
           verticalPosition: 'top'
         });
         this.router.navigate([`/consumer-report/reports/update`,id])
      
       }
       this.getFormQuestions(form.id);
     }, error => {
      this.generalInformationForm.get('saveFailed').setValue(true);
       this.showPanel = false; 
       this.loadFormQuestionsAndAnswers = false;
       this.getFormQuestions(form.id);
     })
  }

  async saveTemplate(){
    this.showPanel = false;
    this.reportConfigName.markAllAsTouched();
    // let errors:any = await this.checkAllValidation();
    
    //Note : add an snackBar display for thhe error list.
    // if(errors.length > 0){
    //   this.showPanel = true;
    //   const snackBarRef =  this.snackBar.openFromComponent(ReportFormErrorDialogComponent, {
    //     horizontalPosition: 'center',
    //     verticalPosition: 'top',
    //     data:{
    //       errors: errors
    //     }
    //   })
    
    //   const resultSubscription = (snackBarRef.instance as ReportFormErrorDialogComponent).getResult().subscribe((result) => {
    //     resultSubscription.unsubscribe(); // Unsubscribe to prevent memory leaks
    //   });
      
    // } 

    // if(errors.length == 0){

    // return;
    let reportPage = [];
    let deleteContents = "";
      this.isLoading = true;
      let selectedForm  = this.forms.find(f => f.uuid == this.formSurveyControl.value);
      deleteContents = this.generalInformationForm.get("deletedContents").value.join(",");
      
      for(let contents of this.generalInformationForm.value['contents']){

          let graphTemplate = [];
          let contentGraph  = contents['graphs'] as IGraphQuestion[] ;
            
            for (let contentIndex = 0; contentIndex < contentGraph.length; contentIndex++) {
             
              const graphQuestion = contentGraph[contentIndex];
                
              let question = this.defaultQuestions.find(dq => dq.uuid == graphQuestion.subID); 
              if(question != undefined){
              
                let rawGraph:any = {};
                rawGraph.customName = graphQuestion.subTitle;
                rawGraph.displayType = graphQuestion.displayType;
                rawGraph.uuid = graphQuestion.subID;
                rawGraph.order = contentIndex+1;
                rawGraph.showInPercentage = graphQuestion.showInPercentage;
                rawGraph.showCompareQuestion = graphQuestion.showCompareQuestion;
                rawGraph.nonFree = graphQuestion.nonFree;
                rawGraph.questionID = question?.question?.id??null;
                rawGraph.formQuestionID = question?.uuid??null
                rawGraph.id = graphQuestion.id || 0;
                rawGraph.deletedAnswers = graphQuestion.deletedAnswers.length > 0 ? graphQuestion.deletedAnswers.join(",") : ""; 
                rawGraph.formQuestionAnswerObjects = [];
                if(graphQuestion.subAnswers.length > 0){
                  for (let __index = 0; __index < graphQuestion.subAnswers.length; __index++) {
                    const ans = graphQuestion.subAnswers[__index];
                    
                    let selectedAnswerDetails = [];
                    ans.answers = question?.answers;
                    let selectedAnswers = [];
                   
                    for(let a of ans.selectedAnswers){
                      let __ans  = question?.answers.find(qa => qa.uuid == a.uuid);
                      selectedAnswers.push(__ans);
                    }
    
                    if(selectedAnswers.length >  0){
                      for(let answer of selectedAnswers){
                       
                        
                        let matrics = null;
                        if(!isArray(ans.selectedMatrics)){
                          for(let q of this.defaultQuestions){
                          
                            if(q.matrices.length > 0){
                              matrics = q.matrices.find(qm => qm.uuid == ans.selectedMatrics) 
                          
                            
                            }
                          }
                        }
                        
                        selectedAnswerDetails.push({
                          questionAnswerID:  answer?.questionAnswerID??null,
                          questionAnswerUUID: answer?.uuid??null,
                          label: answer?.label??"",
                          questionAnswerMatricUUID : isArray(ans.selectedMatrics) ? "" : ans.selectedMatrics,
                          questionAnswerMatricID : matrics == null ? null : matrics.questionMatricID,
                        }); 
                      }               
                    }
    
                    if(ans.dependencies !== null && ans.dependencies.length > 0){ 
                      rawGraph.dependencies = ans.dependencies;
                    }
    
                    rawGraph.formQuestionAnswerObjects.push(
                      { 
                        displayTypeConfig : ans.displayTypeConfig,
                        id: ans.id,
                        selectedAnswerDetails: selectedAnswerDetails,
                        order: __index + 1,
                        compare : ans.compare
                      
                      })
        
                  }
    
                }
               
              rawGraph.displayConfig = await SurveyReportDisplayTypeConfig(graphQuestion);
  
       
              graphTemplate.push(rawGraph);
              }
             
          }
     
          reportPage.push({
            uuid: contents.uuid,
            id:contents.id,
            title: contents.title,
            content: contents.content,
            graphs: graphTemplate, 
            code: "",
            order: contents.order,   
            deletedGraphs: contents.deletedGraphs.join(",")
          } as IContent)
      } 
      
      
      // console.log(reportPage);
      // return;
      
      //remove all answers.
      let contents = this.generalInformationForm.value['contents'];
      for(let content of contents){
        if(content.graphs != undefined && content.graphs.length > 0){
          for(let graph of content.graphs){
            if(graph.subAnswers != undefined && graph.subAnswers.length > 0){
              for(let subAnswer of graph.subAnswers){
                delete subAnswer.answers;
              }
             
            }
             
          }
        }
       
      }

      let comparedFormsArray = this.comparedFormGroup.get("comparedForms") as FormArray;
      
      //If the slug field is empty, auto populate the slug according to the name
      let slug = ''
      if(this.reportConfigSlug.value  != undefined && this.reportConfigSlug.value != ""){
        slug = this.reportConfigSlug.value;
      }else{
        let configName = this.reportConfigName.value;
        slug = configName.toLowerCase().replace(/ /g, '-');
      }
      
      let model:ISurveyConfigRequest = {
        formID: this.loadedModel.action == 'create' ? selectedForm.id.toString() : this.loadedModel.formID,
        configName: this.reportConfigName.value,
        reportName : this.reportName.value,
        configSlug: slug,
        comparedForms: comparedFormsArray.value,
        filterType: this.formSurveyFilterControl.value,
        deletedContents: deleteContents,
        uuid : this.loadedModel.uuid,
        compareName:this.compareName.value
      }
      
      // return;
      if(this.loadedModel.action == 'update'){
        this.consumerReportSurveyConfig.update(this.loadedModel.id,model)
        .subscribe(result => {
          if(result){
            this.saveConfig(reportPage,result);    
          }
        }, error => {
          this.generalInformationForm.get('saveFailed').setValue(true);
          this.isLoading = false;
          this.snackBar.open("Failed to save report config.", "Close", {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          })
        })

      }else{
        this.consumerReportSurveyConfig.create(model)
        .subscribe(async result => {
          if(result){
            this.saveConfig(reportPage,result);
           
          }
        }, error => {
          this.generalInformationForm.get('saveFailed').setValue(true);
          this.isLoading = false;
          this.snackBar.open("Failed to save report config.", "Close", {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          })
        })
      }
    // }
 
  }
  
  saveConfig(reportPage:any[], result:any){
    //save by batch the graphs and answer.
    this.saveReportPage(reportPage, result.id)
    .then(isSave => {
      if(isSave){

        this.consumerReportSurveyConfig.createCombinedComparisonDisplayValue(result.id)
        .subscribe(response => {
          this.isLoading = false;
          this.snackBar.open("Successfully save report config and generate static report.", "Close", {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          })
        this.router.navigate([`/consumer-report/reports`])

        },error => {
          this.snackBar.open("Failed to save combined comparison display value.", "Close", {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          })
        })
        
      }else{
        this.snackBar.open("Failed to save report config.", "Close", {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        })
      }
    })
  }

  saveReportPage(pages: any[], reportConfigID: number){
    return new Promise(async (resolve, reject) => {
      try {
        let pageResponse:any;
        for(let page of pages){  

          if(page.id == ""){
            page.id = 0;
            pageResponse = await this.consumerReportSurveyConfig.createReportPage(page,reportConfigID).toPromise();
          }else{
            page.surveyReportConfigID = reportConfigID;
            pageResponse = await this.consumerReportSurveyConfig.updateReportPage(page).toPromise(); 
          }
    
          if(pageResponse){
            await this.saveReportGraphs(page.graphs, pageResponse.id, reportConfigID)
          }

        }
        resolve(true);
      } catch (error) {
        reject("Report Page Error: "+ error);
      }

        
    })
  }

  saveReportGraphs(graphs: any[], reportPageID: number, reportConfigID:number) {
   
    
    return new Promise(async (resolve, reject) => {
        try {
            const promises = graphs.map(async (graph) => {
              if(graph.displayConfig != "{}"){
                try {

                    graph.displayConfig = JSON.stringify(graph.displayConfig);
                    let formQuestionAnswers = [];
                    for(let ansObjs of graph.formQuestionAnswerObjects){
                      let item = {
                        isCombined: ansObjs.selectedAnswerDetails.length > 1 ? true:false,
                        id : ansObjs.id,
                        surveyReportSectionGraphID: 0, 
                        surveyReportSectionID: reportPageID,
                        surveyReportConfigID: reportConfigID,
                        selectedAnswerDetails: ansObjs.selectedAnswerDetails,
                        bgColor: ansObjs?.displayTypeConfig?.bgColor || "",
                        customName: ansObjs?.displayTypeConfig?.customName || "",
                        displayPercentage: ansObjs?.displayTypeConfig?.displayPercentage || false,
                        displayWidth: parseInt(ansObjs?.displayTypeConfig?.displayWidth) || 6 ,
                        icon: ansObjs?.displayTypeConfig?.icon || "",
                        uuid :ansObjs?.uuid || uuidv4(),
                        order : ansObjs.order,
                        compare: ansObjs.compare
          
                      }
                     formQuestionAnswers.push(item);
                    }

                    // graph.formQuestionAnswerObjects = formQuestionAnswers.length > 0 ? JSON.stringify(formQuestionAnswers) : null
                    graph.formQuestionAnswerObjects = formQuestionAnswers;
                    let graphResponse:any;
                    if(graph.id == 0){
                      graphResponse = await this.consumerReportSurveyConfig.createReportGraph(graph, reportPageID, reportConfigID).toPromise();
                    }else{
                      graph.reportPageID = reportPageID;
                      graph.surveyReportConfigID = reportConfigID;
                      graphResponse = await this.consumerReportSurveyConfig.updateReportGraph(graph).toPromise();  
                    }

                } catch (error) {
                    reject("Report Graph Error: " + error);
                }
              }
            });
            // console.log("saveReportGraphs promise: ", promises);
            await Promise.all(promises);
            resolve(true);
        } catch (error) {
            reject("Error: " + error);
        }
    });
    
  }

  checkAllValidation(){
    return new Promise(resolve =>{
      let errors = [];
      //recheck all validation 
      let contents =  this.generalInformationForm.get('contents') as FormArray;
      for(let content of contents.controls){
        let graphs = content.get('graphs') as FormArray;
        for(let graph of graphs.controls){
         
          graph.clearValidators();
          graph.setValidators(Validators.required); // Update validators
          graph.markAllAsTouched();
          graph.updateValueAndValidity();

          
          if(graph.status == 'INVALID'){
            
            if(graph.get('displayType').value == ''){
              errors.push({
                uuid: graph.get('subID').value,
                message: 'Please select display type.'
              })   
            }
          } 
          let answers = graph.get('subAnswers') as FormArray;
          if(answers.controls != undefined){
            for(let answer of answers.controls){
           
              answer.clearValidators();
              answer.setValidators(Validators.required); // Update validators
              answer.markAllAsTouched();
              answer.updateValueAndValidity();
  
              if(answer.status == 'INVALID'){
                if(answer.get('selectedAnswers').value.length == 0){
                  errors.push({
                    uuid: graph.get('subID').value,
                    message: 'Empty answer field under question "'+graph.get('question').value+'". \n Please select 1 answer.'
                  }) 
                }
              }
            }
          }
          
        
        }
        content.markAllAsTouched(); // Marks all controls in the form group and arrays as touched
        content.updateValueAndValidity();
      }

      if(this.reportConfigName.status !== "VALID") errors.push({
        uuid: "", 
        message : 'Required field config name.'
      })   
      resolve(errors);

    });
    
    
  }

  // generateSurveyReport(formUUId:string, id: string){
  //   this.consumerReportSurveyConfig.gerenateSurveyReport(formUUId,id)
  //   .subscribe(result=>{

      
  //   },error => {
  //     this.generalInformationForm.get('saveFailed').setValue(true);
  //     this.isLoading = false;
  //     this.snackBar.open("Failed to generate static report", 'Close', {
  //       duration: 2000,
  //       horizontalPosition: 'center',
  //       verticalPosition: 'top'
  //     });
  
  //   })
  // }

  onGraphQuestionChange(event){
    let content = this.generalInformationForm.value.contents.find(c => c.uuid == event.panelUUID);
    if(content){
      content.deletedGraphs = event.question.deletedGraphs;
      content.graphs = event.question.graphs;
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    let contents = this.generalInformationForm.get('contents') as FormArray;
    if (event.previousIndex != event.currentIndex) {
      let previousOrder = event.previousIndex + 1;
      let previousContent = contents.at(event.currentIndex);
      let currentOrder = event.currentIndex + 1;
      let currentContent = contents.at(event.previousIndex);
      previousContent.get('order').setValue(previousOrder);
      currentContent.get('order').setValue(currentOrder);
    }
    moveItemInArray(contents.controls, event.previousIndex, event.currentIndex);
  }

  clickTitle(event:MouseEvent){
    event.stopPropagation();
  }

  onInputKeyDown(event:KeyboardEvent){
    if (event.code === 'Space') {
     event.stopPropagation(); // Stop the event from propagating
    }
  }

  openPanelSection(panel:FormGroup){
    let openedPanelSection = panel.get('openedPanelSection') as FormControl;
    openedPanelSection.setValue(true);
    this.renderingQuestions = true;
    setTimeout(() => {
      this.renderQuestions(panel);
      
    },500);

  }

  closePanelSection(panel:FormGroup){
    let openedPanelSection = panel.get('openedPanelSection') as FormControl;
    openedPanelSection.setValue(false);
  }

  async renderQuestions(panel:FormGroup){
  
    let graphs =  panel.get('graphs') as FormArray;
    graphs.clear();
    let sectionID = panel.value.id;

    if(panel.value.id != 0){
      let params = {
        surveyReportSectionID: sectionID,
        limit: -1
      } as unknown as HttpParams;
  
      this.consumerReportSurveyConfig.listReportGraphs(params)
        .subscribe(response => {
         

						for(let configGraph of response.data){
							
							let itemGraph = {
								
								"order": configGraph.order ,
								"subID": configGraph.formQuestionID,
								"subTitle": configGraph.customName,
								"displayType": configGraph.displayType,
								"displayWidth": parseInt(configGraph.displayTypeConfig?.column)  || 6,
								"hint": configGraph.displayTypeConfig?.hint || "",
								"question": configGraph.formQuestion.label,
								"showInPercentage": configGraph.showInPercentage,
								"showCompareQuestion": configGraph.showCompareQuestion,
								"nonFree": configGraph.nonFree,
								"dependencies": configGraph.formQuestion.dependencies,
								"subAnswers": this.formBuilder.array([]),
								"id": configGraph.id, 
								"surveyReportConfigID":configGraph.surveyReportConfigID,
								"surveyReportSectionID": configGraph.surveyReportSectionId,
								"matrices": configGraph.formQuestion.formQuestionMetas,	
								"formQuestionID": configGraph.formQuestion.id,
								"quesionID": configGraph.formQuestion.quesionID
								
							}
              

              graphs.push(this.consumerReportSurveyConfig.makeGraphGroup(itemGraph));
              graphs.updateValueAndValidity();
            }
            
            this.renderingQuestions = false;


        },error => {
          console.log("Error rendering graph : ",error);
          this.renderingQuestions = false;
        })
    }else{
      graphs.push(this.consumerReportSurveyConfig.makeGraphGroup());
      graphs.updateValueAndValidity();
      this.renderingQuestions = false;
    }
    
  }


}

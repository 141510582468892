<form [formGroup]="formData">
  <div class="container">
    <mat-card>
      <mat-card-header>
        <mat-card-title class="pt-3">Store {{ formData.value.name }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col-9">
            <mat-form-field appearance="fill" class="fullWidth">
              <mat-label>Name</mat-label>
              <input matInput #firstName maxlength="120" placeholder="Name" formControlName="name">            
              <mat-error *ngIf="formErrors.name.errors?.required">              
                Name is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field class="fullWidth" appearance="fill">
              <mat-label>Survey Display Country</mat-label>
              <mat-select formControlName="originatedISOCountryCode">
                <mat-option *ngFor="let type of surveyCountry | async" [value]="type.name">
                  {{type.value}}
                </mat-option>
              </mat-select>                
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-form-field class="fullWidth" appearance="fill">
              <mat-label>Description</mat-label>
              <input matInput #password maxlength="256" placeholder="Description" formControlName="description">            
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-slide-toggle
              class="example-margin"
              formControlName="skincare">
              SkinCare
            </mat-slide-toggle>
          </div>
          <div class="col-4">
            <mat-slide-toggle
              class="example-margin"
              formControlName="makeup">
              Make Up
            </mat-slide-toggle>
          </div>
          <div class="col-4">
            <mat-slide-toggle
              class="example-margin"
              formControlName="haircare">
              HairCare
            </mat-slide-toggle>
          </div>
        </div>
        <mat-card-actions>
          <div class="row">
            <div class="col-12 text-center">
              <button mat-raised-button color="primary" class="m-2" (click)="save()" [disabled]="formData.status != 'VALID' || savingForm">Save</button>
              <button mat-raised-button class="m-2"  [routerLink]="['/consumer-report/store']">Cancel</button>
            </div>
          </div>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>
  </div>
</form> 
import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { ICRUDPath } from 'src/app/shared/interfaces/iCRUDPath.interface';
import { environment } from 'src/environments/environment';
import { IClient } from '../interfaces/client';

@Injectable({
  providedIn: 'root'
})
export class NativeAdsUserService extends CRUDService<IClient>{
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }
  list(params?: HttpParams): Observable<ICommonListingResponse<IClient[]>> {
    return this.httpList(params)
  }
  get(id: string | number): Observable<IClient> {
    return this.httpGet(id.toString())
  }
  delete(id: string | number): Observable<IClient> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: IClient): Observable<IClient> {
    throw new Error('Method not implemented.');
  }
  create(model: IClient): Observable<IClient> {
    throw new Error('Method not implemented.');
  }
  makeFormGroup(loadedModel?: IClient) {
    return this.fb.group({
      name: [loadedModel?.name || "", [Validators.required, Validators.maxLength(125), Validators.minLength(3)]],
      email: [loadedModel?.email || "", [Validators.email, Validators.required]],
      contactNumber: [loadedModel?.contactNumber || "", []],
      id: [loadedModel?.id || null, []]
    })
  }
  apiPath = environment.nativeAds.userBase;  
  environmentCRUDPath = environment.nativeAds;

  constructor(
    public readonly httpClient: HttpClient,
    public readonly fb: FormBuilder,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute
  ) {
    super();
  }
}

<div class="row text-center mb-4" *ngIf="isLoading">
    <div class="loading-container">
      <mat-spinner diameter="50" color="accent"> </mat-spinner>
  </div>
  <br>
  <mat-label>Loading ...</mat-label>
</div>

<ng-container *ngIf="!isLoading">
  <div class="row mb-3">
    <div class="col-12 text-right">
      <button mat-raised-button color="primary" [routerLink]="['/consumer-report/reports/create']">Add New Report</button>
    </div>
  </div>
  <div class="row text-center mb-4" *ngIf="generatingSurverReport">
    <div class="loading-container">
        <mat-spinner diameter="50" color="accent"> </mat-spinner>
    </div>
    <br>
    <mat-label>Generating Surver Report  ...</mat-label>
  </div>

  <div *ngIf="!generatingSurverReport">
    <div class="row">
      <div class="col-12">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
          
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element;"> 
              <mat-icon [ngClass]="{'current_active': element.active == 1, 'deactivated' : element.active != 1 }">circle</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element;">
              {{element.configName }}
            </td>
          </ng-container>


          <ng-container matColumnDef="survey">
            <th mat-header-cell *matHeaderCellDef> Survey </th>
            <td mat-cell *matCellDef="let element;">
              {{element.form?.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="createdDate">
            <th mat-header-cell *matHeaderCellDef> Created Date </th>
            <td mat-cell *matCellDef="let element;">
                  {{ element.createdDate | dateTimeFromIso | dateTimeToLocal |
                    dateTimeToFormat:'dd LLL yyyy' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element;">
              <a mat-icon-button color="primary" routerLink="/consumer-report/reports/update/{{element.id}}" matTooltip="Edit report configuration">
                <mat-icon>edit</mat-icon>
              </a>
              <a mat-icon-button color="primary" (click)="disableStaticReport(element.id)" matTooltip="Delete report configuration">
                <mat-icon>delete</mat-icon>
              </a>
              <a mat-icon-button color="primary" (click)="viewReport(element)" matTooltip="View report configuration">
                <mat-icon *ngIf="element.resultSurveyReport != ''">insert_chart_outlined</mat-icon>
              </a>
              <a mat-icon-button color="primary" (click)="duplicateReportConfig(element.id, element)" matTooltip="Duplicate report configuration">
                <mat-icon>content_copy</mat-icon>
              </a>
            </td> 
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns" class="p-2"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="p-2"></tr>
        </table>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [pageIndex]="page"
          (page)="changePage($event)">
        </mat-paginator>
      </div>
    </div>
  </div>
</ng-container>
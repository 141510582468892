import { Auth } from "./auth";
import { consumerReportServer } from "./consumer-report-server";

export const consumerReport = {
  baseURL: 'http://localhost:1490',
  reportBaseURL: 'https://consumer-report.tipsy.darvis.dev/customise',
  surveyURL: 'http://localhost:5000',

  reportUpdatePath: '/update',
  commonDeletePath: '/delete',
  commonListPath: '/list',
  commonUpdatePath: '/update',
  commonCreatePath: '/create',
  commonGetPath: '/get',

  userBase: '/user',
  listAccountManagers: '/user/list-account-manager',
  listUser: '/user/list',
  listCompanies: '/list-company',
  listAccountManagerSubUser : '/user/list-account-manager-sub-user',
  userInfo : '/info',
  createUser: '/user/create',
  getUser: '/user/get',
  updateUser: '/user/update',
  updateCustomerAccountManager: '/user/update-customer-account-manager',
  groupName: 'consumser-report-user',
  userStatus: '/user/change-status',
  listUserSearchHistory: '/user/list-search-history',
  getPointYears: '/list-user-points-years',
  listQuestion: '/question/list',
  getQuestion: '/question/get',
  createQuestion: '/question/create',
  deleteQuestion: '/question/delete',
  updateQuestion: '/question/update',
  filterQuestionAutoComplete: '/question/filter-autocomplete',
  duplicateFormPage: '/form/duplicate-form-page',
  duplicateFormQuestion: '/form/duplicate-form-question',
  formQuestionAnsFormPage: '/form-question-answer/save-form-question-ans',
  formQuestionDependecyFormPage: '/form/save-form-question-dependency',
  formQuestionMatricFormPage: '/form/save-form-question-matrics',
  uploadtosbucket: '/form/upload-to-buckets',
  createForm: '/form/create',
  findForm: '/form/get',
  listForm: '/form/list',
  refreshCacheForm: '/form/refresh-cache',
  deleteForm: '/form/delete',
  linkFormQuestion: '/form/link-question',
  listQuestionAnswer: '/question-answer/list',
  surveyExport: '/form/export-submissions',
  checkUserFormsStatus: '/form/form-status',
  formBase: '/form',
  brandBase: '/brand',
  categoryBase: '/brand-category',
  listCategory: '/list',
  storeBase: '/store',
  questionBase: '/question',
  influencerBase: '/influencer',
  participantBase: '/participants',
  participantSubmissionBase: '/participant-submission',
  participantDefinedValueBase: '/participant-defined-value',
  participantExportSubmission: '/export-participant-submission-csv',
  generateParticipantsSubmission: '/generate-participants-submission',
  generateParticipantsSubmissionV2: '/generate-participants-submission-v2',
  listBrand: '/brand/list',
  listStore: '/list',
  listInfluencer: '/list',
  checkOffSyncFormQuestion: '/form-question/off-sync',
  resyncFormQuestion: '/form-question/resync',
  createFormPage: '/form-page/create',
  deleteFormPage: '/form-page/delete',
  listLookup: '/lookup/list',
  getOption: '/option/get',
  submitFullForm: '/participants/submit-full-survey',
  saveParticipant: '/participants/save',
  saveSurveyQuestions: '/participants/save-survey-questions',
  questionAnswerBase: '/question-answer',
  participantSubmissionList: '/participant-submission/list',
  participantSubmissionGet: '/participant-submission/get',
  participantSubmissionGenerateTempLink: '/generate-take-over-link',
  participantUserDefinedValueBySurvey: '/list-by-form',
  participantUserDefinedValueMergeAnswer: '/merge-answer',
  participantUserDefinedValueDuplicate: '/duplicate',
  participantSubmissionReProcessing: '/force-reprocess',
  participantSubmissionByForm : '/participants-by-form',
  formQuestionBase: '/form-question',
  formQuestionAnswerBase: '/form-question-answer',
  formQuestionUserDefinedValueBySurvey: '/list-user-defined-by-survey',
  formQuestionUserDefinedValue: '/list-user-defined',

  generateStaticSurveyReport: '/generate-static-survey-report',
  formQuestionByFormID:'/form-question-by-formID',
  formPageByFormID:'/form-page/getByFormID',
  generateNewSurveyReportResult:'/user/participantSubmissionObjRepo',
  formQuestionByUUID: '/form-question-by-uuid',
  formQuestionAnswerByFormquestionID: '/form-question-answer-by-formquestion-id',

  pointModifierHintText: "Use this as a weightage for points gained. Leave as zero if you don't want an option to have any points. Change to 1 or any number to give an option a point. Eg, if option has a point and point modifier is 1, so it will be 'point * point modifier' if user selects that option.",

  defaultRoles: [
    'marketing@dailyvanity.com',
    'marketing.sg@dailyvanity.com',
    'marketing.my@dailyvanity.com',
  ].map(r=>r+"_google_workspace").concat(Auth.defaultAdminRoles),

  roles: {
    'consumser-report-user': 'Advertiser Report - User Access',
    'darvis-admin': 'Darvis Admin - Admin Access'
  },
  userStatusText: {
    EXTERNAL_PROVIDER: 'Active',
    CONFIRMED: 'Active',
    FORCE_CHANGE_PASSWORD: 'Pending login'
  },
  ...consumerReportServer
};

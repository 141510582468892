<div class="row text-center" *ngIf="isSaving">
    <div class="loading-container">
      <mat-spinner diameter="50" color="accent"> </mat-spinner>
    </div>
    <br>
    <mat-label>Saving ...</mat-label>
</div>

<form [formGroup]="formData" *ngIf="!isSaving">
	<mat-card class="container">
		<mat-card-content>
			<div class="row">
				<mat-form-field
					class="col-12 col-sm-6 col-lg-6"
					appearance="fill"
				>
					<mat-label>Name</mat-label>
					<input
						matInput
						maxlength="256"
						placeholder="Path after domain. You can use '/*' last char as wildcard. Use '/**/*' to represent any path and char after"
						formControlName="name"
					/>
					<mat-error>
						<span *ngIf="formData.get('name').hasError('required')"
							>Name <strong>required</strong></span
						>
					</mat-error>
				</mat-form-field>
				<mat-form-field
					class="col-12 col-sm-6 col-lg-6"
					appearance="fill"
				>
					<mat-label>Description</mat-label>
					<input
						matInput
						maxlength="256"
						placeholder="Name"
						formControlName="label"
					/>
				</mat-form-field>       
			</div>
			
		</mat-card-content>	
		<mat-card-actions class="d-flex justify-content-center">
			<button mat-raised-button color="primary" (click)="save()">Save</button>
			<a mat-raised-button [routerLink]="['/security-guard/roles']">Cancel</a>
		</mat-card-actions>
	</mat-card>

	<section>
		<div class="container">
			<div class="row">
				<mat-card class="col-12 mt-3">
					<mat-card-header>
						<mat-card-title>New Service Access</mat-card-title>
					</mat-card-header>
					<mat-card-content>
						<mat-form-field class="col-12" appearance="fill">
							<mat-label>Service</mat-label>
							<mat-select [formControl]="monitoringServiceCtrl"  [compareWith]="compareCommonEntity">
								<mat-option *ngFor="let service of services$ | async" [value]="service">
									{{service.name}}
								</mat-option>
							</mat-select>
						</mat-form-field>    
					</mat-card-content>
					<mat-card-actions class="d-flex justify-content-center">
						<button mat-raised-button color="primary" type="button" (click)="addMonitoringService()">Monitor this Service</button>
					</mat-card-actions>
				</mat-card>
					
				<mat-card class="col-12 mt-3">
					<mat-card-content>
						<mat-tab-group [selectedIndex]="selectedServiceCtrl.value" (selectedIndexChange)="changeTabAcess($event)">
							<mat-tab *ngFor="let serviceAccess of serviceAccessTabs$ | async; let index = index">
								<ng-template mat-tab-label>
									<a color="warn" mat-icon-button (click)="removeMonitoringService(index)"><mat-icon>disabled_by_default</mat-icon></a>
									{{ serviceAccess.name }}
								</ng-template>
								<div *ngFor="let roleAccess of serviceAccess.roleAccess; let roleAccessIndex=index">
									<security-guards-role-access-form 
										(addButtonClick)="addAccessForm($event)" 
										[serviceID]="serviceAccess.id"  
										[formModel]="roleAccess" 
										[index]="roleAccessIndex"
										(updateToRoleForm)="updatingAccessForm($event)"
										[currentTabSelected]="currentTabSelected"
										>
									</security-guards-role-access-form>
								</div>
								
							</mat-tab> 
						</mat-tab-group>
					</mat-card-content>
				</mat-card>
			</div>

		</div>
		
	</section>
	
</form>
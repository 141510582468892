import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute } from '@angular/router';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { environment } from 'src/environments/environment';
import { ConfirmDeleteDialogComponent } from '../../components/confirm-delete-dialog/confirm-delete-dialog.component';
import { IConsumerReportUser } from '../../interfaces/consumerReportUser.interface';
import { IForm } from '../../interfaces/form';
import { ConsumerReportUserService } from '../../services/consumerReportUserService.service';

@Component({
  selector: 'app-acccount-manager-index',
  templateUrl: './account-manager-index.component.html',
  styleUrls: ['./account-manager-index.component.scss']
})
export class AccountManagerIndexComponent implements OnInit {

  userList: IConsumerReportUser[];

  displayedColumns: string[] = [
    'account',
    'totalSubAccounts',
    'status',
    'action'
  ];


  formList: IForm[]
  reCaching: boolean
  accountManagers: IConsumerReportUser[]
  selectedAccountManager: boolean
  searchTerms: string = null;


  length = 0;
  pageSize = 15;
  page = 0;
  pageSizeOptions: number[] = [15, 25, 50, 100];

  pageEvent: PageEvent;

  constructor(
    private readonly userService: ConsumerReportUserService,
    private readonly dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly consumerReportUserService: ConsumerReportUserService,
    private readonly authService: AuthService

  ) {
    this.selectedAccountManager = false;
  }

  ngOnInit(): void {
    // this.listAccountManagers()
    this.initAccountManagerList();
    this.reCaching = false
  }

  initAccountManagerList() {
    this.consumerReportUserService.getAccountManagerDetails()
      .subscribe(accounts => {
        this.accountManagers = accounts.filter(a => a !== null)
      })
  }


  listAccountManagers() {
    this.userService.getAccountManagerDetails()
      .subscribe(acccounts => {
        this.accountManagers = acccounts.filter(a => a != null);
      })
  }

  enableUser(id: number): void {
    this.userService.enableProfile(id)
      .subscribe(v => {
        if (v.active) {
          this.listAccountManagers();
        }
      });
  }

  disableUser(id: number): void {
    const dialogRef = this.dialog.open(ConfirmDeleteDialogComponent, {
      width: '350px',
      data: id
    });
    dialogRef.afterClosed().subscribe(v => {
      if (!v) {
        this.listAccountManagers();
      }
    });
  }


  checkSubUser(accountManagerId) {
    if (accountManagerId != "") {
      this.userList = [];
      this.searchTerms = accountManagerId;      
      this.userService.listAccountManagerUser(this.searchTerms, { page: this.page, pageSize: this.pageSize })
        .pipe(
          map<ICommonListingResponse<IConsumerReportUser[]>, IConsumerReportUser[]>(v => {
            this.length = v.total;
            return v.data.map(v => {
              return {
                ...v,
                name: v.displayName + ' - ' + v.email,
                latestSearch: v.latestSearch?.map(vv => {
                  return {
                    ...vv,
                    link: environment.consumerReport.reportBaseURL + environment.consumerReport.reportUpdatePath + '/' + vv.commonID
                  };
                })
              };
            });
          }),
          map(v => {
            const returnVal = [];
            for (const d of v) {
              returnVal.push({
                ...d,
                awsStatus: from(this.authService.findUserByID(d.awsID)).pipe(map(user => {
                  let returnStatus = 'Inactive';
                  if (user.Users && d.active && user.Users.length > 0) {
                    const awsUser = user.Users[0];
                    returnStatus = 'Active';
                    if (awsUser.UserStatus == 'FORCE_CHANGE_PASSWORD') {
                      returnStatus = 'Pending login';
                    }
                  }

                  return returnStatus;
                }))
              });
            }
            this.selectedAccountManager = true;
            return returnVal;
          })
        ).subscribe(v => this.userList = v);
      // window.history.replaceState({}, '', `/consumer-report/customer?limit=${this.pageSize}&page=${this.page}&searchTerms=${this.searchTerms.join(',')}`);

    }
  }

  changePage(pageEvent: PageEvent) {
    let changePage = false;
    if (this.pageSize != pageEvent.pageSize) {
      this.pageSize = pageEvent.pageSize;
      changePage = true;
    }
    if (this.page != pageEvent.pageIndex) {
      this.page = pageEvent.pageIndex;
      changePage = true;
    }
  }


}

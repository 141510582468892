import { HttpParams } from '@angular/common/http';
import { AfterViewInit, EventEmitter } from '@angular/core';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { IQuestion } from '../../interfaces/question';
import { IQuestionSelectList } from '../../interfaces/question-select-list';
import { ConsumerReportQuestionService } from '../../services/consumer-report-question.service';
import { Params } from '@angular/router';

@Component({
  selector: 'app-form-add-questions-dialog',
  templateUrl: './form-add-questions-dialog.component.html',
  styleUrls: ['./form-add-questions-dialog.component.scss']
})
export class FormAddQuestionsDialogComponent extends ListingComponent<IQuestion> implements OnInit, AfterViewInit {
  populateList<U extends HttpParams | Params>(params?: U, urlHistory?: boolean): void {
    this.commonPopulateList(params, urlHistory)
  }
  getExtraListingParams(): void {
    throw new Error('Method not implemented.');
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: string[],
              public override readonly baseService: ConsumerReportQuestionService,
              public dialogRef: MatDialogRef<FormAddQuestionsDialogComponent>) { 
                super() 
                this.pageSize = -1
                this.page = 0                
              }

  displayedColumns: string[] = ['label', 'id'];

  availableQns: IQuestionSelectList[];

  selectedQns: IQuestion[];

  addQnsEvent = new EventEmitter();

  ngOnInit(): void {
    this.populateList()
  }

  closeForm(): void {
    this.dialogRef.close(true);
  }

  selectQns(question: IQuestion, checked: boolean): void {
    if (checked && !this.selectedQns.find(q => q.id === question.id)) {
      this.selectedQns.push(question);
    } else if (!checked && this.selectedQns.find(q => q.id === question.id)) {
      this.selectedQns = this.selectedQns.filter(q => q.id !== question.id);
    }
  }

  filterQuestions() {
    return this.baseService.list(this.makeSearchParams({      
      ignoreIDs: this.data
    }))
  }

  addQuestion(question: IQuestion): void {
    this.addQnsEvent.emit(question);
    this.availableQns = this.availableQns.filter(a => a.id !== question.id)
  }

}

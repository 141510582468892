import { Component, Input, Output, OnChanges, OnInit, SimpleChanges, ViewChild, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingService } from '../../services/loading.service';
import { IService } from '../../interfaces/IService';
import { SalonFinderCollectionService } from '../../services/salon-finder-collection.service';
import { SalonFinderServiceService } from '../../services/salon-finder-service.service';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { debounceTime, filter, map, startWith } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { ICollection } from '../../interfaces/ICollection';

interface PendingSelection {
	[ key: number ]: boolean;
}

@Component({
  selector: 'salon-finder-collection-add-service',
  templateUrl: './collection-add-service.component.html',
  styleUrls: ['./collection-add-service.component.scss']
})
export class CollectionAddServiceComponent extends ListingComponent<IService> implements OnInit, OnChanges {
  
  @Output() 
  parentFunctionnewselectedServices: EventEmitter<IService[]> = new EventEmitter();

  @Input()
  loadedCollection: ICollection;

  @Input()
  collectionID: number

  loading$: Subscription;

  searchForm: FormGroup;
  searchFormRight: FormGroup;
  serviceForm: FormGroup = null;
  savingForm = false;

  service_id =  '';
  collectionName = '';

  // dataSource!:MatTableDataSource<any>;
  dataSource: MatTableDataSource<IService>;
  dataSourceRight: MatTableDataSource<IService>;
  formList: IService[];

  // total: number;
  currentDate: Date = new Date();
  ended: string;
  isselected = false;

  selection = new SelectionModel<IService>(true, []);
  dataLoaded = false;
  submitted = false;
  massage = null;
  loadingCollectionID = 0;
  selectedServicesx = [];
  newselectedServices = [];
  pendingSelectionServices = [];

  public leftServices: IService[];
  public rightServices: IService[];
  public pendingSelection: IService[] = [];
  public pendingUndoSelection: IService[] = [];
	public selectedServices: IService[] = [];
  filteredSelectedServices: IService[];
	public unselectedServices: IService[];

  // @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  // @ViewChild(MatSort, {static: false}) sort: MatSort;

  @ViewChild('TableLeftPaginator') TableLeftPaginator: MatPaginator;
  @ViewChild('TableLeftSort') TableLeftSort: MatSort;

  @ViewChild('TableRightPaginator') TableRightPaginator: MatPaginator;
  @ViewChild('TableRightSort') TableRightSort: MatSort;

  constructor(
    private readonly fb: FormBuilder,
    private readonly salonFinderCollectionService: SalonFinderCollectionService,
    private readonly salonFinderServiceService: SalonFinderServiceService,
    public readonly activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private readonly snackBar: MatSnackBar,
    public loader: LoadingService
  ) {
    super();
    this.makeListingCols();
    this.pendingUndoSelection = []
    // this.dataSource = new MatTableDataSource(this.services);
  }

  // Add the selected service or services to the selected services collection.
	public addToSelectedServices( service?: IService ): void {
    if(service) {
      this.selectedServices.push(service)
    } else {
      this.selectedServices = this.selectedServices.concat(this.pendingSelection)
    }    
  
    this.selectedServices = this.selectedServices.filter((s,i,o)=> i === o.findIndex(oi=>oi.id === s.id))

    // Enable submit button.
    this.savingForm = true;
    this.populateList();
    this.parentFunctionnewselectedServices.emit(this.selectedServices);
    this.pendingSelection = []
    this.filteredSelectedServices = this._filterSelectedService(this.searchFormRight.get('searchText').value)
    
	}

  // Remove the selected service or services from the selected services collection.
	public removeFromSelectedServices( service?: IService ): void {
    if(service) {
      const foundIndex = this.selectedServices.findIndex(s=>s.id === service.id)
      if(foundIndex>=0) {
        this.selectedServices = this.selectedServices.filter(s=>s.id !== service.id)        
      }
    } else {
      this.selectedServices = this.selectedServices.filter(s=>this.pendingUndoSelection.findIndex(un=>un.id === s.id)<0)
    }
    this.savingForm = true;
    this.populateList();
    this.parentFunctionnewselectedServices.emit(this.selectedServices);
    this.pendingUndoSelection = []
    this.filteredSelectedServices = this._filterSelectedService(this.searchFormRight.get('searchText').value)
	}

  isPendingUndoSelected(service: IService) {
    return this.pendingUndoSelection.find(p=>p.id === service.id)
  }

  togglePendingUndoSelection(service: IService) {
    const foundIndex = this.pendingUndoSelection.findIndex(p=>p.id === service.id)
    if(foundIndex>=0) {
      this.pendingUndoSelection = this.pendingUndoSelection.filter(p=>p.id !== service.id)      
    } else {
      this.pendingUndoSelection.push(service)
    }
  }

  isPendingSelected(service: IService) {
    return this.pendingSelection?.find(p=>p.id === service.id)
  }

  // Toggle the pending selection for the given service.
	public togglePendingSelection( service: IService): void {
    const foundIndex = this.pendingSelection.findIndex(p=>p.id === service.id)
    if(foundIndex>=0) {
      this.pendingSelection = this.pendingSelection.filter(p=>p.id !== service.id)      
    } else {
      this.pendingSelection.push(service)
    }		
	}

  // Remove the given services from the given collection. Returns a new collection.
  makeListingCols() {
    this.listingCols = !this.collectionID ? [
      // 'Action',
      'Advertiser',
      'Service Name',
      'Price',
      // 'Outlets',
      // 'Types',
      // 'Promotions',
      // 'Purchased',
      'Status'
    ] : [
      // 'Action',
      'Service Name',
      'Price',
      // 'Outlets',
      // 'Types',
      // 'Promotions',
      // 'Purchased',
      'Status'
    ];
  }

  setup() {
    this.loadingCollectionID = this.activatedRoute.snapshot.params.id;
    this.initForm()
    this.populateList();
    this.makeListingCols();
    if (this.loadedCollection?.id) {
      this.selectedServices = this.selectedServices
        .concat(this.loadedCollection.services)
        .filter((s, i, o) => o.findIndex(oi => oi.id === s.id) === i); // Remove duplicate
    }
    this.filteredSelectedServices = this.selectedServices
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loadedCollection) {
      if (this.loading$) {
        this.loading$.unsubscribe();
        this.loading$ = null;
      }
      this.setup()
    }
  }

  populateList(params?: HttpParams | Params): void {

    const objectParam = {
      serviceIDs: this.selectedServices.map(s=>s.id),
      fromCollection: true,
      searchText: this.searchForm.get('searchText').value,
      ...params
    };

    // console.log('newobject');
    // console.log(newobject);
    this.salonFinderServiceService
    .list(this.makeSearchParams(objectParam))
    .subscribe(r => {
      // console.log(r)
      
      this.initCommonListingResponse(r);
      this.unselectedServices = r.data
      this.pendingSelection = [];
    });
    
  }

  getExtraListingParams() {
    throw new Error('Method not implemented.');
  }

  deleteItem(item: IService): void {
    throw new Error('Method not implemented.');
  }

  initSelected() {
    if(this.loadedCollection)
      this.selectedServices = this.loadedCollection.services || []
  }

  ngOnInit() {   
    this.setup()
    
  }

  _filterSelectedService(value) {
    if (value === '') {
      return this.selectedServices.slice();
    }
    return this.selectedServices.slice().filter(s => s.advertiser.name.toLowerCase().includes(value.toLowerCase()) ||  s.name.toLowerCase().includes(value.toLowerCase()));
  }

  /**
 * Set the paginator and sort after the view init since this component will
 * be able to query its view for the initialized paginator and sort.
 */
  ngAfterViewInit() {
    if (this.TableLeftPaginator !== undefined && this.dataSource) {
      this.dataSource.paginator = this.TableLeftPaginator;
      this.dataSource.sort = this.TableLeftSort;
    }
    if (this.TableRightPaginator !== undefined && this.dataSourceRight) {
      this.dataSourceRight.paginator = this.TableRightPaginator;
      this.dataSourceRight.sort = this.TableRightSort;
    }
  }

  initForm() {
    this.serviceForm = this.fb.group({
      services: this.fb.array([], [Validators.required])
    });

    this.searchForm = this.fb.group({
      searchText: ['']
    });

    this.searchFormRight = this.fb.group({
      searchText: ['']
    });

    if (this.loadedCollection) {
      this.collectionName = this.loadedCollection.name      
    }

    this.searchForm.get('searchText').valueChanges
      .pipe(
        startWith(''),
        filter(v=>v.length>=2 || v.length === 0),
        debounceTime(500)
      )
      .subscribe(value => {
        console.log(value)
        this.populateList()
      });

      this.searchFormRight.get('searchText').valueChanges
      .pipe(
        startWith(''),
        debounceTime(500),
        map(value => this._filterSelectedService(value || '')),
      ).subscribe(s=> {
        this.filteredSelectedServices = s
      })

  }

  handleSearch() {
    // console.log(this.searchForm.value);
    this.populateList(this.searchForm.value);
  }

  removeService(cid: number, sid: number) {
    this.salonFinderServiceService.removeServiceById(cid, sid).subscribe(
      response => {
        this.savingForm = false;
        // console.log(response);
        // this.snackBar.open('Service has been deleted successfully!', 'Close', {
        //   duration: 2000,
        //   horizontalPosition: 'center',
        //   verticalPosition: 'top'
        // });
        // this.ngOnInit();
      },
      error => {
        this.savingForm = false;
        // this.snackBar.open('Unable to delete service!', 'Close', {
        //   duration: 2000,
        //   horizontalPosition: 'center',
        //   verticalPosition: 'top'
        // });
      }
    );
  }
}

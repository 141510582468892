import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MatDrawerContent } from '@angular/material/sidenav';
import { ActivatedRoute, Event, NavigationEnd, NavigationError, Router } from '@angular/router';
import { filter, map } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { UserRequestService } from './security-guard/services/user-request.service';
import { WINDOW } from './shared/providers/window.provider';
import { DarvisService } from './shared/services/darvis.service';
import { SideMenuComponent } from './shared/components/side-menu/side-menu.component';

@Component({
  selector: 'darvis-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'Darvis';
  
  public openMenu: boolean = true

  public auth: boolean = false

  toggleMenu() {
    this.openMenu = !this.openMenu
    this.sidenavContainer.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    })
  }

  logout() {
    this.authService.logout()
    this.auth = this.authService.isAuth()
  }

  hasProfilePic() {
    return this.authService.getProfileFromToken()?.picture || ""
  }

  getUserName() {
    return this.authService.getProfileFromToken()?.name || "User"
  }

  @ViewChild(MatDrawerContent) sidenavContainer: MatDrawerContent;

  getCurrentGreeting() {
    const splitAfternoon = 12; // 24hr time to split the afternoon
    const splitEvening = 17; // 24hr time to split the evening
    const currentHour = new Date().getHours()
    if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
      // Between 12 PM and 5PM
      return 'Good Afternoon';
    } else if (currentHour >= splitEvening) {
      // Between 5PM and Midnight
      return 'Good Evening';
    }
    // Between dawn and noon
    return 'Good Morning';
  }

  constructor(
    public authService: AuthService,
    public darvisService: DarvisService,
    private readonly router: Router,
    private activatedRoute: ActivatedRoute,
    private userRequestService: UserRequestService,
    @Inject(WINDOW) private window: Window
  ) {
    this.auth = authService.isAuth()
    this.darvisService.DrawerContentContainer = this.sidenavContainer
    this.router.events
      .pipe(
        filter<Event>((event) => event instanceof NavigationEnd || event instanceof NavigationError),
        map((e) => {
          if(e instanceof NavigationEnd) {
            return {
              url: e.url,
              id: e.id,
              activatedRoute: this.activatedRoute
            }
          }
          return {

          }          
        })
      )
      .subscribe((event) => {
        const tokenProfile = authService.getProfileFromToken()
        this.auth = authService.isAuth()
        if(authService.getProfileFromToken()) {
          const path = window?.location?.pathname || ""
          let appName = "Darvis";
          if(path.split('/').length>2) {
            const pathAry = path.split('/');
            const firstPath = pathAry.find(p=>p!="");
            appName += "-"+firstPath;
          }
          userRequestService.pushRequest({
            applicationName: appName,
            userID: tokenProfile.sub,
            type: "Client",
            pathAccessing: window?.location?.pathname || "",
            queryParams: window?.location?.search?.replace("?","") || "",
            action: "GET",
            domain: window?.location?.origin || "",
          })
        }
        
    });
  }
  ngAfterViewInit(): void {
    this.auth = this.authService.isAuth()
    this.darvisService.DrawerContentContainer = this.sidenavContainer 
  }

}

import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { UrlShortenerCollectionService } from "../../services/url-shortener-collection.service";

export interface DialogData {
    id: number;
    status: any;
  }
  

@Component({
    selector: 'archived-verify',
    templateUrl: 'archived-verify.html',
    styleUrls: ['./archived-verify.scss']
  })
  export class ArchivedVerifyDialog {

    isDisabled = true;
    delete:any;
    constructor(
      public dialogRef: MatDialogRef<ArchivedVerifyDialog>,
      private readonly urlShortenerService: UrlShortenerCollectionService,
      private readonly snackBar: MatSnackBar,
      @Inject(MAT_DIALOG_DATA) public data: DialogData,
      private router: Router
    ) {}
  
    onNoClick(): void {
      this.dialogRef.close();
    }

    seePages(){      
      this.router.navigate(['/short-url/detail/'+this.data.id]);
      this.dialogRef.close();
    }
    
    archivedIt(){
      this.dialogRef.close('Yes');
    }
  }
<div class="container-fluid">
  <form [formGroup]="answerData">
    <h3>Options for questions</h3>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="fill" class="fullWidth">
          <mat-label>Options</mat-label>
          <textarea matInput #label placeholder='Enter each options in new line' formControlName="answers"></textarea>
          <mat-error *ngIf="formControls.answers.errors">              
            Cannot be empty
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    
    <div class="row">
      <div class="col-12 text-center">
        <button mat-raised-button color="primary" class="m-2" (click)="save()" [disabled]="answerData.status !== 'VALID'">Save</button>
      </div>
    </div>
  </form>
</div>
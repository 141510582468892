import { Component, Input, OnInit } from '@angular/core';
import { UploadFileEnum } from '../../enums/upload-file-type.enum';
import { ICampaignItem } from '../../interfaces/campaign-item';
import { IMasterData } from '../../interfaces/master-data';

@Component({
  selector: 'native-ads-image',
  templateUrl: './native-ads-image.component.html',
  styleUrls: ['./native-ads-image.component.scss']
})
export class NativeAdsImageComponent implements OnInit {

  @Input('campaignItem')
  campaignItem: ICampaignItem

  get campaignItemIcon() {
    let returnImageURLs = []
    for(const typeValue of Object.values(UploadFileEnum)) {      
      const imageURL = this.campaignItem?.images?.filter(i=>i.type === typeValue)?.sort((a,b) => a.width - b.width)?.at(0)?.url || typeValue==UploadFileEnum.IMAGE_MOBILE?this.campaignItem.imageURL:this.campaignItem.desktopImageURL
      if(imageURL) {
        returnImageURLs.push(imageURL)
      }
    }
    return returnImageURLs
  }

  constructor() { }

  ngOnInit(): void {
  }

}

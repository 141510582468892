import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { LuxonModule } from 'luxon-angular';
import { LMarkdownEditorModule } from 'ngx-markdown-editor';
import { ConfirmDeleteDialogComponent } from './components/confirm-delete-dialog/confirm-delete-dialog.component';
import { CustomerFormComponent } from './components/customer-form/customer-form.component';
import { FormAddQuestionsDialogComponent } from './components/form-add-questions-dialog/form-add-questions-dialog.component';
import { FormsFormComponent } from './components/forms-form/forms-form.component';
import { FormsQuestionDependencyDialogComponent } from './components/forms-question-dependency-dialog/forms-question-dependency-dialog.component';
import { QuestionAnswersDialogComponent } from './components/question-answers-dialog/question-answers-dialog.component';
import { QuestionFormComponent } from './components/question-form/question-form.component';
import { ConsumerReportRouteModule } from './consumer-report.routes';
import { CustomerCreateComponent } from './pages/customer-create/customer-create.component';
import { CustomerIndexComponent } from './pages/customer-index/customer-index.component';
import { CustomerUpdateComponent } from './pages/customer-update/customer-update.component';
import { FormsCreateComponent } from './pages/forms-create/forms-create.component';
import { FormsIndexComponent } from './pages/forms-index/forms-index.component';
import { FormsUpdateComponent } from './pages/forms-update/forms-update.component';
import { QuestionCreateComponent } from './pages/question-create/question-create.component';
import { QuestionIndexComponent } from './pages/question-index/question-index.component';
import { QuestionUpdateComponent } from './pages/question-update/question-update.component';
import { RouterModule } from '@angular/router';
import { StoreCreateComponent } from './pages/store-create/store-create.component';
import { StoreUpdateComponent } from './pages/store-update/store-update.component';
import { StoreIndexComponent } from './pages/store-index/store-index.component';
import { BrandsIndexComponent } from './pages/brands-index/brands-index.component';
import { BrandsCreateComponent } from './pages/brands-create/brands-create.component';
import { BrandsUpdateComponent } from './pages/brands-update/brands-update.component';
import { InfluencerIndexComponent } from './pages/influencer-index/influencer-index.component';
import { InfluencerCreateComponent } from './pages/influencer-create/influencer-create.component';
import { InfluencerUpdateComponent } from './pages/influencer-update/influencer-update.component';
import { BrandsFormComponent } from './components/brands-form/brands-form.component';
import { InfluencerFormComponent } from './components/influencer-form/influencer-form.component';
import { StoreFormComponent } from './components/store-form/store-form.component';
import { ParticipantIndexComponent } from './pages/participant-index/participant-index.component';
import { ParticipantCreateComponent } from './pages/participant-create/participant-create.component';
import { ParticipantUpdateComponent } from './pages/participant-update/participant-update.component';
import { ParticipantSubmissionIndexComponent } from './pages/participant-submission-index/participant-submission-index.component';
import { ParticipantSubmissionCreateComponent } from './pages/participant-submission-create/participant-submission-create.component';
import { ParticipantSubmissionViewComponent } from './pages/participant-submission-view/participant-submission-view.component';
import { ParticipantSubmissionUpdateComponent } from './pages/participant-submission-update/participant-submission-update.component';
import { FormUserdefinedIndexComponent } from './pages/form-userdefined-index/form-userdefined-index.component';
import { FormUserdefinedCreateComponent } from './pages/form-userdefined-create/form-userdefined-create.component';
import { QuestionAnswerFormComponent } from './components/question-answer-form/question-answer-form.component';
import { AccountManagerIndexComponent } from './pages/account-manager-index/account-manager-index.component';
import { AccountManagerFormComponent } from './components/account-manager-form/account-manager-form.component';
import { AccountManagerCreateComponent } from './pages/account-manager-create/account-manager-create.component';
import { AccountManagerUpdateComponent } from './pages/account-manager-update/account-manager-update.component';
import { FormDuplicateComponent } from './components/form-duplicate/form-duplicate.component';
import { ReportsIndexComponent } from './pages/reports-index/reports-index.component';
import { ReportsCreateComponent } from './pages/reports-create/reports-create.component';
import { ReportsUpdateComponent } from './pages/reports-update/reports-update.component';
import { ReportsFormComponent } from './components/reports-form/reports-form.component';
import { ReportsFormLayoutDialogComponent } from './components/reports-form-layout-dialog/reports-form-layout-dialog.component';
import { ReportsFormQuestionComponent } from './components/reports-form-question/reports-form-question.component';
import { ReportsFormQuestionAnswersComponent } from './components/reports-form-question-answers/reports-form-question-answers.component';
import { SurveyResultReportDialogComponent } from './components/survey-result-report-dialog/survey-result-report-dialog.component';
import { ReportFormErrorDialogComponent } from './components/report-form-error-dialog/report-form-error-dialog.component';
import { ReportFormQuestionDependencyDialogComponent } from './components/report-form-question-dependency-dialog/report-form-question-dependency-dialog.component';
import { ReportsGroupIndexComponent } from './pages/reports-group-index/reports-group-index.component';
import { ReportsGroupCreateComponent } from './pages/reports-group-create/reports-group-create.component';
import { ReportsGroupUpdateComponent } from './pages/reports-group-update/reports-group-update.component';
import { ReportsGroupFormComponent } from './components/reports-group-form/reports-group-form.component';
import { QuillModule } from 'ngx-quill';

@NgModule({
  declarations: [
    CustomerCreateComponent,
    CustomerUpdateComponent,
    ConfirmDeleteDialogComponent,
    CustomerIndexComponent,
    CustomerFormComponent,
    FormsIndexComponent,
    FormsCreateComponent,
    FormsUpdateComponent,
    FormsFormComponent,
    QuestionCreateComponent,
    QuestionUpdateComponent,
    QuestionFormComponent,
    QuestionIndexComponent,
    FormsQuestionDependencyDialogComponent,
    QuestionAnswersDialogComponent,
    FormAddQuestionsDialogComponent,
    StoreCreateComponent,
    StoreUpdateComponent,
    StoreIndexComponent,
    BrandsIndexComponent,
    BrandsCreateComponent,
    BrandsUpdateComponent,
    InfluencerIndexComponent,
    InfluencerCreateComponent,
    InfluencerUpdateComponent,
    BrandsFormComponent,
    InfluencerFormComponent,
    StoreFormComponent,
    ParticipantIndexComponent,
    ParticipantCreateComponent,
    ParticipantUpdateComponent,
    ParticipantSubmissionIndexComponent,
    ParticipantSubmissionCreateComponent,
    ParticipantSubmissionViewComponent,
    ParticipantSubmissionUpdateComponent,
    FormUserdefinedIndexComponent,
    FormUserdefinedCreateComponent,
    QuestionAnswerFormComponent,
    AccountManagerIndexComponent,
    AccountManagerCreateComponent,
    AccountManagerUpdateComponent,
    AccountManagerFormComponent,
    FormDuplicateComponent,
    ReportsIndexComponent,
    ReportsCreateComponent,
    ReportsUpdateComponent,
    ReportsFormComponent,
    ReportsFormLayoutDialogComponent,
    ReportsFormQuestionComponent,
    ReportsFormQuestionAnswersComponent,
    SurveyResultReportDialogComponent,
    ReportFormErrorDialogComponent,
    ReportFormQuestionDependencyDialogComponent,
    ReportsGroupIndexComponent,
    ReportsGroupCreateComponent,
    ReportsGroupUpdateComponent,
    ReportsGroupFormComponent,

  ],
  imports: [
    CommonModule,
    ConsumerReportRouteModule,
    MatTableModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatButtonModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatSnackBarModule,
    MatChipsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatCardModule,
    MatDividerModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    LuxonModule,
    DragDropModule,
    CdkStepperModule,
    LMarkdownEditorModule,
    RxReactiveFormsModule,
    RouterModule,
    QuillModule.forRoot(),
  ],
  providers: [
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['l', 'LL']
        },
        display: {
          dateInput: 'L',
          monthYearLabel: 'MMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY'
        }
      }
    }
  ]
})
export class ConsumerReportModule { }

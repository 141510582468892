import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { ICommonObject } from 'src/app/shared/interfaces/ICommonObject';
import { ICRUDPath } from 'src/app/shared/interfaces/iCRUDPath.interface';
import { environment } from 'src/environments/environment';
import { IFollower } from '../interfaces/IFollower';
import moment from 'moment';
import { INotification } from '../interfaces/INotification';

@Injectable({
  providedIn: 'root'
})
export class TelegramCollectionService extends CRUDService<IFollower> {
 
  apiPath: string;
  httpClient: HttpClient;
  environmentCRUDPath: ICRUDPath;
  baseURL = environment.telegramReport.baseURL;
  shortUrlbaseURL = environment.telegramReport.shortUrlbaseURL;
   
  constructor(
    private readonly http: HttpClient,
    private fb: FormBuilder,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute
  
    ) {
    super();
  }

  list(params?: HttpParams): Observable<ICommonListingResponse<IFollower[]>> {
    throw new Error('Method not implemented.');
  }
  get(id: string | number): Observable<IFollower> {
    throw new Error('Method not implemented.');
  }
  delete(id: string | number): Observable<IFollower> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: IFollower): Observable<IFollower> {
    throw new Error('Method not implemented.');
  }
  create(model: IFollower): Observable<IFollower> {
    throw new Error('Method not implemented.');
  }
  makeFormGroup(loadedModel?: IFollower) {
    throw new Error('Method not implemented.');
  }

  makeSearchListGroup(defaultParams?: ICommonObject) {
    throw new Error('Method not implemented.');
  }

  getAllFollowers(startDate:string, endDate:string) {
    return this.http.get<ICommonListingResponse<IFollower[]>>(this.baseURL + environment.telegramReport.getAllFollowers +"?start="+startDate+"&end="+endDate); 
  }

  getFollowersComparison(from:any, to:any){
    let params = {'from': from, 'to': to}
    return this.http.post<ICommonListingResponse<any[]>>(this.baseURL + environment.telegramReport.getFollowersComparison,params); 
  }

  getAllNotifications(startDate:string, endDate:string) {
    let params = {'start': startDate, 'end': endDate}
    return this.http.post<ICommonListingResponse<INotification[]>>(this.baseURL + environment.telegramReport.getAllNotifications,params); 
  }

  getNotificationsComparison(from:any, to:any){
    let params = {'from': from, 'to': to}
    return this.http.post<ICommonListingResponse<any[]>>(this.baseURL + environment.telegramReport.getNotificationsComparison,params); 
  }

  getAllPosts(startDate:string, endDate:string) {
    return this.http.get<ICommonListingResponse<any[]>>(this.baseURL + environment.telegramReport.getAllPost+"?start="+startDate+"&end="+endDate); 
  }

  getPostsComparison(from:any, to:any){
    let params = {'from': from, 'to': to}
    return this.http.post<ICommonListingResponse<any[]>>(this.baseURL + environment.telegramReport.getPostComparison,params); 
  }
  
  getAllChat(page:number,take:number,searchText:string){
    let params = {
      status: 1,
      filter:searchText,
      page: page,
      take: take,
    }
    return this.http.get<ICommonListingResponse<any[]>>(this.baseURL + environment.telegramReport.getAllChat, {params}); 
  }

  
  updateSocialEngagementLinks(data){
    return this.http.post<ICommonListingResponse<any[]>>(this.baseURL + environment.telegramReport.updateSocialEngagementLinks,data); 
  }

  unlinkClick(params:any){

    return this.http.post<ICommonListingResponse<any[]>>(this.baseURL + environment.telegramReport.unlinkClick,params); 
  }

  

  //SHORT URL API's

  getShortUrlClicksData(page:number,take:number, customSearch:string, withTGDesc:boolean){
    let params = {
      status: 1,
      client: '',
      campaign: '',
      page: withTGDesc ? page : 0,
      take: take,
      customSearch: customSearch,
      withTGDesc: withTGDesc,
      domain: ''
    }
    return this.http.get<any>(this.shortUrlbaseURL + environment.urlShortener.telegramGetUrls, {params });
  }

  async getShortUrlClicksForComparison(from:any[], to:any[] ):  Promise<any>  {
    let params = {from : from, to: to}
    const data = await this.http.get<any>(this.shortUrlbaseURL + environment.urlShortener.telegramTagShortUrlLinksForComparison, {params }).toPromise();
    return data;
  }
 
}

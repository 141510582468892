<div class="container">
  
  <h1 mat-dialog-title>Voucher Code: #{{data.voucherCode}}</h1>
  <ng-container *ngIf="loading">
    <div class="overlay"></div>
    <mat-progress-spinner [mode]="'indeterminate'" [diameter]="50"></mat-progress-spinner>
  </ng-container>
  <div mat-dialog-content class="row" *ngIf="!loading">
    <p>Client Information</p>
    <mat-form-field class="col-12" appearance="fill">
      <mat-label>Client Name</mat-label>
      <input matInput [formControl]="name">
    </mat-form-field>
    <mat-form-field class="col-12" appearance="fill">
      <mat-label>Client Email</mat-label>
      <input matInput [formControl]="email">
      <mat-error *ngIf="email.hasError('email')">
        <span>Wrong email format</span>
      </mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions *ngIf="!loading">
    <button mat-button (click)="sendEmail()">Send</button>
    <button mat-button [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
  </div>
</div>


<div class="row" [formGroup]="formData">
	<div class="d-flex flex-row-reverse">
		<button mat-icon-button color="warn" [disabled]="index===0" (click)="removeForm(index)"><mat-icon>disabled_by_default</mat-icon></button>
		<button mat-icon-button (click)="addForm()"><mat-icon>add_box</mat-icon></button>
	</div>
	<mat-form-field class="col-12 col-sm-4 col-lg-4" appearance="fill">
		<mat-label>Name</mat-label>
		<input
			matInput
			maxlength="256"
			placeholder="Name"
			formControlName="label"
		/>
		<mat-error>
			<span *ngIf="formData.get('label').hasError('required')"
				>Name <strong>required</strong></span
			>
		</mat-error>
	</mat-form-field>
	
	<mat-form-field class="col-12 col-sm-8 col-lg-8" appearance="fill">
		<mat-label>Path</mat-label>
		<input
			matInput
			maxlength="256"
			placeholder="Path after domain. You can use '/*' last char as wildcard. Use '/**/*' to represent any path and char after"
			formControlName="path"
		/>
		<mat-error>
			<span *ngIf="formData.get('path').hasError('required')"
				>Path <strong>required</strong></span
			>
		</mat-error>
	</mat-form-field>
	<mat-form-field class="col-12 col-sm-6 col-lg-6" appearance="fill">
		<mat-label>Service</mat-label>
		<mat-select formControlName="service"  [compareWith]="compareCommonEntity">
			<mat-option *ngFor="let service of services$ | async" [value]="service">
				{{service.name}}
			</mat-option>
		</mat-select>
	</mat-form-field>   

	<mat-form-field class="col-6 col-sm-3 col-lg-3" appearance="fill">
		<mat-label>Method</mat-label>
		<mat-select formControlName="method">
			<mat-option [value]="'ALL'" *ngFor="let methodValue of methodDrop" [value]="methodValue.id">
				{{ methodValue.name }}
			</mat-option>
		</mat-select>
		<mat-error>
			<span *ngIf="formData.get('method').hasError('required')"
				>Method <strong>required</strong></span
			>
		</mat-error>
	</mat-form-field>

	<mat-form-field class="col-6 col-sm-3 col-lg-3" appearance="fill">
		<mat-label>Allow Access</mat-label>
		<mat-select formControlName="allowAccess">
			<mat-option [value]="false">
				Deny
			</mat-option>
			<mat-option [value]="true">
				Grant
			</mat-option>
		</mat-select>
	</mat-form-field>   
</div>
			
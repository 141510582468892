import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IOrderVoucher } from '../../interfaces/IOrderVoucher';
import { SalonFinderOrderVoucherService } from '../../services/salon-finder-order-voucher.service';

@Component({
  selector: 'salon-finder-order-voucher-send-email-dialog',
  templateUrl: './order-voucher-send-email-dialog.component.html',
  styleUrls: ['./order-voucher-send-email-dialog.component.scss']
})
export class OrderVoucherSendEmailDialogComponent implements OnInit {

  name = new FormControl()
  email = new FormControl()

  emailError: string
  loading = false

  constructor(
    public dialogRef: MatDialogRef<OrderVoucherSendEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IOrderVoucher,
    public salonFinderOrderVoucherService: SalonFinderOrderVoucherService,
    private readonly snackBar: MatSnackBar,
  ) {
    this.name.setValue(data.order.customerName)
    this.email.setValue(data.order.customerEmail)
    this.email.setValidators([Validators.email])
  }

  sendEmail(): void {    
    if(this.email.errors?.length>0) {
      this.snackBar.open('Please resolve all errors', 'Close', {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      })
    } else {
      this.loading = true
      this.salonFinderOrderVoucherService.emailVoucher(this.data, this.email.value, this.name.value)
        .subscribe(d=> {
          this.dialogRef.close(d);
          this.loading = false
        })
    }
    
  }

  ngOnInit(): void {
  }

}

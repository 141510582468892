<div class="container">
  <h1 mat-dialog-title>Voucher Code: #{{data.voucherCode}}</h1>
  <ng-container *ngIf="loading">
    <div class="overlay"></div>
    <mat-progress-spinner [mode]="'indeterminate'" [diameter]="50"></mat-progress-spinner>
  </ng-container>
  <div mat-dialog-content class="row" *ngIf="!loading">    
    <mat-form-field class="col-12" appearance="fill">
      <mat-label>Expiry Date</mat-label>
      <input matInput [matDatepicker]="expiryDatePicker" [formControl]="expiryDate" [min]="minDate">
      <mat-datepicker-toggle matSuffix [for]="expiryDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #expiryDatePicker></mat-datepicker>
      <mat-error *ngIf="expiryDate.hasError('required')">
        <span>Expiry date cannot be empty</span>
      </mat-error>
      <mat-error *ngIf="expiryDate.hasError('matDatepickerMin')">
        <span>Expiry date must be later than today.</span>
      </mat-error>
    </mat-form-field>    
  </div>
  <div mat-dialog-actions *ngIf="!loading">
    <button mat-button (click)="save()">Save</button>
    <button mat-button [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
  </div>
</div>
  
import { Auth } from "./auth";
import { nativeAdsServer } from "./native-ads-server";


export const nativeAds = {
  commonDeletePath: '/',
  commonListPath: '/',
  commonUpdatePath: '',
  commonCreatePath: '/',
  commonGetPath: '',
  campaignBase: '/campaign',
  campaignItemBase: '/campaign-item',
  masterdataBase: '/master-data',
  publisherBase: '/publisher',
  userBase: '/user',
  uploadFileBase: '/upload',
  impressionBase: '/impression',
  impressionStatsByCampaignItem: '/stats-by-campaign-item',
  listCampaignName: '/list-campaign-name',
  campaignUpdateStatus: '/update-status',
  roles: [
    'marketing@dailyvanity.com',
    'marketing.sg@dailyvanity.com',
    'marketing.my@dailyvanity.com',
    'campaign@dailyvanity.com',
  ].map(r=>r+"_google_workspace").concat(Auth.defaultAdminRoles),
  imageSizing: {
    'cu': [
      {
        width: 1800
      },
      {
        width: 1500
      },
      {
        width: 1200
      },
      {
        width: 900
      },
      {
        width: 650
      },
      {
        width: 450
      }
    ],
    'ai': [
      {
        width: 1400
      },
      {
        width: 1000
      },
    {
      width: 700
    }, 
    {
      width: 500
    }, 
    {
      width: 350
    }],
    'pu': [
      {
        width: 2000
      },
      {
        width: 1500
      },
    {
      width: 1000
    }, 
    {
      width: 500
    }, 
    {
      width: 400      
    }, 
    {
      width: 200
    }]
  },
  ...nativeAdsServer
}
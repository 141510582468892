import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Route, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { IConfirmNewPasswordWithCode } from '../interfaces/iconfirm-password-with-code';

@Component({
  selector: 'app-confirm-new-password',
  templateUrl: './confirm-new-password.component.html',
  styleUrls: ['./confirm-new-password.component.scss']
})
export class ConfirmNewPasswordComponent implements OnInit {
  get enteredEmail() {
    if(this.historyState?.email) {
      let loc = this.historyState?.email.indexOf('@') + 1
      return this.historyState?.email.substring(0,1)+'****@'+this.historyState?.email.substring(loc, loc+1)+'****'
    }
    return ""
  }
  
  formData: FormGroup = null

  historyState = null

  constructor(private readonly authService: AuthService, private readonly router: Router) { 
    this.historyState = window.history.state
    console.log(this.historyState)
    if(!this.historyState?.email) {
      router.navigate(['forget-password'])
    }
    this.initForm();
  }

  initForm() {
    const state = window.history.state
    this.formData = this.authService.makeConfirmNewPasswordFormData({
      Username: state.email,
      Password: "",
      ConfirmationCode: ""
    })
  }

  ngOnInit(): void {
    this.initForm()
  }

  async updatePassword() {
    if(this.formData.valid) {
      const formControls = this.formData.controls
      const confirmationPasswordField: IConfirmNewPasswordWithCode = {
        Username: formControls.username.value,
        Password: formControls.password.value,
        ConfirmationCode: formControls.confirmationCode.value
      }
      try {
        await this.authService.confirmNewPasswordWithCode(confirmationPasswordField)
        this.router.navigate(['/sign-in'])
      } catch(e) {
        
      }
      
    }
    
  }

}

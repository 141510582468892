import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { endWith, tap } from 'rxjs/operators';
import { IOutlet } from '../../interfaces/IOutlet';
import { LoadingService } from '../../services/loading.service';
import { SalonFinderAdvertiserService } from '../../services/salon-finder-advertiser.service';
import { SalonFinderOutletService } from '../../services/salon-finder-outlet.service';

@Component({
  selector: 'salon-finder-outlet-update',
  templateUrl: './outlet-update.component.html',
  styleUrls: ['./outlet-update.component.scss']
})
export class OutletUpdateComponent implements OnInit {

  outletForm: IOutlet = {
    advertiser: null,    
    name: '',
    outletCode: '',
    address: '',
    postalCode: '',
    email: '',
    notificationEmail: '',
    phone: '',
    description: '',
    __sImagesPath: '',
    s_images: '',
    opHours: [],
    creditCard: 0,
    active: false,
    advertiserID: 0,
    bankingEntity: null,
    uploadImages: []
  }

  loadingData: boolean = false

  constructor(private activatedRoute: ActivatedRoute, private readonly salonFinderAdvertiserService: SalonFinderAdvertiserService,
    public loader: LoadingService, private salonFinderOutletService: SalonFinderOutletService) { }
  
  ngOnInit(): void {
    this.loadingData = true
    this.salonFinderOutletService.get(this.activatedRoute.snapshot.params.id)
      .subscribe(d=> {
        this.outletForm = d
        this.loadingData = false      
      })
  }
}
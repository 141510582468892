import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { IEmailNotification } from '../../interfaces/iemail-notification';
import { SalonFinderEmailNotificationService } from '../../services/salon-finder-email-notification.service';

@Component({
  selector: 'salon-finder-email-notification-index',
  templateUrl: './email-notification-index.component.html',
  styleUrls: ['./email-notification-index.component.scss']
})
export class EmailNotificationIndexComponent extends ListingComponent<IEmailNotification> implements OnInit {

  populateList<U>(params?: U | HttpParams): void {
    this.commonPopulateList()
  }
  getExtraListingParams(): void {
    throw new Error('Method not implemented.');
  }
  deleteItem(item: IEmailNotification): void {
    throw new Error('Method not implemented.');
  }

  constructor(
    public override readonly baseService: SalonFinderEmailNotificationService
  ) {
    super();
    this.listingCols = [
      'name',
      'subject',
      'sender',
      'reply',
      'description',
      'action'
    ];
  }

  ngOnInit(): void {
    this.populateList()
  }

}

import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { ConfigCompare, ConfigDisplayTypes, ConfigDisplayWidths } from '../../constant/StaticReportConfig';
import { ConsumerReportSurveyConfigService } from '../../services/consumer-report-survey-config.service';
import { MatOptionSelectionChange } from '@angular/material/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { ReportFormQuestionDependencyDialogComponent } from '../report-form-question-dependency-dialog/report-form-question-dependency-dialog.component';
import { ConsumerReportFormQuestionService } from '../../services/consumer-report-form-question.service';
import { IGraphQuestionAnswer } from '../../interfaces/iStaticReportConfig';
import { ConsumerReportBrandCategoryService } from '../../services/consumer-report-brand-category.service';
import { IBrandCategory } from '../../interfaces/ibrand-category';
import { IOptionCountryList } from '../../interfaces/ioption-country-list';
import { ConsumerReportOptionService } from '../../services/consumer-report-option.service';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'app-reports-form-question',
  templateUrl: './reports-form-question.component.html',
  styleUrls: ['./reports-form-question.component.scss'],
  host: {'[id]': 'id'}
})
export class ReportsFormQuestionComponent implements AfterViewInit, OnInit{

  @Input("panelUUID")
  panelUUID:string

  @Input("generalInformationForm")
  generalInformationForm: FormGroup


  @Input("panelIndex")
  panelIndex: number;

  @Input("filteredQuestions")
  filteredQuestions:any[];

  @Input("savingError")
  savingError: any;

  @Input("panelGraphs")
  panelGraphs: FormArray;

  @Input("deletedGraphs")
  deletedGraphs:any

  filteredOptions: Observable<any[]>;

	brandsCategories: IBrandCategory[] = [];
	selectedBrandCategory: FormControl<IBrandCategory> = new FormControl();

	brandsCountries$: Observable<IOptionCountryList[]>;
	selectedbrandsCountry: FormControl<IOptionCountryList> = new FormControl();

  defaultQuestions = [];
  generateAnswer = false;

  graphsInformation:FormGroup

  @Output() graphQuestionsChange = new EventEmitter<{question : any, panelUUID: string }>();


  graph = [{
    order: 1,
    subID: "",
    subTitle: "",
    displayType: "",
    displayWidth: "",
    question: "",
    subAnswers: [],
    showInPercentage: [false],
    showCompareQuestion:[false],
    nonFree:[false],
    formQuestionID:[0],
    deletedAnswers: [],
    compare:"is",
    matrices:"",
    openedPanelAnswer:false
  }]

  displayTypes = ConfigDisplayTypes;
  displayWidths = ConfigDisplayWidths;
  compares = ConfigCompare;

  selectedWidth = null;
  selectedDisplayType = "";
  selectedQuestion;

  constructor(
    private formBuilder: FormBuilder,
    private consumerReportSurveyConfig : ConsumerReportSurveyConfigService,
    private consumerReportFormQuestionService: ConsumerReportFormQuestionService,
		private readonly consumerReportBrandCategoryService: ConsumerReportBrandCategoryService,
		private readonly consumerReportOptionService: ConsumerReportOptionService,
    private readonly dialog: MatDialog,
    ) {
  }

  ngOnInit(){
    this.defaultQuestions = this.filteredQuestions;
    if(!this.generalInformationForm.get("saveFailed").value) this.initializeGraphs();
		
    // NOTE:  trigger this when panel is clicked !!!
    
    this.consumerReportBrandCategoryService.list().subscribe((result) => {
			this.brandsCategories = result.data;
		});
		this.brandsCountries$ = this.consumerReportOptionService.findByType<IOptionCountryList[]>('countryList')

    this.graphsInformation.valueChanges.subscribe(value => {
      this.graphQuestionsChange.emit({
        question: value,
        panelUUID: this.panelUUID
      })
    })
  }

	isBrandQuestions(graph: FormGroup) {
		const formQuestionUUID = graph.get('subID').value;
		const formQuestion = this.filteredQuestions.find(fq => fq.uuid === formQuestionUUID);
		if(formQuestion && formQuestion.answers && formQuestion.answers.length > 0) {
			const brandAnswerIndex = formQuestion.answers.findIndex(a => a.questionAnswer.linkedWith == 'brand');
			if(brandAnswerIndex>=0) {
				return true
			}
		}
		return false;
	}

  ngAfterViewInit(){
  }

  private _filter(searchValue:any): any[] {
      if(typeof searchValue === 'object' && searchValue.question != null ){
         return this.filteredQuestions.filter(option =>   option.label.toLowerCase().includes(searchValue.question.toLowerCase()))
      }else{
        return  this.defaultQuestions;
      }
    }

  createGraphQuestionAnswerArray(graphsQuestionAnswer: []) : FormArray {
    let formGroupArrayGraphAnswers = [];
    for(let gqa of graphsQuestionAnswer){
      formGroupArrayGraphAnswers.push(this.consumerReportSurveyConfig.makeGraphAnswerGroup(gqa));
    }
    return this.formBuilder.array(formGroupArrayGraphAnswers);

  }

  resetGenerateAllAnswers(value: any) {
    this.generateAnswer =  value == "true";
  }

  //Initialize question row
  initializeGraphs(){

    //Initialize content row
    this.graphsInformation  = this.formBuilder.group({
      deletedGraphs: [[]],
      graphs: this.panelGraphs,
    })
    this.filterOptions();
  }

  addSubPanel(graph:FormControl){
		this.panelGraphs.push(this.consumerReportSurveyConfig.makeGraphGroup({order: this.panelGraphs.controls.length + 1}));
		this.filterOptions();
  }

  filterOptions(){
    this.filteredOptions = this.graphsInformation.get('graphs')['controls'].map((control: any,index:number) =>
    control.valueChanges.pipe(
      startWith(''),
      map((searchValue: string) => this._filter(searchValue || ''))
    )
  );
  }

  deleteSubPanel(subPanelIndex:number, mainPanelIndex:number, graph:FormGroup){
    if(graph.get("id").value > 0){
      this.deletedGraphs.value.push(graph.get("id").value);
    }
    this.graphsInformation.get('graphs')['controls'].splice(subPanelIndex,1);
    this.graphsInformation.get('graphs').value.splice(subPanelIndex,1);
    this.graphsInformation.get('graphs').setErrors(null);
  }


  changeSelectedQuestion(event:MatOptionSelectionChange, graph:FormControl){
    if(event.source.id != ""){
      let question = this.defaultQuestions.find(df => df.uuid == event.source.id);
      if(question){
        graph.patchValue({
          subID: event.source.id,
          order: graph.get('order').value,
          subTitle: "",
          displayType: this.consumerReportSurveyConfig.setDefaultDisplayTypes(question.answers.length, question.controlType),
          displayWidth : 6,
          question:event.source.value,
          showInPercentage: false,
          showCompareQuestion: false,
          nonFree: false,
          formQuestionID:question.id,
          matrices: question.matrices,
          selectedMatrics:"",
          compare: "is"
        })
  
        const subAnswers = graph.get('subAnswers') as FormArray;
        subAnswers.clear(); // Clear the existing controls
  
        graph.updateValueAndValidity()
      }
    }

  }


  changeDisplayType(value:string){
    this.selectedDisplayType = value;
  }

  changeDisplayWidth(value:number){
    this.selectedWidth = value;
  }

  downQuestion(currentIndex:number){
    if(currentIndex > 0){

      const prevIndex = currentIndex - 1;

      const currentFormQuestion = this.panelGraphs.at(currentIndex);

      const prevFormQuestion = this.panelGraphs.at(prevIndex);

      currentFormQuestion.value.order -= 1;

      prevFormQuestion.value.order += 1;

      this.panelGraphs.setControl(prevIndex, currentFormQuestion);
      this.panelGraphs.setControl(currentIndex, prevFormQuestion);

    }

  }

  upQuestion(currentIndex:number){
      if (currentIndex < this.panelGraphs.length - 1) {
      const nextIndex = currentIndex + 1;
      const currentFormQuestion = this.panelGraphs.at(currentIndex);
      currentFormQuestion.value.order += 1;
      const nextFormQuestion = this.panelGraphs.at(nextIndex);
      nextFormQuestion.value.order -= 1;
      this.panelGraphs.setControl(nextIndex, currentFormQuestion);
      this.panelGraphs.setControl(currentIndex, nextFormQuestion);
    }

  }

  async generateAllQuestioAnswers(graph: FormGroup){

    
    this.generateAnswer = true;
    let subAnswers = graph.get('subAnswers') as FormArray;
    graph.get('openedPanelAnswer').setValue(false);
    for(let answer of subAnswers.value){
      graph.get('deletedAnswers').value.push(answer.id)
    }

    subAnswers.clear();

    if(graph.get('subID').value != ""){
      let result =  await this.consumerReportFormQuestionService.getFormQuestionByUUIDNBrand(graph.get('subID').value, this.selectedBrandCategory.value?.id, this.selectedbrandsCountry.value?.country).toPromise();
        for(let formQuestion of result){
          for (let index = 0; index < formQuestion.answers.length; index++) {
            const formQuestionAnswer = formQuestion.answers[index];
            let itemAnswer: IGraphQuestionAnswer = {
              customName: "",
              order: index+1,
              questionID: formQuestionAnswer.formQuestionID,
              selectedAnswers: [{
                label : formQuestionAnswer.label,
                uuid: formQuestionAnswer.uuid
              }],
              displayTypeConfig: null,
              uuid: formQuestionAnswer.uuid,
              dependencies: [],
              compare:graph.get("compare").value,
              matrices: graph.get("matrices").value,
              selectedMatrics: graph.get("selectedMatrics").value,
            }
            subAnswers.push(this.consumerReportSurveyConfig.makeGraphAnswerGroup(itemAnswer));
          }

          const sortedAnswers = subAnswers.controls.sort((a, b) =>
              a.value.order - b.value.order
          );
          subAnswers.controls = sortedAnswers;
          subAnswers.markAllAsTouched();
          subAnswers.updateValueAndValidity();
        }

        graph.markAllAsTouched();
        graph.updateValueAndValidity();
        
        this.graphsInformation.markAllAsTouched();
        this.graphsInformation.updateValueAndValidity();
        this.generateAnswer = false;
        
    }else{
      this.generateAnswer = false;
    }


  }

  onGraphQuestionAnswersChange(event) { 
    let graphID = event.graphID.value;
    let answer = event.answer as FormArray;
    let contentIndex = this.generalInformationForm.value.contents.findIndex(c => c.uuid == this.panelUUID);
    
    if(contentIndex > -1){
        let content = this.generalInformationForm.value.contents[contentIndex];
        if(content){
           let graph = content.graphs.find(g => g.id  == graphID);
            if(graph){
              graph.subAnswers = answer.value;
            }
        }
    }
  }

}

<form [formGroup]="formData">
  <div class="container">
    <mat-card>
      <mat-card-header>
        <mat-card-title class="pt-3">Update Advertiser</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col-6 col-sm-12 col-md-4 col-lg-6 col-xl-4 position-relative">
            <img width="100%" src="{{logoImg}}" />
            <input type="file" class="file-upload" class="d-none" (change)="upload($event)" #uploadInput accept="image/*">
            <div class="position-absolute bottom-0 end-0">
              <button mat-mini-fab color="primary" class="upload-btn" (click)="uploadInput.click()">
                <mat-icon>attach_file</mat-icon>
              </button>
            </div>
          </div>
          <div class="col-6 col-sm-12 col-md-8 col-lg-6 col-xl-8 ">
            <mat-form-field class="fullWidth" appearance="fill">              
              <mat-label>Advertiser Name</mat-label>
              <input matInput maxlength="50" placeholder="Advertiser Name" formControlName="name">
              <mat-error>
                <span *ngIf="formData.get('name').hasError('required')">Advertiser Name is <strong>required</strong></span>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="fullWidth" appearance="fill">
              <mat-label>Commission Rate</mat-label>
              <input matInput maxlength="50" placeholder="Commission Rate (%)" formControlName="commission">
              <mat-error>
                <span *ngIf="formData.get('commission').hasError('required')">Commission Rate (%) is <strong>required</strong></span>
              </mat-error>
              <mat-error>
                <span *ngIf="formData.get('commission').hasError('min')"><strong>Commission Rate (%) should be greater than 0</strong></span>
              </mat-error>
              <mat-error>
                <span *ngIf="formData.get('commission').hasError('max')"><strong>Commission Rate (%) should be less than 100</strong></span>
              </mat-error>
              <mat-error>
                <span *ngIf="formData.get('commission').hasError('pattern')"><strong>Commission Rate (%) should be numeric value</strong></span>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="fullWidth" appearance="fill">
              <mat-label>Contact Email</mat-label>
              <input matInput maxlength="1024" placeholder="Email to receive Bookings & Enquiries" formControlName="email">
              <mat-hint>Allows one email only, Email to receive email bookings and enquiries</mat-hint>
              <mat-error>
                <span *ngIf="formData.get('email').hasError('required')">Email to receive Bookings & Enquiries is <strong>required</strong></span>
              </mat-error>
              <mat-error *ngIf="formData.get('email').hasError('email') && !formData.get('email').hasError('required')">
                <strong>Please enter a valid email address</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="fullWidth" appearance="fill">
              <mat-label>Notification Email</mat-label>
              <input matInput maxlength="1024" placeholder="Email to receive Notifications" formControlName="notificationEmail">
              <mat-hint>Add comma between multiple emails, Email to receive notifications</mat-hint> 
              <mat-error>
                <span *ngIf="formData.get('notificationEmail').hasError('required')">Email to receive Marketing Communication from DV is <strong>required</strong></span>
              </mat-error>
              <mat-error *ngIf="formData.get('notificationEmail').hasError('email') && !formData.get('notificationEmail').hasError('required')">
                <strong>Please enter a valid email address</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="fullWidth" appearance="fill">
              <mat-label>Marketing Email</mat-label>
              <input matInput maxlength="1024" placeholder="Email to receive Marketing Communication from DV" formControlName="marketingEmail">
              <mat-hint>Add comma between multiple emails, Email to receive Marketing Communication from DV</mat-hint> 
              <mat-error>
                <span *ngIf="formData.get('email').hasError('required')">Email to receive Notifications is <strong>required</strong></span>
              </mat-error>
              <mat-error *ngIf="formData.get('marketingEmail').hasError('email') && !formData.get('marketingEmail').hasError('required')">
                <strong>Please enter a valid email address</strong>
              </mat-error>
            </mat-form-field>
            <ng-container *ngIf="loadedModel && loadedModel.id">
              <div class="col-12 mt-2">
                <b>Preview URL</b> <br />
                <a href="{{ wordpressPreviewURL }}" target="_blank">{{ wordpressPreviewURL }}</a>
              </div>
              <div class="col-12 mt-2">
                <b>Password</b> <br />
                {{ loadedModel.password }}
              </div>
            </ng-container>
            
            
          </div>
        </div>
        <section formArrayName="bankingEntities">
          <div class="row" *ngFor="let bankingEntity of bankingEntities.controls; let m=index">
            <ng-container [formGroupName]="m">
              <mat-form-field class="col-6 col-sm-12 col-md-6" appearance="fill">
                <mat-label>Bank Name</mat-label>
                <input matInput maxlength="50" placeholder="Bank Name" formControlName="bankName">
                <mat-error>
                  <span *ngIf="bankingEntity.get('bankName').hasError('required')">Bank account Name is <strong>required</strong></span>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-6 col-sm-12 col-md-6" appearance="fill">
                <mat-label>Bank Account Name (Legal Entity Name)</mat-label>
                <input matInput maxlength="50" placeholder="Registered Entity Name (Legal Entity Name)" formControlName="entityName">
                <mat-error>
                  <span *ngIf="bankingEntity.get('entityName').hasError('required')">Entity Name is <strong>required</strong></span>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-6 col-sm-12 col-md-6" appearance="fill">
                <mat-label>Bank Account Number</mat-label>
                <input matInput maxlength="50" placeholder="Bank Account" formControlName="bankAccount">
                <mat-error>
                  <span *ngIf="bankingEntity.get('bankAccount').hasError('required')">Bank Account is <strong>required</strong></span>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-6 col-sm-12 col-md-6" appearance="fill">
                <mat-label>Finance email</mat-label>
                <input matInput maxlength="1024" placeholder="Email to receive Financial Statements" formControlName="entityEmail">
                <mat-hint>Add comma between multiple emails, Email to receive Financial Statements</mat-hint>                
                <mat-error>
                  <span *ngIf="bankingEntity.get('entityEmail').hasError('required')">Email to receive Financial Statements is <strong>required</strong></span>
                </mat-error>
              </mat-form-field>
            </ng-container>
          </div>
        </section>
        
        <div class="row mt-4">
          <div class="col-12">
              <mat-label>Description</mat-label>
              <quill-editor formControlName="description" [styles]="{height: '150px'}">                  
              </quill-editor>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <!-- <mat-form-field class="fullWidth" appearance="fill"> -->
              <mat-label>Terms & Conditions</mat-label>
              <quill-editor formControlName="terms" [styles]="{height: '150px'}">                  
              </quill-editor>
            <!-- </mat-form-field> -->
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-slide-toggle class="example-margin" formControlName="active">
              Active
            </mat-slide-toggle>
          </div>
        </div>
        <mat-card-actions>
          <div class="row">
            <div class="col-12 text-center">
              <button mat-raised-button color="primary" class="m-2" (click)="save()" >Save</button>
              <button mat-raised-button class="m-2"  [routerLink]="['/salon-finder/advertisers']">Cancel</button>
            </div>
          </div>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>   
  </div>
</form>
<div class="row text-center mb-4" *ngIf="!showPanel">
  <div class="loading-container">
    <mat-spinner diameter="50" color="accent"> </mat-spinner>
  </div>
  <br>
  <mat-label>Loading ...</mat-label>
</div>

<ng-container *ngIf="!isRendering">
    <div class="templates-containers" *ngIf="showPanel">
      <!-- SURVEY FIELDS -->
      <div class="row">
        <div class="col-6">
          <button class="mt-4 mb-4" mat-raised-button color="primary" (click)="saveTemplate()"
            [disabled]="selectedFormUUID == '' || loadFormQuestionsAndAnswers">Save Template</button>
        </div>
        <div class="col-6 survey-dp-container" *ngIf="loadedModel.action == 'create'">
          <mat-form-field class="mt-4 mb-4 survey-dp">
            <mat-label>Select Survey</mat-label>
            <mat-select [formControl]="formSurveyControl" name="formSurvey"
              (selectionChange)="updateSelectedForm($event?.value)">
              <mat-option *ngFor="let form of forms" [value]="form.uuid">
                {{form.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="selectedFormUUID != ''">
        <!-- COMPARE FIELDS AND FILTER-->
        <div class="row" *ngIf="showPanel">
          <div class="col-12">
          <form [formGroup]="comparedFormGroup">
            <div class="mb-2"><b>Compared to</b></div>
            <div formArrayName="comparedForms" class="compare-container">
              <div *ngFor="let form of comparedFormGroup.get('comparedForms')['controls']; let i = index" [formGroupName]="i"> 
                <div class="row">
                  <div class="col-5">
                    <mat-form-field  class="mb-2 survey-dp">
                      <mat-label>Select Survey</mat-label>
                      <mat-select [formControl]="form.get('uuid')" [disabled]="form.get('disable')?.value"  required>
                      <mat-option *ngFor="let rf of forms" [hidden]="checkToHide(rf.uuid)" [value]="rf.uuid" >
                        {{rf.name}}
                      </mat-option>
                      </mat-select>
                      <mat-hint *ngIf="form.get('uuid').status != 'VALID'">Please choose a survey to compare</mat-hint>
                      <mat-error *ngIf="form.get('uuid').hasError('required')">Please choose a survey to compare</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-5">
                    <mat-form-field class="col-12" appearance="fill">
                      <input type="text" matInput [formControl]="form.get('subName')" placeholder="Comparison Report Name">
                    </mat-form-field>
                  </div>
                  
                  <div class="col-2">
                    <div>
                      <button mat-mini-fab (click)="addToCompareForm(form, i)"  color="primary" ><mat-icon>add</mat-icon></button>
                      <button mat-mini-fab (click)="removeComparedForm(form, i)" *ngIf="i != 0"  color="warn"> <mat-icon>delete_outline</mat-icon></button>
                    </div>
                
                  </div>
                  
                </div>
              </div>
              
            </div>
          </form>
          </div>
          <div class="col-12">
            <mat-form-field class="mt-4 mb-4 survey-dp">
              <mat-label>Select Filter</mat-label>
              <mat-select [formControl]="formSurveyFilterControl" name="formSurveyFilter">
                <mat-option *ngFor="let report of reportConfigFilters" [value]="report.value">
                  {{report.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        
        </div>
        
      

        <!-- LOADING RENDING QUESTION -->
        <div class="row text-center mb-4" *ngIf="loadFormQuestionsAndAnswers">
          <div class="loading-container">
            <mat-spinner diameter="50" color="accent"> </mat-spinner>
          </div>
          <br>
          <mat-label>Rendering Questions...</mat-label>
        </div>

        <!-- PANEL FIELDS -->
        <div *ngIf=" !loadFormQuestionsAndAnswers">
          <div class="row" *ngIf="showPanel">
            <div class="col-6">
              <mat-form-field class="col-12" appearance="fill">
                <input type="text" matInput [formControl]="reportConfigName" placeholder="Configuration Name" required>
                <mat-error *ngIf="reportConfigName.hasError('required')">Please fill the config name field.</mat-error>
              </mat-form-field>
            </div>
            
            <div class="col-6">
              <mat-form-field class="col-12" appearance="fill">
                <input type="text" matInput [formControl]="reportConfigSlug" placeholder="Slug">
              </mat-form-field>
            </div>

            <div class="col-6">
              <mat-form-field class="col-12" appearance="fill">
                <input type="text" matInput [formControl]="reportName" placeholder="Report Name" required>
              </mat-form-field>
            </div>

            <div class="col-6">
              <mat-form-field class="col-12" appearance="fill">
                <input type="text" matInput [formControl]="compareName" placeholder="Compare Name" required>
              </mat-form-field>
            </div>

            
          </div>
          <form [formGroup]="generalInformationForm" *ngIf="showPanel">
            <div formArrayName="contents">
              <div cdkDropList (cdkDropListDropped)="drop($event)">
                <div *ngFor="let panel of generalInformationForm.get('contents')['controls']; let iPanel = index"
                  [formGroupName]="iPanel" cdkDrag>

                  <mat-accordion>
                    <mat-expansion-panel 
                    class="mb-2"
                    #panel 
                    [expanded]="panel.get('openedPanelSection').value" 
                    (opened)="openPanelSection(panel)"
                    (closed)="closePanelSection(panel)"
                    >
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                          <div class="row">
                            <div class="col-10">
                              <mat-form-field class="col-12" appearance="fill">
                                <input type="text" matInput 
                                [formControl]="panel.get('title')" 
                                placeholder="Title" 
                                (click)="clickTitle($event)"
                                (keydown)="onInputKeyDown($event)"
                                > 
                              </mat-form-field>
                            </div>
                            <div class="col-2">
                              <div class="button-row mt-10">
                                <button  class="mb-2" mat-mini-fab  color="primary"
                                  (click)="addExpansionPanel(panel.get('order'), $event)"><mat-icon>add</mat-icon></button>
                                <button  appearance="fill" mat-mini-fab  color="warn"
                                  [disabled]="generalInformationForm.get('contents')['controls'].length == 1"
                                  (click)="deleteExpansionPanel(iPanel, $event, panel)"> <mat-icon>delete_outline</mat-icon></button>
                              </div>
                            </div>
                          </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="row text-center mb-4" *ngIf="renderingQuestions">
                      <div class="loading-container">
                          <mat-spinner diameter="50" color="accent"> </mat-spinner>
                      </div>
                      <br>
                      <mat-label>Loading ...</mat-label>
                    </div>
                      
                      <ng-container *ngIf="!renderingQuestions">
                        <div class="mt-4">
                          <!-- TODO : Need to recheck the process on rendering the records when generating the values -->
                          <!-- <div class="row">
                            <div class="col-6">
                              <mat-form-field class="col-12" appearance="fill">
                                <mat-select [formControl]="panel.get('formPage')" aria-placeholder="Select form page">
                                  <mat-option *ngFor="let page of formPages" [value]="page.title"
                                    (click)="changeSelectedFormPage(page)">
                                    {{page.title}}
                                  </mat-option>
                                </mat-select>

                                

                              </mat-form-field>
                            </div>
                            <div class="col-4">
                              <div class="button-row">
                                <button mat-button class="full-width mt-2 generate-questions" mat-raised-button color="primary"
                                  (click)="generateFormPageQuestion(panel)">Generate questions and answers</button>
                              </div>
                            </div>
                          </div> -->
                          <div>
                            <app-reports-form-question 
                              [panelUUID]="panel.get('uuid').value"
                              [panelGraphs]="panel.get('graphs')"
                              [deletedGraphs]="panel.get('deletedGraphs')"
                              [panelIndex]="iPanel"
                              [filteredQuestions]="filteredQuestions"
                              [generalInformationForm]="generalInformationForm"
                              (graphQuestionsChange)="onGraphQuestionChange($event)"
                              >
                            </app-reports-form-question>
                          </div>
                        </div>

                      </ng-container>



                    </mat-expansion-panel>
                  </mat-accordion>
                 

                </div>
              </div>


            </div>
          </form>
        </div>
      </div>
    </div>
</ng-container>
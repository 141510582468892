<h1><b> Telegram - Followers </b></h1>
<div>
    <div class="chart" id="chart">
        <div class="row">
            <div class="col-5">
                <mat-form-field appearance="fill" class="period-field">
                    <mat-label>Period</mat-label>
                    <mat-date-range-input [formGroup]="range" [rangePicker]="pickerRange">
                        <input matStartDate formControlName="startRange" placeholder="Start date">
                        <input matEndDate formControlName="endRange" placeholder="End date" (dateChange)="onFetchData()">
                    </mat-date-range-input>
                    <mat-hint>DD MMM YYYY – DD MMM YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="pickerRange"></mat-datepicker-toggle>
                    <mat-date-range-picker #pickerRange></mat-date-range-picker>

                    <mat-error *ngIf="range.controls.startRange.hasError('matStartDateInvalid')">Invalid start
                        date</mat-error>
                    <mat-error *ngIf="range.controls.endRange.hasError('matEndDateInvalid')">Invalid end
                        date</mat-error>
                </mat-form-field>


            </div>
            <div class="col-5 text-left">

                <mat-button-toggle-group *ngIf="checkToDisplayRange()" [value]="selectedRange"
                    (change)="onChangeRange($event)" class="btn-toggle-byweek-bymonth" name="fontStyle"
                    aria-label="Font Style">
                    <mat-button-toggle [value]="toggleEnumRange.Daily">Daily</mat-button-toggle>
                    <mat-button-toggle [value]="toggleEnumRange.Weekly">Weekly</mat-button-toggle>
                    <mat-button-toggle [value]="toggleEnumRange.Monthly">Monthly</mat-button-toggle>
                </mat-button-toggle-group>
            </div>

        </div>
        <div class="row" style="display: block;" class="mb-3" *ngIf="!generatingChart">
            <canvas baseChart class="canvas" height="600" *ngIf="lineChartData.labels.length > 0" [type]="'line'" [data]="lineChartData"
                [options]="lineChartOptions" [legend]="lineChartLegend">
            </canvas>
        </div>

        <div class="row text-center mb-4" *ngIf="generatingChart">
            <div class="loading-container">
                <mat-spinner diameter="50" color="accent"> </mat-spinner>
            </div>
            <br>
            <mat-label>Generating Chart ...</mat-label>
        </div>
    </div>
    <br>
    <div class="row mb-3">
        <div class="col-4">
            <mat-card>
                <div style="text-align:center;">
                    <mat-card-header>
                        <mat-card-title>Joined</mat-card-title>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    
                    <mat-card-content>
                        <p class="count-number"><b>{{totalJoinedRange | number}}</b></p>
                    </mat-card-content>
                </div>

            </mat-card>
        </div>
        <div class="col-4">

            <mat-card>
                <div style="text-align:center;">
                    <mat-card-header>
                        <mat-card-title>Left</mat-card-title>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    
                    <mat-card-content>
                        <p class="count-number"><b>{{totalLeftRange | number}}</b></p>
                    </mat-card-content>
                </div>

            </mat-card>

        </div>
        <div class="col-4">

            <mat-card>
                <div style="text-align:center;">
                    <mat-card-header>
                        <mat-card-title>Net</mat-card-title>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    
                    <mat-card-content>
                        <p class="count-number"><b>{{totalJoinedRange - totalLeftRange | number}}</b></p>
                    </mat-card-content>
                </div>

            </mat-card>

        </div>
    </div>

    <div class="row mb-3">
        <div class="col-12">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Top 5 best "Joined" days</mat-card-title>
                </mat-card-header>
                <mat-divider></mat-divider>
                    
                <mat-card-content>
                    <mat-card-content>
                        <div class="row mt-4 mb-2">
                            <div class="col-2">
                                <span class="circle">1</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalJoinedDateRange[0])" >{{totalJoinedDateRange[0]}}</a></b></span>
                            </div>
                            <div class="col-2">
                                <span class="circle">2</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalJoinedDateRange[1])" >  {{totalJoinedDateRange[1]}} </a></b></span>
                            </div>
                            <div class="col-2">
                                <span class="circle">3</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalJoinedDateRange[2])" > {{totalJoinedDateRange[2]}} </a></b></span>
                            </div>
                            <div class="col-2">
                                <span class="circle">4</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalJoinedDateRange[3])" > {{totalJoinedDateRange[3]}} </a></b></span>
                            </div>
                            <div class="col-2">
                                <span class="circle">5</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalJoinedDateRange[4])" > {{totalJoinedDateRange[4]}} </a></b></span>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card-content>

            </mat-card>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-12">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Top 5 worst "Left" days</mat-card-title>
                </mat-card-header>
                <mat-divider></mat-divider>
                    
                <mat-card-content>
                    <div class="row mt-4 mb-2">
                        <div class="col-2">
                            <span class="circle">1</span>
                            <span class="dateRange"><b><a class="date-column" [routerLink]="" (click)="navigateByDate(totalLeftDateRange[0])" >  {{totalLeftDateRange[0]}} </a></b></span>

                        </div>
                        <div class="col-2">
                            <span class="circle">2</span>
                            <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalLeftDateRange[1])" >  {{totalLeftDateRange[1]}} </a></b></span>

                        </div>
                        <div class="col-2">
                            <span class="circle">3</span>
                            <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalLeftDateRange[2])" >  {{totalLeftDateRange[2]}} </a></b></span>

                        </div>
                        <div class="col-2">
                            <span class="circle">4</span>
                            <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalLeftDateRange[3])" >  {{totalLeftDateRange[3]}} </a></b></span>

                        </div>
                        <div class="col-2">
                            <span class="circle">5</span>
                            <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalLeftDateRange[4])" >  {{totalLeftDateRange[4]}} </a></b></span>

                        </div>
                    </div>
                </mat-card-content>

            </mat-card>
        </div>
    </div>

    <div class="row mb-3 mr-2 ml-2">
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">

            <!-- Position Column -->
            <ng-container matColumnDef="addedDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by date">
                    <b> Date </b>
                </th>
                <td mat-cell *matCellDef="let element"> <b> <a class="date-column" [routerLink]="" (click)="navigateByDate(element.addedDate)" >  {{element.addedDate | date:"dd MMM yyyy, E"}}  </a></b> </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="totalFollowers">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by totalFollowers">
                    <b> Total followers</b>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.totalFollowers | number}} </td>
            </ng-container>

            <!-- Weight Column -->
            <ng-container matColumnDef="joined">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by joined">
                    <b> Joined</b>
                </th>
                <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': highestJoined != 0 && highestJoined == element.joined  ? '#93C47D' : null }"> 
                    {{element.joined | number}}
                 </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="left">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by left">
                    <b> Left</b>
                </th>
                <td mat-cell *matCellDef="let element" [ngStyle]="{'background-color': highestLeft != 0 && highestLeft == element.left  ? '#FF0000' : null }"> 
                    {{element.left | number}}
                </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="net">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by net">
                    <b> Net gain </b>
                </th>
                <td mat-cell *matCellDef="let element"> {{element.net | number}} </td>
            </ng-container>



            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]" aria-label="Select page of users"></mat-paginator>
   
    </div>

</div>
<br>
<div class="comparison mt-4">
    <div class="row">
        <div class="col-5">
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Ranged From</mat-label>
                <mat-date-range-input [formGroup]="rangeFrom" [rangePicker]="pickerFrom">
                    <input matStartDate formControlName="startFrom" placeholder="Start date"
                        (dateChange)="onStartRangeFromChange($event)">
                    <input matEndDate formControlName="endFrom" placeholder="End date"
                        (dateChange)="onEndRangeFromChange($event)">
                </mat-date-range-input>
                <mat-hint>DD MMM YYYY – DD MMM YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                <mat-date-range-picker #pickerFrom></mat-date-range-picker>

                <mat-error *ngIf="rangeFrom.controls.startFrom.hasError('matStartDateInvalid')">Invalid start
                    date</mat-error>
                <mat-error *ngIf="rangeFrom.controls.endFrom.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>
        </div>
        <div class="col-2 text-center" style="margin-top: 35px;">
            <b>Compared To</b>
        </div>
        <div class="col-5">
            <mat-form-field appearance="fill" style="width: 100%;">
                <mat-label>Range To</mat-label>
                <mat-date-range-input [formGroup]="rangeTo" [rangePicker]="pickerTo">
                    <input matStartDate formControlName="startTo" placeholder="Start date"
                        (dateChange)="onStartRangeToChange($event)">
                    <input matEndDate formControlName="endTo" placeholder="End date"
                        (dateChange)="onEndRangeToChange($event)">
                </mat-date-range-input>
                <mat-hint>DD MMM YYYY – DD MMM YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="pickerTo"></mat-datepicker-toggle>
                <mat-date-range-picker #pickerTo></mat-date-range-picker>

                <mat-error *ngIf="rangeTo.controls.startTo.hasError('matStartDateInvalid')">Invalid start
                    date</mat-error>
                <mat-error *ngIf="rangeTo.controls.endTo.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>

        </div>
    </div>
    <br>
    <div class="row mb-3">
        <div class="col-4">
            <mat-card>
                <div style="text-align:center;">
                    <mat-card-header>
                        <mat-card-title>Joined</mat-card-title>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    <mat-card-content>
                        <p class="count-number"><b>{{totalJoinedFromRange | number}} vs {{totalJoinedToRange | number}}</b></p>
                    
                        <div class="percentage"> 
                            <span class="percentage-positive" *ngIf="joinedComparisonPercentage > 0"> {{joinedComparisonPercentage | number}} % </span>
                            <span class="percentage-negative" *ngIf="joinedComparisonPercentage < 0" >{{joinedComparisonPercentage | number}} % </span>
                        
                        </div>
                    </mat-card-content>
                
                </div>

            </mat-card>
        </div>
        <div class="col-4">

            <mat-card>
                <div style="text-align:center;">
                    <mat-card-header>
                        <mat-card-title>Left</mat-card-title>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    <mat-card-content>
                        <p class="count-number"><b>{{totalLeftFromRange | number}} vs {{totalLeftToRange | number}}</b></p>
                        <div class="percentage">
                            <span class="percentage-positive" *ngIf="leftComparisonPercentage > 0">{{leftComparisonPercentage | number}} % </span>
                            <span class="percentage-negative" *ngIf="leftComparisonPercentage < 0" >{{leftComparisonPercentage | number}} % </span>
                
                        </div>
                    </mat-card-content>
                </div>

            </mat-card>
 
        </div>
        <div class="col-4">

            <mat-card>
                <div style="text-align:center;">
                    <mat-card-header>
                        <mat-card-title>Net</mat-card-title>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    <mat-card-content>
                        <p class="count-number">
                            <b>{{totalJoinedFromRange - totalLeftFromRange | number}} vs {{totalJoinedToRange - totalLeftToRange | number}}</b>
                        </p>
                        <div class="percentage">
                            <span class="percentage-positive" *ngIf="netComparisonPercentage > 0" >{{netComparisonPercentage | number}} % </span>
                            <span class="percentage-negative" *ngIf="netComparisonPercentage < 0" >{{netComparisonPercentage | number}} % </span>
                        
                        </div>
                        
                    </mat-card-content>
                </div>

            </mat-card>

        </div>
    </div>

    <div class="row mb-3">
        <div class="col-12">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Top 5 best "Joined" days</mat-card-title>
                </mat-card-header>
                <mat-divider></mat-divider>
                    
                <br>
                <h3>Period {{comparisonFrom?.start}} to {{comparisonFrom?.end}}</h3>

                <mat-card-content>
                    <mat-card-content>
                        <div class="row mt-4 mb-2">
                            <div class="col-2">
                                <span class="circle">1</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalJoinedFromDateRange[0])" >{{totalJoinedFromDateRange[0]}} </a></b></span>

                            </div>
                            <div class="col-2">
                                <span class="circle">2</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalJoinedFromDateRange[1])" > {{totalJoinedFromDateRange[1]}} </a></b></span>

                            </div>
                            <div class="col-2">
                                <span class="circle">3</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalJoinedFromDateRange[2])" > {{totalJoinedFromDateRange[2]}} </a></b></span>


                            </div>
                            <div class="col-2">
                                <span class="circle">4</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalJoinedFromDateRange[3])" > {{totalJoinedFromDateRange[3]}} </a></b></span>


                            </div>
                            <div class="col-2">
                                <span class="circle">5</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalJoinedFromDateRange[4])" > {{totalJoinedFromDateRange[4]}} </a></b></span>

                            </div>
                        </div>
                    </mat-card-content>
                </mat-card-content>

                <br>
                <h3>Period {{comparisonTo?.start}} to {{comparisonTo?.end}}</h3>

                <mat-card-content>
                    <mat-card-content>
                        <div class="row mt-4 mb-2">
                            <div class="col-2">
                                <span class="circle">1</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalJoinedToDateRange[0])"> {{totalJoinedToDateRange[0]}} </a></b></span>
                            </div>
                            <div class="col-2">
                                <span class="circle">2</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalJoinedToDateRange[1])"> {{totalJoinedToDateRange[1]}} </a></b></span>
                            </div>
                            <div class="col-2">
                                <span class="circle">3</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalJoinedToDateRange[2])"> {{totalJoinedToDateRange[2]}} </a></b></span>

                            </div>
                            <div class="col-2">
                                <span class="circle">4</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalJoinedToDateRange[3])"> {{totalJoinedToDateRange[3]}} </a></b></span>

                            </div>
                            <div class="col-2">
                                <span class="circle">5</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalJoinedToDateRange[4])"> {{totalJoinedToDateRange[4]}} </a></b></span>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card-content>

            </mat-card>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-12">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>Top 5 worst "Left" days</mat-card-title>
                </mat-card-header>
                <mat-divider></mat-divider>
                    
                <br>
                <h3>Period {{comparisonFrom?.start}} to {{comparisonFrom?.end}}</h3>
                <mat-card-content>
                    <div class="row mt-4 mb-2">
                        <div class="col-2">
                            <span class="circle">1</span> <span class="dateRange"><b>
                                <a class="date-column" [routerLink]="" (click)="navigateByDate(totalLeftFromDateRange[0])"> {{totalLeftFromDateRange[0]}} </a></b></span>

                        </div>
                        <div class="col-2">
                            <span class="circle">2</span> <span class="dateRange"><b>
                                <a class="date-column" [routerLink]="" (click)="navigateByDate(totalLeftFromDateRange[1])"> 
                                    {{totalLeftFromDateRange[1]}} </a></b></span>

                        </div>
                        <div class="col-2">
                            <span class="circle">3</span> <span class="dateRange"><b>
                                <a class="date-column" [routerLink]="" (click)="navigateByDate(totalLeftFromDateRange[2])"> 
                                    {{totalLeftFromDateRange[2]}} </a></b></span>


                        </div>
                        <div class="col-2">
                            <span class="circle">4</span> <span class="dateRange"><b>
                                <a class="date-column" [routerLink]="" (click)="navigateByDate(totalLeftFromDateRange[3])"> 
                                    {{totalLeftFromDateRange[3]}} </a></b></span>


                        </div>
                        <div class="col-2">
                            <span class="circle">5</span>
                            <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalLeftFromDateRange[4])">  {{totalLeftFromDateRange[4]}} </a>    </b></span>
                        </div>
                    </div>
                </mat-card-content>

                <br>
                <h3>Period {{comparisonTo?.start}} to {{comparisonTo?.end}}</h3>

                <mat-card-content>
                    <mat-card-content>
                        <div class="row mt-4 mb-2">
                            <div class="col-2">
                                <span class="circle">1</span>
                                <span class="dateRange"><b> <a class="date-column" [routerLink]="" (click)="navigateByDate(totalLeftToDateRange[0])">{{totalLeftToDateRange[0]}} </a></b></span>
                            </div>
                            <div class="col-2">
                                <span class="circle">2</span>
                                <span class="dateRange"><b>  <a class="date-column" [routerLink]="" (click)="navigateByDate(totalLeftToDateRange[1])"> {{totalLeftToDateRange[1]}} </a></b></span>
                            </div>
                            <div class="col-2">
                                <span class="circle">3</span>
                                <span class="dateRange"><b>  <a class="date-column" [routerLink]="" (click)="navigateByDate(totalLeftToDateRange[2])"> {{totalLeftToDateRange[2]}} </a></b></span>

                            </div>
                            <div class="col-2">
                                <span class="circle">4</span>
                                <span class="dateRange"><b>  <a class="date-column" [routerLink]="" (click)="navigateByDate(totalLeftToDateRange[3])"> {{totalLeftToDateRange[3]}} </a></b></span>

                            </div>
                            <div class="col-2">
                                <span class="circle">5</span>
                                <span class="dateRange"><b>  <a class="date-column" [routerLink]="" (click)="navigateByDate(totalLeftToDateRange[4])"> {{totalLeftToDateRange[4]}} </a></b></span>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card-content>


            </mat-card>
        </div>
    </div>

</div>
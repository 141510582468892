import { CdkDragDrop, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnChanges, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators';
import { from, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, find, map, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { IControlType } from '../../interfaces/icontrolType';
import { IOption } from '../../interfaces/ioption';
import { IQuestion } from '../../interfaces/question';
import { IQuestionAnswer } from '../../interfaces/question-answer';
import { IQuestionMatric } from '../../interfaces/question-matric';
import { ConsumerReportBrandsService } from '../../services/consumer-report-brands.service'
import { ConsumerReportInfluencerService } from '../../services/consumer-report-influencer.service'
import { ConsumerReportOptionService } from '../../services/consumer-report-option.service';
import { ConsumerReportQuestionService } from '../../services/consumer-report-question.service';
import { ConsumerReportStoreService } from '../../services/consumer-report-store.service'
import { QuestionAnswersDialogComponent } from '../question-answers-dialog/question-answers-dialog.component';
import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MakeSearchParams } from 'src/app/shared/helpers/MakeSearchParams';

interface UploadResult {
  isImg: boolean
  name: string
  url: string
}

@Component({
  selector: 'consumer-report-question-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.scss']
})
export class QuestionFormComponent implements OnInit, OnChanges {

  selectedPreload: string

  pointsModifierHintText = environment.consumerReport.pointModifierHintText

  oldControlType: string = "";

  constructor(
    private readonly fb: FormBuilder,
    private readonly consumerReportQuestionService: ConsumerReportQuestionService,
    private readonly consumerReportBrandsService: ConsumerReportBrandsService,
    private readonly consumerReportStoreService: ConsumerReportStoreService,
    private readonly consumerReportInfluencerService: ConsumerReportInfluencerService,
    private readonly consumerReportOptionService: ConsumerReportOptionService,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.instructionUpload = this.instructionUpload.bind(this);
    this.doUpload = this.doUpload.bind(this);
  }

  doUpload(files: Array<File>): Promise<Array<UploadResult>> {
    // do upload file by yourself
    return Promise.resolve([{ name: 'xxx', url: 'xxx.png', isImg: true }]);
  }

  @Input('model') loadedModel: IQuestion;

  options = {
    showPreviewPanel: false,
    hideIcons: [
      'FullScreen',
      'TogglePreview',
      'Code'
    ],
    resizable: false,
    enablePreviewContentClick: true
  }

  typeOptions = [
    {
      "value":"text",
      "label": "HTML Field"
    },
    {
      "value":"profile",
      "label": "User Profile"
    },
		{
      "value":"profile-filter",
      "label": "Profile Filter"
    },
		{
      "value":"skincare-filter",
      "label": "SkinCare Filter"
    },
		{
      "value":"makeup-filter",
      "label": "MakeUp Filter"
    },
		{
      "value":"haircare-filter",
      "label": "HairCare filter"
    },
		{
      "value":"beauty-concern-filter",
      "label": "Beauty Concern"
    },
    {
      "value":"starsign",
      "label": "Computer Generated - StarSign"
    },
    {
      "value":"zodiac",
      "label": "Computer Generated - Zodiac"
    },
		{
      "value":"age-period",
      "label": "Computer Generated - Age-Period"
    },
    {
      "value":"age",
      "label": "Computer Generated - Age"
    }
  ]



  get answers(): FormArray {
    return this.formData.get('answers') as FormArray;
  }

  get matrices(): FormArray {
    return this.formData.get('matrices') as FormArray;
  }

  get instructionData(): string {
    return this.formData.get('instruction').value as string;
  }

  get questionType(): string {
    return this.formData.get('controlType').value as string;
  }

  needMatricOptions = false
  needAnswerOptions = false

  // get needMatricOptions(): Observable<boolean | unknown> {
  //   if (!this.questionType) {
  //     return of(false);
  //   }
  //   return this.controlType.pipe(
  //     map(v => v.find(vv=>vv.name === this.questionType)?.matric || false)
  //   )
  // }

  // get needAnswerOptions(): Observable<boolean | unknown> {
  //   if (!this.questionType) {
  //     return of(false);
  //   }
  //   return this.controlType.pipe(
  //     map(v => v.find(vv=>vv.name === this.questionType)?.answer || false)
  //   )
  // }

  @ViewChildren(CdkDropList)
  set cdkDropLists(value: QueryList<CdkDropList>) {
    this.answerTrackList = value.first;
  }

  formData: FormGroup = undefined;
  savingForm = false;
  showAns = false;
  showMatrix = false;
  answerList = [];

  originatedCountry = [
    {
      name: 'SG',
      value: 'Singapore',
      default: true
    },
    {
      name: 'MY',
      value: 'Malaysia',
      default: false
    }
  ]

  preloadData = [
    {
      name: 'brands-skincare',
      value: 'SkinCare Brands',
      option: true
    },
    {
      name: 'brands-makeup',
      value: 'MakeUp Brands',
      option: true
    },
    {
      name: 'brands-haircare',
      value: 'HairCare Brands',
      option: true
    },
    {
      name: 'influencer',
      value: 'Influencer',
      option: true
    },
    {
      name: 'store',
      value: 'Store',
      option: true
    }
  ]

  controlType$: Observable<IControlType[]>
  answerTrackList: CdkDropList;

  controlTypeCache: IControlType[] = []

  ngOnInit(): void {
    this.controlType$ = this.consumerReportOptionService.findByType<IControlType[]>('questionControlType')
      .pipe(tap(d=> {this.controlTypeCache = d; this.initForm();}))

  }

  newAnswer(loadedModel?: IQuestionAnswer): FormGroup {
    return this.fb.group({
      label: [loadedModel ? loadedModel.label : undefined, [
        Validators.maxLength(256)
      ]],
      group: [loadedModel ? loadedModel.group : undefined, [
        Validators.maxLength(125)
      ]],
      order: [loadedModel ? loadedModel.order : undefined, [
        RxwebValidators.numeric({acceptValue: NumericValueType.PositiveNumber, allowDecimal: false})
      ]],
      point: [loadedModel ? loadedModel.point : 0, [
        RxwebValidators.numeric({acceptValue: NumericValueType.PositiveNumber, allowDecimal: false})
      ]],
      id: [loadedModel ? loadedModel.id : undefined, [
        RxwebValidators.numeric({acceptValue: NumericValueType.PositiveNumber, allowDecimal: false})
      ]],
      tooltip: [loadedModel ? loadedModel.tooltip : undefined, [
        Validators.maxLength(256)
      ]],
      specialControl: [loadedModel ? loadedModel.specialControl : undefined, [
        Validators.maxLength(256)
      ]]
    });
  }

  newMatrices(loadedModel?: IQuestionMatric): FormGroup {
    return this.fb.group({
      name: [loadedModel ? loadedModel.name : undefined, [
        Validators.maxLength(256)
      ]],
      id: [loadedModel ? loadedModel.id : undefined, [
        RxwebValidators.numeric({acceptValue: NumericValueType.PositiveNumber, allowDecimal: false})
      ]],
      pointModifier: [loadedModel ? loadedModel.pointModifier : undefined, [
        RxwebValidators.numeric({acceptValue: NumericValueType.PositiveNumber, allowDecimal: true})
      ]]
    });
  }

  instructionUpload(files: Array<File>): Promise<Array<UploadResult>> {
    return Promise.resolve([{ name: 'xxx', url: 'xxx.png', isImg: true }]);

  }

  initForm(): void {
    this.formData = this.fb.group({
      controlType: [this.loadedModel?.controlType || "", [
        RxwebValidators.required(),
        Validators.nullValidator
      ]],
      type: [this.loadedModel?.type || 'text', [
        Validators.nullValidator
      ]],
      label: [this.loadedModel.label, [
        RxwebValidators.required(),
        Validators.maxLength(1024),
        Validators.nullValidator
      ]],

      allowDeselectLabel: [this.loadedModel.allowDeselectLabel, [
        Validators.maxLength(50)
      ]],

      instruction: [this.loadedModel.instruction, [
        Validators.maxLength(1024)
      ]],
      order: [this.loadedModel.order, [
        RxwebValidators.numeric({acceptValue: NumericValueType.PositiveNumber, allowDecimal: false})
      ]],
      maxPoint: [this.loadedModel.maxPoint, [
        RxwebValidators.numeric({acceptValue: NumericValueType.PositiveNumber, allowDecimal: false}),
        RxwebValidators.range({maximumNumber: 10000, minimumNumber: 0})
      ]],
      originatedISOCountryCode: [this.loadedModel.originatedISOCountryCode?this.loadedModel.originatedISOCountryCode:"SG", [
        Validators.maxLength(255)
      ]],
      answers: this.fb.array(this.loadedModel.answers.length <= 0 ? [this.newAnswer()] : this.loadedModel.answers.map(f => this.newAnswer(f))),
      matrices: this.fb.array(this.loadedModel.matrices.length <= 0 ? [this.newMatrices()] : this.loadedModel.matrices.map(f => this.newMatrices(f))),
      id: [this.loadedModel.id, [
        RxwebValidators.numeric({acceptValue: NumericValueType.PositiveNumber, allowDecimal: false})
      ]]
    });
    if(this.loadedModel) {
      this.oldControlType = this.loadedModel.controlType
    }

    this.formData.valueChanges.subscribe(v=> {
      if(v.controlType && v.controlType !== this.oldControlType) {
        const matched = this.controlTypeCache?.find(cc=>cc.name === v.controlType)
        if(matched) {
          this.needMatricOptions = matched.matric
          this.needAnswerOptions = matched.answer
          this.oldControlType = matched.name
        }
      }
    })
    if(this.loadedModel.id == undefined){
      this.needAnswerOptions = true;
      this.needMatricOptions = true;
    }else{
      this.loadedModel.answers.length > 0 ? this.needAnswerOptions = true : this.needAnswerOptions = false;
      this.loadedModel.matrices.length > 0 ? this.needMatricOptions = true : this.needMatricOptions = false;
    }
  }

  loadPreloaded(): void {
    this.clearAllOptions();
    const addAnswerFns = (f) => {
      for (const linked of f.data) {
        this.addAnswer({
          label: linked.name,
          linkedWith: this.selectedPreload,
          linkedWithKey: linked.uuid,
          point: 1
        });
      }
    };

    switch(this.selectedPreload) {
      case 'brands-skincare':
        this.consumerReportBrandsService.list(MakeSearchParams({servingCountry: ['SG'], brandCategoryID: ['1'], limit: '-1', 'orderBy[brand.name]': 'ASC'}))
            .subscribe(addAnswerFns);
        break;
      case 'brands-makeup':
        this.consumerReportBrandsService.list(MakeSearchParams({servingCountry: ['SG'], brandCategoryID: ['3'], limit: '-1', 'orderBy[brand.name]': 'ASC'}))
          .subscribe(addAnswerFns);
        break;
      case 'brands-haircare':
        this.consumerReportBrandsService.list(MakeSearchParams({servingCountry: ['SG'], brandCategoryID: ['2'], limit: '-1', 'orderBy[brand.name]': 'ASC'}))
          .subscribe(addAnswerFns);
        break;
      case 'influencer':
        this.consumerReportInfluencerService.list(MakeSearchParams({servingCountry: ['SG'], limit: '-1'}))
          .subscribe(addAnswerFns);
        break;
      case 'store':
        this.consumerReportStoreService.list(MakeSearchParams({servingCountry: ['SG'], limit: '-1'}))
          .subscribe(addAnswerFns);
        break;
      default:
        break;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loadedModel) {
      this.initForm();
    }
  }

  clearAllOptions(): void {
    this.answers.clear();
  }

  addAnswer(answer?: IQuestionAnswer): void {
    this.answers.push(this.newAnswer(answer));
  }

  delAnswer(i: number): void {
    this.answers.removeAt(i);
  }

  addMatric(): void {
    this.matrices.push(this.newMatrices());
  }

  delMatric(i: number): void {
    this.matrices.removeAt(i);
  }

  dropMatric(event: CdkDragDrop<AbstractControl[]>): void {
    moveItemInArray(this.matrices.controls, event.previousIndex, event.currentIndex);
  }

  drop(event: CdkDragDrop<AbstractControl[]>): void {
    moveItemInArray(this.answers.controls, event.previousIndex, event.currentIndex);
  }

  multiAnsDialog(): void {
    const dependencyDialog = this.dialog.open(QuestionAnswersDialogComponent);
    dependencyDialog
      .afterClosed()
      .subscribe(questionAnswers => {
        const answers = (this.formData.get('answers') as FormArray);
        answers.controls.forEach((v, i) => {
          if (v.value.label === '') {
            answers.removeAt(i);
          }
        });
        for (const questionAnswer of questionAnswers) {
          this.addAnswer(questionAnswer);
        }
        this.addAnswer();
      });
  }

  saveForm(): void {
    (this.formData.get('answers') as FormArray).updateValueAndValidity();
    const savingValues = this.formData.value as IQuestion;
    let order = 0;
    this.savingForm = true;
    savingValues.answers = savingValues.answers
      .filter(f => {
        return (f.label && f.label.trim() !== '' && f.label !== undefined && f.label !== undefined);
      })
      .map(f => {
        order += 1;

        return {
          ...f,
          label: f.label.trim(),
          order
        };
      });
    savingValues.matrices = savingValues.matrices
      .filter(f => {
        return (f.name && f.name.trim() !== '' && f.name !== undefined && f.name !== undefined);
      });
    this.consumerReportQuestionService
      .save(savingValues)
      .subscribe(
        d => {
          this.savingForm = false;
          this.loadedModel = d;
          this.snackBar.open('Question has been saved SUCCESSFULLY!', 'Close', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
          if(savingValues.id)
            this.initForm();
          else {
            this.router.navigate([`/consumer-report/question/update`, d.id])
          }
        },
        err => {
          this.savingForm = false;
          this.snackBar.open('Question has been saved FAILED!', 'Close', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
          this.initForm();
        }
      );
  }
}

<div class="row mb-3">
  <!-- <div class="col-12 text-right">
    <button mat-raised-button color="primary" [routerLink]="['/consumer-report/account-manager/create']">Add New Account
      Manager</button>
  </div> -->
</div>
<!-- <div class="row mb-3">
  <div class="col-12">
    <mat-card>
      <mat-card-content>
        <div class="row">
          <div class="col-12">
            <mat-form-field class="fullWidth" appearance="fill">
              <mat-label>Select Account Manager</mat-label>
              <mat-select formControlName="accountManagerId" (selectionChange)="checkSubUser($event.value)"
                (disabled)="accountManagers.length > 0">
                <mat-option *ngFor="let account of accountManagers" [value]="account.id">
                  {{account.firstName}} {{account.lastName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div [hidden]="!selectedAccountManager">
  -->

<div class="row">
  <div class="col-12">
    <table mat-table [dataSource]="accountManagers" class="mat-elevation-z8" style="width: 100%">
      <ng-container matColumnDef="account">
        <th mat-header-cell *matHeaderCellDef> Account </th>
        <td mat-cell *matCellDef="let element">
          <span>DailyVanity</span><br />
          <span>{{element.displayName}}</span><br />
          <span>{{element.email}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="totalSubAccounts">
        <th mat-header-cell *matHeaderCellDef> Accounts </th>
        <td mat-cell *matCellDef="let element;">
          {{element.totalSubAccounts}}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element;"> {{element.status}} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element;">
          <a mat-icon-button color="primary" routerLink="/consumer-report/account-manager/update/{{element.id}}">
            <mat-icon>edit</mat-icon>
          </a>
          <ng-template [ngIf]="element.active==1">
            <a mat-icon-button color="warn" (click)="disableUser(element.id)">
              <mat-icon>block</mat-icon>
            </a>
          </ng-template>
          <ng-template [ngIf]="element.active!=1">
            <a mat-icon-button color="default" (click)="enableUser(element.id)">
              <mat-icon>power_settings_new</mat-icon>
            </a>
          </ng-template>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="p-2"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="p-2"></tr>
    </table>
  </div>
</div>
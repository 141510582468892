import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, forkJoin, of } from 'rxjs';
import { concatMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { MakeSearchParams } from '../../helpers/MakeSearchParams';
import { IFormQuestionAnswer } from '../../interfaces/form-question-answer';
import { IParticipantDefinedValue } from '../../interfaces/iparticipant-defined-value';
import { IQuestionAnswer } from '../../interfaces/question-answer';
import { ConsumerReportAnswerService } from '../../services/consumer-report-answer.service';
import { ConsumerReportFormQuestionAnswerService } from '../../services/consumer-report-form-question-answer.service';
import { ConsumerReportFormQuestionService } from '../../services/consumer-report-form-question.service';
import { ConsumerReportFormService } from '../../services/consumer-report-form.service';
import { ConsumerReportParticipantUserDefinedValueService } from '../../services/consumer-report-participant-user-defined-value.service';

@Component({
  selector: 'app-form-userdefined-create',
  templateUrl: './form-userdefined-create.component.html',
  styleUrls: ['./form-userdefined-create.component.scss']
})
export class FormUserdefinedCreateComponent implements OnInit {

  questionAnswerControl = new FormControl('');
  questionAnswerList: IQuestionAnswer[] = [];
  userDefinedList: IParticipantDefinedValue[];
  formQuestionAnswerList: IFormQuestionAnswer[]
  loadedQuestionID: number
  loadedFormQuestionID: number
  loadedFormID: number
  savingText: string = ''
  userDefinedCol = [
    'displayName',
    'email',
    'submissionDate',
    'value'
  ];

  buttonText: string = "Add new"
  savingForm: boolean = false

  loadedQuestionAnswerModel: FormGroup;

  constructor(
    private readonly participantDefinedValueService: ConsumerReportParticipantUserDefinedValueService,
    private readonly questionAnswerService: ConsumerReportAnswerService,
    private readonly formQuestionAnswerService: ConsumerReportFormQuestionAnswerService,    
    private readonly formQuestionService: ConsumerReportFormQuestionService,
    private readonly route: ActivatedRoute,
    private readonly fb: FormBuilder,
    private router: Router,
    private readonly snackBar: MatSnackBar
  ) {
    combineLatest([this.route.params, this.route.queryParams])
      .subscribe(([, queryParams]) => {

        forkJoin({
          userDefinedList: this.participantDefinedValueService.list(MakeSearchParams({
            page: -1,
            limit: -1,
            IDs: queryParams.userDefinedIDs
          })),
          questionAnswerList: this.questionAnswerService.listAnswer({
            questionID: [queryParams.questionID],
            limit: -1,
            page: -1
          }),
          formQuestionAnswerList: this.formQuestionAnswerService.list(MakeSearchParams({
            page: -1,
            limit: -1,
            formID: queryParams.formID,
            questionID: queryParams.questionID
          }))
        })
        .subscribe(d => {
          this.userDefinedList = d.userDefinedList.data;
          this.questionAnswerList = d.questionAnswerList.data;
          this.formQuestionAnswerList = d.formQuestionAnswerList.data
          this.loadedFormQuestionID = this.formQuestionAnswerList[0].formQuestionID
          this.loadedQuestionID = +queryParams.questionID
          this.loadedFormID = queryParams.formID
          this.loadedQuestionAnswerModel = this.questionAnswerService.makeFormGroup(undefined);
        });
    });
  }

  createNew(): void {
    this.loadedQuestionAnswerModel = this.questionAnswerService.makeFormGroup(undefined);
    this.questionAnswerControl.setValue('')
    this.buttonText = "Add new"
  }

  combineAnswer(): void {

  }

  backToIndex(loadingFormID: number): void {
    this.savingText = ''
    this.savingForm = false
    this.snackBar.open("Successfully added/merged data.", "Close", {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    })
    this.router.navigate([`/consumer-report/form/user-defined`, loadingFormID])
  }

  failedMergeSave() {
    this.savingText = ''
    this.savingForm = false
    this.snackBar.open("FAILED! Unable to add/merge data. Please try again or take a screenshot and email to support@dailyvanity.freshdesk.com", "Close", {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top'
    })
  }

  save() {
    
    if(this.loadedQuestionAnswerModel.valid) {
      this.savingForm = true
      const value = this.loadedQuestionAnswerModel.value as IQuestionAnswer
      let selectedAnswer: IFormQuestionAnswer
      if(value.id) {
        selectedAnswer = this.formQuestionAnswerList.find(qa=>qa.questionAnswerID === value.id)
        this.participantDefinedValueService.approvedDefinedValue(this.userDefinedList.map(d=>d.id), selectedAnswer.id)        
          .subscribe(d=> {
            this.backToIndex(this.loadedFormID)
          }, e=> {
            this.failedMergeSave()
          })
      } else {
        this.savingText = "Creating new Answer"
        this.questionAnswerService.save({
          ...value,
          questionID: this.loadedQuestionID,
          reorder: true
        })
          .pipe(
            tap(c=> {
              this.savingText = "Syncing Question to survey #"+this.loadedFormID
              
            }),
            switchMap((c)=>{
              return forkJoin({
                questionAnswer: of(c),
                formQuestion: this.formQuestionService.resync(this.loadedFormQuestionID.toString())
              })
            }),
            tap(c=> {
              this.savingText = "Approving user defined value"
            }),
            switchMap(c=> {
              const selectedAnswer = c.formQuestion.answers.find(qa=>qa.questionAnswerID === c.questionAnswer.id)
              return this.participantDefinedValueService.approvedDefinedValue(this.userDefinedList.map(d=>d.id), selectedAnswer.id)
            }),
            tap(c=> {
              this.savingText = "Refreshing answer list"
              this.questionAnswerService.listAnswer({questionID: [this.loadedQuestionID.toString()], limit: -1, page: -1})
            })
          ).subscribe(c=> {
            this.questionAnswerService.makeFormGroup(undefined);
            this.backToIndex(this.loadedFormID)
          }, e=> {
            this.failedMergeSave()
          })
      }

    }
  }

  changeForm(event) {
    this.loadedQuestionAnswerModel = this.questionAnswerService.makeFormGroup(event.value);
    this.buttonText = "Merge"
  }

  ngOnInit(): void {
    this.loadedQuestionAnswerModel = this.questionAnswerService.makeFormGroup(undefined);
  }

}

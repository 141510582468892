<div class="row text-center" *ngIf="savingForm">
	<div class="loading-container">
		<mat-spinner diameter="50" color="accent"> </mat-spinner>
	</div>
	<br>
	<mat-label>Saving ...</mat-label>
</div>

<form [formGroup]="formData">
	<div class="container" *ngIf="!savingForm && !isSave">
		<mat-card>
			<mat-card-header>
				<mat-card-title class="pt-3">{{formTypeTitle}}</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<div class="row">

					<div class="col-6">
						<mat-form-field appearance="fill" class="fullWidth">
							<mat-label>Client Name</mat-label>
							<input type="text" placeholder="Client Name" aria-label="Client Name" matInput
								[matAutocomplete]="autoClient" [formControl]="_client" />
							<mat-icon matSuffix>arrow_drop_down</mat-icon>

							<mat-error>
								<span *ngIf="_client.hasError('required')">Client Name is <strong>required</strong></span>
							</mat-error>
						</mat-form-field>

						<mat-autocomplete #autoClient="matAutocomplete" [displayWith]="displayTagFn" (optionSelected)="selectClient($event)">
							<mat-option *ngFor="let client of filteredClients | async" [value]="client">
								{{ client.value }}
							</mat-option>
						</mat-autocomplete>

					</div>
					<div class="col-6">
						<mat-form-field appearance="fill" class="fullWidth">
							<mat-label>Campaign Name</mat-label>

							<input type="text" placeholder="Campaign Name" aria-label="Campaign Name" matInput
								[matAutocomplete]="autoCampaign" [formControl]="_campaign" />
								<mat-icon matSuffix>arrow_drop_down</mat-icon>
							<mat-error>
								<span *ngIf="_campaign.hasError('required')">Campaign Name is <strong>required</strong></span>
							</mat-error>

						</mat-form-field>
						<mat-autocomplete #autoCampaign="matAutocomplete"  [displayWith]="displayTagFn" (optionSelected)="selectCampaign($event)">
							<mat-option *ngFor="let campaign of filteredCampaigns | async" [value]="campaign">
								{{ campaign.value }}
							</mat-option>
						</mat-autocomplete>

					</div>
					<div class="col-6">
						<mat-form-field appearance="fill" class="fullWidth">
							<mat-label>Description (Optional)</mat-label>
							<input matInput maxlength="120" formControlName="description" aria-label="Description" />
						</mat-form-field>
					</div>
					<div class="col-6">
						<mat-form-field appearance="fill" class="fullWidth">
							<mat-label>Original long URL here</mat-label>
							<input matInput formControlName="redirectURL" required aria-label="Original long URL here" [readonly]="generatedURL" />
							<mat-error>
								<span *ngIf="formData.get('redirectURL').hasError('required')">Original URL is <strong>required</strong></span>
							</mat-error>
							<mat-error>
								<span *ngIf="formData.get('redirectURL').hasError('pattern')"><strong>Invalid Url Pattern: </strong>
									Please input full URL that includes http:// or https:// or tel: or mailto: 
								</span>
							</mat-error>
						</mat-form-field>
						<div class="row mt-10 mb-2" [ngClass]="{'mt-50': formData.get('redirectURL').hasError('pattern')}" >
							<div class="col-4">
								<button mat-raised-button (click)="generateURL('whatsApp')" color="primary">WhatsApp URL</button> 
							</div>
							<div class="col-4">
								<button mat-raised-button (click)="generateURL('tel')" color="primary"> Telephone call URL </button> 
							</div>
							<div class="col-4">
								<button mat-raised-button (click)="clearGenerateURL()" color="primary"> Clear </button> 
							</div>
						</div>
						
						
					</div>
					<div class="col-12 mt-50">
						<mat-label><b>Important Note: </b>
							Your short URL should be <b>short and simple</b> so readers who sees it on social media platforms or
							printouts can easily remember and enter it into the browser. If it is just a web link in article
							that link won't be "seen" by the readers, the URL can be gibberish since it is just used for tracking
							purposes and not for vanity nor requiring readers to remember.
						</mat-label>
					</div>

					<div class="col-6">
						<mat-form-field appearance="fill" class="fullWidth">
							<mat-label>Short URL</mat-label>
							<mat-select formControlName="domainID">
								<mat-option *ngFor="let domain of domains" [value]="domain.id">
									{{domain.domain}}
								</mat-option>
							</mat-select>
							<mat-error>
								<span *ngIf="formData.get('domainID').hasError('required')">Short Url is <strong>required</strong></span>
							</mat-error>
						</mat-form-field>
					</div>
					<div class="col-6">
						<mat-form-field appearance="fill" class="fullWidth">
							<mat-icon matPrefix class="success" *ngIf="!formData.get('hash').errors">check</mat-icon>
							<mat-icon matPrefix class="danger" *ngIf="formData.get('hash').errors">close</mat-icon>
							<mat-label>Hash</mat-label>
							<input aria-label="Hash" matInput maxlength="120" formControlName="hash" name="hash" />
							<button type="button" matSuffix mat-icon-button *ngIf="formData.get('hash').hasError('hashFail')" (click)="baseService.generateNewHash(5)">
								<mat-icon>refresh</mat-icon>
							  </button>
							<mat-error>
								<span *ngIf="formData.get('hash').hasError('required')">Hash is <strong>required</strong></span>
								<span *ngIf="formData.get('hash').hasError('hashFail')">Hash unavailable to use</span>
								<span *ngIf="formData.get('hash').hasError('minlength')">Hash must have more than 5 letters</span>
							</mat-error>
						</mat-form-field>
						
					</div>
				</div>
				<div class="col-6">
					<mat-form-field appearance="fill" class="fullWidth" [hidden]="true">
						<mat-label>Owner Name</mat-label>
						<mat-select [(value)]="selectedOwner" disabled>
							<mat-option *ngFor="let owner of owners" [value]="owner.name">
								{{owner.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

			</mat-card-content>
			<mat-card-actions>
				<div class="row">
					<div class="col-12 text-center">
						<button mat-raised-button [disabled]="!isValidForm() || isSavingTag" color="primary" class="m-2" (click)="save()">Save</button>
						<button mat-raised-button class="m-2" [routerLink]="['/short-url/dashboard']">Cancel</button>
					</div>
				</div>
			</mat-card-actions>

		</mat-card>
	</div>
</form>
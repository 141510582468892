import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonObject } from 'src/app/shared/interfaces/ICommonObject';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { environment } from 'src/environments/environment';
import { IDomain } from '../interfaces/idomain';

@Injectable({
  providedIn: 'root'
})
export class UrlShortenerDomainService extends CRUDService<IDomain> {

  create(model: IDomain): Observable<IDomain> {
    throw new Error('Method not implemented.');
  }
  makeSearchListGroup(defaultParams?: ICommonObject) {
    throw new Error('Method not implemented.');
  }
  apiPath = '/domain';
  environmentCRUDPath = environment.urlShortener;
  constructor(
    public readonly httpClient: HttpClient,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute,
    public readonly fb: FormBuilder
  ) {
    super();
  }


  list(params?: HttpParams): Observable<ICommonListingResponse<IDomain[]>> {
    return this.httpList(params);
  }
  get(id: string | number): Observable<IDomain> {
    return this.httpGet(id.toString());
  }
  delete(id: string | number) {
    return this.httpClient.delete<IDomain>(this.deleteURL + '/'+id);
  }
  update(id: string | number, model: IDomain) {
    return this.httpClient.post<IDomain>(this.saveURL(id), model)
  }
  save(model: IDomain, id?: string | number): Observable<IDomain> {
    return this.httpClient.post<IDomain>(this.saveURL(id), model);
  }
  makeFormGroup(loadedModel?: IDomain): FormGroup<any> {
    throw new Error('Method not implemented.');
  }
}

import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { filter, fromEvent, Subscription } from 'rxjs';
import { IService } from '../../interfaces/IService';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { SalonFinderCollectionService } from '../../services/salon-finder-collection.service';
import { SalonFinderServiceService } from '../../services/salon-finder-service.service';
import { HttpClient} from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'salon-finder-collection-update-service',
  templateUrl: './collection-update-service.component.html',
  styleUrls: ['./collection-update-service.component.scss']
})
export class CollectionUpdateServiceComponent extends ListingComponent<IService> implements OnInit, OnChanges {
  @Input()
  collectionID: number = null;

  loading$: Subscription;
  
  serviceForm!: FormGroup;
  savingForm = false;
  collection_id =  '';
  service_id =  '';
  collectionName = '';
  collectionId = ''
  results: IService[];
  dataSource!:MatTableDataSource<any>;
  formList: IService[];
  // total: number;
  currentDate: Date = new Date(); 
  ended: string;
  isselected = false;
  selectedServices = [];
  
  selection = new SelectionModel<IService>(true, []);
  dataLoaded = false;
  submitted = false;
  massage = null;
  loadingCollectionID = 0;

  // @Input('collectionForm') loadedModel: IService;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort: MatSort;

  @ViewChild("filterTextInput") filterTextInput: ElementRef<HTMLInputElement>;
  
  constructor(
    private readonly fb: FormBuilder,
    private readonly salonFinderCollectionService: SalonFinderCollectionService,
    private readonly salonFinderServiceService: SalonFinderServiceService,
    public readonly activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private readonly snackBar: MatSnackBar
  ) {
    super();
    this.makeListingCols();
    fromEvent(this.filterTextInput.nativeElement, "keyup")
      .pipe(
        filter(e=>(e.target as any)?.value !== null),
        filter(e=>(e.target as any)?.value.length>2)
      ).subscribe(e=> {
        const value = (e.target as any)?.value
        this.dataSource.filter = value.trim().toLowerCase();

        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }
      })
  }
  
  makeListingCols() {
    this.listingCols = !this.collectionID?[
      'Action',
      'Advertiser',
      'Service Name',
      'Price',
      'Outlets',
      'Types',
      'Promotions',
      'Purchased',
      'Status'
    ]: [
      'Action',
      'Service Name',
      'Price',
      'Outlets',
      'Types',
      'Promotions',
      'Purchased',
      'Status' 
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.collectionID && changes.collectionID.currentValue.toString() !== this.collectionID.toString()) {
      this.defaultParams = {
        collectionID: this.collectionID?.toString()
      }
      if(this.loading$) {
        this.loading$.unsubscribe()
        this.loading$ = null
      }
      this.populateList()
      this.makeListingCols()
    }
  }

  populateList(params?: HttpParams | Params): void {  
    // console.log(this.loadingCollectionID);
    this.salonFinderServiceService
      .getCollectionAddedServiceData(this.loadingCollectionID, params)
      .subscribe((result:any) => {
        console.log(result);
        this.initCommonListingResponse(result);
      //   this.dataSource = new MatTableDataSource(data);
      //   this.cdr.detectChanges();
      //   this.dataSource.paginator = this.paginator;
      //   this.dataSource.sort = this.sort;
      })
  }

  getExtraListingParams() {
    throw new Error('Method not implemented.');
  }

  deleteItem(item: IService): void {
    throw new Error('Method not implemented.');
  }

  getSelectedServices() {
    this.selectedServices = [];
  }
  
  ngOnInit() {
    this.loadingCollectionID = this.activatedRoute.snapshot.params.id;
    this.populateList();
    this.initForm();
    this.makeListingCols();
  }



  initForm() {
    this.serviceForm = this.fb.group({
      services: this.fb.array([], [Validators.required])
    });

    this.getCollectionById(this.activatedRoute.snapshot.params.id);
    this.salonFinderCollectionService.get(this.activatedRoute.snapshot.params.id).subscribe((data: any) => {
      this.collectionId = data.id;
      // console.log('collection id:' + this.collectionId)
      this.collectionName = data.name;
    });
  }

  getCollectionById(id: any) {
    this.collection_id = id;
    this.salonFinderCollectionService.get(this.collection_id,).subscribe((data: any) => {
      this.collectionName = data.name;
    });
  }

  removeService(cid:number, sid: number) {
    this.salonFinderServiceService.removeServiceById(cid,sid).subscribe(
      response => {
        this.savingForm = false;
        // console.log(response);
        this.snackBar.open('Service has been deleted successfully!', 'Close', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        this.ngOnInit();
      },
      error => {
        this.savingForm = false;
        this.snackBar.open('Unable to delete service!', 'Close', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }
    );
  }
}
import { Validators } from "@angular/forms";
import {PasswordValidators, UniversalValidators} from 'ngx-validators'

export const UserPasswordValidator = [
	Validators.required,
	Validators.compose([
		PasswordValidators.lowercaseCharacterRule(1),
		PasswordValidators.uppercaseCharacterRule(1),
		PasswordValidators.digitCharacterRule(1),
	]),
	Validators.minLength(8),
	Validators.maxLength(50),
	UniversalValidators.noWhitespace
]
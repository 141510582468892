import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UpdateComponent } from 'src/app/shared/abstracts/update-component';
import { ICampaign } from '../../interfaces/campaign';
import { NativeAdsCampaignService } from '../../services/native-ads-campaign.service';

@Component({
  selector: 'app-campaign-update',
  templateUrl: './campaign-update.component.html',
  styleUrls: ['./campaign-update.component.scss']
})
export class CampaignUpdateComponent extends UpdateComponent<ICampaign, NativeAdsCampaignService> implements OnInit {
  
  constructor(
    public readonly baseService: NativeAdsCampaignService,
    private readonly activatedRoute: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.init(this.activatedRoute)
  }

}

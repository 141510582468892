import { HttpParams } from "@angular/common/http";
import { Component } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";

@Component({
  template: ''
})
export abstract class ListingComponent<T> {
  lists: T[] = []
  total: number = 0
  private _pageSizeOptions: number[] = [15, 25, 50, 100, 150, 500, 99999];
  private _pageSize: number = 15
  private _page: number = 0
  private _searchText: string = ''
  private _listingCols: string[] = []
  order: string = ''

  get pageSizeOptions() {
    return this._pageSizeOptions
  }

  set pageSizeOptions(p: number[]) {
    this._pageSizeOptions = p
  }

  get page() {
    return this._page
  }

  set page(p: number) {
    this._page = p
  }

  get searchText() {
    return this._searchText
  }

  set searchText(p: string) {
    this._searchText = p
  }

  get pageSize() {
    return this._pageSize
  }

  set pageSize(p: number) {
    this._pageSize = p
  }

  get listingCols(): string[] {
    return this._listingCols
  }

  set listingCols(cols: string[]) {
    this._listingCols = cols
  }
  
  makeSearchParams(params?): HttpParams {
    let paramObj: {[param: string]: string | ReadonlyArray<string>;} = {}
    if(params)
      Object.keys(params).map(k=> {
        if(params[k]) {
          if(Array.isArray(params[k]))
            paramObj[k+"[]"] = params[k]
          else {
            paramObj[k] = params[k]
          }
        }
      })
    paramObj = {
      searchText: this.searchText,
      ...paramObj,
      page: this.page.toString(),
      limit: this.pageSize.toString()
    }
    return new HttpParams({
      fromObject: paramObj
    })
  }

  changePage(pageEvent: PageEvent) {    
    let changePage = false;
    if (this.pageSize != pageEvent.pageSize) {
      this.pageSize = pageEvent.pageSize;
      changePage = true;
    }
    if (this.page != pageEvent.pageIndex) {
      this.page = pageEvent.pageIndex;
      changePage = true;
    }

    this.populateList(this.makeSearchParams());
  }

  abstract populateList<U>(params?: U): void
  abstract getExtraListingParams()
  abstract deleteItem(item: T): void
}
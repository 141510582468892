import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ConsumerReportUserService } from 'src/app/consumer-report/services/consumerReportUserService.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  constructor(
    private readonly route: ActivatedRoute,
    private readonly auth: AuthService,
    private readonly authService: AuthService,
    public router: Router,
    private readonly cookieHelper: CookieService,
    private readonly consumerReportUserService: ConsumerReportUserService
  ) { 
      this.consumerReportUserService.getUserInfo()
      .subscribe(res => {
        // console.log(res);
      });
  }

    ngOnInit(): void {
      
    // const code = this.route.snapshot.queryParamMap.get('code');
    // const state = this.route.snapshot.queryParamMap.get('state');
    // const signinRedirect = this.cookieHelper.get(environment.auth.signInCookie);
    // if (state === 'GET_CODE') {
    //   try {
    //     await this.authService.exchangeCodeWithToken(code);
    //     if(signinRedirect) {
    //       this.cookieHelper.delete(environment.auth.signInCookie)
    //       this.router.navigateByUrl(signinRedirect);

    //     }
    //   } catch (e) {

    //   }
    // } else {
    //   this.router.navigateByUrl('/sign-in')
    // }
  }

}

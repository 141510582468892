import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IOutlet } from '../../interfaces/IOutlet';
import { SalonFinderAdvertiserService } from '../../services/salon-finder-advertiser.service';
import { SalonFinderOutletService } from '../../services/salon-finder-outlet.service';

@Component({
  selector: 'salon-finder-outlet-create',
  templateUrl: './outlet-create.component.html',
  styleUrls: ['./outlet-create.component.scss']
})
export class OutletCreateComponent implements OnInit {

  outletForm: IOutlet = {
    advertiser: null,    
    name: '',
    outletCode: '',
    address: '',
    postalCode: '',
    email: '',
    notificationEmail: '',
    phone: '',
    description: '',
    __sImagesPath: '',
    s_images: '',
    opHours: [],
    creditCard: 0,
    active: false,
    advertiserID: 0,
    bankingEntity: null,
    uploadImages: []
  }

  constructor(private activatedRoute: ActivatedRoute, private readonly salonFinderAdvertiserService: SalonFinderAdvertiserService, private salonFinderOutletService: SalonFinderOutletService) { }

  
  ngOnInit(): void {
    this.salonFinderAdvertiserService.get(this.activatedRoute.snapshot.params.advertiserID)
      .subscribe(d=> {
        this.outletForm = {
          ...this.outletForm,
          advertiser: d,
          advertiserID: this.activatedRoute.snapshot.params.advertiserID,
          bankingEntity: d.bankingEntities.find(b=>b.outletID === 0 || b.outletID === null),
          description: d.description
        }
        
      })
  }

}
<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="side-menu-tree" >
  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let node" >
    <!-- use a disabled button to provide padding for tree leaf -->
    <!-- <button mat-icon-button disabled></button> -->
    <a mat-button class="text-start {{node?.className}} side-menu-links mat-tree-node"  [disabled]="node?.disabled" [ngClass]="{'active-tab': node.isExpanded}"  routerLinkActive="active" routerLink="{{node?.link}}">
      <mat-icon class="mat-icon-name">{{node.iconName}}</mat-icon>
      {{node.name}}
    </a>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-nested-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodeToggle (click)="triggerSection(node)">
    <a mat-button class="text-start side-menu-links mat-tree-node" routerLinkActive="active" routerLink="{{node.link}}">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'arrow_drop_down' : 'arrow_right'}}
      </mat-icon>
      <mat-icon class="mat-icon-name">{{node.iconName}}</mat-icon>
      {{node.name}}
    </a>
  </mat-nested-tree-node>
</mat-tree>
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { MatListModule } from '@angular/material/list';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LMarkdownEditorModule } from 'ngx-markdown-editor';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AuthGuardService } from './auth/auth-guard.service';
import { AuthModule } from './auth/auth.module';
import { AuthService } from './auth/auth.service';
import { LoginComponent } from './auth/login/login.component';
import { ConsumerReportModule } from './consumer-report/consumer-report.module';
import { SalonFinderModule } from './salon-finder/salon-finder.module';
import { MatTreeModule } from '@angular/material/tree';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { SharedModule } from './shared/shared.module';
import { NativeAdsModule } from './native-ads/native-ads.module';
import { UrlShorternerModule } from './url-shorterner/url-shorterner.module';
import { SecurityGuardInterceptorInterceptor } from './security-guard/injectors/security-guard-interceptor.interceptor';
import { SecurityGuardModule } from './security-guard/security-guard.module';
import { ReportModule } from './report/report.module';
import { TraceService, createErrorHandler } from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { AppAuthInjector } from './shared/injectors/AppAuthInjector';
import { SideMenuComponent } from './shared/components/side-menu/side-menu.component';
import { MatExpansionModule } from '@angular/material/expansion';

export function jwtOptionsFactory(cookieService) {
  return {
    tokenGetter: () => {
      const allCookies = cookieService.getAll();
      return allCookies[environment.auth.cookiesAccessTokenName]
    },
    allowedDomains: environment.auth.allowedDomains
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    SideMenuComponent,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    MatToolbarModule,
    MatIconModule,
    MatTableModule,
    MatButtonModule,
    HttpClientModule,
    MatSidenavModule,
    MatListModule,
    MatTreeModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [CookieService]
      }
    }),
    AuthModule,
    NativeAdsModule,
    SharedModule,
    SalonFinderModule,
    ConsumerReportModule,
    SecurityGuardModule,
    LMarkdownEditorModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    LayoutModule,
    UrlShorternerModule,
    ReportModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: true,
        logErrors: true
      })
    },
    {
      provide: TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true
    },
    AuthGuardService,
    AuthService,
    CookieService,
    { 
      provide: HTTP_INTERCEPTORS, 
      useClass: SecurityGuardInterceptorInterceptor, 
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: AppAuthInjector,
      deps: [AuthService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { HttpParams } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { debounce, debounceTime, first, map, switchMap } from 'rxjs/operators';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { DarvisService } from 'src/app/shared/services/darvis.service';
import { MetricsTypeEnum } from '../../enums/metrics-type.enum';
import { ICampaign } from '../../interfaces/campaign';
import { ICampaignItem } from '../../interfaces/campaign-item';
import { NativeAdsCampaignService } from '../../services/native-ads-campaign.service';
import { NativeAdsImpressionService } from '../../services/native-ads-impression.service';
import { NativeAdsMasterDataService } from '../../services/native-ads-master-data.service';

@Component({
  selector: 'native-ads-campaign-index',
  templateUrl: './campaign-index.component.html',
  styleUrls: ['./campaign-index.component.scss']
})
export class CampaignIndexComponent extends ListingComponent<ICampaign> implements OnInit, OnChanges {

  @Input('clientID')
  clientID: number

  searchStatusFormControl: FormControl = new FormControl([])

  populateList<U>(params?: U | HttpParams, urlHistory?: boolean): void {
    params = this.makeSearchParams({
      statusIDs: this.searchStatusFormControl.value?.map(v=>v.id),
      searchText: this.searchValue
    })
    if(this.clientID) {
      params = params.append('clientID', this.clientID)
    }
    this.commonPopulateListObservable$(params, urlHistory)
      .pipe(
        switchMap(d=> {
          let campaignItemIDs = []
          for(const campaign of d.data) {
            if(campaign?.campaignItems.length) {
              for(const campaignItem of campaign.campaignItems) {
                campaignItemIDs.push(campaignItem.id)
              }
            }
          }
          const impressions = campaignItemIDs.length>0?this.impressionService.listImpressionByCampaignItems(this.makeSearchParams({
            campaignItemIDs: campaignItemIDs
          })):of([]);
          return forkJoin({
            impressions,
            list: of(d)
          })
        })
      )
      .subscribe(d=> {
        d.list.data = d.list.data.map(l=>{
          l.campaignItems = l.campaignItems.map(campaignItem=> {            
            campaignItem.metricsCount = d.impressions.filter(impression=>impression.campaignItemID === campaignItem.id)
            return campaignItem
          })
          return l
        })
        this.initCommonListingResponse(d.list)
      })
  }
  getExtraListingParams(): void {
    throw new Error('Method not implemented.');
  }
  deleteItem(item: ICampaign): void {
    throw new Error('Method not implemented.');
  }

  constructor(
    public baseService: NativeAdsCampaignService,
    private readonly masterDataService: NativeAdsMasterDataService,
    public readonly darvisService: DarvisService,
    private readonly impressionService: NativeAdsImpressionService,
    public readonly mainRouter: Router,
    public readonly activatedRoute: ActivatedRoute
  ) {
    super();
    this.listingCols = [
      
      'client',
      'campaignName',
      
      'purchasedImpression',
      'impression',
      'viewableImpression',
      'clicks',
      'action'
    ];
  }
  ngOnChanges(changes: SimpleChanges): void {    
    if(changes.clientID) {
      this.populateList()
    }
  }

  getPurchasedImpression(campaignItems: ICampaignItem[]) {
    let impressionCount = 0;
    for(const campaignItem of campaignItems) {
      impressionCount += campaignItem.impressionPurchased
    }
    return impressionCount
  }

  getArchievedImpression(campaignItems: ICampaignItem[]) {
    let achievedImpression = 0;
    for(const campaignItem of campaignItems) {
      achievedImpression += campaignItem.achievedImpression
    }
    return achievedImpression
  }

  getArchievedViewableImpression(campaignItems: ICampaignItem[]) {
    let achievedImpression = 0;
    for(const campaignItem of campaignItems) {
      achievedImpression += campaignItem.achievedViewableImpression
    }
    return achievedImpression
  }

  getArchievedClick(campaignItems: ICampaignItem[]) {
    let achievedClick = 0;
    for(const campaignItem of campaignItems) {
      achievedClick += campaignItem.achievedClick
    }
    return achievedClick
  }

  getMetrics(campaignItems: ICampaignItem[], metricsTypeID: MetricsTypeEnum) {
    let count = 0
    for(const campaignItem of campaignItems) {
      const metricsCounts = campaignItem.metricsCount.filter(c=>c.metricsTypeID === metricsTypeID)
      for(const metricsCount of metricsCounts) {
        count += +metricsCount.totalCount
      }
    }
    return count
  }

  get campaignStatus$() {
    return this.masterDataService.filterReference$('campaignStatus')
  }

  selectCompare(a, b) {
    console.log(a)
    console.log(b)
  }

  ngOnInit(): void {
    this.campaignStatus$
      .subscribe(d=> {
        this.searchStatusFormControl.setValue(d)
        this.populateList()
        this.searchStatusFormControl.valueChanges.pipe(
          debounceTime(500)
        ).subscribe(d=> {
          this.refreshList()
        })
      })
    
  }

}

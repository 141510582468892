import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ListingService } from '../abstracts/listing-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { IFormQuestion } from '../interfaces/form-question';
import { IParticipantDefinedValue } from '../interfaces/iparticipant-defined-value';
import { IParticipantSubmittedValue } from '../interfaces/iparticipant-submitted-value';
import { ICommonResponse } from '../interfaces/icommonResponse.interface';

@Injectable({
  providedIn: 'root'
})
export class ConsumerReportParticipantUserDefinedValueService extends ListingService<IParticipantDefinedValue> {
  baseURL = environment.consumerReport.baseURL + environment.consumerReport.participantDefinedValueBase
  list(params?: HttpParams) {
    return this.http.get<ICommonListingResponse<IParticipantDefinedValue[]>>(this.listURL, {
      params
    })
  }
  get(id: string | number) {
    throw new Error('Method not implemented.');
  }
  delete(id: string | number) {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: any) {
    throw new Error('Method not implemented.');
  }
  create(model: any) {
    throw new Error('Method not implemented.');
  }
  save(model: any, id?: string | number) {
    throw new Error('Method not implemented.');
  }

  constructor(private readonly http: HttpClient) {
    super();
  }

  listByFormID(formID: number) {
    return this.http.get<ICommonListingResponse<IParticipantSubmittedValue>>(this.baseURL+environment.consumerReport.participantUserDefinedValueBySurvey + '/' + formID, {
      
    })
  }

  approvedDefinedValue(participantDefinedValueIDs: number[], formQuestionAnswerID: number) {
    return this.http.post<IParticipantDefinedValue>(this.baseURL+environment.consumerReport.participantUserDefinedValueMergeAnswer, {
      participantDefinedValueIDs,
      formQuestionAnswerID
    })
  }

  duplicateDefinedValue(id: string) {
    return this.http.post<ICommonResponse<IParticipantDefinedValue>>(this.baseURL+environment.consumerReport.participantUserDefinedValueDuplicate + '/' + id, {
      
    })
  }


}

<h1 ><b> Telegram - Posts </b></h1>
<div id="chart">
    <div class="chart">
        <div class="row">
            <div class="col-5">
                <mat-form-field appearance="fill" class="period-field">
                    <mat-label>Period</mat-label>
                    <mat-date-range-input [formGroup]="range" [rangePicker]="pickerRange" [dateFilter]="filterFutureDates">
                        <input matStartDate formControlName="startRange"  placeholder="Start date">
                        <input matEndDate formControlName="endRange"  placeholder="End date" (dateChange)="onFetchData()">
                    </mat-date-range-input>
                    <mat-hint>DD MMM YYYY – DD MMM YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="pickerRange"></mat-datepicker-toggle>
                    <mat-date-range-picker #pickerRange></mat-date-range-picker>

                    <mat-error *ngIf="range.controls.startRange.hasError('matStartDateInvalid')">Invalid start
                        date</mat-error>
                    <mat-error *ngIf="range.controls.endRange.hasError('matEndDateInvalid')">Invalid end
                        date</mat-error>
                </mat-form-field>


            </div>
            <div class="col-5 text-left">

                <mat-button-toggle-group *ngIf="checkToDisplayRange()" [value]="selectedRange"
                    (change)="onChangeRange($event)" class="btn-toggle-byweek-bymonth" name="fontStyle"
                    aria-label="Font Style">
                    <mat-button-toggle [value]="toggleEnumRange.Daily">Daily</mat-button-toggle>
                    <mat-button-toggle [value]="toggleEnumRange.Weekly">Weekly</mat-button-toggle>
                    <mat-button-toggle [value]="toggleEnumRange.Monthly">Monthly</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>
        <div class="row" style="display: block;" class="mb-3" *ngIf="!generatingChart">
            <canvas baseChart class="canvas" height="600" [type]="'line'"  *ngIf="lineChartData.labels.length > 0" [data]="lineChartData"
                [options]="lineChartOptions" [legend]="lineChartLegend">
            </canvas>
        </div>

        <div class="row text-center mb-4" *ngIf="generatingChart">
            <div class="loading-container">
                <mat-spinner diameter="50" color="accent"> </mat-spinner>
            </div>
            <br>
            <mat-label>Generating Chart ...</mat-label>
        </div>
    </div>
    <br>

    <div class="row text-center mb-4" *ngIf="generatingTable">
        <div class="loading-container">
            <mat-spinner diameter="50" color="accent"> </mat-spinner>
        </div>
        <br>
        <mat-label>Generating Table ...</mat-label>
    </div>

    
    <div class="row mb-3" *ngIf="!generatingTable">
        <div class="row mb-3">
            <div class="col-12 help-icon">
                <mat-icon matTooltipPosition="above"  [matTooltip]="toolTipInfo"> help</mat-icon>
            </div>
        </div>
        
        <div class="row mb-3">
            <div class="col-3"></div>
            <div class="col-3">
                <mat-card>
                    <div style="text-align:center;">
                        <mat-card-header>
                            <mat-card-title>Total views</mat-card-title>
                        </mat-card-header>
                        <mat-divider></mat-divider>
                        <mat-card-content>
                            <p class="count-number"><b>{{totalEngagements?.totalViews | number}}</b></p>
                        </mat-card-content>
                    </div>
                </mat-card>
            </div>
            <div class="col-3">
                <mat-card>
                
                <div style="text-align:center;">
                    <mat-card-header>
                        <mat-card-title>Average views per post</mat-card-title>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    <mat-card-content>
                        <p class="count-number"><b>{{totalEngagements?.averageViews | number}}</b></p>
                    </mat-card-content>
                </div>
            </mat-card>
                
            </div>
            <div class="col-3">
            <mat-card>
                
                <div style="text-align:center;">
                    <mat-card-header>
                        <mat-card-title>Average view rate based on followers</mat-card-title>
                    </mat-card-header>
                    <mat-divider></mat-divider>
                    <mat-card-content>
                        <p class="count-number"><b>{{totalEngagements?.averageViewRate | number}} % </b></p>
                    </mat-card-content>
                </div>
            </mat-card>
                
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-3">
                <mat-card >
                    <div style="text-align:center;">
                        <mat-card-header>
                            <mat-card-title>Total post</mat-card-title>
                        </mat-card-header>
                        <mat-divider></mat-divider>
                        <mat-card-content>
                            <p class="count-number"><b>{{totalEngagements?.totalPosts | number}}</b></p>
                        </mat-card-content>
                    </div>
                </mat-card>
            </div>
            <div class="col-3">
                <mat-card>
                    <div style="text-align:center;">
                        <mat-card-header>
                            <mat-card-title>Total clicks</mat-card-title>
                        </mat-card-header>
                        <mat-divider></mat-divider>
                        <mat-card-content>
                            <p class="count-number"><b>{{totalEngagements?.totalClicks | number}}</b></p>
                        </mat-card-content>
                    </div>
                </mat-card>
            </div>
            <div class="col-3">
                <mat-card >
                    <div style="text-align:center;">
                        <mat-card-header>
                            <mat-card-title>Average clicks per post</mat-card-title>
                        </mat-card-header>
                        <mat-divider></mat-divider>
                        <mat-card-content>
                            <p class="count-number"><b>{{totalEngagements?.averageClicks | number}}</b></p>
                        </mat-card-content>
                    </div>
                </mat-card>
            </div>
            <div class="col-3">
                <mat-card >
                    <div style="text-align:center;">
                        <mat-card-header>
                            <mat-card-title>Average CTR</mat-card-title>
                        </mat-card-header>
                        <mat-divider></mat-divider>
                        <mat-card-content>
                            <p class="count-number"><b>{{totalEngagements?.averageClickRate | number}} % </b></p>
                        </mat-card-content>
                    </div>
                </mat-card>
            </div>

        </div>

        <div class="row mb-3">
            <div class="col-3"></div>
            <div class="col-3">
                <mat-card>
                    <div style="text-align:center;">
                        <mat-card-header>
                            <mat-card-title>Total shares</mat-card-title>
                        </mat-card-header>
                        <mat-divider></mat-divider>
                        <mat-card-content>
                            <p class="count-number"><b>{{totalEngagements?.totalShares | number}}</b></p>
                        </mat-card-content>
                    </div>
                </mat-card>
            </div>
            <div class="col-3">
                <mat-card>
                    <div style="text-align:center;">
                        <mat-card-header>
                            <mat-card-title>Average shares per post</mat-card-title>
                        </mat-card-header>
                        <mat-divider></mat-divider>
                        <mat-card-content>
                            <p class="count-number"><b>{{totalEngagements?.averageShares | number}} </b></p>
                        </mat-card-content>
                    </div>
                </mat-card>
            </div>
            <div class="col-3">
                <mat-card>
                    <div style="text-align:center;">
                        <mat-card-header>
                            <mat-card-title>Average shares per view</mat-card-title>
                        </mat-card-header>
                        <mat-divider></mat-divider>
                        <mat-card-content>
                            <p class="count-number"><b>{{totalEngagements?.averageShareRate | number}} % </b></p>
                        </mat-card-content>
                    </div>
                </mat-card>
            </div>
        </div>

    </div>
    <div class="row mb-3 mr-2 ml-2" *ngIf="!generatingTable">
        <mat-form-field>
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia" #input>
        </mat-form-field>

        <div class="mat-elevation-z8">

            <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortChange($event)" *ngIf="!generatingTable" matSortActive="addedDate" matSortDirection="desc" matSortDisableClear>

                <ng-container matColumnDef="addedDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header  sortActionDescription="Sort by date">
                        <b> Date </b>
                    </th>
                    <td mat-cell *matCellDef="let element"> <b> <a class="date-column" [routerLink]="" (click)="navigateByDate(element.addedDate)" > {{element.addedDate | date:"dd MMM yyyy, E"}} </a>  </b> </td>
                </ng-container>

                <ng-container matColumnDef="post">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by post">
                        <b> Post</b>
                    </th>
                    <td mat-cell *matCellDef="let element" ><div [innerHtml]="element.minimizeText"></div> <br> <div [innerHtml]="makeLinksClickable(element)"></div></td>
                </ng-container>

                <ng-container matColumnDef="views">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by views">
                        <b> Views</b>
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.views | number}} </td>

                </ng-container>

                <ng-container matColumnDef="viewRate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by viewRate">
                        <b> View Rate</b>
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.viewRate | number : '1.2-2'}} % </td>
                </ng-container>

                <ng-container matColumnDef="clicks">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by clicks">
                        <b> Clicks</b>
                    </th>
                    <td mat-cell *matCellDef="let element"> 

                        <div *ngIf="element.messageLinksCount == 1">
                            <span> {{element.clicks | number}}</span>       
                        </div>
                        <div *ngIf="element.messageLinksCount == 0">
                            <span> </span>       
                        </div>

                        <div *ngIf="element.messageLinksCount > 1">
                            <b> <a style="cursor: pointer;" [routerLink]="" (click)="viewClicks(element)" > View Clicks </a>  </b>    
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="clickRate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by clickRate">
                        <b> Click Rate</b>
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.clickRate | number : '1.2-2'}} % </td>
                </ng-container>

                <ng-container matColumnDef="shares">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by shares">
                        <b> Shares</b>
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.shares | number}} </td>
                </ng-container>

                <ng-container matColumnDef="shareRate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by shareRate">
                        <b> Share Rate</b>
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.shareRate | number : '1.2-2'}} % </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>
                        <b> Actions</b>
                    </th>
                    <td mat-cell *matCellDef="let element">  
                        <div *ngIf="element.messageLinks != null">
                            <button *ngIf="element.messageLinks.length > 0" mat-icon-button (click)="openLinkClickDate(element)" matTooltip="Edit link">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button *ngIf="element.messageLinks.length == 0" mat-icon-button (click)="openLinkClickDate(element)" matTooltip="Link to url shortener">
                                <mat-icon>link</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 50, 100]" aria-label="Select page of users"></mat-paginator>
        </div>
    </div>
    <br>
    <div class="comparison mt-4">
        <div class="row">
            <div class="col-5">
                <mat-form-field appearance="fill" style="width: 100%;">
                    <mat-label>Ranged From</mat-label>
                    <mat-date-range-input [formGroup]="rangeFrom" [rangePicker]="pickerFrom">
                        <input matStartDate formControlName="startFrom" placeholder="Start date"
                            (dateChange)="onStartRangeFromChange($event)">
                        <input matEndDate formControlName="endFrom" placeholder="End date"
                            (dateChange)="onEndRangeFromChange($event)">
                    </mat-date-range-input>
                    <mat-hint>DD MMM YYYY – DD MMM YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                    <mat-date-range-picker #pickerFrom></mat-date-range-picker>

                    <mat-error *ngIf="rangeFrom.controls.startFrom.hasError('matStartDateInvalid')">Invalid start
                        date</mat-error>
                    <mat-error *ngIf="rangeFrom.controls.endFrom.hasError('matEndDateInvalid')">Invalid end
                        date</mat-error>
                </mat-form-field>
            </div>
            <div class="col-2 text-center" style="margin-top: 35px;">
                <b>Compared To</b>
            </div>
            <div class="col-5">
                <mat-form-field appearance="fill" style="width: 100%;">
                    <mat-label>Range To</mat-label>
                    <mat-date-range-input [formGroup]="rangeTo" [rangePicker]="pickerTo">
                        <input matStartDate formControlName="startTo" placeholder="Start date"
                            (dateChange)="onStartRangeToChange($event)">
                        <input matEndDate formControlName="endTo" placeholder="End date"
                            (dateChange)="onEndRangeToChange($event)">
                    </mat-date-range-input>
                    <mat-hint>DD MMM YYYY – DD MMM YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="pickerTo"></mat-datepicker-toggle>
                    <mat-date-range-picker #pickerTo></mat-date-range-picker>

                    <mat-error *ngIf="rangeTo.controls.startTo.hasError('matStartDateInvalid')">Invalid start
                        date</mat-error>
                    <mat-error *ngIf="rangeTo.controls.endTo.hasError('matEndDateInvalid')">Invalid end
                        date</mat-error>
                </mat-form-field>

            </div>
        </div>
        <br>
        <div class="row mb-3">
            <div class="col-sm">
                <mat-card class="mb-3">
                    <div style="text-align:center;">
                        <mat-card-title>Total post</mat-card-title>
                        <mat-divider></mat-divider>
                        <mat-card-content>
                            <p class="count-number"><b>{{totalPostFrom | number}} vs {{totalPostTo | number}}</b></p>

                            <div class="percentage">
                                <span class="percentage-positive" *ngIf="totalPostPercentage > 0">
                                    {{totalPostPercentage | number}} % </span>
                                <span class="percentage-negative"
                                    *ngIf="totalPostPercentage < 0">{{totalPostPercentage | number}} % </span>

                            </div>
                        </mat-card-content>

                    </div>

                </mat-card>
            </div>
            <div class="col-sm">

                <mat-card class="mb-3">
                    <div style="text-align:center;">
                        <mat-card-title>Average views</mat-card-title>
                        <mat-divider></mat-divider>
                        <mat-card-content>
                            <p class="count-number"><b>{{totalAverageViewsFrom | number}} vs {{totalAverageViewsTo | number}}</b></p>
                            <div class="percentage">
                                <span class="percentage-positive"
                                    *ngIf="totalAverageViewsPercentage > 0">{{totalAverageViewsPercentage | number}} %
                                </span>
                                <span class="percentage-negative"
                                    *ngIf="totalAverageViewsPercentage < 0">{{totalAverageViewsPercentage | number}} %
                                </span>

                            </div>
                        </mat-card-content>
                    </div>

                </mat-card>

            </div>
            <div class="col-sm">

                <mat-card class="mb-3">
                    <div style="text-align:center;">
                        <mat-card-title>Average clicks</mat-card-title>
                        <mat-divider></mat-divider>
                        <mat-card-content>
                            <p class="count-number">
                                <b>{{totalAverageClicksFrom | number}} vs {{totalAverageClicksTo | number}}</b>
                            </p>
                            <div class="percentage">
                                <span class="percentage-positive"
                                    *ngIf="totalAverageClicksPercentage > 0">{{totalAverageClicksPercentage | number}} %
                                </span>
                                <span class="percentage-negative"
                                    *ngIf="totalAverageClicksPercentage < 0">{{totalAverageClicksPercentage | number}} %
                                </span>

                            </div>

                        </mat-card-content>
                    </div>

                </mat-card>

            </div>
            <div class="col-sm">

                <mat-card class="mb-3">
                    <div style="text-align:center;">
                        <mat-card-title>Average shares</mat-card-title>
                        <mat-divider></mat-divider>
                        <mat-card-content>
                            <p class="count-number">
                                <b>{{totalAverageSharesFrom | number}} vs {{totalAverageSharesTo | number}}</b>
                            </p>
                            <div class="percentage">
                                <span class="percentage-positive"
                                    *ngIf="totalAverageSharesPercentage > 0">{{totalAverageSharesPercentage | number}} %
                                </span>
                                <span class="percentage-negative"
                                    *ngIf="totalAverageSharesPercentage < 0">{{totalAverageSharesPercentage | number}} %
                                </span>

                            </div>

                        </mat-card-content>
                    </div>

                </mat-card>

            </div>
        </div>
    </div>




</div>
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ListingService } from '../abstracts/listing-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { IStore } from '../interfaces/istore';

@Injectable({
  providedIn: 'root'
})
export class ConsumerReportStoreService extends ListingService<IStore>{
 

  baseURL = environment.consumerReport.baseURL + environment.consumerReport.storeBase

  constructor(private readonly http: HttpClient) {
    super();
  }

  list(searchParams?: HttpParams) {
    return this.http.get<ICommonListingResponse<IStore[]>>(this.baseURL + environment.consumerReport.listStore, {
      params: searchParams
    });
  }

  create(model: IStore) {
    throw new Error('Method not implemented.');
  }
  save(model: IStore, id?: string | number) {
    const url = this.saveURL(id)
    return this.http.post<IStore>(url, model)
  }
  get(id: string | number) {
    return this.http.get<IStore>(this.getURL + '/' + id.toString())
  }
  delete(id: string | number) {
    return this.http.delete<IStore>(this.baseURL + environment.consumerReport.commonDeletePath + '/' + id.toString())
  }
  update(id: string | number, updateObj: IStore) {
    throw new Error('Method not implemented.');
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DateTime } from 'luxon';
import { firstValueFrom, forkJoin } from 'rxjs';
import { MakeSearchParams } from 'src/app/shared/helpers/MakeSearchParams';
import { IOrderVoucher } from '../../interfaces/IOrderVoucher';
import { IRedeemedVoucher, IRedeemedVoucherByRange } from '../../interfaces/iredeemed-voucher';
import { SalonFinderOrderVoucherService } from '../../services/salon-finder-order-voucher.service';
import { SalonFinderOutletService } from '../../services/salon-finder-outlet.service';
import { SalonFinderOrderPaymentService } from '../../services/salon-finder-order-payment.service';

@Component({
  selector: 'salon-finder-order-voucher-payment-dialog',
  templateUrl: './order-voucher-payment-dialog.component.html',
  styleUrls: ['./order-voucher-payment-dialog.component.scss']
})
export class OrderVoucherPaymentDialogComponent implements OnInit {

  loading = false;

  voucherNames: string[] = [];

  voucherStringName:string = '';

  paymentDate = new FormControl([DateTime.now()]);

  loadedOrderVouchers: IOrderVoucher[] = [];

  constructor(
    public dialogRef: MatDialogRef<OrderVoucherPaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IRedeemedVoucherByRange,
    public salonFinderOrderVoucherService: SalonFinderOrderVoucherService,
    public salonFinderOrderPaymentService: SalonFinderOrderPaymentService,
    public salonFinderOutletService: SalonFinderOutletService,
    private readonly snackBar: MatSnackBar,
  ) {
    this.loadVoucher();
  }

  ngOnInit(): void {
    for(let voucher of this.data.redeemVouchers){
      this.voucherNames.push(voucher.entityName);
    }
    this.voucherStringName = this.voucherNames.join(" and <br/>");
  }

  async save() {
    if (this.paymentDate.status !== 'INVALID') {
      this.loading = true;
      const groupedVoucher = [];
      for (const loadedOrderVoucher of this.loadedOrderVouchers) {
        const findIndex = groupedVoucher.findIndex(gv => gv.advertiserID === loadedOrderVoucher.advertiserID);
        if (findIndex < 0) {
          console.log(loadedOrderVoucher.redeemedDate)
          groupedVoucher.push({
            advertiserID: loadedOrderVoucher.advertiserID,
            bankingEntityID: loadedOrderVoucher.advertiser.bankingEntities[0]?.id,
            loadedOrderVouchers: [loadedOrderVoucher],
            payingMonth: DateTime.fromISO(loadedOrderVoucher.redeemedDate.toString()).endOf('month').endOf('day')
          });
        } else {
          groupedVoucher[findIndex].loadedOrderVouchers.push(loadedOrderVoucher);
        }
      } 
      forkJoin(groupedVoucher.map(gv=>{
        return this.salonFinderOrderVoucherService
          .payOrderVoucher(gv.loadedOrderVouchers.map(v => v.id), this.paymentDate.value, gv.advertiserID, gv.bankingEntityID, gv.payingMonth)
      }))
      .subscribe(d => {
        this.loading = false;
        this.dialogRef.close(d);
      });
    }
  }

  loadVoucher() {
    let listingIDS: string[] = [];
    for (const ids of this.data.redeemVouchers) {
      if (ids.totalUnPaidVoucherIDs) {
        listingIDS = listingIDS.concat(listingIDS, ids.totalUnPaidVoucherIDs.split(','));
      }
    }
    listingIDS = listingIDS.filter((value, index, fullarray) => fullarray.findIndex(v => v === value) === index);
    this.salonFinderOrderVoucherService.httpList(MakeSearchParams({
      ids: listingIDS,
      limit: '-1'
    }))
    .subscribe(d => {
      this.loadedOrderVouchers = d.data;
    });
  }

}
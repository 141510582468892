import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IServicePromotion } from '../../interfaces/IServicePromotion';
import { SalonFinderServiceService } from '../../services/salon-finder-service.service';

@Component({
  selector: 'app-promo-create',
  templateUrl: './promo-create.component.html',
  styleUrls: ['./promo-create.component.scss']
})
export class PromoCreateComponent implements OnInit {

  promoForm: IServicePromotion = {
    active: true,
    id: 0,
    name: '',
    price: 0,
    originalPrice: 0,
    startDate: '',
    endDate: '',

    terms: '',
    advertiserID: 0,
    serviceID: 0,

    outlets: [],
    status: 0
  };

  loadingData: boolean = false

  constructor(private readonly activatedRoute: ActivatedRoute, private readonly salonFinderServiceService: SalonFinderServiceService) { 

  }

  ngOnInit(): void {
    this.promoForm.advertiserID = +this.activatedRoute.snapshot.params.advertiserID
  }
}
import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { debounceTime, forkJoin, map, Observable, of, startWith, Subscription, switchMap } from 'rxjs';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { DarvisService } from 'src/app/shared/services/darvis.service';
import { MasterDataTypeEnum } from '../../enums/master-data-type.enum';
import { ICampaignItem } from '../../interfaces/campaign-item';
import { IMasterData } from '../../interfaces/master-data';
import { NativeAdsCampaignItemService } from '../../services/native-ads-campaign-item.service';
import { NativeAdsMasterDataService } from '../../services/native-ads-master-data.service';
import { NativeAdsCampaignService } from '../../services/native-ads-campaign.service';
import { FormBuilder, FormControl, FormGroup, NgModel } from '@angular/forms';
import { NativeAdsUserService } from '../../services/native-ads-user.service';
import { IClient } from '../../interfaces/client';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IPublisher } from '../../interfaces/publisher';
import { NativeAdsPublisherService } from '../../services/native-ads-publisher.service';
import { SelectObjectCompareHelper } from 'src/app/shared/helpers/SelectObjectCompareHelper';
import { ICommonEntityObject } from 'src/app/shared/interfaces/ICommonEntityObject.interface';

@Component({
  selector: 'native-ads-ads-report',
  templateUrl: './ads-report.component.html',
  styleUrls: ['./ads-report.component.scss']
})
export class AdsReportComponent extends ListingComponent<ICampaignItem> implements OnInit, OnChanges, AfterViewInit {

  @Input('clientID')
  clientID?: number

  defaultAllCampaignText = 'All Campaigns'

  searchStatusFormControl: FormControl<ICommonEntityObject<any>[] | null> = new FormControl<ICommonEntityObject<any>[]>([])
  searchPublisherFormControl: FormControl<ICommonEntityObject<any>[] | null> = new FormControl<ICommonEntityObject<any>[]>([])
  searchAdsTypeFormControl: FormControl<ICommonEntityObject<any>[] | null> = new FormControl<ICommonEntityObject<any>[]>([])
  searchClientFormControl: FormControl<IClient | null> = new FormControl<IClient>({
    name: 'All',
    id: 0,
  })
  searchCampaignFormControl: FormControl = new FormControl(this.defaultAllCampaignText)
  searchCampaignTextFormControl: FormControl = new FormControl("")

  searchClientTextFormControl: FormControl = new FormControl()

  searchParams?: FormGroup;

  popuplating$?: Subscription
  clientList: IClient[] = new Array<IClient>();
  filteredClientList: IClient[] = new Array<IClient>();

  campaignList: string[] = new Array<string>();
  filteredCampaignList: string[] = new Array<string>();

  loadedPublisher$?: Observable<IPublisher[]>

  comparingPrimaryKey = SelectObjectCompareHelper

  refreshPage(page: number) {    
    this.refreshList(undefined, page)
  }

  populateList(params?: HttpParams | Params, urlHistory?: boolean): void {
    this.isLoading = true
    let listParams: HttpParams = this.makeSearchParams({
      searchText: this.searchText,
      statusIDs: this.searchStatusFormControl.value?.map(v=>v.id),
      publisherIDs: this.searchPublisherFormControl.value?.map(v=>v.id),
      adsTypeIDs: this.searchAdsTypeFormControl.value?.map(d=>d.id)
    })
    if(params) {
      listParams = (params instanceof HttpParams)?params: this.makeSearchParams(params);      
    }
    if(this.searchClientFormControl.value) {
      listParams = listParams.set('clientID', this.searchClientFormControl.value.id)
    }

    if(this.searchCampaignFormControl.value && this.searchCampaignFormControl.value != this.defaultAllCampaignText) {
      listParams = listParams.set('campaignName', this.searchCampaignFormControl.value)
    }

    if(this.popuplating$) {
      this.popuplating$.unsubscribe()
      this.popuplating$ = undefined
    }
    this.masterDataService.listCache();
    this.popuplating$ = this.commonPopulateListObservable$(listParams, urlHistory)
      .subscribe((result) => {
        this.masterDataService.listCache()
          .subscribe(m=> {
            const data = {
              total: result.total,
              data: result.data.map(od=> {
                od.status = m.find(m=>m.id === od.statusID)
                od.type = m.find(m=>m.id === od.typeID)
                return od;
              })
            }
            this.initCommonListingResponse(data);
            this.isLoading = false
          })
        
      });    
  }
  getExtraListingParams(): void {
    throw new Error('Method not implemented.');
  }
  deleteItem(item: ICampaignItem): void {
    throw new Error('Method not implemented.');
  }

  campaignItemsStatus$ = this.masterDataService.listCache().pipe(
    map(d=> {
      return d.filter(m=>m.reference === MasterDataTypeEnum.campaignStatus)
    })
  )

  campaignItemAdsType$ = this.masterDataService.listCache().pipe(
    map(d=> {
      return d.filter(m=>m.reference === MasterDataTypeEnum.adsType)
    })
  )

  
  constructor(
    public override readonly baseService: NativeAdsCampaignItemService,
    private readonly masterDataService: NativeAdsMasterDataService,
    public override readonly darvisService: DarvisService,
    public readonly campaignService: NativeAdsCampaignService,
    private readonly userService: NativeAdsUserService,
    public override readonly activatedRoute: ActivatedRoute,
    public override readonly mainRouter: Router,
    private readonly publisherService: NativeAdsPublisherService
  ) {
    super();
    const snapshot = this.activatedRoute.snapshot.queryParamMap
    this.searchClientFormControl.setValue({
      id: snapshot.has('clientID')?+snapshot.get('clientID'):0,
      name: ""
    }, {onlySelf: true})
    this.searchCampaignFormControl.setValue(snapshot.has('campaignName')?snapshot.get('campaignName'):this.defaultAllCampaignText)
    this.pageSize = 100
  }

  makeListingCol() {
    return this.clientID?[
      'campaignName',
      'title',
      'adInfo',
      'period',
      'impressionBooked',
      'impression',
      'click',
      'ctr',      
      'action'
    ]: [
      'clientName',
      'campaignName',
      'title',
      'adInfo',
      'period',
      'impressionBooked',
      'impression',
      'click',
      'ctr',      
      'action'
    ]
  }

  ngOnChanges(changes: SimpleChanges): void {    
    if(changes['clientID']) {
      if(this.popuplating$) {
        this.popuplating$.unsubscribe()
        this.popuplating$ = undefined
      }
      this.populateList()
      this.initClientList(+changes['clientID'])      
    }
  }

  getPublisherName(publishers: IPublisher[]) {
    return publishers?.map(p=>p.name).filter(p=>p).join(', ')
  }

  compareClient(a: ICommonEntityObject<any>, b: ICommonEntityObject<any>) {
    return a.id === b.id
  }

  initClientList(clientID?: number) {
    if(!clientID) {
      clientID = 0
    }
    const queryParams = this.makeSearchParams({
      limit: -1,
      clientID: clientID
    })
    queryParams.set('limit', -1)
    this.userService.list(queryParams)
      .subscribe(c=> {
        const sortedData = c.data.sort((a,b)=>a.name.toUpperCase().localeCompare(b.name.toUpperCase()))
        let totalCampaignCount = 0;
        for(const sortedD of sortedData) {
          totalCampaignCount += sortedD.campaignCount
        }
        sortedData.unshift({
          name: 'All',
          id: 0,
          campaignCount: totalCampaignCount
        })
        this.clientList = sortedData
        this.filteredClientList = sortedData
        this.searchClientTextFormControl.valueChanges.pipe(
          startWith(''),
          map(d=> {
            return d!=""?this.clientList.filter(c=>d!= "" && c.name.toUpperCase().trim().includes(d.toUpperCase().trim())): this.clientList
          })
        )
        .subscribe(d=> {
          this.filteredClientList = d
        })
      })
    this.initCampaignList(false)
  }

  initCampaignList(clearSelect: boolean) {
    const clientID = this.searchClientFormControl?.value?.id || 0
    if(clearSelect)
      this.searchCampaignFormControl.setValue(this.defaultAllCampaignText)
    return this.campaignService.listCampaignName(new HttpParams({
      fromObject: {
        limit: -1,
        clientID: clientID
      }
    }))
    .subscribe(campaign=> {
      this.campaignList = campaign.data.map(d=>d.name).sort((a,b)=> a.toUpperCase().trim().localeCompare(b.toUpperCase().trim()))
      this.campaignList.unshift(this.defaultAllCampaignText)
      this.filteredCampaignList = this.campaignList
    })
  }

  ngOnInit(): void {
    const snapshot = this.activatedRoute.snapshot.queryParamMap
    this.updateDefaultParams();
    forkJoin({
      masterData: this.masterDataService.listCache(), 
      publisher: this.publisherService.list()
    }).subscribe(d=>{
      const publisher = d.publisher.data
      const masterData = d.masterData
      if(snapshot.has('publisherIDs[]')) {
        const queryIDs = snapshot.getAll('publisherIDs[]')
        this.searchPublisherFormControl.setValue(publisher.filter(p=>queryIDs.findIndex(q=>+q === p.id)>=0), {emitEvent: false})
      } else {
        this.searchPublisherFormControl.setValue(publisher, {emitEvent: false})
      }
      this.searchStatusFormControl.setValue(masterData.filter(m=>m.reference === MasterDataTypeEnum.campaignStatus), {emitEvent: false})
      this.searchAdsTypeFormControl.setValue(masterData.filter(m=>m.reference === MasterDataTypeEnum.adsType), {emitEvent: false})
      this.populateList()
    })

    

    this.loadedPublisher$ = this.publisherService.list()
      .pipe(
        map(d=>{
          return d.data
        })
      )   
    this.searchAdsTypeFormControl.valueChanges.pipe(
      debounceTime(500)
    ).subscribe(d=>this.refreshList())
    this.searchStatusFormControl.valueChanges.pipe(
      debounceTime(500)
    ).subscribe(d=> {
      this.refreshList()
    })
    this.searchPublisherFormControl.valueChanges.pipe(
      debounceTime(500)
    ).subscribe(d=> {
      this.refreshList()
    })
    this.initClientList()
    this.searchClientFormControl.valueChanges
      .subscribe(d=> {
        this.initCampaignList(true)
        this.populateList();
      })
    this.searchCampaignTextFormControl.valueChanges
      .pipe(
        startWith(''),
        map(s=> {
          this.filteredCampaignList = s != ""?this.campaignList.filter(c=>c.toUpperCase().trim().includes(s.toUpperCase().trim())):this.campaignList
          return s
        })
      )
      .subscribe(d=> {
        this.populateList()
      })
  }
}

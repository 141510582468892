<div class="container-fluid">
	<div class="row">
		<div class="col-6">
			<security-guards-user-form [formModel]="loadedModel"></security-guards-user-form>
		</div>
		
		<div class="col-6">
			<mat-card >
				<mat-card-header>
					<mat-card-subtitle>Profile Information</mat-card-subtitle>
					<mat-card-title>{{ loadedModel?.displayName }}</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<div class="ms-4 mt-3">
						<dl>
							<dt>
								First Name
							</dt>
							<dd>
								{{ loadedModel?.firstName }}
							</dd>
				
							<dt>
								Last Name
							</dt>
							<dd>
								{{ loadedModel?.lastName }}
							</dd>
				
							<dt>
								Email
							</dt>
							<dd>
								{{ loadedModel?.email }}
							</dd>
						</dl>
					</div>
					
				</mat-card-content>
				<mat-card-actions>
					<button mat-button>LIKE</button>
					<button mat-button>SHARE</button>
				</mat-card-actions>
				<mat-card-footer>
					
				</mat-card-footer>
			</mat-card>
		</div>
		
	</div>
</div>

<div class="mt-5" *ngIf="!loading">
	<security-guards-user-request-index [userID]="loadedModel.id"></security-guards-user-request-index>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { UploadButtonComponent } from './components/upload-button/upload-button.component';
import { QuillModule } from 'ngx-quill';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LuxonModule } from 'luxon-angular';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { MatLuxonDateModule, MAT_LUXON_DATE_FORMATS } from '@angular/material-luxon-adapter';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import { ExportFileDialogComponent } from './components/export-file-dialog/export-file-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatMenuModule} from '@angular/material/menu';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { CountryFlagComponent } from './components/country-flag/country-flag.component';
import { WINDOW_PROVIDERS } from './providers/window.provider';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { UTCDate } from './pipes/utc.datepipe';
import { MenuUserIconComponent } from './components/menu-user-icon/menu-user-icon.component';
import {MatTabsModule} from '@angular/material/tabs';
import { MultiselectAutocompleteComponent } from '../url-shorterner/components/multiselect-auto-complete/multiselect-auto-complete.component';
import { MatChipsModule } from '@angular/material/chips';
import { ChangePasswordDialogComponent } from './components/change-password-dialog/change-password-dialog.component';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';



@NgModule({
  declarations: [
    UploadButtonComponent,
    ConfirmDialogComponent,
    ExportFileDialogComponent,
    MultiselectAutocompleteComponent,
    CountryFlagComponent,
    DeleteDialogComponent,
    UTCDate,
    MenuUserIconComponent,
    ChangePasswordDialogComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    LuxonModule,
    FormsModule,
    ReactiveFormsModule,
    MatLuxonDateModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatTableModule,
    MatInputModule,
    MatDividerModule,
    MatSelectModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatCardModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    NgxMatSelectSearchModule,
    NgxMaterialTimepickerModule,
    MatTabsModule,
    MatChipsModule,
    RouterModule
  ],
  exports: [
    FormsModule,
    CommonModule,
    UploadButtonComponent,
    ConfirmDialogComponent,
    ExportFileDialogComponent,
    MenuUserIconComponent,
    ReactiveFormsModule,
    QuillModule,
    MatIconModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    MatCardModule,
    MatDividerModule,
    MatTabsModule,
    MatGridListModule,
    MatButtonModule,
    LuxonModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatToolbarModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDatepickerModule,
    MatSnackBarModule,
    MatChipsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatLuxonDateModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatTableModule,
    MatDividerModule,
    MatInputModule,
    MatSelectModule,
    MatToolbarModule,
    MatCardModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatMenuModule,
    MatCheckboxModule,
    MatTabsModule,
    NgxMatSelectSearchModule,
    NgxMaterialTimepickerModule,
    MatProgressSpinnerModule,
    UTCDate,
    MultiselectAutocompleteComponent,
    MatChipsModule,
    RouterModule
  ],
  providers: [
    WINDOW_PROVIDERS,
    {
      provide: MAT_LUXON_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: ['D', 'DD']
        },
        display: {
          dateInput: 'DD',
          monthYearLabel: 'MMM yyyy',
          dateA11yLabel: 'DDD',
          monthYearA11yLabel: 'MMMM yyyy'
        }
      }
    },
  ]
})
export class SharedModule { }

import { Component, OnChanges, OnInit, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { FormComponent } from 'src/app/shared/abstracts/form-component';
import { ICollection } from '../../interfaces/ICollection';
import { SalonFinderCollectionService } from '../../services/salon-finder-collection.service';
import { environment } from 'src/environments/environment';
import { IUploadImages } from '../../interfaces/iupload-images';
import { SalonFinderUploadImagesService } from '../../services/salon-finder-upload-images.service';
import { UploadImageLinkTypeEnum } from '../../enums/UploadImageLinkType.enum';
import { IS3UploadFile } from 'src/app/shared/interfaces/IS3UploadFile';
import { AWSServiceService } from '../../../shared/services/awsservice.service';
import { Observable } from 'rxjs';
import { IAdvertiser } from '../../interfaces/IAdvertiser';
import { map, startWith } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

/** Error when invalid control is dirty, touched, or submitted. */
// export class MyErrorStateMatcher implements ErrorStateMatcher {
//   isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
//     const isSubmitted = form && form.submitted;
//     return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
//   }
// }
@Component({
  selector: 'collection-form',
  templateUrl: './collection-form.component.html',
  styleUrls: ['./collection-form.component.scss']
})

export class CollectionFormComponent extends FormComponent<ICollection, SalonFinderCollectionService> implements OnInit, OnChanges {
  baseService: SalonFinderCollectionService;
  _serviceTotal = 0;
  // collectionForm: FormGroup = undefined;
  savingForm = false;
  selectedValue: string;
  allStatus = ['Draft', 'Running'];
  allowUpload:boolean=false;
  selectedAdvertiser= new FormControl();
  filteredAdvertisers: Observable<IAdvertiser[]>
  selectedServices = [];
  loadingCollectionID = 0;

  // matcher = new MyErrorStateMatcher();

  // @Input('collectionForm') loadedModel: ICollection;
  @ViewChild('UploadFileInput') uploadFileInput: ElementRef;
  myfilename = 'Select File';

  collectionImages: IUploadImages[];

  backgroundImg: string = "";

  uploadBucket = environment.salonFinder.uploadBucket;
  fileNamePrefix = DateTime.utc().toFormat('yyyy-LL-dd-HH-mm-ss');
  uploadPath = `${environment.salonFinder.uploadKey}/${DateTime.utc().toFormat('yyyy')}/${DateTime.utc().toFormat('LL')}/`;

  constructor(
    private readonly fb: FormBuilder,
    private readonly snackBar: MatSnackBar,
    // public readonly baseService: SalonFinderCollectionService,
    // private readonly salonFinderAdvertiserService: SalonFinderAdvertiserService,
    // private readonly salonFinderOutletService: SalonFinderOutletService,
    public readonly salonFinderCollectionService: SalonFinderCollectionService,
    private readonly salonFinderUploadImageService: SalonFinderUploadImagesService,
    private readonly AWSService: AWSServiceService,
    private readonly activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    super();
    this.selectedServices = [];
  }

  get isFeatured() {
    return this.formData.get('featured').value
  }

  get serviceTotal(): number {
    return this._serviceTotal;
  }

  parentFunctionnewselectedServices(data){
    this.selectedServices=data;
  }

  setServiceTotal(t: number): void {
    this._serviceTotal = t;
  }

  async uploadedFiles(S3Images: IS3UploadFile[]) {
    const result = await this.salonFinderUploadImageService.saveByS3Image(S3Images, UploadImageLinkTypeEnum.COLLECTION)
    this.formData.patchValue({
      background_image: result[0].url,
      uploadImage: result[0],
      uploadImageID: result[0].id
    }, {onlySelf: true})
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loadedModel) {
      this.initForm();
      // this.collectionImages = (this.formData.get('uploadImages') as FormArray).value.filter(v=>v.url)
    }
  }

  initForm() {
    this.formData = this.salonFinderCollectionService.makeFormGroup(this.loadedModel);
    this.selectedServices = this.loadedModel?.services || []
    // console.log(this.formData);
  }

  onSelectedImage(event) {
    if(event.target.files.length>0) {
      const file = event.target.files[0];
      this.formData.get('backgroundImage').setValue(file);
    }
  }

  deleteImage(model: IUploadImages) {
    this.collectionImages = this.collectionImages.filter(u=>u.id != model.id)
  }

  ngOnInit(): void {
    this.initForm();
    this.filteredAdvertisers = this.selectedAdvertiser.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.name)),
      //map(name => (name ? this._filter(name) : this.options.slice())),
    );
    this.loadingCollectionID = this.activatedRoute.snapshot.params.id;
  }

  setUpSlug(){
    const slugFormControl = this.formData.get('slug')
    if((this.loadedModel.slug.length === 0 && !slugFormControl.dirty) || slugFormControl.value.length === 0) {
      let text = this.formData.get('name').value as string
      if (text.charAt(0) == " ") {
        text = text.trim();
      }
      if (text.charAt(text.length - 1) == "-") {
        //text = (text.replace(/-/g, ""));
      }
      text = text.replace(/ +/g, "-");
      text = text.replace(/--/g, "-");
      text = text.normalize("NFKD").replace(/[\u0300-\u036f]/g, ""); // Note: Normalize('NFKD') used to normalize special alphabets like óã to oa
      text = text.replace(/[^a-zA-Z0-9 -]/g, "");
      slugFormControl.setValue(text);
    }
 }

  save() {
    if(this.formErrors().length>0) {
      this.snackBar.open('Please fix all errors in the form', 'Close', {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
      this.savingForm = false
    } else
    {
      let formValue = this.formData.value;
      formValue.services = this.selectedServices.map(service=> {
        return {
          id: service.id
        }
      });
      this.savingForm = true;

      console.log('formValue');
      console.log(formValue);
      console.log('this.loadedModel.id');
      console.log(this.loadedModel.id);
      this.salonFinderCollectionService.save(formValue, this.loadedModel.id)
      .subscribe(response=>
        {
          this.savingForm = false;
          this.loadedModel = response;
          this.snackBar.open('Collection has been saved successfully!', 'Close', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
          this.router.navigate([`/salon-finder/collections`])
        },
        error => {
          this.savingForm = false;
          this.snackBar.open('Unable to add collection!', 'Close', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
          // this.initForm();
        }
      );
    }
  }

  uploadFeatured() {
    this.allowUpload=!this.allowUpload
  }
}

import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime } from 'luxon';
import { map } from 'rxjs';
import { FormComponent } from 'src/app/shared/abstracts/form-component';
import { IS3UploadFile } from 'src/app/shared/interfaces/IS3UploadFile';
import { environment } from 'src/environments/environment';
import { MasterDataTypeEnum } from '../../enums/master-data-type.enum';
import { IMasterData } from '../../interfaces/master-data';

import { IPublisher } from '../../interfaces/publisher';
import { NativeAdsMasterDataService } from '../../services/native-ads-master-data.service';
import { NativeAdsPublisherService } from '../../services/native-ads-publisher.service';

@Component({
  selector: 'native-ads-publisher-form',
  templateUrl: './publisher-form.component.html',
  styleUrls: ['./publisher-form.component.scss']
})
export class PublisherFormComponent extends FormComponent<IPublisher, NativeAdsPublisherService> implements OnInit {

  allowUploadType = ['image/gif','image/jpeg','image/png','image/jpg']

  uploadBucket = environment.nativeAds.uploadBucket;
  fileNamePrefix = DateTime.utc().toFormat('yyyy-LL-dd-HH-mm-ss');
  uploadPath = `${environment.nativeAds.uploadKey}/${DateTime.utc().toFormat('yyyy')}/${DateTime.utc().toFormat('LL')}/`;

  publisherStatus$ = this.masterDataService.listCache()
    .pipe(
      map(d=> {
        return d.filter(m=>m.reference === MasterDataTypeEnum.publisherStatus)
      })
    )

  save(): void {
    if(this.formData.valid) {
      const value = this.formData.value
      if(!value.statusID) {
        value.statusID = value.status.id
      }
      this.savingForm = true
      this.baseService.save(value, value.id)
        .subscribe({
          error: e=> {
            this.savingForm = false;
            this.snackBar.open('Publisher has been saved FAILED!', 'Close', {
              duration: 2000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
          },
          next: d=> {
            this.loadedModel = d
            this.initForm()
            this.savingForm = false
            this.snackBar.open('Publisher has been saved SUCCESSFULLY!', 'Close', {
              duration: 2000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
            this.router.navigate(['native-ads/publisher/update', d.id])
          }
        })
    }
  }

  uploadedFiles(uploadedFiles: IS3UploadFile[]) {
    this.loadedModel.imageURL = environment.nativeAds.bucketURL + '/' + uploadedFiles[0].Key
    this.formData.patchValue({
      imageURL: this.loadedModel.imageURL
    })
    
    // this..at(0).patchValue({
    //   imageURL: environment.nativeAds.bucketURL + '/' + uploadedFiles[0].Key
    // })
    // this.viewMobile = true
  }

  compareService(service: IMasterData, compareService: IMasterData) {
    return service && compareService && service.id === compareService.id
  }

  constructor(
    public baseService: NativeAdsPublisherService,
    private readonly masterDataService: NativeAdsMasterDataService,
    private readonly snackBar: MatSnackBar,
    private readonly router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.initForm();
  }

}

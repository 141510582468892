import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IAdvertiser } from '../interfaces/IAdvertiser';
import { of, Observable, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICRUDPath } from 'src/app/shared/interfaces/iCRUDPath.interface';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SalonFinderAdvertiserService extends CRUDService<IAdvertiser> {
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }
  
  list(params?: HttpParams): Observable<ICommonListingResponse<IAdvertiser[]>> {
    return this.httpClient
      .get<ICommonListingResponse<IAdvertiser[]>>(this.listURL, {
        params: params
      })
  }

  delete(id: string | number): Observable<IAdvertiser> {
    throw new Error('Method not implemented.');
  }

  update(id: string | number, model: IAdvertiser): Observable<IAdvertiser> {
    return this.save(model, id)
  }

  create(model: IAdvertiser): Observable<IAdvertiser> {
    return this.save(model, null)
  }

  makeFormGroup(loadedModel?: IAdvertiser) {
    throw new Error('Method not implemented.');
  }

  baseURL = environment.salonFinder.baseURL;
  apiPath = '/advertisers';
  environmentCRUDPath = environment.salonFinder;

  constructor(public readonly httpClient: HttpClient,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute) {
    super();
  }

  // create data entity
  saveEntity(entity:any):Observable<any>
  {
    // console.log(data);
    return this.httpClient.post(environment.salonFinder.baseURL + environment.salonFinder.entities + '/', entity);
  }

  // update data
  updateData(id:any, data:any):Observable<any>
  {
    return this.httpClient.post(environment.salonFinder.baseURL + environment.salonFinder.advertisers + '/' + id, data);
  }

  // get single data
  get(id: any): Observable<IAdvertiser> {    
    return this.httpClient.get<IAdvertiser>(this.getURL + '/' + id)
  }

  activateAdvertiser(data:any):Observable<any> {
    return this.httpClient.post(environment.salonFinder.baseURL + environment.salonFinder.advertisers + environment.salonFinder.deactivateAdvertisers, data);
  }

  sendOnBoardEmail(aid:number) {
    return this.httpClient.get(environment.salonFinder.baseURL + environment.salonFinder.sendOnBoardEmail + '/' + aid);
  }
}
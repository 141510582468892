import { Component, Inject, OnInit } from '@angular/core';
import { IForm } from '../../interfaces/form';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConsumerReportUserService } from '../../services/consumerReportUserService.service';
import { ConfirmDeleteDialogComponent } from '../confirm-delete-dialog/confirm-delete-dialog.component';

@Component({
  selector: 'app-survey-result-report-dialog',
  templateUrl: './survey-result-report-dialog.component.html',
  styleUrls: ['./survey-result-report-dialog.component.scss']
})
export class SurveyResultReportDialogComponent implements OnInit {

  savingForm = false;

  form:IForm;

  confirmForm: FormGroup = new FormGroup({
    confirmField: new FormControl('', [
      Validators.pattern(/confirm/i),
      Validators.required
    ])
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IForm,
    private readonly consumerReportUserService: ConsumerReportUserService,
    public dialogRef: MatDialogRef<ConfirmDeleteDialogComponent>,
    private readonly snackBar: MatSnackBar,
  ) { 
    this.form = data;
  }

  get formControls() { return  this.confirmForm.controls; }

  ngOnInit(): void {
  }

  closeForm() {
    this.dialogRef.close(true);
  }

  generateNewSurveyResult() {
    console.log(this.form);
    this.savingForm = true;
    this.consumerReportUserService.generateNewSurveyResult(this.form.uuid)
      .subscribe(res => {
        this.savingForm = false;
        this.snackBar.open('Generating new report result for '+this.form.name+ ' is successful.', 'Close', {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        this.dialogRef.close(res.active);
      }, error => {
        this.snackBar.open('Failed to generate new report result.', 'Close', {
          duration: 4000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        this.dialogRef.close(true);
      });
  }
}

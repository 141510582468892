import { HttpParams, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { ICommonObject } from 'src/app/shared/interfaces/ICommonObject';
import { environment } from 'src/environments/environment';
import { RoleAccessMethodEnum } from '../enums/roleAccessMethodEnum';
import { IRoleAccess } from '../interfaces/irole-access';
import { IService } from '../interfaces/iservice';
import { ServiceService } from './service.service';

@Injectable({
  providedIn: 'root'
})
export class RoleAccessService extends CRUDService<IRoleAccess> {
  list(params?: HttpParams): Observable<ICommonListingResponse<IRoleAccess[]>> {
    throw new Error('Method not implemented.');
  }
  get(id: string | number): Observable<IRoleAccess> {
    return this.httpGet(id.toString());
  }
  delete(id: string | number): Observable<IRoleAccess> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: IRoleAccess): Observable<IRoleAccess> {
    throw new Error('Method not implemented.');
  }
  create(model: IRoleAccess): Observable<IRoleAccess> {
    throw new Error('Method not implemented.');
  }

  getNewRoleAccessModel(loadedModel?: IRoleAccess) {
    return {
      'id': [loadedModel?.id || null],
      'label': [loadedModel?.label || ""],
      'path': [loadedModel?.path || ""],
      'method': [loadedModel?.method || RoleAccessMethodEnum.ALL],
      'allowAccess': [loadedModel?.allowAccess || true],
      'roleID': [loadedModel?.roleID || 0],
      'serviceID': [loadedModel?.serviceID || 0],
      service: [loadedModel?.service || {}],
    }
  }

  makeFormGroup(loadedModel?: IRoleAccess) {
    if(loadedModel?.serviceID && loadedModel?.service == null) {
      loadedModel.service = {
        id: loadedModel.serviceID,
        name: '',
        enableCheck: false,
        fqdn: '',
      }
    }
    const fb = this.fb.group(this.getNewRoleAccessModel(loadedModel))
    fb.valueChanges.subscribe(value=> {
      if((value?.service as IService)?.id != value?.serviceID) {
        this.serviceService.getDropDown()
          .subscribe(d=> {
            value.serviceID = d.find(s=>s.id === (value.service as IService).id)?.id
          })
        
      }
    })
    return fb;
  }
  makeSearchListGroup(defaultParams?: ICommonObject) {
    throw new Error('Method not implemented.');
  }
  apiPath = environment.securityGuard.roleAccessBase
  environmentCRUDPath=environment.securityGuard

  constructor(
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute,
    public readonly httpClient: HttpClient,
    public readonly fb: FormBuilder,
    private readonly serviceService: ServiceService
  ) {
    super();
  }
}

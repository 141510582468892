import { Component, Input, OnInit } from '@angular/core';
import { IStaticReportConfigGroup } from '../../interfaces/iStaticReportConfigGroups';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ConsumerReportSurveyConfigGroupService } from '../../services/consumer-report-survey-config-group.service';
import { ConsumerReportSurveyConfigService } from '../../services/consumer-report-survey-config.service';
import { IStaticReportConfig, ISurveyConfigRequest } from '../../interfaces/iStaticReportConfig';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CountryOfOrigin } from '../../constant/StaticReportConfig';

@Component({
  selector: 'app-reports-group-form',
  templateUrl: './reports-group-form.component.html',
  styleUrl: './reports-group-form.component.scss'
})
export class ReportsGroupFormComponent implements OnInit {

  @Input('formModel') loadedModel: IStaticReportConfigGroup;

  reportConfigGroup: FormGroup;
  reportConfigs:any;
  isLoading = false;
  countryOfOrigin = CountryOfOrigin;
  
  constructor(
    private fb: FormBuilder,
    private consumerReportSurveyConfigGroupService: ConsumerReportSurveyConfigGroupService,
    private consumerReportSurveyConfigService: ConsumerReportSurveyConfigService,
    private readonly snackBar: MatSnackBar,
    private router: Router,
    
    ) {
  }
  ngOnInit(): void {  
    this.reportConfigGroup = this.consumerReportSurveyConfigGroupService.makeReportConfigGroup(this.loadedModel);
    this.consumerReportSurveyConfigService.list()
    .subscribe(response=> {
    this.reportConfigs = response.data;
    this.isLoading = false;
    })
   
  }


  onSubmit() {
    this.isLoading = true;
    let item = {
      ...this.reportConfigGroup.value,
      reportConfigs : this.reportConfigGroup.value.reportConfigs.join(",")
    }

    if(this.loadedModel.id == 0){
      this.consumerReportSurveyConfigGroupService.create(item)
      .subscribe(result => {
        console.log(result);
        this.snackBar.open("Successfully save report config group.", "Close", {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        })
        this.router.navigate([`/consumer-report/reports-group`])
      
      },error => {
        this.snackBar.open("Failed to save report config group", "Close", {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        })
        this.isLoading = false;
      })
    }else{
      this.consumerReportSurveyConfigGroupService.update(this.loadedModel.id,item)
      .subscribe(result => {
        console.log(result);
        this.snackBar.open("Successfully save report config group.", "Close", {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        })
        this.router.navigate([`/consumer-report/reports-group`])
      
      },error => {
        this.snackBar.open("Failed to save report config group", "Close", {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        })
        this.isLoading = false;
      })
    }
    
   

  }

}


<form [formGroup]="formData">
  <div class="container">
    <mat-card>
      <mat-card-header>
        <mat-card-title class="pt-3">Add Promotion for {{ loadedAdvertiser?.name }} </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <mat-form-field class="col-12 col-sm-6" appearance="fill">
            <mat-label>Promoting Service</mat-label>
            <mat-select formControlName="service" [compareWith]="compareService" (selectionChange)="handleTerms($event.value)">
              <mat-option *ngFor="let service of allServices$ | async" [value]="service">
                {{service.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-6 col-sm-12 col-md-6" appearance="fill">
            <input matInput maxlength="256" placeholder="Promo Name" formControlName="name">
            <mat-error>
              <span *ngIf="formErrors('name')?.errors?.required">Promo Name is <strong>required</strong></span>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-6 col-sm-12 col-md-6" appearance="fill">
            <input matInput maxlength="10" placeholder="Promotional Price" formControlName="price">
            <mat-error>
              <span *ngIf="formErrors('price')?.errors?.required">Price is <strong>required</strong></span>
            </mat-error>
            <mat-error *ngIf="formErrors('price')?.errors?.pattern">
              <span>Please enter a valid price</span>
            </mat-error>
            <mat-error *ngIf="formErrors('price')?.errors?.lessThan">
              <span>Promotion price must be less than original price</span>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="col-6 col-sm-12 col-md-6" appearance="fill">
            <mat-label>Promotion Period</mat-label>
            <mat-date-range-input [rangePicker]="picker">                 
              <input matStartDate placeholder="Start Date" formControlName="startDate" #dateRangeStart>                 
              <input matEndDate placeholder="End Date" formControlName="endDate" #dateRangeEnd>                
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
        <div class="row mt-4 mb-3">
          <div class="col-12">
            <mat-label>Terms & Conditions</mat-label>
            <quill-editor formControlName="terms" [styles]="{height: '150px'}">                  
            </quill-editor>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <mat-slide-toggle class="example-margin" formControlName="active">
              Active
            </mat-slide-toggle>
          </div>
        </div>
        <mat-card-actions>
          <div class="row">
            <div class="col-12 text-center">
              <button mat-raised-button color="primary" class="m-2" (click)="save()">Save</button>
              <button mat-raised-button class="m-2"  [routerLink]="['/salon-finder/advertisers/update', loadedAdvertiser?.id]">Cancel</button>
            </div>
          </div>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>   
  </div>
</form>
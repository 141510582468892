import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ITel } from '../../interfaces/IUrlGenerator';
import { disable } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-whatsapp-url-generator',
  templateUrl: './whatsapp-url-generator.component.html',
  styleUrls: ['./whatsapp-url-generator.component.scss']
})
export class WhatsappUrlGeneratorComponent implements OnInit {

  defaultMessage: string = "Hi! I'm interested in your beauty service that I saw on Daily Vanity. Can you let me know..\n\n"
    + "Hi! I'm interested in your beauty service that I saw at the Daily Vanity \n"
    + "Beauty Treatments Awards. Can you let me know..";

  code :number = 65;
  number : string= null;
  message:string="";
  url:string = "";

  formData: FormGroup;

  get formErrors() { return this.formData.controls; }

  constructor(
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public loadedModel: ITel,
    public dialogRef: MatDialogRef<WhatsappUrlGeneratorComponent>,
  ) {

  }
  ngOnInit(): void {
    this.formData = this.formBuilder.group({
      number: [this.loadedModel.number || null, [
        Validators.nullValidator
      ]],
      country: [this.loadedModel?.country || 'sg', [
        Validators.required
      ]],
      message: [this.loadedModel?.message || this.defaultMessage, [
        Validators.required
      ]]
    })

    this.message  = encodeURIComponent(this.formData.get('message').value);

    this.number = this.formData.get('number').value;
   
    this.formData.get('country').valueChanges.subscribe(country =>{
      if(country == 'sg'){
        this.code = 65;
      }else{
        this.code = 60;
      }
    })

    this.formData.get('number').valueChanges.subscribe(number =>{
      this.number = number;
    })

    this.formData.get('message').valueChanges.subscribe(message =>{
      this.message = encodeURIComponent(message);
    }) 
  }

  insertUrl() {
    this.dialogRef.close("https://api.whatsapp.com/send?phone="+this.code+""+this.number+"&text="+this.message);
  }
}


import { Auth } from "./auth";
import { telegramServer } from "./telegram-server";

export const telegramReport = {

  reportUpdatePath: '/update',
  commonDeletePath: '/delete',
  commonListPath: '/list',
  commonUpdatePath: '/update',
  commonCreatePath: '/create',
  commonGetPath: '/get',

  // FOLLOWER
  getAllFollowers: '/follower/getAll',
  getFollowersComparison: '/follower/getComparison',

  //NOTIFICATION
  getAllNotifications: '/notification/getAll',
  getNotificationsComparison: '/notification/getComparison',

  //POST
  getAllPost: '/socialEngagement/getAll',
  getPostComparison: '/socialEngagement/getComparison',
  updateSocialEngagementLinks: '/socialEngagement/updateSocialEngagementLinks',

  //MESSAGE
  updateMessageUrlShotenerID: '/message/updateUrlShortenerID',

  //MESSAGE LINKS
  unlinkClick: '/message/unlinkMessage',


  //CHAT
  getAllChat: '/chat/getAll',

  defaultRoles: [
    'marketing@dailyvanity.com',
    'marketing.sg@dailyvanity.com',
    'marketing.my@dailyvanity.com',
		'editorial@dailyvanity.com',
		'editorial.my@dailyvanity.com',
		'editorial.sg@dailyvanity.com',
		'editorial.intern.sg@dailyvanity.com',
  ].map(r=>r+"_google_workspace").concat(Auth.defaultAdminRoles),


  ...telegramServer
};

import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ListingService } from '../abstracts/listing-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { IBrand } from '../interfaces/ibrand'
import { IBrandListRequest } from '../interfaces/ibrand-list-request';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { Router, ActivatedRoute } from '@angular/router';
import { ICRUDPath } from 'src/app/shared/interfaces/iCRUDPath.interface';
import { Observable } from 'rxjs';
import { Validators, FormBuilder } from '@angular/forms';
import { NativeAdsCampaignItemService } from 'src/app/native-ads/services/native-ads-campaign-item.service';
import { ConsumerReportBrandCategoryService } from './consumer-report-brand-category.service';
import { IBrandCategory } from '../interfaces/ibrand-category';

@Injectable({
  providedIn: 'root'
})
export class ConsumerReportBrandsService extends CRUDService<IBrand> {
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }
  apiPath = environment.consumerReport.brandBase
  environmentCRUDPath = environment.consumerReport


  update(id: string | number, model: IBrand): Observable<IBrand> {
    throw new Error('Method not implemented.');
  }

  convertBrandCategory(brandCategory: IBrandCategory[], category: string) {    
    return brandCategory?.findIndex(b=>b.name === category)>=0
  }

  makeFormGroup(loadedModel?: IBrand) {
    return this.fb.group({
      id: [loadedModel?.id],
      name: [loadedModel?.name, [Validators.required]],
      country: [loadedModel?.country],
      countryZone: [loadedModel?.countryZone],
      description: [loadedModel?.description],
      gender: [loadedModel?.gender.toString()],
      originatedISOCountryCode: [loadedModel?.originatedISOCountryCode, [Validators.required]],
      skincare: [ loadedModel?this.convertBrandCategory(loadedModel.brandCategory, 'skincare'):false],
      makeup: [loadedModel?this.convertBrandCategory(loadedModel.brandCategory, 'makeup'):false],
      haircare: [loadedModel?this.convertBrandCategory(loadedModel.brandCategory, 'haircare'):false],
      derma: [loadedModel?this.convertBrandCategory(loadedModel.brandCategory, 'derma'):false],
      drugStore: [loadedModel?this.convertBrandCategory(loadedModel.brandCategory, 'drugstore'):false],
      directSales: [loadedModel?this.convertBrandCategory(loadedModel.brandCategory, 'direct-sales'):false],
      salonBased: [loadedModel?this.convertBrandCategory(loadedModel.brandCategory, 'salon-based'):false],
      celebrity: [ loadedModel?this.convertBrandCategory(loadedModel.brandCategory, 'celebrity'):false],
      brandCategory: this.fb.array(this.consumerReportBrandCategoryService.makeFormArray(loadedModel?.brandCategory || [])),
    })
  }

  constructor(
    public readonly httpClient: HttpClient,
    private readonly consumerReportBrandCategoryService: ConsumerReportBrandCategoryService,
    private readonly fb: FormBuilder,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute
  ) {
    super();
  }

  list(searchParams?: HttpParams) {
    return this.httpList(searchParams)
  }
  create(model: IBrand) {
    return this.httpClient.post<IBrand>(this.createURL, model)
  }

  save(model: IBrand, id?: string | number) {
    const url = this.saveURL(id)
    model.id = +id
    return this.httpClient.post<IBrand>(url, model)
  }

  get(id: string | number) {
    return this.httpGet(id.toString())
  }
  delete(id: string | number) {
    return this.httpClient.delete<IBrand>(this.deleteURL+'/'+id.toString())
  }
}

<div class="row mb-3">
  <div class="col-12 text-right">
    <button mat-raised-button color="primary" [routerLink]="['/salon-finder/collections/add']">Add New Collection</button>
  </div>
</div>
<div class="row mb-3">
  <mat-form-field class="fullWidth col-12" appearance="fill">
    <input type="text"
    placeholder="Type anything to search collection"
    aria-label="Type anything to search collection"
    matInput
    appearance="fill"
    autocomplete="off"
    #searchTextInput>
  </mat-form-field>
</div>
<div class="row">
  <div class="col-12">
    <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
      <ng-container matColumnDef="collectionName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Collection Name</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}">
          {{ element.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="service">
        <th mat-header-cell *matHeaderCellDef>Add Service</th>
        <td mat-cell *matCellDef="let element">
          <p class="mt-3" style="text-decoration: underline;" *ngIf="element.active===1; else elseBlock">
            <a routerLink="/salon-finder/collections/update/{{element.id}}">
              {{ element.serviceCount }}
            </a><br>
            <!-- <a mat-icon-button color="primary" routerLink="/salon-finder/collections/add-service/{{element.id}}">
              <mat-icon matTooltip="Add Service">add</mat-icon>
            </a> -->
          </p>
          <ng-template #elseBlock><p [ngClass]="{'deactivated':element.active===0}">{{ element.serviceCount }}</p></ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}">
          {{ element.startDate | date:'dd MMM yyyy hh:mm a' }} To {{ element.endDate | date:'dd MMM yyyy hh:mm a' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="featured">
        <th mat-header-cell mat-sort-header *matHeaderCellDef >Featured</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}">
          {{ element.featured === 1 ? 'Yes' : 'No' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Status </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}">
          {{ element.status }}
        </td>
      </ng-container>
      <ng-container matColumnDef="active">
        <th mat-header-cell mat-sort-header *matHeaderCellDef> Active </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}">
          {{ element.active === 1 ? 'Yes' : 'No' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="action" >
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element;">
          <span>
            <a mat-icon-button color="primary" routerLink="/salon-finder/collections/update/{{element.id}}">
              <mat-icon matTooltip="Edit" matTooltipPosition="left">edit</mat-icon>
            </a>
            <a mat-icon-button color="warn" (click)="deactivate(element.id,{active:0})" [hidden]="element.active===0">
              <mat-icon matTooltip="Deactivate" matTooltipPosition="right">remove_circle</mat-icon>
            </a>
            <a mat-icon-button color="warn" (click)="deactivate(element.id,{active:1})" [hidden]="element.active===1">
              <mat-icon matTooltip="Activate" matTooltipPosition="right" class="color-green">check_circle</mat-icon>
            </a>
          </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="listingCols" class="p-2"></tr>
      <tr mat-row *matRowDef="let row; columns: listingCols;" class="p-2"></tr>
  </table>
  </div>
</div>
<div class="row mb-4">
  <div class="col-12">
    <mat-paginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" [pageIndex]="page"
      (page)="changePage($event)">
    </mat-paginator>
  </div>
</div>

<ng-container *ngIf="loading$ | async">
  <div class="overlay"></div>
  <mat-progress-spinner [mode]="'indeterminate'" [diameter]="50"></mat-progress-spinner>
</ng-container>

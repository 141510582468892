import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardLoggedService } from './auth-guard-logged.service';
import { AuthGuardService } from './auth-guard.service';
import { ConfirmNewPasswordComponent } from './confirm-new-password/confirm-new-password.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { IndexComponent } from './index/index.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignOutComponent } from './sign-out/sign-out.component';

export const AuthRoute: Routes  = [
  {path: 'sign-in', component: SignInComponent, canActivate: [AuthGuardLoggedService]},
  {path: 'sign-out', component: SignOutComponent, canActivate: [AuthGuardLoggedService]},
  {path: 'forget-password', component: ForgetPasswordComponent, canActivate: [AuthGuardLoggedService]},
  {path: 'confirm-new-password', component: ConfirmNewPasswordComponent, canActivate: [AuthGuardLoggedService]},
  {path: 'index', component: IndexComponent, canActivate: [AuthGuardService]}
];

@NgModule({
  imports: [RouterModule.forChild(AuthRoute)],
  exports: [RouterModule]
})
export class AuthRouteModule { }

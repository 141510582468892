<div class="row mb-3" id="top">
  <div class="col-8 text-right">
    <button mat-raised-button color="primary" [routerLink]="['/short-url/dashboard/add']">Add New URL</button>
  </div>
  <div class="col-4 filter-div">
    <mat-radio-group  name="viewType" [(ngModel)]="selectedView"
    (ngModelChange)="populateListByOwner()" ngDefaultControl>
      <mat-radio-button value="">View All</mat-radio-button>
      <mat-radio-button [value]="currentUser.email">View Mine</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="row mb-3">
    <div class="col-8">
      <darvis-share-multiselect-auto-complete (filterCreated)="onFilterAdded($event)" [startFilters]="startFilters"></darvis-share-multiselect-auto-complete>
    </div>
    <div class="col-4">
      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select [(value)]="selectedStatus" (selectionChange)="onChangeStatus($event)">
          <mat-option value="1">Active</mat-option>
          <mat-option value="0">Inactive</mat-option>
        </mat-select>
      </mat-form-field>
      
    </div>
  </div>
  
  <div class="row text-center" *ngIf="isLoading">
    <div class="loading-container">
      <mat-spinner diameter="50" color="accent"> </mat-spinner>
    </div>
    <br>
    <mat-label>Loading ...</mat-label>
  </div>
  <div *ngIf="!isLoading">
    <div class="row">
      <div class="col-12" >
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
          <ng-container matColumnDef="campaign">
            <th mat-header-cell *matHeaderCellDef> Campaign </th>
            <td mat-cell *matCellDef="let element">
              <div *ngIf="element.tags.length > 0">
                <div *ngFor="let e of element.tags">
                  <div *ngIf="e?.key === 'campaign'"> 
                    <a href="javascript:void(0)"  (click)="clickTag(e)">
                      {{ e?.value }}
                    </a>
                  </div>  
                </div>
              </div>           
            </td>
          </ng-container>

          <ng-container matColumnDef="client">
            <th mat-header-cell *matHeaderCellDef> Client name </th>
            <td mat-cell *matCellDef="let element;">
              <div *ngIf="element.tags.length > 0">
                <div *ngFor="let e of element.tags">
                  <div *ngIf="e?.key === 'client'"> 
                    <a href="javascript:void(0)"  (click)="clickTag(e)">
                      {{ e?.value }}
                    </a>
                  </div>  
                </div>  
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let element;"> {{ element.description }} </td>
          </ng-container>

          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element;">
              {{ element.active === 1 ? 'Active': 'Archive' }} </td>
          </ng-container>

          <ng-container matColumnDef="url">
            <th mat-header-cell *matHeaderCellDef> URL </th>
            <td mat-cell *matCellDef="let element;"> 
              <!-- <span> {{ element.redirectURL }} </span><br>  -->
              <a rel="noopener noreferrer" href="https://{{ element.fullURL }}" target="_blank"> <span>https://{{ element.fullURL }}</span> </a>
              <br>
              <div class="social-icons" *ngIf="hasDVSG(element.fullURL)">
                <a mat-icon-button (click)="copyClipBoard(element.fullURL)"  matTooltip="Click to copy this URL">
                  <mat-icon>file_copy</mat-icon>
                </a>
                <a mat-icon-button (click)="copyClipBoardTikTok(element.hash)" matTooltip="Click to copy this URL that is accepted for TikTok">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tiktok" viewBox="0 0 16 16">
                    <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z"/>
                  </svg>
                </a>
              </div>


    
            </td>
          </ng-container>
          
          <ng-container matColumnDef="allClicks">
            <th mat-header-cell *matHeaderCellDef> Click Total </th>
            <td mat-cell *matCellDef="let element;"> 
              <button mat-button color="primary" (click)="onClickImpression(element.id)" class="no-hover-effect">
                <span>{{ element.totalRequested === null ? 0 : element.totalRequested }}</span>
              </button> 
            </td>
          </ng-container>

          <ng-container matColumnDef="uniqueClicks">
            <th mat-header-cell *matHeaderCellDef> Unique Click </th>

            <td mat-cell *matCellDef="let element;">
              <button mat-button color="primary" (click)="onClickImpression(element.id)" class="no-hover-effect">
                <span>{{ element.totalUniqueRequested === null ? 0: element.totalUniqueRequested }}</span>
              </button> 
            </td>
          </ng-container>
          
          <ng-container matColumnDef="currentOwner">
            <th mat-header-cell *matHeaderCellDef> Owner </th>
            <td mat-cell *matCellDef="let element;"> {{ element?.account?.name }} </td>
          </ng-container>

          <ng-container matColumnDef="createdOn">
            <th mat-header-cell *matHeaderCellDef> Created On </th>
            <td mat-cell *matCellDef="let element;"> {{ element.createdAt | date:'d MMM y'  }} </td>
          </ng-container>
          
          <ng-container matColumnDef="action" >
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element;" >
              <div *ngIf="element.active === 1">
                <a mat-icon-button color="primary" routerLink="/short-url/dashboard/update/{{element.id}}" matTooltip="Edit">
                  <mat-icon>edit</mat-icon>
                </a> 
                <a mat-icon-button color="warn" (click)="deleteItem(element)" matTooltip="Delete">
                  <mat-icon>remove_circle</mat-icon>
                </a>
                <a mat-icon-button (click)="resetClicks(element)" matTooltip="Reset Clicks">
                  <mat-icon>rotate_left</mat-icon>
                </a>
                <a mat-icon-button (click)="archivedItem(element)" matTooltip="When a link is archived, the report numbers and details will be saved but the short
                URL will no longer be in used. This allows you to reuse the short URL for other long URLs. Please ensure places that are using this archived URL have
                been changed">
                  <mat-icon>archive</mat-icon>
                </a>
              </div>
              <div *ngIf="element.active === 0">
                <a mat-icon-button (click)="unArchivedItem(element)" matTooltip="Unarchive">
                  <mat-icon>unarchive</mat-icon>
                </a>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="listingCols" class="p-2"></tr>
          <tr mat-row *matRowDef="let row; columns: listingCols;" class="p-2"></tr>
        </table>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <mat-paginator 
          [length]="total"
          [pageSize]="pageSize"
          [pageIndex]="page"
          [pageSizeOptions]="pageSizeOptions"
          (page)="changePage($event)">
        </mat-paginator>
      </div>
    </div>
</div>

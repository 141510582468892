<div class="row mb-3">
  <div class="col-12 text-right">
    <button mat-raised-button color="primary" [routerLink]="['/consumer-report/influencer/create']">Add New Influencer</button>
  </div>
</div>
<div class="row mb-3" [formGroup]="searchForm">
  <div class="col-12">
    <mat-card>
      <mat-card-content>
        <div class="row">
          <div class="col-8">
            <mat-form-field class="fullWidth col-12" appearance="fill">
              <input type="text"
                placeholder="Search Form"
                aria-label="Search Form"
                matInput
                appearance="fill"
                formControlName="searchText"
                #searchFormInput>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field class="fullWidth" appearance="fill">
              <mat-label>Survey Country</mat-label>
              <mat-select multiple formControlName="servingCountry" [compareWith]="selectedServingCountry">
                <mat-option *ngFor="let country of surveyServingCountry" [value]="country">{{country.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>        
        </div>
      </mat-card-content>  
      <mat-card-actions>
        <div class="col-12 text-center">
          <button mat-raised-button color="primary" (click)="search()">Search</button>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
<div class="row mb-3">
  
</div>
<div class="row">
  <div class="col-12">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> 
          {{ element.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="originatedISOCountryCode">
        <th mat-header-cell *matHeaderCellDef> Country </th>
        <td mat-cell *matCellDef="let element;"> {{ element.originatedISOCountryCode }} </td>
      </ng-container>

      <ng-container matColumnDef="action" >
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element;">
          <a mat-icon-button color="primary" routerLink="/consumer-report/influencer/update/{{element.id}}">
            <mat-icon>edit</mat-icon>
          </a> 
          <a mat-icon-button color="warn" (click)="deleteItem(element)">
            <mat-icon>remove_circle</mat-icon>
          </a>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="listingCols" class="p-2"></tr>
      <tr mat-row *matRowDef="let row; columns: listingCols;" class="p-2"></tr>
    </table>
  </div>
</div>
<div class="row mb-4">
  <div class="col-12">
    <mat-paginator 
      [length]="total"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      (page)="changePage($event)">
    </mat-paginator>
  </div>
</div>
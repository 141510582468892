import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { environment } from 'src/environments/environment';
import { IBrandCategory } from '../interfaces/ibrand-category';

@Injectable({
  providedIn: 'root'
})
export class ConsumerReportBrandCategoryService extends CRUDService<IBrandCategory> {
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }
  
  apiPath = environment.consumerReport.categoryBase
  environmentCRUDPath = environment.consumerReport

  constructor(
    public readonly httpClient: HttpClient,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute,
    public readonly fb: FormBuilder
  ) {
    super();
  }

  get(id: string | number) {
    return this.httpGet(id.toString())
  }


  list(params?: HttpParams): Observable<ICommonListingResponse<IBrandCategory[]>> {
    return this.httpList(params)
  }
  delete(id: string | number): Observable<IBrandCategory> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: IBrandCategory): Observable<IBrandCategory> {
    throw new Error('Method not implemented.');
  }
  create(model: IBrandCategory): Observable<IBrandCategory> {
    throw new Error('Method not implemented.');
  }

  makeBrandCategoryArray(loadedModel: IBrandCategory[], categoryList: IBrandCategory[]) {
    const formArray = categoryList.map(c=> {
      if(loadedModel.findIndex(l=>l.id == c.id) >=0) {
        return this.makeFormGroup({
          ...c,
          checked: true
        })
      } else {
        return this.makeFormGroup({
          ...c,
          checked: false
        })
      }
    })
    return formArray
  }

  makeFormGroup(loadedModel?: IBrandCategory) {
    return this.fb.group({
      id: [loadedModel?.id || 0],
      name: [loadedModel?.name || ""],
      value: [loadedModel?.value || ""],
      checked: [loadedModel?.checked || false],
      active: [loadedModel?.active || true]
    })
  }
}

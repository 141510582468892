import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ListingService } from '../abstracts/listing-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { IFormQuestionAnswer } from '../interfaces/form-question-answer';
import { ICommonResponse } from '../interfaces/icommonResponse.interface';

@Injectable({
  providedIn: 'root'
})
export class ConsumerReportFormQuestionAnswerService extends ListingService<IFormQuestionAnswer>{
  get(id: string | number) {
    throw new Error('Method not implemented.');
  }
  
  list(params?: HttpParams) {
    return this.http.get<ICommonListingResponse<IFormQuestionAnswer[]>>(this.listURL, {
      params: params
    });
  }

  delete(id: string | number) {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: IFormQuestionAnswer) {
    throw new Error('Method not implemented.');
  }
  create(model: IFormQuestionAnswer) {
    throw new Error('Method not implemented.');
  }
  save(model: IFormQuestionAnswer, id?: string | number) {
    throw new Error('Method not implemented.');
  }
  baseURL = environment.consumerReport.baseURL + environment.consumerReport.formQuestionAnswerBase
  constructor(private readonly http: HttpClient) {
    super();
  }
}

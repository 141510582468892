export const removeHttpsPrefix = (link:string) => {

    const prefixesToRemove = ['https://://', 'http://://', 'https://', 'http://'];

    for (const prefix of prefixesToRemove) {
      if (link.startsWith(prefix)) {
        // Remove the prefix from the string
        return link.slice(prefix.length);
      }
    }

    link = link.replace('https://','');
    link = link.replace('http://','');

    // If the string doesn't start with any of the prefixes, return it as is
    return link;
}
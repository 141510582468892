<form [formGroup]="searchFormGroup" class="row mb-3">
	<div class="col-12">
		<mat-card>
			<mat-card-content>
				<div class="row">
					<mat-form-field class="col-12" appearance="fill">
						<input type="text" placeholder="Type anything to search user" aria-label="Type anything to search user request"
							matInput appearance="fill" autocomplete="off" formControlName='searchText'>
					</mat-form-field>
					<mat-form-field class="col-12 col-sm-4" appearance="fill">
						<mat-label>Select Date Range</mat-label>
						<mat-date-range-input [rangePicker]="picker">                 
							<input matStartDate placeholder="Start Date" formControlName="startDate" #dateRangeStart>                 
							<input matEndDate placeholder="End Date" formControlName="endDate" #dateRangeEnd>                
						</mat-date-range-input>
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>
					<mat-form-field class="col-12 col-sm-4" appearance="fill">
						<mat-label>Services</mat-label>
						<mat-select multiple formControlName="services" [compareWith]="compareCommonEntity">
							<mat-option *ngFor="let service of serviceList" [value]="service">
								{{service.name}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field class="col-12 col-sm-4" appearance="fill">
						<mat-label>Activity Type</mat-label>
							<mat-select formControlName="activityType" multiple>
								<mat-option value="API">API Calls</mat-option>
								<mat-option value="Client">Client Navigation</mat-option>
								<mat-option value="Auth">Auth</mat-option>
							</mat-select>
					</mat-form-field>
				</div>
				<div class="row">
					<div class="col-12">
						<button mat-raised-button color="primary" class="m-2" (click)="populateList(null, true)" [disabled]="isSearching">Search</button>
					</div>
				</div>
				
			</mat-card-content>
		</mat-card>
	</div>
	
	
</form>
<div class="row">
	<div class="col-12">
		<table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
			<ng-container matColumnDef="domain">
				<th mat-header-cell mat-sort-header *matHeaderCellDef>Service</th>
				<td mat-cell *matCellDef="let element">
					{{ element.service?.name || element.applicationName }}
				</td>
			</ng-container>
			<ng-container matColumnDef="user">
				<th mat-header-cell mat-sort-header *matHeaderCellDef>User</th>
				<td mat-cell *matCellDef="let element">
					{{ element.user?.displayName }}
				</td>
			</ng-container>
			<ng-container matColumnDef="url">
				<th mat-header-cell *matHeaderCellDef>URL</th>
				<td mat-cell *matCellDef="let element">
					<span *ngIf="element.userRequestQuery">
						[{{ element.userRequestQuery.action }}] {{ getURLAccess(element.userRequestQuery) }}
					</span>
				</td>
			</ng-container>
			<ng-container matColumnDef="serviceType">
				<th mat-header-cell *matHeaderCellDef>Activity Type</th>
				<td mat-cell *matCellDef="let element">
					{{ element.type }}
				</td>
			</ng-container>

			<ng-container matColumnDef="createdDate">
				<th mat-header-cell *matHeaderCellDef>Date</th>
				<td mat-cell *matCellDef="let element">
					{{ element.createdDate | utcdate:'d MMM YYYY hh:mm:ss a' }}
				</td>
			</ng-container>


			<ng-container matColumnDef="action">
				<th mat-header-cell *matHeaderCellDef> Action </th>
				<td mat-cell *matCellDef="let element;">
					<a mat-icon-button color="primary" routerLink="/security-guard/profile/{{element.id}}">
						<mat-icon matTooltip="View" matTooltipPosition="left">visibility</mat-icon>
					</a>
				</td>
			</ng-container>
			<tr mat-header-row *matHeaderRowDef="listingCols" class="p-2"></tr>
			<tr mat-row *matRowDef="let row; columns: listingCols;" class="p-2"></tr>
		</table>
	</div>
</div>
<div class="row mb-4">
	<div class="col-12">
		<mat-paginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
			[showFirstLastButtons]="true" [pageIndex]="page" (page)="changePage($event)">
		</mat-paginator>
	</div>
</div>
import { securityGuardServer } from "./security-guard.server";

export const securityGuard = {
  commonDeletePath: '/delete',
  commonListPath: '/list',
  commonUpdatePath: '/update',
  commonCreatePath: '/create',
  commonGetPath: '/get',

  userRequestBase: '/user-request',
  roleBase: '/role',
  roleSyncGoogleGroup: '/sync-google-group',
  roleAccessBase: '/role-access',
  serviceBase: '/service',
  servicePermissionBase: '/service-permission',
  userBase: '/user',
  timeInterval: 10000,
  ignoreDomains: [
    'http://localhost:5100',
    'https://auth.dailyvanity.sg',
    'https://security-guards.tipsy.darvis.dev',
    'https://security-guards.steady.darvis.dev',
  ],
  ...securityGuardServer
}
import { SelectionModel } from '@angular/cdk/collections';
import { Component, ElementRef, OnChanges, OnInit, SimpleChanges, TrackByFunction, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FormComponent } from 'src/app/shared/abstracts/form-component';
import { MakeSearchParams } from 'src/app/shared/helpers/MakeSearchParams';
import { IS3UploadFile } from 'src/app/shared/interfaces/IS3UploadFile';
import { environment } from 'src/environments/environment';
import { typesArr } from '../../constants/arrays';
import { UploadImageLinkTypeEnum } from '../../enums/UploadImageLinkType.enum';
import { IOutlet } from '../../interfaces/IOutlet';
import { IService } from '../../interfaces/IService';
import { ISalonFinderServiceCategories } from '../../interfaces/salon-finder-service-categories';
import { SalonFinderAdvertiserService } from '../../services/salon-finder-advertiser.service';
import { SalonFinderOutletService } from '../../services/salon-finder-outlet.service';
import { SalonFinderServiceCategoriesService } from '../../services/salon-finder-service-categories.service';
import { SalonFinderServiceService } from '../../services/salon-finder-service.service';
import { SalonFinderUploadImagesService } from '../../services/salon-finder-upload-images.service';

@Component({
  selector: 'service-form',
  templateUrl: './service-form.component.html',
  styleUrls: ['./service-form.component.scss']
})
export class ServiceFormComponent extends FormComponent<IService, SalonFinderServiceService> implements OnInit, OnChanges {
  baseService: SalonFinderServiceService;

  subtypes: any;
  outlets: any;
  allAdvertisers: any;

  customDuration: any;
  imagePreviewUrl: any;

  imgLogo: string;

  dataLoaded = false;
  savingForm = false;

  isLoading = true;
  advertiserOutlets: IOutlet[];
  parentServiceCategories: ISalonFinderServiceCategories[];
  serviceCategories: ISalonFinderServiceCategories[];

  allTypes = [];
  allDurations = [
    {key: 15, value: '15 mins'},
    {key: 15, value: '30 mins'},
    {key: 15, value: '45 mins'},
    {key: 15, value: '1 hr'},
    {key: 15, value: '1.5 hrs'},
    {key: 15, value: '2 hrs'},
    {key: 15, value: '2.5 hrs'},
    {key: 15, value: '3 hrs'}
  ];

  checked = true; 
  outletSelection: SelectionModel<number> = new SelectionModel(true, []);

  uploadBucket = environment.salonFinder.uploadBucket;
  fileNamePrefix = DateTime.utc().toFormat('yyyy-LL-dd-HH-mm-ss');
  uploadPath = `${environment.salonFinder.uploadKey}/${DateTime.utc().toFormat('yyyy')}/${DateTime.utc().toFormat('LL')}/`;

  constructor(
    private readonly fb: FormBuilder,
    private readonly snackBar: MatSnackBar,
    private readonly salonFinderAdvertiserService: SalonFinderAdvertiserService,
    private readonly salonFinderOutletService: SalonFinderOutletService,
    private readonly salonFinderServiceService: SalonFinderServiceService,
    private readonly salonFinderServiceCategoriesService: SalonFinderServiceCategoriesService,
    private readonly salonFinderUploadImageServices: SalonFinderUploadImagesService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute
  ) {
    super();
    // console.log('this.advertiserOutlets');
    // console.log(this.advertiserOutlets);
    this.outletSelection.changed.subscribe(d=> {
      if (d.added && this.advertiserOutlets) {
        d.added
          .map(id => this.advertiserOutlets.find(a => a.id === id))
          .map(outlet => this.formDataOutlets.push(this.salonFinderOutletService.makeFormGroup(outlet)))
      }

      if (d.removed) {
        d.removed
          .map(id => this.formDataOutlets.controls?.findIndex(c => c.value.id === id))
          .map(removeIndex => this.formDataOutlets.removeAt(removeIndex))
      }
    });
  }

  get formDataOutlets() {
    return this.formData.get('outlets') as FormArray;
  }

  get loadedAdvertiser() {
    return this.formData?.value?.advertiser;
  }

  get formDataCategories() {
    return this.formData.get('categories') as FormArray;
  }

  async uploadedFiles(S3Images: IS3UploadFile[]) {
    const result = await this.salonFinderUploadImageServices.saveByS3Image(S3Images, UploadImageLinkTypeEnum.SERVICE);
    this.formData.patchValue({
      s_images: result[0].url,
      uploadImage: result[0],
      uploadImageID: result[0].id
    }, {onlySelf: true});
  }

  toggleCategory(serviceCategories: ISalonFinderServiceCategories) {
    if (this.isCategorySelected(serviceCategories.id) >= 0) {
      const deleteIndex = this.isCategorySelected(serviceCategories.id);
      this.formDataCategories.removeAt(deleteIndex);
      if (!serviceCategories.parentID) {
        this.formData.patchValue({
          categories: this.formDataCategories.value.filter(v => v.parentID != serviceCategories.id).map(m => this.salonFinderServiceCategoriesService.makeFormGroup(m))
        }, {onlySelf: true});
      }
    } else {
      this.formDataCategories.push(this.salonFinderServiceCategoriesService.makeFormGroup(serviceCategories));
      if (!serviceCategories.parentID) {
        const selectedValues = this.formDataCategories.value as ISalonFinderServiceCategories[];
        const newCategories = this.serviceCategories.filter(s => s.parentID === serviceCategories.id).filter(s => !selectedValues.find(f => f.id === s.id))
        this.formData.patchValue({
          categories: [...selectedValues, ...newCategories].map(d => this.salonFinderServiceCategoriesService.makeFormGroup(d))
        }, {onlySelf: true});
      }
    }
  }

  isCategorySelected(id) {
    return (this.formDataCategories.value as ISalonFinderServiceCategories[])?.findIndex(c => c.id === id);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loadedModel) {
      this.initForm();

    }
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    if(this.activatedRoute.snapshot.paramMap.get('advertiserID')) {
      this.fetchAdvertiser(this.activatedRoute.snapshot.paramMap.get('advertiserID'));
    }
    if (this.loadedModel && this.loadedModel?.advertiserID) {
      this.outlet(this.loadedModel.advertiserID);
    }
    this.categories();
    this.formData = this.salonFinderServiceService.makeFormGroup(this.loadedModel);
    if (this.formData.value?.outlets?.filter(o => o.id)?.length > 0) {
      this.outletSelection.select(...this.formData.value.outlets.filter(o => o.id).map(o => o.id))
    }
  }

  getServiceCategories(parentID: number): ISalonFinderServiceCategories[] {
    return this.serviceCategories.filter(c => c.parentID === parentID);
  }

  categories(): void {
    this.salonFinderServiceCategoriesService
      .list(MakeSearchParams({
        limit: -1
      }))
      .pipe(
        tap(d => []),
        map(d => {
          return d.data.sort((a, b) => a.name.localeCompare(b.name));
        })
      )
      .subscribe(d => {
        this.parentServiceCategories = d.filter(dd => !dd.parentID);
        this.serviceCategories = d;
      });
  }

  outlet(advertiserID: number): void {
    this.salonFinderOutletService
      .list(MakeSearchParams({
        limit: -1,
        advertiserID: advertiserID.toString()
      }))
      .pipe(
        tap(d => []),
        map(d => {
          return d.data;
        })
      ).subscribe(d => this.advertiserOutlets = d.sort((a,b) => a.active?1:!b.active?-1:0));
  }

  fillTypes() {
    this.allTypes = typesArr;
  }

  handleChangeAdvertisers(value) {
    this.fetchAdvertiser(value);
    this.fetchAdvOutlets(value);
  }

  handleChangeTypes(value) {
    const results = typesArr.find(({ key }) => key === value);
    this.subtypes = results.subtitle;
  }

  handleChangeDuration(value) {
    this.customDuration = value;
  }

  checkedSubType(subtype, isChecked: boolean) {
    const subtypes: FormArray = this.formData.get('subtypes') as FormArray;

    if (isChecked) {
      subtypes.push(new FormControl(subtype));
    } else {
      const index = subtypes.controls.findIndex(x => x.value === subtype);
      subtypes.removeAt(index);
    }
  }

  checkedOutlets(id: number, isChecked: boolean) {
    const selectedOutlets: FormArray = this.formData.get('selectedOutlets') as FormArray;

    if (isChecked) {
      selectedOutlets.push(new FormControl(id));
    } else {
      const index = selectedOutlets.controls.findIndex(x => x.value === id);
      selectedOutlets.removeAt(index);
    }
  }

  // fetch advertiser's
  fetchAdvertiser(advertiserId: any) {
    this.dataLoaded = true;
    this.salonFinderAdvertiserService.get(advertiserId).subscribe((data: any) => {
      // this.formData.controls['description'].setValue(data.description);
      this.formData.controls.terms.setValue(data.terms);
    });
  }

  // fetch advertiser's outlets
  fetchAdvOutlets(advertiserId: any) {
      this.salonFinderOutletService.getOutlets(advertiserId)
      .subscribe((data: any) => {
        this.outlets = data;
        const selectedOutlets: FormArray = this.formData.get('selectedOutlets') as FormArray;
        for (let i = 0; i < this.outlets.length; i++) {
          selectedOutlets.push(new FormControl(this.outlets[i].id));
        }
      });
  }

  save() {
    const value = this.formData.value;
    // const data = {
    //   id: 0,
    //   wpID: 0,
    //   name: value.name,
    //   price: parseFloat(value.price),
    //   duration: value.customDuration ? value.customDuration : value.duration,
    //   advertiserID: parseInt(value.advertiser.toString()),
    //   description: value.description,
    //   terms: value.terms,
    //   __s_images:  value.s_images,
    //   imageURL:  value.s_images,
    //   __type: value.type,
    //   __subType: typeof value.subtypes === 'string' ? value.subtypes : value.subtypes.join(','),
    //   outletList: value.selectedOutlets,
    //   __outlets: typeof value.selectedOutlets === 'string' ? value.selectedOutlets : value.selectedOutlets.join(','),
    //   preview: value.preview,
    //   active: value.active
    // };

    this.savingForm = true;

    value.categories = value.categories.filter(c => c.id);
    value.outlets = value.outlets.filter(c => c.id);
    console.log('value');
    console.log(value);
    this.salonFinderServiceService.save(value, value.id)
    .subscribe(
      response => {
        this.savingForm = false;
        this.loadedModel = response;
        this.snackBar.open('Service has been saved successfully!', 'Close', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        this.router.navigate([`/salon-finder/advertisers/services/add/${value.advertiserID}`])
      },
      error => {
        this.savingForm = false;
        this.snackBar.open('Unable to add service!', 'Close', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
        // this.initForm();
      }
    );
  }
}

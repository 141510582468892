import { SelectionModel } from '@angular/cdk/collections';
import { HttpParams } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute, Params } from '@angular/router';
import moment from 'moment';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { IServicePromotion } from '../../interfaces/IServicePromotion';
import { LoadingService } from '../../services/loading.service';
import { SalonFinderPromoService } from '../../services/salon-finder-promo.service';

@Component({
  selector: 'salon-finder-promo-index',
  templateUrl: './promo-index.component.html',
  styleUrls: ['./promo-index.component.scss']
})
export class PromoIndexComponent extends ListingComponent<IServicePromotion> implements OnInit, OnChanges, AfterViewInit {

  loading$ = this.loader.loading$;
  dataLoaded = false;

  @Input('advertiserID')
  advertiserID: number = null

  statusColour(servicePromotion: IServicePromotion) {
    const startDate = moment(servicePromotion.startDate)
    const endDate = moment(servicePromotion.endDate)
    return endDate.isBefore(moment()) || servicePromotion.service.active==0 || servicePromotion.status==0 || !servicePromotion.active?'red':moment().isBefore(startDate)?'grey':'green';
  }

  constructor(
    private readonly salonFinderPromoService: SalonFinderPromoService,
    private readonly router: Router,
    public readonly activatedRoute: ActivatedRoute,
    private readonly snackBar: MatSnackBar,
    private readonly cdr: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    public loader: LoadingService
  ) {
    super();
    this.makeListingCols()
  }

  makeListingCols() {
    this.listingCols = !this.advertiserID?[
      'statusID',
      'advertiser',
      'Promo Name',
      'Price',
      'Date',
      'Purchases',
      'Action'
    ]: [
      'statusID',
      'Promo Name',
      'Price',
      'Date',
      'Purchases',
      'Action'
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.advertiserID && changes.advertiserID.currentValue!==undefined) {
      if (changes.advertiserID.currentValue.toString() !== this.advertiserID.toString()) {
        this.defaultParams = {
          advertiserID: this.advertiserID?.toString()
        }
        this.populateList()
        this.makeListingCols()
      }
    }
  }

  populateList(params?: HttpParams | Params): void {
    let listParams = this.makeSearchParams()
    if(this.advertiserID) {
      listParams = this.makeSearchParams({
        advertiserID: this.advertiserID
      })
    }
    this.salonFinderPromoService
      .list(listParams)
      .subscribe((data) => {

        this.initCommonListingResponse(data)
      });
  }

  getExtraListingParams() {
    throw new Error('Method not implemented.');
  }

  deleteItem(item: IServicePromotion): void {
    throw new Error('Method not implemented.');
  }

  deactivate(data) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Deactivate Promo',
        message: 'Are you sure you want to deactivate this promo:' + data.name
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result === true) {
        this.handleActivate(data);
      }
    });
  }

  ngOnInit() {
    if(this.activatedRoute.snapshot.params.id) {
      this.advertiserID = this.activatedRoute.snapshot.params.id;
    }

    this.populateList();
    this.makeListingCols()
  }

  filterData($event: any) {
    this.dataSource.filter = $event.target.value;
  }

  async handleActivate(promoData) {
    // console.log(promoData);
    const selectedPromo = promoData;
    const status = selectedPromo.active ? 0 : 1;
    this.salonFinderPromoService.activatePromo({id: selectedPromo.id, status, woo_id: selectedPromo.wpPromoID})
    .subscribe((data: any) => {
      if (data.active === 1) {
        this.snackBar.open('Promo has been activated successfully!', 'Close', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      } if (data.active === 0) {
        this.snackBar.open('Promo has been deactivated successfully!', 'Close', {
          duration: 2000,
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }

      this.populateList();

      // this.router.navigateByUrl('/salon-finder/advertisers/update/' + this.advertiserID, {state: {tab: 'promotion'}})
    });
  }
}

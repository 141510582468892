<div class="row mb-3">
  <div class="col-12 text-right">
    <button mat-raised-button color="primary" [routerLink]="['/salon-finder/advertisers/services/add/',advertiserID]">Add New Service</button>
    <button mat-raised-button [disabled]="syncingServices.length>0" (click)="resyncAllServices()">Resync all advertiser Service</button>
  </div>
</div>
<div class="row mb-3">
  <mat-form-field class="fullWidth col-12" appearance="fill">
    <input type="text"
    placeholder="Type anything to search service"
    aria-label="Type anything to search service"
    matInput
    appearance="fill"
    autocomplete="off" 
    #searchTextInput>
  </mat-form-field>
</div>
<div class="row">
  <div class="col-12">
    <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
      <ng-container matColumnDef="Advertiser">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Advertiser</th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'deactivated':element.active===0}"> 
            {{ element.advertiser.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Service Name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-center">Service Name</th>
        <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{'deactivated':element.active===0}"> 
          <p *ngIf="element.name != ''">
            {{element.name}}
          </p>
        </td>
      </ng-container>
      <ng-container matColumnDef="Price">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-center">Price</th>
        <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{'deactivated':element.active===0}"> 
          <p *ngIf="element.price" [ngClass]="{'new-price':element.new_price}">
            {{element.price|currency}}
          </p>
          <p *ngIf="element.new_price">
            {{element.new_price|currency}}
          </p>
        </td>
      </ng-container>
      <ng-container matColumnDef="Outlets">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-center" class="text-center">Outlets</th>
        <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{'deactivated':element.active===0}"> 
          <p *ngIf="element.outlets?.length>0; else elseBlock">
            {{element.outlets.length}}
          </p>
          <ng-template #elseBlock>0</ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="Types">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-center">Types</th>
        <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{'deactivated':element.active===0}"> 
          <div *ngIf="element.__type">
            <p>{{element.__type + ' : ' + element.__subType}}</p>
            <p>{{'Duration : ' + element.duration}}</p>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="Promotions">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-center">Promotions</th>
        <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{'deactivated':element.active===0}">
          <div *ngIf="hasPromotions(element.promotions); else elseBlock">
            <p>Until: {{ currentPromotions(element.promotions)?.endDate | date:'d MMMM y' }}</p>
          </div>
          <ng-template #elseBlock>No</ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="Purchased">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-center">Purchased</th>
        <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{'deactivated':element.active===0}"> 
          <div *ngIf="element.pNum || element.rNum; else elseBlock">
            <p *ngIf="element.pNum">{{element.pNum}}</p>
            <p *ngIf="element.rNum">{{element.rNum + " redeemed"}}</p>
          </div>
          <ng-template #elseBlock>-</ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="Status">
        <th mat-header-cell mat-sort-header *matHeaderCellDef  class="text-center">Status</th>
        <td mat-cell *matCellDef="let element" class="text-center" [ngClass]="{'deactivated':element.active===0}"> 
          <p *ngIf="element.active === 0">
            Inactive
          </p>
          <p *ngIf="element.preview === 1">
            Preview
          </p>
          <p *ngIf="element.active === 1 && element.preview === 0">
            Active
          </p>
          <p *ngIf="element.active === 2">
            Pending
          </p>
          <p *ngIf="element.active === 5">
            Error
          </p>
        </td>
      </ng-container>
      <ng-container matColumnDef="Action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element;">
          <span *ngIf="element.active<5 && element.active===2;">
            Pending
          </span>
          <span *ngIf="element.active===1;">
            <a mat-icon-button color="primary" [disabled]="isSync(element.id)" (click)="resyncProduct([element])">
              <mat-icon>autorenew</mat-icon>
            </a>
          </span>
          <span *ngIf="element.active<5 && element.active!==2;">
            <a mat-icon-button color="primary" routerLink="/salon-finder/services/update/{{element.id}}">
            <mat-icon matTooltip="Edit" matTooltipPosition="left">edit</mat-icon>
            </a>
            <a mat-icon-button color="warn" (click)="deactivate(element)" [hidden]="element.active===0">
            <mat-icon matTooltip="Deactivate" matTooltipPosition="right">remove_circle</mat-icon>
            </a>
            <a mat-icon-button color="warn" (click)="handleActivate(element)" [hidden]="element.active===1">
            <mat-icon matTooltip="Activate" matTooltipPosition="right" class="color-green">check_circle</mat-icon>
            </a>
            <mat-slide-toggle class="example-margin" [(ngModel)]="element.preview" (change)="handlePreview(element)">
            </mat-slide-toggle>
          </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="listingCols" class="p-2"></tr>
      <tr mat-row *matRowDef="let row; columns: listingCols;" class="p-2"></tr>
    </table>
  </div>
</div>
<div class="row mb-4">
  <div class="col-12">
    <mat-paginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" [pageIndex]="page"
      (page)="changePage($event)">
    </mat-paginator>
  </div>
</div>

<!-- <ng-container *ngIf="loading$ | async">
  <div class="overlay"></div>
  <mat-progress-spinner [mode]="'indeterminate'" [diameter]="50"></mat-progress-spinner>
</ng-container> -->
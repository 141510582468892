import { HttpParams } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { count, map } from 'rxjs/operators';
import { IBrand } from '../../interfaces/ibrand';
import { IBrandCategory } from '../../interfaces/ibrand-category';
import { IInfluencer } from '../../interfaces/iinfluencer';
import { IOption } from '../../interfaces/ioption';
import { IOptionCountryList } from '../../interfaces/ioption-country-list';
import { IOptionGender } from '../../interfaces/ioption-gender';
import { IOptionSurveyCountry } from '../../interfaces/ioption-survey-country';
import { ConsumerReportBrandCategoryService } from '../../services/consumer-report-brand-category.service';
import { ConsumerReportBrandsService } from '../../services/consumer-report-brands.service';
import { ConsumerReportInfluencerService } from '../../services/consumer-report-influencer.service';
import { ConsumerReportOptionService } from '../../services/consumer-report-option.service';

@Component({
  selector: 'brands-form',
  templateUrl: './brands-form.component.html',
  styleUrls: ['./brands-form.component.scss']
})
export class BrandsFormComponent implements OnInit, OnChanges {

  surveyCountry$: Observable<IOptionSurveyCountry[]>
  countryList$: Observable<IOptionCountryList[]>
  genderList$: Observable<IOptionGender[]>

  @Input('brandsForm') loadedModel: IBrand;

  formData: FormGroup

  savingForm: boolean = false

  _countryList: IOptionCountryList[]

  brandCategory: IBrandCategory[];

  surveyServingCountry = [
    {name: "SG", id: 1},
    {name: "MY", id: 2}
  ]

  get formErrors() { 
    return this.formData.controls 
  }
  
  constructor(
    private readonly fb: FormBuilder,
    private readonly consumerReportOptionService: ConsumerReportOptionService,
    private readonly snackBar: MatSnackBar,
    private readonly consumerReportBrandsService: ConsumerReportBrandsService,
    private readonly consumerReportBrandCategoryService: ConsumerReportBrandCategoryService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loadedModel) {      
      this.initForm();
    }
  }

  

  ngOnInit(): void {
    this.genderList$ = this.consumerReportOptionService.findByType<IOptionGender[]>('genderControlType')
    this.countryList$ = this.consumerReportOptionService.findByType<IOptionCountryList[]>('countryList')
    this.surveyCountry$ = this.consumerReportOptionService.findByType<IOptionSurveyCountry[]>('surveyCountry')
    this.consumerReportBrandCategoryService.list()
      .subscribe(d=> {
        this.brandCategory = d.data
      })
      
    this.initForm();
  }

  countryChange($event) {
    const country = this._countryList.find(c=>c.country === $event.value)
    if(country) {
      this.formData.patchValue({
        countryZone: country.countryZone
      })
    }
    this.formData.updateValueAndValidity()
  }

  updateBrandCategory(formValue: IBrand) {    
    return this.brandCategory.filter(b=> {
      let keyName = b.name
      if(b.name == 'salon-based') {
        keyName = 'salonBased'
      }
      if(b.name == 'direct-sales') {
        keyName = 'directSales'
      }
      if(b.name == 'drugstore') {
        keyName = 'drugStore'
      }
      
      if(b.name == 'celebrity') {
        keyName = 'celebrity'
      }
      return formValue[keyName]
    })
  }

  save() {
    const value = this.formData.value
    this.savingForm = true
    value.brandCategory = this.updateBrandCategory(value)
    
    this.consumerReportBrandsService.save(value, value.id)
      .subscribe(
        d => {
          this.savingForm = false;
          this.loadedModel = d;
          this.snackBar.open('Brand has been saved SUCCESSFULLY!', 'Close', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
          if(value.id)
            this.initForm();
          else {            
            this.router.navigate([`/consumer-report/brand/update`, d.id])
          }
        },
        err => {
          this.savingForm = false;
          this.snackBar.open('Brand has been saved FAILED!', 'Close', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
          this.loadedModel = value
          this.initForm();
        }
      );
  }

  initForm() {
    this.formData = this.consumerReportBrandsService.makeFormGroup(this.loadedModel)
  }
}

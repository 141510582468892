<form [formGroup]="formData">
	<div class="container">
		<mat-card>
			<mat-card-header>
				<mat-card-title class="pt-3">
					<span [hidden]="loadedModel?.id">Add Publisher</span>
					<span [hidden]="!loadedModel?.id">Update Publisher - {{ loadedModel?.name }}</span>
				</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<div class="row">
					<mat-form-field class="col-12 col-sm-6 col-lg-6" appearance="fill">
						<mat-label>Name</mat-label>
						<input matInput maxlength="120" placeholder="Name" formControlName="name" />
						<mat-error>
							<span *ngIf="formData.get('name').hasError('required')">Collection Name is
								<strong>required</strong></span>
						</mat-error>
					</mat-form-field>
					<mat-form-field class="col-12 col-sm-6 col-lg-6" appearance="fill">
						<mat-label>URL</mat-label>
						<input matInput maxlength="120" placeholder="Ads hosting domain" formControlName="url" />
						<mat-error>
							<span *ngIf="formData.get('url').hasError('required')">Campaign Name is <strong>required</strong></span>
						</mat-error>
					</mat-form-field>
					<mat-form-field class="col-12 col-sm-6 col-lg-6" appearance="fill">
						<mat-label>Status</mat-label>
						<mat-select formControlName="status" [compareWith]="compareService">
							<mat-option *ngFor="let status of publisherStatus$ | async" [value]="status">
								{{status.value}}
							</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field class="col-12 col-sm-6 col-lg-6" appearance="fill">
						<mat-label>Client Token</mat-label>
						<input matInput maxlength="120" placeholder="Client Token (autogenerated)" formControlName="clientID" [readonly]="true" />
					</mat-form-field>
					<div class="col-12  col-sm-6 col-lg-6 imageWrapper position-relative">
						<img width="100%" ckla src="{{loadedModel.imageURL}}" *ngIf="loadedModel?.imageURL" />
						<div class="position-absolute bottom-0 end-0">
						  <app-shared-upload-button 
							[uploadBucket]="uploadBucket"
							[fileNamePrefix]="fileNamePrefix"
							[uploadPath]="uploadPath"
							(uploadedCompletes)="uploadedFiles($event)"
							[multiUpload]="false"
							[allowExtension]="allowUploadType"
						  ></app-shared-upload-button>
						</div>
					  </div>

					<div class="col-12 mt-5">
						<mat-slide-toggle class="ml-4" formControlName="defaultSelect">
							Default selected
						</mat-slide-toggle>
					</div> 
				</div>
			</mat-card-content>
			<mat-card-actions>
				<div class="row">
					<div class="col-12 text-center">
						<button mat-raised-button color="primary" class="m-2" (click)="save()"
							[disabled]="formData.status != 'VALID' || savingForm">
							Save
						</button>
						<button mat-raised-button class="m-2" [routerLink]="['/native-ads/publisher']">
							Cancel
						</button>
					</div>
				</div>
			</mat-card-actions>
		</mat-card>
	</div>
</form>
<div class="row text-center mb-4" *ngIf="isLoading">
    <div class="loading-container">
      <mat-spinner diameter="50" color="accent"> </mat-spinner>
  </div>
  <br>
  <mat-label>Loading ...</mat-label>
</div>

<ng-container *ngIf="!isLoading">
    <div class="row mb-3">
      <div class="col-12 text-right">
        <button mat-raised-button color="primary" [routerLink]="['/consumer-report/reports-group/create']">Add New Groups</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
  
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element;">
              {{element.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="countryOfOrigin">
            <th mat-header-cell *matHeaderCellDef> Country</th>
            <td mat-cell *matCellDef="let element;">
              {{element.countryOfOrigin | uppercase }}
            </td>
          </ng-container>
          
  
          <ng-container matColumnDef="reportConfigs">
            <th mat-header-cell *matHeaderCellDef> Report Configs </th>
            <td mat-cell *matCellDef="let element;"> 
              <ng-container *ngFor="let config of element.reportConfigsDTO">
                <div>{{ config.configName}}</div>
            </ng-container>
            </td>
          </ng-container>
  
          <ng-container matColumnDef="createdDate">
            <th mat-header-cell *matHeaderCellDef> Created Date </th>
            <td mat-cell *matCellDef="let element;">
                  {{ element.createdDate | dateTimeFromIso | dateTimeToLocal |
                    dateTimeToFormat:'dd LLL yyyy' }}
            </td>
          </ng-container>
  
  
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element;">
              <a mat-icon-button color="primary" routerLink="/consumer-report/reports-group/update/{{element.id}}">
                <mat-icon>edit</mat-icon>
              </a>
              <a mat-icon-button color="primary" (click)="disableReportConfigGroup(element.id)">
                <mat-icon>delete</mat-icon>
              </a>
            </td> 
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns" class="p-2"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="p-2"></tr>
        </table>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [pageIndex]="page"
          (page)="changePage($event)">
        </mat-paginator>
      </div>
    </div>

  </ng-container>  

<div class="row mb-3">
  <div class="col-12 text-right">
    <button mat-raised-button color="primary" [routerLink]="['/consumer-report/brand/create']">Add New Brand</button>
  </div>
</div>
<div class="row mb-3" [formGroup]="searchForm">
  <div class="col-12">
    <mat-card>
      <mat-card-content>
        <div class="row">
          <div class="col-12">
            <mat-form-field class="fullWidth" appearance="fill">
              <input type="text" placeholder="Type anything to search store" aria-label="Type anything to search store"
                matInput appearance="fill" [formControl]='searchTextFormControl'>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field class="fullWidth" appearance="fill">
              <mat-label>Category</mat-label>
              <mat-select multiple formControlName="brandCategory" [compareWith]="compareID">
                <mat-option *ngFor="let category of categoryList$ | async" [value]="category">{{category.value}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field class="fullWidth" appearance="fill">
              <mat-label>Gender</mat-label>
              <mat-select multiple formControlName="gender" [compareWith]="compareGender">
                <mat-option *ngFor="let gender of genderList$ | async" [value]="gender">{{gender.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field class="fullWidth" appearance="fill">
              <mat-label>Survey Country</mat-label>
              <mat-select multiple formControlName="servingCountry" [compareWith]="compareServingCountry">
                <mat-option *ngFor="let country of surveyServingCountry" [value]="country">{{country.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-3">
            <mat-form-field class="fullWidth" appearance="fill">
              <mat-label>Country</mat-label>
              <mat-select multiple formControlName="country" [compareWith]="compareCountry">
                <mat-option *ngFor="let country of countryList$ | async" [value]="country">{{country.country}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div class="row">
  <div class="col-12 export-container">
    <button mat-raised-button color="primary" (click)="exportExcel()" >Export Brands</button>
  </div>
  <div class="col-12">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element">
          {{ element.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="country">
        <th mat-header-cell *matHeaderCellDef> Country </th>
        <td mat-cell *matCellDef="let element;"> {{ element.country }} </td>
      </ng-container>

      <ng-container matColumnDef="surveyCountry">
        <th mat-header-cell *matHeaderCellDef> Survey Country </th>
        <td mat-cell *matCellDef="let element;"> {{ element.originatedISOCountryCode }} </td>
      </ng-container>

      <ng-container matColumnDef="gender">
        <th mat-header-cell *matHeaderCellDef> Gender </th>
        <td mat-cell *matCellDef="let element;">
          <div *ngIf="element.gender == 0; else elseBlock"><span style="color:hotpink;">
              <mat-icon>female</mat-icon>
            </span></div>
          <ng-template #elseBlock><span style="color:blue;">
              <mat-icon>male</mat-icon>
            </span></ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="haircare">
        <th mat-header-cell *matHeaderCellDef> Haircare </th>
        <td mat-cell *matCellDef="let element;">
          <div *ngIf="hasCategory(element.brandCategory, 'haircare'); else elseBlock"><span style="color:green;">
              <mat-icon>done</mat-icon>
            </span></div>
          <ng-template #elseBlock><span style="color:red;">
              <mat-icon>close</mat-icon>
            </span></ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="skincare">
        <th mat-header-cell *matHeaderCellDef> Skincare </th>
        <td mat-cell *matCellDef="let element;">
          <div *ngIf="hasCategory(element.brandCategory, 'skincare'); else elseBlock"><span style="color:green;">
              <mat-icon>done</mat-icon>
            </span></div>
          <ng-template #elseBlock><span style="color:red;">
              <mat-icon>close</mat-icon>
            </span></ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="makeup">
        <th mat-header-cell *matHeaderCellDef> Makeup </th>
        <td mat-cell *matCellDef="let element;">
          <div *ngIf="hasCategory(element.brandCategory, 'makeup'); else elseBlock"><span style="color:green;">
              <mat-icon>done</mat-icon>
            </span></div>
          <ng-template #elseBlock><span style="color:red;">
              <mat-icon>close</mat-icon>
            </span></ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="derma">
        <th mat-header-cell *matHeaderCellDef> Derma </th>
        <td mat-cell *matCellDef="let element;">
          <div *ngIf="hasCategory(element.brandCategory, 'derma'); else elseBlock"><span style="color:green;">
              <mat-icon>done</mat-icon>
            </span></div>
          <ng-template #elseBlock><span style="color:red;">
              <mat-icon>close</mat-icon>
            </span></ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="directSales">
        <th mat-header-cell *matHeaderCellDef> Direct sales </th>
        <td mat-cell *matCellDef="let element;">
          <div *ngIf="hasCategory(element.brandCategory, 'direct-sales'); else elseBlock"><span style="color:green;">
              <mat-icon>done</mat-icon>
            </span></div>
          <ng-template #elseBlock><span style="color:red;">
              <mat-icon>close</mat-icon>
            </span></ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="drugStore">
        <th mat-header-cell *matHeaderCellDef> Drugstore </th>
        <td mat-cell *matCellDef="let element;">
          <div *ngIf="hasCategory(element.brandCategory, 'drugstore'); else elseBlock"><span style="color:green;">
              <mat-icon>done</mat-icon>
            </span></div>
          <ng-template #elseBlock><span style="color:red;">
              <mat-icon>close</mat-icon>
            </span></ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="salonBased">
        <th mat-header-cell *matHeaderCellDef> Salon-based </th>
        <td mat-cell *matCellDef="let element;">
          <div *ngIf="hasCategory(element.brandCategory, 'salon-based'); else elseBlock"><span style="color:green;">
              <mat-icon>done</mat-icon>
            </span></div>
          <ng-template #elseBlock><span style="color:red;">
              <mat-icon>close</mat-icon>
            </span></ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="celebrity">
        <th mat-header-cell *matHeaderCellDef> Celebrity </th>
        <td mat-cell *matCellDef="let element;">
          <div *ngIf="hasCategory(element.brandCategory, 'celebrity'); else elseBlock"><span style="color:green;">
              <mat-icon>done</mat-icon>
            </span></div>
          <ng-template #elseBlock><span style="color:red;">
              <mat-icon>close</mat-icon>
            </span></ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element;">
          <a mat-icon-button color="primary" routerLink="/consumer-report/brand/update/{{element.id}}">
            <mat-icon>edit</mat-icon>
          </a>
          <a mat-icon-button color="warn" (click)="deleteItem(element)">
            <mat-icon>remove_circle</mat-icon>
          </a>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="brandCols" class="p-2"></tr>
      <tr mat-row *matRowDef="let row; columns: brandCols;" class="p-2"></tr>
    </table>
  </div>
</div>
<div class="row mb-4">
  <div class="col-12">
    <mat-paginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
      (page)="changePage($event)">
    </mat-paginator>
  </div>
</div>
<h2 mat-dialog-title>Export File</h2>
<mat-dialog-content class="mat-typography">
  <div *ngIf="!loadingForms">
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="fill" class="form-list">
          <mat-label>Select Form</mat-label>
          <mat-select [(value)]="selectedForm">
            <mat-option *ngFor="let form of forms" [value]="form.id">
              {{form.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button [disabled]="loadingForms" mat-button (click)="generate()" cdkFocusInitial>
    <span *ngIf="!generateReport">Export</span> 
    <mat-spinner *ngIf="generateReport" [diameter]="30"></mat-spinner> 
  </button>
</mat-dialog-actions>

import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroupDirective, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import { ErrorStateMatcher } from '@angular/material/core';
import { SalonFinderCollectionService } from '../../services/salon-finder-collection.service';
import { ICollection } from '../../interfaces/ICollection';

/** Error when invalid control is dirty, touched, or submitted. */
// export class MyErrorStateMatcher implements ErrorStateMatcher {
//   isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
//     const isSubmitted = form && form.submitted;
//     return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
//   }
// }

@Component({
  selector: 'app-collection-update',
  templateUrl: './collection-update.component.html',
  styleUrls: ['./collection-update.component.scss']
})

export class CollectionUpdateComponent implements OnInit {
  _serviceTotal = 0;

  collectionForm: ICollection;
  id =  '';
  name = '';
  startDate = '';
  endDate = '';
  slug = '';
  pageTitle = '';
  metaDescription = '';
  featured = '';
  backgroundImage = '';
  status = '';
  active = '';
  dataLoaded = false;
  savingForm = false;
  allStatus = ['Draft', 'Running'];
  // matcher = new MyErrorStateMatcher();

  loadingCollectionID = 0;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,   
    private router: Router, 
    private readonly snackBar: MatSnackBar,
    private readonly salonFinderCollectionService: SalonFinderCollectionService,
  ) { 
  }

  get serviceTotal(): number {
    return this._serviceTotal;
  }

  setServiceTotal(t: number): void {
    this._serviceTotal = t;
  }

  ngOnInit(): void {
    this.loadingCollectionID = this.activatedRoute.snapshot.params.id;
    this.salonFinderCollectionService.get(this.activatedRoute.snapshot.params.id)
      .subscribe(d=>this.collectionForm = d)

    // console.log(this.activatedRoute.snapshot.params.id);
    // this.getCollectionById(this.activatedRoute.snapshot.params.id);

    // this.editCollectionForm = this.fb.group({
    //   name : ['', Validators.required],
    //   startDate : ['', Validators.required],
    //   endDate : ['', Validators.required],
    //   featured : [''],
    //   backgroundImage : [''],
    //   status : ['', Validators.required],
    //   active : [''],
    // });
  }
  
  // getCollectionById(id: any) {
  //   this.dataLoaded = true;
  //   this.salonFinderCollectionService.get(id).subscribe((data: any) => {
  //     this.id = data.id;
  //     this.editCollectionForm.controls['name'].setValue(data.name);
  //     this.editCollectionForm.controls['startDate'].setValue(data.startDate);
  //     this.editCollectionForm.controls['endDate'].setValue(data.endDate);
  //     this.editCollectionForm.controls['featured'].setValue(data.featured);
  //     this.editCollectionForm.controls['backgroundImage'].setValue(data.backgroundImage);
  //     this.editCollectionForm.controls['status'].setValue(data.status);
  //     this.editCollectionForm.controls['active'].setValue(data.active);
  //   });
  // }

  // save() {
  //   const value = this.editCollectionForm.value
  //   // console.log(this.editCollectionForm.value);
  //   this.savingForm = true;

  //   this.salonFinderCollectionService.updateData(this.id, value)
  //   .subscribe(
  //     response => {
  //       this.savingForm = false;
  //       // console.log(response);
  //       this.snackBar.open('Collection has been updated successfully!', 'Close', {
  //         duration: 2000,
  //         horizontalPosition: 'center',
  //         verticalPosition: 'top'
  //       });
  //       this.router.navigate(['salon-finder/collections']);
  //     },
  //     error => {
  //       this.savingForm = false;
  //       this.snackBar.open('Unable to update collection!', 'Close', {
  //         duration: 2000,
  //         horizontalPosition: 'center',
  //         verticalPosition: 'top'
  //       });
  //     }
  //   );
  // }
}
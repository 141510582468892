import { Component, Output, OnInit, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import {utc} from 'moment';
import { DatePipe } from '@angular/common';
import { LoadingService } from '../../services/loading.service';
import { IRedeemedVoucher } from '../../interfaces/iredeemed-voucher';
import { SalonFinderFinanceService } from '../../services/salon-finder-finance.service';
import { IOrderVoucher } from '../../interfaces/IOrderVoucher';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { SalonFinderOrderVoucherService } from '../../services/salon-finder-order-voucher.service';
import { DateTime } from 'luxon';
import { MatDialog } from '@angular/material/dialog';
import { OrderVoucherSendEmailDialogComponent } from '../../components/order-voucher-send-email-dialog/order-voucher-send-email-dialog.component';
import { OrderVoucherExtendExpiryDateDialogComponent } from '../../components/order-voucher-extend-expiry-date-dialog/order-voucher-extend-expiry-date-dialog.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { OrderVoucherRedeemDialogComponent } from '../../components/order-voucher-redeem-dialog/order-voucher-redeem-dialog.component';
import { debounceTime, filter, startWith } from 'rxjs/operators';
import moment from 'moment';
import { DateFormat } from 'src/app/shared/helpers/DateFormat';
import { MAT_DATE_FORMATS } from '@angular/material/core';

@Component({
  selector: 'salon-finder-finance-purchases-index',
  templateUrl: './finance-purchases-index.component.html',
  styleUrls: ['./finance-purchases-index.component.scss'],
  providers: [   
    {provide: MAT_DATE_FORMATS, useValue: DateFormat},
  ],
})
export class FinancePurchasesIndexComponent extends ListingComponent<IOrderVoucher> implements OnInit {
  searchForm: FormGroup;
  finances: [];
  allFinances: [];
  searchWord: '';
  searchOption: { value: 'voucher', label: 'Voucher Code' };
  totalGMV = 0;
  totalSfRev = 0;
  startdate: Date;
  enddate: Date;
  isLoading: false;
  isRefresh: false;
  invalidItems: [];
  dataSource!: MatTableDataSource<any>;
  ended: string;
  title: string;
  message: string;
  loading$ = this.loader.loading$;
  pipe = new DatePipe('en-SG');


  @Output() newItemEvent = new EventEmitter<IRedeemedVoucher>();

  constructor(
    private readonly fb: FormBuilder,
    private readonly salonFinderFinanceService: SalonFinderFinanceService,
    private readonly salonFinderOrderVoucherService: SalonFinderOrderVoucherService,
    private cdr: ChangeDetectorRef,
    public loader: LoadingService,
    public dialog: MatDialog
  ) {
    super();
    this.listingCols = [
      'Date Purchased',
      'Merchant',
      'Service',
      'GMV',
      'DV COMM',
      'DV Pays Merchant',
      'Discount',
      'SF Rev',
      'Redemption Status',
      'Actions'
    ];
  }

  populateList<U>(params?: U): void {
    const searchText = this.searchForm?.value?.searchText || "";
    let searchParams = this.makeSearchParams({})
    if(searchText) {
      searchParams = this.makeSearchParams({
        searchText: searchText
      })
    } else {
      searchParams = this.makeSearchParams({
        startDate: moment(this.searchForm.value.startDate).toISOString(true),
        endDate: moment(this.searchForm.value.endDate).endOf('d').toISOString(true),
      })
    }
    this.salonFinderOrderVoucherService.list(searchParams)
      .subscribe(d => this.initCommonListingResponse(d));
    this.getStats();
  }

  getExtraListingParams() {
    throw new Error('Method not implemented.');
  }

  deleteItem(item: IOrderVoucher): void {
    throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    this.initForm();
    this.populateList();
    this.searchForm.get('searchText').valueChanges
      .pipe(
        startWith(''),
        debounceTime(500),
        filter<string>(v => v.length >= 3 || v.length === 0)
      )
      .subscribe(value => {
        this.page = 0;
        this.populateList();
      });
  }

  filterData($event: any): void {
    this.dataSource.filter = $event.target.value;
  }

  getStats(): void {
    const data = this.searchForm.value;
    this.salonFinderOrderVoucherService.getListStats(this.makeSearchParams({
      ...data,
      startDate: moment(data.startDate).toISOString(true),
      endDate: moment(data.endDate).endOf('d').toISOString(true)
    }))
      .subscribe(d => {
        this.totalGMV = d.amount;
        this.totalSfRev = d.revenue;
      });
  }

  getMerchantAmount(orderVoucher: IOrderVoucher): number {
    return orderVoucher.order.pricePerUnit - this.getDVCommission(orderVoucher);
  }

  getDVCommission(orderVoucher: IOrderVoucher) {
    return +orderVoucher.commission / 100 * +orderVoucher.order.pricePerUnit;
  }

  fetchFinancesOnDate() {
    const values = this.searchForm.value;
    if (values.startDate && values.endDate) {
      this.searchForm.patchValue({
        searchText: ""
      }, { emitEvent: false })
      this.populateList();
    }
  }

  initForm() {
    const today = moment();
    this.searchForm = this.fb.group({
      startDate: this.fb.control(today.clone().add(-2, 'month').startOf('day').startOf('month')),
      endDate: this.fb.control(today.clone().endOf('day').endOf('month')),
      searchText: [''],
      searchType: ['']
    });
  }
  extendVoucher(model: IOrderVoucher) {
    this.dialog.open(OrderVoucherExtendExpiryDateDialogComponent, {
      width: '500px',
      data: model,
    }).afterClosed().subscribe(reload => {
      if (reload) {
        this.populateList();
      }
    });
  }
  unCancelVoucher(model: IOrderVoucher) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {
        title: 'Confirm to cancel voucher?',
        message: 'Yes to cancel voucher, No to escape'
      },
    });
    confirmDialog.afterClosed().subscribe(deleting => {
      if (deleting) {
        this.salonFinderOrderVoucherService
          .uncancelVoucher(model)
          .subscribe(d => this.populateList());
      }
    });
  }

  cancelVoucher(model: IOrderVoucher) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      width: '500px',
      data: {
        title: 'Confirm to cancel voucher?',
        message: 'Yes to cancel voucher, No to escape'
      },
    });
    confirmDialog.afterClosed().subscribe(deleting => {
      if (deleting) {
        model.updatedOn = 'darvis';
        model.updatedBy = 0;
        this.salonFinderOrderVoucherService
          .cancelVoucher(model)
          .subscribe(d => this.populateList());
      }
    });
  }

  emailVoucher(model: IOrderVoucher) {
    this.dialog.open(OrderVoucherSendEmailDialogComponent, {
      width: '500px',
      data: model,
    }).afterClosed().subscribe(reload => {
      if (reload) {
        this.populateList();
      }
    });
  }

  redeemVoucher(model: IOrderVoucher) {
    this.dialog.open(OrderVoucherRedeemDialogComponent, {
      width: '500px',
      data: model,
    }).afterClosed().subscribe(reload => {
      if (reload) {
        this.populateList();
      }
    });
  }
}

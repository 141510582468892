import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import { UrlDetails } from 'src/app/url-shorterner/interfaces/IUrlDetails';
import { UrlShortenerCollectionService } from 'src/app/url-shorterner/services/url-shortener-collection.service';
import { IFollower } from '../../interfaces/IFollower';
import { TelegramCollectionService } from '../../services/telegram-collection.service';
import { MatPaginator } from '@angular/material/paginator';
import { GenerateFollowerDateList, GetMonthsInRange, GetWeeksInRange } from '../../helpers/follower/followerUtils';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { DateFormat } from 'src/app/shared/helpers/DateFormat';
import { filter } from 'rxjs';

interface Period {
  value: string;
  viewValue: string;
}

export enum ToggleEnumRange {
  Daily,
  Weekly,
  Monthly
}

export interface FollowersData {
  addedDate: string;
  totalFollowers: number;
  joined: number;
  left: number;
  net: number;
}

@Component({
  selector: 'app-follower-index',
  templateUrl: './follower-index.component.html',
  styleUrls: ['./follower-index.component.scss'],
  providers: [   
    {provide: MAT_DATE_FORMATS, useValue: DateFormat},
  ],
})
export class FollowerIndexComponent implements OnInit, AfterViewInit {

  displayedColumns: string[] = ['addedDate', 'totalFollowers', 'joined', 'left', 'net'];
  dataSource: MatTableDataSource<FollowersData>;

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  generatingChart = false;
  periodLabel = [];
  periodTotalJoined = [];
  periodTotalLeft = [];

  urlDetails: UrlDetails;
  urlInfo: any;
  returnFilter: any;
  maxValue = 10;

  periods: Period[] = [
    { value: 'TODAY', viewValue: 'Today' },
    { value: 'WEEK', viewValue: 'Past 7 Days' },
    { value: 'MONTH', viewValue: 'Past 30 Days' },
    { value: 'QUARTER', viewValue: 'Past 3 Months' },
    { value: 'LIFETIME', viewValue: 'Lifetime' },
  ];
  toggleEnumRange = ToggleEnumRange;
  selectedRange = ToggleEnumRange.Daily;
  selected = 'QUARTER';

  rangeFrom = new FormGroup({
    startFrom: new FormControl<Date | null>(null),
    endFrom: new FormControl<Date | null>(null),
  })

  rangeTo = new FormGroup({
    startTo: new FormControl<Date | null>(null),
    endTo: new FormControl<Date | null>(null),
  })

  public lineChartData: ChartConfiguration<'line'>['data'] = {
    labels: this.periodLabel,
    datasets: [
      {
        data: [],
        label: 'Joined',
      },
      {
        data: [],
        label: 'Left',
      },
    ]
  };
  public lineChartOptions: ChartOptions<'line'> = {
    responsive: true,
    scales: {
      y: {
        min: 0,
        max: this.maxValue
      },
    },
    elements: {
      line: {
        tension: 0 // disables bezier curves
      }
    }

  };

  public lineChartLegend = true;

  totalJoinedRange: number = 0;
  totalJoinedDateRange: string[] = [];

  totalLeftRange: number = 0;
  totalLeftDateRange: string[] = [];

  comparisonFrom: any;
  comparisonTo: any;

  joinedComparisonPercentage: any;
  leftComparisonPercentage: any;
  netComparisonPercentage: any;

  totalJoinedFromRange: number = 0;
  totalJoinedFromDateRange: string[] = [];

  totalLeftFromRange: number = 0;
  totalLeftFromDateRange: string[] = [];

  totalJoinedToRange: number = 0;
  totalJoinedToDateRange: string[] = [];

  totalLeftToRange: number = 0;
  totalLeftToDateRange: string[] = [];

  tableSourceOfJoinedNWorst: any[] = [];

  rangeStart: string = '';
  rangeEnd: string = '';

  range: any;

  rangeStartFrom: string = '';
  rangeEndFrom: string = '';

  rangeStartTo: string = '';
  rangeEndTo: string = '';

  highestJoined = -Infinity;
  highestLeft = -Infinity;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly telegramCollectionService: TelegramCollectionService,
    private readonly router: Router,
    private activatedRoute: ActivatedRoute,
  ) {

    if(this.activatedRoute.snapshot.queryParams && this.activatedRoute.snapshot.queryParams.start !== undefined){
      this.rangeStart = this.activatedRoute.snapshot.queryParams.start.trim();
    } 
    if(this.activatedRoute.snapshot.queryParams && this.activatedRoute.snapshot.queryParams.end !== undefined){
      this.rangeEnd = this.activatedRoute.snapshot.queryParams.end.trim();
    }

    router.events.pipe(
      filter(event => event instanceof NavigationEnd)  
    ).subscribe((event) => {

      if((event as NavigationEnd).url === '/telegram/follower'){
        let endDate: any;
        let daysAgo: any;
    
        endDate = moment().format('MMM DD YYYY')
        daysAgo = moment().subtract(5, 'days').toDate();
    
        endDate = moment(endDate).format('MMM DD YYYY')
    
        let startDate: any = moment(daysAgo).format('MMM DD YYYY');
    
        this.rangeStart = startDate;
        this.rangeEnd = endDate;
        this.router.navigate(['/telegram/follower'],{queryParams:{start:moment(this.rangeStart).format('DD-MMM-YYYY'), end:moment(this.rangeEnd).format('DD-MMM-YYYY')}});
      }
    });



  }

  ngAfterViewInit() {
  }

  ngOnInit(): void {

    const element =  document.getElementById("chart");
    element.scrollIntoView()
    
    this.range = new FormGroup({
      startRange: new FormControl<Date | null>(new Date(this.rangeStart)),
      endRange: new FormControl<Date | null>(new Date(this.rangeEnd)),
    })

    this.loadFollowers(this.rangeStart, this.rangeEnd);
  }

  onFetchData() {
    if(this.range.value.startRange && this.range.value.endRange){
      this.rangeStart = moment(this.range.value.startRange).format('MMM DD YYYY')
      this.rangeEnd = moment(this.range.value.endRange).format('MMM DD YYYY')
      this.loadFollowers(this.rangeStart, this.rangeEnd); 
    }
  }

  async loadFollowersTable(tableData: any) {

    this.dataSource = new MatTableDataSource<FollowersData>(tableData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async loadFollowers(startDate: string, endDate: string) {

    if (startDate == '' || endDate == '') {
      return false;
    }



    this.rangeStart = startDate;
    this.rangeEnd = endDate;

    this.lineChartData.datasets[0].data = [];
    this.lineChartData.datasets[1].data = [];
    this.lineChartData.labels = [];
    this.periodLabel = [];
    this.periodTotalJoined = [];
    this.periodTotalLeft = [];
    this.generatingChart = true;
    this.maxValue = 0;

    let periodValueHolder = [];
  

    this.totalJoinedRange = 0;
    this.totalLeftRange = 0;

    let utcFormatStart = moment(startDate).utc(true).format();
    let utcFormatEnd = moment(endDate).utc(true).format();

    this.router.navigate(['/telegram/follower'],{queryParams:{start:moment(this.rangeStart).format('DD-MMM-YYYY'), end:moment(this.rangeEnd).format('DD-MMM-YYYY')}});

    this.telegramCollectionService.getAllFollowers(utcFormatStart, utcFormatEnd)
      .subscribe(async (response) => {
        let res;
        if(response.total > 0){
          switch (this.selectedRange) {
            case 0:
              res = await GenerateFollowerDateList(new Date(this.rangeStart), new Date(this.rangeEnd), response.data);
              break;
            case 1:
              res = await GetWeeksInRange(new Date(this.rangeStart), new Date(this.rangeEnd), response.data)
              break;
            case 2:
              res = await GetMonthsInRange(new Date(this.rangeStart), new Date(this.rangeEnd), response.data)
              break;
          }
        }

        if (res) {
          for (let data of res) {
            this.periodLabel.push(data.label);

            periodValueHolder.push(data.joined);
            periodValueHolder.push(data.left);

            this.periodTotalJoined.push(data.joined);
            this.periodTotalLeft.push(data.left);

          }

          this.maxValue = Math.max(...periodValueHolder) > this.maxValue ? Math.max(...periodValueHolder) : this.maxValue;
          this.lineChartOptions.scales.y.max = this.maxValue
          this.lineChartData.datasets[0].data = this.periodTotalJoined;
          this.lineChartData.datasets[1].data = this.periodTotalLeft;
          this.lineChartData.labels = this.periodLabel;
          this.chart?.chart.update();

          this.generatingChart = false;
        }

        this.tableSourceOfJoinedNWorst = res;
        this.highestJoined = 0;
        this.highestLeft = 0;
        let bestJoinedDays = await this.bestJoinedDays(res);

        this.totalJoinedDateRange = bestJoinedDays.result;

        let worstJoinedDays = await this.worstJoinedDays(res);

        this.totalLeftDateRange = worstJoinedDays.result;

        this.tableSourceOfJoinedNWorst = this.tableSourceOfJoinedNWorst.sort((a, b) => new Date(a.addedDate).getTime() - new Date(b.addedDate).getTime());


        for (let data of this.tableSourceOfJoinedNWorst) {
          data.addedDate = moment(data.addedDate).format('DD MMM YYYY');
          data['net'] = data.joined - data.left;

          if (data.joined > this.highestJoined) {
            this.highestJoined = data.joined;
          }

          if (data.left > this.highestLeft) {
            this.highestLeft = data.left;
          }

          this.totalJoinedRange += data.joined;
          this.totalLeftRange += data.left;


        }

        this.loadFollowersTable(this.tableSourceOfJoinedNWorst);

      })
  }

  onStartRangeFromChange(event) {
    this.rangeStartFrom = moment(event.value).format('MMM DD YYYY')
    if (this.rangeStartFrom !== 'Invalid date') {
      this.loadComparisonFollowers(this.rangeStartFrom, this.rangeEndFrom, this.rangeStartTo, this.rangeEndTo);
    }
  }

  onEndRangeFromChange(event) {
    this.rangeEndFrom = moment(event.value).format('MMM DD YYYY')
    if (this.rangeEndFrom !== 'Invalid date') {
      this.loadComparisonFollowers(this.rangeStartFrom, this.rangeEndFrom, this.rangeStartTo, this.rangeEndTo);
    }
  }

  onStartRangeToChange(event) {
    this.rangeStartTo = moment(event.value).format('MMM DD YYYY')
    if (this.rangeStartTo !== 'Invalid date') {
      this.loadComparisonFollowers(this.rangeStartFrom, this.rangeEndFrom, this.rangeStartTo, this.rangeEndTo);
    }
  }

  onEndRangeToChange(event) {
    this.rangeEndTo = moment(event.value).format('MMM DD YYYY')
    if (this.rangeEndTo !== 'Invalid date') {
      this.loadComparisonFollowers(this.rangeStartFrom, this.rangeEndFrom, this.rangeStartTo, this.rangeEndTo);
    }
  }

  async loadComparisonFollowers(startDateFrom: string, endDateFrom: string, startDateTo: string, endDateTo: string) {
    if (startDateFrom == '' || endDateFrom == '' && startDateTo == '' || endDateTo == '') {
      return false;
    }

    this.totalJoinedFromRange = 0;
    this.totalLeftFromRange = 0;

    this.totalJoinedToRange = 0;
    this.totalLeftToRange = 0;


    this.comparisonFrom = {
      start: moment(startDateFrom).utc(true).format(),
      end: moment(endDateFrom).utc(true).format()
    }

    this.comparisonTo = {
      start: moment(startDateTo).utc(true).format(),
      end: moment(endDateTo).utc(true).format()
    }

    this.telegramCollectionService.getFollowersComparison(this.comparisonFrom, this.comparisonTo)
      .subscribe(async (res: any) => {

        for (let fromRange of res.from) {
          this.totalJoinedFromRange += fromRange.joined
          this.totalLeftFromRange += fromRange.left
        }

        for (let toRange of res.to) {
          this.totalJoinedToRange += toRange.joined;
          this.totalLeftToRange += toRange.left;
        }

        //FROM
        let bestJoinedFromDays = await this.bestJoinedDays(res.from);
        this.totalJoinedFromDateRange = bestJoinedFromDays.result

        let worstJoinedFromDays = await this.worstJoinedDays(res.from);
        this.totalLeftFromDateRange = worstJoinedFromDays.result

        // TO
        let bestJoinedToDays = await this.bestJoinedDays(res.to);
        this.totalJoinedToDateRange = bestJoinedToDays.result

        let worstJoinedToDays = await this.worstJoinedDays(res.to);
        this.totalLeftToDateRange = worstJoinedToDays.result


        this.joinedComparisonPercentage = (((this.totalJoinedFromRange - this.totalJoinedToRange) / this.totalJoinedToRange) * 100).toFixed(2);
        this.leftComparisonPercentage = (((this.totalLeftFromRange - this.totalLeftToRange) / this.totalLeftToRange) * 100).toFixed(2);

        let netJoinFrom = this.totalJoinedFromRange - this.totalLeftFromRange;
        let netJoinTo = this.totalJoinedToRange - this.totalLeftToRange;

        this.netComparisonPercentage = (((netJoinFrom - netJoinTo) / netJoinTo) * 100).toFixed(2);

      })

  }

  async bestJoinedDays(data: IFollower[]) {
    const sortedArray = data.sort((a, b) => b.joined - a.joined);
    const result: string[] = [];
    let totalResult = 0;
    for (let i = 0; i < 5 && i < sortedArray.length; i++) {
      sortedArray[i].createdDate = moment(sortedArray[i].addedDate).format('DD MMM YYYY');
      result.push(moment(sortedArray[i].addedDate).format('DD MMM YYYY'));
      totalResult = totalResult + sortedArray[i].joined;
    }

    return { 'result': result }
  }

  async worstJoinedDays(data: IFollower[]) {

    const sortedArray = data.sort((a, b) => b.left - a.left);
    let result: string[] = [];
    let totalResult = 0;
    for (let i = 0; i < 5 && i < sortedArray.length; i++) {
      sortedArray[i].createdDate = moment(sortedArray[i].addedDate).format('DD MMM YYYY');
      result.push(moment(sortedArray[i].addedDate).format('DD MMM YYYY'));
      totalResult = totalResult + sortedArray[i].left;
    }

    return { 'result': result }
  }

  onChangeRange($event) {
    this.selectedRange = $event.value;
    this.loadFollowers(this.rangeStart, this.rangeEnd)
  }

  checkToDisplayRange() {
    return this.selected === 'QUARTER' || this.selected === 'LIFETIME' || this.selected === 'MONTH';
  }

  navigateByDate(date) {
    let endDate = moment(date).format('DD-MMM-YYYY')
    let startDate  = moment(endDate).subtract(2, 'days').format('DD-MMM-YYYY');

    this.router.navigate(['/telegram/post'],{queryParams:{start:startDate, end:endDate}});
  }


}


<form [formGroup]="formData">
  <div class="container">
    <mat-card>
      <mat-card-header>
        <mat-card-title class="pt-3">Adding Service for {{ loadedAdvertiser?.name }}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col-6 col-sm-12 col-md-4 col-lg-6 col-xl-4 position-relative">
            <img width="100%" src="{{formData.value.s_images}}" />
            <div class="position-absolute bottom-0 end-0">
              <app-shared-upload-button 
                [uploadBucket]="uploadBucket"
                [fileNamePrefix]="fileNamePrefix"
                [uploadPath]="uploadPath"
                (uploadedCompletes)="uploadedFiles($event)"
                [multiUpload]="false"
              ></app-shared-upload-button>
            </div>
          </div>
          <div class="col-6 col-sm-12 col-md-8 col-lg-6 col-xl-8">
            <div class="row">
              <mat-form-field class="col-12" appearance="fill">
                <input matInput maxlength="256" placeholder="Service Name" formControlName="name">
                <mat-error>
                  <span *ngIf="formData.get('name').hasError('required')">Service Name is <strong>required</strong></span>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-6 col-sm-12 col-md-6" appearance="fill">
                <input matInput maxlength="10" placeholder="Price" formControlName="price">
                <mat-error>
                  <span *ngIf="formData.get('price').hasError('required')">Price is <strong>required</strong></span>
                </mat-error>
                <mat-error *ngIf="formData.get('price').hasError('pattern') && !formData.get('price').hasError('required')">
                  <span>Please enter a valid price</span>
                </mat-error>
                <mat-error>
                  <span *ngIf="formData.get('price').hasError('min')">Price should be positive</span>
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-6 col-sm-12 col-md-6" appearance="fill">
                <mat-label>Duration</mat-label>
                <input type="text"
                       placeholder="Pick one"
                       aria-label="Duration"
                       matInput
                       formControlName="duration"
                       [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let option of allDurations" [value]="option.value">
                    {{option.value}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <div class="col-6 col-sm-12 col-md-6">
                <h3><strong>Advertiser Outlets</strong></h3>
                <cdk-virtual-scroll-viewport class="outlet-viewport scrollable-viewport" itemSize="20">
                  <ul>
                    <li *cdkVirtualFor="let outlet of advertiserOutlets">
                      <mat-checkbox [checked]="outletSelection.isSelected(outlet.id)" (change)="outletSelection.toggle(outlet.id)">
                        {{ outlet.name }} <span *ngIf="!outlet.active">(Inactive)</span>
                      </mat-checkbox>
                    </li>
                  </ul>
                </cdk-virtual-scroll-viewport>
              </div>
              <div class="col-6 col-sm-12 col-md-6">
                <h3><strong>Categories</strong></h3>
                <cdk-virtual-scroll-viewport class="categories-viewport scrollable-viewport" itemSize="20">
                  <dl>
                    <ng-container *cdkVirtualFor="let parentServiceCategory of parentServiceCategories">
                      <dt>
                        <mat-checkbox [checked]="isCategorySelected(parentServiceCategory.id) >= 0" (change)="toggleCategory(parentServiceCategory)">
                          {{parentServiceCategory.name}}
                        </mat-checkbox>
                      </dt>
                      <dd *ngFor="let serviceCategory of getServiceCategories(parentServiceCategory.id)">
                        <mat-checkbox [checked]="isCategorySelected(serviceCategory.id) >= 0" (change)="toggleCategory(serviceCategory)">
                          {{ serviceCategory.name }}
                        </mat-checkbox>
                      </dd>
                    </ng-container>
                    
                  </dl>
                </cdk-virtual-scroll-viewport>
              </div>
            </div>
            
          </div>          
        </div>
        <div class="row">
          <div class="col-12">
            <mat-label>Description</mat-label>
            <quill-editor formControlName="description" [styles]="{height: '150px'}">                  
            </quill-editor>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <mat-label>Terms & Conditions</mat-label>
            <quill-editor formControlName="terms" [styles]="{height: '150px'}">                  
            </quill-editor>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <mat-slide-toggle class="m-4" formControlName="preview" [checked] = "checked">
              Preview
            </mat-slide-toggle>
            <mat-slide-toggle class="m-4" formControlName="active" [checked] = "checked">
              Active
            </mat-slide-toggle>
          </div>
          <div class="col-6">
            
          </div>
        </div>
        <mat-card-actions>
          <div class="row">
            <div class="col-12 text-center">
              <button mat-raised-button color="primary" class="m-2" (click)="save()">Save</button>
              <button mat-raised-button class="m-2" [routerLink]="['/salon-finder/advertisers/update', loadedAdvertiser?.id]">Cancel</button>
            </div>
          </div>
        </mat-card-actions>
      </mat-card-content>
    </mat-card>   
  </div>
</form>

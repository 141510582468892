<div>
  <mat-card>
    <mat-card-content>
      <form [formGroup]="accountManagerForm">

        <div class="container">
          <div class="row">
            <div class="col-6">
              <mat-form-field appearance="fill" class="fullWidth">
                <mat-label>First Name</mat-label>
                <input matInput #firstName maxlength="120" placeholder="First Name" formControlName="firstName">
                <mat-error *ngIf="formErrors.firstName.errors?.required">
                  First Name is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field appearance="fill" class="fullWidth">
                <mat-label>Last Name</mat-label>
                <input matInput #lastName maxlength="120" placeholder="Last Name" formControlName="lastName">
                <mat-error *ngIf="formErrors.lastName.errors?.required">
                  Last Name is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field appearance="fill" class="fullWidth">
                <mat-label>Email</mat-label>
                <input matInput #email maxlength="256" placeholder="Enter user email" formControlName="email"
                  [disabled]="loadedModel.id>0">
                <mat-error *ngIf="formErrors.email.errors?.required">
                  Email is <strong>required</strong>
                </mat-error>
                <mat-error *ngIf="formErrors.email.errors?.email">
                  Email format error
                </mat-error>
                <mat-error *ngIf="formErrors.email.errors?.IsUserAlreadyExistConstraint">
                  {{ formErrors.email.errors.IsUserAlreadyExistConstraint }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-6">
            </div>
          </div>

          <div class="row">
            <div class="loading-container" *ngIf="savingForm">
              <mat-spinner diameter="30" color="accent"> </mat-spinner> Saving ...
            </div>

            <div class="col-12 text-center" *ngIf="!savingForm">
              <button mat-raised-button color="primary" class="m-2" (click)="saveUser()"
                [disabled]="accountManagerForm.status != 'VALID' || savingForm">
                Save
              </button>
              <button mat-raised-button class="m-2" [routerLink]="['/consumer-report/account-manager']">Cancel</button>
            </div>
          </div>

        </div>

      </form>
    </mat-card-content>
  </mat-card>
  <br>

  <div class="row">
    <div class="col-12">
      <table mat-table [dataSource]="userList" class="mat-elevation-z8" style="width: 100%">
        <ng-container matColumnDef="account">
          <th mat-header-cell *matHeaderCellDef> Account </th>
          <td mat-cell *matCellDef="let element">
            <span>{{element.company}}</span><br />
            <span>{{element.displayName}}</span><br />
            <span>{{element.email}}</span>
          </td>
        </ng-container>
      
        
      <ng-container matColumnDef="paidReport">
        <th mat-header-cell *matHeaderCellDef> Paid Report(s) </th>
        <td mat-cell *matCellDef="let element;"> {{ element.points.length > 0 ? element.points[0].expiryFormatedDate  : ''  }} </td>
      </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element;"> {{element.awsStatus | async}} </td>
        </ng-container>

        <ng-container matColumnDef="latestSearch">
          <th mat-header-cell *matHeaderCellDef> Search history </th>
          <td mat-cell *matCellDef="let element;">
            <div *ngFor="let e of element.latestSearch">
              <span *ngIf="e.commonID">
                <a href="{{ e.link }}" target="_blank">{{ e.searchOn | dateTimeFromIso | dateTimeToLocal |
                  dateTimeToFormat:'dd LLL yyyy' }}</a>
              </span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="createdDate">
          <th mat-header-cell *matHeaderCellDef> Created Date </th>
          <td mat-cell *matCellDef="let element;"> {{element.registerOn | dateTimeFromIso | dateTimeToLocal |
            dateTimeToFormat:'dd LLL yyyy' }} </td>
        </ng-container>

        <ng-container matColumnDef="lastLoggedOn">
          <th mat-header-cell *matHeaderCellDef> Last login </th>
          <td mat-cell *matCellDef="let element;"> {{element.lastLoggedOn | dateTimeFromIso | dateTimeToLocal |
            dateTimeToFormat:'dd LLL yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="accountManager">

          <th mat-header-cell *matHeaderCellDef> Account Manager </th>
          <td mat-cell *matCellDef="let element;">
            <!-- <span *ngIf="element.accountManagerID">{{
              element.accountManager.displayName }}</span> -->
            <form [formGroup]="accountManagerColumn">
              <mat-form-field class="fullWidth">
                <mat-select formControlName="accountManagerColumn"
                  (selectionChange)="updateRowAccountManager(element.id,$event)" [compareWith]="compareSelect">
                  <mat-option *ngFor="let accountManager of accountManagers$ | async" [value]="accountManager"  >
                    {{ accountManager.displayName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </form>
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element;">
            <a mat-icon-button color="primary" routerLink="/consumer-report/customer/update/{{element.id}}">
              <mat-icon>edit</mat-icon>
            </a>
            <ng-template [ngIf]="element.active==1">
              <a mat-icon-button color="warn" (click)="disableUser(element.id)">
                <mat-icon>block</mat-icon>
              </a>
            </ng-template>
            <ng-template [ngIf]="element.active!=1">
              <a mat-icon-button color="default" (click)="consumerReportUserService.enableProfile(element.id)">
                <mat-icon>power_settings_new</mat-icon>
              </a>
            </ng-template>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="p-2"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="p-2"></tr>
      </table>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-12">
      <mat-paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [pageIndex]="page"
        (page)="changePage($event)">
      </mat-paginator>
    </div>
  </div>
  <div class="col-12 text-center">
    <button mat-raised-button color="primary" (click)="saveUpdatedCustomerAccountManager()"
      [disabled]="updatedCustomerAccountManager.length === 0">
      Save Customer
    </button>
  </div>
  


</div>
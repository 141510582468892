import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { IService } from '../../interfaces/IService';
import { SalonFinderAdvertiserService } from '../../services/salon-finder-advertiser.service';

@Component({
  selector: 'app-service-create',
  templateUrl: './service-create.component.html',
  styleUrls: ['./service-create.component.scss']
})
export class ServiceCreateComponent implements OnInit {

  serviceForm: IService = {
    woo_id: 0,
    name: '',
    price: '',
    duration: '',
    customDuration: '',
    s_images: environment.salonFinder.serviceDefaultImg,
    description: '',
    terms: '',
    advertiserID: 0,
    collection_id: 0,
    outlets: [],
    preview: true,
    status: 0,
    advertiser: null,
    categories: [],
    id: 0,
    uploadImageID: 0,
    active: 2
  };

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly advertiserService: SalonFinderAdvertiserService
  ) { }

  ngOnInit(): void {
    this.advertiserService.get(this.activatedRoute.snapshot.paramMap.get('advertiserID'))
      .subscribe(d => {
        this.serviceForm = {
          ...this.serviceForm,
          advertiser: d,
          advertiserID: d.id
        };
      });
  }

}

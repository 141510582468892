import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ObjectCannedACL, PutObjectCommand } from '@aws-sdk/client-s3';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import slugify from 'slugify';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { IS3UploadFile } from 'src/app/shared/interfaces/IS3UploadFile';
import { AWSServiceService } from 'src/app/shared/services/awsservice.service';
import { environment } from 'src/environments/environment';
import { UploadImageLinkTypeEnum } from '../enums/UploadImageLinkType.enum';
import { IUploadImages } from '../interfaces/iupload-images';

@Injectable({
  providedIn: 'root'
})
export class SalonFinderUploadImagesService extends CRUDService<IUploadImages> {
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }

  baseURL = environment.salonFinder.baseURL
  apiPath = '/upload'
  environmentCRUDPath = {
    ...environment.salonFinder,
    commonCreatePath: '/add-record'
  }

  public uploadingImage: boolean = false


  list(params?: HttpParams): Observable<ICommonListingResponse<IUploadImages[]>> {
    throw new Error('Method not implemented.');
  }
  get(id: string | number): Observable<IUploadImages> {
    throw new Error('Method not implemented.');
  }
  delete(id: string | number): Observable<IUploadImages> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: IUploadImages) {
    return this.save(model, id)
  }
  create(model: IUploadImages) {
    return this.save(model, null)
  }
  makeFormGroup(loadedModel?: IUploadImages) {
    return this.fb.group({
      id:[loadedModel?.id],
      url: [loadedModel?.url],
      fileName: [loadedModel?.fileName],
      mimetype: [loadedModel?.mimetype],
      bucketURL: [loadedModel?.bucketURL?loadedModel?.bucketURL:environment.salonFinder.uploadBucket],
      path: [loadedModel?.path],
      linkType: [loadedModel?.linkType?loadedModel.linkType:UploadImageLinkTypeEnum.ADVERTISER],
      linkID: [loadedModel?.linkID?loadedModel.linkID:0],
      name: [loadedModel?.name],
      pos: [loadedModel?.pos?loadedModel.pos:1],
    })
  }
  constructor(
    public readonly httpClient: HttpClient,
    private fb: FormBuilder,
    private readonly AWSService: AWSServiceService,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute
  ) {
    super();
  }

  getFileName(filename) {
    return `${DateTime.utc().toFormat('yyyy-LL-dd-HH-mm-ss')}-${slugify(filename.toLowerCase())}`
  }

  getPath(filename) {
    return environment.salonFinder.uploadKey+`/${DateTime.utc().toFormat('yyyy')}/${DateTime.utc().toFormat('LL')}/${filename}`
  }

  save(model: IUploadImages, id?: string | number) {
    return this.httpClient.post<IUploadImages>(this.saveURL(id), {
      ...model,
      id: id
    })
  }

  async saveByS3Image(models: IS3UploadFile[], linkType: UploadImageLinkTypeEnum, linkID: number = 0, startPos: number = 1): Promise<IUploadImages[]> {
    let uploadedImages: IUploadImages[] = new Array<IUploadImages>();
    let pos = startPos
    for(const model of models) {
      const result = await this.save(this.makeIUploadImage(model, linkType, linkID, pos)).toPromise()
      pos++
      uploadedImages.push(result)
    }
    return uploadedImages
  }

  makeIUploadImage(model: IS3UploadFile, linkType: UploadImageLinkTypeEnum, linkID: number = 0, pos: number = 1) {
    return {
      url: environment.salonFinder.bucketURL+"/"+model.Key,
      fileName: model.FileName,
      mimetype: model.Body.type,
      bucketURL: environment.salonFinder.uploadBucket,
      path: model.Key,
      linkType: linkType,
      linkID: linkID,
      pos: pos,
      name: model.Body.name
    }
  }


  public async upload(event, cb): Promise<IUploadImages[]> {
    const uploadFileList: FileList | null = event.target.files
    let uploadedImages: IUploadImages[] = new Array<IUploadImages>();
    this.uploadingImage = true
    for(var i=0; i<uploadFileList.length; i++) {
      const uploadFile = uploadFileList.item(i);
      const savedFileName = this.getFileName(uploadFile.name)
      let uploadFileLocationKey = this.getPath(savedFileName)
      var params = {
        Body: uploadFile,
        Bucket: environment.salonFinder.uploadBucket,
        Key: uploadFileLocationKey,
        ACL: ObjectCannedACL.public_read,
        ContentType: uploadFile.type,
        ContentLength: uploadFile.size
      };
      await this.AWSService.S3Client.send(new PutObjectCommand(params))
      const uploadImageModel = await this.save({
        url: environment.salonFinder.bucketURL+"/"+uploadFileLocationKey,
        fileName: savedFileName,
        mimetype: uploadFile.type,
        bucketURL: environment.salonFinder.uploadBucket,
        path: uploadFileLocationKey,
        linkType: UploadImageLinkTypeEnum.OUTLET,
        linkID: 0,
        pos: i+1,
        name: uploadFile.name
      }).toPromise()
      uploadedImages.push(uploadImageModel)
    }
    this.uploadingImage = false
    return uploadedImages
  }
}

import { HttpParams } from "@angular/common/http";
import { Component, Inject, InjectionToken, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { CRUDService } from "./crud-service";

@Component({
  template: ''
})
export abstract class UpdateComponent<T, U extends CRUDService<T>> {

  primaryKey: string = 'id'
  loading: boolean = false
  loadingID: number | string
  public loadedModel: T
  public readonly baseService: U

  public loadedModel$: BehaviorSubject<T> = new BehaviorSubject(null);
  public formData: FormGroup;

  constructor() {
    
  }
  public init(route: ActivatedRoute) {
    route.paramMap.subscribe((params) => {
      const id = params.get(this.primaryKey)
      if(id != "create"){
        if(!this.loadedModel || (this.loadedModel[this.primaryKey] != +id && !this.loading)) {
          this.loading = true
          this.baseService.httpGet(id)
            .subscribe(d=> {
              this.loadingID = id
              this.loading = false
              this.loadedModel = d
              this.loadedModel$.next(d)
            })
        }
      }
    })
  }
}
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { Observable } from 'rxjs';
import { CRUDService } from 'src/app/shared/abstracts/crud-service';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { ICRUDPath } from 'src/app/shared/interfaces/iCRUDPath.interface';
import { environment } from 'src/environments/environment';
import { IRegisteredEntity } from '../interfaces/iregistered-entity';

@Injectable({
  providedIn: 'root'
})
export class SalonFinderRegisteredEntityService extends CRUDService<IRegisteredEntity> {
  makeSearchListGroup() {
    throw new Error('Method not implemented.');
  }

  baseURL: string = environment.salonFinder.baseURL
  apiPath: string = '/registerEntity'

  environmentCRUDPath: ICRUDPath = environment.salonFinder

  constructor(public readonly httpClient: HttpClient, 
    private fb: FormBuilder,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute) {
    super()
  }

  makeFormGroup(loadedModel?: IRegisteredEntity) {
    return this.fb.group({
      entityName: this.fb.control(loadedModel?.entityName, [
        Validators.required,
        Validators.maxLength(50),
        Validators.nullValidator
      ]),
      entityEmail: this.fb.control(loadedModel?.entityEmail, [
        Validators.required,        
        Validators.maxLength(1024),
        Validators.nullValidator
      ]),
      bankName: this.fb.control(loadedModel?.bankName, [
        Validators.required,
        Validators.maxLength(50),
        Validators.nullValidator
      ]),
      bankAccount: this.fb.control(loadedModel?.bankAccount, [
        Validators.required,
        Validators.maxLength(50),
        Validators.nullValidator
      ]),
      sendToAdvertiser: this.fb.control(loadedModel?.sendToAdvertiser?loadedModel?.sendToAdvertiser:true),
      outletID: this.fb.control(loadedModel?.outletID, [RxwebValidators.numeric({allowDecimal: false})]),
      advertiserID: this.fb.control(loadedModel?.advertiserID, [RxwebValidators.numeric({allowDecimal: false})]),
      id: this.fb.control(loadedModel?.id?loadedModel?.id:null)
    });
  }
  list(params?: HttpParams): Observable<ICommonListingResponse<IRegisteredEntity[]>> {
    throw new Error('Method not implemented.');
  }
  get(id: string | number): Observable<IRegisteredEntity> {
    throw new Error('Method not implemented.');
  }
  delete(id: string | number): Observable<IRegisteredEntity> {
    throw new Error('Method not implemented.');
  }
  update(id: string | number, model: IRegisteredEntity): Observable<IRegisteredEntity> {
    throw new Error('Method not implemented.');
  }
  create(model: IRegisteredEntity): Observable<IRegisteredEntity> {
    throw new Error('Method not implemented.');
  }
  save(model: IRegisteredEntity, id?: string | number): Observable<IRegisteredEntity> {
    throw new Error('Method not implemented.');
  }

  
}

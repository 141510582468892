import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { IConsumerReportUser } from '../../interfaces/consumerReportUser.interface';
import { ConsumerReportUserService } from '../../services/consumerReportUserService.service';

@Component({
  selector: 'app-update-account-manager',
  templateUrl: './account-manager-update.component.html',
  styleUrls: ['./account-manager-update.component.scss']
})
export class AccountManagerUpdateComponent implements OnInit {

  constructor(private readonly route: ActivatedRoute, private readonly consumerReportUserService: ConsumerReportUserService) { }

  accountManagerModel: IConsumerReportUser = {
    accountManagerID: 0,
    awsID: '',
    company: '',
    displayName: '',
    email: '',
    firstName: '',
    id: 0,
    lastName: '',
    active: true
  };
  
  ngOnInit(): void {
    const customerID = this.route.snapshot.paramMap.get('id');
    this.consumerReportUserService.getUser(customerID)
      .subscribe(res => {
        this.accountManagerModel = res;
      });
  }

}

<div class="container-fluid">
  <form [formGroup]="confirmForm">
    <h3>{{ dialogTitle }}</h3>
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="fill" class="fullWidth">
          <mat-label>Confirm</mat-label>
          <input matInput #confirmField maxlength="120" placeholder='Type "{{ confirmationText }}" to deactivate' formControlName="confirmField">            
          <mat-error *ngIf="formControls.confirmField.errors">              
            Value don't match <strong>{{ confirmationText }}</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <button mat-raised-button color="primary" class="m-2" (click)="closeForm(false)">Cancel</button>
        <button mat-raised-button color="warn" class="m-2" (click)="closeForm(true)" [disabled]="confirmForm.status != 'VALID' || savingForm">
          <span *ngIf="!savingForm">Confirm</span>
          <span *ngIf="savingForm">Loading...</span>
          
        </button>
      </div>
    </div>
  </form>
</div>
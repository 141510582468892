import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormComponent } from '../../abstracts/form-component';
import { ListingComponent } from '../../abstracts/listing-component';
import { IOption } from '../../interfaces/ioption';
import { IOptionCountryList } from '../../interfaces/ioption-country-list';
import { IOptionGender } from '../../interfaces/ioption-gender';
import { IOptionSurveyCountry } from '../../interfaces/ioption-survey-country';
import { IStore } from '../../interfaces/istore';
import { ConsumerReportBrandsService } from '../../services/consumer-report-brands.service';
import { ConsumerReportInfluencerService } from '../../services/consumer-report-influencer.service';
import { ConsumerReportOptionService } from '../../services/consumer-report-option.service';
import { ConsumerReportStoreService } from '../../services/consumer-report-store.service';

@Component({
  selector: 'consumer-report-store-form',
  templateUrl: './store-form.component.html',
  styleUrls: ['./store-form.component.scss']
})
export class StoreFormComponent extends FormComponent<IStore> implements OnInit, OnChanges {

  _countryList: IOptionCountryList[]


  constructor(
    private readonly fb: FormBuilder,
    private readonly consumerReportOptionService: ConsumerReportOptionService,
    private readonly snackBar: MatSnackBar,
    private readonly consumerReportStoreService: ConsumerReportStoreService,
    private router: Router
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loadedModel) {      
      this.initForm();
    }
  }

  surveyCountry: Observable<IOptionSurveyCountry[]> = this.consumerReportOptionService.findByType('surveyCountry')
  
  countryList: Observable<IOptionCountryList[]> = this.consumerReportOptionService.findByType('countryList')
  

  ngOnInit(): void {
    this.initForm();
  }

  countryChange($event) {
    const country = this._countryList.find(c=>c.country === $event.value)
    if(country) {
      this.formData.patchValue({
        countryZone: country.countryZone
      })
    }
  }

  save() {
    const value = this.formData.value
    this.savingForm = true

    this.consumerReportStoreService.save(value, value.id)
      .subscribe(
        d => {
          this.savingForm = false;
          this.loadedModel = d;
          this.snackBar.open('Brand has been saved SUCCESSFULLY!', 'Close', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
          if(value.id)
            this.initForm();
          else {            
            this.router.navigate([`/consumer-report/brand/update`, d.id])
          }
        },
        err => {
          this.savingForm = false;
          this.snackBar.open('Brand has been saved FAILED!', 'Close', {
            duration: 2000,
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
          this.initForm();
        }
      );
  }

  initForm() {
    this.formData = this.fb.group({
      id: [this.loadedModel.id],
      name: [this.loadedModel.name, [Validators.required]],
      country: [this.loadedModel.country],
      originatedISOCountryCode: [this.loadedModel.originatedISOCountryCode],
      description: [this.loadedModel.description],
      skincare: [this.loadedModel.skincare],
      makeup: [this.loadedModel.makeup],
      haircare: [this.loadedModel.haircare]
    })
  }

}

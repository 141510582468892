<div *ngIf="data">
    <h2 mat-dialog-title>Display Config</h2>
    <mat-dialog-content class="mat-typography">
        <form [formGroup]="layoutForm" >
            <div class="row">
                <div class="col-6">
                    <section class="example-section">
                        <mat-checkbox class="example-margin" formControlName="displayPercentage">Show Percentage</mat-checkbox>
                    </section> 
                </div>
                <div class="col-6">
                    <mat-form-field class="col-12" appearance="fill">
                        <mat-label>Background Color</mat-label>
                        <input matInput type="text"  formControlName="bgColor" />
                    </mat-form-field>
                </div>   
            </div>
            <div class="row">
                <div class="col-12">
                    <mat-form-field class="col-12" appearance="fill">
                        <mat-label>Icon</mat-label>
                        <input type="text" matInput formControlName="icon" [matAutocomplete]="auto">
                        <mat-icon matSuffix>{{layoutForm.value.icon}}</mat-icon>
                        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selectedIcon($event)">
                            <mat-option *ngFor="let icon of filteredIcons | async" [value]="icon">
                            <mat-icon aria-hidden="false">{{icon}}</mat-icon> {{icon}}
                            </mat-option>
                        </mat-autocomplete>

                    
                    </mat-form-field>
                </div>
                <div class="col-12">
                    <mat-form-field class="col-12" appearance="fill">
                        <mat-label>Custom name</mat-label>
                        <input type="text" matInput formControlName="customName">
                    </mat-form-field>                  
           
                </div>
                             
            </div>   
        </form>
      
    </mat-dialog-content>
    <mat-dialog-actions align="center" class="mb-4">
      <button mat-button mat-raised-button color="warn"  mat-dialog-close>Cancel</button>
      <button mat-button mat-raised-button color="primary" [disabled]="layoutForm.status != 'VALID'" (click)="save()">Save</button>
    </mat-dialog-actions>
</div>


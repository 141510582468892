<div class="row mb-3">
  <div class="col-12 text-center">
    <h1><b>Add Service(s)</b></h1>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-6">
        <form [formGroup]="searchForm">
          <mat-form-field class="fullWidth col-12" appearance="fill">
            <input type="text"
            placeholder="Type anything to search service"
            aria-label="Type anything to search service"
            matInput
            appearance="fill"
            formControlName="searchText"
            autocomplete="off" 
            >
          </mat-form-field>
        </form>
      </div>
      <div class="col-6">
        <form [formGroup]="searchFormRight">
          <mat-form-field class="fullWidth col-12" appearance="fill">
            <input type="text"
            placeholder="Type anything to search added service"
            aria-label="Type anything to search added service"
            matInput
            appearance="fill"
            formControlName="searchText"
            autocomplete="off" 
            >
          </mat-form-field>
          </form>
      </div>
    </div>
    <h3 *ngIf="collectionName">Collection Name : <b>{{collectionName}}</b></h3>
    <div class="dual-select">
      <div class="dual-select__left">
        <table class="mat-elevation-z8" style="width: 100%">
          <thead style="border-bottom-width: 1px;border-bottom-style: solid; height: 56px;" class="text-center">
            <tr>
              <th>Advertiser</th>
              <th>Service Name</th>
              <th>Price</th>
              <!-- <th>Status</th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let service of unselectedServices"
            (click)="togglePendingSelection( service )"
            (dblclick)="addToSelectedServices( service )"
            class="dual-select__item text-center"
            [class.dual-select__item--selected]="isPendingSelected(service)" style="height: 48px;">
              <td>{{ service.advertiser.name }}</td>
              <td>{{ service.name }}</td>
              <td>${{ service.price }}</td>
            </tr>
          </tbody>
        </table>
        <div class="row mt-4">
          <div class="col-12">
            <mat-paginator #TableLeftPaginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" [pageIndex]="page"
              (page)="changePage($event)">
            </mat-paginator>
          </div>
        </div>
      </div>
      <div class="dual-select__controls">
        <button
          (click)="addToSelectedServices()"
          class="dual-select__control">
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
        <button
          (click)="removeFromSelectedServices()"
          class="dual-select__control">
          <mat-icon>keyboard_arrow_left</mat-icon>
        </button>
      </div>
      <div class="dual-select__right">
        <form>
          <table class="mat-elevation-z8" style="width: 100%">
            <thead style="border-bottom-width: 1px;border-bottom-style: solid; height: 56px;" class="text-center">
              <tr>
                <th>Advertiser</th>
                <th>Service Name</th>
                <th>Price</th>
                <!-- <th *ngIf="loadingCollectionID" class="ml-5">Action</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let service of filteredSelectedServices"
              (click)="togglePendingUndoSelection( service )"
              (dblclick)="removeFromSelectedServices( service )"
              class="dual-select__item text-center"
              [class.dual-select__item--selected]="isPendingUndoSelected(service)" style="height: 48px;">
                <td>{{ service.advertiser.name }}</td>
                <td>{{ service.name }}</td>
                <td>${{ service.price }}</td>
                <!-- <td *ngIf="loadingCollectionID"><p>
                  <a mat-icon-button color="warn" (click)="togglePendingSelection( service )">
                      <mat-icon matTooltip="Remove Service" matTooltipPosition="right">remove_circle</mat-icon>
                  </a>
                 </p>
                </td> -->
              </tr>
            </tbody>
          </table>

          <div class="vertical-center mt-4">
            <p>* To add service(s), please click the service(s) on left column then click button ">" or double click the service(s) on left column, don't forget to click "Save" button</p>
            <p>* To remove service(s), please click the service(s) on right column then click button "<" or double click the service(s) on right column, don't forget to click "Save" button</p>
          </div>

          <!-- <div class="container">
            <div class="vertical-center mt-4">
                <button type="submit" class="btn submit" [disabled]="serviceForm.status != 'VALID' || savingForm">Submit</button>
            </div>
          </div> -->
          <div class="row mt-4" *ngIf="totalright>0">
            <div class="col-12">
              <mat-paginator #TableRightPaginator [length]="totalright" [pageSize]="pageSizeRight" [pageSizeOptions]="pageSizeOptionsRight" [showFirstLastButtons]="true" [pageIndex]="pageright"
                (page)="changePageRight($event)">
              </mat-paginator>
            </div>
          </div>
        </form>
      </div>
    </div>
    
  </div>
</div>
<!-- <div class="row mb-4">
  <div class="col-12">
    <mat-paginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [showFirstLastButtons]="true" [pageIndex]="page"
      (page)="changePage($event)">
    </mat-paginator>
  </div>
</div> -->

<ng-container *ngIf="isLoading">
  <div class="overlay"></div>
  <mat-progress-spinner [mode]="'indeterminate'" [diameter]="50"></mat-progress-spinner>
</ng-container>
import { ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { IUrlShortener } from 'src/app/consumer-report/interfaces/IUrlShortener';
import { UrlShortenerCollectionService } from '../../services/url-shortener-collection.service';
import { DeleteDialog } from '../../components/delete-dialog/delete-confirmation';
import { MatDialog } from '@angular/material/dialog';
import { ResetDialog } from '../../components/reset-dialog/reset-confirmation';
import { ArchivedDialog } from '../../components/archived-dialog/archived-confirmation';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ArchivedVerifyDialog } from '../../components/archived-verify/archived-verify';
import { INewUrlShortener } from '../../interfaces/INewUrlShortener';
import { removeHttpsPrefix } from 'src/app/shared/helpers/RemoveHttsPrefix';
import { ITag } from '../../interfaces/ITag';
import { ICommonListingResponse } from 'src/app/shared/interfaces/icommonListingResponse.interface';
import { UrlShortenerTagService } from '../../services/url-shortener-tag.service';
import { MatTableDataSource } from '@angular/material/table';
import { Clipboard } from '@angular/cdk/clipboard';
import { urlShortenerServer } from 'src/environments/url-shortener-server';

@Component({
  selector: 'url-shortener-index',
  templateUrl: './url-shortener-index.component.html',
  styleUrls: ['./url-shortener-index.component.scss']
})
export class UrlShortenerIndexComponent extends ListingComponent<INewUrlShortener> implements  OnInit, OnChanges {
  
  searchForm: FormGroup
  urlShortenerLists: IUrlShortener[]
  lists : never[]; 
  searchTerms: any[] = []; 
  baseUrl : string;
  clientTitle:any = [];
  campaignTitle:any = [];
  customSearch:any = [];
  tagIDs: number[] = [];
  
  selectedView:any = "";
  selectedStatus:any = '1';
  selectedFilter:any; 
  currentUser;


  startFilters: ITag[] = []

  constructor(
    private readonly fb: FormBuilder,
    private readonly urlShortenerService: UrlShortenerCollectionService,
    private readonly urlShortenerTagService: UrlShortenerTagService,
    public readonly activatedRoute: ActivatedRoute,
    private readonly authService: AuthService,
    public readonly baseService: UrlShortenerCollectionService,
    public readonly mainRouter: Router,
    public dialog: MatDialog,
    private router: Router,
    private changeDetectorRefs: ChangeDetectorRef,
    private clipboard: Clipboard
  ) {
    super();
    this.listingCols = [
      'campaign',      
      'client',
      'description',
      'status',
      'url',
      'allClicks',
      'uniqueClicks',
      'currentOwner',
      'createdOn',
      'action'
    ];

    if(activatedRoute.snapshot.queryParams['customSearch[]']) {
      this.customSearch = activatedRoute.snapshot.queryParams['customSearch[]']
    }
    if(activatedRoute.snapshot.queryParams['campaign[]']) {
      this.campaignTitle = activatedRoute.snapshot.queryParams['campaign[]']
    }
    if(activatedRoute.snapshot.queryParams['client[]']) {
      this.clientTitle = activatedRoute.snapshot.queryParams['client[]']
    }
    if(activatedRoute.snapshot.queryParams['tagIDs[]']) {
      this.tagIDs = activatedRoute.snapshot.queryParams['tagIDs[]']
    }
    
    this.currentUser = this.authService.getProfileFromToken();
  }

  copyClipBoard(link:string){
    this.clipboard.copy('https://'+link)
  }

  copyClipBoardTikTok(link:string){
    this.clipboard.copy('https://'+urlShortenerServer.tikTokURL+link);
  }

  hasDVSG(fullUrl:string){
    return fullUrl.includes('dv.sg');
  }


  ngOnChanges(changes: SimpleChanges): void {   
    if(changes.client || changes.campaign || changes.custom || changes.ownerEmail) {
      this.populateList(null, true)
    }
  }

  initURLSearchForm() {
    this.searchForm = this.fb.group({
      searchText: [""],
    })
  }

  changePage(pageEvent: PageEvent) {
    let changePage = false;
    if (this.pageSize != pageEvent.pageSize) {
      this.pageSize = pageEvent.pageSize;
      changePage = true;
    }
    if (this.page != pageEvent.pageIndex) {
      this.page = pageEvent.pageIndex;
      changePage = true;
    }

    this.populateList(null, true)

    let el = document.getElementById("top");
    el.scrollIntoView({behavior: 'smooth'});

  }

  populateList(params?: any, urlHistory?: boolean): void {
    params = this.makeSearchParams({
      status: this.selectedStatus,
      client : this.clientTitle || [],
      campaign : this.campaignTitle || [],
      customSearch: this.customSearch || [],
      ownerEmail: this.selectedView || null,
      tagIDs: this.tagIDs || [],
    })

    this.commonPopulateList(params, urlHistory);
    let el = document.getElementById("top");
    el.scrollIntoView({behavior: 'smooth'});
  }

  populateListByOwner(): void {
    this.isLoading = true;
    this.campaignTitle = [];
    this.campaignTitle =[];
    this.customSearch = [];
    this.tagIDs = [];
    this.page = 0;
    this.populateList(null,true);
  }

  ngOnInit() {
    if(this.tagIDs.length > 0){ 
      this.urlShortenerTagService.list(this.makeSearchParams({
        limit: -1,
        searchIDs: this.tagIDs
      })).subscribe((result:ICommonListingResponse<ITag[]>) => {
        this.startFilters = result.data;
      })
    }
    this.populateList();
  }

  getExtraListingParams() {
    throw new Error('Method not implemented.');
  }

  onChangeStatus(event:any){
    this.populateList()
  }

  resetClicks(item:any):void{
    const dialogRef = this.dialog.open(ResetDialog, {
      width: '450px',
      data: {id: item.id},
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.data === 'success'){
        this.populateList()
      }
    });
  }

  deleteItem(item: any): void {
    const dialogRef = this.dialog.open(DeleteDialog, {
      width: '450px',
      data: {id: item.id},
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.data === 'success'){
        this.populateList()
      }
    });
  }

  archivedItem(item:any){
    const dialogRef = this.dialog.open(ArchivedVerifyDialog, {
      width: '600px',
      data: {id: item.id}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result === 'Yes'){
        const dialogArchivedRef = this.dialog.open(ArchivedDialog, {
          width: '600px',
          data: {id: item.id,status:item.active}
        });

        dialogArchivedRef.afterClosed().subscribe(response => {
          if(response !== undefined && response.data === 'success'){
            this.populateList(null, true)
          }
        })
      }
    });

  }

  unArchivedItem(item:any){
    const dialogArchivedRef = this.dialog.open(ArchivedDialog, {
      width: '600px',
      data: {id: item.id,status:item.active}
    });

    dialogArchivedRef.afterClosed().subscribe(result => {
      if(result !== undefined && result.data === 'success'){
        this.populateList();
      }
    })
    
  }

  clickTag(value: ITag){
    this.page = 0;
    this.onFilterAdded([value])
    this.populateList(null, true)
  }

  onClickImpression(id:string){
   return this.router.navigate(['/short-url/detail/'+id],{queryParams:{page: this.page, take: this.pageSize}})
  }

  onFilterAdded(eventData: ITag[]) {
    this.campaignTitle = []
    this.clientTitle = []
    this.customSearch = []
    this.tagIDs = []
    for(const tag of eventData){
      if(tag.key === "campaign"){
        this.campaignTitle.push(tag.value.trim())
      }else if(tag.key === "client"){
        this.clientTitle.push(tag.value.trim())
      }else{
        this.customSearch.push(tag.value.trim())
      }
      if(tag.id && tag.id != 0){
        this.tagIDs.push(tag.id)
      }
    }
    this.populateList(null, true)
  }
}

<div  *ngIf="!isLoading">
<div [formGroup]="graphAnswersInformation">

    <mat-accordion >
        <mat-expansion-panel  [expanded]="openPanelStateAnswer.value" #panel (opened)="openPanelAnswer()"
                             (closed)="closePanelAnswer()" >
          <mat-expansion-panel-header>
            <mat-panel-title>
              Answers
            </mat-panel-title>
          </mat-expansion-panel-header>
          
            <ng-container *ngIf="openPanelStateAnswer.value">
              
              <mat-table #table fixedLayout="true" [dataSource]="graphQuestionAnswers['controls']">
                <ng-container matColumnDef="order" sticky>
                    <mat-header-cell *matHeaderCellDef> Order </mat-header-cell>
                    <mat-cell *matCellDef="let element; let i = index">
                    <span>{{element.get("order").value}}</span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="answers">
                    <mat-header-cell *matHeaderCellDef> Answers </mat-header-cell>
                    <mat-cell *matCellDef="let element; let i = index">
      
                      <mat-form-field appearance="fill">
                        <mat-chip-grid #chipGrid>
                          <mat-chip-row *ngFor="let answer of element.get('selectedAnswers').value; let ansIndex=index" (removed)="remove(ansIndex,answer.uuid,element)">
                            {{answer.label}}
                            <button matChipRemove [attr.aria-label]="'remove ' + answer.uuid">
                              <mat-icon>cancel</mat-icon>
                            </button>
                          </mat-chip-row>
                        </mat-chip-grid>
                        <input #fruitInput [formControl]="element.get('answer')"
                          [matChipInputFor]="chipGrid" [matAutocomplete]="auto"/>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event,element, i)">
                          <ng-container *ngIf="filteredAnswersOptions[i]">
                            <mat-option *ngFor="let answer of filteredAnswersOptions[i] | async" 
                              [disabled]="checkIfAnswerAlreadySelected(answer.uuid,element)"
                              [id]="answer.uuid"
                            [value]="answer.label"
                            >
                              {{answer.label}}
                            </mat-option>
                        </ng-container>
                        </mat-autocomplete>
                      </mat-form-field>


                    </mat-cell>
                </ng-container>
        
                <ng-container matColumnDef="matrics">
                    <mat-header-cell *matHeaderCellDef> Matrics </mat-header-cell>
                    <mat-cell *matCellDef="let element; let i = index">
        
                    <mat-form-field class="col-12 mt-4" appearance="fill" *ngIf="defaultMatrices.length > 0">
                        <mat-select [formControl]="element.get('selectedMatrics')" (selectionChange)="changeMatric($event, element.get('selectedMatrics'))" >
                    
                        <mat-option 
                            *ngFor="let matric of defaultMatrices" 
                            [value]="matric.uuid">
                            {{ matric.name }}
                        </mat-option>
                        </mat-select>
                    </mat-form-field>
        
                    </mat-cell>
                </ng-container>
        
                <ng-container matColumnDef="compare">
                    <mat-header-cell *matHeaderCellDef> Compare </mat-header-cell>
                    <mat-cell *matCellDef="let element; let i = index">
        
                        <mat-form-field class="col-12 mt-4" appearance="fill"  *ngIf="defaultMatrices.length > 0">
                            <mat-select [formControl]="element.get('compare')" required
                                aria-placeholder="compare">
                                <mat-option *ngFor="let compare of compares" [value]="compare.value">
                                    {{compare.name}}
                                </mat-option>
                            </mat-select>
                            
                        </mat-form-field>
        
                    </mat-cell>
                </ng-container>
        
                <ng-container matColumnDef="action">
                    <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
                    <mat-cell *matCellDef="let element; let i = index">
                    <div class="button-row">
                        <button 
                        mat-mini-fab aria-label="Add Question"
                        (click)="addRowAnswerGI()" 
                        color="primary"
                        #tooltip="matTooltip"
                        matTooltip="Add Question">
                        <mat-icon>add</mat-icon>
                        </button>
                        <button 
                        mat-mini-fab aria-label="Display Type Layout"
                        (click)="showConfigLayout(element)" 
                        color="primary"
                        #tooltip="matTooltip"
                        matTooltip="Edit display layout"
                        >
                        <mat-icon>edit</mat-icon>
                        </button>
                        <button 
                        [disabled]="graphQuestionAnswers['controls'].length == 1" 
                        mat-mini-fab aria-label="Delete Question" 
                        (click)="deleteRowAnswerGI(element,i)" 
                        color="warn"
                        #tooltip="matTooltip"
                        matTooltip="Delete Question"
                        >
                        <mat-icon>delete_outline</mat-icon>
                        </button>
                    </div>
                    </mat-cell>
                </ng-container>
        
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>

            </ng-container>

            <div class="row text-center mb-4" *ngIf="renderingAnswers">
              <div class="loading-container">
                  <mat-spinner diameter="50" color="accent"> </mat-spinner>
              </div>
              <br>
              <mat-label>Loading ...</mat-label>
            </div>


        </mat-expansion-panel>
      </mat-accordion>

</div>
</div>
<div class="row text-center mb-4" *ngIf="isLoading">
    <div class="loading-container">
        <mat-spinner diameter="50" color="accent"> </mat-spinner>
    </div>
    <br>
    <mat-label>Loading ...</mat-label>
  </div>
  
<ng-container *ngIf="duplicating">
  <div class="row">
    <div class="col-12 text-center mt-5">
      <h3>Duplicating {{formToDuplicate?.name}}</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center mt-5">
      <div>
        <p>Duplicating form (<b>{{numberForm}}</b>)</p>
        <mat-icon *ngIf="duplicatingForm">check_circle_outline</mat-icon>
        <mat-progress-spinner *ngIf="!duplicatingForm" [mode]="'indeterminate'" [diameter]="20"
          style="margin:0 auto;"></mat-progress-spinner>

      </div>
      <br>
      <div>
        <p>Duplicating form page (<b>{{numberFormPage}}</b>)</p>
        <mat-icon *ngIf="duplicatingFormPage">check_circle_outline</mat-icon>
        <mat-progress-spinner *ngIf="!duplicatingFormPage" [mode]="'indeterminate'" [diameter]="20"
          style="margin:0 auto;"></mat-progress-spinner>

      </div>
      <br>
      <div>
        <p>Duplicating form page questions (<b>{{numberFormPageQuestions}}</b>)</p>
        <mat-icon *ngIf="duplicatingFormPageQuestions">check_circle_outline</mat-icon>
        <mat-progress-spinner *ngIf="!duplicatingFormPageQuestions" [mode]="'indeterminate'" [diameter]="20"
          style="margin:0 auto;"></mat-progress-spinner>

      </div>
      <br>
      <div>
        <p>Duplicating form page questions answers (<b>{{numberFormPageQuestionsAns}}</b>)</p>
        <mat-icon *ngIf="duplicatingFormPageQuestionsAns">check_circle_outline</mat-icon>
        <mat-progress-spinner *ngIf="!duplicatingFormPageQuestionsAns" [mode]="'indeterminate'" [diameter]="20"
          style="margin:0 auto;"></mat-progress-spinner>

      </div>
      <br>
      <div>
        <p>Duplicating form page questions dependency (<b>{{numberFormPageQuestionsDependency}}</b>)</p>
        <mat-icon *ngIf="duplicatingFormPageQuestionsDependency">check_circle_outline</mat-icon>
        <mat-progress-spinner *ngIf="!duplicatingFormPageQuestionsDependency" [mode]="'indeterminate'" [diameter]="20"
          style="margin:0 auto;"></mat-progress-spinner>

      </div>
      <br>
      <div>
        <p>Duplicating form page questions matric (<b>{{numberFormPageQuestionsMatrics}}</b>)</p>
        <mat-icon *ngIf="duplicatingFormPageQuestionsMatrics">check_circle_outline</mat-icon>
        <mat-progress-spinner *ngIf="!duplicatingFormPageQuestionsMatrics" [mode]="'indeterminate'" [diameter]="20"
          style="margin:0 auto;"></mat-progress-spinner>

      </div>

      <div>
        <p>Saving new duplicated form</p>
        <mat-icon *ngIf="uploadingTOS3">check_circle_outline</mat-icon>
        <mat-progress-spinner *ngIf="!uploadingTOS3" [mode]="'indeterminate'" [diameter]="20"
          style="margin:0 auto;"></mat-progress-spinner>

      </div>

    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center mt-5">
      <h4> Please be patient this will take a while.</h4>
      <h4>Don't close or refresh your browser</h4>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="generatingParticipantSubmission">
  <div class="row text-center mb-4 mt-4">
    <div class="loading-container">
      <mat-spinner diameter="50" color="accent"> </mat-spinner>
    </div>
    <br>
    <mat-label>Processing participants submissions..</mat-label>
    <mat-label>This will take a minute...</mat-label>
  </div>
</ng-container>

<ng-container *ngIf="!duplicating && !generatingParticipantSubmission">

  <div class="row mb-3">
    <div class="col-12 text-right">
      <button mat-raised-button color="primary" [routerLink]="['/consumer-report/form/create']">Add New Form</button>
    </div>
  </div>
  <div class="row mb-3">
    <mat-form-field class="fullWidth col-12" appearance="fill">
      <input type="text" placeholder="Search Form" aria-label="Search Form" matInput appearance="fill" #searchFormInput>
    </mat-form-field>
  </div>
  <div class="row">
    <div class="col-12 text-center">
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 commonTable" style="width: 100%">
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element;"> <mat-icon
              [style.color]="statusColour[element.status]">circle</mat-icon> </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element">
            {{ element.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="questionCount">
          <th mat-header-cell *matHeaderCellDef> No. of Questions </th>
          <td mat-cell *matCellDef="let element;"> {{ element.questionCount }} </td>
        </ng-container>

        <ng-container matColumnDef="originatedISOCountryCode">
          <th mat-header-cell *matHeaderCellDef> Country </th>
          <td mat-cell *matCellDef="let element;"> {{ element.originatedISOCountryCode }} </td>
        </ng-container>

        <ng-container matColumnDef="period">
          <th mat-header-cell *matHeaderCellDef> Period </th>
          <td mat-cell *matCellDef="let element;"> {{ element.startDate | date:'d MMMM y' }} - {{ element.endDate |
            date:'d MMMM y' }} </td>
        </ng-container>

        <ng-container matColumnDef="participantCount">
          <th mat-header-cell *matHeaderCellDef> Completed / Participants </th>
          <td mat-cell *matCellDef="let element;"> {{ element.completedSubmissionCount }} / {{ element.participantCount
            }} </td>
        </ng-container>

        <ng-container matColumnDef="exportStatus">
          <th mat-header-cell *matHeaderCellDef> Export Status </th>
          <td mat-cell *matCellDef="let element;">
            <mat-icon [matTooltip]="getExportStatus(element.exportStatus)?.label"
            [style.color]="getExportStatus(element.exportStatus)?.color">circle</mat-icon> 
          </td>
        </ng-container>

        <ng-container matColumnDef="exportDate">
          <th mat-header-cell *matHeaderCellDef> Export Date </th>
          <td mat-cell *matCellDef="let element;">
            <a href="javascript:void(0)"  (click)="getCSVFile(element)" *ngIf="element.exportStatus == 'success'">
              {{element.exportDate | date:'d MMMM y' }}
            </a>
          
          </td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element;">
            <mat-spinner [hidden]="!reCaching" diameter="20"></mat-spinner>

            <span [hidden]="reCaching">
              <a mat-icon-button color="primary" (click)="exportSubmission(element)" matTooltip="Export submission">
                <mat-icon>file_download</mat-icon>
              </a>
              <a mat-icon-button color="primary" routerLink="/consumer-report/form/user-defined/{{element.id}}"
                matTooltip="Edit user defined answers">
                <mat-icon>edit_note</mat-icon>
              </a>
              <a mat-icon-button color="primary" routerLink="/consumer-report/form/update/{{element.id}}"
                matTooltip="Edit">
                <mat-icon>edit</mat-icon>
              </a>
              <a mat-icon-button color="warn" (click)="delForm(element)" matTooltip="Delete">
                <mat-icon>remove_circle</mat-icon>
              </a>
              <a mat-icon-button color="primary" (click)="recacheForm(element.id)" matTooltip="Recache form">
                <mat-icon>cached</mat-icon>
              </a>
              <a mat-icon-button color="primary" (click)="duplicateForm(element)" matTooltip="Duplicate form">
                <mat-icon>content_copy</mat-icon>
              </a>
              <a mat-icon-button color="primary" (click)="generateSurveyResult(element)"
                matTooltip="Generate survey result" *ngIf="element.submissionCount > 0 && element.participantCount > 0">
                <mat-icon>cloud_download</mat-icon>
              </a>

              <a mat-icon-button color="primary" (click)="generateParticipantSubmissionCSV(element)"
                matTooltip="Export participants submissions" *ngIf="element.submissionCount > 0 && element.participantCount > 0">
                <mat-icon> insert_drive_file</mat-icon>
              </a>

              <a mat-icon-button color="primary" (click)="generateParticipantSubmissionCSVv2(element)"
                matTooltip="Export participants submissions v2" *ngIf="element.submissionCount > 0 && element.participantCount > 0">
                <mat-icon> file_copy</mat-icon>
              </a>

            </span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="formCols" class="p-2"></tr>
        <tr mat-row *matRowDef="let row; columns: formCols;" class="p-2"></tr>
      </table>
    </div>
  </div>
  <div class="row mb-4">
    <div class="col-12">
      <mat-paginator [length]="total" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
        (page)="changePage($event)">
      </mat-paginator>
    </div>
  </div>
</ng-container>